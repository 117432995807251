import { RatingPromptWrapperProps } from './index';
import { useEffect, useState } from 'react';
import RatingComponent from './RatingComponent';
import { RatingComponentData, ratingPromptAPI } from 'api/rating';

function RatingComponentPageWrapper({ itemType, itemId }: RatingPromptWrapperProps) {
    const [showPrompt, setShowPrompt] = useState(false);
    const [promptData, setPromptData] = useState<RatingComponentData>();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        ratingPromptAPI.getRatingComponentData(itemType, itemId, false).then((response) => {
            if (response.status === 'OK') {
                setShowPrompt(true);
                setPromptData(response.data);
            }

            if (response.status === 'SKIP') {
                setShowPrompt(false);
            }
        });
    }, []);

    const hidePrompt = () => {
        setShowPrompt(false);
    };

    return showPrompt && promptData ? (
        <RatingComponent
            itemType={itemType}
            itemId={itemId}
            showPrompt={showPrompt}
            promptData={promptData}
            hidePrompt={hidePrompt}
            expanded={expanded}
            setExpanded={setExpanded}
        />
    ) : null;
}

export default RatingComponentPageWrapper;
