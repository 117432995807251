import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { useQuery } from '@tanstack/react-query';
import { elementAPI, FolderData } from 'api/editor/element';
import ElementFolderItem from './folder-selection/ElementFolderItem';
import AddFolderButton from './folder-selection/AddFolderButton';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { now } from 'lodash';

export interface FolderSelectionProps extends FormControlProps {
    label: string;
    baseUrl: string;
    readOnly?: boolean;
}

export default function FolderSelection({ controlProps }: FormElementControlPropsType<FolderSelectionProps>) {
    const { t } = useBundleTranslation(['app/components/common/form/form']);

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState<FolderData | null>(null);

    const readOnly = controlProps.readOnly ?? false;

    const {
        data: elementFolders = [],
        refetch,
        isLoading,
    } = useQuery<FolderData[], Error>(['fetchFoldersData'], () => elementAPI.getFoldersData(controlProps.baseUrl));

    if (isLoading) {
        return <Skeleton height={'38px'} animation={'pulse'} />;
    }

    const onFolderDelete = (folderData: FolderData) => {
        setFolderToDelete(folderData);
        setShowConfirmationPopup(true);
    };

    const popupSettings: PopupSettings = {
        title: t('delete_folder_title'),
        textOK: t('delete_button'),
    };

    const afterFolderChanged = () => {
        controlProps.form.hookFormSetValue(controlProps.name, now(), { shouldDirty: true });
    };

    const onDeleteConfirm = () => {
        if (folderToDelete) {
            elementAPI.deleteFolder(controlProps.baseUrl, folderToDelete.folderId).then((response) => {
                refetch();
                afterFolderChanged();
            });
        }

        setShowConfirmationPopup(false);
    };

    const getFolderPath = () => {
        return (
            folderToDelete && (
                <Stack direction={'row'} justifyContent={'center'} spacing={0.5} sx={{ width: '85%' }}>
                    <Typography
                        component={'p'}
                        sx={{
                            direction: 'rtl',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                        color={'text.secondary'}
                    >
                        {folderToDelete.folderPathParts.join(' / ')}
                    </Typography>
                    <Typography color={'text.secondary'} fontWeight={600} sx={{ whiteSpace: 'nowrap' }}>
                        {folderToDelete.folderPathParts.length > 0
                            ? `/ ${folderToDelete.folderName}`
                            : folderToDelete.folderName}
                    </Typography>
                </Stack>
            )
        );
    };

    return (
        <>
            <Box>
                {elementFolders.length > 0 && (
                    <Box sx={{ paddingBottom: '16px' }}>
                        {elementFolders.map((elementFolder) => (
                            <ElementFolderItem
                                key={elementFolder.folderId}
                                elementFolder={elementFolder}
                                onFolderDelete={onFolderDelete}
                                readOnly={readOnly}
                            />
                        ))}
                    </Box>
                )}
                {!readOnly && (
                    <AddFolderButton
                        refetch={refetch}
                        baseUrl={controlProps.baseUrl}
                        selectedFolders={elementFolders}
                        afterAdd={afterFolderChanged}
                    />
                )}
            </Box>
            {showConfirmationPopup && folderToDelete && (
                <Popup
                    settings={popupSettings}
                    open={showConfirmationPopup}
                    onHide={() => {
                        setShowConfirmationPopup(false);
                        setFolderToDelete(null);
                    }}
                    maxWidth={'popupSm'}
                    onConfirm={onDeleteConfirm}
                >
                    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                        <Typography>{t('delete_confirm')}</Typography>
                        {getFolderPath()}
                    </Stack>
                </Popup>
            )}
        </>
    );
}

export class FolderSelectionBuilder extends FormComponentBuilder {
    prepareProps(): FolderSelectionProps {
        return {
            ...this.controlProps,
            label: this.elementProps.componentProps?.label,
            baseUrl: this.elementProps.componentProps?.baseUrl,
            readOnly: this.elementProps.componentProps?.readOnly,
        };
    }
}
