import { instance } from './api';
import { ElementDocumentType } from 'components/element-viewer';
import { HomePageFilterState, HomePageState } from 'app/home/index.functions';

export interface TileImage {
    thumb_src: string;
    user_dashboard_element_instance_id: string;
}

export interface CustomField {
    name: string;
    type: string;
    id: number;
    supportRestriction: boolean;
    values: string[];
}

export interface CustomFieldSection {
    cfs_id: number;
    label: string;
    fields: CustomField[];
}

export interface TopicInfo {
    topic_id?: number;
    name?: string;
    use_icon?: string;
    icon_type?: string;
    icon?: string;
    fa_icon?: string;
    description?: string;
    definition?: string;
}

export interface TopicType {
    topic_type_name?: string;
    use_icon?: string;
    icon_type?: string;
    icon?: string;
    fa_icon?: string;
    htmlIcon?: {
        icon_type?: string;
        icon?: string;
    };
    topics?: TopicInfo[];
}

export interface AccessRequestElementInfo {
    name?: string;
    html?: string;
    isPermittedForExternalTool?: string;
    externalToolName?: string;
    accessData?: {
        element_id?: number;
        segment_value_id?: number;
        content_type?: string;
        external_content_type_name?: string;
        description?: string;
        element_info?: string;
        image?: string;
        topics?: { [key: string]: TopicType };
        custom_fields?: CustomFieldSection[];
        documents: Array<ElementDocumentType>;
    };
}

export interface RequestAccessResponse {
    message?: string;
}

export interface StatusResponse {
    status: 'OK' | 'ERROR';
    messages?: {
        [key: string]: { [key: string]: string };
    };
}
export interface BurstCountResponse {
    status: 'OK' | 'ERROR';
    data?: {
        count: number;
    };
}
export interface HandleNewBurstResponse {
    status: 'OK' | 'ERROR';
    redirectUrl?: string;
}
export type PortalPagesMenuItem = {
    portal_page_id: number;
    internal_name: string;
    name: string;
    catalog_layout_ind: string;
    icon_ind: string;
    fa_icon_name: string;
    page_type: string;
    image_src?: string;
};
export interface PortalPagesMenuResponse {
    displayCatalog: boolean;
    portalMaxTabs: number;
    catalogMenuItem: {
        icon_ind: string;
        fa_icon_name: string;
        image_src?: string;
        name: string;
    };
    portalPages: PortalPagesMenuItem[];
}
export interface PortalPagesResponse {
    status: 'OK' | 'ERROR';
    data?: PortalPagesMenuResponse;
}

export type HiddenItemsCountResponse = {
    count: number;
};

export type HiddenItemsElement = {
    user_dashboard_element_instance_id: string;
    element_type: string;
    certified_ind: string;
    last_certified_time: string | null;
    topics: string | null;
    certification_level_name: string | null;
    certification_level_color: string | null;
    element_id: number;
    segment_value_id: number;
    element_dashboard_name: string;
    last_measurement_time: string | null;
    last_measurement_time_formatted: string | null;
    last_measurement_value_formatted: string | null;
    total_forecast_amount_formatted: string | null;
    is_in_favorites: string | null;
    element_info: string;
    is_edit_access_ind: string;
    last_certified_by_name: string | null;
    in_favorites: string | null;
    has_access: string | null;
};

export type HiddenItemsResponse = {
    elements: HiddenItemsElement[];
};

export const homePage = {
    async getData(params: any) {
        let url = `/data/home/`;
        if (null !== params) url += '?' + new URLSearchParams(params);

        const response = await instance.get(url);
        return response.data;
    },
    async loadTileImages(ids: string[]): Promise<TileImage[]> {
        const formData = new FormData();

        ids.forEach((id) => {
            formData.append('ids[]', id);
        });

        const response = await instance.post('/data/home/index/load-tile-images', formData);
        return response.data.data || [];
    },
    async loadCustomFields(elementId: number, type: string): Promise<CustomFieldSection[]> {
        const url = `/data/home/index/load-custom-fields-info/${elementId}/${type}`;

        const response = await instance.get(url);

        return response.data.data || [];
    },
    async getAccessRequestInfo(elementId: number, segmentValueId: number): Promise<AccessRequestElementInfo> {
        const url = `/data/home/index/access-request-info/element/${elementId}/segment/${segmentValueId}`;

        const response = await instance.get(url);

        return response.data.data || [];
    },
    async requestAccess(elementId: number, segmentValueId: number): Promise<RequestAccessResponse> {
        const url = `/data/home/index/require-tiles-access`;

        const response = await instance.post(url, {
            element_id: elementId,
            segment_value_id: segmentValueId,
        });

        return response.data.data || [];
    },
    async saveState(homePageState: HomePageState): Promise<RequestAccessResponse> {
        const url = `/data/home/index/save-state`;

        const response = await instance.post(url, {
            state: homePageState,
        });

        return response.data.data || [];
    },
    async hideCategories(): Promise<RequestAccessResponse> {
        const url = `/data/home/index/hide-categories`;

        const response = await instance.post(url);

        return response.data.data || [];
    },
    async addFolder(folderName: string): Promise<StatusResponse> {
        const url = `/data/notification/folder/0/form`;

        const response = await instance.post(url, { name: folderName });

        return response.data || [];
    },
    async getBurstsCount(elementId: number, segmentValueId: number): Promise<BurstCountResponse> {
        if (0 == elementId)
            return new Promise<BurstCountResponse>((resolve, reject) => {
                resolve({ status: 'OK', data: { count: 0 } });
            });

        const url = `/data/home/index/get-bursts-count/element/${elementId}/segment/${segmentValueId}`;

        const response = await instance.get(url);

        return response.data || [];
    },
    async handleNewBurst(elementId: number, segmentValueId: number): Promise<HandleNewBurstResponse> {
        const url = `/data/notification/burst/tile-new-burst`;

        const response = await instance.post(url, {
            tile_favorite_id: '',
            tile_element_id: elementId,
            tile_sv_id: segmentValueId,
            burst: '0',
        });

        return response.data || [];
    },
    async loadHiddenItemsCount(filter: HomePageFilterState): Promise<HiddenItemsCountResponse> {
        const url = `/data/home/index/load-hidden-items-count`;

        const response = await instance.post(url, {
            filter: filter,
        });

        return response.data.data || { count: 0 };
    },
    async loadHiddenItems(filter: HomePageFilterState): Promise<HiddenItemsResponse> {
        const url = `/data/home/index/load-hidden-items`;

        const response = await instance.post(url, {
            filter: filter,
        });

        return response.data.data || { elements: [] };
    },
    async getPortalPagesMenu(): Promise<PortalPagesMenuResponse> {
        const url = `/data/home/index/get-portal-pages-menu`;

        const response = await instance.get(url);

        return response.data.data || {};
    },
};
