import { useRef, useState } from 'react';

export default function useHandleEditPanelError() {
    const setMessage = (msg: string) => {
        setInnerMessage(msg);
        if (!msg) {
            return;
        }
        if (editPanel.current) {
            editPanel.current?.scrollTo({ top: 0 });
        }
    };

    const [message, setInnerMessage] = useState('');
    const editPanel = useRef<HTMLDivElement>();
    return { message, setMessage, editPanel };
}
