import React, { useEffect, useRef, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, ClickAwayListener, IconButton, Link, Tooltip } from '@mui/material';
import PartItemWallComment from 'components/wall/parts/PartItemWallComment';
import IconMi from 'components/common/icon/IconMi';
import WallPostbox from 'components/wall/parts/WallPostbox';
import { wallAPI } from 'api/wall';
import merge from 'ts-deepmerge';
import useWallContext from 'components/wall/hooks/useWallContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';

export interface PartItemWallThreadProps {
    elementType: string;
    data: any;
    uid: string;
    wallData: any;
    updateWallData: (newData: any) => void;
    followUser: (followData: any) => void;
    insightful: (type: string, id: number | string) => void;
    deleteNote?: () => void;
    deleteAnnotation?: () => void;
    notifyOther: (type: string, text: any, itemData: any) => void;
    imageAntiCache?: string;
}

export default function PartItemWallThread(props: PartItemWallThreadProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const {
        data,
        uid,
        elementType,
        wallData,
        updateWallData = () => {},
        followUser = () => {},
        insightful = () => {},
        deleteNote = () => {},
        deleteAnnotation = () => {},
        notifyOther = () => {},
        imageAntiCache,
    } = props;

    const params = wallData.params;
    const [isShowCommentForm, setIsShowCommentForm] = useState<boolean>(false);
    const [addCommentNotSavedVal, setAddCommentNotSavedVal] = useState<string>('');

    const getNodeUid = () => {
        switch (elementType) {
            //prefix wall_user_annotation_
            case 'user_annotation':
                return data.user_annotation_id;
            //prefix wall_user_note_
            case 'user_note':
                return data.user_note_id;
            //prefix wall_alert_event_
            case 'alert_event':
                return data.alert_event_id;
            //prefix wall_notable_event_
            case 'notable_event':
                return data.notable_event_id;
        }
    };

    const prefix = `wall_${elementType}_`;
    const nodeUid = `${elementType}_` + getNodeUid();

    const followDiscussion = async () => {
        //data.discussion_follower_id > 0 ? 'unfollow' : 'follow'
        const followAction = data.discussion_follower_id <= 0;
        const reqData: any = {
            element: params.element_id,
            sv_id: params.segment_value_id,
            user_note_id: data.user_note_id,
            user_annotation_id: data.user_annotation_id,
            alert_event_id: data.alert_event_id,
            notable_event_id: data.notable_event_id,
        };

        if (!followAction) {
            reqData.discussion_follower_id = data.discussion_follower_id;
        }

        let followActionAvailable = true;
        if (followAction) {
            await wallAPI
                .checkNotificationEnabled()
                .then((response) => {
                    if (response.data?.result == false) {
                        followActionAvailable = false;
                        alert(t('thread.alert_change_preferences_to_receive_notifications'));
                    }
                })
                .catch(() => {});
        }

        if (!followActionAvailable) return false;

        await wallAPI
            .followDiscussion(reqData)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    const newData = merge(wallData.data, response.data.data);
                    updateWallData({ data: newData });
                }
            })
            .catch(() => {});
    };

    const addCommentEvent = () => {
        if (wallData.addComment) {
            const isShowCommentForm = data[`${wallData.addComment.type}_id`] == wallData.addComment.id;
            setIsShowCommentForm(isShowCommentForm);
        }
    };

    useEffect(() => {
        addCommentEvent();
    }, []);
    useEffect(() => {
        addCommentEvent();
    }, [wallData.addComment]);

    return (
        <Box id={`${prefix}${uid}_${nodeUid}_thread`} className="wall_thread">
            <Box
                id={`${prefix}${uid}_${nodeUid}_comments`}
                className="wall_comments"
                sx={{ pl: '48px' /*image 32px  + indent 12 + 4px ext indent*/ }}
            >
                {Object.keys(data.comments).length > 0 && (
                    <>
                        {Object.values(data.comments)
                            .sort((a: any, b: any) => {
                                //var $comments = _.sortBy($row.comments, function($v){ return $v.comment_ts; });
                                return a.comment_ts < b.comment_ts ? 1 : a.comment_ts > b.comment_ts ? -1 : 0;
                            })
                            .filter((item: any) => item.text)
                            .map((comment: any) => {
                                return (
                                    <PartItemWallComment
                                        data={data}
                                        comment={comment}
                                        key={comment.user_comment_id}
                                        followUser={followUser}
                                        insightful={insightful}
                                        wallData={wallData}
                                        updateWallData={updateWallData}
                                        notifyOther={notifyOther}
                                        imageAntiCache={imageAntiCache}
                                    />
                                );
                            })}
                    </>
                )}
            </Box>

            {wallData.settings.privAddComments ? (
                <Box
                    id={`${prefix}${uid}_${nodeUid}_comment_block`}
                    className="wall_comment_box_last_OLD"
                    sx={{
                        '.comment_block_td': {
                            display: 'flex',
                            borderTop: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
                            paddingTop: '4px',
                            marginTop: '4px',
                            padding: '4px 0 4px 8px',
                        },
                    }}
                >
                    {!isShowCommentForm && (
                        <table className="add_comment_table" cellSpacing="0" cellPadding="0" width="100%">
                            <tbody>
                                <tr className="wall_cbl_row">
                                    <td className="comment_block_td">
                                        <Tooltip title={t('thread.add_comment_tooltip')}>
                                            <Box
                                                sx={{ mr: 2, display: 'inline-flex', alignItems: 'center' }}
                                                component="span"
                                                className="add_comment_a"
                                                id={`${prefix}${uid}_${nodeUid}_comment`}
                                                data-node-uid={nodeUid}
                                                data-type="text"
                                                data-user_note_id={data.user_note_id}
                                                data-user_annotation_id={data.user_annotation_id}
                                                data-alert_event_id={data.alert_event_id}
                                                data-notable_event_id={data.notable_event_id}
                                                onClick={() => {
                                                    setIsShowCommentForm(true);
                                                }}
                                            >
                                                <IconMi icon="comment" fontSize="16" sx={{ mr: 1 }} />
                                                {t('thread.comment')}
                                            </Box>
                                        </Tooltip>

                                        {(data.user_note_id > 0 ||
                                            data.user_annotation_id > 0 ||
                                            (data.notable_event_id > 0 &&
                                                (!Array.isArray(data.comments) ||
                                                    (Array.isArray(data.comments) && data.comments.length != 0)))) && (
                                            <Tooltip
                                                title={t(
                                                    `thread.${
                                                        data.discussion_follower_id > 0
                                                            ? 'unfollow_tooltip'
                                                            : 'follow_tooltip'
                                                    }`
                                                )}
                                            >
                                                <Box
                                                    sx={{ mr: 2, display: 'inline-flex', alignItems: 'center' }}
                                                    className={`wall_${
                                                        data.discussion_follower_id > 0 ? 'unfollow' : 'follow'
                                                    }`}
                                                    data-discussion_follower_id={data.discussion_follower_id ?? 0}
                                                    data-user_note_id={data.user_note_id}
                                                    data-user_annotation_id={data.user_annotation_id}
                                                    data-alert_event_id={data.alert_event_id}
                                                    data-notable_event_id={data.notable_event_id}
                                                    onClick={followDiscussion}
                                                >
                                                    <IconMi icon="arrow-right" fontSize="16" sx={{ mr: 1 }} />
                                                    {t(
                                                        `thread.${
                                                            data.discussion_follower_id > 0
                                                                ? 'unfollow_link'
                                                                : 'follow_link'
                                                        }`
                                                    )}
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {data.alert_event_id > 0 && (
                                            <>
                                                {data.issue_id && data.issue_id != '0' && data.workflow_id > 0 && (
                                                    <Box
                                                        component="a"
                                                        href="#"
                                                        className="update_status"
                                                        data-user_note_id={data.user_note_id}
                                                        data-user_annotation_id={data.user_annotation_id}
                                                        data-alert_event_id={data.alert_event_id}
                                                        data-notable_event_id={data.notable_event_id}
                                                        data-issue_id={data.issue_id}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            //ToDo self.UpdateStatusPopup($(this));
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTasks} />
                                                        {t('thread.update_status')}
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                        <Box sx={{ ml: 'auto' }}>
                                            <Box
                                                component="input"
                                                type="button"
                                                className="wall_post_comment"
                                                id={`${prefix}${uid}_${nodeUid}_comment_post`}
                                                data-box={`${prefix}${uid}_${nodeUid}_comment`}
                                                sx={{ display: 'none', ml: '5px' }}
                                                value=""
                                            />
                                            {data.user_note_id > 0 && (
                                                <>
                                                    {((data.author_user_id == params.user_id &&
                                                        Object.keys(data.comments).length == 0) ||
                                                        wallData.settings.privDeleteCollaborativeItems) && (
                                                        <Tooltip title={t('thread.delete_tooltip')} disableInteractive>
                                                            <IconButton
                                                                sx={{ p: '0', color: 'inherit' }}
                                                                className="wall__item-content-footer-icon-button wall_delete_note"
                                                                data-user_note_id={data.user_note_id}
                                                                onClick={deleteNote}
                                                            >
                                                                <IconMi icon="delete" fontSize="16" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                            {data.user_annotation_id > 0 && (
                                                <>
                                                    {(data.author_user_id == params.user_id ||
                                                        wallData.settings.privDeleteCollaborativeItems) && (
                                                        <>
                                                            {(wallData.settings.privDeleteCollaborativeItems ||
                                                                Object.values(data.comments).every((item: any) => {
                                                                    return !(item.comment_user_id != params.user_id);
                                                                })) && (
                                                                <Tooltip
                                                                    title={t('thread.delete_anot_tooltip')}
                                                                    disableInteractive
                                                                >
                                                                    <IconButton
                                                                        sx={{ p: '0', color: 'inherit' }}
                                                                        className="wall__item-content-footer-icon-button wall_delete_annotation"
                                                                        data-user_annotation_id={
                                                                            data.user_annotation_id
                                                                        }
                                                                        data-annotation_group_id={
                                                                            data.annotation_group_id
                                                                        }
                                                                        onClick={deleteAnnotation}
                                                                    >
                                                                        <IconMi icon="delete" fontSize="16" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </td>
                                    <td className="comment_block_post_td"></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Box
                                            id={`${prefix}${uid}_${nodeUid}_comment_expand`}
                                            sx={{ display: 'none' }}
                                            className="wall_comment_expand"
                                        >
                                            {t('comment.post_to_submit_comment')}
                                            <Box component="span" className="attach_comment_image_span">
                                                <input
                                                    type="checkbox"
                                                    id={`wallAttachCommentImage_${uid}_${nodeUid}`}
                                                    className="attach_comment_image"
                                                />
                                                <label htmlFor={`wallAttachCommentImage_${uid}_${nodeUid}`}>
                                                    {t('comment.attach_file')}
                                                </label>
                                            </Box>
                                        </Box>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {isShowCommentForm && (
                        <ClickAwayListener
                            onClickAway={() => {
                                setIsShowCommentForm(false);
                            }}
                        >
                            <Box
                                sx={{
                                    borderTop: '1px solid rgba(228, 228, 228, 0.75)',
                                    marginTop: '4px',
                                    padding: '8px',
                                }}
                            >
                                <WallPostbox
                                    params={{
                                        uid: params.uid,
                                        element_id: params.element_id,
                                        segment_value_id: params.segment_value_id,
                                        wallAddNote: false,
                                        type: 'comment',
                                        reqParams: {},
                                        containerId: 'test',
                                        collaborativeAttachments: wallData.settings.collaborativeAttachments,
                                        notificationsDisabled: wallData.settings.notificationsDisabled,
                                        initialText: addCommentNotSavedVal,
                                        onClickPost: (text, share, fromData) => {
                                            setIsShowCommentForm(false);

                                            const reqData = fromData.append ? fromData : new FormData();
                                            reqData.append('element', params.element_id);
                                            reqData.append('sv_id', params.segment_value_id);
                                            reqData.append('user_note_id', data.user_note_id);
                                            reqData.append('user_annotation_id', data.user_annotation_id);
                                            reqData.append('alert_event_id', data.alert_event_id);
                                            reqData.append('notable_event_id', data.notable_event_id);
                                            reqData.append('text', text);
                                            reqData.append('share', share.length > 0 ? share.join(';') + ';' : '');

                                            wallAPI
                                                .addComment(reqData)
                                                .then((response) => {
                                                    if (response.data?.status == 'OK') {
                                                        const newData = merge(wallData.data, response.data.data);
                                                        updateWallData({ data: newData });
                                                        setAddCommentNotSavedVal('');
                                                    }
                                                })
                                                .catch(() => {});
                                        },
                                        onChangeVal: (text) => {
                                            setAddCommentNotSavedVal(text);
                                        },
                                    }}
                                />
                            </Box>
                        </ClickAwayListener>
                    )}
                </Box>
            ) : (
                <Box className="mb-10" />
            )}
        </Box>
    );
}
