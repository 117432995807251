import React from 'react';
import { Button, Grid } from '@mui/material';
import { ButtonConfig, GridData, MassActionsConfig, PopupMode, PopupType } from 'components/common/grid/';
import { logWarning, prepareTranslatedText } from 'tools/tools';
import { TFunction } from 'react-i18next';
import { PopupSettings } from 'components/common/popup/Popup';
import IconHandler from 'components/common/icon/IconHandler';
import { onButtonClickAction } from './index';
import { useNavigate } from 'react-router-dom';

export default function MassActionButton({
    config,
    controllerUrl,
    uid,
    selectedItems,
    setPopupType,
    setPopupMode,
    setPopupRedirectAfter,
    setPopupUrl,
    setPopupConfig,
    setFormPostData,
    setPopupGetMethod,
    setPopupComponentProps,
    gridData,
    massActionsConfig,
    t,
    setPopupComponent,
    reloadGridData,
    setShowLoader,
    id,
    setCheckedKeys,
}: {
    config: ButtonConfig;
    controllerUrl: string;
    uid: string;
    selectedItems: Array<string | number>;
    setPopupType?: (type: PopupType) => void;
    setPopupMode?: (mode: PopupMode) => void;
    setPopupRedirectAfter?: (redirect: boolean) => void;
    setPopupUrl?: (url: string) => void;
    setPopupConfig?: (popupConfig: PopupSettings) => void;
    setFormPostData?: (formData: any) => void;
    setPopupGetMethod?: (getMethod: boolean) => void;
    setPopupComponent?: (popupComponent: string) => void;
    setPopupComponentProps?: (componentProps: { [key: string]: string }) => void;
    gridData: GridData;
    massActionsConfig?: MassActionsConfig;
    t: TFunction;
    reloadGridData?: () => void;
    setShowLoader?: (value: boolean) => void;
    id: string;
    setCheckedKeys?: (keys: Array<string | number>) => void;
}) {
    const startIcon = config.icon ?? null;
    const itemIdKey = massActionsConfig?.idColumn ?? 'id';
    const navigate = useNavigate();

    const getSelectedItems = () => {
        if (config.filterRows && gridData.length > 0) {
            const filterColumnName = config.filterRows?.column;
            const filterColumnValue = config.filterRows?.value;

            return selectedItems.filter((itemId) => {
                const selectedRowData = gridData.find((rowData) => rowData[itemIdKey] == itemId);

                return selectedRowData !== undefined && selectedRowData[filterColumnName] === filterColumnValue;
            });
        }

        return selectedItems;
    };

    const onClick = () => {
        try {
            onButtonClickAction(
                config,
                controllerUrl,
                'mass',
                navigate,
                {
                    setPopupUrl,
                    setPopupConfig,
                    setPopupType,
                    setPopupMode,
                    setPopupRedirectAfter,
                    setFormPostData,
                    setPopupGetMethod,
                    setPopupComponent,
                    setPopupComponentProps,
                },
                getSelectedItems(),
                setShowLoader,
                reloadGridData,
                undefined,
                setCheckedKeys
            );
        } catch (error: any) {
            logWarning(error.message);
            return;
        }
    };

    if (config.filterRows && getSelectedItems().length === 0) {
        return null;
    }

    const { text: btnText, needTranslation } = prepareTranslatedText(config.text ?? '');

    return (
        <Grid item key={`button-container-${uid}-${config.name}`}>
            <Button
                onClick={onClick}
                key={uid + config.name}
                startIcon={<IconHandler icon={startIcon} />}
                variant={config.variant ? (config.variant as any) : 'outlined'}
                data-test={id}
                color={config.color ? (config.color as any) : 'primary'}
            >
                {needTranslation === false ? btnText : t(btnText)}
                {getSelectedItems().length !== selectedItems.length && (
                    <> {t('items', { count: getSelectedItems().length })}</>
                )}
            </Button>
        </Grid>
    );
}
