import { useEffect, useState } from 'react';

export default function useOnMeasurementTime(
    measurementTime: string | undefined,
    resetData: (resetInitialData: boolean) => void
) {
    // Full reset on Measurement Interval Change
    const [prevMeasurementTime, setPrevMeasurementTime] = useState(measurementTime);
    useEffect(() => {
        if (measurementTime != prevMeasurementTime) {
            setPrevMeasurementTime(measurementTime);
            resetData(true);
        }
    }, [measurementTime]);
}
