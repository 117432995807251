import { getImageByType } from './Icons';
import { useBlendColors } from 'hooks/useDesign';
import { alpha } from '@mui/material';

export const getLineageDiagramConfig = (themeObj?: any) => {
    const styles = {
        //ToDo: Pass theme variables
        nodes: {
            color: {
                background: themeObj.palette.background.default,
                border: themeObj.palette.primary.main,
                highlight: {
                    border: themeObj.palette.primary.main,
                    background: useBlendColors(alpha(themeObj.palette.primary.main, 0.15)),
                },
                hover: {
                    border: themeObj.palette.primary.main,
                    background: useBlendColors(alpha(themeObj.palette.primary.main, 0.15)),
                },
            },
            icon: themeObj.palette.primary.main,
            font: {
                background: themeObj.palette.background.default,
                color: themeObj.palette.primary.main,
            },
        },
        edges: {
            color: useBlendColors(alpha(themeObj.palette.text.primary, 0.4)),
        },
    };

    return {
        layout: {
            improvedLayout: true,
            hierarchical: {
                direction: 'LR',
                sortMethod: 'directed',
            },
        },
        nodes: {
            image: getImageByType('element', false, styles.nodes.icon),
            shape: 'circularImage',
            size: 25,
            imagePadding: 15,
            color: styles.nodes.color,
            font: styles.nodes.font,
        },
        edges: {
            arrows: {
                to: {
                    enabled: true,
                    type: 'triangle',
                },
            },
            chosen: false,
            color: styles.edges.color,
        },
        physics: {
            enabled: true,
            solver: 'hierarchicalRepulsion',
            hierarchicalRepulsion: {
                nodeDistance: 175,
                damping: 0.2,
                springLength: 75,
            },
            timestep: 0.8,
        },
        interaction: {
            dragNodes: false,
            dragView: true,
            selectConnectedEdges: false,
            zoomView: true,
            navigationButtons: true,
            keyboard: true,
            hover: true,
        },
        groups: {
            report: {
                image: getImageByType('report', false, styles.nodes.icon),
            },
            metric: {
                image: getImageByType('metric', false, styles.nodes.icon),
            },
            'external-metric': {
                image: getImageByType('external-metric', false, styles.nodes.icon),
            },
            'manual-metric': {
                image: getImageByType('metric', false, styles.nodes.icon),
            },
            'external-report': {
                image: getImageByType('external-report', false, styles.nodes.icon),
            },
            'external-content': {
                image: getImageByType('external-content', false, styles.nodes.icon),
            },
            burst: {
                image: getImageByType('burst', false, styles.nodes.icon),
            },
            'dataset-view': {
                image: getImageByType('dataset-view', false, styles.nodes.icon),
            },
            dataset: {
                image: getImageByType('dataset', false, styles.nodes.icon),
            },
            plugin: {
                image: getImageByType('plugin', false, styles.nodes.icon),
            },
            'deleted-dataset': {
                image: getImageByType('deleted-dataset', false, styles.nodes.icon),
            },
            source: {
                image: getImageByType('source', false, styles.nodes.icon),
            },
            element: {
                image: getImageByType('element', false, styles.nodes.icon),
            },
            'multi-metric': {
                image: getImageByType('multi-metric', false, styles.nodes.icon),
            },
            'legacy-report': {
                image: getImageByType('legacy-report', false, styles.nodes.icon),
            },
            'external-dataset': {
                image: getImageByType('external-dataset', false, styles.nodes.icon),
            },
            'external-metadata': {
                image: getImageByType('external-metadata', false, styles.nodes.icon),
            },
            'external-metadata-view': {
                image: getImageByType('external-metadata-view', false, styles.nodes.icon),
            },
            'external-source': {
                image: getImageByType('external-source', false, styles.nodes.icon),
            },
        },
    };
};
