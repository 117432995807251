import { lazy } from 'react';
import { devRoutes } from './dev';
import { adminRoutes } from 'routes/admin';
import { dashboardRoutes } from 'routes/dashboard';
import { editorRoutes } from 'routes/editor';
import { monitorRoutes } from 'routes/monitor';
import { serviceRoutes } from 'routes/service/index';
import { notificationRoutes } from 'routes/notification';
import { breadcrumbItem } from 'store/breadcrumbsSlice';
import { redirectRoutes } from 'routes/redirect';
const ExternalReportGetToken = lazy(() => import('app/extreport/ExternalReportGetToken'));
const ElementViewer = lazy(() => import('components/element-viewer/ElementViewer'));
const GlobalSearch = lazy(() => import('app/search/global/index'));
const SearchTuning = lazy(() => import('app/search/tuning/index'));
const ElementScreenShot = lazy(() => import('components/element-screenshot/ElementScreenShot'));
const ExternalContentFileError = lazy(
    () => import('components/element-viewer/external-content-file/ExternalContentFileError')
);
const DocumentViewer = lazy(() => import('components/document-viewer/DocumentViewer'));
const PublishingBoard = lazy(() => import('app/content-workflow/PublishingBoard'));
const DenyPage = lazy(() => import('app/auth/deny'));
const LoginPage = lazy(() => import('app/auth/login/index'));
const SignedOut = lazy(() => import('app/auth/signed-out'));
const DatasetViewer = lazy(() => import('components/dataset-viewer/DatasetViewer'));
const ChangePassword = lazy(() => import('app/auth/change'));
const ResetPassword = lazy(() => import('app/auth/resetpass'));
const PortalPageViewer = lazy(() => import('app/portal-page-viewer'));
const ExternalTool = lazy(() => import('app/search/embed/ExternalTool'));
const EmbedTiles = lazy(() => import('app/home/EmbedTiles'));

const BlankLayout = lazy(() => import('layouts/BlankLayout'));
const DefaultLayout = lazy(() => import('layouts/DefaultLayout'));
import Error404Page from 'app/error/Error404Page';

export interface breadcrumbsSettings {
    list?: breadcrumbItem[];
    instanceDataKey?: string | 'noInstanceDataKey'; //default 'name'. Use 'noInstanceDataKey' to  refuse set by instance
}
export interface pageTitleSettings {
    value?: false | string; //false - set default portalName if exist else empty ''
    instanceDataKey?: string | 'noInstanceDataKey'; //default 'name'. Use 'noInstanceDataKey' to  refuse set by instance
}
interface RouteItemProps {
    settingsUrl?: string;
    uid?: string;
    pk?: string;
    pageMenu?: string;
    pageTitle?: false | pageTitleSettings; //false - remove title! Override Default: RouteItemType.name.
    breadcrumbs?: false | breadcrumbsSettings; //false - hide! Default RouteItemType.name
    helpLink?: string; //User Menu -> Help link for page
    passSearchParams?: boolean; //pass search params to request data
}

export type RouteItemType = {
    path: Array<string>;
    name: string; //By default for PageTitle and Breadcrumbs Label
    component: any;
    props?: RouteItemProps;
    layout?: string;
    isPublic?: boolean;
    isPortalPageMenu?: boolean;
    index?: boolean;
};

export const layoutRoutes: any[] = [
    {
        name: 'default',
        component: DefaultLayout,
    },
    {
        name: 'blank',
        component: BlankLayout,
    },
];

const indexRoutes: Array<RouteItemType> = [
    ...([
        {
            path: ['*'],
            name: 'error_404.name',
            component: Error404Page,
            props: {
                breadcrumbs: {
                    list: [],
                },
            },
        },
        {
            path: ['/service/iframe/index/type/tile/*'],
            name: 'element.name',
            component: EmbedTiles,
            layout: 'blank',
        },
        {
            path: ['/ext-content/:elementId/unavailable/error/:error/fileId?/:fileId?/file?/:fileName?'],
            name: 'extcontent.name',
            component: ExternalContentFileError,
            layout: 'blank',
        },
        {
            path: ['/ext-content/:elementId/segment?/:segmentValueId?/*'],
            name: 'extcontent.name',
            component: ElementViewer,
        },
        {
            path: ['/extreport/:elementId/segment?/:segmentValueId?/*'],
            name: 'extreport.name',
            component: ElementViewer,
        },
        {
            path: ['/chart/:elementId/segment?/:segmentValueId?/uco?/:uco?/view?/:view?/*'],
            name: 'metric.name',
            component: ElementViewer,
            props: {
                //look diff link by param v6 $helpLinksForPages
                helpLink:
                    'https://help.metricinsights.com/m/Jump_Start_Guide_for__Regular_Users/l/494422-opening-the-full-chart-view',
            },
        },
        {
            path: ['/report/:elementId/segment?/:segmentValueId?/*'],
            name: 'report.name',
            component: ElementViewer,
            props: {
                helpLink:
                    'https://help.metricinsights.com/m/Creating_Basic_Metrics_and_Reports/l/104406-create-a-simple-non-segmented-report',
            },
        },
        {
            path: ['content/index/view-document/document/:documentId'],
            name: 'document.name',
            component: DocumentViewer,
        },
        {
            path: [
                // Metric
                '/chart/:elementId/segment/:segmentValueId/uco?/:uco?/thumbnail',
                '/chart/:elementId/segment?/:segmentValueId?/uco?/:uco?/preview/*',
                // Report
                '/report/:elementId/segment?/:segmentValueId?/thumbnail',
                '/report/:elementId/segment?/:segmentValueId?/preview/*',
                // External Report
                '/extreport/:elementId/segment?/:segmentValueId?/preview/*',
                // External Content
                '/ext-content/:elementId/segment?/:segmentValueId?/preview/*',
            ],
            name: '',
            component: ElementScreenShot,
            layout: 'blank',
        },
        {
            path: ['/extreport/:elementId/get-token/:connectionProfileId'],
            name: '',
            component: ExternalReportGetToken,
            layout: 'blank',
        },
        {
            path: ['/search/global'],
            name: 'Search',
            component: GlobalSearch,
        },
        {
            path: ['/search/tuning'],
            name: 'search_tuning.name',
            component: SearchTuning,
        },
        {
            path: ['/content-workflow/:cwfId?'],
            name: 'publishing.name',
            component: PublishingBoard,
            props: { pageMenu: 'publishing' },
        },
        {
            path: ['/login'],
            name: 'login',
            component: LoginPage,
            layout: 'blank',
            isPublic: true,
            props: { pageTitle: { value: false } },
        },
        {
            path: ['/auth/deny/type?/:type?/*'],
            name: 'Access Denied',
            component: DenyPage,
            isPublic: true,
            props: {
                breadcrumbs: {
                    list: [],
                },
            },
        },
        {
            path: ['/auth/signed-out'],
            name: 'Signed Out',
            component: SignedOut,
            isPublic: true,
            layout: 'blank',
        },
        {
            path: ['/dataset/:datasetId/instance?/:instanceId?/filter?/:filterId?/*'],
            name: 'Dataset Viewer',
            component: DatasetViewer,
            props: {
                helpLink:
                    'https://help.metricinsights.com/m/Creating_Basic_Metrics_and_Reports/l/691335-dataset-viewer-select-fields-define-filters',
            },
        },
        {
            path: ['/search/external-tool/:externalToolId'],
            name: 'Embed External Tool',
            component: ExternalTool,
        },
        {
            path: ['/api'],
            name: 'API Toolkit',
            component: lazy(() => import('app/api-toolkit/index')),
        },
        {
            path: ['/auth/change'],
            name: 'changepass.name',
            component: ChangePassword,
            isPublic: true,
            layout: 'blank',
        },
        {
            path: ['/auth/resetpass'],
            name: 'resetpass.name',
            component: ResetPassword,
            isPublic: true,
            layout: 'blank',
        },
        {
            path: ['/p/*'],
            name: 'portal_page.viewer',
            component: PortalPageViewer,
            isPublic: true,
            isPortalPageMenu: true,
            layout: 'blank',
            props: {
                breadcrumbs: {
                    list: [],
                },
            },
        },
    ] as RouteItemType[]),
    ...devRoutes,
    ...adminRoutes,
    ...dashboardRoutes,
    ...editorRoutes,
    ...notificationRoutes,
    ...monitorRoutes,
    ...serviceRoutes,
    ...redirectRoutes, //backward compatibility
];

export default indexRoutes;
