import React, { useContext, useState } from 'react';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ReportContentNS } from 'components/report-content';
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { AssocArray } from 'tools/types';
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import FieldsList, {
    FieldSortingContext,
} from 'components/report-content/components/manage-filters/edit-panel/FieldsList';
import { getFieldOptionForFormName } from 'components/report-content/components/manage-filters/edit-panel';
import FieldForm from 'components/report-content/components/manage-filters/edit-panel/FieldForm';
import { arrayMoveImmutable } from 'array-move';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import { Box, Stack } from '@mui/material';
import StaticInfo from 'components/common/StaticInfo';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import ReactHookFormController, { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import ComponentSettingsManageFiltersDataQueries = ReportContentNS.ComponentSettingsManageFiltersDataQueries;
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsExternalVisualization = ReportContentNS.ComponentSettingsExternalVisualization;
import BlockType = ReportContentNS.BlockType;
import RendererFilter = ReportContentNS.RendererFilter;
import { getElementFieldValue, prepareFormElementProps } from 'components/common/form/formTools';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsExternalVisualization> = [];

export default function ManageFiltersEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsManageFilters> & { block: BlockType<ComponentSettingsManageFilters> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);

    const [fieldsState, setFieldsState] = useState<Array<RendererFilter>>(component.settings.fields ?? []);

    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        filterPosition: component.settings?.filterPosition ?? 'top',
    };

    fieldsState.forEach((field) => {
        defaultState[getFieldOptionForFormName(field, 'filter_column')] = field.filter_column;
    });

    if (contentSettings.segmentValues.segments[0]) {
        defaultState.dimension = contentSettings.segmentValues.segments[0].name;
        if (contentSettings.segmentValues.segments[1]) {
            defaultState.dimension += ', ' + contentSettings.segmentValues.segments[1].name;
        }
    }
    const formsContext = useContext<FormsContextType>(FormsContext);
    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);
    const segmentValueIds = [
        contentSettings.segmentValues.segments[0]
            ? Number(
                  getElementFieldValue(
                      elementProps.form,
                      '$form[report].primary_segment_value_ds_column_id',
                      formsContext
                  )
              )
            : 0,
        contentSettings.segmentValues.segments[1]
            ? Number(
                  getElementFieldValue(
                      elementProps.form,
                      '$form[report].secondary_segment_value_ds_column_id',
                      formsContext
                  )
              )
            : 0,
    ];

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process fields settings
        const fields = fieldsState.map((field) => ({
            ...field,
            filter_column: elementProps.form.hookFormGetValues(getFieldOptionForFormName(field, 'filter_column')),
        }));

        let dataQueries: ComponentSettingsManageFiltersDataQueries = { skip: true };
        fields
            .filter((field) => field.filter_column == 'Y')
            .forEach((field) => {
                if ('dropdown' == field.filterType || 'multiselect' == field.filterType) {
                    dataQueries[field.reference_name] = {
                        resultFields: [field.reference_name],
                        sort: [{ field: field.reference_name, dir: 'DESC' === field.sortDirection ? 'DESC' : 'ASC' }],
                        resultFieldAggregations: {},
                        distinct: true,
                    };
                } else if ('range' == field.filterType || 'charting_interval' == field.filterType) {
                    const rfaMin: any = {};
                    const rfaMax: any = {};
                    rfaMin[field.reference_name] = 'Min';
                    dataQueries[field.reference_name + '-from'] = {
                        resultFields: [field.reference_name],
                        resultFieldAggregations: rfaMin,
                    };
                    rfaMax[field.reference_name] = 'Max';
                    dataQueries[field.reference_name + '-to'] = {
                        resultFields: [field.reference_name],
                        resultFieldAggregations: rfaMax,
                    };
                }

                // if ('N' === field.include_all_value) {
                //     component.settings.waitToLoad = 'Y';
                // }
            });

        const newSettings: ComponentSettingsManageFilters = {
            ...component.settings,
            dataQueries: dataQueries,
            fields: fields,
            filterPosition: elementProps.form.hookFormGetValues('filterPosition'),
        } as ComponentSettingsManageFilters;
        // @ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        editPanelContext?.updateBlockSettings(block.uid, newSettings, true);
    };

    const [editField, setEditField] = useState<RendererFilter | null>(null);
    const handleFieldFormApply = (field: RendererFilter) => {
        // elementProps.form.hookFormSetValue(getFieldOptionForFormName(field, 'filter_label'), field.filter_label);

        const fieldIndex = fieldsState.findIndex((fs) => fs.reference_name_escaped == field.reference_name_escaped);
        if (fieldIndex != -1) {
            const list = fieldsState.slice();
            list[fieldIndex] = { ...list[fieldIndex], ...field };
            setFieldsState(list);
        }
        setEditField(null);
    };

    const handleSelectField = (field: RendererFilter) => {
        setEditField({
            ...field,
            filter_column: elementProps.form.hookFormGetValues(getFieldOptionForFormName(field, 'filter_column')),
        });
    };

    const handleFieldsSort = (oldIndex: number, newIndex: number) => {
        setFieldsState(
            arrayMoveImmutable(fieldsState, oldIndex, newIndex).map((filter, i) => ({
                ...filter,
                column_sequence: i,
            }))
        );
    };

    return (
        <>
            {editField == null && (
                <Stack height={1}>
                    <Box flexShrink={0}>
                        <BlockEditPanelHeader
                            title={t('manage_filters.edit_title')}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                    <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                        {/*Hidden until 7.0.2 MI-21300*/}
                        {false && (
                            <Box sx={{ display: 'flex' }}>
                                <StaticAddon>{t('manage_filters.label_position')}</StaticAddon>
                                <Box flexGrow={1}>
                                    <ReactHookFormController
                                        componentValues={['top', 'left', 'right'].map((item) => {
                                            return {
                                                label: t('manage_filters.label_position_' + item),
                                                value: item,
                                            };
                                        })}
                                        elementProps={prepareFormElementProps({
                                            ...elementProps,
                                            ...{
                                                component: {
                                                    component: 'FormSelect',
                                                    name: 'filterPosition',
                                                } as RawFormComponentType,
                                            },
                                        })}
                                    />
                                </Box>
                            </Box>
                        )}
                        {contentSettings.hasInstances &&
                            contentSettings.instances.length > 0 &&
                            contentSettings.measurement_time && (
                                <StaticInfo label={t('manage_filters.snapshot_date_msg')} />
                            )}
                        {contentSettings.segmentValues.segments.length > 0 && (
                            <Box>
                                <TemplateFormComponent
                                    elementProps={elementProps}
                                    component={'FormText'}
                                    name={'dimension'}
                                    props={{ label: t('manage_filters.label_dimensions'), disabled: true }}
                                />
                            </Box>
                        )}
                        <FieldSortingContext.Provider
                            value={{
                                elementProps: elementProps,
                                selectField: handleSelectField,
                                onSortEnd: handleFieldsSort,
                            }}
                        >
                            <FieldsList
                                fields={fieldsState.filter(
                                    (f) =>
                                        !segmentValueIds.includes(
                                            Number(f.dataset_filter_column_id ?? f.dataset_column_id)
                                        )
                                )}
                                header={t('columns_header')}
                            />
                        </FieldSortingContext.Provider>
                    </Stack>
                    <Box flexShrink={0}>
                        <BlockEditPanelControls
                            onApply={handleApply}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                </Stack>
            )}
            {editField != null && (
                <>
                    {/*Field Edit Panel*/}
                    <FieldForm
                        field={editField}
                        onApply={handleFieldFormApply}
                        onCancel={() => {
                            setEditField(null);
                        }}
                        title={t('manage_filters.edit_title')}
                    />
                </>
            )}
        </>
    );
}
