import { useLayoutEffect, useState } from 'react';

export const ReadyClass = 'component-ready';
export default function useComponentReady(data: any, timeOut: number = 500) {
    const [componentReady, setComponentReady] = useState(false);
    useLayoutEffect(() => {
        if (!data) {
            return;
        }
        setTimeout(() => {
            document.body.classList.add(ReadyClass);
            setComponentReady(true);
        }, timeOut);
    }, [data]);
    return componentReady;
}
