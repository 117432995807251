import useBundleTranslation from 'i18n';
import React from 'react';

export function wallFormatDate(dt: string) {
    if (dt && typeof dt == 'string') {
        var m = dt.match(/(\d{4})\-(\d{2})\-(\d{2})\s(\d{1,2}):(\d{2}):(\d{2})/);
        if (m)
            return (
                '' +
                parseInt(m[2], 10) +
                '/' +
                parseInt(m[3], 10) +
                '/' +
                m[1] +
                ' ' +
                (parseInt(m[4], 10) % 12) +
                ':' +
                m[5] +
                (parseInt(m[4], 10) > 12 ? 'pm' : 'am')
            );
    }
    return '';
}

export function wallFormatDateExt(dt: string) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    if (dt && typeof dt == 'string') {
        let m = dt.match(/(\d{4})\-(\d{2})\-(\d{2})\s(\d{1,2}):(\d{2}):(\d{2})/);
        if (m) {
            let myDays = [
                t('format_date.day_sunday'),
                t('format_date.day_monday'),
                t('format_date.day_tuesday'),
                t('format_date.day_wednesday'),
                t('format_date.day_thursday'),
                t('format_date.day_friday'),
                t('format_date.day_saturday'),
                t('format_date.day_sunday'),
            ];
            let d = new Date(parseInt(m[1]), parseInt(m[2], 10) - 1, parseInt(m[3], 10));
            return myDays[d.getDay()] + ' ' + m[2] + '/' + m[3] + '/' + m[1];
        }
    }
    return '';
}

export function wallFormatDateTimeExt(dt: string) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    if (dt && typeof dt == 'string') {
        var m: any = dt.match(/(\d{4})\-(\d{2})\-(\d{2})\s(\d{1,2}):(\d{2}):(\d{2})/);
        if (m) {
            var myDays = [
                t('format_date.day_sunday'),
                t('format_date.day_monday'),
                t('format_date.day_tuesday'),
                t('format_date.day_wednesday'),
                t('format_date.day_thursday'),
                t('format_date.day_friday'),
                t('format_date.day_saturday'),
                t('format_date.day_sunday'),
            ];
            var d = new Date(parseInt(m[1]), parseInt(m[2], 10) - 1, parseInt(m[3], 10));

            let suffix = m[4] >= 12 ? t('format_date.suffix_pm') : t('format_date.suffix_am');
            //only -12 from hours if it is greater than 12 (if not back at mid night)
            m[4] = m[4] > 12 ? m[4] - 12 : m[4];
            //if 00 then it is 12 am
            m[4] = m[4] == '00' ? 12 : m[4];
            return myDays[d.getDay()] + ' ' + m[2] + '/' + m[3] + '/' + m[1] + ' at ' + m[4] + ':' + m[5] + suffix;
        }
    }
    return '';
}

export function wallFormatDateShort(dt: string) {
    if (dt && typeof dt == 'string') {
        var m = dt.match(/(\d{4})\-(\d{2})\-(\d{2})\s(\d{1,2}):(\d{2}):(\d{2})/);
        if (m) return '' + parseInt(m[2], 10) + '/' + parseInt(m[3], 10) + '/' + m[1];
    }
    return '';
}

export function wallFormatDateTime(dt: string) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    if (dt && typeof dt == 'string') {
        var m = dt.match(/(\d{4})\-(\d{2})\-(\d{2})\s(\d{1,2}):(\d{2}):(\d{2})/);
        if (m) {
            var myMonth = [
                t('format_date.month_jan'),
                t('format_date.month_feb'),
                t('format_date.month_mar'),
                t('format_date.month_apr'),
                t('format_date.month_may'),
                t('format_date.month_jun'),
                t('format_date.month_jul'),
                t('format_date.month_aug'),
                t('format_date.month_sep'),
                t('format_date.month_oct'),
                t('format_date.month_nov'),
                t('format_date.month_dec'),
            ];
            return (
                '' +
                myMonth[parseInt(m[2], 10) - 1] +
                ' ' +
                parseInt(m[3], 10) +
                ', ' +
                m[1] +
                ' ' +
                parseInt(m[4], 10) +
                ':' +
                m[5]
            );
        }
    }
    return '';
}

export function wallProcessAlertText(text: string, params: any) {
    text = text.replace(/\n/g, '<br />');
    let res = text.match(/(.*?)\:\s(.*?)\((.*?)\)/);
    if ('undefined' != typeof params && 'undefined' != typeof params.searchText && params.searchText) {
        let tmp = text.split(/<[^>]*>/g),
            searchBoxWords = params.searchText.split(' ');
        for (let k = 0; k < searchBoxWords.length; k++)
            if ('string' === typeof searchBoxWords[k]) searchBoxWords[k] = searchBoxWords[k].toLowerCase();

        if (tmp && tmp.length > 1) {
            if (searchBoxWords.length) {
                for (let k = 0; k < searchBoxWords.length; k++)
                    if (searchBoxWords[k]) {
                        let tmpText = '',
                            tmp = text.split(/<[^>]*>/g);

                        for (let i = 0; i < tmp.length; i++) {
                            tmpText = tmp[i].replace(
                                new RegExp('(' + searchBoxWords[k].replace(/[.*+?|()\[\]{}\\$^]/g, '\\$&') + ')', 'gi'),
                                '!@%^!@%^!@%^!@%^$1^%@!^%@!^%@!^%@!'
                            );
                            text = text.replace(tmp[i], tmpText);
                        }
                    }
            }

            text = text
                .replace(/!@\%\^!@\%\^!@\%\^!@\%\^/g, '<span class="wall_highlighted_text">')
                .replace(/\^\%@!\^\%@!\^\%@!\^\%@!/g, '</span>');
        } else {
            text = wallProcessText(text, params);
        }
    }
    return text;
}

export function wallProcessText(text: string | null, params: any) {
    if ('undefined' == typeof text || text == null) return '';
    text = text.replace(/\n/g, '<br />');

    if ('undefined' != typeof params.searchText && params.searchText) {
        let searchBoxWords = params.searchText.split(' ');
        for (let k = 0; k < searchBoxWords.length; k++)
            if ('string' === typeof searchBoxWords[k]) searchBoxWords[k] = searchBoxWords[k].toLowerCase();

        if (searchBoxWords.length) {
            for (let k = 0; k < searchBoxWords.length; k++)
                if (searchBoxWords[k])
                    text = text.replace(
                        new RegExp('(' + searchBoxWords[k].replace(/[.*+?|()\[\]{}\\$^]/g, '\\$&') + ')', 'gi'),
                        '!@%^!@%^!@%^!@%^$1^%@!^%@!^%@!^%@!'
                    );

            text = text
                .replace(/!@\%\^!@\%\^!@\%\^!@\%\^/g, '<span class="wall_highlighted_text">')
                .replace(/\^\%@!\^\%@!\^\%@!\^\%@!/g, '</span>');
        }
    }

    return text;
}

export function wallProcessMarkdownText(text: string, params: any) {
    //    text = text.replace(/\n\n/g,"<br />");

    if ('undefined' != typeof params.searchText && params.searchText) {
        var searchBoxWords = params.searchText.split(' ');
        for (var k = 0; k < searchBoxWords.length; k++)
            if ('string' === typeof searchBoxWords[k]) searchBoxWords[k] = searchBoxWords[k].toLowerCase();

        if (searchBoxWords.length) {
            for (var k = 0; k < searchBoxWords.length; k++)
                if (searchBoxWords[k])
                    text = text.replace(
                        new RegExp('(' + searchBoxWords[k].replace(/[.*+?|()\[\]{}\\$^]/g, '\\$&') + ')', 'gi'),
                        '!@%^!@%^!@%^!@%^$1^%@!^%@!^%@!^%@!'
                    );

            text = text
                .replace(/!@\%\^!@\%\^!@\%\^!@\%\^/g, '<span class="wall_highlighted_text">')
                .replace(/\^\%@!\^\%@!\^\%@!\^\%@!/g, '</span>');
        }
    }

    return text;
}
