import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AlertSubscriptionPopup from 'components/alert-subscriptions/AlertSubscriptionPopup';
import IconMi from 'components/common/icon/IconMi';
import { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';
import useBundleTranslation from 'i18n';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';
import { ElementAlertStatus } from '../index';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';

export default function AlertSubscriptionIcon({
    elementId,
    segmentValueId,
    elementType,
    bookmarksContext,
    isActionMenuItem,
    alertStatus,
}: {
    elementId: number;
    segmentValueId: number;
    elementType?: string;
    bookmarksContext?: IBookmarksContext;
    isActionMenuItem?: boolean;
    alertStatus?: ElementAlertStatus;
}) {
    //ToDo prepare icon tooltip AlertRule.prototype.prepareTextForIconTooltip
    const [showAlertSubscriptionPopup, setShowAlertSubscriptionPopup] = useState<boolean>(false);
    const isReverseLink = elementType != 'internal report';
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);
    const [alertStatusData, setAlertStatusData] = useState<ElementAlertStatus>();

    const pageHash = window.location.hash;

    useEffect(() => {
        if (alertStatus) {
            setAlertStatusData(alertStatus);
        }
        if (pageHash === '#show_alert_wizard') {
            setShowAlertSubscriptionPopup(true);
        }
    }, []);

    const hasIcon = useEmbeddingContextVisible('iNotification');
    if (!hasIcon) {
        return null;
    }

    const getElementTypeLabel = () => {
        let elTypeTranslation = '';
        switch (elementType) {
            case 'external report':
            case 'internal report':
                elTypeTranslation = 'notification';
                break;
            default:
                elTypeTranslation = 'alerts';
                break;
        }
        return elTypeTranslation;
    };

    const onClickAlerts = () => {
        setShowAlertSubscriptionPopup(true);
    };

    const prepareTextForIconTooltip = () => {
        const alertStatus = alertStatusData;
        let text = '';
        if (alertStatus) {
            const immediate_count = Number(alertStatus.immediate_count);
            const digest_count = Number(alertStatus.digest_count);
            const sms_count = Number(alertStatus.sms_count);
            const browser_count = Number(alertStatus.browser_count);
            const total_count = immediate_count + digest_count + sms_count + browser_count;

            const notification = alertStatus.email_notification_enabled_ind;
            const subscribed = total_count > 0 ? 'Y' : 'N';
            const digest = alertStatus.send_alerts_email_ind === 'Y' && digest_count > 0 ? 'Y' : 'N';
            const immediate =
                alertStatus.immediate_alerting_enabled_ind === 'Y' && immediate_count + sms_count > 0 ? 'Y' : 'N';
            const isDatasetReport = alertStatus.dataset_report;
            const folder_set_ind = alertStatus.folder_set_ind;
            const element_type = alertStatus.element_type;

            switch (notification + subscribed + digest + immediate) {
                case 'NYNN':
                case 'NYYN':
                case 'NYYY':
                case 'NYNY':
                    text = t('alert_btn_tooltip.alert_alerts_only_visible_in_browser');
                    break;
                case 'NNNN':
                case 'NNYN':
                case 'NNYY':
                case 'NNNY':
                    text = t('alert_btn_tooltip.alert_all_notifications_are_off');
                    break;
                case 'YNNN':
                case 'YNYN':
                case 'YNYY':
                case 'YNNY':
                    text = t('alert_btn_tooltip.alert_no_alert_subscriptions');
                    break;
                case 'YYNN':
                    text = t('alert_btn_tooltip.alert_alert_notifications_not_enabled');
                    break;
                case 'YYNY':
                    text = t('alert_btn_tooltip.alert_immediate_alerts_are_enabled');
                    break;
                case 'YYYY':
                    text = t('alert_btn_tooltip.alert_immediate_and_digest_alerts_are_enabled');
                    break;
                case 'YYYN':
                    text = t('alert_btn_tooltip.alert_digest_alerts_are_enabled');
                    break;
            }

            var isReport = ['internal report', 'external report', 'multi-metric chart'].includes(element_type);
            if (isReport) {
                text = isDatasetReport
                    ? t('alert_btn_tooltip.alert_no_report_notifications')
                    : t('alert_btn_tooltip.alert_no_enabled_notification_subscriptions');
                if (notification == 'N') {
                    text = t('alert_btn_tooltip.alert_all_notifications_are_off');
                } else {
                    if (digest == 'Y' || immediate == 'Y' || folder_set_ind == 'Y') {
                        text = t('alert_notifications_are_enabled');
                    }
                }
            }
        } else {
            text = t('alert_subscription.alerts');
        }
        return text;
    };

    const prepareIcon = () => {
        const alertStatus = alertStatusData;
        const isMetric = 'metric' === elementType;
        let iconType = isMetric ? 'alerts' : 'mail';
        if (alertStatus && isMetric) {
            if (alertStatus.enabled == 'Y' && alertStatus.send == 'Y') {
                iconType = 'notification-active';
            }
        }
        return iconType;
    };

    return (
        <>
            {isActionMenuItem ? (
                <ActionMenuItem
                    label={t(`alert_subscription.${getElementTypeLabel()}`)}
                    desc={t(`alert_subscription.${getElementTypeLabel()}_desc`)}
                    icon={prepareIcon()}
                    onClick={onClickAlerts}
                />
            ) : (
                <Tooltip title={prepareTextForIconTooltip()}>
                    <IconButton sx={{ p: '6px' }} onClick={onClickAlerts}>
                        <IconMi icon={prepareIcon()} fontSize="16" />
                    </IconButton>
                </Tooltip>
            )}

            {showAlertSubscriptionPopup && (
                <AlertSubscriptionPopup
                    elementId={elementId}
                    segmentId={segmentValueId}
                    reverseLink={isReverseLink}
                    bookmarksContext={bookmarksContext}
                    onClose={() => {
                        setShowAlertSubscriptionPopup(false);
                    }}
                    onUpdateAlertStatusData={(newData) => {
                        if (alertStatusData) {
                            setAlertStatusData({ ...alertStatusData, ...newData });
                        }
                    }}
                />
            )}
        </>
    );
}
