import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AlertColor } from '@mui/material/Alert';

import { formAPI } from 'api/form';
import StaticInfo from './StaticInfo';
import { IconType } from 'components/common/icon/IconHandler';

interface IProps {
    type?: AlertColor;
    refreshUrl: string;
    refreshInterval?: number;
    icon?: IconType | boolean;
    variant?: any;
    align?: string;
}

interface IntervalResponse {
    data: {
        data: string;
        status: 'OK' | 'ERROR';
    };
}

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;

const IntervalStaticInfo: React.FC<IProps> = ({
    type,
    refreshInterval = ONE_MINUTE_IN_MILLISECONDS,
    refreshUrl,
    icon,
    variant,
    align,
}) => {
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

    const { data: response, refetch } = useQuery<IntervalResponse, Error>(['fetchIntervalStaticInfo'], () =>
        refreshUrl ? formAPI.load(refreshUrl) : {}
    );

    useEffect(() => {
        if (refreshUrl && !timerId) {
            const id = setInterval(() => refetch(), refreshInterval);
            setTimerId(id);
        }

        return () => {
            timerId && clearInterval(timerId);
        };
    }, [refreshUrl]);

    return response?.data?.data ? (
        <Box>
            <StaticInfo type={type} label={response.data.data ?? ''} icon={icon} variant={variant} align={align} />
        </Box>
    ) : null;
};

export default IntervalStaticInfo;
