import {
    ToggleButton,
    ToggleButtonGroup,
    Box,
    Stack,
    alpha,
    Button,
    ClickAwayListener,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import {
    IntervalChartChartingInterval,
    MetricChartChartingInterval,
    MetricChartStateRange,
    MetricChartViewsMode,
} from 'components/metric/chart';
import { MetricChartTools } from 'components/metric/chart/metricChartTools';
import { ElementDisplayContextType } from 'components/metric/chart/MetricChartWrapper';
import { Chart as HChart } from 'highcharts';
import moment from 'moment';
import IconMi from 'components/common/icon/IconMi';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import DatePicker from 'components/common/date-picker/DatePicker';
import Moment from 'moment/moment';

export default function MetricChartTopBar({
    views,
    actualView,
    onViewChange,
    chartingIntervals,
    selectedInterval,
    maxXValue,
    minXValue,
    setSelectedInterval,
    elementDisplayType = 'viewer',
    chart,
    measurementIntervalUnit,
}: {
    views: Array<MetricChartViewsMode>;
    actualView: MetricChartViewsMode;
    onViewChange: (view: MetricChartViewsMode) => void;
    chartingIntervals: Array<MetricChartChartingInterval>;
    selectedInterval?: MetricChartStateRange | null;
    setSelectedInterval: (interval: MetricChartChartingInterval) => void;
    measurementIntervalUnit: string;
    maxXValue: number;
    minXValue: number;
    elementDisplayType?: ElementDisplayContextType;
    chart: HChart;
}) {
    const { t } = useBundleTranslation(['components/metric/chart']);

    const allowedRanges: Array<IntervalChartChartingInterval> = chartingIntervals.filter((interval) => {
        if (maxXValue == 0 || interval.chartingIntervalUnit == 'custom') {
            return false;
        }
        return (
            MetricChartTools.calcChartingIntervalRange(
                maxXValue,
                interval.chartingIntervalUnit,
                -interval.chartingIntervalValue
            ) >= minXValue
        );
    }) as Array<IntervalChartChartingInterval>;

    const [selectedIntervalId, setSelectedIntervalId] = useState<number>(0);

    useEffect(() => {
        if (!selectedInterval) {
            return;
        }

        if (selectedInterval.interval_unit == 'custom') {
            // TODO: add calendar
            return;
        }

        const interval = chartingIntervals.find(
            (i) =>
                i.chartingIntervalUnit != 'custom' &&
                i.chartingIntervalValue == selectedInterval.interval_value &&
                i.chartingIntervalUnit == selectedInterval.interval_unit
        ) as IntervalChartChartingInterval | undefined;
        if (!interval) {
            return;
        }
        setSelectedIntervalId(interval.chartingIntervalId);
    }, [selectedInterval]);

    const handleChange = (event: React.MouseEvent<HTMLElement> | null, intervalId: number) => {
        const interval = chartingIntervals.find(
            (i) => i.chartingIntervalUnit != 'custom' && i.chartingIntervalId == intervalId
        );
        if (!interval) {
            return false;
        }
        setSelectedInterval(interval);
    };

    const handleCalendarApply = (startDate: Date, endDate: Date) => {
        setIsCalendarVisible(false);
        setSelectedInterval({
            chartingIntervalUnit: 'custom',
            minValue: moment(startDate).unix() * 1000,
            maxValue: moment(endDate).unix() * 1000,
        });
    };

    if (elementDisplayType == 'screenshot') {
        return null;
    }

    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const hasTime = ['minute', 'hour'].includes(measurementIntervalUnit);

    return (
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2, mt: 1 }}>
            <Stack direction="row">
                <ToggleButtonGroup color="primary" value={selectedIntervalId} exclusive onChange={handleChange}>
                    {allowedRanges.map((interval) => {
                        return (
                            <ToggleButton value={interval.chartingIntervalId} key={interval.chartingIntervalId}>
                                {interval.chartingIntervalValue + interval.chartingIntervalUnit[0]}
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
                <ChartCalendar
                    chart={chart}
                    hasTime={hasTime}
                    isCalendarVisible={isCalendarVisible}
                    onOpen={() => setIsCalendarVisible(true)}
                    onClose={() => setIsCalendarVisible(false)}
                    onApply={handleCalendarApply}
                />
            </Stack>
            {elementDisplayType == 'viewer' && (
                <Box>
                    <ToggleButtonGroup
                        color="primary"
                        value={actualView}
                        exclusive
                        onChange={(event, v) => {
                            if (v !== null) onViewChange(v);
                        }}
                    >
                        {views.map((v) => (
                            <ToggleButton value={v} key={v}>
                                {t(`view.${v}`)}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
            )}
        </Stack>
    );
}

function ChartCalendar({
    chart,
    isCalendarVisible,
    onApply,
    onClose,
    onOpen,
    hasTime,
}: {
    chart: HChart;
    isCalendarVisible: boolean;
    hasTime: boolean;
    onOpen: () => void;
    onApply: (startDate: Date, endDate: Date) => void;
    onClose: () => void;
}) {
    const format = hasTime ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD';
    const [extremes, setExtremes] = useState(chart.xAxis[0].getExtremes());

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [maxAllowed, setMaxAllowed] = useState<Date | null>(null);
    const [minAllowed, setMinAllowed] = useState<Date | null>(null);

    const { t } = useBundleTranslation(['components/metric/chart']);

    useEffect(() => {
        if (extremes.userMin) {
            setStartDate(new Date(extremes.userMin));
        }
        if (extremes.userMax) {
            setEndDate(new Date(extremes.userMax));
        }
        setMaxAllowed(new Date(extremes.dataMax));
        setMinAllowed(new Date(extremes.dataMin));
    }, [extremes]);

    const [selectedCalendar, setSelectedCalendar] = useState<'start' | 'end'>('start');

    const [textStartValue, setTextStartValue] = useState<string>('');
    useEffect(() => setTextStartValue(moment(startDate).format(format)), [startDate]);
    const handleStartInputChange = (value: any) => {
        const newValue = value.target.value;
        setTextStartValue(newValue);
        const newDate = moment(newValue, format);
        if (newDate.isValid() && newValue.length == 10 && newDate.isBetween(moment(minAllowed), moment(endDate))) {
            setStartDate(newDate.toDate());
        }
    };

    const [textEndValue, setTextEndValue] = useState<string>('');
    useEffect(() => setTextEndValue(moment(endDate).format(format)), [endDate]);
    const handleEndInputChange = (value: any) => {
        const newValue = value.target.value;
        setTextEndValue(newValue);
        const newDate = moment(newValue, format);
        if (newDate.isValid() && newValue.length == 10 && newDate.isBetween(moment(startDate), moment(maxAllowed))) {
            setEndDate(newDate.toDate());
        }
    };

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    onClose();
                }}
            >
                <Box style={{ position: 'relative' }}>
                    <Button
                        variant="outlined"
                        color="neutral"
                        sx={{ ml: 1 }}
                        className={'min-width--icon'}
                        onClick={() => onOpen()}
                    >
                        <IconMi icon={'calendar'} fontSize={'16'} />
                    </Button>
                    {isCalendarVisible && (
                        <Box
                            sx={{
                                backgroundColor: (theme) => theme.palette.background.default,
                                borderRadius: 1,
                                boxShadow: (theme) => `0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)}`,
                                color: (theme) => alpha(theme.palette.text.primary, 0.8),
                                position: 'absolute',
                                zIndex: 1,
                                '.custom-datepicker__react-datepicker-popper': { zIndex: 1 },
                                '.custom-datepicker__calendar': { boxShadow: 'none !important' },
                                p: 1,
                            }}
                        >
                            <Stack direction={'row'} sx={{ mb: 1 }}>
                                <Stack direction={'row'} flexGrow={1}>
                                    <StaticAddon sx={{ px: 0.75, mr: 0 }}>{t('calendar_range.from')}</StaticAddon>
                                    <Box flexGrow={1}>
                                        <TextField
                                            fullWidth
                                            onClick={() => setSelectedCalendar('start')}
                                            value={textStartValue}
                                            onChange={(value) => handleStartInputChange(value)}
                                            sx={{
                                                input: { pl: 0.625, pr: 0 },
                                                '.MuiInput-root': {
                                                    borderRadius: 0,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Stack>

                                <Stack direction={'row'} flexGrow={1}>
                                    <StaticAddon
                                        sx={{
                                            px: 0.75,
                                            mr: 0,
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            borderLeft: 0,
                                        }}
                                    >
                                        {t('calendar_range.to')}
                                    </StaticAddon>
                                    <Box flexGrow={1}>
                                        <TextField
                                            fullWidth
                                            onClick={() => setSelectedCalendar('end')}
                                            value={textEndValue}
                                            onChange={(value) => handleEndInputChange(value)}
                                            sx={{
                                                input: { pl: 0.625, pr: 0 },
                                                '.MuiInput-root': {
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </Stack>

                            {selectedCalendar == 'start' ? (
                                <DatePicker
                                    dateFormat={format}
                                    inline
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    maxDate={endDate}
                                    minDate={minAllowed}
                                    data-test={'chart-date-picker-from'}
                                    todayButton={undefined}
                                    showTimeSelect={hasTime}
                                    minTime={
                                        minAllowed &&
                                        Moment(minAllowed).format('yyyy-MM-DD') ==
                                            Moment(startDate).format('yyyy-MM-DD')
                                            ? minAllowed
                                            : new Date(new Date().setHours(0, 0, 0, 0))
                                    }
                                    maxTime={
                                        endDate &&
                                        Moment(endDate).format('yyyy-MM-DD') == Moment(startDate).format('yyyy-MM-DD')
                                            ? endDate
                                            : new Date(new Date().setHours(23, 59, 0, 0))
                                    }
                                />
                            ) : (
                                <DatePicker
                                    dateFormat={format}
                                    inline
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    minDate={startDate}
                                    maxDate={maxAllowed}
                                    data-test={'chart-date-picker-to'}
                                    todayButton={undefined}
                                    showTimeSelect={hasTime}
                                    minTime={
                                        startDate &&
                                        Moment(startDate).format('yyyy-MM-DD') == Moment(endDate).format('yyyy-MM-DD')
                                            ? startDate
                                            : new Date(new Date().setHours(0, 0, 0, 0))
                                    }
                                    maxTime={
                                        maxAllowed &&
                                        Moment(maxAllowed).format('yyyy-MM-DD') ==
                                            Moment(startDate).format('yyyy-MM-DD')
                                            ? maxAllowed
                                            : new Date(new Date().setHours(23, 59, 0, 0))
                                    }
                                />
                            )}
                            <Box sx={{ mt: 1 }}>
                                <Button onClick={() => onApply(startDate as Date, endDate as Date)}>
                                    {t('calendar_range.apply_btn')}
                                </Button>
                                <Box component={'span'} sx={{ ml: 1.5, mr: 0.6 }}>
                                    {t('calendar_range.or')}
                                </Box>
                                <Button onClick={() => onClose()} variant={'text'} sx={{ px: 1, minWidth: 'auto' }}>
                                    {t('calendar_range.cancel_btn')}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </ClickAwayListener>
        </>
    );
}
