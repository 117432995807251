import { FormLabel, FormControl, Grid, Box, Tooltip } from '@mui/material';
import { WrapFormElementProps } from './index';
import { getFromComponentPropsClass } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import showdown from 'showdown';

const converter = new showdown.Converter();
export default function FormWrapperElementLabel({ elementProps, children, componentValues }: WrapFormElementProps) {
    let className = getFromComponentPropsClass(elementProps, componentValues);

    const { t } = useBundleTranslation([elementProps.translationNS ?? 'components/common/form/form']);
    const label = elementProps.componentProps.translate
        ? t(elementProps.componentProps.label as string)
        : elementProps.componentProps.label;

    return (
        <Grid className={className} item xs={elementProps.componentProps.xs}>
            <FormControl fullWidth>
                {label != '' && (
                    <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="span" className={elementProps.componentProps.labelClass}>
                            {label}
                        </Box>
                        {elementProps.componentProps?.tooltip?.icon && (
                            <Tooltip
                                title={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: converter.makeHtml(
                                                String(t(elementProps.componentProps.tooltip.icon))
                                            ),
                                        }}
                                    ></span>
                                }
                                placement={'top-start'}
                            >
                                <Box sx={{ display: 'inline-flex', ml: 0.5, color: 'primary.main' }}>
                                    <IconMi icon="info-circle" fontSize="16" />
                                </Box>
                            </Tooltip>
                        )}
                    </FormLabel>
                )}
                {children}
            </FormControl>
        </Grid>
    );
}
