import { AssocArray } from 'tools/types';
import { Data, Options, Node as visNode, IdType, Edge } from 'vis-network/declarations/network/Network';

export interface LineageDrawActionResponse {
    externalIcons: AssocArray<string>;
    lineageDiagramConfig: LineageDiagramConfig;
    maxTotalNodes: number;
    lineageDiagramData: LineageData;
}

export interface LineageDiagramConfig {
    direction: Orientation;
    showSetting: boolean;
}

export interface LineageProps {
    showLineage: boolean;
    onHide: () => void;
    id: string;
    type: string;
}

export interface LineageNode extends visNode {
    params: {
        id: IdType;
        pluginName?: string;
        owners?: {
            bo_dn: string | null;
            bo_email: string | null;
            to_dn: string | null;
            to_email: string | null;
            dst_dn: string | null;
            dst_email: string | null;
        };
        showDescription?: boolean;
        descriptionUsesMarkdown?: boolean;
        description?: string;
        certified?: string;
        certifiedLevelName?: string;
        certifiedColor?: string;
        engagement?: number;
        tags?: Array<TagDefinition>;
        tableDatasetId?: number;
        type?: string;
        initLabel?: string;
        data?: {
            children: {
                [key: string]: string;
            };
        };
    };
    level: number;
    id: IdType;
}

export interface TagDefinition {
    tags: Array<{
        name: string;
        node_type: string;
        node_id: string | number;
    }>;
    topic_type_name: string;
    use_icon: string | null;
    icon_type: string | null;
    icon: string | null;
    fa_icon: string | null;
}

export interface LineageEdge extends Edge {
    childGroup: string;
    from: IdType;
    to: IdType;
}

export interface LineageData extends Data {
    nodes: LineageNode[];
    edges: LineageEdge[];
}

export interface VisNetworkProps {
    data: LineageData;
    options: Options;
    nodesInfo: {
        [key: string]: { childCount: { [key: string]: number }; parentIds: Array<IdType> };
    };
    initNodes: LineageNode[];
    showLegend: boolean;
    setShowLegend: (value: boolean) => void;
    orientation: Orientation;
    setOrientation: (value: Orientation) => void;
    focusThisNode: (nodeId: IdType, nodeType: string) => void;
    maxTotalNodes: number;
    externalIcons: AssocArray<string>;
    previousNode: RootNode | null;
    setPreviousNode: (value: RootNode | null) => void;
}

export interface CollapsedInfo {
    [key: string]: IdType[];
}

export interface RootNode {
    name?: string;
    group?: string;
    id: IdType;
}

export interface LineageMenuProps {
    showMenu: boolean;
    handleClose: () => void;
    position: MenuPosition;
    selectedNode?: LineageNode;
    isChildrenCollapsable: (nodeId: IdType) => boolean;
    parentNodeId?: IdType;
    isNodeCollapsable: (nodeId: IdType) => boolean;
    isChildrenExpandable: (nodeId: IdType) => boolean;
    collapseChildNodes: (nodeId: IdType) => void;
    collapseThisNode: (nodeId: IdType) => void;
    expandChildNodes: (nodeId: IdType) => void;
    focusThisNode: () => void;
    focusChildNode: (nodeId: IdType) => void;
    menuAnchor: HTMLElement | null;
    externalIcons: AssocArray<string>;
}

export interface NodesInfoObject {
    [key: string]: NodeInfo;
}

export interface NodeInfo {
    childCount: { [key: string]: number };
    parentIds: Array<IdType>;
}

export interface Node {
    name: string;
    id: string;
}

export type Orientation = 'UD' | 'LR';

export type MenuPosition = { vertical: number; horizontal: number };

export const getPluginSlugByName = (pluginName: string) => {
    return pluginName.toLowerCase().replace(' ', '_');
};

export const getLineageType = (type: string) => {
    let lineageType = type;

    if (type === 'internal report') {
        lineageType = 'report';
    }
    if (type === 'external report') {
        lineageType = 'external-report';
    }
    if (type === 'other external content') {
        lineageType = 'external-content';
    }
    if (type === 'multi-metric chart') {
        lineageType = 'multi-metric';
    }

    return lineageType;
};
