import React, { createContext, Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'i18n/i18n';

import { Provider } from 'react-redux';
import { store } from 'store/store';
import initShared from 'shared';

if (document.getElementById('mi-react-root')) {
    const root = createRoot(document.getElementById('mi-react-root')!);

    root.render(
        <Provider store={store}>
            <Suspense>
                <App />
            </Suspense>
        </Provider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initShared();
