import { setInstalledFont } from 'store/fontSlice';

export const useInstallFont = async (
    fontId: string | null | undefined,
    fileName: string,
    fontList: any,
    dispatch: any
) => {
    //console.log('Init Load Font #' + fontId);
    if (!fontId) return true;
    if (!fontList.includes(fontId)) {
        const font = new FontFace(`CustomFont_${fontId}`, `url(/data/content/font/${fontId}/${fileName})`);
        document.fonts.add(font);
        await font
            .load()
            .then(() => {
                //console.log(`Font #${fontId} ${font.status}`);
                dispatch(setInstalledFont(fontId));
            })
            .catch((error) => {
                console.log(`Font #${fontId} Load Error: "${error}"`);
            });
    }
    return true;
};
