import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';

import React, { useState } from 'react';

import useBundleTranslation from 'i18n';

import IconMi from 'components/common/icon/IconMi';
import { Button } from '@mui/material';

import { editorExternalReferenceAPI } from 'api/editor/external-reference';
import { APIResponse } from 'tools/types';
import { ReferenceInfoResponse } from 'components/external-reference-selection';
import { FormComponentBuilder } from 'components/common/form/layout';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useQueryClient } from '@tanstack/react-query';

export interface FilterUsageRefreshButtonControlProps extends FormControlProps {
    profileId: number;
    referenceId: number;
    filterId: number;
    gridToRefresh: string;
}

export default function FilterUsageRefreshButton({
    controlProps,
}: FormElementControlPropsType<FilterUsageRefreshButtonControlProps>) {
    const { t } = useBundleTranslation(['components/external-reference/editor/reference_external_filters_manager']);

    // Display Loading Mask
    const [isCollectionRunning, setIsCollectionRunning] = useState<boolean>(false);

    const handleRefreshAutoFilters = () => {
        setIsCollectionRunning(true);
        editorExternalReferenceAPI
            .refreshFilterValues(controlProps.profileId, controlProps.referenceId, controlProps.filterId)
            .then((response: APIResponse<ReferenceInfoResponse>) => {
                reloadGridData();
            })
            .finally(() => {
                setIsCollectionRunning(false);
            });
    };

    const queryClient = useQueryClient();
    const reloadGridData = () => {
        queryClient.invalidateQueries([controlProps.gridToRefresh]);
    };

    return (
        <>
            {isCollectionRunning && <LoadingPlaceholder />}
            <Button
                startIcon={<IconMi icon={'refresh-cw'} />}
                variant={'outlined'}
                onClick={() => {
                    handleRefreshAutoFilters();
                }}
            >
                {t('refresh_filter_values')}
            </Button>
        </>
    );
}

export class FilterUsageRefreshButtonBuilder extends FormComponentBuilder {
    prepareProps(): FilterUsageRefreshButtonControlProps {
        return {
            ...this.controlProps,
            profileId: Number(this.elementProps.componentProps?.profileId ?? 0),
            referenceId: Number(this.elementProps.componentProps?.referenceId ?? 0),
            filterId: Number(this.elementProps.componentProps?.filterId ?? 0),
            gridToRefresh: this.elementProps.componentProps?.gridToRefresh ?? '',
        };
    }
}
