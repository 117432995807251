import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import { Box, FormControlLabel, FormHelperText, Switch, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import useBundleTranslation from 'i18n';

export interface CertificationControlProps extends FormControlProps {
    certifiedByField: string;
    certifiedOnField: string;
}

export default function CertificationControl({
    controlProps,
    elementProps,
}: FormElementControlPropsType<CertificationControlProps>) {
    const { t } = useBundleTranslation(['app/editor/element/certification-control']);

    const certified = controlProps.value === 'Y';
    const certifiedBy = controlProps.form.hookFormWatch(controlProps.certifiedByField);
    const certifiedOn = controlProps.form.hookFormWatch(controlProps.certifiedOnField);

    const label = useMemo(() => {
        if (certified && certifiedBy && certifiedOn) {
            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('certified_by', { certifiedBy: certifiedBy, certifiedOn: certifiedOn }),
                    }}
                />
            );
        }

        return (
            <Typography className={controlProps.labelClass}>{t(certified ? 'certified' : 'uncertified')}</Typography>
        );
    }, [certified, certifiedBy, certifiedOn]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        controlProps.form.hookFormSetValue(controlProps.name, value ? 'Y' : 'N', { shouldDirty: true });
    };

    return (
        <>
            <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                    <Switch
                        data-test={controlProps.uid}
                        checked={certified}
                        color={'success'}
                        onChange={onChange}
                        disabled={elementProps?.componentProps.disabled}
                    />
                }
                label={
                    <Box sx={{ pt: '5px' }} className={controlProps.labelClass}>
                        {label}
                        {certified && <FormHelperText sx={{ mt: 0 }}>{t('helper')}</FormHelperText>}
                    </Box>
                }
            />
        </>
    );
}

export class CertificationControlBuilder extends FormComponentBuilder {
    prepareProps(): CertificationControlProps {
        return {
            ...this.controlProps,
            certifiedByField: this.elementProps.componentProps?.certifiedByField,
            certifiedOnField: this.elementProps.componentProps?.certifiedOnField,
        };
    }
}
