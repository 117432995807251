import { alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export const MuiButtonConfig = {
    defaultProps: {
        variant: 'contained',
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
    },
    variants: [
        //Contained
        {
            props: { variant: 'contained', color: 'primary' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        backgroundColor: theme.componentSettings.primaryButton.primaryColor,
                        color: theme.componentSettings.primaryButton.textColor,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.componentSettings.primaryButton.primaryColor, 0.8)),
                        '&:not(:active)>svg': {
                            color: 'inherit',
                        },
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.8),
                            theme.palette.text.primary
                        ),
                    },
                };
            },
        },
        {
            props: { variant: 'contained', color: 'error' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.8)),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(
                            alpha(theme.palette.error.main, 0.8),
                            theme.palette.text.primary
                        ),
                    },
                };
            },
        },
        {
            props: { variant: 'contained', color: 'success' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.8)),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(
                            alpha(theme.palette.success.main, 0.8),
                            theme.palette.text.primary
                        ),
                    },
                };
            },
        },
        {
            props: { variant: 'contained', color: 'neutral' },
            style: ({ theme }: { theme: any }) => {
                return {
                    backgroundColor: theme.palette.text.primary,
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.8)),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.9)),
                    },
                };
            },
        },
        //Outlined
        {
            props: { variant: 'outlined', color: 'primary' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        borderColor: theme.componentSettings.primaryButton.primaryColor,
                        color: theme.componentSettings.primaryButton.primaryColor,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.04)
                        ),
                        borderColor: useBlendColors(alpha(theme.componentSettings.primaryButton.primaryColor, 0.8)),
                        color: alpha(theme.componentSettings.primaryButton.primaryColor, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.24)
                        ),
                        borderColor: theme.componentSettings.primaryButton.primaryColor,
                        color: theme.componentSettings.primaryButton.primaryColor,
                    },
                };
            },
        },
        {
            props: { variant: 'outlined', color: 'success' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        borderColor: theme.palette.success.main,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.04)),
                        borderColor: useBlendColors(alpha(theme.palette.success.main, 0.8)),
                        color: alpha(theme.palette.success.main, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.24)),
                        borderColor: theme.palette.success.main,
                        color: theme.palette.success.main,
                    },
                    '&:hover>svg, &:active>svg': {
                        color: 'inherit',
                    },
                };
            },
        },
        {
            props: { variant: 'outlined', color: 'error' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        borderColor: theme.palette.error.main,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.04)),
                        borderColor: useBlendColors(alpha(theme.palette.error.main, 0.8)),
                        color: alpha(theme.palette.error.main, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.24)),
                        borderColor: theme.palette.error.main,
                        color: theme.palette.error.main,
                    },
                };
            },
        },
        {
            props: { variant: 'outlined', color: 'neutral' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        color: theme.palette.text.primary,
                        borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.4)),
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.04)),
                        borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                        color: alpha(theme.palette.text.primary, 0.8),
                    },
                    '&:active, &.active-state': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                        borderColor: theme.palette.text.primary,
                        color: theme.palette.text.primary,
                    },
                };
            },
        },
        //Light
        {
            props: { variant: 'light', color: 'primary' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        color: theme.componentSettings.primaryButton.primaryColor,
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.08)
                        ),
                        border: `1px solid ${theme.componentSettings.primaryButton.primaryColor}`,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.04)
                        ),
                        borderColor: useBlendColors(alpha(theme.componentSettings.primaryButton.primaryColor, 0.8)),
                        color: alpha(theme.componentSettings.primaryButton.primaryColor, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.24)
                        ),
                        borderColor: theme.componentSettings.primaryButton.primaryColor,
                        color: theme.componentSettings.primaryButton.primaryColor,
                    },
                };
            },
        },
        {
            props: { variant: 'light', color: 'success' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        color: theme.palette.success.main,
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.08)),
                        border: `1px solid ${theme.palette.success.main}`,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.04)),
                        borderColor: useBlendColors(alpha(theme.palette.success.main, 0.8)),
                        color: alpha(theme.palette.success.main, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.24)),
                        borderColor: theme.palette.success.main,
                        color: theme.palette.success.main,
                    },
                    '&:hover>svg, &:active>svg': {
                        color: 'inherit',
                    },
                };
            },
        },
        {
            props: { variant: 'light', color: 'error' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        color: theme.palette.error.main,
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.08)),
                        border: `1px solid ${theme.palette.error.main}`,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.04)),
                        borderColor: useBlendColors(alpha(theme.palette.error.main, 0.8)),
                        color: alpha(theme.palette.error.main, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.24)),
                        borderColor: theme.palette.error.main,
                        color: theme.palette.error.main,
                    },
                    '&:hover>svg, &:active>svg': {
                        color: 'inherit',
                    },
                };
            },
        },
        {
            props: { variant: 'light', color: 'neutral' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&': {
                        color: theme.palette.text.primary,
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.08)),
                        border: `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.4))}`,
                    },
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.04)),
                        borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                        color: alpha(theme.palette.text.primary, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                        borderColor: theme.palette.text.primary,
                        color: theme.palette.text.primary,
                    },
                };
            },
        },
        //Text
        {
            props: { variant: 'text', color: 'primary' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.04)
                        ),
                        color: alpha(theme.componentSettings.primaryButton.primaryColor, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.24)
                        ),
                        color: theme.componentSettings.primaryButton.primaryColor,
                    },
                };
            },
        },
        {
            props: { variant: 'text', color: 'success' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.04)),
                        color: alpha(theme.palette.success.main, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.24)),
                        color: theme.palette.success.main,
                    },
                };
            },
        },
        {
            props: { variant: 'text', color: 'error' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.04)),
                        color: alpha(theme.palette.error.main, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.24)),
                        color: theme.palette.error.main,
                    },
                };
            },
        },
        {
            props: { variant: 'text', color: 'neutral' },
            style: ({ theme }: { theme: any }) => {
                return {
                    '&:hover': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.04)),
                        color: alpha(theme.palette.text.primary, 0.8),
                    },
                    '&:active': {
                        backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                        color: theme.palette.text.primary,
                    },
                };
            },
        },
    ],
    styleOverrides: {
        root: ({ theme }: { ownerState: any; theme: any }) => {
            return {
                borderRadius: theme.componentSettings.button.borderRadius + 'px',
                boxShadow: 'none',
                '&:hover, &:active': {
                    boxShadow: 'none',
                },
                '&:focus': {
                    outline: 'none',
                    ':not(:hover):not(:active)': {
                        // boxShadow: '0 0 0 1px red',
                    },
                },
                '&:active>svg, &:hover>svg': {
                    //color: theme.palette.text.primary,
                },
                '&.Mui-focusVisible': {
                    outline: 'none',
                },
            };
        },
        sizeMedium: ({ theme }: { theme: any }) => {
            return {
                padding: '3px 12px',
                minHeight: '28px',
                minWidth: '80px',
                '& > .svg-inline--fa, & > .svg-icon-mi': {
                    margin: '0 -12px',
                },
                '&.min-width--icon': {
                    //  className={"min-width--icon"}
                    minWidth: theme.size.pxValue(theme.size.defaultHeight),
                },
                '&.min-width--reset': {
                    //  className={"min-width--reset"}
                    minWidth: 'auto',
                },
            };
        },
        iconSizeMedium: {
            '& .svg-inline--fa': {
                fontSize: 14,
            },
            '& .svg-icon-mi': {
                fontSize: 16,
            },
        },
        contained: ({ theme }: { ownerState: any; theme: any }) => {
            return {
                border: '0',
                '&.Mui-disabled': {
                    backgroundColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    color: theme.palette.text.opposite,
                },
            };
        },
        outlined: ({ theme }: { ownerState: any; theme: any }) => {
            return {
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    '.svg-inline--fa': {
                        color: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    },
                },
            };
        },
        light: ({ theme }: { ownerState: any; theme: any }) => {
            return {
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    '.svg-inline--fa': {
                        color: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    },
                },
            };
        },
        text: ({ theme }: { ownerState: any; theme: any }) => {
            return {
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    '.svg-inline--fa': {
                        color: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    },
                },
            };
        },
    },
};
