import { Chart as HChart } from 'highcharts';
import { ReactElement } from 'react';
import { ChartPoint } from 'components/metric/chart';
import { alpha, Box } from '@mui/material';

const TooltipWidth = 226;
const TooltipMargin = 25;

export default function TooltipContainer({
    point,
    children,
    chart,
}: {
    point: ChartPoint | null;
    chart: HChart;
    children: ReactElement;
}) {
    if (point == null) {
        return <></>;
    }
    /* Calculate tooltip position START */
    // var container_width = this.container.outerWidth();
    // var tooltip_width   = this.settings.isPreview ? 135 : 235;

    const pointY = point.plotY ?? 0;
    const pointX = point.plotX ?? 0;

    let top = pointY + chart.plotHeight - 12;
    let left = pointX + chart.plotLeft + TooltipMargin;

    // Drop to bottom
    if (chart.plotWidth < TooltipWidth + left && chart.plotWidth > TooltipWidth / 2 + left - TooltipMargin) {
        top += 2 * TooltipMargin;
        left -= TooltipWidth / 2 + TooltipMargin;
    } else {
        // Drop to left
        if (chart.plotWidth < TooltipWidth + left) {
            left -= TooltipWidth + 2 * TooltipMargin;
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: 1,
                boxShadow: (theme) => `0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)}`,
                width: TooltipWidth,
                top: top,
                left: left + chart.container.offsetLeft,
                position: 'absolute',
                color: (theme) => alpha(theme.palette.text.primary, 0.8),
                zIndex: 3,
            }}
        >
            {children}
        </Box>
    );
}
