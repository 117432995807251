import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import AsyncSelect from 'react-select/async';
import useBundleTranslation from 'i18n';
import CreatableAsyncSelect from 'react-select/async-creatable';
import { gridAPI } from 'api/grid';
import { FormComponentValue } from 'components/common/form/layout/control';
import { useReactSelectStyles } from './index';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import { ReactSelectDropdownIndicator } from 'components/common/react-select/parts/ReactSelectDropdownIndicator';
import debounce from 'lodash/debounce';
import { ReactSelectSelectContainer } from 'components/common/react-select/parts/ReactSelectSelectContainer';
import { ReactSelectSingleValue } from 'components/common/react-select/parts/ReactSelectSingleValue';
import { ReactSelectMultiValue } from 'components/common/react-select/parts/ReactSelectMultiValue';
import { ReactSelectMultiValueRemove } from 'components/common/react-select/parts/ReactSelectMultiValueRemove';
import { ReactSelectClearIndicator } from 'components/common/react-select/parts/ReactSelectClearIndicator';
import { ReactSelectOption } from 'components/common/react-select/parts/ReactSelectOption';
import ReactSelectVirtualMenuList from 'components/common/react-select/parts/ReactSelectVirtualMenuList';

export type AsyncReactSelectProps = {
    component?: string;
    uid?: string;
    id?: string;
    name?: string;
    value: any;
    addonLabel?: string;
    update: (value: any, fullValue?: any) => void;
    dataUrl?: string;
    isMulti?: boolean;
    creatable?: boolean;
    preloadOptions?: boolean;
    optionComponent?: (props: any) => any;
    lazyLoad?: boolean;
    inputUpdate?: (inputValue: string) => void;
    customPortalTarget?: any;
    onMenuOpen?: () => void;
    loadOptions?: (inputValue: string, init?: boolean) => Promise<FormComponentValue[]>;
    applyVirtualList?: boolean;
};

function AsyncReactSelect(props: AsyncReactSelectProps) {
    const { t } = useBundleTranslation();
    const viewSettings = useReactSelectStyles();
    const [options, setOptions] = useState<FormComponentValue[]>([]);
    const [firstPageOptions, setFirstPageOptions] = useState<FormComponentValue[]>([]);
    const [selectedInitOptions, setSelectedInitOptions] = useState<FormComponentValue[]>([]);
    const [currentSelectValue, setCurrentSelectValue] = useState<FormComponentValue[]>([]);

    const AsyncSelectComponent = props.creatable ? CreatableAsyncSelect : AsyncSelect;

    useEffect(() => {
        if (props.loadOptions) {
            props.loadOptions('', true).then((data: FormComponentValue[]) => {
                setSelectedInitOptions(data);
            });
        }
    }, [props.dataUrl]);

    const loadOptions = useCallback(
        debounce((inputValue: string, callback: (options: FormComponentValue[]) => void) => {
            if (props.loadOptions) {
                props.loadOptions(inputValue).then((data: FormComponentValue[]) => {
                    setFirstPageOptions((prevState) => {
                        const newState = [...prevState, ...data];

                        return newState.filter((item, index) => {
                            return index === newState.findIndex((el) => el.value === item.value);
                        });
                    });
                    setOptions(data);
                    callback(data);
                });
            } else {
                if (props.dataUrl) {
                    gridAPI
                        .loadFilter(props.dataUrl, { pattern: inputValue })
                        .then((data: { data: { status: string; data: FormComponentValue[] } }) => {
                            if (data.data.status === 'OK' && data.data.data.length > 0) {
                                setOptions(data.data.data);
                                callback(data.data.data);
                            }
                        })
                        .catch((error: any) => {
                            console.error(error);
                            callback([]);
                        });
                } else {
                    callback([]);
                }
            }
        }, 300),
        [props.dataUrl]
    );

    const handleChange = function (newValue: any) {
        if (newValue == null) {
            return '';
        }
        const value = props.isMulti ? newValue.map((el: FormComponentValue) => el.value) : newValue.value;
        props.update(value, newValue);
    };

    useEffect(() => {
        const wrappedValue = Array.isArray(props.value) ? props.value : [props.value];
        let defaultValue = options.filter(
            (el) => wrappedValue.includes(Number(el.value)) || wrappedValue.includes(String(el.value))
        );

        const firstPageSelectedOptions = firstPageOptions.filter(
            (el) => wrappedValue.includes(Number(el.value)) || wrappedValue.includes(String(el.value))
        );

        const selectedOptions = selectedInitOptions.filter(
            (el) => wrappedValue.includes(Number(el.value)) || wrappedValue.includes(String(el.value))
        );

        const combinedValue = [...defaultValue, ...firstPageSelectedOptions, ...selectedOptions];

        const newCurrentSelectedValue = combinedValue.filter((item, index) => {
            return index === combinedValue.findIndex((el) => el.value === item.value);
        });

        setCurrentSelectValue(newCurrentSelectedValue);
    }, [props.value, options, props.creatable]);

    const optionComponent = typeof props.optionComponent != 'undefined' ? props.optionComponent : ReactSelectOption;
    const components: any = {
        SelectContainer: ReactSelectSelectContainer,
        Option: optionComponent,
        SingleValue: ReactSelectSingleValue,
        MultiValue: ReactSelectMultiValue,
        DropdownIndicator: ReactSelectDropdownIndicator,
        MultiValueRemove: ReactSelectMultiValueRemove,
        ClearIndicator: ReactSelectClearIndicator,
    };

    if (props.applyVirtualList)
        components.MenuList = (props: any) => <ReactSelectVirtualMenuList {...props} viewSettings={viewSettings} />;

    return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }} data-test={props.id}>
            {props.addonLabel && <StaticAddon>{props.addonLabel}</StaticAddon>}
            <AsyncSelectComponent
                cacheOptions
                isMulti={props.isMulti}
                value={currentSelectValue}
                defaultOptions={props.preloadOptions}
                loadOptions={loadOptions}
                onChange={handleChange}
                classNamePrefix="custom-select"
                placeholder={t('type_for_search')}
                theme={viewSettings.theme}
                styles={viewSettings.styles}
                instanceId={props.name}
                menuPosition={'fixed'}
                menuPortalTarget={
                    props.customPortalTarget ?? (props.customPortalTarget === null ? undefined : document.body)
                }
                components={components}
                onMenuOpen={props.onMenuOpen}
                onMenuScrollToBottom={() => {}}
            />
        </Box>
    );
}

export default AsyncReactSelect;
