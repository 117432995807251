import {
    DeleteResponse,
    FailedMessage,
    FormLayoutType,
    FormRendererProps,
    FormSettingsRaw,
    RawFormComponentType,
    RawFormElementProps,
} from './index';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { logWarning, processUrl, settingsUrlToTranslationNS } from 'tools/tools';
import { formAPI } from 'api/form';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbsCurrentElement } from 'store/breadcrumbsSlice';
import { FormTabType } from './tab';
import useBundleTranslation from 'i18n';
import useFormTabs from 'components/common/form/hooks/useFormTabs';
import useFormContext, { FormsContext } from 'components/common/form/hooks/useFormContext';
import usePageForm, { safeNavigate } from 'components/common/form/hooks/usePageForm';
import { buildComponentsMap } from 'components/common/form/formTools';
import { CustomFormTemplates } from 'form-templates';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import useOnLeavePrompt from 'hooks/useOnLeavePrompt';
import { IConfirmPopupPropsSettings } from 'components/common/form/renderer/page/FormConfirmPopup';
import { createPortal } from 'react-dom';
import { setPageTitle } from 'store/pageTitleSlice';

export default function FormRenderer(props: FormRendererProps) {
    const translationNS = props.translationNS ?? settingsUrlToTranslationNS(props.originalSettingsUrl);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urlParams = useParams();
    const { t } = useBundleTranslation(translationNS);

    const isNew = props.entityId == '0';

    // Delete Entity
    const [confirmPopupOpen, setConfirmPopupOpen] = useState<boolean>(false);
    const [proceedConfirmationPopupOpen, setProceedConfirmationPopupOpen] = useState<boolean>(false);
    const [failedMessage, setFailedMessage] = useState<FailedMessage | string | null>(null);
    let [confirmPopupSettings, setConfirmPopupSettings] = React.useState<IConfirmPopupPropsSettings>({
        popupSettings: {},
        onConfirm: () => {},
        onHide: () => {},
        message: '',
    });

    let [loadingMaskText, setLoadingMaskText] = useState<string>('');
    let [loadingMaskShow, setLoadingMaskShow] = useState<boolean>(false);
    const onDeleteConfirm = (confirmed: boolean = false) => {
        setLoadingMaskText(t('deleting___'));
        setLoadingMaskShow(true);

        const deleteUrl = `${props.settingsUrl}${
            confirmed ? (-1 === props.settingsUrl.indexOf('?') ? '?' : '&') + 'confirm=true' : ''
        }`;

        return formAPI.delete(deleteUrl).then((response: DeleteResponse) => {
            setLoadingMaskShow(false);
            setConfirmPopupOpen(false);
            setIgnoreIsDirtyOnLeave(true);
            if (response.data.status === 'ERROR') {
                if (response.data.message) {
                    alert(response.data.message);
                } else if (response.data.messages) {
                    alert(response.data.messages.join(' '));
                }
            }
            if (response.data.status === 'OK') {
                if (response.data.redirectUrl) {
                    safeNavigate(navigate, response.data.redirectUrl ?? '/');
                }

                if (response.data.failedMessage) {
                    setFailedMessage(response.data.failedMessage);
                    setProceedConfirmationPopupOpen(true);
                }
            }
        });
    };

    const proceedConfirmationPopupSettings: PopupSettings = {
        title: t('delete'),
        textOK: t('delete'),
        needTranslation: false,
    };

    const proceedConfirmationSubmit = () => {
        onDeleteConfirm(true);
    };

    const proceedConfirmationCancel = () => {
        setFailedMessage(null);
        setProceedConfirmationPopupOpen(false);
    };

    const handleDeleteEntity = () => {
        const deletedItem = t(`form_items.${props.formSettings.uid}`);
        const name = props.formData.name;
        setConfirmPopupSettings({
            popupSettings: {
                title: t(
                    settings?.actionSettings?.delete?.confirmConfig?.title ?? 'form_default_delete_confirm_title',
                    {
                        item: deletedItem,
                    }
                ),
                textOK: t(settings?.actionSettings?.delete?.confirmConfig?.textOK ?? 'delete'),
                needTranslation: false,
                colorOk: 'error',
                iconOK: 'trash',
            },
            message: t(
                settings?.actionSettings?.delete?.confirmConfig?.message ?? 'form_default_delete_confirm_message',
                {
                    item: deletedItem,
                    name: name,
                }
            ),
            onConfirm: () => {
                setConfirmPopupOpen(false);
                onDeleteConfirm(false);
            },
            onHide: () => setConfirmPopupOpen(false),
        });
        setConfirmPopupOpen(true);
    };

    // Add New
    let [showSubformPopup, setShowSubformPopup] = React.useState<false | 'new' | 'duplicate'>(false);
    let [subformPopupSettings, setSubformPopupSettings] = React.useState<Partial<PopupSettings>>({});

    const handleDuplicateEntity = function (popupSettings?: PopupSettings) {
        if (settings?.actionSettings?.duplicate?.confirmConfig) {
            setConfirmPopupSettings({
                popupSettings: {
                    title: t(settings?.actionSettings?.duplicate?.confirmConfig?.title ?? 'duplicate'),
                    textOK: t(settings?.actionSettings?.duplicate?.confirmConfig?.textOK ?? 'ok'),
                    needTranslation: false,
                },
                message: t(
                    settings?.actionSettings?.duplicate?.confirmConfig?.message ??
                        'form_default_duplicate_confirm_message',
                    {
                        name: props?.formData?.name ?? '',
                    }
                ),
                onConfirm: () => {
                    setConfirmPopupOpen(false);
                    onDuplicateEntity(popupSettings);
                },
                onHide: () => setConfirmPopupOpen(false),
            });
            setConfirmPopupOpen(true);
        } else {
            onDuplicateEntity(popupSettings);
        }
    };
    const handleAddNewEntity = function (popupSettings?: PopupSettings) {
        if (settings?.urlAdd?.length) {
            return formAPI.saveFormData(settings.urlAdd).then((response: any) => {
                if ('ERROR' === response.data.status) {
                    alert(response.data.message);
                } else {
                    safeNavigate(navigate, response.data.redirectUrl);
                }
            });
        } else {
            if (popupSettings) {
                setSubformPopupSettings(popupSettings);
            }
            setShowSubformPopup('new');
        }
    };

    // Duplicate
    const onDuplicateEntity = function (popupSettings?: PopupSettings) {
        let hasFieldsToDuplicate = false;
        tabs.formTabs.map((tab: FormTabType) => {
            tab.components.map((component: RawFormComponentType, i: number) => {
                hasFieldsToDuplicate = hasFieldsToDuplicate || component.onDuplicate === true;
            });
        });

        if (hasFieldsToDuplicate) {
            if (popupSettings) {
                setSubformPopupSettings(popupSettings);
            }
            setShowSubformPopup('duplicate');
        } else {
            setLoadingMaskShow(true);
            formAPI
                .duplicate(
                    props.settingsUrl + (props.settingsUrl.includes('?') ? '&' : '?') + 'duplicate=Y',
                    props.formData
                )
                .then((response: any) => {
                    setLoadingMaskShow(false);

                    if (response.data.status === 'ERROR') {
                        alert(response.data.message);
                    }

                    if (response.data.redirectUrl) {
                        // setIgnoreIsDirtyOnLeave(true);
                        safeNavigate(navigate, response.data.redirectUrl);
                    }
                });
        }
    };

    // Set global form settings
    const [settings, setSettings] = useState<FormSettingsRaw>(props.formSettings);
    useEffect(() => {
        setSettings(props.formSettings);
    }, [props.formSettings]);

    // Setup tabs data from form.settings
    const tabs = useFormTabs(!props?.isPopup, settings, props.entityId);

    const formRefetch = (resetField: boolean = false): Promise<any> => {
        return props.refetch().then((response) => {
            if (!resetField) {
                return response;
            }
            const newFormData = response?.data?.data?.data;
            if (!newFormData) {
                return;
            }
            for (const [name, value] of Object.entries(newFormData)) {
                hookForm.form.hookFormSetValue(name, value);
            }
            return response;
        });
    };

    const hookForm = usePageForm(
        props.formData,
        t,
        props,
        tabs,
        formRefetch,
        handleDeleteEntity,
        handleAddNewEntity,
        handleDuplicateEntity
    );

    useEffect(() => {
        if (props.setFormRef && hookForm.form.formDidMount) {
            props.setFormRef(hookForm);
        }
    }, [hookForm, hookForm.form.formDidMount]);

    // AutoSave form on any change
    useEffect(() => {
        if (settings?.params?.autoSave && hookForm.form.hookFormState.isDirty && hookForm.form.formDidMount) {
            hookForm.form.formAct.act.submit.exec(hookForm.form.hookFormGetValues());
        }
    }, [hookForm.form.hookFormState.isDirty]);

    const [isPopupFormVisible, setIsPopupFormVisible] = useState<boolean>(true);
    const handleOnHideFormPopup = function () {
        setIsPopupFormVisible(false);
        setTimeout(() => props.onHide(), 0);
    };

    const crumbsDataKey = useSelector((state: any) => state.breadcrumbs.instanceDataKey ?? 'name');
    const titleDataKey = useSelector((state: any) => state.pageTitle.instanceDataKey ?? 'name');
    useEffect(() => {
        if (!props.isPopup) {
            //set page title from Form Settings or by element value
            if (typeof props.formSettings.title != 'undefined') {
                dispatch(setPageTitle(t(props.formSettings.title)));
            } else if (titleDataKey && props.formData?.[titleDataKey]) {
                dispatch(setPageTitle(props.formData[titleDataKey]));
            }
            //set breadcrumbs element
            if (crumbsDataKey && props.formData?.[crumbsDataKey]) {
                dispatch(setBreadcrumbsCurrentElement(props.formData[crumbsDataKey]));
            } else {
                dispatch(setBreadcrumbsCurrentElement(null));
            }
        }
        hookForm.form.setFormDidMount(true);
        return () => {
            hookForm.form.setFormDidMount(false);
            if (!props.isPopup) {
                dispatch(setBreadcrumbsCurrentElement(null));
            }
        };
    }, []);

    const entityName = hookForm.form.hookFormWatch(crumbsDataKey);
    useEffect(() => {
        if (!props.isPopup) {
            if (entityName) {
                dispatch(setBreadcrumbsCurrentElement(entityName));
            } else {
                dispatch(setBreadcrumbsCurrentElement(props.formData[crumbsDataKey]));
            }
        }
    }, [entityName]);

    const formsContext = useFormContext(props.formSettings?.uid, hookForm.form);

    const formLayout: FormLayoutType = props.isPopup
        ? props?.popupSettings?.asidePanel
            ? 'sidePanel'
            : 'popup'
        : 'page';

    const componentsMap = useMemo(() => buildComponentsMap(tabs.formTabs), [settings]);

    const layoutProps: IFormLayoutProps = {
        uid: props.uid,
        elementProps: {
            pk: props.pk,
            data: props.formData,
            urlParams: urlParams,
            formSettingsUrl: props.settingsUrl,
            translationNS: translationNS,
            form: hookForm.form,
            component: {} as RawFormComponentType,
        } as RawFormElementProps,
        hookForm: hookForm,
        tabs: tabs,
        componentsMap: componentsMap,
        formLayout: formLayout,
        t: t,
        // onSubmit: hookForm.form.formAct.act.submit.exec,
        sharedComponents: settings?.sharedControls?.components ?? [],
        actionSettings: settings?.actionSettings ?? {},
        panelSettings: settings.params?.panel,
        isNew: isNew,
        confirmPopupProps: {
            isOpen: confirmPopupOpen,
            settings: confirmPopupSettings,
        },
        asPopupProps: {
            popupType: props.popupType ?? 'new',
            isOpen: isPopupFormVisible,
            popupSettings: props?.popupSettings ?? ({} as PopupSettings),
            onHide: handleOnHideFormPopup,
        },
        subFormPopup: {
            pk: props.pk,
            uid: settings.uid,
            popupSettings: subformPopupSettings,
            onHide: () => {
                setShowSubformPopup(false);
                setSubformPopupSettings({});
            },
            afterSave: (response: any) => {
                if (response.data.redirectUrl) {
                    // setIgnoreIsDirtyOnLeave(true);
                    safeNavigate(navigate, response.data.redirectUrl);
                }
            },
            showType: showSubformPopup,
            popupType: showSubformPopup ? showSubformPopup : 'new',
            settingsUrl:
                showSubformPopup == 'duplicate'
                    ? props.settingsUrl + (props.settingsUrl.includes('?') ? '&' : '?') + 'duplicate=Y'
                    : processUrl(props.originalSettingsUrl, { [props.pk]: 0 }),
        },
    };

    useEffect(() => {
        if (isNew) {
            hookForm.form.hookFormSetValue('_', null, { shouldDirty: true });
        }
    }, []);

    // Show confirmation before leave, if form not saved
    const [ignoreIsDirtyOnLeave, setIgnoreIsDirtyOnLeave] = useState<boolean>(false);
    useOnLeavePrompt(
        t('form_leave_confirm'),
        !ignoreIsDirtyOnLeave &&
            Object.entries(hookForm.form.hookFormState.dirtyFields).filter((f) => f[0] != '').length > (isNew ? 1 : 0),
        formLayout
    );

    let layoutComponent = <FormLayoutComposer props={layoutProps} />;
    if (settings.template) {
        if (!CustomFormTemplates[settings.template]) {
            logWarning('Form Layout not found: ' + settings.template);
        } else {
            layoutComponent = React.createElement(CustomFormTemplates[settings.template], layoutProps);
        }
    }

    return (
        <FormsContext.Provider value={formsContext}>
            {createPortal(
                <Backdrop
                    sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.modal + 1 }}
                    open={hookForm.state.loadingMaskShow || loadingMaskShow}
                >
                    {loadingMaskShow ? loadingMaskText : hookForm.state.loadingMaskText}
                    <CircularProgress color="inherit" />
                </Backdrop>,
                document.body
            )}
            {layoutComponent}
            {proceedConfirmationPopupOpen && (
                <Popup
                    settings={proceedConfirmationPopupSettings}
                    open={proceedConfirmationPopupOpen}
                    onHide={proceedConfirmationCancel}
                    onConfirm={proceedConfirmationSubmit}
                >
                    {failedMessage && typeof failedMessage === 'object' && (
                        <Stack direction={'column'} spacing={1}>
                            <div dangerouslySetInnerHTML={{ __html: failedMessage.message }} />
                            {failedMessage.items?.map((item: string, key: number) => {
                                return <Typography key={`related-item-${key}`}>{item}</Typography>;
                            })}
                        </Stack>
                    )}
                    {failedMessage && typeof failedMessage === 'string' && (
                        <div dangerouslySetInnerHTML={{ __html: failedMessage }} />
                    )}
                </Popup>
            )}
        </FormsContext.Provider>
    );
}
