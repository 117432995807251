import {
    FormComponentValueSelectable,
    FormElementControlPropsType,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { FormControlCheckBoxListProps } from './index';
import useBundleTranslation from 'i18n';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RawFormElementProps } from 'components/common/form';
import { Alert, alpha, Box, FormLabel, Stack } from '@mui/material';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import CheckBoxSearch from './CheckBoxSearch';
import { ColumnType, GridDataRow } from 'components/common/grid/';
import { useGridMassActions } from 'components/common/grid/hooks/useGridMassActions';
import MITable from 'components/common/grid/MITable';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps, processSettingsUrl } from 'components/common/form/formTools';
import { FormElementWrapper } from 'components/common/form/element/FormElementWrapper';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import styles from './CheckBoxList.styles';
import { setFormElementSharedState } from 'store/formSlice';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { instance } from 'api/api';
import { APIResponse } from 'tools/types';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';

interface IFilterStatus {
    searchFilterValue: string;
    filters: Array<{
        name: string;
        value: string;
    }>;
}

function useAsyncData(
    controlName: string,
    tableHeight: number,
    pageSize: number,
    filterStatus: IFilterStatus,
    dataURL: string | false
) {
    const [actualPage, setActualPage] = useState(0);
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const [fullData, setFullData] = useState<Array<Array<FormComponentValueSelectable>>>([]);

    const [filterTransition, setFilterTransition] = useState(false);
    const [filters, setFilters] = useState(filterStatus);

    const [searchPattern, setSearchPattern] = useState('');
    const [patternTimeout, setPatternTimeout] = useState(0);

    useEffect(() => {
        if (patternTimeout) {
            clearTimeout(patternTimeout);
        }
        setPatternTimeout(
            window.setTimeout(() => {
                setSearchPattern(filterStatus.searchFilterValue);
            }, 300)
        );
    }, [filterStatus.searchFilterValue]);

    useEffect(() => {
        if (!dataURL) {
            return;
        }

        if (JSON.stringify(filters) != JSON.stringify({ ...filterStatus, searchFilterValue: searchPattern })) {
            // Reset data on filters update
            setFilterTransition(true);
            setActualPage(0);
            setAllDataLoaded(false);
            setFullData([]);
            // Set filters
            setFilters({ ...filterStatus, searchFilterValue: searchPattern });
        }
    }, [filterStatus, searchPattern]);

    const filtersString = JSON.stringify(filters);

    useEffect(() => {
        if (!allDataLoaded) {
            refetch();
        }
    }, [actualPage, filtersString, allDataLoaded]);

    const { isLoading, refetch } = useQuery<any | void, Error>([controlName, dataURL], async () => {
        if (!dataURL) {
            return [];
        }

        // Add filter params
        const params: any = {};
        filterStatus.filters.forEach((f) => {
            params[f.name] = f.value;
        });

        const response = await instance.get<APIResponse<Array<any>>>(dataURL, {
            params: { ...params, page: actualPage, pattern: searchPattern, pageSize: pageSize },
        });
        if (response.data.status == 'OK') {
            const list = fullData.slice();
            //@ts-ignore
            list[actualPage] = prepareFormComponentValues(response.data.data);
            if (list[actualPage].length < pageSize) {
                setAllDataLoaded(true);
            }
            setFullData(list);
            setFilterTransition(false);
        }
        return [];
    });

    const [resultList, setResultList] = useState<Array<FormComponentValueSelectable>>([]);
    useEffect(() => {
        let list: Array<FormComponentValueSelectable> = [];
        fullData.forEach((l) => (list = list.concat(l)));
        setResultList(list);
    }, [fullData]);

    // For async Data
    const handleScroll = (scrollX: number, scrollY: number) => {
        if (!dataURL || allDataLoaded || isLoading || filterTransition) {
            return;
        }

        const contextHeight = resultList.length * 32;
        const top = Math.abs(scrollY);

        if (contextHeight - top - tableHeight < 500) {
            // Trigger Load next page
            setActualPage(actualPage + 1);
        }
    };

    return { resultList, isLoading: isLoading || filterTransition, handleScroll };
}

export function CheckBoxListControl({ controlProps }: FormElementControlPropsType<FormControlCheckBoxListProps>) {
    const { t } = useBundleTranslation(['components/common/check_box_list']);
    const dispatch = useDispatch();

    const isAsyncData = typeof controlProps.controlComplexData.values == 'string';
    const defaultValuesList = isAsyncData ? [] : controlProps.controlComplexData.values;
    const [listValues, setListValues] = useState<Array<FormComponentValueSelectable>>(defaultValuesList);
    useEffect(() => {
        if (isAsyncData) {
            return;
        }
        if (JSON.stringify(listValues) == JSON.stringify(defaultValuesList)) {
            return;
        }
        setListValues(controlProps.controlComplexData.values);
    }, [JSON.stringify(controlProps.controlComplexData.values)]);

    const listValuesRef = useRef<Array<FormComponentValueSelectable>>([]);

    // Information about parent-child values
    const [valuesChildParentMap, setValuesChildParentMap] = useState<any>({});
    const valuesChildParentMapRef = useRef<any>({});
    useEffect(() => {
        if (isAsyncData) {
            return;
        }
        const map: any = {};
        controlProps.controlComplexData.values.forEach((v) => {
            if (v.props?.children?.length) {
                v.props.children.forEach((c: any) => {
                    map[c] = v.value;
                });
            }
        });
        setValuesChildParentMap(map);
        valuesChildParentMapRef.current = map;
    }, []);

    const handleSearchFilterChange = function (event: any): void {
        setSearchFilterValue(event);
    };

    const [searchFilterValue, setSearchFilterValue] = useState<string>('');

    const handleCheckboxChange = function (valueIndex: number, newStatus: boolean): void {
        if (listValuesRef.current[valueIndex].selected !== newStatus) {
            setListValues(processCheckBoxStatusChange(valueIndex, newStatus, listValuesRef.current.slice()));
        }
    };

    const processCheckBoxStatusChange = function (
        valueIndex: number,
        newStatus: boolean,
        list: Array<FormComponentValueSelectable>
    ): Array<FormComponentValueSelectable> {
        list[valueIndex].selected = newStatus;
        if (newStatus) {
            // Select all Parents
            if (typeof valuesChildParentMapRef.current[list[valueIndex].value] != 'undefined') {
                const parentIndex = list.findIndex(
                    (v) => v.value == valuesChildParentMapRef.current[list[valueIndex].value]
                );
                if (parentIndex != -1) {
                    processCheckBoxStatusChange(parentIndex, true, list);
                }
            }
        } else {
            // Unselect children
            if (list[valueIndex].props?.children?.length) {
                // Find children index by value, set it unchecked
                list[valueIndex].props.children
                    .map((c: any) => list.findIndex((v) => v.value == c))
                    .filter((i: number) => i != -1)
                    .forEach((i: number) => processCheckBoxStatusChange(i, false, list));
            }
        }
        return list;
    };

    const handleBulkChange = function (newValue: boolean) {
        let list = listValuesRef.current.slice();
        list.forEach((v, i) => {
            if (isRowVisible(v) && !v.disabled) {
                list = processCheckBoxStatusChange(i, newValue, list);
            }
        });
        setListValues(list);
    };

    const defaultFiltersValues: any = {};
    controlProps.controlComplexData.filters.forEach((f) => {
        if (typeof f.defaultValue != 'undefined') {
            defaultFiltersValues[f.name] = f.defaultValue;
        }
    });
    const elementProps = useCustomSimplifiedForm(defaultFiltersValues);
    // Subscribe to changes
    const fieldsToWatch = elementProps.form.hookFormWatch(controlProps.controlComplexData.filters.map((f) => f.name));

    useEffect(() => {
        const list: Array<string> = listValues.filter((v) => v.selected).map((v) => v.value);
        handleChange(JSON.stringify(list));
        listValuesRef.current = listValues;

        massActionsProps.setCheckedKeys(list);
    }, [listValues]);

    const handleChange = (values: string) => {
        controlProps.onChange(values);
        const list: Array<string> = JSON.parse(values);
        const result = filteredList.filter((f) => list.includes(f.value));
        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: { selectedOption: structuredClone(result) },
            })
        );
    };

    const isRowVisible = function (v: FormComponentValueSelectable): boolean {
        if (isAsyncData) {
            return true;
        }
        let isVisible = true;
        // Apply search filter
        if (searchFilterValue.length) {
            if (!v.label.toLowerCase().includes(searchFilterValue.toLowerCase())) {
                isVisible = false;
            }
        }
        // Apply custom filters
        for (const [key, value] of Object.entries(v.props)) {
            const filter = controlProps.controlComplexData.filters.find((f) => f.name == key);
            let fieldValue = elementProps.form.hookFormGetValues(key);
            if (filter && filter.component == 'FormCheckbox') {
                if (fieldValue == 'N') {
                    continue;
                }
            }

            if (fieldValue != undefined && fieldValue != '') {
                if (fieldValue != value) {
                    isVisible = false;
                }
            }
        }
        return isVisible;
    };

    const getFilteredList = () => {
        if (isAsyncData) {
            // For async data, all filters must be applied on the server side.
            return listValues;
        }

        if (!controlProps.showOnlyUniqueLabels) {
            return listValues.filter((v) => isRowVisible(v));
        }

        //ToDo refactor burst add tile (MI-22768) to use right back data instead this filter (multi values of one filter)
        const uniqueValues: FormComponentValueSelectable[] = [];

        listValues
            .filter((v) => isRowVisible(v))
            .forEach((v) => {
                const index = uniqueValues.findIndex((v2) => v2.label === v.label);

                if (index === -1) {
                    uniqueValues.push(v);
                }
            });

        return uniqueValues;
    };

    const filtersStatus: IFilterStatus = {
        searchFilterValue: searchFilterValue ?? '',
        filters: controlProps.controlComplexData.filters.map((f) => {
            return {
                name: f.name,
                value: elementProps.form.hookFormGetValues(f.name) ?? (f.component == 'FormCheckbox' ? 'N' : ''),
            };
        }),
    };

    const filteredList = getFilteredList();
    const checkedCount = listValues.filter((v) => v.selected).length;

    const [columns, setColumns] = useState<Array<ColumnType>>([]);

    useEffect(() => {
        const columns: Array<ColumnType> = [
            {
                title: t(controlProps.selectAllLabel),
                name: 'main',
                resizable: false,
            },
        ];
        controlProps.controlComplexData.columns.forEach((column) => {
            columns.push({ ...column, resizable: false });
        });
        setColumns(columns);
    }, [controlProps.controlComplexData.columns]);

    const data = useMemo<Array<GridDataRow>>(
        () =>
            filteredList.map((value) => {
                const text = value.label;
                const result: GridDataRow = {
                    id: value.value,
                    main: {
                        component: 'text',
                        config: {
                            text: text,
                            subText: value.props?.desc,
                            rawHtml: true,
                        },
                    },
                };

                if (value.disabled) {
                    result.unselectable = 'true';
                }

                controlProps.controlComplexData.columns.forEach((column) => {
                    //@ts-ignore
                    if (column?.component) {
                        result[column.name] = {
                            //@ts-ignore
                            component: column.component,
                            width: 30,
                            config: {
                                //@ts-ignore
                                width: 30,
                                value: value.value,
                            },
                        };
                    } else {
                        result[column.name] = value.props[column.name] ?? '';
                    }
                });

                return result;
            }),
        [filteredList]
    );
    // Setup Checkboxes
    const massActionsProps = useGridMassActions(data, { enabled: true });
    massActionsProps.handleCheckRow = useCallback(
        (event: any) => {
            const checked = event.target.checked;
            const value = event.target.value;
            const itemIndex = listValuesRef.current.findIndex((item) => item.value == value);
            handleCheckboxChange(itemIndex, checked);
        },
        [massActionsProps.checkedRowsKeys, filteredList]
    );
    massActionsProps.handleCheckAllRows = (event: any) => {
        const checked = event.target.checked;
        handleBulkChange(checked);
    };
    massActionsProps.checkAllChecked = () =>
        filteredList.filter((v) => v.selected).length == filteredList.filter((v) => !v.disabled).length &&
        filteredList.filter((v) => v.selected).length > 0;

    const fixMaxTableHeightByData = data.length >= (controlProps.maxHeightElementsCount ?? 15);
    let minMaxTableHeight = data.length == 0 && controlProps.height == 0 ? 70 : controlProps.height;
    if (fixMaxTableHeightByData && controlProps.height == 0) {
        if (controlProps.maxHeightElementsCount) {
            const oneRowHeight = 32;
            minMaxTableHeight = controlProps.maxHeightElementsCount * oneRowHeight + oneRowHeight; //+ table header
        } else {
            minMaxTableHeight = 400;
        }
    }

    const {
        resultList: asyncData,
        isLoading: asyncIsLoading,
        handleScroll,
    } = useAsyncData(
        controlProps.name,
        minMaxTableHeight,
        controlProps.dataPageSize,
        filtersStatus,
        isAsyncData
            ? processSettingsUrl(
                  String(controlProps.controlComplexData.values),
                  Object.keys(controlProps.urlParams),
                  controlProps.urlParams
              )
            : false
    );

    useEffect(() => {
        if (!isAsyncData) {
            return;
        }
        setListValues(asyncData);
    }, [asyncData]);

    const selectedCountInFilterValues = filteredList.filter((item) => item.selected).length;

    return (
        <Stack sx={{ width: '100%' }}>
            <Stack direction={'row'} sx={styles.filtersWrapper}>
                {controlProps.showSearch && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            ...styles.filterItem,
                            ...(controlProps.searchWidth ? { width: controlProps.searchWidth } : {}),
                        }}
                    >
                        <Box flexGrow={1}>
                            <CheckBoxSearch
                                value={searchFilterValue}
                                onChange={handleSearchFilterChange}
                                placeholder={controlProps.searchPlaceholder}
                            />
                        </Box>
                        {controlProps.showCounter && (
                            <Box flexShrink={0} sx={{ whiteSpace: 'nowrap', color: 'text.secondary' }}>
                                {t('selected_items_label', {
                                    count: checkedCount,
                                })}
                            </Box>
                        )}
                    </Stack>
                )}

                {controlProps.controlComplexData.filters.map((filter, index) => {
                    const isCheckbox = filter.component == 'FormCheckbox';
                    const isRadioGroup = filter.component == 'FormRadioGroup';
                    const hideDefaultLabel = isCheckbox ? {} : { label: '' };

                    const filterProps = prepareFormElementProps({
                        ...elementProps,
                        component: { ...filter, ...hideDefaultLabel },
                    } as RawFormElementProps);

                    const label = (filter as any).label;

                    const isLabelAboveMod = filter?.props?.filterView == 'labelAbove';

                    return (
                        <Stack
                            direction="row"
                            sx={{
                                ...styles.filterItem,
                                ...(filter?.props?.filterWidth ? { width: filter.props.filterWidth } : {}),
                            }}
                            className={(isLabelAboveMod ? 'label-above-mod' : '') + (isCheckbox ? 'checkbox-mod' : '')}
                        >
                            {!isCheckbox && !isRadioGroup && label && !isLabelAboveMod && (
                                <StaticAddon>{t(label)}</StaticAddon>
                            )}
                            {isRadioGroup && label && !isLabelAboveMod && (
                                <FormLabel
                                    className={'label align-as-field-value'}
                                    sx={{ flexShrink: 0, mr: 1, mb: 0 }}
                                >
                                    {t(label)}
                                </FormLabel>
                            )}
                            {!isCheckbox && label && isLabelAboveMod && (
                                <FormLabel className={'label'}>{t(label)}</FormLabel>
                            )}
                            <Box sx={{ width: '100%' }}>
                                <FormElementWrapper key={index} elementProps={filterProps}>
                                    <ReactHookFormController
                                        componentValues={filterProps.component.componentStartValues}
                                        elementProps={filterProps}
                                    />
                                </FormElementWrapper>
                            </Box>
                        </Stack>
                    );
                })}
            </Stack>

            {controlProps.showWarnHiddenElementsByFilter && checkedCount != selectedCountInFilterValues && (
                <Alert severity={'info'} variant={'standard'} sx={{ mb: 2 }}>
                    {t('hidden_by_filter', { count: checkedCount - selectedCountInFilterValues })}
                </Alert>
            )}

            <Box sx={{ position: 'relative' }} id={controlProps.name}>
                {asyncIsLoading && (
                    <LoadingPlaceholder
                        sx={{
                            zIndex: 999,
                            position: 'absolute',
                            backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                            color: (theme) => alpha(theme.palette.text.primary, 0.4),
                        }}
                    />
                )}
                <MITable
                    isLoading={controlProps.isLoadingTableData}
                    shouldUpdateScroll={false}
                    handleScroll={handleScroll}
                    gridName={controlProps.name + '_grid'}
                    height={minMaxTableHeight}
                    autoHeight={controlProps.height == 0 && !fixMaxTableHeightByData}
                    data={data}
                    emptyGridMessage={controlProps.emptyDataPlaceholder}
                    columns={columns}
                    massActionsProps={massActionsProps}
                    massActionsConfig={{ enabled: true }}
                    handleClickRow={(rowData) => {
                        if (controlProps.selectByClickWholeRow) {
                            const itemIndex = listValuesRef.current.findIndex((item) => item.value == rowData.id);
                            if (itemIndex !== -1 && !listValuesRef.current[itemIndex].disabled) {
                                handleCheckboxChange(itemIndex, !listValuesRef.current[itemIndex].selected);
                            }
                        }
                    }}
                />
            </Box>
            <input
                name={controlProps.name}
                type={'hidden'}
                value={controlProps.value}
                onChange={controlProps.onChange}
            />
        </Stack>
    );
}
