import { Box, Grid, Stack, Typography } from '@mui/material';
import styles from '../styles';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import useBundleTranslation from 'i18n';

function Owner({ type, name }: { type: string; name?: string }) {
    if (!name || name === '') {
        return null;
    }

    return (
        <Grid container direction={'row'} minWidth={'225px'}>
            <Grid item xs={6}>
                <Typography fontSize={12} fontWeight={400} lineHeight={'18px'} display="inline" minWidth={'92px'}>
                    {`${type}:`}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    fontSize={12}
                    fontWeight={400}
                    lineHeight={'18px'}
                    display="inline"
                    color={'primary.main'}
                    minWidth={'120px'}
                >
                    {name}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default function Owners({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const { t } = useBundleTranslation(['app/home', 'main']);

    const isOwnersPresent =
        datasetInfo.business_owner_dn || datasetInfo.technical_owner_dn || datasetInfo.data_steward_dn;

    return isOwnersPresent ? (
        <Box sx={styles.flexStartWrapper}>
            <Typography fontSize={14} width={'16px'}>
                <IconMi icon={'user'} />
            </Typography>
            <Stack direction={'column'} spacing={0.5}>
                <Owner type={t('info_panel.business_owner')} name={datasetInfo.business_owner_dn} />
                <Owner type={t('info_panel.technical_owner')} name={datasetInfo.technical_owner_dn} />
                <Owner type={t('info_panel.data_steward')} name={datasetInfo.data_steward_dn} />
            </Stack>
        </Box>
    ) : null;
}
