import React, { useContext } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { getElementFieldValue } from 'components/common/form/formTools';
import useBundleTranslation from 'i18n';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import { ActSubmit } from 'components/common/form/act';

export default function GlossarySectionUsage(props: IFormLayoutProps) {
    const { t } = useBundleTranslation(['app/editor/glossary/section']);
    const formsContext = useContext<FormsContextType>(FormsContext);

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                return new Promise((resolve, reject) => {
                    const label = getElementFieldValue(props.hookForm.form, 'topic_id.$option.label') ?? '';
                    const sectionName =
                        getElementFieldValue(props.hookForm.form, '$form[topicTypeForm].name', formsContext) ?? '';
                    const options = label.split(':');
                    if (options.length !== 2) {
                        reject();
                        return false;
                    }
                    if (
                        confirm(
                            t('move_confirm', { name: options[1], section: options[0], currentTopicType: sectionName })
                        )
                    ) {
                        return super.exec(data);
                    } else {
                        reject();
                    }
                });
            }
        }
    );

    return <FormLayoutComposer props={props} />;
}
