import { Popup, PopupSettings } from 'components/common/popup/Popup';
import React from 'react';
import useBundleTranslation from 'i18n';
import { IInfoPopup, useInfoPopup } from 'components/common/popup/InfoPopup';

export interface IConfirmationPopup extends IInfoPopup {
    onConfirm: () => void;
}

export function useConfirmationPopup(
    onConfirm: () => void,
    onHide?: () => void,
    defaultContent?: any
): IConfirmationPopup {
    const popup = useInfoPopup(onHide, defaultContent);
    const { t } = useBundleTranslation();
    return { ...popup, title: t('confirmation'), onConfirm };
}

export function ConfirmationPopupWrapper({ confirmationPopup }: { confirmationPopup: IConfirmationPopup }) {
    const handleOnHide = () => {
        confirmationPopup.setIsOpen(false);
        if (confirmationPopup.onHide) {
            confirmationPopup.onHide();
        }
    };

    const handleOnConfirm = () => {
        confirmationPopup.setIsOpen(false);
        confirmationPopup.onConfirm();
    };

    return (
        <ConfirmationPopup
            title={confirmationPopup.title}
            onHide={handleOnHide}
            onConfirm={handleOnConfirm}
            isOpen={confirmationPopup.isOpen}
        >
            {confirmationPopup.content}
        </ConfirmationPopup>
    );
}

export function ConfirmationPopup({
    title,
    isOpen,
    children,
    onHide,
    onConfirm,
}: {
    title: string;
    isOpen: boolean;
    children: any;
    onHide: () => void;
    onConfirm: () => void;
}) {
    const popupSettings: PopupSettings = {
        title: title,
        needTranslation: false,
        textOK: 'Yes',
        textCancel: 'No',
    };

    return (
        <Popup onHide={onHide} onConfirm={onConfirm} settings={popupSettings} open={isOpen}>
            {children}
        </Popup>
    );
}
