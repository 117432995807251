import { FormLayoutType, FormType, RawFormElementProps } from 'components/common/form/index';
import { IFormTabComponentRecord, prepareFormElementProps } from 'components/common/form/formTools';
import FormElement from 'components/common/form/element/FormElement';
import React from 'react';

export interface IFormElementComponentProps {
    elementProps: RawFormElementProps;
    formLayout: FormLayoutType;
    formType: FormType;
    record?: IFormTabComponentRecord;
}

export function FormElementComponent({ elementProps, formLayout, formType, record }: IFormElementComponentProps) {
    if (!record) {
        // TODO: Warning
        return null;
    }
    const fullProps = prepareFormElementProps(
        {
            ...elementProps,
            component: record.props.component,
        },
        record.props.nextComponent
    );

    const fieldPresent =
        (formType == 'edit' && fullProps.component.props.onEdit) ||
        (formType == 'new' && fullProps.component.props.onNew) ||
        (formType == 'duplicate' && fullProps.component.props.onDuplicate);

    if (!fieldPresent) {
        return null;
    }

    return (
        <FormElement
            {...fullProps}
            wrapWithContainer={true}
            containerMaxWidth={formLayout == 'popup' ? false : fullProps.containerMaxWidth}
        />
    );
}
