import React, { useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import useBundleTranslation from 'i18n';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { createPortal } from 'react-dom';
import { ActSubmit } from 'components/common/form/act';

export default function ElementShareForm(props: IFormLayoutProps) {
    const { t } = useBundleTranslation();
    const [isLoading, setIsLoading] = useState(false);

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            exec = async (data: any) => {
                setIsLoading(true);
                return super
                    .exec(data)
                    .then((response) => {
                        if (response?.data?.status == 'OK') {
                            alert(t('your_content_shared'));
                        }
                        return response;
                    })
                    .finally(() => setIsLoading(false));
            };
        }
    );

    return (
        <>
            {isLoading &&
                createPortal(
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            zIndex: (theme) => 9999,
                        }}
                    />,
                    document.body
                )}
            <FormLayoutComposer props={props} />
        </>
    );
}
