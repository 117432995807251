import { TooltipFormatterContextObject } from 'highcharts/highstock';
import React from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsBubbleChart = ReportContentNS.ComponentSettingsBubbleChart;
import { escapeHtml } from 'components/report-content/utils/tools';

export default function BubbleChartTooltip({
    context,
    settings,
}: {
    context: TooltipFormatterContextObject;
    settings: ComponentSettingsBubbleChart;
}) {
    const point = context.point as typeof context.point & { x_f: number; y_f: number; v_f: number };
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: point.name }}></div>

            <div>
                <span>{escapeHtml(settings.x_field.column_name)}:</span>
                <span>{point.x_f}</span>
            </div>
            <div>
                <span>{escapeHtml(settings.y_field.column_name)}:</span>
                <span>{point.y_f}</span>
            </div>
            <div>
                <span>{escapeHtml(settings.value_field.column_name)}:</span>
                <span>{point.v_f}</span>
            </div>
        </div>
    );
}
