import { useEffect, useMemo } from 'react';
import merge from 'ts-deepmerge';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import useBundleTranslation from 'i18n';
highchartsMore(Highcharts);

(function (H) {
    H.wrap(H.Legend.prototype, 'handleOverflow', function (p, h) {
        return h;
    });
})(Highcharts);

Highcharts.dateFormats = {
    //@ts-ignore
    q: function (timestamp) {
        const date = new Date(timestamp);
        let quarter = 0;

        if (date) {
            quarter = Math.ceil((date.getUTCMonth() + 1) / 3);
        }

        return quarter;
    },
};

export default function Chart({
    chartOptions,
    afterChartCreated,
    constructorType,
}: {
    chartOptions: Highcharts.Options;
    afterChartCreated?: Highcharts.ChartCallbackFunction;
    constructorType?: 'mapChart' | 'stockChart';
}) {
    const { t } = useBundleTranslation();
    const appTheme = useTheme();
    useEffect(() => {
        Highcharts.setOptions({
            lang: {
                months: [
                    t('January'),
                    t('February'),
                    t('March'),
                    t('April'),
                    t('May'),
                    t('June'),
                    t('July'),
                    t('August'),
                    t('September'),
                    t('October'),
                    t('November'),
                    t('December'),
                ],
                shortMonths: [
                    t('Jan'),
                    t('Feb'),
                    t('Mar'),
                    t('Apr'),
                    t('May'),
                    t('Jun'),
                    t('Jul'),
                    t('Aug'),
                    t('Sep'),
                    t('Oct'),
                    t('Nov'),
                    t('Dec'),
                ],
                weekdays: [
                    t('Sunday'),
                    t('Monday'),
                    t('Tuesday'),
                    t('Wednesday'),
                    t('Thursday'),
                    t('Friday'),
                    t('Saturday'),
                ],
            },
        });
    }, []);

    const defaultOptions = {
        rangeSelector: {
            inputBoxHeight: 20,
            inputBoxWidth: 100,
            inputBoxBorderColor: alpha(appTheme.palette.text.primary, 0.08),
            inputStyle: {
                color: appTheme.palette.primary.main,
            },
        },
        tooltip: {
            padding: 10,
            borderRadius: 5,
            backgroundColor: appTheme.palette.background.default,
            borderColor: 'none',
        },
    };

    const options = useMemo(() => merge(defaultOptions, chartOptions), [chartOptions]);

    return (
        <HighchartsReact
            callback={afterChartCreated}
            constructorType={constructorType ?? 'stockChart'}
            highcharts={Highcharts}
            options={options}
        />
    );
}
