import React, { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Button, IconButton, Stack, Tab, Tabs, Theme } from '@mui/material';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import DataPreview from 'app/editor/dataset/DataPreview';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';
import { Resizable } from 're-resizable';
import styles from 'components/common/form/DataFetchCommand.styles';
import CommandEditor from 'components/common/form/data-fetch-command/CommandEditor';
import SegmentDataPreview from 'components/common/form/data-fetch-command/SegmentDataPreview';
import IconMi from 'components/common/icon/IconMi';
import { useTheme } from '@mui/material/styles';
import RunHistory from 'components/common/form/data-fetch-command/RunHistory';
import showdown from 'showdown';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getElementFieldValue, getPkId, paramsUrl, processSettingsUrl } from 'components/common/form/formTools';
import { FormComponentBuilder } from 'components/common/form/layout';
import PluginQueryBuilder, { pluginQueryBuilderConfigObject } from 'components/plugin-query-builder/PluginQueryBuilder';
import { FormsContext, FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getAsFieldValue } from 'components/common/form/dependency/processEffect';
import DataFetchCommandHint from 'components/common/form/data-fetch-command/hint/DataFetchCommandHint';
import { APIResponse, AssocArray } from 'tools/types';
import DataFetchCommandCollection from 'components/common/form/data-fetch-command/collection/DataFetchCommandCollection';
import {
    IValidationResponse,
    IValidationResponseDataDependencyError,
    IValidationResponseReportSuccess,
} from 'components/common/form/data-fetch-command/validation';
import DataFetchCommandValidation from 'components/common/form/data-fetch-command/validation/DataFetchCommandValidation';
import { setFormElementSharedState } from 'store/formSlice';
import { useDispatch } from 'react-redux';
import { DataFetchCommandNS } from 'components/common/form/data-fetch-command';
import patternsMap = DataFetchCommandNS.patternsMap;
import { instance } from 'api/api';
import DataFetchCommandDelete from 'components/common/form/data-fetch-command/delete/DataFetchCommandDelete';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { reportAPI } from 'api/report';
import { ConfirmationPopupWrapper, useConfirmationPopup } from 'components/common/popup/ConfirmationPopup';
import {
    ProcessCollectionResultPromise,
    ProcessValidationResultPromise,
} from 'components/common/form/data-validate-collect/useDataValidateCollect';
const converter = new showdown.Converter();

const SegmentPattern = ':segment';

export type DataFetchCommandEntityType =
    | 'metric'
    | 'report'
    | 'dataset'
    | 'command'
    | 'dependency'
    | 'segment'
    | 'calendar';

export interface FormControlDataFetchCommandProps extends FormControlProps {
    pkId: string;
    validationFieldId: string;
    type: DataFetchCommandEntityType;
    validationUrl: string;
    collectionUrl: string;
    renderCommandEditor: boolean;
    dataPreviewUrl: string;
    hasDataUrl: string;
    dataSource: string;
    subst: AssocArray<string>;
    segmentId: number;
    getDefaultsForDeleteUrl: string;
    checkDataBeforeDeleteUrl: string;
    deleteDataUrl: string;
}

const textareaId = 'codeArea';

function useHasData(hasDataUrl: string) {
    const urlParams = useParams();
    const url = processSettingsUrl(hasDataUrl, Object.keys(urlParams), urlParams);

    const { data: hasData, refetch } = useQuery<boolean, Error>(['dataFetchCommandHasData', url], () => {
        return instance
            .get<APIResponse<{ records: number }>>(url)
            .then((response) => response.data.status == 'OK' && response.data.data.records > 0);
    });

    return { hasData, refetch };
}

function DataFetchCommand({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlDataFetchCommandProps>) {
    const dispatch = useDispatch();
    const urlParams = useParams();
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const formsContext = useContext<FormsContextType>(FormsContext);

    const dataPreviewUrl = paramsUrl(controlProps?.dataPreviewUrl ?? '', urlParams);

    const [showDataPreview, setShowDataPreview] = useState(false);
    const appTheme = useTheme();

    const { hasData, refetch: refreshHasData } = useHasData(controlProps.hasDataUrl);

    const dataSourceValue = controlProps.dataSource;
    const isSql = dataSourceValue.endsWith('_sql') || dataSourceValue.endsWith('existing datasets');
    const isPlugin = dataSourceValue.endsWith('_plugin');
    const isManual = 'manual' === dataSourceValue;

    const tab = document.querySelector('.active-tab-content') as HTMLElement;
    const headingHeight: number = appTheme.size.navbarHeight + appTheme.size.tabsHeight; //navbar + tabs bar
    const dataPreviewInitialHeight = 350;

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [pluginQueryBuilderData, setPluginQueryBuilderData] = useState<pluginQueryBuilderConfigObject>({
        title: '',
        pattern: '', //ToDo parent.find('#plugin_connection_profile_id').attr('pattern')
        ve_display_method: '', //ToDo parent.find('#plugin_connection_profile_id').attr('ve_display_method')
        interval: null,
        fiscal: '',
        profileId: 0,
        reportID: 0,
        extReportID: '',
        extReportName: '',
        extReportLinkedRefId: 0,
        itemId: getPkId(elementProps?.pk ?? '', elementProps?.urlParams ?? {}),
        itemType: controlProps.type == 'metric' ? 'element' : controlProps.type, //ToDo pass prop or use field val. Old -  ed.setDataForExternalReferenceFilterUsage
        query: controlProps.value ?? '',
        /* 'var Test123 = date(Case Created Date, Case Created Date)\naggregates = count(*)\nfilter = CaseNumber < 3 AND CaseNumber > 18\n' +
            'sort = Subject asc\n', */
        plugin_alias: '',
        has_ve: '',
        has_summary: '',
    });
    const validationActionRef = useRef<null | Function>(null);

    const updatePluginQueryBuilderData = (newData: any) => {
        setPluginQueryBuilderData((previousPluginQueryBuilderDataState) => ({
            ...previousPluginQueryBuilderDataState,
            ...newData,
        }));
    };

    let valuesRecordedText = ('metric' === controlProps.type ? 'metric_' : '') + 'values_were_recorded';
    if (controlProps.type == 'dataset') {
        valuesRecordedText = 'dataset_values_were_recorded';
    }
    const setContentHeight = (height: number) => {
        tab.style.height = window.innerHeight - height - headingHeight + 'px';
    };

    useEffect(() => {
        if (!tab) return;
        if ('dataset' !== controlProps.type) return;
        if (showDataPreview) {
            setContentHeight(dataPreviewInitialHeight);
        } else {
            tab.style.height = '';
        }
    }, [showDataPreview]);

    const highlightWithLineNumbers = (code: string) =>
        highlight(code, languages.sql, 'sql')
            .split('\n')
            .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
            .join('\n');

    const addCommandToEditor = useCallback(
        (command: string) => {
            const textareaField = document.getElementById(textareaId) as HTMLTextAreaElement;
            const value = controlProps.form.hookFormGetValues(controlProps.name) || '';
            const startPos = textareaField?.selectionStart ?? 0;
            const nextValue = `${value.slice(0, startPos)} ${command} ${value.slice(startPos)}`;
            controlProps.form.hookFormSetValue(controlProps.name, nextValue);
            textareaField.focus();
        },
        [controlProps.form]
    );

    const onValueChanged = function (newValue: string) {
        if (validationSuccess) {
            setValidationSuccess(false);
            controlProps.form.hookFormSetValue(controlProps.validationFieldId, 'N', {
                shouldDirty: true,
            });
        }
        controlProps.form.hookFormSetValue(controlProps.name, newValue, { shouldDirty: true });
    };

    const loadDataClick = () => {
        if ('metric' === controlProps.type) {
            navigate('/editor/element/metric/' + controlProps.pkId + '/manual');
        }
    };

    const getAttrVal = (patternVal: string) => {
        const value = elementProps ? getAsFieldValue(patternVal, elementProps, formsContext) : undefined;
        return value === patternVal ? undefined : value;
    };

    // Statement
    const [hasSegmentPattern, setHasSegmentPattern] = useState<boolean>(false);
    const [hasRegularPattern, setHasRegularPattern] = useState<boolean>(false);
    const [matchedPatterns, setMatchedPatterns] = useState<Array<string>>([]);

    const actualSegmentValue = controlProps.form.hookFormWatch('segment_id');
    useEffect(() => {
        if (controlProps.type != 'report') {
            return;
        }
        if (actualSegmentValue > 0) {
            setHasSegmentPattern(true);
        }
    }, [actualSegmentValue]);

    useEffect(() => {
        if (hasSegmentPattern) {
            setMatchedPatterns((prevState) => {
                return [...prevState, SegmentPattern];
            });
        } else {
            setMatchedPatterns((prevState) => prevState.filter((pattern) => pattern != SegmentPattern));
        }
    }, [hasSegmentPattern]);

    useEffect(() => {
        setHasRegularPattern(matchedPatterns.length - Number(hasSegmentPattern) > 0);
    }, [matchedPatterns]);

    useEffect(() => {
        // Update component Shared State
        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: {
                    has_segment_pattern: hasSegmentPattern ? 'Y' : 'N',
                    has_regular_pattern: hasRegularPattern ? 'Y' : 'N',
                    has_data: hasData ? 'Y' : 'N',
                },
            })
        );
    }, [hasSegmentPattern, hasRegularPattern, hasData]);

    const [isDsLoadInd, setIsDsLoadInd] = useState<boolean>(false);
    const [isDataSource, setIsDataSource] = useState<boolean>(false);
    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name == 'ds_incremental_load_ind') {
                setIsDsLoadInd(value['ds_incremental_load_ind'] == 'Y');
            }
            if (name == 'data_source') {
                setIsDataSource(value['data_source'] == 'dataset');

                const plugin_connection_profile_id = getAttrVal(`$data_source.plugin_connection_profile_id`);
                const plugin_name = getAttrVal(`$data_source.plugin_name`);
                const plugin_alias = getAttrVal(`$data_source.plugin_alias`);
                const has_visual_editor = getAttrVal(`$data_source.has_ve`);
                const supports_command_summary = getAttrVal(`$data_source.has_summary`);

                if (plugin_connection_profile_id) {
                    updatePluginQueryBuilderData({
                        profileId: Number(plugin_connection_profile_id),
                        title: plugin_name ?? '',
                        plugin_alias: plugin_alias ?? '',
                        has_ve: has_visual_editor,
                        has_summary: supports_command_summary,
                    });
                }
            }
            if (name == 'measurement_interval_id') {
                const measurement_interval_unit = getAttrVal(`$measurement_interval_id.interval_unit`);
                const measurement_interval_fiscal = getAttrVal(`$measurement_interval_id.fiscal`);
                updatePluginQueryBuilderData({
                    interval: measurement_interval_unit ?? null,
                    fiscal: measurement_interval_fiscal,
                });
            }
            if (name == 'external_report_external_id') {
                const report_id = value['external_report_external_id'];
                const external_report_id = getAttrVal(`$external_report_external_id.external_report_id`);
                const external_report_name = getAttrVal(`$external_report_external_id.name`);
                const external_report_liked_ref_id = getAttrVal(`$external_report_external_id.linked_ref_id`) ?? 0;
                updatePluginQueryBuilderData({
                    reportID: report_id ?? 0,
                    extReportID: external_report_id ?? '',
                    extReportName: external_report_name ?? '',
                    extReportLinkedRefId: external_report_liked_ref_id ?? 0,
                });
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    const elementId = controlProps.form.hookFormGetValues('element_id');
    const { data: instancesData } = useQuery<{ instances: Array<any>; hasInstances: boolean }, Error>(
        [`report_content_editor_${elementId}_instances`],
        () => {
            return reportAPI.getInstances(elementId, 0);
        }
    );
    const [reportWithInst, setReportWithInst] = useState(false);
    useEffect(() => {
        if (controlProps.type != 'report' || !instancesData) {
            return;
        }
        if (instancesData.instances.length && instancesData.hasInstances) {
            setReportWithInst(true);
        }
    }, [instancesData]);

    useEffect(() => {
        if (!controlProps.form.formDidMount) {
            return;
        }

        const list = patternsMap[controlProps.type].filter((s) => controlProps.value.includes(s));
        if (hasSegmentPattern) {
            list.push(SegmentPattern);
        }

        if (isDataSource && controlProps.type == 'metric') {
            if (isDsLoadInd) {
                if (!list.includes(':last_measurement_time')) {
                    list.push(':last_measurement_time');
                }
            } else {
                const index = list.findIndex((s) => s == ':last_measurement_time');
                if (index != -1) {
                    list.splice(index, 1);
                }
            }
        }

        if (reportWithInst) {
            if (!list.includes(':last_event_time')) {
                list.push(':last_event_time');
            }
        }

        setMatchedPatterns(list);

        if (controlProps.type == 'calendar' || controlProps.type == 'metric' || controlProps.type == 'dataset') {
            const fieldName = controlProps.type == 'calendar' ? 'scope_limited_by_segment_id' : 'segment_id';
            let bindParam = getElementFieldValue(controlProps.form, `${fieldName}.bind_param`);
            if (!isSql && !isPlugin && (controlProps.type == 'metric' || controlProps.type == 'calendar')) {
                setHasSegmentPattern(actualSegmentValue > 0);
            } else {
                if (bindParam == null) {
                    setTimeout(() => {
                        bindParam = getElementFieldValue(controlProps.form, `${fieldName}.bind_param`);
                        setHasSegmentPattern(controlProps.value.includes(':' + bindParam));
                    }, 1000);
                } else {
                    setHasSegmentPattern(controlProps.value.includes(':' + bindParam));
                }
            }
        }
    }, [
        controlProps.value,
        controlProps.segmentId,
        controlProps.form.formDidMount,
        isDataSource,
        isDsLoadInd,
        reportWithInst,
        isSql,
        isPlugin,
        actualSegmentValue,
    ]);

    // Validation
    const [lastValidationData, setLastValidationData] = useState<AssocArray<string>>(controlProps.subst);
    let [validationSuccess, setValidationSuccess] = useState<boolean>(
        controlProps.form.hookFormWatch(controlProps.validationFieldId) == 'Y'
    );
    const [validationIteration, setValidationIteration] = useState<number>(0);
    const [validationMessage, setValidationMessage] = useState<string>('');
    const [warningMessage, setWarningMessage] = useState<string>('');
    const [validationData, setValidationData] = useState<any>({});
    const [previewTableData, setPreviewTableData] = useState<IValidationResponseReportSuccess | undefined>();
    const [validationLabel, setValidationLabel] = useState<ReactJSXElement | null>(null);

    useEffect(() => {
        controlProps.form.hookFormSetValue('validationIteration', validationIteration, { shouldDirty: false });
    }, [validationIteration]);

    useEffect(() => {
        const newValue = validationSuccess ? 'Y' : 'N';
        if (controlProps.form.hookFormWatch(controlProps.validationFieldId) != newValue) {
            controlProps.form.hookFormSetValue(controlProps.validationFieldId, newValue, { shouldDirty: true });
        }
    }, [validationSuccess]);

    // Metric Validation
    const mvcpResolve = useRef<any>(null);
    const mvcpReject = useRef<any>(null);
    const metricValidationConfirmPopup = useConfirmationPopup(
        () => {
            controlProps.form.hookFormSetValue('metric_value_type', 'float', { shouldDirty: true });
            if (mvcpResolve.current) {
                mvcpResolve.current();
            }
        },
        () => {
            if (mvcpReject.current) {
                mvcpReject.current();
            }
        },
        t('validation.metric.float_to_int_warning')
    );

    const handleValidationResult = function <T extends IValidationResponse>(
        isSuccess: boolean,
        message: string,
        data: T
    ): Promise<ProcessValidationResultPromise> {
        if (data?.has_warning == 'Y') {
            setWarningMessage(data.warnings ?? '');
        } else {
            setWarningMessage('');
        }
        if (isSuccess) {
            setValidationSuccess(isSuccess);
            setValidationMessage(message);
            // Trigger other components update
            const v = Number(controlProps.form.hookFormGetValues('dfc_validation_refresh'));
            controlProps.form.hookFormSetValue('dfc_validation_refresh', Number.isInteger(v) ? v + 1 : 1);
            if ('dataset' === controlProps.type) {
                setShowDataPreview(true);
                setValidationIteration((prevState) => prevState + 1);
            } else if ('segment' === controlProps.type) {
                setShowDataPreview(true);
                setValidationData(data);
            } else if (controlProps.type == 'report') {
                if (typeof data.data != 'undefined') {
                    setShowDataPreview(true);
                    // @ts-ignore
                    setPreviewTableData(data);
                } else {
                    setShowDataPreview(false);
                    setPreviewTableData(undefined);
                }
            } else if (controlProps.type == 'metric' && data?.valueType) {
                const actualType = controlProps.form.hookFormGetValues('metric_value_type');
                if (actualType == 'int' && data.valueType == 'decimal') {
                    metricValidationConfirmPopup.setIsOpen(true);
                    return new Promise((resolve, reject) => {
                        mvcpResolve.current = resolve;
                        mvcpReject.current = reject;
                    });
                }
            }
        } else {
            if (controlProps.type == 'dependency') {
                let typeData = data as IValidationResponseDataDependencyError;
                if (typeData?.is_validated == 'Y') {
                    isSuccess = true;
                }
            }
            setValidationSuccess(isSuccess);
            setValidationMessage(message);
            setValidationIteration((prevState) => prevState + 1);
        }
        // Wait for useEffect to update the value before returning the promise
        return new Promise((resolve) => setTimeout(() => resolve({ status: true, data: data }), 10));
    };

    // Collection
    const handleCollectionResult = function <T extends IValidationResponse>(
        isSuccess: boolean,
        message: string,
        data: T
    ): Promise<ProcessCollectionResultPromise> {
        setWarningMessage('');
        if (isSuccess) {
            // if (data.rows > 0) setHasData(true);
            refreshHasData();
            if ('segment' === controlProps.type) {
                queryClient.invalidateQueries(['segmentValueGrid']);
                setValidationMessage(message);
            } else {
                setValidationMessage(t(valuesRecordedText, { count: data.rows ?? data?.fetched_rows ?? 0 }));
            }
        } else {
            if (isManual) {
                alert(message);
            }
            setValidationMessage(message);
        }
        return Promise.resolve({ status: true, data: data });
    };

    useEffect(() => {
        //@ts-ignore
        window.datasetCollection = {};
        return () => {
            //@ts-ignore
            delete window.datasetCollection;
        };
    }, []);

    // Delete
    const afterDeleteValues = (response: any) => {
        setValidationMessage('');
        setWarningMessage('');
        // setHasData(response.data.has_values > 0);
        refreshHasData();
        queryClient.resetQueries({
            predicate: (query) => query.queryKey[0] == 'entryEventsGrid',
        });
    };

    const [topButtonBuildReportElement, setTopButtonBuildReportElement] = useState<HTMLElement | null>(null);
    useEffect(() => {
        if (controlProps.type != 'dataset') {
            return;
        }
        const interval = window.setInterval(() => {
            const element = document.getElementById('data-fetch-command-top-button-portal-build-report');
            if (element != topButtonBuildReportElement) {
                setTopButtonBuildReportElement(element);
            }
        }, 100);

        return () => window.clearInterval(interval);
    }, []);

    const actionButtonsBlock = (visualEditorBtn?: ReactJSXElement | null) => {
        if (isManual) return null;

        if (!visualEditorBtn) visualEditorBtn = null;

        const isSqlEditor = isSql;
        const isPluginEditor = !isSql && isPlugin;

        const isShowRunValidation =
            isSqlEditor ||
            isPluginEditor ||
            (['metric', 'calendar', 'segment', 'dataset', 'report'].includes(controlProps.type) &&
                dataSourceValue == 'dataset');
        const isShowValidationRows =
            controlProps.dataPreviewUrl &&
            (isSqlEditor ||
                isPluginEditor ||
                (['dataset', 'report'].includes(controlProps.type) && dataSourceValue == 'dataset'));
        const isShowCollectionButton = ['metric', 'calendar', 'segment'].includes(controlProps.type);
        const isShowCommandDelete = hasData && ['metric', 'calendar', 'segment'].includes(controlProps.type);
        const isShowRunHistory = hasData && ['metric', 'segment'].includes(controlProps.type);

        return (
            <Stack spacing={1}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <DataFetchCommandValidation
                        controlProps={controlProps}
                        onResult={handleValidationResult}
                        matchedPatterns={matchedPatterns}
                        lastValidationData={lastValidationData}
                        setLastValidationData={setLastValidationData}
                        onChangeValidationLabel={(validationLabelElement) => {
                            setValidationLabel(validationLabelElement);
                        }}
                        setValidationAction={(action) => {
                            validationActionRef.current = action;
                        }}
                    >
                        {isShowRunValidation && (
                            <Button
                                data-test={controlProps.name + '_validate_button'}
                                variant={dataSourceValue == 'dataset' ? 'light' : undefined}
                                startIcon={<IconMi icon={dataSourceValue == 'dataset' ? 'test' : 'play'} />}
                                color={'success'}
                            >
                                {t(dataSourceValue == 'dataset' ? 'button_validate_check_data' : 'button_validate')}
                            </Button>
                        )}
                    </DataFetchCommandValidation>
                    {isShowRunValidation && (
                        <Box>
                            <Box sx={styles.divider} />
                        </Box>
                    )}
                    {visualEditorBtn}
                    {isShowValidationRows && (
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                setShowDataPreview(true);
                            }}
                        >
                            {t('button_show_validation_rows')}
                        </Button>
                    )}
                    <DataFetchCommandCollection
                        controlProps={controlProps}
                        onResult={handleCollectionResult}
                        matchedPatterns={matchedPatterns}
                        lastCollectionData={lastValidationData}
                        setLastCollectionData={setLastValidationData}
                        hasData={hasData ?? false}
                        validationSuccess={validationSuccess}
                    >
                        {isShowCollectionButton && (
                            <Button
                                data-test={controlProps.name + '_collect_button'}
                                variant={'outlined'}
                                startIcon={<IconMi icon={'refresh-cw'} />}
                            >
                                {t('collect_data')}
                            </Button>
                        )}
                    </DataFetchCommandCollection>
                    {isShowCommandDelete && (
                        <DataFetchCommandDelete
                            controlProps={controlProps}
                            afterDeleteValues={afterDeleteValues}
                            segmentId={controlProps.segmentId}
                            getDefaultsUrl={paramsUrl(controlProps.getDefaultsForDeleteUrl, urlParams)}
                            checkBeforeDeleteUrl={paramsUrl(controlProps.checkDataBeforeDeleteUrl, urlParams)}
                            deleteUrl={paramsUrl(controlProps.deleteDataUrl, urlParams)}
                        />
                    )}
                    {isShowRunHistory && (
                        <Box>
                            <Box sx={styles.divider} />
                        </Box>
                    )}
                    {isShowRunHistory && <RunHistory type={controlProps.type} itemId={controlProps.pkId} />}
                </Stack>
                {validationLabel}
            </Stack>
        );
    };

    return (
        <>
            {isManual && ['metric', 'dataset'].includes(controlProps.type) && (
                <Button onClick={loadDataClick} variant={'outlined'}>
                    {t('load_data')}
                </Button>
            )}
            {controlProps.type == 'metric' && (
                <ConfirmationPopupWrapper confirmationPopup={metricValidationConfirmPopup} />
            )}
            {isManual ? (
                <DataFetchCommandCollection
                    controlProps={controlProps}
                    onResult={handleCollectionResult}
                    matchedPatterns={matchedPatterns}
                    lastCollectionData={lastValidationData}
                    setLastCollectionData={setLastValidationData}
                    hasData={hasData ?? false}
                    validationSuccess={validationSuccess}
                >
                    {null}
                </DataFetchCommandCollection>
            ) : (
                <>
                    {isSql && (
                        <Box className={'dataFetchExtraSizeContainer'} sx={styles.extraSizeContainer}>
                            <Box sx={styles.container}>
                                <Resizable
                                    minHeight={312}
                                    enable={{
                                        bottom: true,
                                    }}
                                    style={styles.resizableComponent as React.CSSProperties}
                                    handleStyles={{
                                        bottom: {
                                            bottom: 0,
                                            right: 0,
                                            left: 'auto',
                                            width: 'auto',
                                            height: 'auto',
                                        },
                                    }}
                                    handleComponent={{
                                        bottom: (
                                            <Box sx={{ pr: 1.5, pb: '14px' }}>
                                                <IconMi
                                                    icon={'resize'}
                                                    sx={{
                                                        fontSize: '16px',
                                                        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.4),
                                                    }}
                                                />
                                            </Box>
                                        ),
                                    }}
                                >
                                    <Box sx={styles.commandContainer} className={isExpanded ? 'isExpandedPanel' : ''}>
                                        <Stack direction={'row'} flexGrow={1}>
                                            <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                                <Stack direction={'row'} sx={styles.commandHeader}>
                                                    <Tabs
                                                        value={'query'}
                                                        onChange={(event: React.SyntheticEvent, newValue: string) => {}}
                                                    >
                                                        <Tab
                                                            label={t('query')}
                                                            value={'query'}
                                                            icon={<IconMi icon={'file-sql'} fontSize={'16'} />}
                                                            iconPosition="start"
                                                        />
                                                    </Tabs>
                                                    {controlProps.renderCommandEditor && (
                                                        <Button
                                                            variant="outlined"
                                                            color="neutral"
                                                            className={'min-width--icon'}
                                                            onClick={() => setIsExpanded((prevState) => !prevState)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            <IconMi
                                                                icon={isExpanded ? 'chevrons-right' : 'chevrons-left'}
                                                            />
                                                        </Button>
                                                    )}
                                                </Stack>
                                                <Box
                                                    sx={{
                                                        ...styles.commandContent,
                                                        borderColor: (theme) =>
                                                            validationSuccess
                                                                ? alpha(theme.palette.success.main, 0.64)
                                                                : alpha(theme.palette.error.main, 0.64),
                                                    }}
                                                >
                                                    <Editor
                                                        data-test={controlProps.name + '_editor'}
                                                        onValueChange={onValueChanged}
                                                        highlight={(code) => highlightWithLineNumbers(code)}
                                                        value={controlProps.value ?? ''}
                                                        textareaId={textareaId}
                                                        className="editor"
                                                        style={
                                                            {
                                                                ...styles.editorComponent,
                                                                fontFamily: appTheme.font.monospace,
                                                                fontSize: '11px',
                                                            } as CSSProperties
                                                        }
                                                    />
                                                </Box>
                                            </Stack>
                                            {isExpanded && controlProps.renderCommandEditor && (
                                                <Box sx={styles.commandRightPanel}>
                                                    <CommandEditor
                                                        dataSource={controlProps.dataSource}
                                                        onChange={addCommandToEditor}
                                                    />
                                                </Box>
                                            )}
                                        </Stack>

                                        <Stack sx={styles.commandFooter} direction={'row'} alignItems={'center'}>
                                            {actionButtonsBlock()}
                                        </Stack>
                                    </Box>
                                </Resizable>
                            </Box>
                        </Box>
                    )}
                    {!isSql && isPlugin && (
                        <Box className={'dataFetchExtraSizeContainer'} sx={styles.extraSizeContainer}>
                            <PluginQueryBuilder
                                config={pluginQueryBuilderData}
                                validationSuccess={validationSuccess}
                                onChangedQueryValue={onValueChanged}
                                editorHighlightWithLineNumbers={highlightWithLineNumbers}
                                editorTextareaId={textareaId}
                                parent={{}}
                                actionButtonsBlock={actionButtonsBlock}
                                form={controlProps.form}
                                onClickSaveQueryBuilderPopup={() => {
                                    if (validationActionRef.current) validationActionRef.current(false);
                                }}
                            />
                        </Box>
                    )}
                    {isExpanded && isPlugin && controlProps.renderCommandEditor && (
                        <Box sx={styles.rightSidePanel}>
                            <CommandEditor dataSource={controlProps.dataSource} onChange={addCommandToEditor} />
                        </Box>
                    )}

                    {!isSql && !isPlugin && (
                        <>
                            {(['metric', 'calendar', 'segment'].includes(controlProps.type) ||
                                (['dataset', 'report'].includes(controlProps.type) &&
                                    dataSourceValue == 'dataset')) && (
                                <Stack sx={styles.commandActions} direction={'row'} alignItems={'center'}>
                                    {actionButtonsBlock()}
                                </Stack>
                            )}
                        </>
                    )}

                    {validationMessage > '' && (
                        <Box
                            className={'dataFetchValidationMessage'}
                            data-test={
                                validationSuccess
                                    ? 'data-fetch-command-message-success'
                                    : 'data-fetch-command-message-warning'
                            }
                            sx={{
                                ...styles.validationMsgBlock,
                                ...(validationSuccess ? styles.validationSuccess : styles.validationError),
                            }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: validationMessage }} />
                        </Box>
                    )}

                    {warningMessage > '' && (
                        <Box
                            className={'dataFetchWarningMessage'}
                            data-test={'data-fetch-command-message-info'}
                            sx={{ ...styles.validationMsgBlock, ...styles.validationWarning }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: warningMessage }} />
                        </Box>
                    )}

                    <DataFetchCommandHint controlProps={controlProps} />

                    {'segment' == controlProps.type && showDataPreview && (
                        <SegmentDataPreview validationData={validationData} />
                    )}

                    {(previewTableData != null || (dataPreviewUrl.length > 0 && 'dataset' == controlProps.type)) && (
                        <Box sx={{ position: 'fixed', inset: 'auto 0 0 0', zIndex: 5 }}>
                            <DataPreview
                                show={showDataPreview}
                                setShow={setShowDataPreview}
                                defaultHeight={dataPreviewInitialHeight}
                                onResize={(e, dir, ref, delta) => {
                                    setContentHeight(ref.clientHeight);
                                }}
                                showCloseButton
                                pkId={controlProps.pkId}
                                uid={'data-preview-' + validationIteration}
                                gridConfigUrl={dataPreviewUrl}
                                previewData={previewTableData}
                            />
                        </Box>
                    )}
                </>
            )}
        </>
    );
}

export class DataFetchCommandManagerBuilder extends FormComponentBuilder {
    prepareProps(): FormControlDataFetchCommandProps {
        const subst: AssocArray<string> = {};
        for (const [key, value] of Object.entries(this.elementProps.componentProps.subst ?? {})) {
            if (String(value).length > 0) {
                subst[key] = String(value);
            }
        }

        return {
            ...this.controlProps,
            pkId: getPkId(this.elementProps.pk, this.elementProps.urlParams),
            validationFieldId: this.elementProps.componentProps.validationFieldId ?? '',
            type: this.elementProps.componentProps.type ?? 'dataset',
            hasDataUrl: this.elementProps.componentProps.hasDataUrl ?? '',
            dataPreviewUrl: this.elementProps.componentProps.dataPreviewUrl ?? '',
            renderCommandEditor: this.elementProps.componentProps.renderCommandEditor ?? false,
            validationUrl: this.elementProps.componentProps.validationUrl ?? '',
            collectionUrl: this.elementProps.componentProps.collectionUrl ?? '',
            dataSource: this.elementProps.componentProps.dataSource ?? '',
            segmentId: this.elementProps.componentProps.segmentId ?? 0,
            getDefaultsForDeleteUrl: this.elementProps.componentProps.getDefaultsForDeleteUrl ?? '',
            checkDataBeforeDeleteUrl: this.elementProps.componentProps.checkDataBeforeDeleteUrl ?? '',
            deleteDataUrl: this.elementProps.componentProps.deleteDataUrl ?? '',
            subst: subst,
        };
    }
}

export default DataFetchCommand;
