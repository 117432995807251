import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import { ReportContentNS } from 'components/report-content/index';
import ExternalVisualizationInitialDataResponse = ReportContentNS.ExternalVisualizationInitialDataResponse;
import ReactSelect from 'components/common/react-select/ReactSelect';
import { FormComponentValue } from 'components/common/form/layout/control';
import ComponentSettingsExternalVisualizationDataQueries = ReportContentNS.ComponentSettingsExternalVisualizationDataQueries;
import IBlocksContext = ReportContentNS.IBlocksContext;
import BlocksContext = ReportContentNS.BlocksContext;
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsExternalVisualization = ReportContentNS.ComponentSettingsExternalVisualization;
import useReportContentContext from 'components/report-content/hooks/useReportContentContext';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import useOnApplyAccessMapChange from 'components/report-content/hooks/useOnApplyAccessMapChange';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

export default function ExternalVisualization({
    contentSettings,
    component,
}: ComponentUpdateProps<ComponentSettingsExternalVisualization>) {
    const { filtersString } = useReportContentContext(() => {
        if (component.settings.link_to_dataset == 'D') {
            removeInitialData();
        }
    });

    const [searchParams] = useSearchParams();
    const hashParam = searchParams.get('extvishash');
    const [extVizHash, setExtVizHash] = useState<String | undefined>();
    if (contentSettings.context == 'view' && hashParam && extVizHash != hashParam) {
        setExtVizHash(hashParam);
    }

    const blocksContext = useContext<IBlocksContext | null>(BlocksContext);
    const initialQueryKey = `report_block_initial_${component.datasetRendererBlockComponentId}_${contentSettings.segmentValueId}`;
    const { data: initialData, remove: removeInitialData } = useQuery<ExternalVisualizationInitialDataResponse, Error>(
        [initialQueryKey],
        () => {
            setLastDataQueries(component.settings.dataQueries);
            return reportAPI.getComponentData(component.settings, contentSettings, filtersString);
        }
    );

    useEffect(() => {
        if (blocksContext) {
            blocksContext.setExtViz(component.settings);
        }
        return () => {
            setSelectedViz('');
            removeInitialData();
            // Trigger on remove
            if (blocksContext) {
                blocksContext.setExtViz(null);
            }
        };
    }, []);

    useOnApplyAccessMapChange(contentSettings.applyAccessMap, removeInitialData);

    // Last request to server was made with this data
    const [lastDataQueries, setLastDataQueries] = useState<ComponentSettingsExternalVisualizationDataQueries | null>(
        null
    );

    // Reset all data on dataQueries change
    useEffect(() => {
        if (
            lastDataQueries != null &&
            JSON.stringify(lastDataQueries) != JSON.stringify(component.settings.dataQueries)
        ) {
            removeInitialData();
            if (blocksContext) {
                blocksContext.setExtViz(component.settings);
            }
        }
    }, [component.settings]);

    useEffect(() => {
        // TODO:
        removeInitialData();
        if (blocksContext) {
            blocksContext.setExtViz(component.settings);
        }
    }, [
        JSON.stringify(component.settings.filtersOnDataset),
        component.settings.link_to_dataset,
        component.settings.report_ext_vis_element_id,
    ]);

    const [selectedViz, setSelectedViz] = useState('');
    const handleVizChange = (value: any) => {
        setSelectedViz(value);
    };

    useEffect(() => {
        if (!initialData) {
            return;
        }
        if (selectedViz == '') {
            if (initialData.visualizations.length) {
                let index = 0;
                if (extVizHash) {
                    index = initialData.visualizations.findIndex((v) => v.hash == extVizHash);
                    if (index == -1) {
                        index = 0;
                    }
                }
                const url = initialData.visualizations[index].url ?? initialData.visualizations[index].url_external;
                setSelectedViz(url);
            }
        }
    }, [initialData]);

    const selectData: Array<FormComponentValue> = useMemo(() => {
        if (!initialData) {
            return [];
        }
        return initialData.visualizations.map((viz) => {
            let label: Array<string> = [];
            if (viz.prompts) {
                label = viz.prompts.map((prompt) => prompt.label + ': ' + prompt.values.join(', '));
            }

            return {
                label: label.join(' | '),
                value: viz.url ?? viz.url_external,
                props: {
                    urlExternal: viz.url ?? viz.url_external,
                    prompts: viz?.prompts ?? [],
                    hash: viz?.hash ?? '',
                    filters: viz?.filters ?? '',
                },
            };
        });
    }, [initialData]);

    const [vizLink, setVizLink] = useState('');
    useEffect(() => {
        if (selectedViz == '') {
            return;
        }

        const data = selectData.find((v) => v.value == selectedViz);
        if (data) {
            setVizLink(data.props.urlExternal);
        }
    }, [selectedViz, selectData]);

    useComponentReady(selectedViz, 5000);

    // Scroll to iFrame
    const wrapperEl = useRef<HTMLDivElement>(null);
    const [iFrameFocused, setIFrameFocused] = useState(false);
    const hashFrameRef = useRef<HTMLElement>();
    useEffect(() => {
        if ((hashFrameRef.current || wrapperEl.current) && !iFrameFocused && extVizHash) {
            const iFrameWrapper = hashFrameRef.current ?? wrapperEl.current;
            if (!iFrameWrapper) {
                return;
            }
            setTimeout(() => {
                const scrollWrapper = iFrameWrapper.closest('.scroll-content-container') as HTMLElement;
                scrollWrapper.scrollTo({
                    top: iFrameWrapper.offsetTop - scrollWrapper.offsetTop - 10,
                    behavior: 'smooth',
                });
            }, 1000);
            setIFrameFocused(true);
        }
    }, [hashFrameRef.current, wrapperEl.current, extVizHash]);

    useEffect(() => {
        // Handle click on Href Cell of Dataset table
        if (component.settings.userSelectedViz && component.settings.userSelectedViz?.length > 0) {
            handleVizChange(component.settings.userSelectedViz);
            if (wrapperEl.current) {
                const scrollWrapper = wrapperEl.current.closest('.scroll-content-container') as HTMLElement;
                scrollWrapper.scrollTo({
                    top: wrapperEl.current.offsetTop - scrollWrapper.offsetTop - 10,
                    behavior: 'smooth',
                });
            }
        }
    }, [component.settings.userSelectedViz]);

    if (!initialData) {
        return <span>loading...</span>;
    }

    if (component.settings.report_max_viz_number_to_display >= initialData.visualizations.length) {
        return (
            <div style={{ overflow: 'hidden' }}>
                {selectData.map((v) => {
                    let prompts = null;
                    if (
                        component.settings.report_ext_vis_applicable_scope == 'row' &&
                        v.props.prompts.length &&
                        initialData.visualizations.length > 1
                    ) {
                        prompts = (
                            <div>
                                {v.props.prompts.map((v: any, i: number) => (
                                    <div key={i}>
                                        {v.label}: {v.values.join(', ')}
                                    </div>
                                ))}
                            </div>
                        );
                    }

                    return (
                        <Box ref={v.props.hash == extVizHash ? hashFrameRef : undefined} key={v.value}>
                            {prompts}
                            <iframe style={{ width: '100%', height: component.settings.height ?? 600 }} src={v.value} />
                            {initialData.displayOpenLink == 'Y' && (
                                <div>
                                    <a href={v.props.urlExternal} target={'_blank'} rel="noopener">
                                        Open Visualization
                                    </a>
                                </div>
                            )}
                        </Box>
                    );
                })}
            </div>
        );
    }

    return (
        <div ref={wrapperEl} style={{ overflow: 'hidden' }}>
            {initialData.visualizations.length > 1 && (
                <div>
                    <ReactSelect data={selectData} value={selectedViz} update={handleVizChange} />
                </div>
            )}
            <iframe style={{ width: '100%', height: component.settings.height ?? 600 }} src={selectedViz} />
            {initialData.displayOpenLink == 'Y' && (
                <div>
                    <a href={vizLink} target={'_blank'} rel="noopener">
                        Open Visualization
                    </a>
                </div>
            )}
        </div>
    );
}
