import { FormTabType } from 'components/common/form/tab';
import { PopupSettings } from 'components/common/popup/Popup';
import { useEffect, useRef, useState } from 'react';
import {
    FormRendererAPIType,
    RawFormComponentType,
    FormDataAPIType,
    FormRendererProps,
} from 'components/common/form/index';
import useFormElementRegister from 'components/common/form/hooks/useFormElementRegister';
import { useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';
import { AssocArray } from 'tools/types';
import {
    FormComponentValue,
    FormControlProps,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { setFormAllowedValues, setFormComponentAllowedValues, unsetForm } from 'store/formSlice';
import { formAPI } from 'api/form';
import { TFunction } from 'i18next';
import { importErrorHandler } from 'components/common/form/responseErrorHandlers';
import { getFormId } from 'components/common/form/formTools';
import { FormUseTabs } from 'components/common/form/hooks/useFormTabs';
import { useQueryClient } from '@tanstack/react-query';
import { useGlobalContext } from 'layouts/context';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ActAfterSaveError, ActAfterSaveSuccess, ActSave, ActSubmit, FormActs } from 'components/common/form/act';

export function safeNavigate(navigate: NavigateFunction, url: string) {
    if (!url) {
        return;
    }
    setTimeout(() => {
        navigate(url);
    }, 100);
}

export default function usePageForm(
    defaultValues: any = {},
    t: TFunction,
    formRendererProps?: FormRendererProps,
    tabs?: FormUseTabs,
    formRefetch?: (resetField?: boolean) => Promise<any>,
    onDelete?: () => void,
    onAdd?: (popupSettings?: PopupSettings) => void,
    onDuplicate?: (popupSettings?: PopupSettings) => void
): FormDataAPIType {
    const { addElementValuesRegister, getElementValuesRegister } = useFormElementRegister();
    const dispatch = useDispatch();

    const processedDefaultValues: any = {};
    const formKey =
        (formRendererProps?.formSettings?.uid ?? '') +
        (formRendererProps?.settingsUrl ?? '') +
        (formRendererProps?.entityId ?? '') +
        (formRendererProps?.popupType ?? 'new');
    const isNew = formRendererProps?.entityId == '0';
    //init defaultValue from "PHP Form Description"
    //!test for FormHidden only: remove or extend
    if (tabs) {
        tabs.formTabs.forEach((tab) =>
            tab.components.forEach((component) => {
                if (component.defaultValue && component.name && component.component == 'FormHidden') {
                    processedDefaultValues[component.name] = component.defaultValue;
                }
            })
        );
    }

    for (const [key, value] of Object.entries(defaultValues)) {
        processedDefaultValues[key] = value == null ? '' : value;
    }

    const {
        setValue: hookFormSetValue,
        control,
        formState,
        getValues,
        watch,
        handleSubmit,
        reset: hookFormReset,
        clearErrors: hookFormClearErrors,
        setError: hookFormSetError,
        getFieldState: hookFormGetFieldState,
    } = useForm({
        defaultValues: processedDefaultValues,
    });

    const [formDidMount, setFormDidMount] = useState<boolean>(false);

    // Save allowed component values to storage
    useEffect(() => {
        if (!tabs || !formKey) {
            return;
        }

        const valuesMap: AssocArray<Array<FormComponentValue>> = {};

        const processComponent = (component: RawFormComponentType) => {
            if (component.components) {
                component.components.forEach((c) => processComponent(c));
            }
            const elementAllowedValues = prepareFormComponentValues(component.data);
            if (elementAllowedValues.length > 0 && component?.name?.length > 0) {
                valuesMap[component.name] = elementAllowedValues;
            } else {
                // TODO: warning?
            }
        };

        tabs.formTabs.forEach((tab) =>
            tab.components.forEach((component) => {
                processComponent(component);
            })
        );
        dispatch(setFormAllowedValues({ formKey: formKey, values: valuesMap }));
        return () => {
            dispatch(unsetForm({ formKey: formKey }));
        };
    }, []);

    // Update allowed values list for component
    const refreshComponentData = function (
        controlProps: FormControlProps,
        updateComponentValues?: <T>(response: T) => Array<FormComponentValue>
    ): Promise<any> {
        if (!formRendererProps?.settingsUrl) {
            throw 'settingsUrl is not set, but call refreshComponentData';
        }

        const params: Array<string> = ['uid=' + controlProps.uid];

        if (controlProps?.urlParams) {
            for (const [k, v] of Object.entries(controlProps.urlParams)) {
                params.push(`${k}=${v}`);
            }
        }

        let fullSettingsUrl = formRendererProps.settingsUrl + '/refresh-component?' + params.join('&');

        return formAPI.load(fullSettingsUrl).then((response: any) => {
            if (updateComponentValues) {
                setComponentData(controlProps.name, updateComponentValues(response));
            }
            return response;
        });
    };
    const setComponentData = (name: string, values: Array<FormComponentValue>) => {
        dispatch(
            setFormComponentAllowedValues({
                formKey: formKey ?? '',
                componentName: name,
                values: values,
            })
        );
    };

    const findComponent = (components: Array<RawFormComponentType>, key: string): RawFormComponentType | null => {
        let result = null;
        for (const [componentKey, component] of Object.entries(components) as [string, any]) {
            if (result) {
                break;
            }
            if (component.uid == key) {
                result = component;
                break;
            }
            if (!result && component.uid === '' && component.components && component.components.length > 0) {
                result = findComponent(component.components, key);
            }
        }
        return result;
    };

    const scrollToElement = (tab: FormTabType, component: RawFormComponentType) => {
        const formId = getFormId(formRendererProps?.uid ?? '', isNew)
            .replaceAll('/', '\\/')
            .replaceAll('=', '\\=')
            .replaceAll('?', '\\?');
        const elementsList = document.querySelectorAll(`#${formId} input[name="${component.name}"]`);

        if (elementsList.length > 0) {
            const element = elementsList.item(0) as HTMLInputElement;
            const wrapper = element.closest('main');
            const scrollWrapper =
                (element.closest('.MuiDialog-scrollBody') as HTMLElement) || //popup
                (element.closest('.scroll-content-container') as HTMLElement) || //form
                window;

            if (!tab.isActive && formRendererProps?.isPopup != true && tabs) {
                const tabIndex = tabs.formTabs.findIndex((t) => t.name == tab.name);
                if (tabIndex != -1) {
                    tabs.handleTabChange(true, tabIndex);
                }
            }
            setTimeout(() => {
                if (wrapper) {
                    //ToDo wrapper.offsetTop can return another value if parent element has 'position',
                    // then need use another calc
                    scrollWrapper.scrollTo({
                        top: wrapper.offsetTop - scrollWrapper.offsetTop - 10,
                        behavior: 'smooth',
                    });
                }
                if (element.tagName == 'INPUT' || element.tagName == 'TEXTAREA') {
                    setTimeout(() => element.focus(), 500);
                }
            }, 100);
            return true;
        }
        return false;
    };

    const ctx = useGlobalContext();
    const [setInfoPopup] = [ctx.setInfoPopup];
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    // Save utility
    const [triggerSave, setTriggerSave] = useState<number>(0);
    const triggerSaveRef = useRef<number>(0);

    const saveResponseRef = useRef<any>({});

    const { isDirty, dirtyFields } = useFormState({ control: control });
    const isDirtyRef = useRef(isDirty);
    useEffect(() => {
        isDirtyRef.current = isDirty;
    }, [isDirty]);

    let [loadingMaskText, setLoadingMaskText] = useState<string>('');
    let [loadingMaskShow, setLoadingMaskShow] = useState<boolean>(false);

    function actSubmit(data: any): Promise<any> {
        if (!isDirtyRef.current && !formRendererProps?.isPopup) {
            return Promise.resolve({ data: { status: 'NOT_DIRTY', data: data } });
        }
        return formActions.formAct.act.save.exec(data);
    }

    const actSave = (data: any): Promise<any> => {
        setLoadingMaskText(t('saving___'));
        setLoadingMaskShow(true);
        const method = formRendererProps?.popupType == 'duplicate' ? formAPI.duplicate : formAPI.saveFormData;
        return method(formRendererProps?.settingsUrl ?? '', data, formActions)
            .then((response: any) => {
                saveResponseRef.current = response.data;
                setLoadingMaskShow(false);
                if ('ERROR' === response.data.status) {
                    formActions.formAct.act.afterSaveError.exec(response);
                } else {
                    formActions.formAct.act.afterSaveSuccess.exec(response, data);
                }
                return response;
            })
            .catch((e: any) => {
                setLoadingMaskShow(false);
                console.log(e);
            });
    };

    const actAfterSaveSuccess = (response: any, data: any) => {
        for (const [key, value] of Object.entries(data)) {
            const currentValue = getValues(key);
            if (typeof currentValue != 'undefined' && currentValue != value) {
                hookFormSetValue(key, value, { shouldDirty: false });
            }
        }
        hookFormReset(data, { keepValues: true, keepDefaultValues: false });
        hookFormClearErrors();

        if ('function' === typeof formRendererProps?.afterSave) {
            formRendererProps.afterSave(response);
        } else if (response.data.infoPopupMessage) {
            setInfoPopup({ message: response.data.infoPopupMessage });
        } else if (isNew && response.data.redirectUrl) {
            safeNavigate(navigate, response.data.redirectUrl);
        } else if (response.data.reloadPage) {
            setTimeout(() => location.reload(), 100);
        } else if (response.data.updatedFields) {
            for (const [key, value] of Object.entries(response.data.updatedFields)) {
                if (key === 'reloadGridData') {
                    if (Array.isArray(value)) {
                        value.forEach((gridName) => {
                            queryClient.invalidateQueries({
                                queryKey: [gridName],
                            });
                        });
                    }
                } else {
                    if (value === '********') {
                        hookFormSetValue(key, value, { shouldDirty: false });
                    } else {
                        hookFormSetValue(key, value);
                    }
                }
            }
        }
        if (formRendererProps?.isPopup) {
            formRendererProps.onHide();
        }

        setTriggerSave(triggerSave + 1);
        triggerSaveRef.current = triggerSave + 1;
        return Promise.resolve(response);
    };

    const actAfterSaveError = (response: any) => {
        if (response.data.errors) {
            importErrorHandler(response.data.errors, hookFormSetError, t);
        } else if (response.data.messages) {
            let isScrolled = false;
            for (const [key, message] of Object.entries(response.data.messages) as [string, any]) {
                let values = Object.values(message);
                let errorMessage = values[0] as string;

                for (const [tabKey, tab] of Object.entries(tabs?.formTabs ?? {})) {
                    const component = findComponent(tab.components, key);

                    if (component) {
                        let label = component.label ?? component.name;
                        errorMessage = errorMessage.replace('{{label}}', t(label));
                        if (!isScrolled) {
                            if (scrollToElement(tab, component)) {
                                isScrolled = true;
                            }
                        }
                        break;
                    }
                }

                hookFormSetError(key, {
                    type: 'server',
                    message: errorMessage,
                });
            }
        } else {
            hookFormSetError('general', {
                type: 'server',
                message: response.data.error ?? response.data.message,
            });
        }
        return Promise.resolve(response);
    };

    const formActions: FormRendererAPIType = {
        hookFormControl: control,
        hookFormState: formState,
        hookFormGetValues: getValues,
        hookFormSetValue: hookFormSetValue,
        hookFormWatch: watch,
        hookFormHandleSubmit: handleSubmit,
        hookFormClearErrors: hookFormClearErrors,
        hookFormSetError: hookFormSetError,
        hookFormReset: hookFormReset,
        hookFormGetFieldState: hookFormGetFieldState,
        addElementValuesRegister: addElementValuesRegister,
        getElementValuesRegister: getElementValuesRegister,
        formDidMount: formDidMount,
        setFormDidMount: setFormDidMount,
        formKey: formKey ?? '',
        formRefetch: formRefetch ?? ((resetField?: boolean) => Promise.resolve(false)),
        formSave: function () {
            return this.formAct.act.submit.exec(getValues());
        },
        tabs: tabs,
        onDelete: onDelete ?? (() => {}),
        onAdd: onAdd ?? (() => {}),
        onDuplicate: onDuplicate ?? (() => {}),
        getSaveResponse: () => saveResponseRef.current,
        handleRefreshComponentData: !formRendererProps?.settingsUrl ? false : refreshComponentData,
        setComponentData: setComponentData,
        triggerSave: triggerSaveRef,
        formTranslation: t ?? null,
        settingsUrl: formRendererProps?.settingsUrl,
        formAct: new FormActs({
            submit: new ActSubmit(actSubmit),
            afterSaveError: new ActAfterSaveError(actAfterSaveError),
            afterSaveSuccess: new ActAfterSaveSuccess(actAfterSaveSuccess),
            save: new ActSave(actSave),
        }),
    };

    return {
        data: processedDefaultValues,
        form: formActions,
        state: {
            loadingMaskText: loadingMaskText,
            loadingMaskShow: loadingMaskShow,
        },
    };
}
