import React, { useEffect, useState } from 'react';
import { alpha, Box, Button } from '@mui/material';
import { FormPanelButtonType, FormPanelSettings, FormRendererAPIType } from 'components/common/form';
import { AssocArray } from 'tools/types';
import FormPanelButton from './FormPanelButton';
import { createPortal } from 'react-dom';

const defaultPanelButtons = {
    add: {
        component: 'FormPanelButtonAdd',
        label: 'add',
        show: true,
        props: {},
    },
    search: {
        component: 'FormPanelButtonSearch',
        label: 'search',
        show: true,
        props: {},
    },
    delete: {
        component: 'FormPanelButtonDelete',
        label: 'delete',
        menuItemDesc: undefined,
        show: true,
        props: {},
    },
    duplicate: {
        component: 'FormPanelButtonDuplicate',
        label: 'panel_btn_duplicate_label',
        menuItemDesc: 'panel_btn_duplicate_desc',
        show: false,
        props: {},
    },
    save: {
        component: 'FormPanelButtonSave',
        label: 'save',
        show: true,
        separatorBefore: true,
        props: {},
    },
} as AssocArray<FormPanelButtonType<any>>;

export function processingPanelButton(key: string, button: any) {
    if (button === false) return null;

    const isDefault = typeof defaultPanelButtons[key] != 'undefined';

    if (isDefault) {
        //button == true: return default template obj
        if (button === true) return defaultPanelButtons[key];

        //return merge of default template obj + props
        if (undefined === button.component && (true === button.show || undefined === button.show))
            return { ...defaultPanelButtons[key], ...button };
    }

    //Else return custom button component
    if (true === button.show || undefined === button.show) return button;

    return null;
}

export default function FormTopControlPanel({
    panelSettings,
    formActions,
}: {
    panelSettings?: FormPanelSettings;
    formActions: FormRendererAPIType;
}) {
    if (typeof panelSettings == 'undefined') {
        panelSettings = { buttons: defaultPanelButtons };
    }

    if (typeof panelSettings.buttons == 'undefined') {
        panelSettings.buttons = defaultPanelButtons;
    }

    const buttonsList: any[] = [];
    const [container, setContainer] = useState<HTMLElement | null>(null);

    const submitId = btoa(Date.now().toString(36) + Math.random().toString(36));

    useEffect(() => {
        if (panelSettings?.buttonsContainer?.length) {
            const element = document.getElementById(panelSettings.buttonsContainer);
            if (element) {
                setContainer(element);
            }
        } else {
            setContainer(null);
        }
    }, [panelSettings.buttonsContainer]);

    for (const [key, button] of Object.entries(panelSettings.buttons)) {
        const buttonObj = processingPanelButton(key, button);

        if (buttonObj) {
            if (buttonObj.separatorBefore)
                buttonsList.push(
                    <Box
                        key={`separator_${key}`}
                        sx={{
                            width: '1px',
                            height: '16px',
                            backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08), //divider
                            mx: 1.5,
                        }}
                    />
                );
            buttonsList.push(
                <FormPanelButton
                    submitId={submitId}
                    key={key}
                    formActions={formActions}
                    buttonName={key}
                    button={buttonObj}
                />
            );
        }
    }

    const content = <Box sx={{ alignItems: 'center', display: 'flex', position: 'relative' }}>{buttonsList}</Box>;
    return (
        <>
            <Button id={submitId} sx={{ display: 'none' }} type="submit"></Button>
            {container ? createPortal(content, container) : content}
        </>
    );
}
