import { FormControlCheckboxProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import React, { useEffect, useMemo, useState } from 'react';
import FormCheckbox, { FormCheckboxBuilder } from 'components/common/form/layout/control/FormCheckbox';
import { getElementFieldValue } from 'components/common/form/formTools';

export interface DatasetBackFillProps extends FormControlCheckboxProps {}

export default function DatasetBackFill({
    controlProps,
    elementProps,
}: FormElementControlPropsType<DatasetBackFillProps>) {
    const [formReady, setFormReady] = useState(false);

    const checkValue = (value: any) => {
        const hist = getElementFieldValue(controlProps.form, 'dataset_source.hist', {});
        const hasPattern = getElementFieldValue(controlProps.form, 'data_fetch_command.has_regular_pattern', {});

        const A = value['save_historical_instances_ind'] == 'Y';
        const B = value['measurement_time_source'] == 'command';
        const C = value['data_fetch_method'] == 'dataset';
        const D = hist == 'Y';
        const E = hasPattern == 'Y';
        const F = value['data_fetch_method'] == 'plugin';

        const result = !(A && B && ((C && D) || E || F));
        if (result) {
            controlProps.form.hookFormSetValue(controlProps.name, 'N');
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setFormReady(true);
            checkValue(controlProps.form.hookFormGetValues());
        }, 3000);

        const subscription = controlProps.form?.hookFormWatch((value, { name, type }) => {
            if (!formReady) {
                return;
            }
            if (
                [
                    'data_fetch_command',
                    'save_historical_instances_ind',
                    'measurement_time_source',
                    'data_fetch_method',
                    'dataset_source',
                    'data_fetch_method',
                ].includes(name ?? '')
            ) {
                checkValue(value);
            }
        });
        return () => subscription?.unsubscribe();
    }, [formReady, controlProps.form?.hookFormWatch]);

    return useMemo(
        () => <FormCheckbox controlProps={controlProps} elementProps={elementProps} />,
        [JSON.stringify(controlProps)]
    );
}

export class DatasetBackFillBuilder extends FormCheckboxBuilder {}
