import React, { useState } from 'react';
import { Params as UrlParams } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useFormState } from 'react-hook-form';
import { formAPI } from 'api/form';
import { Popup } from 'components/common/popup/Popup';
import GridPopup from 'components/common/grid/GridPopup';
import IconHandler from 'components/common/icon/IconHandler';
import { FormPanelButtonProps } from 'components/common/form';
import useBundleTranslation from 'i18n';
import { processSettingsUrl } from 'components/common/form/formTools';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';
import showdown from 'showdown';
import { openInNewTab } from 'tools/tools';
import pollingService from 'tools/pollingService';

const converter = new showdown.Converter();

const FormPanelGenericButton: React.FC<FormPanelButtonProps<any>> = ({ button, formActions, isSubMenuItem }) => {
    const { t } = useBundleTranslation(button.translationNS ?? 'components/common/form/form');
    const navigate = useNavigate();
    const { isDirty } = useFormState({ control: formActions.hookFormControl });

    const queryClient = useQueryClient();
    const urlParams: UrlParams = useParams();
    const [isLoading, toggleLoading] = useState(false);
    const [isModalOpen, toggleModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [translateMessage, setTranslateMessage] = useState(true);
    const icon = button.props.icon ? <IconHandler icon={button.props.icon} /> : null;
    const saveBeforeAction = button.props.saveBeforeAction ?? false;

    const responseMessagePopupSettings = {
        title: t('info'),
        textOK: t('ok'),
        noCancel: true,
    };

    const errorMessagePopupSettings = {
        title: t('error'),
        noButtons: true,
    };

    const url = processSettingsUrl(button.props.url ?? '', Object.keys(urlParams), urlParams);
    const popupUrl = button.props.ignoreCache ? `${url}?v=${Date.now()}` : url;

    const updateFormField = () => {
        if (button.props.fieldsToUpdateBeforeSave) {
            Object.entries(button.props.fieldsToUpdateBeforeSave).forEach((item) => {
                formActions.hookFormSetValue(item[0], item[1] ?? '');
            });
        }
    };

    const onSuccess = () => {
        toggleLoading(false);
    };

    const onError = (response: { message: string }) => {
        toggleLoading(false);
        setErrorMessage(response.message);
    };

    const handleClick = async () => {
        if (button.props.checkFormBeforeSave && isDirty) {
            alert(t('save_before_change_alert'));
            return;
        }

        if (button.props?.confirmBefore) {
            if (!confirm(t(button.props.confirmBefore))) {
                return;
            }
        }

        if (button.props.alertMessage) {
            alert(t(button.props.alertMessage));
            return;
        }

        if (saveBeforeAction && isDirty) {
            const saveResponse = await formActions.formSave();
            if (saveResponse?.data?.status != 'OK') {
                return;
            }
        }

        if (url && !button.props.popupConfig) {
            toggleLoading(true);
            setMessage('');
            setErrorMessage('');
            try {
                let response = null;

                if (button.props.isPost === true) {
                    response = await formAPI.loadByPost(url);
                } else {
                    response = await formAPI.load(url);
                }

                const isOk = response.data?.status === 'OK';

                if (response.data?.status === 'QUEUE') {
                    const { run } = pollingService({
                        onSuccess,
                        onError,
                        requestData: {},
                    });

                    run(response.data?.data.ppl_id);
                    return;
                }

                if (isOk && button.props.gridIds) {
                    await queryClient.invalidateQueries(button.props.gridIds);
                }
                const responseMessage = response?.data?.message || response?.data?.data?.message;
                const successMessage = button.props.successText ? t(button.props.successText) : responseMessage;
                const failedMessage = responseMessage
                    ? responseMessage
                    : button.props.failedText
                    ? t(button.props.failedText)
                    : t('error');
                if (button.props.successText) setTranslateMessage(false);
                else
                    setTranslateMessage(
                        response?.data?.data?.translateMessage ?? response?.data?.translateMessage ?? true
                    );

                setMessage(isOk ? successMessage : '');

                toggleLoading(false);
                if (isOk && response.data.redirectUrl) {
                    navigate(response.data.redirectUrl);
                }

                if (isOk && button.props.refreshForm) {
                    await formActions.formRefetch(button.props?.resetAfterRefreshForm);
                }

                if (!isOk) {
                    setErrorMessage(failedMessage);
                }
            } catch (error: any) {
                setErrorMessage(
                    button.props.failedText ?? error?.response?.data?.message ?? error?.message ?? t('error')
                );
                toggleLoading(false);
            }
        }

        if (button.props.popupConfig) {
            toggleModalOpen(true);
        }

        if (button.props.type === 'submit') {
            await updateFormField();
            await formActions.formSave();
        }

        if (button.props.redirectTo) {
            let redirectTo = processSettingsUrl(button.props.redirectTo, Object.keys(urlParams), urlParams);
            const matches = redirectTo.match(/\{([^}]+)\}/g);
            if (matches)
                for (const match of matches) {
                    let value = formActions.hookFormGetValues(match.replace('{', '').replace('}', ''));

                    if (value) redirectTo = redirectTo.replaceAll(match, value);
                    else redirectTo = redirectTo.replaceAll(match, '');
                }

            if (button.props.redirectNewTab) {
                openInNewTab(redirectTo);
            } else {
                navigate(redirectTo);
            }
        }
    };

    if (button.props?.visible == 'N') {
        return null;
    }

    return (
        <>
            {isSubMenuItem ? (
                <ActionMenuItem
                    label={t(button.label)}
                    desc={button.menuItemDesc ? t(button.menuItemDesc) : undefined}
                    icon={button.props.icon?.type === 'mi' ? button.props.icon.value : undefined}
                    onClick={handleClick}
                />
            ) : (
                <Button
                    sx={{ mr: button.props?.mr ?? 1 }}
                    onClick={handleClick}
                    variant={button.props.variant}
                    type={button.props.type ?? 'button'}
                    startIcon={isLoading ? null : icon}
                    disabled={button.props.disabled || isLoading}
                >
                    {isLoading && <CircularProgress sx={{ marginRight: '5px' }} color="inherit" size={12} />}
                    {t(button.label)}
                </Button>
            )}

            {message && (
                <Popup settings={responseMessagePopupSettings} maxWidth="popupSm" onHide={() => setMessage('')} open>
                    {translateMessage ? (
                        <Typography>{t(message)}</Typography>
                    ) : (
                        <span dangerouslySetInnerHTML={{ __html: converter.makeHtml(message) }}></span>
                    )}
                </Popup>
            )}

            {errorMessage && (
                <Popup settings={errorMessagePopupSettings} maxWidth="popupSm" onHide={() => setErrorMessage('')} open>
                    {translateMessage ? (
                        <Typography>{t(errorMessage)}</Typography>
                    ) : (
                        <span dangerouslySetInnerHTML={{ __html: converter.makeHtml(errorMessage) }}></span>
                    )}
                </Popup>
            )}

            {button.props.popupConfig && url && isModalOpen && (
                <GridPopup
                    popupUrl={popupUrl}
                    handleHidePopup={() => toggleModalOpen(false)}
                    handleConfirmPopup={() => {}}
                    popupConfig={button.props.popupConfig}
                    popupType={button.props.popupConfig?.type ?? 'form'}
                    popupMode={button.props.popupConfig?.mode ?? 'new'}
                    redirectAfter={button.props.popupConfig?.redirectAfter ?? false}
                />
            )}
            {isLoading && button.props.isPost && (
                <LoadingPlaceholder>
                    <Typography>{t(button.props.loadingText ?? 'loading')}</Typography>
                </LoadingPlaceholder>
            )}
        </>
    );
};

export default FormPanelGenericButton;
