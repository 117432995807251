import {
    ReferenceHierarchyResponse,
    ReferenceInfoResponse,
    ReferenceMetadataResponse,
} from 'components/external-reference-selection';
import { APIResponse } from 'tools/types';
import { instance } from 'api/api';

export const referenceAPI = {
    async getReferenceInfo(
        profileId: number,
        referenceId: number,
        collectionId?: number
    ): Promise<ReferenceInfoResponse> {
        if (isNaN(profileId) || isNaN(referenceId) || profileId == 0 || referenceId == 0) {
            return { reference: false };
        }

        const url = `/data/reference/get-reference-info/connection_profile_id/${profileId}/external_report_reference_id/${referenceId}`;
        let params = {};
        if (collectionId) {
            params = {
                collection_id: collectionId,
            };
        }
        const response = await instance.get<APIResponse<ReferenceInfoResponse>>(url, { params: params });
        return response.data.data;
    },

    async getReferenceHierarchy(profileId: number, capability: string): Promise<ReferenceHierarchyResponse> {
        const url = `/data/reference/get-reference-hierarchy/connection_profile_id/${profileId}`;
        const response = await instance.get<APIResponse<ReferenceHierarchyResponse>>(url, {
            params: {
                capability: capability,
            },
        });
        return response.data.data;
    },
    async getReferenceMetadata(referenceId: number): Promise<ReferenceMetadataResponse> {
        const url = `/data/reference/${referenceId}/metadata`;
        const response = await instance.get<APIResponse<ReferenceMetadataResponse>>(url);
        return response.data.data;
    },
    async getSourceDatasetMetadata(sourceDatasetId: number): Promise<ReferenceMetadataResponse> {
        const url = `/data/source-dataset/${sourceDatasetId}/metadata`;
        const response = await instance.get<APIResponse<ReferenceMetadataResponse>>(url);
        return response.data.data;
    },
    async getExternalSourceMetadata(externalSourceId: number): Promise<ReferenceMetadataResponse> {
        const url = `/data/external-source/${externalSourceId}/metadata`;
        const response = await instance.get<APIResponse<ReferenceMetadataResponse>>(url);
        return response.data.data;
    },
};
