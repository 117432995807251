import React, { useContext, useMemo, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import useBundleTranslation from 'i18n';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { AssocArray } from 'tools/types';
import ReactHookFormController, { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentSettingsMapChart = ReportContentNS.ComponentSettingsMapChart;
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import { MapsList } from 'components/report-content/components/map-chart/MapsList';
import { FormComponentValue } from 'components/common/form/layout/control';
import AggregationField from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/AggregationField';
import EditPanelColorPicker from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelColorPicker';
import fieldToChartMeasuredField = ReportContentNS.fieldToChartMeasuredField;
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsMapChart> = [
    'title',
    'show_in_viewer',
    'include_in_email',
    'include_in_email_attachment',
    'map',
    'group_field',
    'display_label',
    'display_full_label',
    'color_start',
    'color_end',
];
const componentNumericFormField: Array<keyof ComponentSettingsMapChart> = ['height'];

export default function MapChartEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsMapChart> & { block: BlockType<ComponentSettingsMapChart> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const datasetFields = editPanelContext?.datasetFields ?? [];
    const [measuredField, setMeasuredField] = useState(component.settings.measureFields[0]);

    // Prepare data for Form
    const defaultState: AssocArray<any> = {};
    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process measure fields settings
        const newSettings: ComponentSettingsMapChart = {
            ...component.settings,
        } as ComponentSettingsMapChart;
        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        const dataQueries: ComponentSettingsChartDataQueries = {
            table: {
                resultFields: [newSettings.group_field, measuredField.reference_name],
                resultFieldAggregations: {
                    [measuredField.reference_name]: measuredField.aggregation_function,
                },
            },
        };

        const resultSettings = {
            ...newSettings,
            measureFields: [{ ...measuredField }],
            dataQueries: dataQueries,
        } as ComponentSettingsMapChart;

        editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
    };

    // Prepare map Select
    const mapsListData: Array<FormComponentValue> = useMemo(
        () =>
            MapsList.map((row) => ({
                label: row.name,
                value: row.filename,
                valueGroup: row.parent,
            })),
        [MapsList]
    );

    const isDisplayFullLabel = elementProps.form.hookFormWatch('display_label') != 'N';
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <Stack height={1}>
            {/*Main Panel*/}
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={t('map_chart.edit_title')}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: { component: 'FormText', name: 'title', label: t('title') },
                        })}
                    />
                </Box>
                <Divider />

                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                    <Box sx={{ width: '72px' }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: { component: 'FormText', name: 'height', label: '' },
                            })}
                        />
                    </Box>
                </Stack>
                <Divider />

                <Box>
                    <FormLabel>{t('map_chart.map')}</FormLabel>
                    <TemplateFormComponent
                        elementProps={elementProps}
                        componentValues={mapsListData}
                        component={'FormSelect'}
                        name={'map'}
                    />
                </Box>

                <Box>
                    <FormLabel>{t('map_chart.location')}</FormLabel>
                    <ReactHookFormController
                        componentValues={datasetFields.map((field) => ({
                            label: field.column_name,
                            value: field.reference_name,
                        }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'group_field',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>

                <AggregationField
                    label={t('values')}
                    allowedFields={datasetFields}
                    measuredField={measuredField}
                    setMeasuredField={setMeasuredField}
                    fieldToMeasuredField={fieldToChartMeasuredField}
                />
                <Divider />

                <Box>
                    <FormLabel>{t('map_chart.display_label')}</FormLabel>
                    <TemplateFormComponent
                        elementProps={elementProps}
                        componentValues={[
                            { label: 'No', value: 'N' },
                            { label: 'Yes', value: 'Y' },
                            { label: 'Only with data', value: 'with_data_only' },
                        ]}
                        component={'FormRadioGroup'}
                        name={'display_label'}
                    />
                </Box>

                <Box className={isDisplayFullLabel ? '' : 'd-none'}>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'display_full_label'}
                        label={t('map_chart.display_full_label')}
                    />
                </Box>

                <Divider />

                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>{t('map_chart.colors_range')}</Box>
                    <EditPanelColorPicker
                        elementProps={elementProps}
                        name={'color_start'}
                        icon={{ type: 'mi', value: 'color-fill', fill: 'text' }}
                    />
                    <EditPanelColorPicker
                        elementProps={elementProps}
                        name={'color_end'}
                        icon={{ type: 'mi', value: 'color-fill', fill: 'text' }}
                    />
                </Stack>

                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                    <Box sx={{ width: '72px' }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: { component: 'FormText', name: 'height', label: '' },
                            })}
                        />
                    </Box>
                </Stack>
                <Divider />
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'show_in_viewer'}
                        label={t('show_in_viewer')}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'include_in_email'}
                        label={t('include_in_email')}
                    />
                </Box>
                <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                <Box>
                    <GetEmbedCode contentSettings={contentSettings} height={actualHeight} blockUID={block.uid} t={t} />
                </Box>
                <Divider />
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={handleApply}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
        </Stack>
    );
}
