import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import { ReportContentNS } from 'components/report-content/index';
import ContentMode = ReportContentNS.ContentMode;
import ContentSettings = ReportContentNS.ContentSettings;
import ManageFiltersInstance = ReportContentNS.ManageFiltersInstance;
import { YNValues } from 'tools/types';
import DisplayMask = ReportContentNS.DisplayMask;
type SegmentSelection = 'none' | 'compound' | 'simple';

export interface ReportFilterSegmentValue {
    segment_value_id: number;
    parent_segment_value_id: number | null;
    segment: string;
}

export interface ReportFilterSegment {
    segment_id: number;
    type: SegmentSelection;
    hidden: YNValues;
    parent_segment_id: number | null;
    name: string;
    values: Array<ReportFilterSegmentValue>;
}

export interface ReportFilterSegmentValuesData {
    segment_value_id: number;
    segments: Array<ReportFilterSegment>;
}

export interface DatasourceColumnsDataResponse {
    has_access_map: boolean;
}

export default function useReportContentSettings(
    contentMode: ContentMode,
    elementId: number,
    segmentValueId: number,
    displayMasks: Array<DisplayMask>,
    datasetFilter?: string, // DataSource {dataset}_{filter}
    elementSegmentId?: number, // Used in editor to reload segment values list
    forScreenShot?: boolean
) {
    const queryKey = `report_content_editor_${elementId}_instances`;

    const [settings, setSettings] = useState<ContentSettings>({
        elementId: elementId,
        segmentValueId: segmentValueId,
        context: contentMode,
        pageSize: 500,
        applyAccessMap: contentMode == 'view' ? 'Y' : 'N',
        instances: [],
        hasInstances: false,
        forScreenShot: forScreenShot ?? false,
        segmentValues: {
            segment_value_id: 0,
            segments: [],
        },
        displayMasks: displayMasks,
    });

    const { data: instancesData, isLoading: instancesLoading } = useQuery<
        { instances: Array<ManageFiltersInstance>; hasInstances: boolean },
        Error
    >([queryKey], () => {
        return reportAPI.getInstances(settings.elementId, settings.segmentValueId);
    });

    useEffect(() => {
        if (!settings.measurement_time && instancesData?.instances?.length) {
            setSettings((prev) => {
                return {
                    ...prev,
                    instances: instancesData.instances,
                    measurement_time: instancesData.instances[0].measurement_time_raw,
                    hasInstances: instancesData.hasInstances,
                };
            });
        } else {
            setSettings((prev) => {
                return {
                    ...prev,
                    instances: [],
                    measurement_time: undefined,
                    hasInstances: false,
                };
            });
        }
    }, [instancesData]);

    const segmentQueryKey = (settings.measurement_time ?? '') + '_' + (elementSegmentId ?? '0');

    const { data: segmentValuesData, isLoading: segmentsLoading } = useQuery<ReportFilterSegmentValuesData, Error>(
        [`report_content_editor_${elementId}_segment_values`, segmentQueryKey],
        () => {
            return reportAPI.getSegmentValuesList(
                settings.elementId,
                settings.segmentValueId,
                settings.measurement_time
            );
        }
    );

    useEffect(() => {
        if (!segmentValuesData) {
            return;
        }
        setSettings((prev) => {
            const result = { ...prev, segmentValues: segmentValuesData };
            if (segmentValuesData.segments.length) {
                const segment = segmentValuesData.segments[0].values.find(
                    (v) => v.segment_value_id == prev.segmentValueId
                );

                if (!segment && !forScreenShot) {
                    result.segmentValueId = segmentValuesData.segments[0].values?.[0]?.segment_value_id ?? 0;
                }
            }

            return result;
        });
    }, [segmentValuesData]);

    const datasourceQueryKey = `report_content_editor_datasource_${elementId}_` + (datasetFilter ?? '');
    const { data: hasUserMap, isLoading: userMapLoading } = useQuery<Boolean, Error>([datasourceQueryKey], () => {
        if (!datasetFilter) {
            return false;
        }
        const tmp = datasetFilter.split('_');
        const ds = tmp?.[0] ?? '';
        const filter = tmp?.[1] ?? '';

        return ds == '' || ds == '0' ? false : reportAPI.getColumnsSource(ds, filter);
    });

    return {
        settings,
        setSettings,
        instancesData,
        hasUserMap,
        forScreenShot,
        segmentsLoading,
        instancesLoading,
        userMapLoading,
    };
}
