import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ColumnType, GridData } from 'components/common/grid';
import useBundleTranslation from 'i18n';
import MITable from 'components/common/grid/MITable';

interface ISourcedContainerProps {
    gridData: GridData;
    columns: ColumnType[];
    reloadGridData: () => void;
}

export default function SourcedContainer({ gridData, columns, reloadGridData }: ISourcedContainerProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    return gridData.length > 0 ? (
        <Stack sx={{ padding: '24px' }} spacing={2}>
            <Box key={`row-grid-title`}>
                <Typography variant="heading" width={'100%'}>
                    {`${gridData.length} ${t(
                        gridData.length > 1 ? 'add_metric.sourced.metrics' : 'add_metric.sourced.metric'
                    )} ${t('add_metric.sourced.already')}`}
                </Typography>
            </Box>
            <MITable
                gridName={'dataset_sourced_metrics'}
                columns={columns}
                data={gridData}
                massActionsConfig={{ enabled: false }}
                reloadGridData={reloadGridData}
                columnsWidth={{
                    edit: 50,
                    delete: 50,
                }}
                t={t}
            />
        </Stack>
    ) : null;
}
