import React, { useEffect, useRef, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { useDispatch } from 'react-redux';
import { authAPI } from 'api/api';
import { setBrandThemeProperties } from 'store/auth';
import { ActSubmit } from 'components/common/form/act';

export default function BrandForm(props: IFormLayoutProps) {
    const dispatch = useDispatch();
    const isNewMode = props.isNew;
    const isFormChanged = useRef<boolean>(false);
    const [themeId, setThemeId] = useState<any>(props.hookForm.form.hookFormGetValues('theme_id'));

    useEffect(() => {
        return () => {
            if (!isNewMode) {
                if (isFormChanged.current) {
                    //reload brandThemeProperties if changed theme id and leave it
                    authAPI.info().then((response) => {
                        if (response.statusText == 'OK' && response.data?.userAuth?.userInfo?.brandThemeProperties) {
                            dispatch(setBrandThemeProperties(response.data.userAuth.userInfo.brandThemeProperties));
                        }
                    });
                }
            }
        };
    }, []);

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                //check before save if changed theme id
                isFormChanged.current = themeId != props.hookForm.form.hookFormGetValues('theme_id');
                return super.exec(data);
            }
        }
    );

    return <FormLayoutComposer props={props} />;
}
