import React from 'react';
import { components } from 'react-select';
import { Box } from '@mui/material';

export const ReactSelectSelectContainer = (props: any) => {
    return (
        <Box
            className={'custom_select_holder multi_one_row_mode_placeholder'}
            sx={{
                width: 1,
                position: 'relative',
                minHeight: (theme) => theme.size.defaultHeight,
            }}
        >
            <components.SelectContainer {...props}>{props.children}</components.SelectContainer>
        </Box>
    );
};
