import useWallContext from 'components/wall/hooks/useWallContext';
import { useEffect, useState } from 'react';

export default function useAddComment(
    pointType: 'alert_event' | 'user_annotation' | 'notable_event',
    id: string,
    onSuccess: Function
) {
    const { wallActions, setEditAnnotationId, setEditEventId } = useWallContext(false);

    const handleAddCommentClick = () => {
        wallActions.setActiveAddCommentField(pointType, id);
        onSuccess();
    };

    return { handleAddCommentClick, setEditAnnotationId, setEditEventId };
}
