import { Theme, alpha } from '@mui/material';

export default {
    certificatedRow: {
        fontSize: '14px',
        marginBottom: '5px',
        paddingRight: '45px',
        cursor: 'pointer',
        display: 'flex',
    },
    box: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px',
        width: '50%',
    },
    followingTables: {
        display: 'flex',
        padding: 3,
        flexDirection: 'column',
        width: '50%',
    },
    tableTitle: {
        fontWeight: '700',
    },
    tableListWrapper: {
        mb: 2,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    image: {
        padding: '0 5px',
        minWidth: '230px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        maxHeight: '180px',
        overflow: 'hidden',

        img: {
            cursor: 'pointer',
        },
    },
    noImage: {
        padding: '0 5px',
        minWidth: '230px',
        display: 'flex',
        maxHeight: '180px',
        overflow: 'hidden',
    },
    lineage: {
        padding: 0,
    },
    clickButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
        minWidth: '80px',
        padding: '5px 12px',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
        cursor: 'pointer',
        '&:hover': {
            color: (theme: Theme) => theme.palette.primary.main,
            borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.2),
            background: (theme: Theme) => alpha(theme.palette.primary.main, 0.2),
        },
    },
    item: {
        display: 'flex',
        alignItems: 'start',

        svg: {
            margin: '3px 6px 3px 0',
            marginRight: '6px',
        },
        img: {
            marginRight: '3px',
        },
        p: {
            padding: '3px 0',
            p: {
                margin: 0,
                padding: '3px 0',
            },
        },
    },
    labelWrapper: {
        display: 'flex',
    },
    value: {
        paddingLeft: '8px !important',
        wordBreak: 'break-all',
        padding: '3px 0',
    },
    label: {
        fontWeight: 'bold',
    },
    emptyItem: {
        '& > p:last-child': {
            paddingRight: '22px',
        },
        '& > div:first-child': {
            paddingLeft: '22px',
        },
    },
    description: {
        fontSize: '12px',
        marginBottom: '10px',
        marginTop: '10px',

        '& p': {
            margin: 0,
        },
    },
    row: {
        fontSize: '12px',
        flexGrow: 1,
        marginRight: '15px',

        mark: {
            color: (theme: Theme) => theme.palette.primary.main,
            background: (theme: Theme) => alpha(theme.palette.primary.main, 0.2),
            borderBottom: '1px solid',
        },
    },
    placeholder: {
        fontSize: '14px',
        textAlign: 'center',
        padding: '20px',
    },
    tags: {
        display: 'flex',

        img: {
            marginRight: '5px',
        },
    },
    tag: {
        marginLeft: '10px',
        lineHeight: '18px',
        borderRadius: '3px',
        padding: '0 8px',
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
        cursor: 'pointer',
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),

        '&:hover': {
            color: (theme: Theme) => theme.palette.primary.main,
            borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.2),
            background: (theme: Theme) => alpha(theme.palette.primary.main, 0.2),
        },
    },
    rowInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2,

        ul: {
            paddingLeft: '15px',
            margin: 0,
        },
    },
    rowInfoHalf: {
        width: '100%',
        '.MuiTypography-root': {
            marginBottom: '2px',
        },
    },
    certified: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        color: (theme: Theme) => theme.palette.success.main,

        svg: {
            marginRight: '5px',
        },
    },
};
