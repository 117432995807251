import React, { useEffect, useContext } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Checkbox, IconButton, Stack, Tooltip } from '@mui/material';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from 'components/plugin-query-builder/query-builder/QueryBuilderFieldsGrid';
import IconMi from 'components/common/icon/IconMi';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import styles from './QueryBuilderGrid.styles';

export interface QueryBuilderFieldsGridRowProps {
    elementId?: number; //strict
    pluginName?: string;
    pluginTitle?: string;
    data: any;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    dropCard: (dragIndex: number) => void;
    deleteItem: () => void;
    editItem: () => void;
    onShowAliasPopup: () => void;
    index: number;
    readonly?: boolean;
    editorData?: any;
    editorAct?: any;
}

function DimensionItemRow(props: {
    item: any;
    dragConf: any;
    readonly?: boolean;
    editItem: () => void;
    deleteItem: () => void;
    onShowAliasPopup: () => void;
    selectItemToggle: (newState?: boolean) => void;
    editorData?: any;
    editorAct?: any;
}) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const {
        item,
        dragConf,
        readonly = false,
        editItem = () => {},
        deleteItem = () => {},
        onShowAliasPopup = () => {},
        selectItemToggle = (newState?: boolean) => {},
        editorData,
        editorAct,
    } = props;
    const { reportAct, reportData, queryData, queryAct, helperAct, pluginConfig } = useContext(PluginQBContext);
    const isCountOfRecords = item.field.name.toUpperCase() == 'COUNT(*)' && item.field.typeOriginal == 'INTEGER';
    const field = item.field;
    const metric = item.metric;

    const selectedOverrideType = field.type !== field.typeOriginal ? field.type : '';
    const selectedAggregation = metric && metric.isValid ? metric.operation.toUpperCase() : '';

    const isChecked = !readonly ? editorAct.getCheckedState(field.name, item.isChecked) : false;

    let allowedAggregation: string[] = [];
    let overrideTypesList: any[] = [];

    const setFieldName = () => {
        if (isCountOfRecords) return t('fields.count_label');

        if (item.component == 'ConstructedDateItem') {
            let dateVal = '';
            if (field.date.value === 'Date') {
                const dateValParts: string[] = [];
                dateValParts.push(queryAct.quoteField(helperAct.escapeHtml(field.date.year)));
                if (field.date.month) dateValParts.push(queryAct.quoteField(helperAct.escapeHtml(field.date.month)));
                if (field.date.day) dateValParts.push(queryAct.quoteField(helperAct.escapeHtml(field.date.day)));
                if (field.date.hour) dateValParts.push(queryAct.quoteField(helperAct.escapeHtml(field.date.hour)));
                dateVal = `date(${dateValParts.join(', ')})`;
            } else if (pluginConfig.derivedFieldFormatDateTypeIsAvailable && field.date.format.length) {
                dateVal = `formatDate(${queryAct.quoteField(
                    helperAct.escapeHtml(field.date.value)
                )}, ${queryAct.quoteField(helperAct.escapeHtml(field.date.format))})`;
            } else {
                dateVal = helperAct.escapeHtml(field.date.value);
            }
            return (
                <>
                    <Box component="strong">{helperAct.escapeHtml(field.name)}</Box> as {dateVal}
                </>
            );
        }
        if (item.component == 'ExpressionItem') {
            return (
                <>
                    <Box component="strong">{helperAct.escapeHtml(field.name)}</Box> as{' '}
                    {helperAct.escapeHtml(field.expr)}
                </>
            );
        }

        const aliasSuffix =
            pluginConfig.availableAliases && readonly && typeof field.alias === 'string'
                ? ` (${t('fields.alias_suffix')}: ${helperAct.escapeHtml(field.alias)})`
                : '';

        return helperAct.escapeHtml(field.name) + aliasSuffix;
    };
    const fieldName = setFieldName();

    const setFieldAlias = () => {
        if (!pluginConfig.availableAliases) return null;

        if (item.component == 'ConstructedDateItem' || item.component == 'ExpressionItem' || isCountOfRecords)
            return null;

        if (typeof field.alias === 'string') {
            return (
                <Stack direction={'row'} alignItems={'center'}>
                    <Box>{helperAct.escapeHtml(field.alias)}</Box>
                    <Tooltip title={t('fields_popup.edit_alias_icon_tooltip')}>
                        <IconButton onClick={onShowAliasPopup}>
                            <IconMi icon="gear" fontSize="16" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            );
        }

        return (
            <Tooltip title={t('fields_popup.add_alias_icon_tooltip')}>
                <Box
                    sx={{
                        display: 'inline-flex',
                        color: 'primary.main',
                        '&:hover': { opacity: 0.75, cursor: 'pointer' },
                    }}
                    onClick={onShowAliasPopup}
                >
                    {t('fields_popup.add_alias_label')}
                </Box>
            </Tooltip>
        );
    };
    const fieldAlias = setFieldAlias();

    const setFieldType = () => {
        if (item.component == 'ConstructedDateItem') return 'Date';
        if (item.component == 'ExpressionItem') return 'Decimal';

        return helperAct.escapeHtml(helperAct.ucfirst(field.typeOriginal));
    };
    const fieldType = setFieldType();

    if (!isCountOfRecords) {
        switch (field.type) {
            case 'DATE':
                allowedAggregation = ['MIN', 'MAX'];
                break;
            case 'INTEGER':
            case 'DECIMAL':
            case 'EXPR':
                allowedAggregation = ['MIN', 'MAX', 'SUM', 'AVG'];
                break;
        }

        overrideTypesList = (!field.isUsed ? ['INTEGER', 'DECIMAL', 'DATE', 'TEXT'] : []).map((item) => {
            return {
                value: item,
                label: item.toLowerCase(),
                disabled: item == field.typeOriginal || (item == 'DECIMAL' && field.typeOriginal == 'EXPR'),
            };
        });
    }

    const aggregationsList = allowedAggregation.map((item) => {
        return {
            value: item,
            label: item.toLowerCase(),
        };
    });

    if (aggregationsList.length) {
        aggregationsList.unshift({
            value: '',
            label: '---',
        });
    }

    if (overrideTypesList.length) {
        overrideTypesList.unshift({
            value: '',
            label: '---',
        });
    }

    useEffect(() => {
        if (!allowedAggregation.includes(selectedAggregation)) queryAct.removeMetricByFieldName(field.name);
    }, [selectedOverrideType]);

    const selectTypeOverride = (override: string) => {
        const isAutoChecked = editorAct.isFieldAutoChecked(field.name);

        if (override > '' && override.toUpperCase() !== field.typeOriginal) {
            const newAutoCheckedVal = !isChecked || isAutoChecked;
            editorAct.setCheckedState(field.name, true, newAutoCheckedVal);
        } else if (isAutoChecked && (!selectedAggregation || !override)) {
            editorAct.setCheckedState(field.name, false, false);
        }

        reportAct.setTypeOverride(field.name, override);
    };

    const selectTypeAggregation = (aggregation: string) => {
        const isAutoChecked = editorAct.isFieldAutoChecked(field.name);

        if (aggregation > '') {
            const newAutoCheckedVal = !isChecked || isAutoChecked;
            editorAct.setCheckedState(field.name, true, newAutoCheckedVal);
        } else if (
            isAutoChecked &&
            (!selectedOverrideType || selectedOverrideType.toUpperCase() === field.typeOriginal)
        ) {
            editorAct.setCheckedState(field.name, false, false);
        }

        if (aggregation > '') {
            if (queryAct.hasMetricByFieldName(field.name)) {
                queryAct.removeMetricByFieldName(field.name);
                queryAct.addMetric(queryAct.createMetric(field.name, aggregation));
            } else {
                queryAct.addMetric(queryAct.createMetric(field.name, aggregation));
                queryAct.removeDimension(field.name);
            }
        } else {
            //ToDo add to dimension????
            queryAct.removeMetricByFieldName(field.name);
        }
    };

    if (readonly) {
        return (
            <Box sx={styles.rowWrapper}>
                <Box className={'cell cell--text cell--main'}>{fieldName}</Box>
                <Box className={'cell cell--text data--type'}>{fieldType}</Box>
                <Box className={'cell cell--text data--override'}>
                    {field.type !== field.typeOriginal ? helperAct.escapeHtml(helperAct.ucfirst(field.type)) : ''}
                </Box>
                <Box className={'cell cell--text data--aggregation'}>
                    {metric && metric.isValid ? helperAct.escapeHtml(metric.operation) : ''}
                </Box>
            </Box>
        );
    }

    return (
        <Box
            data-handler-id={dragConf.handlerId}
            ref={dragConf.previewRef}
            sx={styles.rowWrapper}
            className={isChecked ? 'selected' : ''}
        >
            <Box ref={dragConf.dragRef} className={'cell cell--drag'}>
                <IconMi icon={'drag-and-drop-grid'} />
            </Box>
            <Box className={'cell cell--checkbox'}>
                <Checkbox
                    sx={{ p: 0 }}
                    checked={isChecked}
                    onChange={(event) => {
                        selectItemToggle();
                    }}
                />
            </Box>
            <Box className={'cell cell--text cell--main'}>{fieldName}</Box>
            {pluginConfig.availableAliases && (
                <Box className={'cell cell--text-and-action data--alias'}>{fieldAlias}</Box>
            )}
            <Box className={'cell cell--text data--type'}>{fieldType}</Box>
            <Box className={'cell cell--select data--override'}>
                {overrideTypesList.length > 0 && (
                    <ReactSelect data={overrideTypesList} value={selectedOverrideType} update={selectTypeOverride} />
                )}
            </Box>
            <Box className={'cell cell--select data--aggregation'}>
                {aggregationsList.length > 0 && (
                    <ReactSelect data={aggregationsList} value={selectedAggregation} update={selectTypeAggregation} />
                )}
            </Box>
            <Box className={'cell cell--actions'}>
                {(item.component == 'ConstructedDateItem' || item.component == 'ExpressionItem') && (
                    <Tooltip title={t('fields_popup.edit_icon_tooltip')}>
                        <IconButton onClick={editItem}>
                            <IconMi icon="gear" fontSize="16" />
                        </IconButton>
                    </Tooltip>
                )}
                {(isCountOfRecords ||
                    item.component == 'ConstructedDateItem' ||
                    item.component == 'ExpressionItem') && (
                    <Tooltip title={t('fields_popup.remove_icon_tooltip')}>
                        <IconButton
                            onClick={() => {
                                deleteItem();
                            }}
                        >
                            <IconMi icon="times" fontSize="16" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    );
}

function PromptItemRow(props: {
    item: any;
    dragConf: any;
    readonly?: boolean;
    selectItemToggle: () => void;
    editorData?: any;
    editorAct?: any;
}) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { item, dragConf, readonly = false, selectItemToggle = () => {}, editorData, editorAct } = props;
    const { reportData, queryData, queryAct, helperAct, pluginConfig } = useContext(PluginQBContext);
    const prompt = item.field;

    const isChecked = !readonly ? editorAct.getCheckedState(prompt.name, item.isChecked) : false;

    if (readonly) {
        return (
            <Box sx={styles.rowWrapper}>
                <Box className={'cell cell--text cell--main'}>{helperAct.escapeHtml(prompt.name)}</Box>
                <Box className={'cell cell--text data--type'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconMi icon="filter" sx={{ fontSize: 16, mr: 1 }} />
                        Filter
                    </Box>
                </Box>
                <Box className={'cell cell--text data--override'} />
                <Box className={'cell cell--text data--aggregation'} />
            </Box>
        );
    }

    return (
        <Box
            data-handler-id={dragConf.handlerId}
            ref={dragConf.previewRef}
            sx={styles.rowWrapper}
            className={isChecked ? 'selected' : ''}
        >
            <Box ref={dragConf.dragRef} className={'cell cell--drag'}>
                <IconMi icon={'drag-and-drop-grid'} />
            </Box>
            <Box className={'cell cell--checkbox'}>
                <Checkbox
                    sx={{ p: 0 }}
                    checked={isChecked}
                    onChange={(event) => {
                        selectItemToggle();
                    }}
                />
            </Box>
            <Box className={'cell cell--text cell--main'}>{helperAct.escapeHtml(prompt.name)}</Box>
            {pluginConfig.availableAliases && <Box className={'cell cell--text-and-action data--alias'} />}
            <Box className={'cell cell--text data--type'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconMi icon="filter" sx={{ fontSize: 16, mr: 1 }} />
                    Filter
                </Box>
            </Box>
            <Box className={'cell cell--text data--override'} />
            <Box className={'cell cell--text data--aggregation'} />
            <Box className={'cell cell--actions'} />
        </Box>
    );
}

export default function QueryBuilderFieldsGridRow(props: QueryBuilderFieldsGridRowProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const {
        data,
        index,
        moveCard,
        dropCard,
        readonly = false,
        deleteItem = () => {},
        editItem = () => {},
        onShowAliasPopup = () => {},
        editorData,
        editorAct,
    } = props;

    const dragRef = useRef<HTMLElement>(null);
    const previewRef = useRef<HTMLElement>(null);

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item: any, monitor) {
            dropCard(item.index);
        },
        hover(item: any, monitor) {
            if (!previewRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset() ?? { y: 0 };
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    //drag(drop(ref));

    drag(dragRef);
    drop(preview(previewRef));

    const dragConf = {
        handlerId: handlerId,
        previewRef: previewRef,
        dragRef: dragRef,
    };

    const selectItemToggle = () => {
        const isChecked = editorAct.getCheckedState(data.field.name, data.isChecked);
        editorAct.setCheckedState(data.field.name, !isChecked);
    };

    if (
        data.component == 'DimensionItem' ||
        data.component == 'ConstructedDateItem' ||
        data.component == 'ExpressionItem'
    )
        return (
            <DimensionItemRow
                item={data}
                dragConf={dragConf}
                editItem={editItem}
                deleteItem={deleteItem}
                onShowAliasPopup={onShowAliasPopup}
                selectItemToggle={selectItemToggle}
                readonly={readonly}
                editorData={editorData}
                editorAct={editorAct}
            />
        );

    if (data.component == 'PromptItem')
        return (
            <PromptItemRow
                item={data}
                dragConf={dragConf}
                selectItemToggle={selectItemToggle}
                readonly={readonly}
                editorData={editorData}
                editorAct={editorAct}
            />
        );

    return <Box></Box>;
}
