import { useContext } from 'react';
import { DownloadManagerContext, DownloadManagerHandlerProps } from 'services/DownloadManager';
import { Stack } from '@mui/material';

export type DownloadFormatType = 'csv' | 'csvf' | 'xlsx' | 'pdf' | 'pptx';
export function DownloadLink({
    children,
    downloadOptions,
    options,
}: {
    children: any;
    downloadOptions: DownloadManagerHandlerProps;
    options?: any;
}) {
    const downloadHandler = useContext(DownloadManagerContext);
    const callDownloadManager = function () {
        downloadHandler(downloadOptions, options);
    };

    return (
        <Stack direction={'row'} alignItems={'center'} onClick={callDownloadManager}>
            {children}
        </Stack>
    );
}

export function DownloadLinkElement({
    children,
    elementId,
    segmentValueId,
    format,
    uco,
}: {
    children: any;
    elementId: number;
    segmentValueId: number;
    format: string;
    uco?: number;
}) {
    const props = {
        elementId: elementId,
        segmentId: segmentValueId,
        format: format,
    };
    return (
        <DownloadLink options={uco ? { uco: uco } : undefined} downloadOptions={props}>
            {children}
        </DownloadLink>
    );
}
