import React, { useEffect, useRef, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, ClickAwayListener, Link, Tooltip } from '@mui/material';
import { wallAPI } from 'api/wall';
import merge from 'ts-deepmerge';
import styles from 'components/wall/Wall.styles';

import PartWallWallFutureAnnotations from 'components/wall/parts/PartWallWallFutureAnnotations';
import ItemWallExpertNote from 'components/wall/parts/ItemWallExpertNote';
import ItemWallUserNote from 'components/wall/parts/ItemWallUserNote';
import ItemWallUserAnnotation from 'components/wall/parts/ItemWallUserAnnotation';
import ItemWallAlertEvent from 'components/wall/parts/ItemWallAlertEvent';
import ItemWallNotableEvent from 'components/wall/parts/ItemWallNotableEvent';
import WallPostbox from 'components/wall/parts/WallPostbox';
import NotifyOtherPopup from 'components/wall/parts/NotifyOtherPopup';
import IconMi from 'components/common/icon/IconMi';
import useWallContext from 'components/wall/hooks/useWallContext';
import { YNValues } from 'tools/types';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';
import { useLocation } from 'react-router-dom';

interface wallActions {
    processResponseData?: (data: any) => any;
    afterWallLoad?: (data: any) => void;
    //renderCommentsArea?: () => void; //remove. maybe useless. need to check
    //AfterDeleteComment?: () => void; //remove. maybe useless. need to check
}

interface wallItemsData {
    user_note?: any;
    user_annotation?: any;
    alert_event?: any;
    notable_event?: any;
}

interface wallSettingsData {
    collaborativeAttachments: boolean;
    isAdmin: boolean;
    notificationsDisabled: boolean;
    privAddComments: boolean;
    privDeleteCollaborativeItems: boolean;
}

interface wallParams {
    uid: string;
    element_id: number;
    segment_value_id: number;
    element_type: string;
    user_id?: number;
    reqData?: object;
    searchText?: any; //collaborative
    user_dashboard_element_instance_id?: any; //collaborative
    initialShowCount?: number;

    event_index?: any;
    ann_index?: object;
    user_annotation_ids?: any[];
    alert_event_ids?: any[];
    notable_event_ids?: any[];
    view_scope?: any;
    overlayElements?: any;
    report_data_set_chart_annotation_ids?: any;

    is_expert_commentary_ind?: YNValues;
    wallAddNote?: boolean;

    showAddNoteField?: boolean;
    checkUpdate?: boolean;
    isExpertCommentaryWall?: boolean;
    isExpertCommentaryAllowEdit?: boolean;

    viewFilter?: string;
    isFollowing?: boolean;

    //checkUpdate = '<?=((defined('WALL_CHECK_UPDATE') && 'N'==WALL_CHECK_UPDATE)?'N':'Y')?>';
}
interface wallTemplates {
    template?: any;
    areaTemplate?: any;
    commentTemplate?: any;
    noteTemplate?: any;
    fileNameTemplate?: any;
    selectedUsersTemplate?: any;
}

export interface WallProps {
    params: wallParams;
    templates?: wallTemplates;
    actions?: wallActions;
}

type Required<T> = {
    [P in keyof T]-?: T[P];
};

export interface wallData {
    isLoaded: boolean;
    lastUpdate: string;
    showAllElements: boolean;
    params: wallParams;
    el: string; //$('#collaborativeViewElement_'+uid);
    template: any;
    wallAreaTemplate: any;
    data: wallItemsData;
    uid: string;
    noteBox: null | string;
    addNote: null | string; //$('#wall_add_note_'+this.uid);
    wallArea: null | string; //this.el.find('.wall_area_OLD');
    postbox: any; //this.postbox = new WallPostbox();
    uploader: null;
    viewFilter: null | string;
    isFollowing: boolean;
    defaultData: any;
    noteForm: any;
    issueForm: any;
    commentForm: any;
    actions: Required<wallActions>;
    insightfulUsersTimeout: null; //useless. Used another approach
    postboxTimeout: null;
    userImageTimeouts: any;
    notifyOtherForm: any;
    wallAddNote: boolean;
    showAddNoteField: boolean;
    checkUpdate: boolean;
    isExpertCommentaryWall: boolean;
    isExpertCommentaryAllowEdit: boolean;
    settings: wallSettingsData;
    addComment?: { type: string; id: number };
}

export async function getInsightfulUsers(reqData: {
    user_comment_id?: number;
    user_note_id?: number;
    user_annotation_id?: number;
}) {
    if (!reqData || !Object.keys(reqData).length) return null;

    let users = null;

    await wallAPI
        .getInsightfulUsers(reqData)
        .then((response) => {
            if (response.data?.users && response.data.users.length) {
                users = response.data.users;
            }
        })
        .catch(() => {});

    return users;
}

export function InsightfulUsersTooltip(props: { users: string[] }) {
    const { t } = useBundleTranslation(['components/wall/wall']);

    if (!props.users || !props.users.length) return null;

    return (
        <Box>
            {t('insightful_users.tooltip', { count: props.users.length })}
            {props.users.map((user: string) => (
                <Box key={user}>{user}</Box>
            ))}
        </Box>
    );
}

export default function Wall(props: WallProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const { params, templates, actions = {} } = props;
    const [wallItems, setWallItems] = useState<any[]>([]);

    const privAddComments = useIsAdminOrHasPrivilege('PRIV_ADD_COMMENTS');
    const privDeleteCollaborativeItems = useIsAdminOrHasPrivilege('PRIV_DELETE_COLLABORATIVE_ITEMS');
    const [wallData, setWallData] = useState<wallData>({
        isLoaded: false,
        lastUpdate: '',
        showAllElements: false,
        params: params,
        el: 'collaborativeViewElement_' + params.uid,
        data: { user_note: {}, user_annotation: {}, alert_event: {}, notable_event: {} },
        uid: params.uid,
        addNote: '#wall_add_note_' + params.uid,
        noteBox: '.wall_add_note_box', //this.addNote.find('.wall_add_note_box');
        wallArea: '.wall_area_OLD', //el.find('.wall_area_OLD');
        template: templates?.template ?? null,
        wallAreaTemplate: templates?.areaTemplate ?? null,
        postbox: null, //new WallPostbox();
        uploader: null,
        viewFilter: params.viewFilter ?? null,
        isFollowing: params.isFollowing ?? false,
        defaultData: { user_note: {}, user_annotation: {}, alert_event: {}, notable_event: {} },
        noteForm: null, // new DevxForm({id:'noteForm', popupOptions:{}});
        /*noteForm.afterSave = function(response){ self.AfterPostNote(response); };
        issueForm = new DevxForm({beforeSubmit: function(){
                if($('#issueForm li.wall_postbox_attached_file_li:visible').length > 0 && $.trim($('#issueForm #comment').val()).length == 0){
                    alert(trsl.tt('alert_attached_must_be_accompanied', 'Your attached file must be accompanied by a comment.'));
                    $('#issueForm #comment').focus();
                    return false;
                }
                return true;
            }, id:'issueForm', popupOptions:{}});*/
        issueForm: null,
        commentForm: null, //new DevxForm({id:'commentForm', popupOptions:{}});
        insightfulUsersTimeout: null, //useless. Used another approach
        postboxTimeout: null,
        userImageTimeouts: {},
        notifyOtherForm: null, //useless = new DevxForm({"id":'notifyotherForm',popupOptions:{"popupCssClass":"new_popup","width": 600}});
        wallAddNote: params.wallAddNote !== false,
        showAddNoteField: params.showAddNoteField !== false,
        checkUpdate: params.checkUpdate ?? false,
        isExpertCommentaryWall: params.isExpertCommentaryWall == true,
        isExpertCommentaryAllowEdit: params.isExpertCommentaryAllowEdit == true,

        actions: {
            processResponseData:
                actions.processResponseData ??
                ((data) => {
                    return data;
                }),
            afterWallLoad: actions.afterWallLoad ?? (() => {}),
        },
        settings: {
            collaborativeAttachments: false,
            isAdmin: false,
            notificationsDisabled: false,
            privAddComments: privAddComments,
            privDeleteCollaborativeItems: privDeleteCollaborativeItems,
        },
    });
    const [notifyOtherPopupData, setNotifyOtherPopupData] = useState<any>(null);
    const [notifyOtherError, setNotifyOtherError] = useState<string>('');
    const [expertAnalysisNotSavedVal, setExpertAnalysisNotSavedVal] = useState<string>('');
    const [editExpertAnalysisData, setEditExpertAnalysisData] = useState<any>(null);
    const [showExpertAnalysisAddField, setShowExpertAnalysisAddField] = useState<boolean>(false);
    const [clickToEditUserNoteData, setClickToEditUserNoteData] = useState<boolean>(false);
    const [editUserNoteData, setEditUserNoteData] = useState<any>(null);
    const [addNoteNotSavedVal, setAddNoteNotSavedVal] = useState<string>('');
    const [isExpandedAddNoteField, setIsExpandedAddNoteField] = useState<boolean>(false);
    const addNoteField = useRef<HTMLInputElement>();
    const [imageAntiCache, setImageAntiCache] = useState<any>(Date.now());
    const wallDataRef = useRef(wallData);
    const location = useLocation();

    const handleExpertAnalysisAddField = (state: boolean) => {
        setShowExpertAnalysisAddField(state);
        if (!state) setEditExpertAnalysisData(null);
    };

    useEffect(() => {
        if (showExpertAnalysisAddField && ref.current) {
            //@ts-ignore
            ref.current?.focus();
        }
    }, [showExpertAnalysisAddField]);

    const { setWallActions, addOnUpdateCallback } = useWallContext(false);

    const onDeleteAnnotation = (annotationId: number) => {
        const newData: any = { ...{}, ...wallDataRef.current.data };
        delete newData.user_annotation[annotationId];
        updateWallData({ data: newData });
        /*
         if (typeof(dash)!='undefined')
        dash.collaborative.WallAfterDelete();
        */
    };

    const onDeleteEvent = (eventId: number) => {
        const newData: any = { ...{}, ...wallDataRef.current.data };
        delete newData.notable_event[eventId];
        updateWallData({ data: newData });
    };

    const setActiveAddCommentField = (element_type: string, element_id: any) => {
        const isValidElementType = ['user_note', 'user_annotation', 'alert_event', 'notable_event'].includes(
            element_type
        );

        if (isValidElementType) {
            updateWallData({ addComment: { type: element_type, id: element_id } });
        }
    };

    useEffect(() => {
        //setInitialData
        /*
        updateWallData({
                if (templates.commentTemplate) this.postbox.commentTemplate = templates.commentTemplate;
                if (templates.noteTemplate) this.postbox.noteTemplate = templates.noteTemplate;
                if (templates.fileNameTemplate) this.postbox.fileNameTemplate = templates.fileNameTemplate;
                if (templates.selectedUsersTemplate) this.postbox.selectedUsersTemplate = templates.selectedUsersTemplate;
        });
        */

        //this.BindLiveEvents();
        if (!wallDataRef.current.isLoaded) load();

        if (wallDataRef.current.isExpertCommentaryWall) {
            //@ts-ignore
            setWallActions({
                setExpertAnalysisField: handleExpertAnalysisAddField,
            });
        } else {
            //@ts-ignore
            setWallActions({
                onDeleteAnnotation: onDeleteAnnotation,
                onDeleteEvent: onDeleteEvent,
                setActiveAddCommentField: setActiveAddCommentField,
            });

            addOnUpdateCallback('wallContent', () => {
                goCheckUpdate();
            });

            if (location.hash) {
                const hashData = location.hash.split('/');
                const addCommentIndex = hashData.indexOf('addcomment');
                if (addCommentIndex != -1 && hashData[addCommentIndex + 1] == 'Y') {
                    const typeIndex = hashData.findIndex((item) => {
                        return (
                            item == 'user_note' ||
                            item == 'user_annotation' ||
                            item == 'alert_event' ||
                            item == 'notable_event'
                        );
                    });
                    if (typeIndex !== -1 && Number(hashData[typeIndex + 1]) > 0) {
                        setActiveAddCommentField(hashData[typeIndex], hashData[typeIndex + 1]);
                    }
                }
            }
        }
    }, []);
    useEffect(() => {
        //reload if wall get new ids
        let needReloadData = false;
        if (!wallDataRef.current.isExpertCommentaryWall) {
            const annCount = (wallDataRef.current.params.user_annotation_ids ?? []).length;
            const alertCount = (wallDataRef.current.params.alert_event_ids ?? []).length;
            const eventCount = (wallDataRef.current.params.notable_event_ids ?? []).length;
            needReloadData =
                (props.params.user_annotation_ids ?? []).length > annCount ||
                (props.params.alert_event_ids ?? []).length > alertCount ||
                (props.params.notable_event_ids ?? []).length > eventCount;
        }

        updateWallData({ params: props.params });

        if (!wallDataRef.current.isExpertCommentaryWall) {
            if (needReloadData) load(true);
        }
    }, [props.params]);

    const EVENTS = {
        WALL_AFTER_LOAD: 'WALL_AFTER_LOAD',
        WALL_AFTER_RENDER: 'WALL_AFTER_RENDER',
    };

    const updateWallData = (newData: any) => {
        wallDataRef.current = { ...wallDataRef.current, ...newData };
        setWallData((previousWallData) => ({ ...previousWallData, ...newData }));
    };

    const getRequestData = () => {
        const params = wallDataRef.current.params;
        const requestData: any = params?.reqData ?? {};

        requestData.element = params.element_id;
        requestData.sv_id = params.segment_value_id;

        if (Array.isArray(params.overlayElements) && params.overlayElements.length) {
            requestData.overlay_elements = params.overlayElements.map((item) => item.metric_element_id).join(',');
            requestData.overlay_segment_values = params.overlayElements.map((item) => item.segment_value_id).join(',');
        }

        if (params.user_annotation_ids) {
            requestData.user_annotation_ids = params.user_annotation_ids.join(',');
        }
        if (params.alert_event_ids) {
            requestData.alert_event_ids = params.alert_event_ids.join(',');
        }
        if (params.notable_event_ids) {
            requestData.notable_event_ids = params.notable_event_ids.join(',');
        }
        if (params.view_scope) {
            requestData.view_scope = params.view_scope;
        }

        return requestData;
    };

    const setLastUpdate = (data: any) => {
        let last_update = '';

        if ('object' == typeof data) {
            for (let type in data) {
                for (let item in data[type]) {
                    const element = data[type][item];
                    if (last_update < element.ts) last_update = element.ts;
                    if (Object.keys(element.comments).length > 0) {
                        for (let commentId in element.comments) {
                            if (last_update < element.comments[commentId].ts)
                                last_update = element.comments[commentId].ts;
                        }
                    }
                }
            }
        }
        updateWallData({ lastUpdate: last_update });
    };

    const load = (reload?: boolean) => {
        wallAPI
            .loadData(getRequestData())
            .then((response) => {
                //We pass it in func to refuse old state of wallData
                let newData = wallDataRef.current.data;
                if (response.data?.status === 'OK') {
                    newData = wallDataRef.current.actions.processResponseData(response.data?.data);
                    updateWallData({
                        data: newData,
                        settings: response.data.settings,
                    });

                    renderArea();

                    //ToDo Wall.prototype.AfterLoad
                    //if ((''==this.params.view_scope ||

                    wallDataRef.current.actions.afterWallLoad(response.data);
                }
                updateWallData({ isLoaded: true });
                setLastUpdate(newData);
                window.dispatchEvent(new CustomEvent(EVENTS.WALL_AFTER_LOAD));
                if (wallDataRef.current.checkUpdate && !reload) goCheckUpdate();
            })
            .catch(() => {});
    };

    const goCheckUpdate = (force?: boolean) => {
        wallAPI
            .loadData({ ...getRequestData(), last_update: wallDataRef.current.lastUpdate })
            .then((response) => {
                if (response.data?.status === 'OK') {
                    const data = wallDataRef.current.actions.processResponseData(response.data.data);
                    if ('object' == typeof data) {
                        const newData = merge(wallDataRef.current.data, data);
                        updateWallData({ data: newData, settings: response.data.settings });
                        setLastUpdate(newData);
                    }
                }
                wallDataRef.current.actions.afterWallLoad(response.data);
                if (!force) {
                    setTimeout(function () {
                        goCheckUpdate();
                    }, 30000);
                }

                /*ToDo right clear interal
                if (this.checkUpdateTimeout) clearTimeout(this.checkUpdateTimeout);
                this.checkUpdateTimeout = setTimeout(function(){ self.CheckUpdate(); },30000);
                 */
            })
            .catch(() => {});
    };

    const [createStatus, setCreateStatus] = useState<string>();

    const renderArea = () => {
        const items: any[] = [];

        const dashFollowed = true; //ToDo ('undefined'!=typeof(dash) && 'undefined'!=typeof(dash.collaborative) && dash.collaborative.followed && dash.collaborative.followed[this.uid])
        if (!wallDataRef.current.isFollowing || dashFollowed) {
            const dataTypes = ['user_note', 'user_annotation', 'alert_event', 'notable_event'];

            dataTypes.forEach((type) => {
                const typeElements = wallDataRef.current.data[type as keyof wallItemsData];
                if (typeElements) {
                    Object.keys(typeElements).forEach((typeItemKey) => {
                        items.push(typeElements[typeItemKey]);
                    });
                }
            });

            if (
                wallDataRef.current.params.view_scope == 'chart' &&
                wallDataRef.current.viewFilter == 'notable_event' &&
                wallDataRef.current.params?.event_index
            ) {
                items.sort((a, b) => {
                    return (
                        wallDataRef.current.params.event_index[parseInt(a.notable_event_id, 10)] -
                        wallDataRef.current.params.event_index[parseInt(b.notable_event_id, 10)]
                    );
                });
            } else {
                items.sort((a, b) => {
                    return a.sort_time < b.sort_time ? 1 : a.sort_time > b.sort_time ? -1 : 0;
                });
            }
        }
        setWallItems(items);
        window.dispatchEvent(new CustomEvent(EVENTS.WALL_AFTER_RENDER));
    };

    useEffect(() => {
        renderArea();
    }, [wallDataRef.current.data]);

    if (!wallDataRef.current) {
        return null;
    }

    const isShowFutureAnnotations = wallDataRef.current.viewFilter == 'user_note' || !wallDataRef.current.viewFilter;
    const futureAnnotations = !isShowFutureAnnotations
        ? []
        : wallItems.filter(
              (item) => item.user_annotation_id && item.user_annotation_id != '0' && item.is_future_annotation == '1'
          );
    let limitToShow = wallDataRef.current.showAllElements
        ? 0
        : (wallDataRef.current.params?.initialShowCount ?? 5) + futureAnnotations.length;
    const itemsInFilter = wallItems.filter((item) => {
        const filter = wallDataRef.current.viewFilter;

        if (
            ('user_note' == filter &&
                ((item.user_note_id && item.user_note_id != '0') ||
                    (item.user_annotation_id && item.user_annotation_id != '0'))) ||
            ('alert_event' == filter && item.alert_event_id && item.alert_event_id != '0') ||
            ('notable_event' == filter && item.notable_event_id && item.notable_event_id != '0')
        )
            return true;

        return false;
    }).length;
    const totalToShow = !wallDataRef.current.viewFilter ? wallItems.length : itemsInFilter;
    const leftToShow = totalToShow - limitToShow;

    const addCommentData = wallDataRef.current.addComment;
    if (!wallDataRef.current.isExpertCommentaryWall && addCommentData) {
        const addCommentIndex = wallItems.findIndex((item) => {
            return item[addCommentData.type + '_id'] == addCommentData.id;
        });
        if (addCommentIndex != -1 && limitToShow && addCommentIndex >= limitToShow) {
            limitToShow = 0;
            wallDataRef.current.showAllElements = true;
        }
    }

    const getTilesNoActivityMsg = () => {
        if (
            wallDataRef.current.viewFilter &&
            ['user_note', 'alert_event', 'notable_event', 'user_annotation'].includes(wallDataRef.current.viewFilter)
        ) {
            return t(`tiles_no_activity_msg.${wallDataRef.current.viewFilter}`);
        } else {
            return t('tiles_no_activity_msg.another_filter_state');
        }
    };

    const followUser = (followData: { user_follower_id: any; author_user_id: any }) => {
        const followAction = followData.user_follower_id <= 0;
        const reqData: any = {
            element: wallDataRef.current.params.element_id,
            sv_id: wallDataRef.current.params.segment_value_id,
            follows_user_id: followData.author_user_id,
        };

        if (!followAction) {
            reqData.user_follower_id = followData.user_follower_id;
        }

        wallAPI
            .followUser(reqData)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    const newData: any = { ...{}, ...wallDataRef.current.data };
                    const responseData = response.data;

                    for (let type in newData) {
                        //pass item types
                        for (let itemKey in newData[type]) {
                            //pass items in some type
                            const item = newData[type][itemKey];
                            if (
                                'undefined' != typeof item.author_user_id &&
                                'undefined' != typeof item.user_follower_id &&
                                item.author_user_id == responseData.follows_user_id
                            ) {
                                newData[type][itemKey].user_follower_id = !followAction
                                    ? null
                                    : responseData.user_follower_id;
                            }

                            if (item.comments && Object.values(item.comments).length) {
                                for (let commentKey in item.comments) {
                                    const comment = item.comments[commentKey];
                                    if (
                                        'undefined' != typeof comment.comment_user_id &&
                                        'undefined' != typeof comment.user_follower_id &&
                                        comment.comment_user_id == responseData.follows_user_id
                                    ) {
                                        newData[type][itemKey]['comments'][commentKey].user_follower_id = !followAction
                                            ? null
                                            : responseData.user_follower_id;
                                    }
                                }
                            }
                        }
                    }
                    updateWallData({ data: newData });
                }
            })
            .catch(() => {});
    };

    const insightful = (type: string, id: number | string) => {
        const reqData: any = {
            element: wallDataRef.current.params.element_id,
            sv_id: wallDataRef.current.params.segment_value_id,
            [`user_${type}_id`]: id,
        };

        wallAPI
            .insightful(type, reqData)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    const newData = merge(wallDataRef.current.data, response.data.data);
                    updateWallData({ data: newData });
                }
            })
            .catch(() => {});
    };

    const deleteNote = (noteData: any) => {
        const isExpertCommentary = noteData.is_expert_commentary_ind == 'Y';

        if (!confirm(t(`${isExpertCommentary ? 'expert_note' : 'user_note'}.delete_warn`))) return false;

        const reqData: any = {
            element: wallDataRef.current.params.element_id,
            sv_id: wallDataRef.current.params.segment_value_id,
            user_note_id: noteData.user_note_id,
        };

        /*
        if ('undefined' != typeof this.parent.loadingMask) {
           this.parent.loadingMask.msg = trsl.tt('mask_deleting', 'Deleting...');
            this.parent.loadingMask.show();
        }
        */

        wallAPI
            .deleteNote(reqData)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    const newData: any = { ...{}, ...wallDataRef.current.data };
                    delete newData.user_note[reqData.user_note_id];
                    updateWallData({ data: newData });

                    /*
                        if (typeof(dash)!='undefined')
                        dash.collaborative.WallAfterDelete();

                        if (this.parent && this.parent.AfterDeleteNote) {
                        this.parent.AfterDeleteNote(isExpertCommentary);
                    */
                }
            })
            .catch(() => {});
    };

    const notifyOther = (type: string, text: any, itemData: any) => {
        setNotifyOtherPopupData({
            params: {
                element_id: itemData.element_id,
                segment_value_id: itemData.segment_value_id,
                user_note_id: itemData.user_note_id,
                alert_event_id: itemData.alert_event_id,
                notable_event_id: itemData.notable_event_id,
                user_annotation_id: itemData.user_annotation_id,
                user_comment_id: itemData.user_comment_id,
            },
            text: text,
            type: type,
        });
    };

    const addNote = (text: string, share: string[], fromData: any, isExpertNote: boolean, userNoteId?: number) => {
        const reqData = fromData.append ? fromData : new FormData();

        reqData.append('element_id', wallDataRef.current.params.element_id);
        reqData.append('segment_value_id', wallDataRef.current.params.segment_value_id);
        reqData.append('user_note_id', userNoteId ?? '');
        reqData.append('text', text);

        if (isExpertNote) {
            reqData.append('is_expert_commentary_ind', 'Y');
        } else {
            const shareVal = share.length > 0 ? share.join(';') + ';' : '';
            reqData.append('share', shareVal);
        }

        wallAPI
            .addNote(reqData)
            .then((response) => {
                if (response.data?.status == 'OK') {
                    if (isExpertNote && userNoteId) {
                        //reload wall on expert edit
                        //will be better get right object to replace
                        goCheckUpdate(true);
                    } else {
                        //Expert wall contain only one element
                        const newData = isExpertNote
                            ? response.data.data
                            : merge(wallDataRef.current.data, response.data.data);
                        updateWallData({ data: newData });
                    }
                }
                setEditUserNoteData(null);
            })
            .catch(() => {});
    };

    const ref = useRef();

    return (
        <Box sx={{ paddingBottom: '12px' }}>
            {wallDataRef.current.showAddNoteField && wallDataRef.current.settings.privAddComments && (
                <ClickAwayListener
                    onClickAway={(event) => {
                        if (clickToEditUserNoteData) {
                            //click to edit, ignore click outside fire
                            setClickToEditUserNoteData(false);
                        } else {
                            setIsExpandedAddNoteField(false);
                            setEditUserNoteData(null);
                        }
                    }}
                >
                    <Box
                        id={`wall_add_note_${wallDataRef.current.uid}`}
                        className="wall_add_note_OLD"
                        sx={{
                            mb: 3,
                            display:
                                wallDataRef.current.viewFilter && wallDataRef.current.viewFilter != 'user_note'
                                    ? 'none'
                                    : '',
                        }}
                    >
                        <WallPostbox
                            params={{
                                uid: wallDataRef.current.params.uid,
                                element_id: wallDataRef.current.params.element_id,
                                segment_value_id: wallDataRef.current.params.segment_value_id,
                                wallAddNote: wallDataRef.current.wallAddNote,
                                type: 'note',
                                reqParams: {},
                                containerId: 'test',
                                rowCount: isExpandedAddNoteField ? 5 : 1,
                                collaborativeAttachments: wallDataRef.current.settings.collaborativeAttachments,
                                notificationsDisabled: wallDataRef.current.settings.notificationsDisabled,
                                initialText: isExpandedAddNoteField
                                    ? editUserNoteData?.raw_text ?? addNoteNotSavedVal
                                    : '',
                                filesData: editUserNoteData?.shared_files,
                                onFocusAction: () => {
                                    setIsExpandedAddNoteField(true);
                                },
                                onClickPost: (text, share, fromData) => {
                                    setIsExpandedAddNoteField(false);
                                    addNote(text, share, fromData, false, editUserNoteData?.user_note_id);
                                    if (!editUserNoteData?.user_note_id) setAddNoteNotSavedVal('');
                                },
                                onChangeVal: (text) => {
                                    if (!editUserNoteData?.user_note_id) setAddNoteNotSavedVal(text);
                                },
                            }}
                            templates={templates}
                            ref={addNoteField}
                        />
                    </Box>
                </ClickAwayListener>
            )}
            <Box
                id={wallDataRef.current.el}
                className={`wall collaborativeViewElement_${wallDataRef.current.uid} ${
                    wallDataRef.current.isExpertCommentaryWall ? ' expert_note_body' : ''
                }`}
                sx={styles.wall_tmp}
            >
                <Box id={`wall_area_${wallDataRef.current.uid}`} className="wall_area_OLD wall__area">
                    {isShowFutureAnnotations && futureAnnotations.length > 0 && (
                        <PartWallWallFutureAnnotations
                            items={futureAnnotations}
                            uid={wallDataRef.current.uid}
                            wallData={wallDataRef.current}
                            updateWallData={updateWallData}
                            followUser={followUser}
                            insightful={insightful}
                            imageAntiCache={imageAntiCache}
                        />
                    )}
                    {wallItems.slice(0, limitToShow != 0 ? limitToShow : undefined).map((item, index) => {
                        if (item.user_note_id && item.user_note_id != '0') {
                            if (item.is_expert_commentary_ind == 'Y') {
                                return (
                                    <ItemWallExpertNote
                                        data={item}
                                        uid={wallDataRef.current.uid}
                                        key={`user_note_${item.user_note_id}`}
                                        wallData={wallDataRef.current}
                                        deleteNote={() => {
                                            deleteNote(item);
                                        }}
                                        editNote={() => {
                                            setEditExpertAnalysisData(item);
                                            handleExpertAnalysisAddField(true);
                                        }}
                                        addNote={() => handleExpertAnalysisAddField(true)}
                                    />
                                );
                            } else {
                                if (editUserNoteData?.user_note_id == item.user_note_id) return null;
                                return (
                                    <ItemWallUserNote
                                        data={item}
                                        uid={wallDataRef.current.uid}
                                        key={`user_note_${item.user_note_id}`}
                                        wallData={wallDataRef.current}
                                        updateWallData={updateWallData}
                                        followUser={followUser}
                                        insightful={insightful}
                                        deleteNote={() => {
                                            deleteNote(item);
                                        }}
                                        editNote={() => {
                                            setEditUserNoteData(item);
                                            setClickToEditUserNoteData(true);
                                            if (addNoteField) addNoteField?.current?.focus();
                                        }}
                                        notifyOther={notifyOther}
                                        imageAntiCache={imageAntiCache}
                                    />
                                );
                            }
                        } else if (item.user_annotation_id && item.user_annotation_id != '0') {
                            if (item.is_future_annotation != '1') {
                                return (
                                    <ItemWallUserAnnotation
                                        data={item}
                                        uid={wallDataRef.current.uid}
                                        key={`user_annotation_${item.user_annotation_id}`}
                                        wallData={wallDataRef.current}
                                        updateWallData={updateWallData}
                                        followUser={followUser}
                                        insightful={insightful}
                                        notifyOther={notifyOther}
                                        imageAntiCache={imageAntiCache}
                                    />
                                );
                            }
                        } else if (item.alert_event_id && item.alert_event_id != '0') {
                            return (
                                <ItemWallAlertEvent
                                    data={item}
                                    uid={wallDataRef.current.uid}
                                    key={`alert_event_${item.alert_event_id}`}
                                    wallData={wallDataRef.current}
                                    updateWallData={updateWallData}
                                    followUser={followUser}
                                    insightful={insightful}
                                    notifyOther={notifyOther}
                                    imageAntiCache={imageAntiCache}
                                />
                            );
                        } else if (item.notable_event_id && item.notable_event_id != '0') {
                            return (
                                <ItemWallNotableEvent
                                    data={item}
                                    uid={wallDataRef.current.uid}
                                    key={`notable_event_${item.notable_event_id}`}
                                    wallData={wallDataRef.current}
                                    updateWallData={updateWallData}
                                    followUser={followUser}
                                    insightful={insightful}
                                    notifyOther={notifyOther}
                                    imageAntiCache={imageAntiCache}
                                />
                            );
                        }
                        return null;
                    })}
                    {limitToShow > 0 && totalToShow > limitToShow && (
                        <Box className="wall_node_OLD show_more_node">
                            <Box
                                className="show-more"
                                sx={{
                                    textAlign: 'center',
                                    py: 0.5,
                                    backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.06),
                                }}
                            >
                                <Link
                                    className="show-more-a"
                                    onClick={() => {
                                        updateWallData({ showAllElements: true });
                                    }}
                                >
                                    {t('show_mote_link', { count: leftToShow })}
                                </Link>
                            </Box>
                        </Box>
                    )}
                    {(wallDataRef.current.viewFilter || wallDataRef.current.isFollowing) &&
                        itemsInFilter == 0 &&
                        (!wallDataRef.current.params.view_scope ||
                            wallDataRef.current.params.view_scope != 'report_note') && (
                            <Box className="tilesNoActivity">{getTilesNoActivityMsg()}</Box>
                        )}
                </Box>
            </Box>
            {notifyOtherPopupData && (
                <NotifyOtherPopup
                    text={notifyOtherPopupData.text}
                    type={notifyOtherPopupData.type}
                    elementId={wallDataRef.current.params.element_id}
                    segmentValueId={wallDataRef.current.params.segment_value_id}
                    onClose={() => {
                        setNotifyOtherPopupData(null);
                        setNotifyOtherError('');
                    }}
                    errorMsg={notifyOtherError}
                    onConfirm={(emails: any, comment: string) => {
                        const data = { ...{}, ...notifyOtherPopupData.params };
                        data.share = emails;
                        data.text = comment;

                        wallAPI
                            .notifyOther(data)
                            .then((response) => {
                                if (response.data?.status == 'OK') {
                                    setNotifyOtherError('');
                                    setNotifyOtherPopupData(null);
                                } else if (response.data?.status == 'ERROR') {
                                    const field = Object.keys(response.data.messages)[0];
                                    const msg = Object.keys(response.data.messages[field])[0];
                                    setNotifyOtherError(response.data.messages?.[field]?.[msg] ?? t('error')); // response.data.messages
                                }

                                //ToDo ??? format of data
                                //OpenNotifyOtherPopup
                                //engine/application/notification/views/scripts/notifyother/draw.phtml
                            })
                            .catch((response) => {});
                    }}
                />
            )}
            {wallDataRef.current.isExpertCommentaryWall &&
                wallDataRef.current.isExpertCommentaryAllowEdit &&
                !showExpertAnalysisAddField && (
                    <>
                        {wallDataRef.current.params.view_scope == 'expert_note' &&
                            wallItems.length == 0 &&
                            wallDataRef.current.params.element_type != 'metric' &&
                            wallDataRef.current.params.element_type != 'multi-metric chart' &&
                            wallDataRef.current.params.element_type != 'other external content' &&
                            wallDataRef.current.params.element_type != 'external report' && (
                                <Box>
                                    <div className="expert_note_add_div">
                                        <Tooltip title={t('expert_analysis.add_tooltip')}>
                                            <Box
                                                component="a"
                                                id={`wall_add_note_box_a_${wallDataRef.current.uid}`}
                                                href="#"
                                                className="expert_note_add_a"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleExpertAnalysisAddField(true);
                                                }}
                                                sx={{ display: 'inline-flex', alignItems: 'center' }}
                                            >
                                                <IconMi icon="briefcase" fontSize="16" sx={{ mr: 1 }} />
                                                {t('expert_analysis.add_link')}
                                            </Box>
                                        </Tooltip>
                                    </div>
                                </Box>
                            )}
                    </>
                )}
            {showExpertAnalysisAddField && (
                <ClickAwayListener onClickAway={() => handleExpertAnalysisAddField(false)}>
                    <Box
                        className="wall_add_note_OLD"
                        sx={{
                            mb: 3,
                        }}
                    >
                        <WallPostbox
                            ref={ref}
                            params={{
                                uid: wallDataRef.current.params.uid,
                                element_id: wallDataRef.current.params.element_id,
                                segment_value_id: wallDataRef.current.params.segment_value_id,
                                wallAddNote: wallDataRef.current.wallAddNote,
                                isExpertCommentary: true,
                                type: 'note',
                                reqParams: {},
                                containerId: 'test',
                                collaborativeAttachments: wallDataRef.current.settings.collaborativeAttachments,
                                notificationsDisabled: wallDataRef.current.settings.notificationsDisabled,
                                initialText: editExpertAnalysisData?.raw_text ?? expertAnalysisNotSavedVal,
                                filesData: editExpertAnalysisData?.shared_files,
                                onClickPost: (text, share, fromData) => {
                                    handleExpertAnalysisAddField(false);
                                    addNote(text, share, fromData, true, editExpertAnalysisData?.user_note_id);
                                    if (!editExpertAnalysisData?.user_note_id) setExpertAnalysisNotSavedVal('');
                                },
                                onClickCancel: () => handleExpertAnalysisAddField(false),
                                onChangeVal: (text) => {
                                    if (!editExpertAnalysisData?.user_note_id) setExpertAnalysisNotSavedVal(text);
                                },
                            }}
                            templates={templates}
                        />
                    </Box>
                </ClickAwayListener>
            )}
        </Box>
    );
}
