import { ReportContentNS } from 'components/report-content/index';
import React, { useEffect, useRef, useState } from 'react';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import Chart from 'components/chart/Chart';
import ComponentSettingsMapChart = ReportContentNS.ComponentSettingsMapChart;
import useRCChartData from 'components/report-content/hooks/useRCChartData';
import { AssocArray } from 'tools/types';
import Highcharts from 'highcharts/highstock';
import HighchartsMap from 'highcharts/modules/map';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { Chart as HChart } from 'highcharts';
import { applyChartOptionsForThumbnail } from 'components/report-content/utils/tools';
HighchartsMap(Highcharts);

const loadMapJson = (mapName: string): Promise<any> => {
    return new Promise((res, rej) => {
        const map = mapName.replace('.js', '');
        import(`@highcharts/map-collection/${map}.topo.json`).then((data) => {
            res(data?.default);
        });
    });
};

export default function MapChart({
    contentSettings,
    component,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsMapChart>) {
    const { initialData, data } = useRCChartData(component, contentSettings, updateBlockSettings, true);
    const isThumbnail = contentSettings.forScreenShot && window.location.href.includes('thumbnail');
    const [formattedValues, setFormattedValues] = useState<AssocArray<string>>({});
    const formattedValuesRef = useRef(formattedValues);
    useEffect(() => {
        formattedValuesRef.current = formattedValues;
    }, [formattedValues]);

    const [mapGeoJSON, setMapGeoJSON] = useState<any>(null);
    useEffect(() => {
        const loadJsonAsync = async () => {
            const json = await loadMapJson(component.settings.map);
            setMapGeoJSON(json);
        };
        loadJsonAsync();
    }, [component.settings.map]);

    const options = {
        chart: {
            animation: false,
            height: 600,
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'bottom',
        },
        credits: { enabled: false },
        rangeSelector: { enabled: false },
        navigator: { enabled: false },
        scrollbar: { enabled: false },
        exporting: { enabled: false },
        title: { text: null },
        mapNavigation: {
            enabled: !contentSettings.forScreenShot,
            buttons: {
                zoomIn: { x: -60 },
                zoomOut: { x: -60 },
            },
        },
        tooltip: {
            formatter: function (): any {
                const self: any = this;
                return `${self.point.name}<br/><b>${self.point.y_f}</b>`;
            },
        },
        colorAxis: {
            min: 1,
            max: 100,
            minColor: component.settings.color_start,
            maxColor: component.settings.color_end,
        },
        series: [
            {
                animation: !contentSettings.forScreenShot,
                data: [],
                mapData: {},
                joinBy: ['hc-key', 'key'],
                name: component.settings.measureFields[0].column_name,
                states: {
                    hover: {
                        color: component.settings.color_end,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (): any {
                        const self: any = this;
                        if ('with_data_only' === component.settings.display_label && null === self.point.value) {
                            return null;
                        }
                        return 'N' == component.settings.display_full_label &&
                            self.point.properties &&
                            'undefined' !== typeof self.point.properties['hc-a2'] &&
                            self.point.properties['hc-a2']
                            ? self.point.properties['hc-a2']
                            : self.point.name;
                    },
                },
            },
        ],
    };

    const [chartOptions, setChartOptions] = useState(options);
    const [rerender, setRerender] = useState(false);
    useEffect(() => {
        if (isThumbnail) {
            //@ts-ignore
            applyChartOptionsForThumbnail(options);
        } else {
            options.chart.height = component.settings.height;
        }
        if (mapGeoJSON == null) {
            return;
        }
        setRerender(true);
        let showDataLabels = true;

        let resultData: any = [];
        let min = 0;
        let max = 0;
        mapGeoJSON.objects.default.geometries.forEach((feature: any, index: number) => {
            for (const [k, v] of Object.entries(data)) {
                if (
                    feature.properties['name'] &&
                    null != v[component.settings.measureFields[0].reference_name] &&
                    (v[component.settings.group_field] == feature.properties['name'] ||
                        (v[component.settings.group_field] &&
                            0 == feature.properties['name'].indexOf(v[component.settings.group_field])))
                ) {
                    const val = parseFloat(v[component.settings.measureFields[0].reference_name]);
                    min = min > val ? val : min;
                    max = max < val ? val : max;
                    resultData.push({
                        name: v[component.settings.group_field],
                        value: String(val),
                        key: feature.properties['hc-key'],
                        y_f:
                            v[component.settings.measureFields[0].reference_name + '-formatted'] ??
                            v[component.settings.measureFields[0].reference_name],
                    });
                    break;
                }
            }
        });
        //
        if ('N' == component.settings.display_label || isThumbnail) {
            showDataLabels = false;
        }
        if ('undefined' == typeof component.settings.color_start) {
            component.settings.color_start = '#EFEFFF';
        }
        if ('undefined' == typeof component.settings.color_end) {
            // @ts-ignore
            component.settings.color_end = Highcharts.getOptions().colors[0];
        }
        options.series[0].data = resultData;
        options.series[0].mapData = mapGeoJSON;
        options.series[0].dataLabels.enabled = showDataLabels;
        options.colorAxis.min = min;
        options.colorAxis.max = max;

        // @ts-ignore
        setChartOptions(options);
        // Redraw chart
        setTimeout(() => setRerender(false));
    }, [data, mapGeoJSON, initialData, component.settings]);

    const [chart, setChart] = useState<HChart>();
    useComponentReady(chart);

    return initialData && !rerender ? (
        <div style={{ overflow: 'hidden', maxHeight: component.settings.height + 'px' }}>
            <Chart
                /*@ts-ignore*/
                chartOptions={chartOptions}
                constructorType={'mapChart'}
                afterChartCreated={(chart: HChart) => setChart(chart)}
            />
        </div>
    ) : (
        // TODO: add loading mask
        <span></span>
    );
}
