import { FormRendererAPIType } from 'components/common/form/index';
import { useEffect, useState } from 'react';
import { getElementFieldValue } from 'components/common/form/formTools';
import { TFunction } from 'react-i18next';
import { FormControlDataFetchCommandProps } from 'components/common/form/DataFetchCommand';

export function getDFCommandLabel(method: string, form: FormRendererAPIType, t: TFunction) {
    switch (method) {
        case 'sql':
            return t('main_hint.df_command_label.label_sql_statement');
        case 'plugin':
            let l =
                getElementFieldValue(form, `plugin_connection_profile_id.command_label`) ||
                t('main_hint.df_command_label.label_plugin_command');
            if ('powerbi' == getElementFieldValue(form, `plugin_connection_profile_id.plugin`)) {
                if ('N' == getElementFieldValue(form, `external_report_external_id.ve_link`)) {
                    l = t('main_hint.df_command_label.label_mdx_dax_query');
                }
            }
            return l;
        case 'composite':
            return t('main_hint.df_command_label.label_metric_formula', 'Metric formula');
        case 'single report':
            return '';
        case 'dataset':
            return '&nbsp;';
        case 'existing datasets':
            return t('main_hint.df_command_label.label_sql_statement_using_dataset');
        default:
            return t('main_hint.df_command_label.label_sql_statement_using_report');
    }
}

export interface HintComponentProps {
    controlProps: FormControlDataFetchCommandProps;
    t: TFunction;
}

export function useMethod(form: FormRendererAPIType) {
    const [method, setMethod] = useState('');
    useEffect(() => {
        const subscription = form.hookFormWatch((value, { name, type }) => {
            if (name == 'data_source') {
                const dataSourceMethod = getElementFieldValue(form, 'data_source.method');
                setMethod(String(dataSourceMethod));
            }
        });
        return () => subscription?.unsubscribe();
    }, [form.hookFormWatch]);
    return method;
}
