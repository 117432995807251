import React, { useContext, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { ReportContentNS } from 'components/report-content/index';
import AllowedComponentType = ReportContentNS.AllowedComponentType;
import ReportContentBlock from 'components/report-content/ReportContentBlock';
import BlockType = ReportContentNS.BlockType;
import ContentSettings = ReportContentNS.ContentSettings;
import ComponentSettings = ReportContentNS.ComponentSettings;
import { ReportContentActions } from 'app/editor/report';
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import { alpha, Box, Stack } from '@mui/material';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { FormDataAPIType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import DatasetField = ReportContentNS.DatasetField;
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import IconMi from 'components/common/icon/IconMi';
import IBlocksContext = ReportContentNS.IBlocksContext;
import BlocksContext = ReportContentNS.BlocksContext;

function ContentDropBox({ onAddNewBlock }: { onAddNewBlock: (component: AllowedComponentType) => void }) {
    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: 'component',
            canDrop: () => true,
            drop: (obj: AllowedComponentType) => {
                onAddNewBlock(obj);
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
        }),
        []
    );

    return (
        <Box
            ref={drop}
            sx={{
                width: '100%',
                height: '50px',
                border: '1px dashed',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.24),
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
                textAlign: 'center',
                position: 'relative',
            }}
            data-test={'drop-components-area'}
        >
            Drop Component Here
        </Box>
    );
}

export default function ReportContent({
    contentSettings,
    blocks,
    manageFiltersBlock,
    actions,
    onAddNewBlock,
    hasUserMap,
    onRestrictByUserMapChange,
    datasetFields,
}: {
    contentSettings: ContentSettings;
    blocks: Array<BlockType<ComponentSettings>>;
    manageFiltersBlock?: BlockType<ComponentSettingsManageFilters>;
    actions: ReportContentActions;
    onAddNewBlock: (component: AllowedComponentType) => void;
    hasUserMap?: boolean;
    onRestrictByUserMapChange?: (applyUserMap: boolean) => void;
    datasetFields: Array<DatasetField>;
}) {
    const elementProps: FormDataAPIType = useCustomSimplifiedForm({ restrict_data_with_user_map: 'N' });

    const urlSearchParams = new URLSearchParams(window.location.search);
    const viewType = urlSearchParams.get('view_type');
    const blockUid = urlSearchParams.get('block_uid');
    const maxHeight = urlSearchParams.get('height');

    const filteredBlocks = useMemo(() => {
        if (viewType == 'tile') {
            // Show tile block
            return blocks
                .filter(
                    (block) =>
                        (blockUid != null && block.uid == blockUid) ||
                        (blockUid == null && block.component.settings.is_preview_ind == 'Y')
                )
                .map((block) => {
                    // TODO:
                    block.component.settings.height =
                        maxHeight != null ? Number(maxHeight) - 45 : block.component.settings.height;
                    return block;
                });
        }
        return blocks;
    }, [blocks, viewType]);

    const restrictData = elementProps.form.hookFormWatch('restrict_data_with_user_map');
    useEffect(() => {
        if (!onRestrictByUserMapChange) {
            return;
        }
        onRestrictByUserMapChange(restrictData == 'Y');
    }, [restrictData]);

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        actions.sortBlocks(result.source.index, result.destination.index);
    };

    const blocksContext = useContext<IBlocksContext | null>(BlocksContext);
    if (!manageFiltersBlock) {
        blocksContext?.setManageFiltersReady(true);
    }

    const content =
        contentSettings.context == 'edit' ? (
            <>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'report_content_blocks'}>
                        {(provided: any) => (
                            <div ref={provided.innerRef}>
                                {filteredBlocks.map((block, index) => (
                                    <Draggable draggableId={block.uid} key={block.uid} index={index}>
                                        {(provided: any) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                                <Stack
                                                    direction={'row'}
                                                    justifyContent={'space-between'}
                                                    sx={{
                                                        width: '100%',
                                                        marginTop: index == 0 ? undefined : '16px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            border: '1px solid',
                                                            borderRight: 'none',
                                                            borderColor: (theme) =>
                                                                alpha(theme.palette.text.primary, 0.08),
                                                            borderRadius: '1px 0 0 1px',
                                                            width: '21px',
                                                            flexShrink: 0,
                                                            marginTop:
                                                                block.component.settings.is_collapsed_ind != 'Y' &&
                                                                block.component.settings?.title?.length
                                                                    ? '32px'
                                                                    : undefined,
                                                        }}
                                                    >
                                                        <div
                                                            style={{ padding: '8px 0 0 2px' }}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <IconMi icon={'arrows-alt-v'} fontSize="16" />
                                                        </div>
                                                    </Box>
                                                    <Box sx={{ overflow: 'hidden', width: '100%' }}>
                                                        <ReportContentBlock
                                                            key={block.uid}
                                                            contentSettings={contentSettings}
                                                            block={block}
                                                            actions={actions}
                                                            datasetFields={datasetFields}
                                                        />
                                                    </Box>
                                                </Stack>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <ContentDropBox onAddNewBlock={onAddNewBlock} />
            </>
        ) : (
            filteredBlocks.map((block) => (
                <ReportContentBlock
                    key={block.uid}
                    contentSettings={contentSettings}
                    block={block}
                    actions={actions}
                    datasetFields={datasetFields}
                />
            ))
        );

    return (
        <Stack spacing={2}>
            {typeof manageFiltersBlock != 'undefined' && (
                <ReportContentBlock
                    key={'manage-filters'}
                    contentSettings={contentSettings}
                    block={manageFiltersBlock}
                    actions={actions}
                    isFilterBlock
                    datasetFields={datasetFields}
                />
            )}
            {hasUserMap && (
                <Box sx={{ textAlign: 'center' }}>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                component: 'FormCheckbox',
                                name: 'restrict_data_with_user_map',
                                label: 'Restrict Data with User Map',
                            },
                        })}
                    />
                </Box>
            )}
            {blocksContext?.manageFiltersReady == true ? content : null}
        </Stack>
    );
}
