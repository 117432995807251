import { Box, FormLabel, Input, Stack, Tooltip } from '@mui/material';
import { FormElementControlPropsType, FormControlTextProps } from './index';
import FormHidden from './FormHidden';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
import React, { useContext } from 'react';
import { FormComponentBuilder } from 'components/common/form/layout/index';
import Components, { ComponentKey } from 'components/common/ComponentIndex';
import showdown from 'showdown';
import { FormLayoutContext } from 'components/common/form/layout/tab';

const converter = new showdown.Converter();
export default function FormText({ controlProps, elementProps }: FormElementControlPropsType<FormControlTextProps>) {
    const inputProps = {
        readOnly: controlProps.readOnly,
        maxLength: controlProps.maxLength,
        startAdornment: controlProps.startAdornment ?? undefined,
        endAdornment: controlProps.endAdornment ?? undefined,
    };

    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'components/common/form/form']);

    const changeField = (event: any) => {
        let value = event.target.value as string;

        if (inputProps.maxLength !== undefined) {
            value = value.slice(0, inputProps.maxLength);
        }

        if (elementProps?.inputFilter != undefined) {
            value = elementProps?.inputFilter(value);
        }

        controlProps.onChange(value);
    };

    const layoutContext = useContext(FormLayoutContext);

    const label = controlProps.translate ? t(controlProps.label as string) : controlProps.label;
    if (controlProps.asLabel) {
        return (
            <>
                <FormHidden controlProps={controlProps} />
                <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box component="span" className={controlProps.labelClass}>
                        {t(controlProps.label as string)}
                    </Box>
                </FormLabel>
                <Box width="100%" className={controlProps.controlClass}>
                    {controlProps.value}
                </Box>
            </>
        );
    }

    const labelComponent = (
        <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="span" className={controlProps.labelClass}>
                {label}
            </Box>
            {controlProps?.tooltip?.icon && (
                <Tooltip
                    title={
                        <Box
                            sx={{ '& p': { m: 0 } }}
                            dangerouslySetInnerHTML={{
                                __html: converter.makeHtml(String(t(controlProps.tooltip.icon))),
                            }}
                        />
                    }
                    placement={'top-start'}
                >
                    <Box sx={{ display: 'inline-flex', ml: 0.5, color: 'primary.main' }}>
                        <IconMi icon="info-circle" fontSize="16" />
                    </Box>
                </Tooltip>
            )}
        </FormLabel>
    );
    const hasHelper = typeof controlProps.helper != 'undefined';
    const autocomplete =
        controlProps.autoComplete ?? controlProps.fieldType === 'password' ? 'new-password' : 'one-time-code';

    const inputComponent = (
        <Stack direction="row" width="100%" sx={{ width: controlProps.width }}>
            <Box sx={{ flexGrow: 1 }}>
                <Input
                    data-focusable
                    {...inputProps}
                    name={controlProps.name}
                    value={controlProps.value}
                    type={controlProps.fieldType ?? 'text'}
                    onChange={changeField}
                    placeholder={t(controlProps.placeholder ?? '')}
                    fullWidth
                    disabled={controlProps.disabled}
                    style={{ maxWidth: controlProps.maxWidth }}
                    onBlur={controlProps.onBlur}
                    className={controlProps.controlClass ?? undefined}
                    data-test={controlProps.uid}
                    autoComplete={autocomplete}
                    onKeyDown={(e) => {
                        if (layoutContext.formLayout == 'popup' && e.key === 'Enter') {
                            controlProps.form.formSave();
                        }
                    }}
                />
            </Box>
            {hasHelper &&
                React.createElement(Components[controlProps.helper.name as ComponentKey] as React.FC, {
                    // @ts-ignore
                    value: controlProps.value,
                    afterSave: () => {},
                    helperProps: controlProps.helper,
                    controlProps: controlProps,
                })}
        </Stack>
    );

    return (
        <>
            {controlProps.tooltip?.input?.length || controlProps.tooltip?.input?.length ? (
                <>
                    <Tooltip
                        title={
                            <Box
                                sx={{ '& p': { m: 0 } }}
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(
                                        String(t(controlProps.tooltip?.label ?? controlProps.tooltip.input))
                                    ),
                                }}
                            />
                        }
                        placement={'top-start'}
                    >
                        {labelComponent}
                    </Tooltip>

                    {controlProps.tooltip?.input?.length && (
                        <Tooltip
                            title={
                                <Box
                                    sx={{ '& p': { m: 0 } }}
                                    dangerouslySetInnerHTML={{
                                        __html: converter.makeHtml(String(t(controlProps.tooltip.input))),
                                    }}
                                />
                            }
                            placement={'top-start'}
                        >
                            {inputComponent}
                        </Tooltip>
                    )}
                </>
            ) : (
                <>
                    {controlProps.label == '' || typeof controlProps.label == 'undefined' ? null : labelComponent}
                    {inputComponent}
                </>
            )}
        </>
    );
}

export class FormTextManagerBuilder extends FormComponentBuilder {
    prepareProps(): FormControlTextProps {
        return {
            ...this.controlProps,
            placeholder: this.elementProps.componentProps?.placeholder ?? '',
            rows: this.elementProps.componentProps?.rows ?? 4,
            asLabel: this.elementProps.componentProps?.asLabel ?? false,
            maxLength: this.elementProps.componentProps?.maxLength,
            maxWidth: this.elementProps.componentProps?.maxWidth,
            disabled: this.elementProps.componentProps?.disabled ?? false,
            fieldType: this.elementProps.componentProps?.fieldType ?? 'text',
            startAdornment: this.elementProps.componentProps?.startAdornment,
            endAdornment: this.elementProps.componentProps?.endAdornment,
            autoComplete: this.elementProps.componentProps?.autoComplete,
        };
    }
}
