import React, { useMemo } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentProps = ReportContentNS.ComponentProps;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotDataCell = ReportContentNS.PivotDataCell;
import { PivotCellStyler } from 'components/report-content/components/pivot/PivotCellStyler';
import ComponentSettingsPivotField = ReportContentNS.ComponentSettingsPivotField;
import { Box } from '@mui/material';

export default function PivotCell({
    component,
    cell,
    cellStyler,
    isEven,
    cellIndex,
    field,
    width,
    left,
}: ComponentProps<ComponentSettingsPivot> & {
    cell: PivotDataCell;
    cellStyler: PivotCellStyler;
    isEven: boolean;
    cellIndex: number;
    field: ComponentSettingsPivotField;
    width: number;
    left: number;
}) {
    const styleData = cellStyler.getCellStyle(cellIndex, 0, field, cell, !isEven);

    const style = {
        display: 'inline-block',
        borderLeft: '1px rgba(204, 204, 204, 0.09) solid',
        //@ts-ignore
        width: width + 'px',
        overflow: 'hidden',
        verticalAlign: 'top',
        flexShrink: 0,
        lineHeight: '1.22em',
        minHeight: '1.89em',
        padding: '0.335em 5px',
        ...styleData.styleRules,
    };

    if (left > 0) {
        // @ts-ignore
        style.left = left + 'px';
    }

    let className = cellStyler.defaultCellStyles.buildClassStr(styleData.classRules);
    if (component.settings.rowFields.length > cell.shift) {
        className += ' block-wrapper-fixed-column';
    }

    const color =
        isEven && component.settings.zebra_striping == 'Y'
            ? cellStyler.defaultCellStyles.getZebraColor('#fff')
            : '#fff';

    return useMemo(
        () => (
            <Box
                component={'span'}
                sx={{
                    '&.block-wrapper-fixed-column': {
                        position: 'sticky',
                        left: 0,
                        backgroundColor: color ? color : '#fff',
                    },
                }}
                data-index={cellIndex}
                style={style}
                className={className}
                dangerouslySetInnerHTML={{ __html: cell.value }}
            />
        ),
        [JSON.stringify(style), cell.value, className, component.settings.zebra_striping]
    );
}
