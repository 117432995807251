import React, { useEffect } from 'react';
import { FormControl, FormLabel, Grid } from '@mui/material';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { ISubstitutionFormProps } from 'components/common/form/data-fetch-command/substitution/index';
import useBundleTranslation from 'i18n';

export default function SubstitutionDeleteValueField({
    formApi,
    measurementTime,
    hasMeasurementTime,
}: ISubstitutionFormProps & { measurementTime: string; hasMeasurementTime: boolean }) {
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);

    useEffect(() => {
        const defaultValue = formApi.form.hookFormGetValues('delete_data');
        if (typeof defaultValue == 'undefined' || defaultValue == '') {
            formApi.form.hookFormSetValue('delete_data', 'N');
        }
    }, []);

    const isDelete = formApi.form.hookFormWatch('delete_data') == 'Y';

    return (
        <Grid item xs={12}>
            <FormControl fullWidth>
                <FormLabel>{t('collection.delete_label')}</FormLabel>
                <TemplateFormComponent
                    componentValues={[
                        { label: 'Yes', value: 'Y' },
                        { label: 'No', value: 'N' },
                    ]}
                    component={'FormRadioGroup'}
                    elementProps={formApi}
                    name={'delete_data'}
                />
            </FormControl>
            {hasMeasurementTime && (
                <>
                    <br />
                    <br />
                    <div>
                        {t(isDelete ? 'collection.delete_yes' : 'collection.delete_no', {
                            measurementTime: measurementTime,
                        })}
                    </div>
                </>
            )}
        </Grid>
    );
}
