import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';

export default function ManualRunRequestCell({ data, setPopupUrl, setPopupConfig }: CustomCellProps) {
    if (data === undefined) {
        return;
    }

    const { t } = useBundleTranslation('app/editor/schedule/manual-run');
    const cellDataRows = [];

    if (data.stage > '') {
        if (data.stage === 'bursting_favorites') {
            if (Number(data.run_dl_list_id) > 0) {
                const popupConfig: PopupSettings = {
                    title: t('dl_popup_title'),
                    noCancel: true,
                    textOK: t('dl_popup_close'),
                };

                const onClick = (event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();

                    if (setPopupConfig) {
                        setPopupConfig(popupConfig);
                    }

                    if (setPopupUrl) {
                        setPopupUrl(
                            `/data/editor/schedule/${data.notification_schedule_id}/history/${data.ns_run_id}/show-distribution-list-4-run/index`
                        );
                    }
                };

                const button = (
                    <Box component="a" onClick={onClick} sx={{ cursor: 'pointer' }}>
                        {t('list_of_bursts')}
                    </Box>
                );

                cellDataRows.push(button);
            } else {
                cellDataRows.push(<Typography>{t('all_bursts')}</Typography>);
            }
        } else if (data.stage === 'favorites') {
            cellDataRows.push(<Typography>{t('favorite')}</Typography>);
        }

        if (Number(data.run_requested_by) > 0) {
            const text = `${t('requested_by')} ${data.run_requestor > '' ? data.run_requestor : t('someone')}`;

            cellDataRows.push(
                <Typography textOverflow={'ellipsis'} overflow={'hidden'}>
                    {text}
                </Typography>
            );
        }

        if (data.user_scope > '') {
            const text = `${t('for')} ${
                data.user_scope === 'all'
                    ? t('all_recipients')
                    : data.user_scope === 'admins'
                    ? t('all_admins')
                    : t('self')
            }`;
            cellDataRows.push(
                <Typography textOverflow={'ellipsis'} overflow={'hidden'}>
                    {text}
                </Typography>
            );
        }
    }

    return (
        <Stack direction={'column'} spacing={0.75} sx={{ padding: '8px' }}>
            {cellDataRows.map((cellDataRow, index) => (
                <Box key={`cell-${data.notification_schedule_id}-${data.ns_run_id}-row-${index}`}>{cellDataRow}</Box>
            ))}
        </Stack>
    );
}
