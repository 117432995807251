import React, { useEffect } from 'react';
import { CustomCellProps, TransformedDataObject } from 'components/common/grid/index';

import { Box, IconButton, Input, Tooltip } from '@mui/material';
import { AsyncActionData, gridAPI } from 'api/grid';
import { getCellContent, getCellWithTooltip } from './index';
import { APIResponse } from 'tools/types';
import { openInNewTab } from 'tools/tools';
import { useNavigate } from 'react-router-dom';
import IconMi from 'components/common/icon/IconMi';

import { translationAPI } from 'api/api';
import { useExpanded } from 'react-table';
import useBundleTranslation from 'i18n';

export default function TranslationCell({
    text,
    tooltip,
    subText,
    data,
    icon,
    href,
    reloadGridData,
    setShowLoader,
    rawHtml,
    align,
    rawTooltipHtml,
}: CustomCellProps) {
    const navigate = useNavigate();
    const { t } = useBundleTranslation('admin/vocabulary');
    const [isChanged, setIsChanged] = React.useState<boolean>(false);
    const [isDifferentFromSystemValue, setIsDifferentFromSystemValue] = React.useState<boolean>(false);
    const englishValue = data?.en_value as string;
    const initialValue = data?.value as string;
    const [value, setValue] = React.useState<string>(initialValue);

    useEffect(() => {
        setValue(initialValue);
        setIsChanged(false);
        setIsDifferentFromSystemValue(
            undefined !== data?.system_value &&
                data?.system_value > '' &&
                undefined !== data?.value &&
                data?.system_value != data?.value
        );
    }, [initialValue]);

    const changeValue = (v: string) => {
        setValue(v);
        setIsChanged(v !== initialValue);
        setIsDifferentFromSystemValue(
            undefined !== data?.system_value && data?.system_value > '' && data?.system_value != v
        );
    };

    const revertClick = () => {
        changeValue(initialValue ?? '');
    };

    const saveClick = () => {
        translationAPI
            .save({
                value: value,
                language_id: data?.language_id,
                application_translation_token_id: data?.application_translation_token_id,
                application_translation_id: data?.application_translation_id,
            })
            .then((response: any) => {
                if (reloadGridData && response.status === 'OK') reloadGridData();
            })
            .finally(() => {});
    };

    const suggestionClick = () => {
        translationAPI
            .suggestion({
                value: englishValue,
                language_id: data?.language_id,
                token: data?.token,
                application_translation_token_id: data?.application_translation_token_id,
            })
            .then((response: any) => {
                if (response.status === 'OK' && response.suggestion > '') {
                    changeValue(response.suggestion as string);
                }
            })
            .finally(() => {});
    };

    const rollbackClick = () => {
        translationAPI
            .rollback({
                application_translation_id: data?.application_translation_id,
            })
            .then((response: any) => {
                if (reloadGridData && response.status === 'OK') reloadGridData();
            })
            .finally(() => {});
    };

    const changeField = (e: any) => {
        changeValue(e.target.value as string);
    };

    const keyDown = (e: any) => {
        if (isChanged && 's' == e.key && e.altKey && e.ctrlKey) {
            saveClick();
            return false;
        }

        if ('' == e.target.value && 't' == e.key && e.altKey && e.ctrlKey) {
            suggestionClick();
            return false;
        }
        return true;
    };

    const onBlur = () => {};

    return (
        <div>
            <Input
                style={{ width: 250 }}
                value={value}
                inputProps={{ tabIndex: 1 }}
                multiline={true}
                type={'text'}
                onChange={changeField}
                onKeyDown={keyDown}
                onBlur={onBlur}
            />
            <span>
                {'' == value && (
                    <Tooltip id="button-report" title={t('suggest')}>
                        <IconButton
                            aria-label="suggest"
                            size={'small'}
                            sx={{ color: 'text.primary' }}
                            onClick={() => suggestionClick()}
                        >
                            <IconMi icon={'vocabulary'} fontSize={'16'} />
                        </IconButton>
                    </Tooltip>
                )}
                {isChanged && (
                    <>
                        <Tooltip id="button-report" title={t('save')}>
                            <IconButton
                                aria-label="apply"
                                size={'small'}
                                sx={{ color: 'text.primary' }}
                                onClick={() => saveClick()}
                            >
                                <IconMi icon={'success'} fontSize={'16'} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip id="button-report" title={t('rollback')}>
                            <IconButton
                                aria-label="apply"
                                size={'small'}
                                sx={{ color: 'text.primary' }}
                                onClick={() => revertClick()}
                            >
                                <IconMi icon={'refresh-cw'} fontSize={'16'} />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                {isDifferentFromSystemValue && (
                    <Tooltip id="button-report" title={t('back_to_default')}>
                        <IconButton
                            aria-label="reject"
                            size={'small'}
                            sx={{ color: 'text.primary' }}
                            onClick={() => rollbackClick()}
                        >
                            <IconMi icon={'reject'} fontSize={'16'} />
                        </IconButton>
                    </Tooltip>
                )}
            </span>
        </div>
    );
}
