import { Box, Button, InputAdornment, Skeleton, Stack, TextField, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useEffect, useState } from 'react';
import { NodeModel } from '@minoru/react-dnd-treeview/dist/types';
import { elementAPI, FolderData } from 'api/editor/element';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import styles from './FolderSelect.styles';
import Tree from 'components/common/tree/Tree';
import useBundleTranslation from 'i18n';
import Node from './Node';

export default function AddFolderButton({
    refetch,
    baseUrl,
    selectedFolders,
    afterAdd,
}: {
    refetch: () => void;
    baseUrl: string;
    selectedFolders: FolderData[];
    afterAdd: () => void;
}) {
    const { t } = useBundleTranslation(['app/editor/folder/content']);

    const [showFoldersPopup, setShowFoldersPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [foldersFilter, setFoldersFilter] = useState('');
    const [folders4select, setFolders4select] = useState<NodeModel[]>([]);
    const [openNodes, setOpenNodes] = useState<Set<string | number>>();
    const [initFolders4select, setInitFolders4select] = useState<NodeModel[]>([]);

    const processResponse = (response: NodeModel[]) => {
        const openNodesSet = new Set<string | number>();
        response.forEach((folder) => {
            openNodesSet.add(folder.id);
        });

        setOpenNodes(openNodesSet);
        setInitFolders4select(response);
        setFolders4select(response);
    };

    const onAddButtonClick = () => {
        setShowFoldersPopup(true);

        if (initFolders4select.length === 0) {
            setLoading(true);

            elementAPI.getAvailableFolders(baseUrl).then((response) => {
                setLoading(false);
                processResponse(response);
            });
        }
    };

    const popupSettings: PopupSettings = {
        title: t('choose_folder_title'),
        noButtons: true,
    };

    const handleHidePopup = () => {
        setShowFoldersPopup(false);
        setFoldersFilter('');
        setFolders4select(initFolders4select);
    };

    const getFilterInputIcon = () => {
        if (foldersFilter === '') {
            return <IconMi icon="search" fontSize={'16'} />;
        }

        return (
            <Box
                sx={{ cursor: 'pointer', marginTop: '5px', color: 'text.primary' }}
                onClick={() => {
                    setFoldersFilter('');
                }}
            >
                <IconMi icon="times" fontSize={'16'} />
            </Box>
        );
    };

    const handleChangeFilterFolders = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFoldersFilter(event.target.value);
    };

    const pushParentFolders = (folder: NodeModel, filteredFoldersWithParents: NodeModel[]) => {
        if (folder.parent > 0) {
            const parentExist = filteredFoldersWithParents.find((searchFolder) => {
                return searchFolder.id === folder.parent;
            });

            if (!parentExist) {
                const parent = initFolders4select.find((searchFolder) => {
                    return searchFolder.id === folder.parent;
                });

                if (parent) {
                    filteredFoldersWithParents.push(parent);
                    pushParentFolders(parent, filteredFoldersWithParents);
                }
            }
        }
    };

    const pushFolderChild = (folder: NodeModel, filteredFoldersWithParents: NodeModel[]) => {
        const childFolders = initFolders4select.filter((searchFolder) => {
            return searchFolder.parent === folder.id;
        });

        if (childFolders.length > 0) {
            childFolders.forEach((childFolder) => {
                const childExist = filteredFoldersWithParents.find((searchFolder) => {
                    return searchFolder.id === childFolder.id;
                });

                if (!childExist) {
                    filteredFoldersWithParents.push(childFolder);
                    pushFolderChild(childFolder, filteredFoldersWithParents);
                }
            });
        }
    };

    useEffect(() => {
        if (foldersFilter === '') {
            setFolders4select(initFolders4select);

            return;
        }

        const filteredFolders = initFolders4select.filter((folder) => {
            return folder.text.toLowerCase().includes(foldersFilter.toLowerCase());
        });

        let filteredFoldersWithParents: NodeModel[] = [];

        if (filteredFolders.length > 0) {
            filteredFolders.forEach((folder) => {
                const folderExist = filteredFoldersWithParents.find((searchFolder) => {
                    return searchFolder.id === folder.id;
                });
                if (!folderExist) {
                    filteredFoldersWithParents.push(folder);
                    pushParentFolders(folder, filteredFoldersWithParents);
                }
            });
            filteredFolders.forEach((folder) => {
                pushFolderChild(folder, filteredFoldersWithParents);
            });
        }

        setFolders4select(filteredFoldersWithParents);
    }, [foldersFilter, initFolders4select]);

    return (
        <>
            <Button
                data-test={'add-folder-button'}
                color={'success'}
                variant={'light'}
                startIcon={<IconMi icon="new" fontSize="16" />}
                onClick={onAddButtonClick}
            >
                {t('add_folder')}
            </Button>
            {showFoldersPopup && (
                <Popup settings={popupSettings} open={showFoldersPopup} onHide={handleHidePopup} maxWidth={'popupLg'}>
                    {loading ? (
                        <Skeleton height={'380px'} animation={'pulse'} />
                    ) : (
                        <Stack direction={'column'} spacing={2}>
                            <TextField
                                value={foldersFilter}
                                placeholder={t('filter_folders_placeholder')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                                            {getFilterInputIcon()}
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                onChange={handleChangeFilterFolders}
                            />
                            <Box sx={styles.wrapper}>
                                {folders4select.length > 0 ? (
                                    <Tree
                                        treeData={folders4select}
                                        nodeComponent={Node}
                                        openState={openNodes}
                                        additionalNodeProps={{
                                            selectedFolders: selectedFolders,
                                            onFolderSelect: (folder: NodeModel) => {
                                                elementAPI.addFolder(baseUrl, folder.id).then((response) => {
                                                    refetch();
                                                    handleHidePopup();
                                                    afterAdd();
                                                });
                                            },
                                        }}
                                    />
                                ) : (
                                    <Box sx={styles.noResultsWrapper}>
                                        <Typography>{t('folders_no_result')}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    )}
                </Popup>
            )}
        </>
    );
}
