import React, { useEffect, useState } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { ElementType } from 'components/element-viewer/index';
import IconMi from 'components/common/icon/IconMi';
import { useSelector } from 'react-redux';

export default function HeadingElementData({ element, children }: { element: ElementType; children?: any }) {
    const brandThemeProperties = useSelector((state: any) => state.auth?.userInfo?.brandThemeProperties);
    const isDisplayName = brandThemeProperties?.brand_theme_element_viewer_display_name === 'Y';
    const isDisplayDescription =
        brandThemeProperties?.brand_theme_element_viewer_display_description === 'Y' && element.row.description > '';
    const isDisplayCertification =
        brandThemeProperties?.brand_theme_element_viewer_display_certification === 'Y' && element.certification.enabled;
    const descriptionContentRef = React.useRef<HTMLDivElement>(null);
    const [descriptionCut, setDescriptionCut] = useState<boolean>(false);

    useEffect(() => {
        if (isDisplayDescription && descriptionContentRef.current) {
            setDescriptionCut(descriptionContentRef.current.offsetWidth > 540);
        }
    }, []);

    const certificationIcon = isDisplayCertification ? (
        <Box className={'certification-icon'} sx={{ display: 'inline-flex' }}>
            <IconMi
                icon="certified"
                sx={{
                    fontSize: '16px',
                    color: element.certification.color,
                }}
            />
        </Box>
    ) : null;

    const descriptionElement = !isDisplayDescription ? null : element.row.descriptionMarkdownInd ? (
        <span
            className={'markdown-holder'}
            dangerouslySetInnerHTML={{
                __html: element.row.descriptionMarkdown,
            }}
        />
    ) : (
        element.row.description
    );

    const isOnlyCertificationIcon = !isDisplayName && !isDisplayDescription && !children && isDisplayCertification;
    return (
        <Stack
            sx={{ overflow: 'hidden', pr: 2 }}
            className={`heading-element-data ${
                isOnlyCertificationIcon ? 'heading-element-data--only-certification' : ''
            }`}
        >
            {isDisplayName && (
                <Stack direction={'row'} alignItems={'center'} sx={{ pt: 0.5, '.certification-icon': { ml: 0.5 } }}>
                    <Box
                        sx={{
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {element.row.name}
                    </Box>
                    {isDisplayCertification && certificationIcon}
                </Stack>
            )}

            {isDisplayDescription && (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ pt: isDisplayName ? 0.25 : 0.75, '.certification-icon': { mr: 0.5 } }}
                >
                    {!isDisplayName && isDisplayCertification && certificationIcon}
                    <Tooltip
                        title={descriptionCut ? descriptionElement : ''}
                        placement="bottom-end"
                        slotProps={{
                            tooltip: {
                                sx: { maxWidth: '485px', marginTop: '5px !important' },
                            },
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '540px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                '*': {
                                    display: 'inline !important',
                                    whiteSpace: 'nowrap !important',
                                },
                            }}
                        >
                            <Box className={'description-holder'} ref={descriptionContentRef}>
                                {descriptionElement}
                            </Box>
                        </Box>
                    </Tooltip>
                </Stack>
            )}
            {children && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        pt: isDisplayName || isDisplayDescription ? 1 : undefined,
                        '.certification-icon': { alignSelf: 'center' },
                    }}
                >
                    {!isDisplayName && !isDisplayDescription && isDisplayCertification && certificationIcon}
                    {children}
                </Stack>
            )}
            {isOnlyCertificationIcon && (
                <Stack
                    direction="row"
                    alignItems={'center'}
                    sx={{ minHeight: (theme) => theme.size.pxValue(theme.size.defaultHeight) }}
                >
                    {certificationIcon}
                </Stack>
            )}
        </Stack>
    );
}
