import { ElementViewerDataType, prepareSegments } from 'components/element-viewer';
import { FilterType, getFiltersStateBuilder } from 'components/external-reference/Filter';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';
import React, { useEffect, useState } from 'react';
import { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';
import {
    applyBookmarkToFilters,
    getNewBookmarkObj,
    setBookmarkReferenceId,
} from 'components/external-reference/bookmark/Bookmark';
import { externalReportAPI } from 'api/external-report';
import { ExternalReportViewerDataType, useGetSegmentValueIdFromFilters } from 'app/extreport/ExternalReportViewer';
import { arrayMoveImmutable } from 'array-move';
import useBundleTranslation from 'i18n';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { useParams } from 'react-router-dom';

export interface IBookmarksContext {
    showIcon?: boolean;
    filters: Array<FilterType>;
    siblingTabs: Array<SiblingTabType>;
    bookmarks: Array<BookmarkType>;
    selectedReferenceId: number;
    APIUpdateBookmark: (bookmark: BookmarkType | null, selectedReferenceId: number) => void;
    APISaveAsNewBookmark: (bookmark: BookmarkType | null, selectedReferenceId: number) => void;
    APIDeleteBookmark: (bookmarkId: number) => void;
    APIDuplicateBookmark: (bookmarkId: number) => void;
    APISortBookmarks: (oldIndex: number, newIndex: number) => void;
    APIIsLoading: APILoadingType;
}

export type APILoadingType = 'delete' | 'save' | 'update' | 'copy' | 'sort' | false;

export default function useBookmarks(
    viewerRequestExtraData: ExternalReportViewerDataType,
    viewerRequestData: ElementViewerDataType,
    filters: Array<FilterType>,
    siblingTabs: Array<SiblingTabType>,
    handleApplyBookmark?: (bookmarkId: number) => void,
    distributionItemId?: number
) {
    const [bookmarks, setBookmarks] = useState<Array<BookmarkType>>([]);
    const [shouldSelectNewBookmark, setShouldSelectNewBookmark] = useState(false);
    const [filtersData, setFiltersData] = useState<any>();
    const [appliedBookmarkId, setAppliedBookmarkId] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<APILoadingType>(false);

    const { t } = useBundleTranslation(['components/external-reference/bookmark']);

    useEffect(() => {
        if (!shouldSelectNewBookmark || !handleApplyBookmark || bookmarks.length != 1) {
            setShouldSelectNewBookmark(false);
            return;
        }
        handleApplyBookmark(bookmarks[0].id);
        setShouldSelectNewBookmark(false);
    }, [shouldSelectNewBookmark]);

    const findBookmarkIndexById = function (bookmarkId: number): number {
        return bookmarks.findIndex((bookmark) => bookmark.id == bookmarkId);
    };

    const elementId = viewerRequestData.elementData.row.elementId;

    // Save bookmark changes
    const APIUpdateBookmark = function (bookmark: BookmarkType | null, selectedReferenceId: number) {
        let filtersState = filters.slice();

        if (!bookmark) {
            // If bookmark empty update AppliedBookmark
            // Can be called from BookmarkDropDown
            if (appliedBookmarkId == 0) {
                // Save as new
                return APISaveAsNewBookmark(null, selectedReferenceId);
            }
            bookmark = bookmarks[findBookmarkIndexById(appliedBookmarkId)];
            // Update bookmark filters list
            bookmark.filtersList = filters.map((filter) => ({
                filterId: filter.filterId,
                values: filter.values.filter((v) => filter.selectedValues.includes(v.value_id)),
                selectedValues: filter.selectedValues,
                filterInput: filter.filterInput,
            }));
            // Update bookmark referenceId
            const tab = siblingTabs.find((t) => t.referenceId == selectedReferenceId);
            setBookmarkReferenceId(bookmark, tab ?? false);
        }

        if (bookmark.id == 0) {
            return APISaveAsNewBookmark(bookmark, selectedReferenceId);
        }

        filtersState = applyBookmarkToFilters(filtersState, bookmark);
        // POST to server
        let segmentValueId = useGetSegmentValueIdFromFilters(filtersState);
        setIsLoading('update');
        // Update state
        const list = bookmarks.slice();
        list[findBookmarkIndexById(bookmark.id)] = bookmark;
        externalReportAPI.bookmark
            .updateBookmark(
                elementId,
                segmentValueId,
                bookmark,
                JSON.stringify(getFiltersStateBuilder(filtersState.filter((f) => f.filterId > 0))),
                distributionItemId
            )
            .then((response) => {
                setBookmarks(list);
            })
            .finally(() => setIsLoading(false));
    };

    const APISaveAsNewBookmark = function (bookmark: BookmarkType | null, selectedReferenceId: number) {
        let filtersState = filters.slice();
        if (!bookmark) {
            bookmark = getNewBookmarkObj();
            bookmark.filtersList = filters.map((filter) => ({
                filterId: filter.filterId,
                values: filter.values.filter((v) => filter.selectedValues.includes(v.value_id)),
                selectedValues: filter.selectedValues,
                filterInput: filter.filterInput,
            }));
            // Update bookmark referenceId
            const tab = siblingTabs.find((t) => t.referenceId == selectedReferenceId);
            setBookmarkReferenceId(bookmark, tab ?? false);
        } else {
            filtersState = applyBookmarkToFilters(filtersState, bookmark);
        }

        let segmentValueId = useGetSegmentValueIdFromFilters(filtersState);
        setIsLoading('save');
        externalReportAPI.bookmark
            .updateBookmark(
                elementId,
                segmentValueId,
                bookmark,
                JSON.stringify(getFiltersStateBuilder(filtersState.filter((f) => f.filterId > 0))),
                distributionItemId
            )
            .then((response) => {
                if (response.data!.status == 'OK' && bookmark) {
                    bookmark.id = response.data.data.id;
                    bookmark.displayOrder = response.data.data.displayOrder;
                    const list = bookmarks.slice();
                    list.push(bookmark);
                    setBookmarks(list);
                    setShouldSelectNewBookmark(true);
                } else {
                    console.log(response);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const APIDuplicateBookmark = (bookmarkId: number) => {
        setIsLoading('copy');
        externalReportAPI.bookmark
            .duplicateBookmark(elementId, bookmarkId)
            .then((response) => {
                if (response.data!.status == 'OK') {
                    const bookmark = getNewBookmarkObj(bookmarks.find((b) => b.id == bookmarkId));
                    //@ts-ignore
                    bookmark.id = response.data.data.bookmark_id;
                    bookmark.displayOrder = response.data.data.displayOrder;
                    bookmark.name = response.data.data.name;
                    const list = bookmarks.slice();
                    // @ts-ignore
                    list.push(bookmark);
                    setBookmarks(list);
                } else {
                    console.log(response);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const APIDeleteBookmark = (bookmarkId: number) => {
        if (!confirm(t(distributionItemId ? 'confirm_delete_combination' : 'confirm_delete'))) {
            return;
        }

        setIsLoading('delete');
        const list = bookmarks.slice();
        list.splice(findBookmarkIndexById(bookmarkId), 1);
        externalReportAPI.bookmark
            .deleteBookmark(elementId, bookmarkId)
            .then((response) => {
                if (response.data!.status == 'OK') {
                    setBookmarks(list);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const APISortBookmarks = (oldIndex: number, newIndex: number) => {
        setIsLoading('sort');
        const list = arrayMoveImmutable(bookmarks, oldIndex, newIndex);
        externalReportAPI.bookmark
            .sortBookmarks(elementId, list, distributionItemId)
            .then(() => {
                setBookmarks(list);
            })
            .finally(() => setIsLoading(false));
    };

    // Process initial data
    useEffect(() => {
        // if (viewerDataRequestStatus != 'success') {
        //     return;
        // }
        const filtersData = viewerRequestExtraData.filtersData.data;
        let { filtersList, segmentValuesMap } = prepareSegments(filtersData.filtersList, viewerRequestData.segmentData);
        let hasSegment = false;
        const bookmarksList = filtersData.bookmarksList.map((bookmark: BookmarkType) => {
            if (bookmark.segmentId) {
                hasSegment = true;
                // Setup SegmentFilter as filter for bookmark
                // const segmentId = bookmark.segmentId * -1;

                let parentSegmentIsSet = false;
                filtersList.forEach((filter: FilterType) => {
                    if (!filter.isSegment) {
                        return;
                    }

                    let segmentValueId = bookmark.segmentValueId;
                    if (bookmark.parentSegmentValueId > 0) {
                        segmentValueId = Number(
                            parentSegmentIsSet ? bookmark.segmentValueId : bookmark.parentSegmentValueId
                        );
                        parentSegmentIsSet = true;
                    }
                    // @ts-ignore
                    const segmentValueInfo = segmentValuesMap[filter.filterId].find(
                        (value: any) => value.value_id == segmentValueId
                    );

                    bookmark.filtersList.unshift({
                        filterId: filter.filterId,
                        values: [
                            {
                                value_id: segmentValueId,
                                value: segmentValueInfo.value,
                                display_value: segmentValueInfo.display_value,
                                isVisible: true,
                            },
                        ],
                        selectedValues: [bookmark.segmentValueId],
                        filterInput: 'single',
                    });
                });
            }

            bookmark.filtersList = bookmark.filtersList.map((filter) => {
                const fullFilter = filtersList.find((f: FilterType) =>
                    filter.filterId < 0 ? f.filterId == filter.filterId * -1 : f.filterId == filter.filterId
                );
                if (fullFilter) {
                    filter.filterInput = fullFilter.filterInput ?? 'single';
                }
                if (filter.filterInput == 'text') {
                    filter.selectedValues = filter.values.map((v) => v.value);
                } else {
                    filter.selectedValues = filter.values.map((v) => v.value_id);
                }
                return filter;
            });
            return bookmark;
        });
        // Find default bookmark
        if (filtersData.selectedBookmarkId == 0 && bookmarksList.length != 0 && !hasSegment) {
            // Select first Bookmark
            filtersData.selectedBookmarkId = bookmarksList[0].id;
        }
        setBookmarks(bookmarksList);
        setFiltersData(filtersData);
    }, []);

    return {
        bookmarks,
        setBookmarks,
        filtersData,
        appliedBookmarkId,
        setAppliedBookmarkId,
        APIUpdateBookmark,
        APISaveAsNewBookmark,
        APIDeleteBookmark,
        APIDuplicateBookmark,
        APISortBookmarks,
        APIIsLoading: isLoading,
    };
}
