import React, { useEffect, useMemo, useState } from 'react';
import Components, { ComponentKey } from 'components/common/ComponentIndex';
import { Stack, Box } from '@mui/material';
import { FormComponentValue, FormControlProps, FormElementControlPropsType, prepareFormComponentValues } from './index';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { setFormElementSharedState } from 'store/formSlice';
import { useDispatch } from 'react-redux';
import { FormComponentBuilder } from 'components/common/form/layout';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { FormElementProps } from 'components/common/form/index';
import useBundleTranslation from 'i18n';

export interface FormControlSelectProps extends FormControlProps {
    urlParams: UrlParams;
    isMulti: boolean;
    disabled: boolean;
    hideSingle: boolean;
    data: Array<FormComponentValue>;
    elementProps: FormElementProps;
    addonLabel?: string;
    isMultiOneRowMode?: boolean;
    async: boolean;
    getOptionLabel?: (option: any) => any;
}

export function getRefreshKey(controlProps: FormControlProps) {
    let params = '';
    let isComponentReady = true;
    if (controlProps?.urlParams) {
        for (const [k, v] of Object.entries(controlProps.urlParams)) {
            params += `${k}=${v}`;
            if (String(v)?.startsWith('$')) {
                isComponentReady = false;
                break;
            }
        }
    }
    return isComponentReady ? params : '';
}

export default function FormSelect({ controlProps }: FormElementControlPropsType<FormControlSelectProps>) {
    const hasHelper = typeof controlProps.helper != 'undefined';
    const { t } = useBundleTranslation(controlProps.elementProps?.translationNS ?? 'components/common/form/form');

    // Update Select data on UrlParams Change
    const refreshKey = useMemo(() => {
        return getRefreshKey(controlProps);
    }, [controlProps.urlParams]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!controlProps.form.formDidMount || refreshKey == '') {
            return;
        }
        refreshValues();
    }, [refreshKey]);

    let refreshValues = (response?: any) => {
        if (
            typeof controlProps.elementProps == 'undefined' ||
            !controlProps.elementProps.form.handleRefreshComponentData
        ) {
            return;
        }

        let ret = controlProps.elementProps.form.handleRefreshComponentData(controlProps, (response: any) => {
            return prepareFormComponentValues(response.data.data);
        });
        if (undefined != response && response.data && response.data.id)
            ret.then(() => controlProps.onChange(response.data.id));
    };

    const handleOnChange = (value: any, fullValue: any) => {
        // Update component Shared State

        const selectedOption =
            controlProps.async && fullValue ? fullValue : controlProps.data.find((data) => data.value == value);

        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: { selectedOption: selectedOption },
            })
        );
        controlProps.onChange(value);
    };

    const [editDisabled, setEditDisabled] = useState(controlProps.helper?.editDisabled ?? false);
    const [addDisabled, setAddDisabled] = useState(controlProps.helper?.addDisabled ?? false);
    useEffect(() => {
        const selectedOption = controlProps.data.find((data) => data.value == controlProps.value);
        if (selectedOption?.props?.allow_edit_ind == 'N') {
            setEditDisabled(true);
        } else {
            if (['', null, '0', 0].includes(controlProps.value)) {
                setEditDisabled(true);
            } else {
                setEditDisabled(controlProps.helper?.editDisabled ?? false);
            }
        }
        if (selectedOption?.props?.allow_add_ind == 'N') {
            setAddDisabled(true);
        } else {
            setAddDisabled(controlProps.helper?.addDisabled ?? false);
        }
    }, [controlProps.value, controlProps.data]);

    let asyncUrl = '';
    const asyncParams = controlProps.urlParams;
    if (controlProps.async && controlProps.form.settingsUrl) {
        //@ts-ignore
        asyncParams.uid = controlProps.uid;
        asyncUrl = controlProps.form.settingsUrl + '/refresh-component';
    }

    return (
        <>
            <Stack direction="row" width="100%" sx={{ width: controlProps.width }}>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <ReactSelect
                        data={controlProps.data}
                        value={controlProps.value}
                        disabled={controlProps.disabled}
                        update={handleOnChange}
                        name={controlProps.name}
                        isMulti={controlProps.isMulti}
                        isMultiOneRowMode={controlProps.isMultiOneRowMode}
                        addonLabel={controlProps.addonLabel ? t(controlProps.addonLabel) : undefined}
                        data-test={controlProps.uid}
                        async={asyncUrl}
                        urlParams={asyncParams}
                        getOptionLabel={controlProps.getOptionLabel}
                    />
                </Box>
                {hasHelper &&
                    React.createElement(Components[controlProps.helper.name as ComponentKey] as React.FC, {
                        // @ts-ignore
                        value: controlProps.value,
                        afterSave: refreshValues,
                        helperProps: { ...controlProps.helper, editDisabled: editDisabled, addDisabled: addDisabled },
                        controlProps: controlProps,
                    })}
            </Stack>
        </>
    );
}

export class FormSelectBuilder extends FormComponentBuilder {
    processFormData(formData: FormData, key: string, data: any) {
        // If multiple = true append files to formData request as array
        if (!(this.controlProps as FormControlSelectProps).isMulti || !Array.isArray(data)) {
            formData.append(key, data);
        } else {
            data.forEach((v: string) => {
                formData.append(key + '[]', v);
            });
        }
    }

    prepareProps(): FormControlSelectProps {
        return {
            ...this.controlProps,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            } as UrlParams,
            data: this.componentValues,
            elementProps: this.elementProps,
            isMulti: this.elementProps.componentProps?.isMulti ?? false,
            isMultiOneRowMode: this.elementProps.componentProps?.isMultiOneRowMode ?? false,
            disabled: this.elementProps.componentProps?.disabled ?? false,
            hideSingle: this.elementProps.componentProps?.hideSingle ?? false,
            width: this.elementProps.componentProps?.width,
            addonLabel: this.elementProps.componentProps?.addonLabel,
            async: this.elementProps.componentProps?.async ?? false,
            getOptionLabel: this.elementProps.componentProps?.getOptionLabel,
        };
    }
}
