import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import { ReportContentNS } from 'components/report-content/index';
import { IFilterColumn } from 'components/report-content/components/manage-filters/index';
import ManageFiltersInitialDataResponse = ReportContentNS.ManageFiltersInitialDataResponse;
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import IBlocksContext = ReportContentNS.IBlocksContext;
import BlocksContext = ReportContentNS.BlocksContext;
import FilterControlSelect from 'components/report-content/components/manage-filters/parts/filter/FilterControlSelect';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import FilterControlRange from 'components/report-content/components/manage-filters/parts/filter/FilterControlRange';
import FilterControlChartingInterval from 'components/report-content/components/manage-filters/parts/filter/FilterControlChartingInterval';
import getFilterConditionRules from 'components/report-content/components/manage-filters/helpers/getFilterConditionRules';
import buildFilterColumns from 'components/report-content/components/manage-filters/helpers/buildFilterColumns';
import { useLocation } from 'react-router-dom';
import { AssocArray } from 'tools/types';
import ManageFiltersInstance = ReportContentNS.ManageFiltersInstance;
import ReactSelect from 'components/common/react-select/ReactSelect';
import { alpha, Box, Button, Stack, Tooltip } from '@mui/material';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import { ReportFilterSegment } from 'components/report-content/hooks/useReportContentSettings';
import { useBlendColors } from 'hooks/useDesign';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import useOnMeasurementTime from 'components/report-content/hooks/useOnMeasurementTime';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';
import { externalReportAPI } from 'api/external-report';

// On 'viewer' mode send request to save selected values
function saveFiltersValue(elementId: number, segmentValueId: number, settings: ComponentSettingsManageFilters) {
    const getCurrentFilters = (onlyChangedFilters = true) => {
        const savedFilters: any = settings.fields
            .filter((f) => {
                let canBeCached = true;
                return (
                    f.filter_column === 'Y' &&
                    (f.filterType === 'multiselect' || f.filterType === 'dropdown') &&
                    (!onlyChangedFilters || canBeCached)
                );
            })
            .map((f) => ({
                name: f.column_name,
                title: f.filter_label,
                filterType: f.filterType,
                referenceName: f.reference_name_escaped,
                values: f.savedValues,
                canBeCached: true,
            }));
        if (savedFilters.length > 0) {
            return savedFilters;
        }
        return null;
    };
    const filterData = getCurrentFilters();
    if (filterData) {
        externalReportAPI.bookmark.saveFilters(elementId, segmentValueId, filterData);
    }
}

export default function ManageFilters({
    contentSettings,
    component,
    updateBlockSettings,
    actions,
    editPanel,
    blockId,
}: ComponentUpdateProps<ComponentSettingsManageFilters>) {
    const { t } = useBundleTranslation(['components/report-content']);
    const location = useLocation();
    const initialQueryKey = `report_block_initial_${component.datasetRendererBlockComponentId}`;
    const { data: initialData, remove: removeInitialData } = useQuery<ManageFiltersInitialDataResponse, Error>(
        [initialQueryKey],
        () => {
            return reportAPI.getComponentData(component.settings, contentSettings, null);
        }
    );

    // Full reset on Measurement Interval Change
    useOnMeasurementTime(contentSettings.measurement_time, removeInitialData);

    const [filterColumns, setFilterColumns] = useState<Array<IFilterColumn>>([]);

    const [lastFilterSettings, setLastFilterSettings] = useState<any>(null);
    const getFilterSettings = (filters: Array<IFilterColumn>) => {
        return filters
            .sort((a, b) => (a.reference_name > b.reference_name ? 1 : -1))
            .map((filter) => ({
                name: filter.reference_name,
                type: filter.filterType,
                sort_order: filter.sortDirection,
            }));
    };

    // Pass filter values from URL GET params into filters
    useEffect(() => {
        let requestParams: AssocArray<Array<string>> = {};
        if (location.search.length) {
            const urlParam = new URLSearchParams(location.search);
            for (let key of urlParam.keys()) {
                const values = urlParam.getAll(key);
                if (key.endsWith('[]')) {
                    key = key.slice(0, -2);
                }
                requestParams[key] = values;
            }
        }
        if (component.settings.fields) {
            component.settings.fields
                .filter((field) => field.filter_column == 'Y')
                .map((field) => {
                    for (const [key, value] of Object.entries(requestParams)) {
                        if (
                            key.toLowerCase() === field.reference_name.toLowerCase() ||
                            (field.column_name?.length &&
                                key.replace(/\s/g, '_').toLowerCase() ===
                                    field.column_name.replace(/\s/g, '_').toLowerCase()) ||
                            (field.filter_label?.length &&
                                key.replace(/\s/g, '_').toLowerCase() ===
                                    field.filter_label.replace(/\s/g, '_').toLowerCase())
                        ) {
                            field.savedValues = value;
                            break;
                        }
                    }
                });
        }
    }, []);

    const blocksContext = useContext<IBlocksContext | null>(BlocksContext);

    useEffect(() => {
        const newFilterColumns = buildFilterColumns(component.settings, initialData);

        const filterSettings = getFilterSettings(newFilterColumns);
        if (JSON.stringify(filterSettings) != JSON.stringify(lastFilterSettings)) {
            setLastFilterSettings(filterSettings);
            if (lastFilterSettings != null) {
                removeInitialData();
                return;
            }
        }

        let shouldResetData = false;
        if (filterColumns.length != newFilterColumns.length) {
            shouldResetData = true;
        } else {
            filterColumns.forEach((p) => {
                const newFilter = newFilterColumns.find((n) => n.reference_name == p.reference_name);
                if (!newFilter || newFilter.filterType != p.filterType) {
                    shouldResetData = true;
                }
            });
        }

        if (!shouldResetData && blocksContext) {
            const rules = getFilterConditionRules(component, newFilterColumns);
            blocksContext.setFilters(rules);
        }

        let valuesWasChanged = false;
        newFilterColumns.forEach((filter) => {
            if (filter.data == '' || (filter.filterType != 'dropdown' && filter.filterType != 'multiselect')) {
                return;
            }

            const allowedList = Array.isArray(filter.data)
                ? filter.data.map((row: any) => {
                      return row[filter.uid];
                  })
                : [];

            const newValues = filter.savedValues.filter((v) => allowedList.includes(v));
            if (JSON.stringify(newValues) != JSON.stringify(filter.savedValues)) {
                filter.savedValues = newValues;
                valuesWasChanged = true;
            }
        });

        if (valuesWasChanged) {
            const fields = component.settings.fields.slice();
            fields.forEach((f) => {
                const filter = newFilterColumns.find((filter) => filter.uid == f.reference_name_escaped);
                if (filter) {
                    f.savedValues = filter.savedValues;
                }
            });
            updateBlockSettings({ ...component.settings, fields: fields });
        }
        setFilterColumns(newFilterColumns);
    }, [component.settings, initialData]);

    useEffect(() => {
        if (!initialData || !blocksContext) {
            return;
        }
        setTimeout(() => blocksContext.setManageFiltersReady(true), 100);
    }, [initialData]);
    const isVisibleSegment = useEmbeddingContextVisible('segment');

    const handleFilterChange = (filter: IFilterColumn, value: any) => {
        if (!component.settings.fields) {
            return;
        }
        const index = component.settings.fields.findIndex((f) => f.reference_name_escaped == filter.uid);

        if (index == -1) {
            return;
        }

        const fields = component.settings.fields.slice();
        fields[index].savedValues = Array.isArray(value) ? value : [value];
        if (contentSettings.context == 'view') {
            saveFiltersValue(contentSettings.elementId, contentSettings.segmentValueId, component.settings);
        }
        updateBlockSettings({ ...component.settings, fields: fields });
    };
    if (!initialData) {
        return <span>loading...</span>;
    }
    return (
        <Box
            sx={{
                position: 'sticky',
                top: '0',
                zIndex: 100,
            }}
        >
            <Box
                className={'filter-block-scroll-overlap'}
                sx={{
                    position: 'absolute',
                    bottom: '100%',
                    left: 0,
                    right: 0,
                    backgroundColor: 'background.default',
                    height: '32px',
                }}
            />
            <Stack
                justifyContent={'space-between'}
                direction={'row'}
                sx={{
                    backgroundColor: (theme) => useBlendColors(alpha(theme.palette.text.primary, 0.04)),
                    border: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                    borderRadius: 1,
                    p: 0.75,
                }}
            >
                <Box>
                    {contentSettings.hasInstances &&
                        contentSettings.instances.length > 0 &&
                        contentSettings.measurement_time && (
                            <Box sx={{ display: 'inline-flex', m: 0.75 }}>
                                <StaticAddon>Snapshot Date</StaticAddon>
                                <Box sx={{ width: '180px' }}>
                                    <InstanceSelect
                                        instances={contentSettings.instances}
                                        selected={contentSettings.measurement_time}
                                        onChange={(value) => actions.updateMeasurementTime(value)}
                                    />
                                </Box>
                            </Box>
                        )}
                    {contentSettings.segmentValues.segments.length > 0 && isVisibleSegment && (
                        <Box sx={{ width: '264px', display: 'inline-flex', m: 0.75 }}>
                            <StaticAddon sx={{ maxWidth: '110px' }}>
                                <Tooltip
                                    placement="bottom-start"
                                    enterDelay={1000}
                                    title={contentSettings.segmentValues.segments[0].name}
                                >
                                    <span>{contentSettings.segmentValues.segments[0].name} </span>
                                </Tooltip>
                            </StaticAddon>
                            <SegmentValueSelect
                                segment={contentSettings.segmentValues.segments[0]}
                                selected={String(contentSettings.segmentValueId)}
                                onChange={(value) => actions.updateSegmentValue(value)}
                            />
                        </Box>
                    )}
                    {filterColumns
                        .sort((a, b) => a.column_sequence - b.column_sequence)
                        .map((fc) => {
                            let component: any = 'Component not FOUND: ' + fc.filterType;
                            switch (fc.filterType) {
                                case 'multiselect':
                                case 'dropdown':
                                    component = (
                                        <FilterControlSelect
                                            filter={fc}
                                            onChange={(value) => handleFilterChange(fc, value)}
                                        />
                                    );
                                    break;
                                case 'range':
                                    component = (
                                        <FilterControlRange
                                            filter={fc}
                                            onChange={(value) => handleFilterChange(fc, value)}
                                        />
                                    );
                                    break;
                                case 'charting_interval':
                                    component = (
                                        <FilterControlChartingInterval
                                            filter={fc}
                                            onChange={(value) => handleFilterChange(fc, value)}
                                        />
                                    );
                            }
                            return (
                                <Box sx={{ width: '264px', display: 'inline-flex', m: 0.75 }} key={fc.column_name}>
                                    <StaticAddon>{fc.column_name}</StaticAddon>
                                    <Box flexGrow={1}>{component}</Box>
                                </Box>
                            );
                        })}
                </Box>
                {contentSettings.context == 'edit' && (
                    <Box sx={{ m: 0.75 }}>
                        <Button
                            startIcon={<IconMi icon="gear" />}
                            onClick={() => actions.openBlockEditor(String(blockId), editPanel)}
                            data-test={'edit-filters-button'}
                        >
                            {t('manage_filters.edit_filters')}
                        </Button>
                    </Box>
                )}
            </Stack>
        </Box>
    );
}

function InstanceSelect({
    instances,
    selected,
    onChange,
}: {
    instances: Array<ManageFiltersInstance>;
    selected: string;
    onChange: (values: string) => void;
}) {
    const data = instances.map((inst) => ({ label: inst.measurement_time, value: inst.measurement_time_raw }));

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ReactSelect
                data={data}
                update={(value) => {
                    onChange(value);
                }}
                value={selected}
            />
        </Box>
    );
}

function SegmentValueSelect({
    segment,
    selected,
    onChange,
}: {
    segment: ReportFilterSegment;
    selected: string;
    onChange: (values: string) => void;
}) {
    const data = segment.values.map((v) => ({ label: v.segment, value: String(v.segment_value_id) }));

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ReactSelect
                data={data}
                update={(value) => {
                    onChange(value);
                }}
                value={selected}
            />
        </Box>
    );
}
