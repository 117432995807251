import { Popup, PopupSettings } from 'components/common/popup/Popup';
import React, { useEffect, useState } from 'react';
import { Stack, ToggleButtonGroup } from '@mui/material';
import FormToggleButton from 'components/common/form/layout/control/FormToggleButton';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { FormComponentValue } from 'components/common/form/layout/control';
import { templateAPI } from 'api/editor/template';
import { useNavigate } from 'react-router-dom';

const type2template: { [key: string]: string } = {
    'other external content': 'external-content',
    'external report': 'external-report',
    metric: 'metric',
    dataset: 'dataset',
};

export default function CreateTemplatePopup({
    popupOpen,
    setPopupOpen,
    type,
    sourceId,
}: {
    popupOpen: boolean;
    setPopupOpen: (state: boolean) => void;
    type: string;
    sourceId: string;
}) {
    const templateEditorUrl = decodeURIComponent(`editor/template/${type2template[type]}`);

    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [action, setAction] = useState('create');
    const [updateTemplate, setUpdateTemplate] = useState('');
    const [availableTemplates, setAvailableTemplates] = useState<FormComponentValue[]>([]);

    const navigate = useNavigate();

    const createTemplate = () => {
        templateAPI
            .createFromSource(sourceId, type)
            .then((response) => {
                if (response.status === 'OK') {
                    navigate(`/${templateEditorUrl}/${response.data.id}`);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (popupOpen) {
            setIsLoading(true);
            templateAPI
                .getTemplates(sourceId, type)
                .then((response) => {
                    if (response.length > 0) {
                        const values: FormComponentValue[] = response.map((template) => {
                            return {
                                value: template.template_metadata_id,
                                label: template.name,
                            };
                        });

                        setShowCreatePopup(true);
                        setAvailableTemplates(values);
                    } else {
                        createTemplate();
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [popupOpen]);

    if (!popupOpen) {
        return null;
    }

    const onCreateTemplateSubmit = () => {
        setIsLoading(true);
        setShowCreatePopup(false);
        setPopupOpen(false);

        if (action === 'create') {
            createTemplate();
        }
        if (action === 'update') {
            templateAPI
                .updateFromSource(sourceId, type, updateTemplate)
                .then((response) => {
                    if (response.status === 'OK') {
                        navigate(`/${templateEditorUrl}/${response.data.id}`);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const createTemplatePopupSettings: PopupSettings = { title: 'Manage Templates', textOK: 'OK' };

    const onActionChange = (event: any, value: string) => {
        if (value !== null) {
            setAction(value);
        }
    };

    return (
        <>
            {isLoading && <LoadingPlaceholder />}
            {showCreatePopup && (
                <Popup
                    onHide={() => {
                        setShowCreatePopup(false);
                        setPopupOpen(false);
                    }}
                    open={popupOpen}
                    settings={createTemplatePopupSettings}
                    onConfirm={onCreateTemplateSubmit}
                    maxWidth={'popupSm'}
                >
                    <Stack direction={'column'} spacing={2}>
                        <ToggleButtonGroup color="primary" value={action} exclusive onChange={onActionChange}>
                            <FormToggleButton key={'create'} value={'create'}>
                                {'Create'}
                            </FormToggleButton>
                            <FormToggleButton key={'update'} value={'update'}>
                                {'Update'}
                            </FormToggleButton>
                        </ToggleButtonGroup>
                        {action === 'update' && (
                            <ReactSelect
                                data={availableTemplates}
                                value={updateTemplate}
                                update={(newValue) => setUpdateTemplate(newValue)}
                            />
                        )}
                    </Stack>
                </Popup>
            )}
        </>
    );
}
