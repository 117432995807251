interface QBReportFieldProps {
    name: string;
    type: string;
    date?: any;
    expr?: string;
    type_overridden?: string;
}

export interface QBReportFieldData {
    isConstructedDate: boolean;
    isExpression: boolean;
    isUsed: boolean;
    name: string;
    type: string;
    typeOriginal: string;
    typeOverridden: string | null;
    isDimension: boolean;
    typeIsChanged: boolean;
    date: any;
    expr: string;
    setType: (newType: string) => void;
    setAlias: (newAlias: string) => void;
    alias: string | null;
    aliasIsChanged: boolean;
}

export interface QBReportField extends QBReportFieldData {}

export class QBReportField {
    constructor(data: QBReportFieldProps) {
        const type_overridden = data.type_overridden ? data.type_overridden.toUpperCase() : null;
        const type = data.type.toUpperCase();
        const expression = data.expr ?? '';

        this.isConstructedDate = type == 'DATE' && typeof data.date != 'undefined';
        this.isExpression = type == 'EXPR' && typeof data.expr != 'undefined';
        this.isDimension = !this.isConstructedDate && !this.isExpression;
        this.isUsed = false;
        this.name = data.name;
        this.type = type_overridden ?? type;
        this.typeOriginal = type;
        this.typeOverridden = type_overridden;
        this.typeIsChanged = false;
        this.date = this.isConstructedDate ? data.date : {};
        this.expr = this.isExpression ? expression : '';
        this.alias = null;
        this.aliasIsChanged = false;
    }

    setType(newType: string) {
        setQBReportFieldType(this, newType);
    }
    setAlias(newAlias: string) {
        setQBReportFieldAlias(this, newAlias);
    }
}

export const setQBReportFieldType = (field: QBReportFieldData, newType: string) => {
    const type = newType.toUpperCase();

    if (type && type.length && type !== field.typeOriginal && (!field.isExpression || type !== 'DECIMAL')) {
        field.type = type;
        field.typeOverridden = type;
    } else {
        field.type = field.typeOriginal;
    }

    field.typeIsChanged = true;
};

export const setQBReportFieldAlias = function (field: QBReportFieldData, newAlias: string) {
    if (typeof newAlias === 'string' && newAlias.length) {
        if (field.alias !== newAlias) {
            field.alias = newAlias;
            field.aliasIsChanged = true;
        }
    } else if (typeof field.alias === 'string') {
        field.alias = null;
        field.aliasIsChanged = true;
    }
};
