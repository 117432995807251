import { FormComponentValue, FormControlProps, FormElementControlPropsType } from './control';
import { Controller as ReactFormController } from 'react-hook-form';
import buildFormControlProperties from 'components/common/form/buildFormControlProperties';
import React, { Suspense } from 'react';
import { FormDataAPIType, FormElementProps, RawFormElementProps } from 'components/common/form';
import { ComponentKey } from 'components/common/ComponentIndex';
import { AssocArray } from 'tools/types';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { Box } from '@mui/material';
import { FormComponentDependency } from 'components/common/form/dependency';

export function TemplateFormComponent({
    component,
    componentValues,
    elementProps,
    name,
    props = {},
    deps = [],
}: {
    component: ComponentKey;
    componentValues?: Array<FormComponentValue>;
    elementProps: RawFormElementProps | FormDataAPIType;
    name: string;
    props?: AssocArray<any>;
    deps?: Array<FormComponentDependency>;
}) {
    return (
        <Box>
            <ReactHookFormController
                componentValues={componentValues}
                elementProps={prepareFormElementProps({
                    ...elementProps,
                    component: {
                        name: name,
                        component: component,
                        props: props,
                        deps: deps,
                    },
                })}
            />
        </Box>
    );
}

export default function ReactHookFormController({
    componentValues,
    elementProps,
    afterChange,
}: {
    componentValues?: Array<FormComponentValue>;
    elementProps: FormElementProps;
    afterChange?: (event: any) => void;
}) {
    return (
        <Suspense>
            <ReactFormController
                name={elementProps.component.name}
                defaultValue={elementProps.data[elementProps.component.name]}
                control={elementProps.form.hookFormControl}
                render={({ field }) => {
                    let component = <div>ERROR</div>;
                    const controlData = buildFormControlProperties(elementProps, field, componentValues, afterChange);
                    if (controlData.isValid) {
                        component = React.createElement(
                            controlData.componentName as React.FC<FormElementControlPropsType<FormControlProps>>,
                            {
                                controlProps: controlData.controlProps,
                                elementProps: elementProps,
                            }
                        );
                    }
                    return component;
                }}
            />
        </Suspense>
    );
}
