import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { TileInfo, openTileUrl } from 'app/home/index.functions';
import React, { lazy, useEffect, useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import TopPanel from './TopPanel';
import { usePrepareViewerData } from 'components/element-viewer/ElementViewer';
import { FilterType } from 'components/external-reference/Filter';
import { useQuery } from '@tanstack/react-query';
import { AnyElementsViewerDataType, ElementViewerDataResponse, ElementViewerDataType } from 'components/element-viewer';
import { viewerAPI } from 'api/viewer';
const MetricPreview = lazy(() => import('./MetricPreview'));
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DashboardElementType } from 'tools/types';
import { MetricViewerDataType } from 'components/metric/MetricViewer';
import ElementPreviewSummary from 'app/home/tile/preview/ElementPreviewSummary';
import useBundleTranslation from 'i18n';
import useBookmarks, { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';
import { ExternalReportViewerDataType } from 'app/extreport/ExternalReportViewer';
import { useHomeContext } from 'app/home/context';
import { createPortal } from 'react-dom';
import { formatElementDashboardName } from 'tools/tools';
import { escape } from 'lodash';
import { SiblingTabType } from 'app/extreport/viewer/SiblingTabsMenu';

export default function Preview({
    tile,
    onNextPreviewClick,
    hideNextPrevButtons,
}: {
    tile: TileInfo | null;
    onNextPreviewClick: (next: boolean) => void;
    hideNextPrevButtons?: boolean;
}) {
    const { t } = useBundleTranslation(['app/home']);

    const [showPreviewPopup, setShowPreviewPopup] = useState(true);
    const [userChartOverlay, setUserChartOverlay] = useState<number>(0);
    const [filters, setFilters] = useState<Array<FilterType>>([]);
    const ctx = useHomeContext();
    const setTilePreview = ctx.setTilePreview;
    const elementId = Number(tile?.element_id);
    const segmentValueId = Number(tile?.segment_value_id);

    const [searchParams] = useSearchParams();
    const queryKey = `element_preview_${tile?.user_dashboard_element_instance_id}`;
    const { data: viewerRequestData, refetch } = useQuery<ElementViewerDataResponse, Error>([queryKey], () => {
        return viewerAPI.loadElementData(
            elementId,
            Number(tile?.segment_value_id),
            userChartOverlay,
            false,
            true,
            undefined,
            searchParams,
            true
        );
    });

    const { viewerData, viewerExtraData } = usePrepareViewerData(
        userChartOverlay,
        setUserChartOverlay,
        setFilters,
        'preview',
        viewerRequestData
    );

    const onPreviewHide = () => {
        setShowPreviewPopup(false);
        setTilePreview(null);
    };

    const navigate = useNavigate();

    const openElement = (
        type: DashboardElementType,
        elementId: number,
        segmentValueId?: number,
        e?: React.MouseEvent
    ) => {
        if (null != tile) return openTileUrl(tile, navigate, e);
    };

    // TODO:
    const isEmptyInstance: any = 'N';
    const baseScr =
        process.env.REACT_APP_BACKEND_API_BASE_URL +
        `/data/content/index/preview/element/${elementId}/segment/${segmentValueId}?dashboard=Y`; // /dashboard/Y/external/Y

    const [imageUrl, setImageUrl] = useState(
        viewerData?.elementData?.displayFullImage && !('Y' == isEmptyInstance)
            ? baseScr
            : isEmptyInstance == 'Y'
            ? '/img/default_preview_no_data.png?dashboard=Y'
            : baseScr
    );
    const [imageTimestamp, setImageTimestamp] = useState(0);

    return tile && showPreviewPopup && viewerData?.elementData && viewerExtraData ? (
        <Dialog
            fullWidth
            maxWidth={'popupXl'}
            scroll={'body'}
            sx={{
                '.MuiDialog-paper': { overflow: 'visible', marginTop: '100px' },
                '.MuiDialog-container:after': {
                    content: 'none',
                },
            }}
            open={showPreviewPopup}
            onClose={onPreviewHide}
        >
            <DialogTitle id="alert-dialog-title" sx={{ py: '10px' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={1}>
                        {true !== hideNextPrevButtons ? (
                            <>
                                <Button
                                    variant={'light'}
                                    startIcon={<IconMi icon="chevron-left" />}
                                    onClick={() => {
                                        onNextPreviewClick(false);
                                    }}
                                >
                                    {t('previous')}
                                </Button>
                                <Button
                                    variant={'light'}
                                    endIcon={<IconMi icon="chevron-right" />}
                                    onClick={() => {
                                        onNextPreviewClick(true);
                                    }}
                                >
                                    {t('next_preview')}
                                </Button>
                            </>
                        ) : (
                            <Stack>&nbsp;</Stack>
                        )}
                    </Stack>
                    <IconButton
                        aria-label="close"
                        onClick={onPreviewHide}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'primary.main',
                        }}
                    >
                        <IconMi icon="times" fontSize="16" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers sx={{ overflow: 'visible', py: '10px' }}>
                {viewerData &&
                    (viewerData.elementData.row.type == 'external report' ? (
                        <TopPanelWithBookmark
                            filters={filters}
                            tile={tile}
                            viewerExtraData={viewerExtraData as ExternalReportViewerDataType}
                            viewerData={viewerData}
                            openElement={openElement}
                            onBookmarkChange={(timestamp: number) => {
                                setImageTimestamp(timestamp);
                            }}
                        />
                    ) : (
                        <TopPanel
                            viewerData={viewerData}
                            tileName={
                                undefined !== tile.element_dashboard_name_with_formatting &&
                                tile.element_dashboard_name_with_formatting > ''
                                    ? formatElementDashboardName(tile.element_dashboard_name_with_formatting)
                                    : escape(tile.element_dashboard_name ?? '')
                            }
                            openElement={openElement}
                        />
                    ))}
            </DialogContent>
            <DialogContent sx={{ overflow: 'visible' }}>
                <PreviewContent
                    imageUrl={imageUrl + '&t=' + imageTimestamp}
                    refetch={refetch}
                    openElement={openElement}
                    viewerData={viewerData}
                    viewerExtraData={viewerExtraData}
                    segmentValueId={segmentValueId}
                />
            </DialogContent>
        </Dialog>
    ) : (
        <LoadingPlaceholder />
    );
}

function TopPanelWithBookmark({
    viewerExtraData,
    viewerData,
    filters,
    tile,
    openElement,
    onBookmarkChange,
}: {
    viewerExtraData: ExternalReportViewerDataType;
    viewerData: ElementViewerDataType;
    filters: Array<FilterType>;
    tile: TileInfo;
    openElement: (type: DashboardElementType, elementId: number, segmentValueId: number) => void;
    onBookmarkChange: (timestamp: number) => void;
}) {
    const {
        bookmarks,
        APIUpdateBookmark,
        APISaveAsNewBookmark,
        APIDeleteBookmark,
        APIDuplicateBookmark,
        APISortBookmarks,
        APIIsLoading,
    } = useBookmarks(viewerExtraData, viewerData, filters, []);

    const [lastBookmark, setLastBookmark] = useState(bookmarks?.[0] ?? null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (JSON.stringify(lastBookmark) == JSON.stringify(bookmarks?.[0] ?? {})) {
            return;
        }
        setLastBookmark(bookmarks?.[0] ?? null);
    }, [JSON.stringify(bookmarks)]);

    useEffect(() => {
        if (lastBookmark == null) {
            return;
        }
        if (!loaded) {
            setLoaded(true);
            return;
        }
        onBookmarkChange(Date.now());
    }, [lastBookmark]);

    let siblingTabs: Array<SiblingTabType> = [];
    if (viewerData.elementData.row.type == 'external report') {
        siblingTabs = viewerExtraData.tabsData.siblingTabs;
    }

    const bookmarksContext: IBookmarksContext = {
        showIcon: filters.length + siblingTabs.length > 0,
        bookmarks: bookmarks,
        filters: filters,
        siblingTabs: siblingTabs,
        selectedReferenceId: 0,
        APIUpdateBookmark: APIUpdateBookmark,
        APISaveAsNewBookmark: APISaveAsNewBookmark,
        APIDeleteBookmark: APIDeleteBookmark,
        APIDuplicateBookmark: APIDuplicateBookmark,
        APISortBookmarks: APISortBookmarks,
        APIIsLoading: APIIsLoading,
    };

    return (
        <>
            {APIIsLoading &&
                createPortal(
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            zIndex: 90000,
                        }}
                    />,
                    document.body
                )}
            <TopPanel
                bookmarksContext={bookmarksContext}
                viewerData={viewerData}
                tileName={tile.element_dashboard_name ?? ''}
                openElement={openElement}
            />
        </>
    );
}

export function PreviewContent({
    viewerData,
    viewerExtraData,
    segmentValueId,
    openElement,
    refetch,
    imageUrl,
}: {
    viewerData: ElementViewerDataType;
    viewerExtraData: AnyElementsViewerDataType;
    segmentValueId: number;
    openElement: (type: DashboardElementType, elementId: number, segmentValueId?: number) => void;
    refetch: () => void;
    imageUrl: string;
}) {
    const { t } = useBundleTranslation(['components/common/element']);

    const [imageLoaded, setImageLoaded] = useState(false);

    const viewerProps = {
        related: [],
        filters: [],
        onFiltersChange: () => {},
        onSegmentValueChange: () => {},
        segmentValueId: segmentValueId,
        onFavoritesChange: () => {},
        onRelatedElementSelection: () => {},
        onFilterChange: () => {},
        refetch: refetch,
    };

    const elementId = viewerData.elementData.row.elementId;
    const handleClick = () => {
        openElement(viewerData.elementData.row.type, elementId, segmentValueId);
    };

    const onLoad = () => {
        setImageLoaded(true);
    };

    const summaryComponent = (
        <ElementPreviewSummary
            elementInfo={viewerData?.elementData}
            viewerRequestExtraData={viewerExtraData}
            onToggleVisibility={() => {}}
            isVisible
            isPreview
        />
    );

    if (['metric', 'multi-metric chart'].includes(viewerData.elementData.row.type)) {
        return (
            <Stack direction="row" sx={{ overflow: 'hidden' }}>
                <MetricPreview
                    openElement={openElement}
                    elementInfo={viewerData?.elementData}
                    viewerRequestExtraData={viewerExtraData as MetricViewerDataType}
                    viewerRequestData={viewerData}
                    {...viewerProps}
                />
                {summaryComponent}
            </Stack>
        );
    }

    if (
        viewerData.elementData.row.type == 'external report' &&
        undefined !== viewerData.elementData?.noToken &&
        true === viewerData.elementData?.noToken
    ) {
        openElement(viewerData.elementData.row.type, elementId, segmentValueId);
        return null;
    }

    if (
        viewerData.elementData.row.type == 'external report' ||
        viewerData.elementData.row.type == 'other external content'
    ) {
        return (
            <Stack direction="row" sx={{ overflow: 'hidden' }}>
                <Box sx={{ maxWidth: '750px' }} flexGrow={1}>
                    <span onClick={handleClick}>
                        <img style={{ maxWidth: '750px' }} id="preview_image_el" src={imageUrl} onLoad={onLoad} />
                    </span>
                    {viewerData.elementData.row.type == 'external report' &&
                        !viewerData.elementData.row.externalReportAutoUpdateImageInd &&
                        viewerData.elementData.availablePreview && (
                            <div className="dashboardPreviewSample">label_sample</div>
                        )}
                    {!imageLoaded && viewerData.elementData.row.type == 'external report' && (
                        <Stack justifyContent={'center'} alignItems={'center'} spacing={2} sx={{ minHeight: '200px' }}>
                            <CircularProgress size={24} />
                            <Typography>{t('preview_preparing')}</Typography>
                            <Button variant={'text'} sx={{ width: '30%' }} onClick={handleClick}>
                                {t('skip_preview')}
                            </Button>
                        </Stack>
                    )}
                </Box>

                {summaryComponent}
            </Stack>
        );
    }

    if (viewerData.elementData.row.type == 'internal report') {
        return (
            <Stack direction="row" sx={{ overflow: 'hidden' }}>
                <Stack direction="column">
                    <Box flexGrow={1}>
                        <span>
                            <iframe
                                style={{ border: 'none', overflow: 'hidden', width: 750, height: 516 }}
                                src={`/report/${elementId}/segment/${segmentValueId}/preview?width=750&height=500&view_type=tile`}
                            />
                        </span>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Button variant="light" startIcon={<IconMi icon="view" />} onClick={handleClick}>
                            {t('view_full_report')}
                        </Button>
                    </Box>
                </Stack>
                {summaryComponent}
            </Stack>
        );
    }

    return null;
}
