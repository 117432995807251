import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from 'tools/tools';
import Highcharts from 'highcharts/highstock';

export function escapeHtml(unsafe: string | undefined) {
    if (typeof unsafe == 'undefined') {
        return '';
    }

    return String(unsafe)
        .replaceAll('&', '&amp;')
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        .replaceAll('"', '&quot;')
        .replaceAll("'", '&#039;');
}

export function parseDate(value: string | null) {
    if (null == value) return new Date();

    const match = value.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
    if (!match) {
        return new Date();
    }
    //@ts-ignore
    const r: Array<number> = match;
    r.shift(); // discard the "full match" index
    return new Date(Date.parse(`${r[0]}-${r[1]}-${r[2]}T${r[3]}:${r[4]}:${r[5]}+0000`));
}

export function applyChartOptionsForThumbnail(options: Highcharts.Options) {
    options.chart = {
        ...options.chart,
        ...{
            width: THUMBNAIL_WIDTH,
            height: THUMBNAIL_HEIGHT,
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
        },
    };
    return options;
}

export function getFontSizeStyle(settings: { mainFont?: string; mainFontSize?: number }) {
    const fontMap: any = {
        'font-arial': 'Arial, Helvetica, sans-serif !important;',
        'font-georgia': 'Georgia, serif;',
        'font-tahoma': 'Tahoma, Geneva, sans-serif;',
        'font-times': '"Times New Roman", Times, serif;',
        'font-verdana': 'Verdana, Geneva, sans-serif;',
    };
    const fontClass = settings.mainFont ?? 'font-arial';

    return {
        fontFamily: fontMap[fontClass] ?? fontMap['font-arial'],
        fontSize: (settings.mainFontSize ?? 12) + 'px',
    };
}
