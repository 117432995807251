import { IconButton, Grid, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import FormComponent from 'components/common/form/Form';
import { useQueryClient } from '@tanstack/react-query';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';

export default function EditCell({ href, reloadGridData, text, popupConfig, icon, t, dataTestCell }: CustomCellProps) {
    if (undefined === t) t = useBundleTranslation('main')['t'];

    const [editFormHref, setEditFormHref] = useState<string>('');
    const queryClient = useQueryClient();
    const formUid = 'edit-cell-query-' + (href ?? '');

    const handleOpenEditForm = () => {
        queryClient.resetQueries([formUid]);
        setEditFormHref(href ?? '');
    };
    const handleCloseEditForm = () => {
        setEditFormHref('');
    };

    const editPopupConfig = popupConfig ?? { title: text };

    return (
        <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title={t('edit')}>
                <IconButton
                    aria-label="edit"
                    size={'small'}
                    onClick={handleOpenEditForm}
                    sx={{ color: 'text.primary' }}
                    data-test={`${dataTestCell}`}
                >
                    <IconMi icon={icon ? icon.value : 'edit-pencil'} fontSize={'16'} />
                </IconButton>
            </Tooltip>
            {editFormHref && (
                <FormComponent
                    isPopup={true}
                    popupType={'edit'}
                    pk={`draw_form_pk`}
                    uid={formUid}
                    settingsUrl={editFormHref}
                    onHide={handleCloseEditForm}
                    popupSettings={editPopupConfig}
                    afterSave={(response: any) => {
                        if (reloadGridData) {
                            reloadGridData();
                        }
                    }}
                />
            )}
        </Grid>
    );
}
