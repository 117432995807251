import React, { useContext, useLayoutEffect, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import useBundleTranslation from 'i18n';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Box, Button, Divider, FormLabel, IconButton, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import IconMi from 'components/common/icon/IconMi';
import { FormDataAPIType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { AssocArray } from 'tools/types';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentSettingsTextBlock = ReportContentNS.ComponentSettingsTextBlock;
import MeasureFieldsList from 'components/report-content/components/text-block/edit-panel/MeasureFieldsList';
import fieldToTextBlockMeasuredField = ReportContentNS.fieldToTextBlockMeasuredField;
import getFirstAllowedFieldAsMeasure = ReportContentNS.getFirstAllowedFieldAsMeasure;
import { addToStateArray, removeFromStateArray, replaceInStateArray, uid } from 'tools/tools';
import ComponentSettingsTextBlockFormulaField = ReportContentNS.ComponentSettingsTextBlockFormulaField;
import FormulaForm from 'components/report-content/components/text-block/edit-panel/FormulaForm';
import ComponentSettingsTextBlockMeasureField = ReportContentNS.ComponentSettingsTextBlockMeasureField;
import DatasetField = ReportContentNS.DatasetField;
import MeasureForm from 'components/report-content/components/text-block/edit-panel/MeasureForm';
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsTextBlock> = ['title', 'show_in_viewer', 'include_in_email'];
const componentNumericFormField: Array<keyof ComponentSettingsTextBlock> = ['height'];

function useFormulas(defaultFormulaFields: Array<ComponentSettingsTextBlockFormulaField>) {
    // Formula Fields
    const [formulaFields, setFormulaFields] = useState(defaultFormulaFields);
    const handleRemove = (name: string) => {
        removeFromStateArray(setFormulaFields, (f) => f.name == name);
    };

    const [selectedForEdit, setSelectedForEdit] = useState<ComponentSettingsTextBlockFormulaField | false>(false);

    const handleAdd = () => {
        addToStateArray(setFormulaFields, {
            name: '',
            display_mask_id: '',
            expression: '',
            value_type: 'numeric',
            column_name: '',
            formattingRules: [],
            reference_name: uid(),
        });
    };

    useLayoutEffect(() => {
        const field = formulaFields.find((f) => f.name == '');
        if (field) {
            setSelectedForEdit(field);
        }
    }, [formulaFields]);

    const handleApply = (formula: ComponentSettingsTextBlockFormulaField) => {
        replaceInStateArray(
            setFormulaFields,
            (f) => f.reference_name == formula.reference_name,
            structuredClone(formula)
        );
    };

    return {
        formulaFields,
        selectedForEdit,
        setSelectedForEdit,
        handleAdd,
        handleRemove,
        handleApply,
    };
}

function useMeasureFields(
    defaultMeasureFields: Array<ComponentSettingsTextBlockMeasureField>,
    datasetFields: Array<DatasetField>
) {
    const [fields, setFields] = useState(defaultMeasureFields);

    const [selectedForEdit, setSelectedForEdit] = useState<ComponentSettingsTextBlockMeasureField | false>(false);

    const handleAdd = () => {
        const measureField = getFirstAllowedFieldAsMeasure(datasetFields, fields, fieldToTextBlockMeasuredField);
        if (measureField) {
            addToStateArray(setFields, measureField);
        }
    };

    const handleApply = (measureField: ComponentSettingsTextBlockMeasureField) => {
        replaceInStateArray(
            setFields,
            (f) => f.reference_name == measureField.reference_name,
            structuredClone(measureField)
        );
    };

    return { fields, setFields, handleAdd, handleApply, selectedForEdit, setSelectedForEdit };
}

export default function TextBlockEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsTextBlock> & { block: BlockType<ComponentSettingsTextBlock> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const datasetFields = editPanelContext?.datasetFields ?? [];

    // Prepare data for Form
    const defaultState: AssocArray<any> = {};

    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process measure fields settings
        const newSettings: ComponentSettingsTextBlock = {
            ...component.settings,
            // measureFields: [measuredField],
        } as ComponentSettingsTextBlock;
        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        const resultSettings = {
            ...newSettings,
            measureFields: measures.fields.slice(),
            formulaFields: formulas.formulaFields.slice(),
        } as ComponentSettingsTextBlock;

        editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
    };

    const measures = useMeasureFields(component.settings.measureFields, datasetFields);
    const formulas = useFormulas(component.settings.formulaFields);
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <>
            {!formulas.selectedForEdit && !measures.selectedForEdit && (
                <Stack height={1}>
                    {/*Main Panel*/}
                    <Box flexShrink={0}>
                        <BlockEditPanelHeader
                            title={t('text_block.edit_title')}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                    <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                        <Box>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: { component: 'FormText', name: 'title', label: t('title') },
                                })}
                            />
                        </Box>
                        <Divider />
                        <Box>
                            <MeasureFieldsList
                                onSelectField={measures.setSelectedForEdit}
                                fields={measures.fields}
                                setFields={measures.setFields}
                                datasetFields={datasetFields}
                            />
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    onClick={() => measures.handleAdd()}
                                    variant={'light'}
                                    startIcon={<IconMi icon={'new'} />}
                                >
                                    {t('text_block.variable')}
                                </Button>
                            </Box>
                        </Box>
                        <Divider />
                        <Box>
                            <Box>
                                <FormLabel>{t('text_block.formulas')}</FormLabel>
                                {formulas.formulaFields.length > 0 && (
                                    <Stack direction={'column'} spacing={1}>
                                        {formulas.formulaFields.map((f) => (
                                            <Box key={f.name}>
                                                {f.name}
                                                <IconButton onClick={() => formulas.setSelectedForEdit(f)}>
                                                    <IconMi icon="gear" fontSize="16" />
                                                </IconButton>
                                                <IconButton onClick={() => formulas.handleRemove(f.name)}>
                                                    <IconMi icon="times" fontSize="16" />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </Stack>
                                )}
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    onClick={() => formulas.handleAdd()}
                                    variant={'light'}
                                    startIcon={<IconMi icon={'new'} />}
                                >
                                    {t('text_block.formula')}
                                </Button>
                            </Box>
                        </Box>
                        <Divider />
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                            <Box sx={{ width: '72px' }}>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        inputFilter: 'int',
                                        component: { component: 'FormText', name: 'height', label: '' },
                                    })}
                                />
                            </Box>
                        </Stack>
                        <Divider />
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'show_in_viewer'}
                                label={t('show_in_viewer')}
                            />
                        </Box>
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'include_in_email'}
                                label={t('include_in_email')}
                            />
                        </Box>
                        <Box>
                            <GetEmbedCode
                                contentSettings={contentSettings}
                                height={actualHeight}
                                blockUID={block.uid}
                                t={t}
                            />
                        </Box>
                        <Divider />
                    </Stack>
                    <Box flexShrink={0}>
                        <BlockEditPanelControls
                            onApply={handleApply}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                </Stack>
            )}
            {formulas.selectedForEdit && (
                <FormulaForm
                    settings={component.settings}
                    formula={formulas.selectedForEdit}
                    formulasList={formulas.formulaFields}
                    measuresList={measures.fields}
                    contentSettings={contentSettings}
                    title={''}
                    onApply={formulas.handleApply}
                    onClose={(isCancel) => {
                        formulas.setSelectedForEdit(false);
                        if (isCancel) {
                            formulas.handleRemove('');
                        }
                    }}
                />
            )}
            {measures.selectedForEdit && (
                <MeasureForm
                    measure={measures.selectedForEdit}
                    title={''}
                    onApply={measures.handleApply}
                    onClose={() => {
                        measures.setSelectedForEdit(false);
                    }}
                />
            )}
        </>
    );
}
