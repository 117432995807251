import { ElementType } from 'components/element-viewer/index';
import useBundleTranslation from 'i18n';
import { Box, Chip, Stack, Tooltip } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import DropdownIcon from 'components/element-viewer/controls-panel-icon/DropdownIcon';
import EmailLine from 'components/element-viewer/common/EmailLine';
import IconMi from 'components/common/icon/IconMi';
import ElementTopicSectionIcon from 'components/common/icon/ElementTopicSectionIcon';
import { ElementTopicSection, Topic, useTopicsLink } from 'components/element-viewer/element-topics';
import GlossaryTermPopup from 'components/glossary-term-popup';
import styles from './InfoIcon.styles';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import { wallFormatDateTime } from 'components/wall';
import { ExternalReportViewerDataType } from 'app/extreport/ExternalReportViewer';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import isOwnerVisible from 'tools/owners';

export default function InfoIcon({
    element,
    viewerRequestExtraData,
}: {
    element: ElementType;
    viewerRequestExtraData?: ExternalReportViewerDataType;
}) {
    const [isOpenState, setIsOpenState] = useState<boolean>(false);
    const { configuration }: UserAuth = useAppSelector((state) => state.auth);

    const showOwners = !useConfigConstantValue('HIDE_OWNER_FIELDS');
    const [collapseTermSectionList, setCollapseTermSectionList] = useState<any>({});
    const [infoBtnBottomCoordinate, setInfoBtnBottomCoordinate] = useState<number>(0);
    const [descriptionCut, setDescriptionCut] = useState<boolean>(false);
    const [descriptionExpand, setDescriptionExpand] = useState<boolean>(false);
    const descriptionContentRef = React.useRef<HTMLDivElement>(null);
    const descriptionExpandtRef = React.useRef<HTMLDivElement>(null);

    const { t } = useBundleTranslation(['components/common/element']);
    // TODO: magic color constant?
    const certificationColor = element.certification?.color ? element.certification.color : 'success.main';

    const { handleTopicClick, showTermsPopup, selectedTopicId, topicIds, setShowTermsPopup } = useTopicsLink(
        Object.values(element.topics).reduce(
            (acc: Array<Topic>, section: ElementTopicSection) => acc.concat(section.topics),
            []
        )
    );

    const categoryName = element.info.categoryName.length > 0 ? element.info.categoryName : t('uncategorized');

    let lastGenerationTime: false | string = false;
    if (
        element.row.type == 'external report' &&
        element.row.lastDisplayGenerationTime &&
        element.row.lastDisplayGenerationTime != '0000-00-00 00:00:00'
    ) {
        lastGenerationTime = wallFormatDateTime(element.row.lastDisplayGenerationTime);
    }
    let dataSource: false | string = false;
    if (viewerRequestExtraData?.additionalData?.dataSourceName) {
        dataSource = viewerRequestExtraData.additionalData.dataSourceName;
    }

    const tagsSectionKeys = Object.keys(element.topics).filter((key) => key == 'default-topic-type');
    const termsSectionKeys = Object.keys(element.topics).filter((key) => key != 'default-topic-type');
    const termsSectionsRef = React.useRef<HTMLDivElement>(null);
    const infoIconRef = React.useRef<HTMLDivElement>(null);

    const toggleCollapseTermSection = (sectionKey: string) => {
        const newCollapseSectionsList = { ...collapseTermSectionList };
        newCollapseSectionsList[sectionKey] = !newCollapseSectionsList?.[sectionKey];
        setCollapseTermSectionList(newCollapseSectionsList);
    };

    const defineInfoHeight = () => {
        if (infoIconRef.current) {
            const infoIconRect = infoIconRef.current.getBoundingClientRect();
            setInfoBtnBottomCoordinate(infoIconRect.bottom + 15);
        }
    };

    const maxDescContainerHeight = 176; //11 rows (1row = 16px)

    useEffect(() => {
        defineInfoHeight();
        window.addEventListener('resize', defineInfoHeight);

        return () => {
            window.removeEventListener('resize', defineInfoHeight);
        };
    }, []);

    useEffect(() => {
        if (isOpenState) {
            if (descriptionContentRef.current) {
                setDescriptionCut(descriptionContentRef.current.clientHeight > maxDescContainerHeight);
            }
        }
    }, [isOpenState]);

    return (
        <span data-test={'top-info-icon'} ref={infoIconRef}>
            <DropdownIcon
                tooltip={t('info')}
                icon="info-circle"
                popperSx={{ maxHeight: `calc(100% - ${infoBtnBottomCoordinate}px)`, display: 'flex' }}
                onChangeState={(isOpen) => {
                    setIsOpenState(isOpen);
                }}
                onClickInside={(event, handleClose) => {
                    if (
                        descriptionExpandtRef.current &&
                        descriptionExpandtRef.current.contains(event.target as HTMLElement)
                    ) {
                        return;
                    } else if (
                        termsSectionsRef.current &&
                        termsSectionsRef.current.contains(event.target as HTMLElement)
                    ) {
                        if (event.target.closest('.term-item')) {
                            handleClose(event);
                        } else {
                            return;
                        }
                    } else {
                        handleClose(event);
                    }
                }}
            >
                <Stack sx={styles.tooltipContainer}>
                    {element.info.certified && (
                        <Stack direction="row" sx={styles.sectionWrapper}>
                            <Box sx={styles.iconHolder}>
                                <IconMi icon="certified" sx={{ fontSize: '16px', color: certificationColor }} />
                            </Box>
                            <Box sx={styles.breakWordValue}>
                                <Box component="span" sx={{ color: certificationColor, fontWeight: 600 }}>
                                    {element.certification?.name ? element.certification.name : ''}{' '}
                                    {t('info_icon.certified')}
                                </Box>
                                <Box component="span">{t('info_icon.certified_by')}</Box>{' '}
                                <Box component="span" sx={{ color: 'primary.main' }}>
                                    {element.info.certifiedByDn}
                                </Box>{' '}
                                {t('info_icon.certified_on', { date: element.info.certifiedDate })}
                            </Box>
                        </Stack>
                    )}
                    {element.row.description.trim().length > 0 && (
                        <Stack direction="row" sx={styles.sectionWrapper}>
                            <Box sx={styles.iconHolder} />
                            <Box sx={styles.descriptionItem}>
                                <Box
                                    sx={{
                                        ...styles.descriptionHolder,
                                        maxHeight:
                                            descriptionCut && !descriptionExpand
                                                ? `${maxDescContainerHeight}px`
                                                : 'none',
                                    }}
                                >
                                    <Box ref={descriptionContentRef}>
                                        {element.row.descriptionMarkdownInd ? (
                                            <span
                                                className={'markdown-holder'}
                                                dangerouslySetInnerHTML={{ __html: element.row.descriptionMarkdown }}
                                            />
                                        ) : (
                                            element.row.description
                                        )}
                                    </Box>
                                </Box>
                                {descriptionCut && (
                                    <Box
                                        ref={descriptionExpandtRef}
                                        onClick={() => {
                                            setDescriptionExpand(!descriptionExpand);
                                        }}
                                        sx={styles.descriptionExpandControl}
                                    >
                                        {descriptionExpand ? t('description_show_less') : t('description_show_more')}
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    )}
                    {tagsSectionKeys.map((sectionKey, i) => {
                        const section = element.topics[sectionKey];
                        return (
                            <Fragment key={i}>
                                <Stack direction="row" sx={styles.sectionWrapper}>
                                    <Box sx={styles.iconHolder}>
                                        <ElementTopicSectionIcon
                                            section={section}
                                            styles={{ icon: { fontSize: '16px' } }}
                                        />
                                    </Box>
                                    <Stack direction="row" flexWrap={'wrap'} sx={{ mt: -0.5, ml: -0.5 }}>
                                        {section.topics.map((topic) => {
                                            const needToCutLabel = topic.name.length > 30;
                                            const label = needToCutLabel
                                                ? topic.name.substring(0, 30) + '...'
                                                : topic.name;
                                            return (
                                                <Box sx={{ mt: 0.5, ml: 0.5 }} key={topic.topicId}>
                                                    <Tooltip
                                                        title={
                                                            isOpenState ? (
                                                                <Box>
                                                                    {needToCutLabel ? (
                                                                        <Box sx={{ pb: 1 }}>{topic.name}</Box>
                                                                    ) : null}
                                                                    {t('search_tags_tooltip')}
                                                                </Box>
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    >
                                                        <Chip
                                                            onClick={() =>
                                                                handleTopicClick(section.isDefault, topic.topicId)
                                                            }
                                                            label={label}
                                                            color="primary"
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            );
                                        })}
                                    </Stack>
                                </Stack>
                            </Fragment>
                        );
                    })}

                    {termsSectionKeys.length > 0 && (
                        <Stack sx={styles.sectionWrapper} width={1} spacing={0.5} ref={termsSectionsRef}>
                            {termsSectionKeys.map((sectionKey, i) => {
                                const section = element.topics[sectionKey];
                                return (
                                    <Box
                                        key={i}
                                        width={1}
                                        sx={styles.termSection}
                                        className={collapseTermSectionList?.[sectionKey] ? 'collapse' : 'expand'}
                                    >
                                        <Stack
                                            direction="row"
                                            className={'term-section__header'}
                                            onClick={() => {
                                                toggleCollapseTermSection(sectionKey);
                                            }}
                                        >
                                            <Box sx={styles.iconHolder}>
                                                <ElementTopicSectionIcon
                                                    section={section}
                                                    styles={{ icon: { fontSize: '14px' } }}
                                                />
                                            </Box>

                                            <Box flexGrow={1}>{section.topicTypeName}</Box>
                                            <Box sx={{ ...styles.iconHolder, ...{ mr: 0, ml: 1 } }}>
                                                <IconMi
                                                    icon={
                                                        collapseTermSectionList?.[sectionKey]
                                                            ? 'chevron-right'
                                                            : 'chevron-down'
                                                    }
                                                    sx={{ fontSize: 16 }}
                                                />
                                            </Box>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            flexWrap={'wrap'}
                                            sx={{ ml: -0.5, pl: '24px' }}
                                            className={'term-section__items'}
                                        >
                                            {section.topics.map((topic) => {
                                                const needToCutLabel = topic.name.length > 30;
                                                const label = needToCutLabel
                                                    ? topic.name.substring(0, 30) + '...'
                                                    : topic.name;
                                                return (
                                                    <Box sx={{ mt: 0.5, ml: 0.5 }} key={topic.topicId}>
                                                        <Tooltip
                                                            title={
                                                                isOpenState ? (
                                                                    <Box>
                                                                        {needToCutLabel ? (
                                                                            <Box sx={{ pb: 1 }}>{topic.name}</Box>
                                                                        ) : null}
                                                                        {t('search_tags_tooltip')}
                                                                    </Box>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <Chip
                                                                onClick={() =>
                                                                    handleTopicClick(section.isDefault, topic.topicId)
                                                                }
                                                                className={'term-item'}
                                                                label={label}
                                                                color="primary"
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                );
                                            })}
                                        </Stack>
                                    </Box>
                                );
                            })}
                        </Stack>
                    )}

                    <Stack sx={styles.sectionWrapper} spacing={0.25}>
                        {showOwners &&
                            (isOwnerVisible(configuration.owners.business_owner, 'elements') ||
                                isOwnerVisible(configuration.owners.technical_owner, 'elements') ||
                                isOwnerVisible(configuration.owners.data_steward, 'elements')) &&
                            (element.info.businessOwnerDn ||
                                element.info.technicalOwnerDn ||
                                element.info.dataStewardDn) && (
                                <Stack direction="row" width={1}>
                                    <Box sx={styles.iconHolder}>
                                        <IconMi icon="user" sx={{ fontSize: '16px' }} />
                                    </Box>
                                    <Stack spacing={0.25} sx={{ overflow: 'hidden' }}>
                                        {isOwnerVisible(configuration.owners.business_owner, 'elements') && (
                                            <Stack direction={'row'} sx={styles.fixedValueRow}>
                                                <EmailLine
                                                    label={configuration.owners.business_owner.label}
                                                    email={element.info.businessOwnerEmail}
                                                    ownerName={element.info.businessOwnerDn}
                                                    elementName={element.row.name}
                                                />
                                            </Stack>
                                        )}
                                        {isOwnerVisible(configuration.owners.technical_owner, 'elements') && (
                                            <Stack direction={'row'} sx={styles.fixedValueRow}>
                                                <EmailLine
                                                    label={configuration.owners.technical_owner.label}
                                                    email={element.info.technicalOwnerEmail}
                                                    ownerName={element.info.technicalOwnerDn}
                                                    elementName={element.row.name}
                                                />
                                            </Stack>
                                        )}
                                        {isOwnerVisible(configuration.owners.data_steward, 'elements') && (
                                            <Stack direction={'row'} sx={styles.fixedValueRow}>
                                                <EmailLine
                                                    label={configuration.owners.data_steward.label}
                                                    email={element.info.dataStewardEmail}
                                                    ownerName={element.info.dataStewardDn}
                                                    elementName={element.row.name}
                                                />
                                            </Stack>
                                        )}
                                    </Stack>
                                </Stack>
                            )}
                        {dataSource && (
                            <Stack direction="row">
                                <Box sx={styles.iconHolder} />
                                <Stack direction={'row'} sx={styles.fixedValueRow}>
                                    <Box className={'fixed-label'}>{t('info_icon.data_source')}</Box>
                                    <Box className={'fixed-value'}>{dataSource}</Box>
                                </Stack>
                            </Stack>
                        )}
                        <Stack direction="row">
                            <Box sx={styles.iconHolder}>
                                <IconMi icon="categories" sx={{ fontSize: '16px' }} />
                            </Box>
                            <Stack direction={'row'} sx={styles.fixedValueRow}>
                                <Box className={'fixed-label'}>{t('info_icon.category')}</Box>
                                <Box className={'fixed-value'}>{categoryName}</Box>
                            </Stack>
                        </Stack>
                        {element.info.engagement && (
                            <Stack direction="row">
                                <Box sx={styles.iconHolder}>
                                    <IconMi icon="engagement" sx={{ fontSize: '16px' }} />
                                </Box>
                                <Stack direction={'row'} sx={styles.fixedValueRow}>
                                    <Box className={'fixed-label'}>{t('info_icon.engagement')}</Box>
                                    <Box className={'fixed-value'}>{element.info.engagement}</Box>
                                </Stack>
                            </Stack>
                        )}
                        {lastGenerationTime && (
                            <Stack direction="row">
                                <Box sx={styles.iconHolder} />
                                <Stack direction={'row'} sx={styles.fixedValueRow}>
                                    <Box className={'fixed-label'}>{t('info_icon.image_last_taken')}</Box>
                                    <Box className={'fixed-value'}>{lastGenerationTime}</Box>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>

                    {element.info.customFields && element.info.customFields.length > 0 && (
                        <Stack direction={'row'} sx={styles.sectionWrapper}>
                            <Box sx={styles.iconHolder}>
                                <IconMi icon="custom-field" sx={{ fontSize: '16px' }} />
                            </Box>
                            <Stack flexGrow={1} spacing={0.5} sx={{ overflow: 'hidden' }}>
                                {element.info.customFields.map((section) => (
                                    <Box key={section.cfs_id}>
                                        <Box sx={{ fontWeight: 'bold' }}>{section.label}</Box>

                                        <Stack spacing={0.25}>
                                            {section.fields.map((field) => {
                                                const value = Object.values(field.values).join(', ');
                                                const el = field?.link?.startsWith('http') ? (
                                                    <a target={'_blank'} href={field.link}>
                                                        {value}
                                                    </a>
                                                ) : (
                                                    value
                                                );
                                                return (
                                                    <Stack key={field.id} direction={'row'} sx={styles.fixedValueRow}>
                                                        <Box className={'fixed-label'}>{field.name}</Box>
                                                        <Box
                                                            className={'fixed-value markdown-holder'}
                                                            sx={{ overflow: 'hidden' }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: el,
                                                            }}
                                                        />
                                                    </Stack>
                                                );
                                            })}
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </DropdownIcon>
            {showTermsPopup && (
                <GlossaryTermPopup
                    setShowPopup={setShowTermsPopup}
                    showPopup={showTermsPopup}
                    topicIds={topicIds}
                    topicId={selectedTopicId}
                />
            )}
        </span>
    );
}
