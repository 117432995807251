import React, { useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { IImportResultData } from 'app/content/import';
import ImportResultPopup from 'app/content/import/ImportResultPopup';
import { formAPI } from 'api/form';
import { useQueryClient } from '@tanstack/react-query';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { createPortal } from 'react-dom';
import { ActSubmit } from 'components/common/form/act';

export default function ImportForm(props: IFormLayoutProps) {
    const queryClient = useQueryClient();

    const [showResults, setShowResults] = useState(false);
    const [resultsData, setResultsData] = useState<IImportResultData | null>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (data: any) => {
        const afterSubmit = (response: any) => {
            setShowResults(true);
            setResultsData(response.data.data);
        };

        setLoading(true);

        return formAPI
            .saveFormData('data/admin/content/import/form', data, props.hookForm.form)
            .then((response) => afterSubmit(response))
            .catch((error) => {
                alert(error);
                closeFormPopup();
            })
            .finally(() => setLoading(false));
    };

    const closeFormPopup = () => {
        queryClient.invalidateQueries(['ImportExportGrid']);
        props.asPopupProps.onHide();
    };

    const onPopupHide = () => {
        setShowResults(false);
        setResultsData(null);
        closeFormPopup();
    };

    const onImportAgain = () => {
        setShowResults(false);
        setResultsData(null);
    };

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                return handleSubmit(data);
            }
        }
    );

    return (
        <>
            <FormLayoutComposer props={props} />
            {showResults && (
                <ImportResultPopup
                    importResultData={resultsData}
                    onHide={onPopupHide}
                    popupOpen={showResults}
                    onImportAgain={onImportAgain}
                />
            )}
            {loading &&
                createPortal(
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            zIndex: (theme) => 9999,
                        }}
                    />,
                    document.body
                )}
        </>
    );
}
