import React, { useEffect, useRef } from 'react';
import { Params as UrlParams } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { FormPanelButtonProps } from 'components/common/form';
import useBundleTranslation from 'i18n';
import { processSettingsUrl } from 'components/common/form/formTools';
import IconMi from 'components/common/icon/IconMi';
import { useFormState } from 'react-hook-form';
import { openInNewTab } from 'tools/tools';
import { instance } from 'api/api';
import FormPanelButtonCollectData from 'components/common/form/data-fetch-command/top-panel/FormPanelButtonCollectData';

export default function FormPanelMetricViewButton(props: FormPanelButtonProps<any>) {
    const { button, formActions } = props;
    const { t } = useBundleTranslation(button.translationNS ?? 'components/common/form/form');
    const navigate = useNavigate();
    const urlParams: UrlParams = useParams();

    const isManual = formActions.hookFormGetValues('data_source') == 'manual';

    const afterFormSubmit = () => {
        if (button.props.redirectTo) {
            const redirectTo = processSettingsUrl(button.props.redirectTo, Object.keys(urlParams), urlParams);
            if (button.props.redirectNewTab) {
                openInNewTab(redirectTo);
            } else {
                setTimeout(() => navigate(redirectTo), 100);
            }
        }
    };

    const { isDirty } = useFormState({ control: formActions.hookFormControl });
    const isDirtyRef = useRef(isDirty);
    useEffect(() => {
        isDirtyRef.current = isDirty;
    }, [isDirty]);

    const handleClick = async () => {
        if (isNew && formActions.hookFormGetValues('enabled_ind') == 'N') {
            formActions.hookFormSetValue('enabled_ind', 'Y', { shouldDirty: true });
            setTimeout(() => handleClick(), 200);
            return;
        }

        if (formActions.hookFormGetValues('aggregate_if_missing_measurements_ind') == 'N') {
            const response = await instance
                .get(`data/metric/${formActions.hookFormGetValues('element_id')}/check-files`)
                .then((response) => {
                    return response.data;
                });

            if (response.status != 'OK') {
                alert(
                    t('metric_no_chart_data', {
                        name: formActions.hookFormGetValues(button.props.fieldName ?? ''),
                    })
                );
                formActions.hookFormSetValue('metric_data_empty', 'Y', { shouldDirty: false });
                return;
            }
        }

        if (isDirtyRef.current) {
            formActions.formSave().then((response) => {
                if (response?.data?.status != 'OK') {
                    return;
                }
                afterFormSubmit();
            });
        } else {
            afterFormSubmit();
        }
    };

    const manualMetricEnable = () => {
        const save = () => {
            formActions.formSave().then((response) => {
                if (response?.data?.status != 'OK') {
                    return;
                }
                const id = formActions.hookFormGetValues('element_id');
                navigate(`/editor/element/metric/${id}/manual#data`);
            });
        };

        if (formActions.hookFormGetValues('enabled_ind') == 'N') {
            formActions.hookFormSetValue('enabled_ind', 'Y', { shouldDirty: true });
            setTimeout(() => save(), 200);
            return;
        } else {
            save();
        }
    };

    const enabledTime = formActions.hookFormGetValues('first_enabled_time');
    const isNew = enabledTime == '' || enabledTime == null || enabledTime == '0000-00-00 00:00:00';

    return isNew ? (
        isManual ? (
            <Button
                sx={{ mr: 1 }}
                onClick={manualMetricEnable}
                variant={button.props.variant}
                type={'button'}
                startIcon={<IconMi icon={'view'} />}
            >
                {t('enable_publish')}
            </Button>
        ) : (
            <FormPanelButtonCollectData {...props} />
        )
    ) : (
        <Button
            sx={{ mr: 1 }}
            onClick={handleClick}
            variant={button.props.variant}
            type={'button'}
            startIcon={<IconMi icon={'view'} />}
        >
            {t(isNew ? 'enable_publish' : button.label)}
        </Button>
    );
}
