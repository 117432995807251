import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { reportAPI } from 'api/report';
import { AssocArray } from 'tools/types';
import { ReportContentNS } from 'components/report-content/index';
import BlockComponent = ReportContentNS.BlockComponent;
import ContentSettings = ReportContentNS.ContentSettings;
import LineChartInitialDataResponse = ReportContentNS.LineChartInitialDataResponse;
import AdditionalContentSettingsLineChart = ReportContentNS.AdditionalContentSettingsLineChart;
import ChartComponentSettings = ReportContentNS.ChartComponentSettings;
import useOnApplyAccessMapChange from 'components/report-content/hooks/useOnApplyAccessMapChange';
import useOnMeasurementTime from 'components/report-content/hooks/useOnMeasurementTime';
import useReportContentContext from 'components/report-content/hooks/useReportContentContext';

export default function useRCChartData<T extends ChartComponentSettings>(
    component: BlockComponent<T>,
    contentSettings: ContentSettings,
    updateBlockSettings: (newSettings: T) => void,
    updateDataQueries: boolean
) {
    const settingsRef = useRef(component.settings);
    useEffect(() => {
        settingsRef.current = component.settings;
    }, [component.settings]);

    const { filtersString } = useReportContentContext(() => removeInitialData());
    const dataQueryKey =
        `report_block_initial_${component.datasetRendererBlockComponentId}_${contentSettings.segmentValueId}` +
        (updateDataQueries ? '_update_query' : '_data');
    const {
        data: initialData,
        isLoading,
        remove: removeInitialData,
    } = useQuery<LineChartInitialDataResponse, Error>(
        [dataQueryKey, JSON.stringify(settingsRef.current.dataQueries)],
        () => {
            const lineChartSettings: AdditionalContentSettingsLineChart = {
                ...contentSettings,
                page: 0,
            };
            return reportAPI.getComponentData(settingsRef.current, lineChartSettings, filtersString);
        }
    );
    const [data, setData] = useState<Array<AssocArray<string>>>([]);
    useEffect(() => {
        if (!initialData) {
            return;
        }
        setData(initialData.dataResults.table);
        // Hide block from viewer if component has no data
        if (contentSettings.context != 'view') {
            return;
        }
        updateBlockSettings({
            ...component.settings,
            //@ts-ignore
            hasNoData: initialData.dataResults.table.length == 0,
        });
    }, [initialData]);

    useOnApplyAccessMapChange(contentSettings.applyAccessMap, removeInitialData);
    useOnMeasurementTime(contentSettings.measurement_time, removeInitialData);
    // Reset all data on Component Filter Change
    useEffect(() => {
        removeInitialData();
    }, [component.settings.groupFilter]);
    return { initialData, data, isLoading };
}
