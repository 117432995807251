import * as React from 'react';
import { TFunction } from 'react-i18next';
import useBundleTranslation from 'i18n';
import { useEffect, useState } from 'react';
import { add, remove } from 'store/popupSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Breakpoint,
    Typography,
    CircularProgress,
} from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import IconMi from 'components/common/icon/IconMi';
import { SxProps } from '@mui/system';

export type PopupSettings = {
    title?: string;
    groupTitles?: {
        single: string;
        multi: string;
    };
    subTitle?: string;
    subTitleConfig?: {
        icon: string;
        type: string;
        text: string;
        color: string;
    };
    customSubTitle?: JSX.Element;
    textOK?: string;
    colorOk?: string;
    iconOK?: string;
    textCancel?: string;
    needTranslation?: boolean;
    noCancel?: boolean;
    noOk?: boolean;
    noButtons?: boolean;
    maxWidth?: Breakpoint;
    customMaxWidth?: string;
    asidePanel?: boolean;
    customActionPanel?: JSX.Element;
    uid?: string;
};

export function Popup({
    children,
    settings,
    open,
    onConfirm,
    onHide,
    onBackdropClick = () => {},
    okDisabled = false,
    isLoading = false,
    maxWidth = 'popupLg',
    fullWidth = true,
    scrollType = 'body',
    additionalButtons,
    actionsPreContent,
    keepMounted = false,
    sx,
    translationNS,
    t,
    className,
    onCancel,
}: {
    isLoading?: boolean;
    children: any;
    settings: PopupSettings;
    open: boolean;
    onConfirm?: any;
    onHide: any;
    onBackdropClick?: () => void;
    okDisabled?: boolean;
    maxWidth?: Breakpoint | false;
    fullWidth?: boolean;
    scrollType?: 'body' | 'paper';
    additionalButtons?: Array<ReactJSXElement>;
    actionsPreContent?: any;
    keepMounted?: boolean;
    sx?: SxProps;
    translationNS?: string;
    t?: TFunction;
    className?: string;
    onCancel?: () => void;
}) {
    if (undefined === t) t = useBundleTranslation([translationNS ?? 'components/common/grid/grid'])['t'];

    onConfirm = onConfirm ?? onHide;

    const dispatch = useDispatch();

    const currentPopupIndex = useSelector((state: any) => state.popup.popupCounter);

    const [popupIndex] = useState(currentPopupIndex);
    const [modelClass, setModelClass] = useState('');
    useEffect(() => {
        setModelClass(popupIndex + 1 == currentPopupIndex ? '' : 'd-none');
    }, [currentPopupIndex]);

    useEffect(() => {
        dispatch(add());
        return () => {
            dispatch(remove());
        };
    }, []);

    const getPanelProps = () => {
        if (settings.asidePanel) {
            return {
                sx: {
                    position: 'fixed',
                    top: 40,
                    right: 0,
                    width: '37%',
                    height: 'calc(100% - 40px)',
                    maxHeight: 'calc(100% - 40px)',
                    margin: '0px !important',
                },
            };
        }
    };

    const getContentWrapperSx = () => {
        if (settings.asidePanel) {
            return { maxHeight: '88%', minHeight: '88%', padding: '0px' };
        }

        return { overflow: 'visible' };
    };

    const buildCustomSubTittle = () => {
        if (settings.subTitleConfig) {
            const { color, icon, text } = settings.subTitleConfig;

            const firstPartIndex = text?.indexOf('<<');
            const secondPartIndex = text?.indexOf('>>');

            const firstPart = text?.substring(0, firstPartIndex).trim();
            const iconText = text.substring(firstPartIndex + 2, secondPartIndex);
            const lastPart = text?.substring(secondPartIndex + 2).trim();
            const iconElement = (
                <Typography color={color} sx={{ display: 'flex' }}>
                    <IconMi icon={icon} fontSize={'16'} sx={{ marginRight: '2px' }} />
                    {iconText}
                </Typography>
            );

            return (
                <Typography className="popup-subtitle" sx={{ pt: '2px', display: 'flex' }}>
                    {firstPart}
                    &nbsp;
                    {iconElement}
                    &nbsp;
                    {lastPart}
                </Typography>
            );
        }
        return null;
    };

    const dataTestAttr = 'popup_' + (settings.uid ?? settings.title);

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={(event, reason) => {
                if (reason && reason == 'backdropClick') {
                    onBackdropClick();
                } else {
                    onHide(event);
                }
            }}
            scroll={scrollType}
            sx={{
                ...{
                    '.MuiDialog-paper': {
                        overflow: 'visible',
                        marginTop: '100px',
                    },
                    '.MuiDialog-container:after': {
                        content: 'none',
                    },
                    '.MuiDialog-paperScrollPaper': { maxHeight: 'calc(100% - 132px)' },
                },
                ...sx,
                ...{ '.MuiDialog-container .MuiDialog-paper': { maxWidth: settings.customMaxWidth ?? undefined } },
            }}
            keepMounted={keepMounted}
            PaperProps={getPanelProps()}
            data-test={dataTestAttr + '_wrapper'}
            className={'popup-component' + (className ? ' ' + className : '')}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    position: 'relative',
                    py: '10px',
                    minHeight: '48px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: 'primary.main',
                        fontSize: '14px',
                        lineHeight: '18px',
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {false !== settings.needTranslation ? t(settings.title ?? 'info') : settings.title ?? t('info')}
                </Typography>
                {settings.subTitle && (
                    <Typography className="popup-subtitle" sx={{ pt: '2px' }}>
                        {false !== settings.needTranslation ? t(settings.subTitle) : settings.subTitle}
                    </Typography>
                )}
                {settings.subTitleConfig && buildCustomSubTittle()}
                {onHide ? (
                    <IconButton
                        aria-label="close"
                        onClick={onHide}
                        sx={{
                            position: 'absolute',
                            right: '16px',
                            top: '50%',
                            color: 'primary.main',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <IconMi icon="times" fontSize="12" />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers sx={getContentWrapperSx()} data-test={dataTestAttr}>
                {children}
            </DialogContent>
            {settings.noButtons !== true && !settings.customActionPanel && (
                <DialogActions sx={{ justifyContent: 'normal', py: 2, px: 3, flexWrap: 'wrap' }}>
                    {actionsPreContent}
                    {additionalButtons && additionalButtons.map((button) => button)}
                    {!settings!.noOk && (
                        <Button
                            variant={additionalButtons ? 'outlined' : 'contained'}
                            onClick={onConfirm}
                            disabled={okDisabled}
                            color={settings.colorOk as any}
                            className={'popup-button--ok'}
                            data-test={dataTestAttr + '_ok_button'}
                            startIcon={settings.iconOK ? <IconMi icon={settings.iconOK} /> : undefined}
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ marginRight: '5px' }}
                                    color={additionalButtons ? 'primary' : 'inherit'}
                                    size={12}
                                />
                            )}

                            {settings.textOK
                                ? false !== settings.needTranslation
                                    ? t(settings.textOK)
                                    : settings.textOK
                                : t('save')}
                        </Button>
                    )}

                    {!settings!.noCancel && (
                        <Button
                            data-test={dataTestAttr + '_cancel_button'}
                            variant="outlined"
                            color="neutral"
                            onClick={onCancel ? onCancel : onHide}
                            className={'popup-button--cancel'}
                        >
                            {settings.textCancel
                                ? false !== settings.needTranslation
                                    ? t(settings.textCancel)
                                    : settings.textCancel
                                : t('cancel')}
                        </Button>
                    )}
                </DialogActions>
            )}
            {settings.customActionPanel}
        </Dialog>
    );
}
