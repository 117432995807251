import { alpha, Box, Button, Popover, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import useBundleTranslation from 'i18n';
import React, { useState } from 'react';
import ReactSelect from 'components/common/react-select/ReactSelect';
import IconMi from 'components/common/icon/IconMi';
interface alertNotificationData {
    immediate_notification_ind: string;
    send_notification_via: string;
    send_alert_to_phone: string;
    trigger_alert_when: string;
    workflow_role_name: string | null;
    trigger_alert_when_frequently: string;
}
export interface AlertRuleNotificationChangeBoxProps {
    alertRule?: any;
    notificationLineForRole: any;
    mod?: string;
    anchorElement: HTMLElement | null;
    onApply: (notificationData: alertNotificationData) => void;
    onCancel: (event: any) => void;
}

//engine/layouts/components/alert_rule/notification_box2.phtml
//AlertNotificationBox
//AlertNotificationBoxFactory
export default function AlertRuleNotificationChangeBox(props: AlertRuleNotificationChangeBoxProps) {
    const { t } = useBundleTranslation(['components/alert-subscription/alert_rule_list']);
    const {
        alertRule,
        notificationLineForRole = '',
        mod,
        anchorElement,
        onApply = () => {},
        onCancel = () => {},
    } = props;

    const [notification, setNotification] = useState<alertNotificationData>(
        alertRule?.notification ?? {
            immediate_notification_ind: 'Y',
            send_notification_via: 'email',
            send_alert_to_phone: 'N',
            trigger_alert_when: 'first time only',
            workflow_role_name: null,
            trigger_alert_when_frequently: 'first time only',
        }
    );

    const immediateNotificationIndOptions = ['N', 'Y', 'None'].map((value) => {
        return {
            value: value,
            label: t(`notification_box.immediate_notification_ind.${value}`),
        };
    });

    const sendNotificationViaOptions = ['email', 'phone_alert', 'sms'].map((value) => {
        return {
            value: value,
            label: t(`notification_box.send_via.${value}`),
        };
    });
    const alertWhenOptions = ['first time only', 'every time'].map((value) => {
        return {
            value: value,
            label: t(`notification_box.alert_when.${value.replaceAll(' ', '_')}`),
        };
    });
    const alertWhenFrequentlyOptions = ['first time only', 'every time'].map((value) => {
        return {
            value: value,
            label: t(`notification_box.alert_when.${value.replaceAll(' ', '_')}`),
        };
    });

    const isImmediateRole = alertRule?.workflow_notif_i == 'immediate' || alertRule?.workflow_notif_u == 'immediate';
    const isWorkflowRoleDependent = alertRule?.workflowId && alertRule?.workflowId != 0;

    const SendViaControl = () => {
        return (
            <ToggleButtonGroup
                color="primary"
                value={notification.send_notification_via}
                exclusive
                onChange={(event, value) => {
                    setNotification({ ...notification, send_notification_via: value });
                }}
                sx={{ width: '100%' }}
            >
                {sendNotificationViaOptions.map((item) => {
                    const icon = {
                        email: 'mail',
                        phone_alert: 'mobile',
                        sms: 'comment-2',
                    } as any;
                    return (
                        <ToggleButton value={item.value} key={item.value}>
                            <IconMi icon={icon[item.value]} fontSize="16" sx={{ mr: 1 }} />
                            {item.label}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        );
    };
    const SaveAndCancelControl = () => {
        return (
            <>
                <Button
                    sx={{ mt: 1, mr: 1 }}
                    onClick={() => {
                        onApply(notification);
                    }}
                >
                    {t('notification_box.btn_save')}
                </Button>
                <Button sx={{ mt: 1 }} variant={'outlined'} color={'neutral'} onClick={onCancel}>
                    {t('notification_box.btn_cancel')}
                </Button>
            </>
        );
    };

    const isBulk = !alertRule;
    const isItemIcon = mod == 'itemIcon';

    const getStyles = () => {
        if (isBulk) {
            return {
                '.MuiPaper-root': {
                    boxShadow: (theme: Theme) => `0px 8px 32px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
                    borderTopLeftRadius: 0,
                },
            };
        }

        if (isItemIcon) {
            return {
                '.MuiPaper-root': {
                    ml: '8px',
                    boxShadow: (theme: Theme) => `5px 5px 4px ${alpha(theme.palette.text.primary, 0.16)}`,
                    borderTopLeftRadius: 0,
                },
            };
        } else {
            return {
                '.MuiPaper-root': {
                    mt: 0.5,
                    boxShadow: (theme: Theme) => `0px 8px 32px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
                },
            };
        }
    };

    return (
        <Box>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isBulk || isItemIcon ? 'right' : 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: isBulk || isItemIcon ? 'right' : 'left',
                }}
                open={true}
                anchorEl={anchorElement}
                onClose={onCancel}
                sx={getStyles()}
            >
                <Box sx={{ p: 1, width: 328 }}>
                    {isWorkflowRoleDependent ? (
                        <>
                            {isImmediateRole ? (
                                <>
                                    <Box>{t('notification_box.receive_immediate_via_label')}</Box>
                                    <Box sx={{ mt: 1, mb: 1.5 }}>
                                        <SendViaControl />
                                    </Box>
                                    <Box>{notificationLineForRole}</Box>
                                    <Box>
                                        <SaveAndCancelControl />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box>{notificationLineForRole}</Box>
                                    <Box>
                                        <Button sx={{ mt: 1, mr: 1 }} onClick={onCancel}>
                                            {t('notification_box.btn_ok')}
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Box>
                                <ReactSelect
                                    data={immediateNotificationIndOptions}
                                    value={notification.immediate_notification_ind}
                                    update={(val) => {
                                        setNotification({ ...notification, immediate_notification_ind: val });
                                    }}
                                />
                            </Box>
                            {notification.immediate_notification_ind === 'Y' && (
                                <Box sx={{ mt: 1, mb: 1.5 }}>
                                    <SendViaControl />
                                </Box>
                            )}
                            <Box sx={{ mt: 1 }}>
                                <ReactSelect
                                    data={alertWhenFrequentlyOptions}
                                    value={notification.trigger_alert_when_frequently}
                                    update={(val) => {
                                        setNotification({
                                            ...notification,
                                            trigger_alert_when:
                                                notification.trigger_alert_when == 'frequently' ? 'frequently' : val,
                                            trigger_alert_when_frequently: val,
                                        });
                                    }}
                                />
                            </Box>
                            <Box>
                                <SaveAndCancelControl />
                            </Box>
                        </>
                    )}
                </Box>
            </Popover>
        </Box>
    );
}
