import React, { useEffect, useRef, useState } from 'react';
import { IFilterColumn } from 'components/report-content/components/manage-filters/index';
import { Box, Input, InputAdornment, TextField } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import Moment from 'moment/moment';
import DatePicker from 'components/common/date-picker/DatePicker';

export default function FilterControlRange({
    filter,
    onChange,
}: {
    filter: IFilterColumn;
    onChange: (newValue: any) => void;
}) {
    const isDatePicker = filter.value_type == 'datetime';
    const ref = useRef<number | null>(null);
    const handleChange = (value: string, index: number) => {
        const newValues = values.slice();
        newValues[index] = value;
        setValues(newValues);
        // Add delay before apply changes
        if (ref.current != null) {
            clearTimeout(ref.current);
        }
        ref.current = window.setTimeout(() => onChange(newValues), 1000);
    };

    const [values, setValues] = useState<Array<any>>([]);
    useEffect(() => {
        setValues(filter.savedValues);
    }, [filter.savedValues]);

    const dateFormat = filter.show_date_only == 'Y' ? 'yyyy-MM-DD' : 'yyyy-MM-DD HH:mm:ss';
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    useEffect(() => {
        if (values.length && isDatePicker) {
            setStartDate(new Date(values[0]));
            setEndDate(new Date(values[1]));
        }
    }, [values]);

    if (values.length == 0) {
        return null;
    }

    if (isDatePicker) {
        const calendarProps = {
            showTimeSelect: filter.show_date_only == 'N',
            dateFormat: dateFormat,
            customInput: (
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconMi icon="calendar" fontSize="16" />
                            </InputAdornment>
                        ),
                    }}
                />
            ),
        };

        return (
            <Box sx={{ flexGrow: 1 }}>
                <DatePicker
                    {...calendarProps}
                    selected={startDate}
                    value={values[0]}
                    onChange={(value) => handleChange(Moment(value).format(dateFormat), 0)}
                    maxDate={endDate}
                />
                to
                <DatePicker
                    {...calendarProps}
                    selected={endDate}
                    value={values[1]}
                    onChange={(value) => handleChange(Moment(value).format(dateFormat), 1)}
                    minDate={startDate}
                />
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Input
                value={values[0]}
                type={'text'}
                onChange={(event) => handleChange(event.target.value as string, 0)}
            />
            to
            <Input
                value={values[1]}
                type={'text'}
                onChange={(event) => handleChange(event.target.value as string, 1)}
            />
        </Box>
    );
}
