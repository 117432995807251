import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssocArray } from 'tools/types';
import { FormComponentValue } from 'components/common/form/layout/control';

export interface formSliceState {
    formElementsValues: AssocArray<AssocArray<Array<FormComponentValue>>>;
    formElementsSharedState: AssocArray<AssocArray<AssocArray<any>>>;
}

export const formSlice = createSlice({
    name: 'form',
    initialState: {
        formElementsValues: {},
        formElementsSharedState: {},
    } as formSliceState,
    reducers: {
        setFormAllowedValues: (
            state,
            action: PayloadAction<{ formKey: string; values: AssocArray<Array<FormComponentValue>> }>
        ) => {
            const formKey = action.payload.formKey;
            state.formElementsValues[formKey] = action.payload.values;
        },

        setFormComponentAllowedValues: (
            state,
            action: PayloadAction<{ formKey: string; componentName: string; values: Array<FormComponentValue> }>
        ) => {
            const formKey = action.payload.formKey;
            const name = action.payload.componentName;
            state.formElementsValues[formKey][name] = action.payload.values;
        },

        setFormElementSharedState: (
            state,
            action: PayloadAction<{ formKey: string; componentName: string; sharedState: AssocArray<any> }>
        ) => {
            const formKey = action.payload.formKey;
            const name = action.payload.componentName;
            if (typeof state.formElementsSharedState[formKey] == 'undefined') {
                state.formElementsSharedState[formKey] = {};
            }
            state.formElementsSharedState[formKey][name] = action.payload.sharedState;
        },
        unsetForm: (state, action: PayloadAction<{ formKey: string }>) => {
            const formKey = action.payload.formKey;
            state.formElementsSharedState[formKey] = {};
            state.formElementsValues[formKey] = {};
        },
    },
});

export const { setFormAllowedValues, setFormComponentAllowedValues, setFormElementSharedState, unsetForm } =
    formSlice.actions;

export default formSlice.reducer;
