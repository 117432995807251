import React, { useContext, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import useBundleTranslation from 'i18n';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import ComponentSettingsBubbleChart = ReportContentNS.ComponentSettingsBubbleChart;
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { AssocArray } from 'tools/types';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import fieldToChartMeasuredField = ReportContentNS.fieldToChartMeasuredField;
import DatasetField = ReportContentNS.DatasetField;
import AggregationField from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/AggregationField';
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsBubbleChart> = [
    'title',
    'show_in_viewer',
    'include_in_email',
    'include_in_email_attachment',
    'x_axis_plot_intermediate_values',
    'y_axis_plot_intermediate_values',
    'display_value',
    'display_label',
];
const componentNumericFormField: Array<keyof ComponentSettingsBubbleChart> = ['height'];

export default function BubbleChartEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsBubbleChart> & { block: BlockType<ComponentSettingsBubbleChart> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const datasetFields = editPanelContext?.datasetFields ?? [];

    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        name_field: component.settings.name_field.reference_name_escaped,
        x_field: component.settings.x_field.reference_name_escaped,
        y_field: component.settings.y_field.reference_name_escaped,
    };
    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process measure fields settings
        const newSettings: ComponentSettingsBubbleChart = {
            ...component.settings,
        } as ComponentSettingsBubbleChart;
        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        const newNameField = datasetFields.find(
            (f) => f.reference_name_escaped == elementProps.form.hookFormGetValues('name_field')
        ) as DatasetField;
        const newXField = datasetFields.find(
            (f) => f.reference_name_escaped == elementProps.form.hookFormGetValues('x_field')
        ) as DatasetField;
        const newYField = datasetFields.find(
            (f) => f.reference_name_escaped == elementProps.form.hookFormGetValues('y_field')
        ) as DatasetField;

        const resultFields = [newNameField.reference_name, newXField.reference_name, newYField.reference_name],
            resultFieldAggregations: any = {};

        resultFieldAggregations[newXField.reference_name] = 'Min';
        resultFieldAggregations[newYField.reference_name] = 'Min';

        if (measuredField.reference_name > '') {
            resultFields.push(measuredField.reference_name);
            resultFieldAggregations[measuredField.reference_name] = measuredField.aggregation_function;
        }

        const dataQueries: ComponentSettingsChartDataQueries = {
            table: { resultFields: resultFields, resultFieldAggregations: resultFieldAggregations },
        };

        const resultSettings = {
            ...newSettings,
            value_field: { ...measuredField },
            name_field: fieldToChartMeasuredField(newNameField),
            x_field: fieldToChartMeasuredField(newXField),
            y_field: fieldToChartMeasuredField(newYField),
            // localPalette: newLocalPalette,
            dataQueries: dataQueries,
        } as ComponentSettingsBubbleChart;

        editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
    };

    const [measuredField, setMeasuredField] = useState(component.settings.value_field);
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <Stack height={1}>
            {/*Main Panel*/}
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={t('bubble_chart.edit_title')}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: { component: 'FormText', name: 'title', label: t('title') },
                        })}
                    />
                </Box>
                <Divider />
                <Box>
                    <FormLabel>{t('labels')}</FormLabel>
                    <ReactHookFormController
                        componentValues={datasetFields.map((field) => ({
                            label: field.column_name,
                            value: field.reference_name_escaped,
                        }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'name_field',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                <Box>
                    <FormLabel>{t('bubble_chart.x_axis')}</FormLabel>
                    <ReactHookFormController
                        componentValues={datasetFields
                            .filter((f) => f.value_type == 'numeric')
                            .map((field) => ({
                                label: field.column_name,
                                value: field.reference_name_escaped,
                            }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'x_field',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'x_axis_plot_intermediate_values'}
                        label={t('bubble_chart.x_axis_plot_intermediate_values')}
                    />
                </Box>

                <Box>
                    <FormLabel>{t('bubble_chart.y_axis')}</FormLabel>
                    <ReactHookFormController
                        componentValues={datasetFields
                            .filter((f) => f.value_type == 'numeric')
                            .map((field) => ({
                                label: field.column_name,
                                value: field.reference_name_escaped,
                            }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'y_field',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'y_axis_plot_intermediate_values'}
                        label={t('bubble_chart.y_axis_plot_intermediate_values')}
                    />
                </Box>

                <Divider />

                <AggregationField
                    label={t('value')}
                    allowedFields={datasetFields}
                    measuredField={measuredField}
                    setMeasuredField={setMeasuredField}
                    fieldToMeasuredField={fieldToChartMeasuredField}
                />

                <Divider />
                <Box>
                    <EditPanelCheckBox elementProps={elementProps} name={'display_value'} label={t('display_value')} />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'display_label'}
                        label={t('bubble_chart.display_label')}
                    />
                </Box>
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                    <Box sx={{ width: '72px' }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: { component: 'FormText', name: 'height', label: '' },
                            })}
                        />
                    </Box>
                </Stack>
                <Divider />

                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'show_in_viewer'}
                        label={t('show_in_viewer')}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'include_in_email'}
                        label={t('include_in_email')}
                    />
                </Box>
                <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                <Box>
                    <GetEmbedCode contentSettings={contentSettings} height={actualHeight} blockUID={block.uid} t={t} />
                </Box>
                <Divider />
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={handleApply}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
        </Stack>
    );
}
