import { instance } from './api';
import qs from 'query-string';

export const alertRuleAPI = {
    async getData(params: any = {}) {
        if ('undefined' === typeof params) params = {};
        let urlData = {
            alert_rule_id: params.alertRuleId,
            element_id: params.elementId,
            segment_value_id: params.segmentValueId,
            user_id: params.userId,
            assign_type: params.alertRuleId ? 'edit' : 'draw',
            report_id: params.reportId,
            visualization_element_id:
                params.visualizationElementId && params.visualizationElementId != params.elementId
                    ? params.visualizationElementId
                    : undefined,
            step: params.step,
            global: params.global === 'Y' ? 'Y' : 'N',
        };

        return await instance.get(
            'data/notification/alert-rule/wizard' +
                (params.alertRuleId ? `/${params.alertRuleId}/` : '') +
                '?' +
                qs.stringify(urlData)
        );
    },

    add(params: any = {}): any {
        if ('undefined' === typeof params) params = {};
        return instance.post(
            `data/notification/alert-rule/insert${params.user_id ? `/user/${params.user_id}` : ''}`,
            params
        );
    },

    update(params: any = {}): any {
        if ('undefined' === typeof params) params = {};
        return instance.post(
            `/data/notification/alert-rule/update/${params.alert_rule_id}${
                params.user_id ? `/user/${params.user_id}` : ''
            }`,
            params
        );
    },

    checkDeliveryTypes() {
        return instance.get(`/data/notification/alert-rule/check-delivery-types`);
    },

    confirmSendTestData(type: string, alertRuleId?: number, metricId?: number) {
        const url = `/data/notification/alert-rule/confirm-send-test/type/${type}${
            alertRuleId ? `/alert_rule_id/${alertRuleId}` : ''
        }${metricId ? `/metric_id/${metricId}` : ''}`;
        return instance.get(url);
    },

    sendTest(alertRuleData: any, type: string, sendTo: string, userEmail?: string) {
        const data = {
            ...(alertRuleData ?? {}),
            sendTo: sendTo,
            email: sendTo == 'me' ? '' : (userEmail ?? '').trim(),
        };
        delete data.user_id;
        const url = `data/notification/alert-rule/send-test/type/${type}`;
        return instance.post(url, data);
    },

    getPreviewData(alertRuleData: any, type: string) {
        const url = `data/notification/alert-rule/preview/type/${type}`;
        return instance.post(url, alertRuleData);
    },

    getSendTestRecipientsList(sendTo: string, type: string, alertRuleId: number, metricId?: number) {
        const metric_id = metricId ?? 0;
        const url = `data/notification/alert-rule/${alertRuleId}/get-send-now-recipients/metric_id/${metric_id}/type/${type}${
            sendTo == 'admins' ? '/admins/Y' : ''
        }`;
        return instance.get(url);
    },

    getSubscribedUsers(alertRuleId: number) {
        return instance.get(`data/notification/alert-rule/${alertRuleId}/get-subscribed-users`);
    },

    getWorkflowInfo(workflowId: string, workflowInstanceId?: number) {
        const instanceId = workflowInstanceId ?? 0;
        return instance.get(
            `data/notification/alert-rule/get-workflow-info/workflow/${workflowId}${
                instanceId > 0 ? `/workflow_instance/${instanceId}` : ''
            }`
        );
    },

    getAlertRulePatterns(elementId: number) {
        const urlData = {
            element_id: elementId,
        };
        return instance.get(`data/notification/alert-rule/get-patterns-edit-popup` + '?' + qs.stringify(urlData));
    },

    async createSimulation(params: any) {
        const url = `/data/notification/alert-rule/create-simulation`;
        return await instance.post(url, params ?? {});
    },

    async checkSimulationStatus(simulationId: number) {
        const url = `/data/notification/alert-rule/check-simulation/simulation/${simulationId}`;
        return await instance.post(url);
    },

    async getSimulationGridData(simulationId: number) {
        return await instance.get(
            `/data/notification/alert-rule/get-simulation-grid-data${simulationId ? `/simulation/${simulationId}` : ''}`
        );
    },

    cancelSimulation(simulationId: number) {
        const url = `/data/notification/alert-rule/cancel-simulation/simulation/${simulationId}`;
        return instance.post(url);
    },

    addAlertMetric4ReportData(reportId: number, userId?: number) {
        let params = {
            reportId: reportId,
            userId: userId,
        };

        const url = `/data/notification/alert-rule/get-alert-metric4report`;
        return instance.get(url + '?' + qs.stringify(params));
    },

    createNewAlertRule() {
        return instance.get('data/editor/alert-rule/get-metric-for-new-alert-popup');
    },
};
