import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import { alertSubscriptionAPI } from 'api/api';
import MITable from 'components/common/grid/MITable';
import { useIsAdmin } from 'hooks/useUserPrivilege';

interface user {
    display_name: string;
    user_id: number;
}

interface popupMainData {
    alertName: string;
    users?: user[];
    isTableView: boolean;
}

export interface SubscribedUsersPopupProps {
    alertRuleId: number;
    isReport: string;
    elementId: number;
    segmentId: number;
    userToAdd?: number;
    notificationLine?: string;
    onClose: (event: any) => void;
}

//alertRuleDescriptionInit
//engine/application/notification/views/scripts/alertruleuser/list.phtml
export default function SubscribedUsersPopup(props: SubscribedUsersPopupProps) {
    const { alertRuleId, isReport, elementId, segmentId, userToAdd, notificationLine, onClose = () => {} } = props;

    const { t } = useBundleTranslation(['components/alert-subscription/subscribed_users_popup']);
    const [mainData, setMainData] = useState<popupMainData>();
    const [imageAntiCache, setImageAntiCache] = useState<any>(Date.now());
    const isAdmin = useIsAdmin();

    useEffect(() => {
        alertSubscriptionAPI
            .subscribedUsers(alertRuleId, isReport, elementId, segmentId, userToAdd, notificationLine)
            .then((response) => {
                if (response.statusText === 'OK') {
                    setMainData(response.data);
                }
            })
            .catch(() => {});
    }, []);

    if (!mainData) return null;

    return (
        <Popup
            settings={{
                title: t('popup_title'),
                noButtons: true,
            }}
            maxWidth="popupSm"
            open={true}
            onHide={onClose}
        >
            <Box>
                <Box sx={{ pb: 2 }}>"{mainData.alertName}"</Box>
                {mainData.isTableView ? (
                    <MITable
                        autoHeight
                        gridName={'subscribed_users'}
                        massActionsConfig={{ enabled: false }}
                        data={(mainData.users ?? []).map((user: any) => {
                            return {
                                name: {
                                    component: isAdmin ? 'link' : 'text',
                                    config: {
                                        text: user.display_name,
                                        href: `/editor/user/${user.user_id}`,
                                    },
                                },
                                method: user.method,
                                last_alert: user.last_alert,
                            };
                        })}
                        height={200}
                        columns={[
                            {
                                name: 'name',
                                title: t('grid_column.name'),
                                resizable: false,
                            },
                            {
                                name: 'method',
                                title: t('grid_column.method'),
                                resizable: false,
                            },
                            {
                                name: 'last_alert',
                                title: t('grid_column.last_alert'),
                                resizable: false,
                            },
                        ]}
                    />
                ) : (
                    <ul className="user-list-view">
                        {(mainData.users ?? []).map((user) => {
                            return (
                                <li className="ul-li">
                                    <div className="wall_note_user_image_td_OLD ul-user-avatar">
                                        <img
                                            className="wall_other_users_image_OLD"
                                            src={`/data/dashboard/wall/image?user=${user.user_id}}&thumb=note&r=${imageAntiCache}`}
                                        />
                                    </div>
                                    <div className="wall_note_text_OLD ul-user-name">{user.display_name}</div>
                                    <div style={{ clear: 'both' }} />
                                </li>
                            );
                        })}
                    </ul>
                )}
            </Box>
        </Popup>
    );
}
//ToDO implement different view reference to backend data (format table data)
//ToDo Change link for user avatar
//ToDo consider descriptionBox && alertRuleDescriptionInit
