import React, { useContext, useState } from 'react';
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { FormDataAPIType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Box, Divider, FormControl, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsTextBlockMeasureField = ReportContentNS.ComponentSettingsTextBlockMeasureField;
import SimpleFormattingRulesList from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/SimpleFormattingRulesList';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import useBundleTranslation from 'i18n';

export default function MeasureForm({
    measure,
    onApply,
    onClose,
    title,
}: {
    measure: ComponentSettingsTextBlockMeasureField;
    onApply: (formula: ComponentSettingsTextBlockMeasureField) => void;
    onClose: (isCancel: boolean) => void;
    title: string;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const displayMasks = editPanelContext?.displayMasks ?? [];

    const elementProps: FormDataAPIType = useCustomSimplifiedForm({
        display_mask_id: measure.display_mask_id,
    });

    const handleApply = () => {
        onApply({
            ...measure,
            ...elementProps.form.hookFormGetValues(),
            formattingRules: structuredClone(formattingRules),
        });
        onClose(false);
    };

    const [formattingRules, setFormattingRules] = useState(measure.formattingRules ?? []);

    return (
        <Stack height={1}>
            <Box flexShrink={0}>
                <BlockEditPanelHeader title={title} onCancel={() => onClose(true)} item={measure.column_name} />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                {measure.value_type != 'text' && (
                    <Box>
                        <FormControl>
                            <FormLabel>{t('display_mask')}</FormLabel>
                            <TemplateFormComponent
                                elementProps={elementProps}
                                componentValues={[{ label: 'Default', value: '' }]
                                    .concat(
                                        displayMasks
                                            .filter(
                                                (d) =>
                                                    d.currency_mask_ind != 'Y' &&
                                                    d.mask_type ==
                                                        (measure.value_type == 'numeric' ? 'numeric' : 'date')
                                            )
                                            .map((d) => ({
                                                label: d.display_example,
                                                value: d.display_mask_id,
                                            }))
                                    )
                                    .concat(
                                        displayMasks
                                            .filter(
                                                (d) =>
                                                    d.currency_mask_ind == 'Y' &&
                                                    d.mask_type ==
                                                        (measure.value_type == 'numeric' ? 'numeric' : 'date')
                                            )
                                            .map((d) => ({
                                                label: d.display_example,
                                                value: d.display_mask_id,
                                                valueGroup: t('currency'),
                                            }))
                                    )}
                                component={'FormSelect'}
                                name={'display_mask_id'}
                            />
                        </FormControl>
                    </Box>
                )}
                {measure.value_type == 'numeric' && <Divider />}
                {measure.value_type != 'datetime' && (
                    <>
                        <FormLabel>{t('text_block.formatting_for', { name: measure.column_name })}</FormLabel>
                        <SimpleFormattingRulesList
                            fieldValueType={measure.value_type}
                            rules={formattingRules}
                            onUpdate={(newRules) => {
                                if (JSON.stringify(formattingRules) != JSON.stringify(newRules)) {
                                    setFormattingRules(newRules);
                                }
                            }}
                        />
                    </>
                )}
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls onApply={() => handleApply()} onCancel={() => onClose(true)} isTypeSave />
            </Box>
        </Stack>
    );
}
