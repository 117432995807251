import { DataFetchCommandEntityType, FormControlDataFetchCommandProps } from 'components/common/form/DataFetchCommand';
import { AssocArray } from 'tools/types';

export namespace DataFetchCommandNS {
    const postFieldsMap: { [key in DataFetchCommandEntityType | 'global']: Array<string> } = {
        global: [
            'data_source',
            'external_report_external_id',
            'data_fetch_command',
            'dynamic_parameter1_value',
            'dynamic_parameter2_value',
            'pattern_subst',
        ],
        calendar: ['event_interval', 'bulk_mode_ind'],
        command: [],
        dataset: ['measurement_interval_id', 'save_historical_instances_ind'],
        dependency: ['dependency_mode'],
        metric: ['measurement_interval_id', 'bulk_mode_ind'],
        report: ['measurement_interval_id'],
        segment: [
            'partition_value_type',
            'segment_type',
            'currency_set_by_segment_value_ind',
            'parent_segment_id',
            'primary_segment_id',
            'secondary_segment_id',
        ],
    };

    export const patternsMap: { [key in DataFetchCommandEntityType]: Array<string> } = {
        calendar: [':last_event_time'],
        command: [':last_measurement_time'],
        dataset: [':measurement_time'],
        dependency: [':last_dependency_satisfied'],
        metric: [':last_measurement_time'],
        report: [],
        segment: [],
    };

    export function prepareRequestData(
        substitutions: AssocArray<string>,
        controlProps: FormControlDataFetchCommandProps,
        url?: string
    ) {
        const postFields: AssocArray<string> = {};
        postFieldsMap.global.concat(postFieldsMap[controlProps.type]).forEach((field) => {
            postFields[field] = controlProps.form.hookFormGetValues(field) ?? '';
        });
        postFields['segment_id'] = controlProps.form.hookFormGetValues('segment_id') ?? '0';
        if (postFields['segment_id'] == '0') {
            // Event Calendar
            postFields['segment_id'] = controlProps.form.hookFormGetValues('scope_limited_by_segment_id') ?? '0';
        }
        postFields['segment_value_id'] = controlProps.form.hookFormGetValues('segment_value_id') ?? 0;

        const result: any = {
            ...postFields,
            ...substitutions,
            type: controlProps.type,
            pkId: controlProps.pkId,
        };
        if (url) {
            result.poolingCreateURL = url;
        }
        if (result?.pattern_subst && result.pattern_subst.match(/\s[0-9]{2}:[0-9]{2}$/)) {
            result.pattern_subst += ':00';
        }

        return result;
    }
}
