import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Button, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { useNavigate } from 'react-router-dom';
import AlertRuleListLineItem from 'components/alert-subscriptions/parts/AlertRuleListLineItem';
import SubscribedUsersPopup from 'components/alert-subscriptions/SubscribedUsersPopup';
import DimensionPopup from 'components/dimension-popup/DimensionPopup';
import AlertRuleNotificationChangeBox from 'components/alert-subscriptions/parts/AlertRuleNotificationChangeBox';
import styles from './AlertRuleList.styles';

export interface AlertRuleListProps {
    data: any;
    clickAddNewRule: (event: any) => void;
    maxSegmentsInSegmentLine?: number;
    reverseLink?: boolean;
    onUpdateAlertRuleData: (alertRule: any, type: string) => void;
    onNotificationChange: (notificationData: any, alertRule: any) => void;
}
interface subscribedToThisAlertDataProps {
    alertRuleId: number;
    isReport: string;
    elementId: number;
    segmentId: number;
    userToAdd?: number;
    notificationLine?: string;
}

//engine/layouts/components/alert_rule/alert_rule_list_template_.phtml
//AlertRuleList2 List
export default function AlertRuleList(props: AlertRuleListProps) {
    const {
        data,
        maxSegmentsInSegmentLine = 3,
        clickAddNewRule = () => {},
        onUpdateAlertRuleData = () => {},
        onNotificationChange = () => {},
        reverseLink = false,
    } = props;

    const { t } = useBundleTranslation(['components/alert-subscription/alert_rule_list']);
    const navigate = useNavigate();

    const asMetricFlow = !['internal report', 'external report', 'multi-metric chart'].includes(data.elementInfo.type);

    const [subscribedToThisAlertData, setSubscribedToThisAlertData] = useState<subscribedToThisAlertDataProps>();
    const [showSubscribedToThisAlertPopup, setShowSubscribedToThisAlertPopup] = useState<boolean>(false);
    const [alertForDimensionPopup, setAlertForDimensionPopup] = useState<any | null>(null);
    const [notificationChangeBoxAnchor, setNotificationChangeBoxAnchor] = useState<HTMLElement | null>(null);
    const [notificationChangeBoxAlert, setNotificationChangeBoxAlert] = useState<any>(undefined);
    const [isMarkChangeBoxIcon, setIsMarkChangeBoxIcon] = useState<boolean>(false);

    const isSubscribedCount = data.alerts.filter((item: any) => item.subscribed == 'Y').length;

    const showNotificationChangeBox = (event: React.MouseEvent<HTMLElement>, alert?: any) => {
        setIsMarkChangeBoxIcon(!!event.currentTarget.getAttribute('data-markicon'));
        setNotificationChangeBoxAnchor(event.currentTarget);
        setNotificationChangeBoxAlert(alert);
    };

    const ControlLine = () => {
        if (data.options.can_create_alert_rule === false) {
            if (data.alerts.length > 0) {
                return null;
            } else {
                return <Box sx={styles.arlControlLine}>{t('empty_alert_rules_message')}</Box>;
            }
        }
        return (
            <Box sx={styles.arlControlLine} className={data.alerts.length % 2 == 0 ? 'odd' : undefined}>
                <Button variant="outlined" startIcon={<IconMi icon="new" />} onClick={clickAddNewRule}>
                    {t('btn_add_rule')}
                </Button>
            </Box>
        );
    };

    // ToDo: change link to new
    const navigateToEdit = (alertRule: any) => {
        let rl = '';
        const url = `/editor/${alertRule.type != 'global' ? 'kpi-alert-rule' : ''}/${alertRule.id}${
            alertRule.type == 'global'
                ? '/element_id/' + (alertRule.visualization == 'Y' ? alertRule.vis_element_id : alertRule.element_id)
                : ''
        }`;
        /* if (reverseLink) {
            rl = `/rl_e/${data.elementInfo.elementId}${
                data.elementInfo.segmentId > 0 ? '/rl_s/' + data.elementInfo.segmentId : ''
            }/rl/Y`;
        }*/
        navigate(url + rl);
    };

    const getNotificationLine = (alertRule: any) => {
        const isWorkflowRoleDependent = alertRule.workflow_id != 0;
        if (isWorkflowRoleDependent) return alertRule.workflow_role;

        let result = '';
        if (alertRule.notification.immediate_notification_ind == 'Y') {
            result += t('notification_line_type_immediate_y', {
                sendVia: t(`send_notification_via.${alertRule.notification.send_notification_via}`),
            });
        } else if (alertRule.notification.immediate_notification_ind == 'N') {
            result += t('notification_line_type_immediate_n');
        } else {
            result += t('notification_line_type_immediate_other');
        }

        if (alertRule.notification.trigger_alert_when == 'frequently') {
            result += ' ' + t('notification_line_trigger_alert_when_frequently') + ' ';
            result += alertRule.notification.trigger_alert_when_frequently;
        } else {
            result += ' ' + alertRule.notification.trigger_alert_when;
        }
        return result;
    };

    const getNotificationLineForRole = function (alertRule: any) {
        const isWorkflowRoleDependent = alertRule.workflow_id != 0;
        if (!isWorkflowRoleDependent) {
            return getNotificationLine(alertRule);
        }

        const getSubscriptionStatus = (type: string) => {
            const typeText = t(`subscription_status.${type}`);
            return (
                typeText +
                (type === 'immediate' ? t(`send_notification_via.${alertRule.notification.send_notification_via}`) : '')
            );
        };

        return (
            <>
                <Box>
                    {t('notification_line_for_role.role', {
                        workflowRoleName: alertRule.notification.workflow_role_name,
                    })}
                </Box>
                <Box>
                    {t('notification_line_for_role.creation', {
                        subscriptionStatus: getSubscriptionStatus(alertRule.workflow_notif_i),
                    })}
                </Box>
                <Box>
                    {t('notification_line_for_role.updates', {
                        subscriptionStatus: getSubscriptionStatus(alertRule.workflow_notif_u),
                    })}
                </Box>
            </>
        );
    };

    return (
        <Box>
            {(data.alerts.length > 0 || asMetricFlow) && (
                <>
                    <Stack
                        direction="row"
                        sx={{
                            mb: 2,
                            alignItems: 'end',
                            mt: !asMetricFlow ? 2 : undefined,
                            minHeight: (theme) => theme.size.defaultHeight,
                        }}
                    >
                        <Typography variant="heading" sx={{ flexGrow: 1 }}>
                            {t(
                                asMetricFlow
                                    ? 'title_alert_conditions_are_when'
                                    : 'title_send_when_alert_condition_is_met'
                            )}
                        </Typography>
                        {isSubscribedCount > 1 && (
                            <Button
                                sx={{ ml: 1.5 }}
                                variant={'light'}
                                startIcon={<IconMi icon="mail" />}
                                onClick={(event) => {
                                    showNotificationChangeBox(event);
                                }}
                                className={
                                    notificationChangeBoxAnchor && !notificationChangeBoxAlert ? 'active' : 'default'
                                }
                            >
                                {t('link_set_delivery_for_all_subscriptions')}
                            </Button>
                        )}
                    </Stack>
                    <Box sx={styles.arlWrapper}>
                        <Box sx={styles.arlItemsWrapper}>
                            {data.alerts.map((alert: any, index: number) => {
                                return (
                                    <AlertRuleListLineItem
                                        key={alert.id}
                                        alertRule={alert}
                                        segmentsInfo={data.segments}
                                        maxSegmentsInSegmentLine={maxSegmentsInSegmentLine}
                                        notificationLine={getNotificationLine(alert)}
                                        notificationLineForRole={getNotificationLineForRole(alert)}
                                        activeNotifyBox={
                                            isMarkChangeBoxIcon &&
                                            notificationChangeBoxAnchor &&
                                            notificationChangeBoxAlert?.id == alert.id
                                        }
                                        clickToShowSubscribed={(notificationLine) => {
                                            //showSubscribedToThisAlertPopup
                                            setSubscribedToThisAlertData({
                                                alertRuleId: alert.id,
                                                elementId: data.elementInfo.elementId,
                                                segmentId: data.elementInfo.segmentId,
                                                isReport: alert.is_report,
                                                notificationLine:
                                                    alert.subscribed == 'Y' ? notificationLine : undefined,
                                                userToAdd: alert.subscribed == 'Y' ? 0 : undefined,
                                            });
                                            setShowSubscribedToThisAlertPopup(true);
                                        }}
                                        clickToShowDimension={() => {
                                            //showSubscribedToThisAlertPopup
                                            setAlertForDimensionPopup(alert);
                                        }}
                                        onChangeSubscribe={(event) => {
                                            onUpdateAlertRuleData(
                                                {
                                                    ...alert,
                                                    subscribed: event.target.checked ? 'Y' : 'N',
                                                },
                                                'subscribed'
                                            );
                                        }}
                                        clickToShowNotifyBox={(event) => {
                                            showNotificationChangeBox(event, alert);
                                        }}
                                        clickGoToEdit={() => {
                                            navigateToEdit(alert);
                                        }}
                                        clickSubscribeToSegment={() => {
                                            onUpdateAlertRuleData(
                                                {
                                                    ...alert,
                                                    subscriptions: [
                                                        ...alert.subscriptions,
                                                        {
                                                            user_alert_rule: 0,
                                                            segment_value_id: data.segments.segment_value_id,
                                                            title: data.segments.title,
                                                        },
                                                    ],
                                                },
                                                'subscriptions'
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Box>
                        {asMetricFlow && <ControlLine />}
                    </Box>
                </>
            )}
            {showSubscribedToThisAlertPopup && subscribedToThisAlertData && (
                <SubscribedUsersPopup
                    onClose={() => {
                        setShowSubscribedToThisAlertPopup(false);
                    }}
                    {...subscribedToThisAlertData}
                />
            )}
            {alertForDimensionPopup && (
                <DimensionPopup
                    onClose={() => {
                        setAlertForDimensionPopup(null);
                    }}
                    onApply={(data) => {
                        let newVal = data.segments
                            .filter((item: any) => item.selected == 'Y')
                            .map((item: any) => {
                                return {
                                    user_alert_rule: 0,
                                    segment_value_id: item.segment_value_id,
                                    title: item.title,
                                };
                            });
                        if (data.radioType === 'all') {
                            newVal = [{ user_alert_rule: 0, segment_value_id: -1, title: '' }];
                        } else if (data.radioType === 'current') {
                            //current selected
                        }
                        if (newVal.length == 0) {
                            alert(t('alert_please_select_at_least_one_dimension'));
                            return false;
                        }

                        onUpdateAlertRuleData(
                            {
                                ...alertForDimensionPopup,
                                subscriptions: newVal,
                                subscribed: 'Y',
                            },
                            'subscriptions'
                        );

                        setAlertForDimensionPopup(null);
                    }}
                    elementId={data.elementInfo.elementId}
                    segmentId={data.elementInfo.segmentId}
                    alertRuleId={alertForDimensionPopup.id}
                    initialSelectedIds={alertForDimensionPopup.subscriptions.map(
                        (segment: any) => segment.segment_value_id
                    )}
                    initialClearSelected={true}
                />
            )}

            {notificationChangeBoxAnchor && (
                <AlertRuleNotificationChangeBox
                    alertRule={notificationChangeBoxAlert}
                    anchorElement={notificationChangeBoxAnchor}
                    mod={isMarkChangeBoxIcon ? 'itemIcon' : undefined}
                    notificationLineForRole={
                        notificationChangeBoxAlert ? getNotificationLineForRole(notificationChangeBoxAlert) : ''
                    }
                    onApply={(notificationData) => {
                        onNotificationChange(notificationData, notificationChangeBoxAlert);
                        setNotificationChangeBoxAnchor(null);
                    }}
                    onCancel={() => {
                        setNotificationChangeBoxAnchor(null);
                    }}
                />
            )}
        </Box>
    );
}
