import { alpha, Theme } from '@mui/material';

export default {
    visualPreviewWrapper: {
        position: 'relative',
        minHeight: '143px',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
    },
    visualPreviewHandler: { opacity: 0.4 },
    visualPreviewCover: {
        position: 'absolute',
        inset: '0 0',
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
    },
    visualPreviewCoverBtn: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        top: '50%',
    },
    container: {
        position: 'relative',
    },
    resizableComponent: {
        display: 'flex',
        flexDirection: 'column',
    },

    commandContainer: {
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
    },
    commandHeader: {
        flexShrink: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
        borderBottom: 0,
        '.isExpandedPanel &': {
            borderTopRightRadius: 0,
        },
    },
    commandContent: {
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        '.editor': {
            counterReset: 'line',
            /*border: 1px solid red;*/
        },
        '.editor #codeArea': {
            outline: 'none',
            paddingLeft: '56px !important',
        },
        '.editor pre': {
            paddingLeft: '56px !important',
            minHeight: '100%',
            display: 'block !important',
            flexGrow: 1,
            height: 'auto',
            backgroundColor: (theme: Theme) => `${alpha(theme.palette.text.primary, 0.04)} !important`,
            '.token.atrule, .token.attr-value, .token.keyword': {
                color: (theme: Theme) => theme.palette.primary.main,
                textShadow: '0px 0px 1px currentColor', //emulate fontWeight: 700 to reject bug with cursor FF
            },
            '.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string': {
                backgroundColor: 'transparent',
            },
        },
        '.editor pre:before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            height: '100%',
            width: '40px',
            backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        },
        '.editor .editorLineNumber': {
            position: 'absolute',
            left: 0,
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
            textAlign: 'right',
            width: '40px',
            fontWeight: 400,
            pr: 1,
        },
    },
    commandFooter: {
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        borderBottomRightRadius: '3px',
        borderBottomLeftRadius: '3px',
        borderTop: 0,
        p: 1,
        '.isExpandedPanel &': {
            borderBottomRightRadius: 0,
        },
    },
    editorComponent: {
        //pass styles to external plugin
        outline: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
    },
};
