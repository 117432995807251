import React, { useState, useContext } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Button } from '@mui/material';
import QueryBuilderFilterGridRow from 'components/plugin-query-builder/query-builder/QueryBuilderFilterGridRow';
import IconMi from 'components/common/icon/IconMi';
import QueryBuilderFilterPopup from 'components/plugin-query-builder/query-builder/QueryBuilderFilterPopup';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import styles from './QueryBuilderGrid.styles';

export interface QueryBuilderFilterGridProps {}

export default function QueryBuilderFilterGrid(props: QueryBuilderFilterGridProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { queryData, queryAct, reportAct } = useContext(PluginQBContext);

    const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);
    const [filterEditIndex, setFilterEditIndex] = useState<number | null>(null);

    /*
        var filters = [];
        var filterItem, i, j;
        for (i = 0, j = page.editor.query.filters.length; i < j; i++) {
            filterItem = page.editor.query.filters[i];
            if (filterItem && page.editor.report.hasField(filterItem.column)) {
                filters.push(page.editor.genFilterItem(filterItem));
            }
        }
    */

    const filtersData = queryData.filters.filter(
        (filterItem: any) => filterItem && reportAct.hasField(filterItem.column)
    );
    const isEmptyFilter = !filtersData.length;

    return (
        <Box>
            {isEmptyFilter ? (
                <Box sx={styles.emptyGridPlaceholder}>
                    <Box> {t('filter_popup.empty_filter_msg')}</Box>
                    <Button
                        startIcon={<IconMi icon="new" />}
                        variant={'light'}
                        onClick={() => {
                            setShowFilterPopup(true);
                        }}
                        sx={{ mt: 1 }}
                    >
                        {t('filter_popup.add_filter_btn')}
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Box sx={styles.filterGrid}>
                        <Box sx={styles.rowWrapper} className={'header'}>
                            <Box className={'cell cell--text cell--main'}>{t('filter_popup.grid_label_field')}</Box>
                            <Box className={'cell cell--text data--operator'}>
                                {t('filter_popup.grid_label_operator')}
                            </Box>
                            <Box className={'cell cell--text data--value'}>{t('filter_popup.grid_label_value')}</Box>
                            <Box className={'cell cell--actions'}></Box>
                        </Box>
                        <Box sx={styles.gridBody}>
                            {filtersData.map((item: any, index: number) => {
                                return (
                                    <QueryBuilderFilterGridRow
                                        key={item.column + item.condition + item.value}
                                        data={item}
                                        editItem={() => {
                                            setFilterEditIndex(index);
                                            setShowFilterPopup(true);
                                        }}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Button
                            startIcon={<IconMi icon="new" />}
                            variant={'light'}
                            onClick={() => {
                                setShowFilterPopup(true);
                            }}
                        >
                            {t('filter_popup.add_filter_btn')}
                        </Button>
                    </Box>
                </Box>
            )}
            {showFilterPopup && (
                <QueryBuilderFilterPopup
                    onClose={() => {
                        setShowFilterPopup(false);
                        setFilterEditIndex(null);
                    }}
                    onApply={(data) => {
                        setShowFilterPopup(false);
                        setFilterEditIndex(null);
                    }}
                    editElement={filterEditIndex !== null ? queryData.filters[filterEditIndex] : undefined}
                />
            )}
        </Box>
    );
}
