import { AssocArray, YNValues } from 'tools/types';
import { ConditionFormatting } from './components/dataset/edit-panel/condition-formatting';
import { ChartAxis } from 'components/chart';
import { IconType } from '../common/icon/IconHandler';
import { ReportContentActions } from 'app/editor/report';
import React from 'react';
import { ReportFilterSegmentValuesData } from 'components/report-content/hooks/useReportContentSettings';
import { GroupRule } from 'components/dataset-viewer';
import { FormComponentValue } from 'components/common/form/layout/control';
export type OrderDirection = 'ASC' | 'DESC';
export type DatasetFieldValueType = 'text' | 'numeric' | 'datetime';
export type TextAlignType = 'left' | 'center' | 'right';

export namespace ReportContentNS {
    export interface NewBlockContextType {
        datasetFields: Array<DatasetField>;
        nextSortOrder: number;
        nextUId: number;
    }

    export class AbstractBlockFactory<T extends ReportContentNS.ComponentSettings> {
        public getNewBlock(newBlockContext: NewBlockContextType): BlockType<T> {
            throw 'AbstractBlockFactory Should be extended by Regular Class';
        }
    }

    export abstract class AbstractComponentBuilder<T extends ReportContentNS.ComponentSettings> {
        constructor(
            protected contentSettings: ReportContentNS.ContentSettings,
            protected block: BlockType<T>,
            protected updateBlockSettings: (
                newSettings: ComponentSettings,
                options?: { shouldDirty?: boolean }
            ) => void,
            protected actions: ReportContentActions
        ) {}

        public abstract getComponent(editPanel?: any): any;
        public abstract getEditPanel(): any;
        public getComponentControls(): any {
            return null;
        }
    }
}

// Utils
export namespace ReportContentNS {
    export function generateMeasureFieldId(): string {
        return Date.now().toString(36) + Math.random().toString(36);
    }

    export function fieldToChartMeasuredField(
        field: ReportContentNS.DatasetField
    ): ReportContentNS.ComponentSettingsChartMeasureField {
        const aggregations = ReportContentNS.AllowedMeasureFieldAggregations.filter(
            (f) => f.value_type == field.value_type
        );

        // TODO:
        return {
            dataset_column_id: field.dataset_column_id,
            dataset_filter_column_id: field.dataset_filter_column_id,
            dataset_filter_id: field.dataset_filter_id,
            dataset_id: field.dataset_id,
            aggregation_function: aggregations.length ? aggregations[0].name : '',
            color: '',
            label: field.reference_name,
            column_name: field.column_name,
            display_mask_id: field.display_mask_id ?? '0',
            display_type: 'line',
            reference_name: field.reference_name,
            reference_name_escaped: field.reference_name_escaped,
            uId: generateMeasureFieldId(),
            value_type: field.value_type,
            y_axis: 'left',
        };
    }

    export function getFirstAllowedFieldAsMeasure<T extends ReportContentNS.MeasureField>(
        datasetFields: Array<ReportContentNS.DatasetField>,
        measureFields: Array<T>,
        fieldToMeasuredField: (datasetField: DatasetField) => T
    ): T | false {
        const allowedField = datasetFields.filter(
            (datasetField) =>
                !measureFields || measureFields.findIndex((f) => f.reference_name == datasetField.reference_name) == -1
        );

        if (allowedField.length == 0) {
            return false;
        }

        // Try to get first numeric field, if there is no numeric fields, take first
        let field = allowedField.find((f) => f.value_type == 'numeric');
        if (!field) {
            field = allowedField[0];
        }
        return fieldToMeasuredField(field);
    }
}

// Block Context
export namespace ReportContentNS {
    export interface IBlocksContext {
        manageFiltersSettings: ComponentSettingsManageFilters | null;
        // Top Filters State (Used by other Components)
        setFilters: (rules: any) => void;
        filters: any;
        // Visualization extVizString (Used by Dataset)
        setExtViz: (extViz: ComponentSettingsExternalVisualization | null) => void;
        extViz: ComponentSettingsExternalVisualization | null;
        setManageFiltersReady: (ready: boolean) => void;
        manageFiltersReady: boolean;
    }
    export const BlocksContext = React.createContext<IBlocksContext | null>(null);
}

// Content global types
export namespace ReportContentNS {
    export type AllowedComponentSectionType = 'chart' | 'table' | 'other';
    export type ContentMode = 'view' | 'edit';

    export interface ContentSettings {
        elementId: number;
        segmentValueId: number;
        context: ContentMode;
        pageSize: number;
        applyAccessMap: YNValues;
        instances: Array<ManageFiltersInstance>;
        hasInstances: boolean;
        segmentValues: ReportFilterSegmentValuesData;
        measurement_time?: string;
        forScreenShot: boolean;
        displayMasks: Array<DisplayMask>;
    }
    export interface ComponentSettings {
        title: string;
        height: number;
        show_in_viewer: YNValues;
        is_preview_ind: YNValues;
        include_in_email: YNValues;
        include_in_email_attachment: YNValues;
        isOnCreation?: boolean;
        internal_name: ComponentsType;
        is_collapsed_ind: YNValues;
        groupFilter: GroupRule;
        hasNoData?: boolean;
    }

    export interface ChartComponentSettings extends ComponentSettings {
        dataQueries: ComponentSettingsChartDataQueries;
    }

    export type ComponentsType =
        | 'manage_filters'
        | 'bubble_chart'
        | 'dataset'
        | 'chart'
        | 'external_visualization'
        | 'funnel_chart'
        | 'jct_31'
        | 'kpi'
        | 'line'
        | 'map_chart'
        | 'pivot'
        | 'range_chart'
        | 'text_block';

    export interface AllowedComponentType {
        datasetRendererComponentId: number;
        // datasetReportDescription: null;
        displayOrder: number;
        faIconName?: string;
        iconInd?: string;
        image: string;
        internalName: ComponentsType;
        isEnabledInd: true;
        isSystemInd: true;
        // javascriptChartTemplateId: null;
        name: string;
        section: AllowedComponentSectionType;
        settings: any;
    }

    export interface BlockComponent<T extends ComponentSettings> {
        datasetRendererBlockComponentId: number;
        datasetRendererBlockId: number;
        datasetRendererComponentId: number;
        displayOrder: number;
        internalName: ComponentsType;
        // javascriptChartTemplateId: null;
        settings: T;
    }

    export interface BlockType<T extends ComponentSettings> {
        component: BlockComponent<T>;
        // datasetRendererBlockId: number;
        displayOrder: number;
        // elementId: number;
        uid: string;
    }

    export interface ComponentProps<T extends ComponentSettings> {
        component: BlockComponent<T>;
        contentSettings: ContentSettings;
        actions: ReportContentActions;
        editPanel?: any;
        blockId?: string;
    }

    export interface ComponentUpdateProps<T extends ComponentSettings> extends ComponentProps<T> {
        updateBlockSettings: (newSettings: T, options?: { shouldDirty?: boolean }) => void;
    }

    export interface DatasetField {
        // aggregate_function: '';
        // column_instance: 'current';
        column_name: string;
        column_sequence: number;
        dataset_column_id: number;
        dataset_filter_column_id: number;
        dataset_filter_id: number;
        dataset_id: number;
        display_mask_id: string | null;
        // is_derived_ind: 'N';
        // is_result_ind: 'Y';
        name: string;
        reference_name: string;
        reference_name_escaped: string;
        value_type: DatasetFieldValueType;
        label?: string;
    }

    export type RendererFilterType = 'dropdown' | 'charting_interval' | 'range' | 'datetime' | 'multiselect';

    export interface RendererFilter extends DatasetField {
        filter_column: YNValues;
        include_all_value?: YNValues;
        filterType?: RendererFilterType;
        filter_label?: string;
        sortDirection: OrderDirection;
        show_date_only?: YNValues;
        column_sequence: number;
        savedValues?: Array<any>;
        label?: string;
        reference_name_escaped: string;
    }

    export interface ViewerData {
        rendererBlocks: Array<BlockType<ComponentSettings>>;
        rendererFilters: ComponentSettingsManageFilters;
        datasetFields: Array<DatasetField>;
    }

    export interface EditorData extends ViewerData {
        allowedComponents: AssocArray<AllowedComponentType>;
        displayMasks: Array<DisplayMask>;
    }

    export interface MeasureField {
        aggregation_function: string;
        column_name: string;
        reference_name: string;
        value_type: DatasetFieldValueType;
    }

    export interface SimpleFormattingRule {
        condition: string;
        data: string;
        bgColor?: string;
        data_second?: string;
        textBold?: boolean;
        textColor?: string;
        textItalic?: boolean;
    }

    export interface IFormattingRuleConditions {
        key: string;
        name: string;
        value_type: 'numeric' | 'text';
    }
    export const FormattingRuleConditions: Array<IFormattingRuleConditions> = [
        { key: '>', name: 'label_greater_than', value_type: 'numeric' },
        { key: '>=', name: 'label_greater_than_or_equal', value_type: 'numeric' },
        { key: '=', name: 'label_equals', value_type: 'numeric' },
        { key: '!=', name: 'label_does_not_equal', value_type: 'numeric' },
        { key: '<', name: 'label_less_than', value_type: 'numeric' },
        { key: '<=', name: 'label_less_than_or_equal', value_type: 'numeric' },
        { key: 'between', name: 'label_between', value_type: 'numeric' },
        { key: '=', name: 'label_exactly_matches', value_type: 'text' },
        { key: '!=', name: 'label_does_not_equal', value_type: 'text' },
        { key: 'contains', name: 'label_contains', value_type: 'text' },
        { key: 'does not contain', name: 'label_does_not_contain', value_type: 'text' },
    ];

    export const AggregationMethods: Array<FormComponentValue> = [
        { value: 'Sum', label: 'label_sum' },
        { value: 'Min', label: 'label_min' },
        { value: 'Max', label: 'label_max' },
        { value: 'Avg', label: 'label_avg' },
        { value: 'Count', label: 'label_count' },
        { value: 'Count Distinct', label: 'label_count_distinct' },
    ];

    export interface DisplayMask {
        display_mask_id: string;
        display_example: string;
        mask_description: string;
        currency_mask_ind?: YNValues;
        // currency_id: null;
        date_format_string: string;
        // decimal_point_delimiter: '.';
        // default_float_mask_ind: 'N';
        // default_int_mask_ind: 'N';
        // display_precision_digits: 1;
        // last_updated_by: null;
        // last_updated_time: '2016-08-26 23:04:52';
        mask_type: 'numeric' | 'date';
        // prefix: null;
        // show_amount_as: 'Raw Number';
        // show_calendar_quarter_ind: null;
        // suffix: '%';
        // thousands_delimiter: null;
    }
}

// Table like (dataset component)
export namespace ReportContentNS {
    export class BlockFactoryDataset extends ReportContentNS.AbstractBlockFactory<ComponentSettingsDataset> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsDataset> {
            const fields: Array<ComponentSettingsDatasetField> = datasetFields.map((f) => {
                return {
                    columnSize: 200,
                    aggregation_method: '',
                    column_name: f.column_name,
                    dataset_column_id: Number(f.dataset_column_id),
                    dataset_filter_column_id: Number(f.dataset_filter_column_id),
                    dataset_filter_id: Number(f.dataset_filter_id),
                    dataset_id: Number(f.dataset_id),
                    display_mask_id: f.display_mask_id,
                    field: f.column_name,
                    id: f.reference_name,
                    reference_name: f.reference_name,
                    reference_name_escaped: f.reference_name_escaped,
                    show_column_in_table_display_ind: 'Y',
                    value_type: f.value_type,
                    override_header_ind: 'N',
                    label: f.label,
                    break_column: 'N',
                    group_column: 'N',
                    compute_result_total_ind: 'Y',
                };
            });
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'dataset',
                    settings: {
                        uid: uid,
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        is_preview_ind: 'N',
                        is_collapsed_ind: 'N',
                        include_in_email: 'Y',
                        include_in_email_attachment: 'Y',
                        autoStretch: true,
                        internal_name: 'dataset',
                        breakColumns: [],
                        breakArea: false,
                        groupColumns: [],
                        groupingArea: false,
                        report_index_preview_type: 'dataset',
                        report_multiple_units_label: 'rows',
                        report_no_units_label: 'No rows',
                        report_single_unit_label: 'row',
                        fields: fields,
                        fixedColumns: 0,
                        formationRulesData: [],
                        headerFormatting: {
                            textBold: true,
                        },
                        limit_total_rows: 'N',
                        mainFont: AllowedFonts[0].value,
                        mainFontSize: 12,

                        show_top_n_series: 1,
                        show_totals: 'N',
                        sortFields: [],
                        totalsSettings: {
                            textBold: true,
                        },
                        totals_label: 'Overall Total',
                        groupFilter: { operator: 'AND', rules: [] },
                        zebra_striping: 'Y',
                        showHiddenFields: 'N',
                        isOnCreation: true,
                        extViz: {
                            link_to_dataset: 'N',
                            filters: [],
                            report_ext_vis_applicable_scope: 'row',
                            report_ext_vis_element_id: 0,
                            report_max_viz_number_to_display: 0,
                            report_refer_from_email_to: 'self',
                            report_ext_vis_images_in_email: 0,
                            dataset_block_uid: '',
                        },
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface FontType {
        value: string;
        label: string;
    }
    export const AllowedFonts: Array<FontType> = [
        {
            value: 'font-arial',
            label: 'Arial, Helvetica, sans-serif',
        },
        {
            value: 'font-georgia',
            label: 'Georgia, serif',
        },
        {
            value: 'font-tahoma',
            label: 'Tahoma, Geneva, sans-serif',
        },
        {
            value: 'font-times',
            label: 'Times New Roman, Times, serif',
        },
        {
            value: 'font-verdana',
            label: 'Verdana, Geneva, sans-serif',
        },
    ];
    export const AllowedFontSizes: Array<number> = [10, 11, 12, 13, 14, 16, 18];

    export type DataRowType = AssocArray<string | number | null>;

    export type PivotDataRowType = Array<PivotCellData>;
    export interface PivotCellData {
        field: string;
        value: string | number | null;
    }
    export interface AdditionalContentSettingsDataset extends ContentSettings {
        page: number;
        getAmount: YNValues;
        getTotals: YNValues;
    }

    export interface DatasetInitialDataResponse {
        '0': { cnt: number };
        totals: { '0': DataRowType };
    }

    export interface FieldTextFormatting {
        bgColor?: string;
        textWrap?: boolean;
        textBold?: boolean;
        textItalic?: boolean;
        textColor?: string;
        textAlign?: TextAlignType;
        applyTo?: string | -1;
    }

    export interface ComponentSettingsDatasetField extends FieldTextFormatting {
        // aggregate_function: '';
        // aggregation: false;
        aggregation_method: string;
        columnSize: number;
        // column_instance: 'current';
        column_name: string;
        // column_sequence: 0;
        compute_result_total_ind: YNValues;
        dataset_column_id: number;
        dataset_filter_column_id: number;
        dataset_filter_id: number;
        dataset_id: number;
        display_mask_id: string | null;
        field: string;
        id: string;
        // instance: 'current';
        // is_derived_ind: 'N';
        // is_result_ind: 'Y';
        // key: 'calendar_date-current';
        // label: 'calendar_date';
        // label_new: 'calendar_date';
        // name: 'calendar_date';
        // ref_column_name: 'calendar_date';
        transform_label?: string;
        reference_name: string;
        reference_name_escaped: string;
        show_column_in_table_display_ind: YNValues;
        value_type: DatasetFieldValueType;
        override_header_ind?: YNValues;
        label?: string;
        break_column: YNValues;
        group_column: YNValues;
    }

    export interface ComponentSettingsDatasetHeader extends FieldTextFormatting {
        value_type?: string;
    }

    export interface ComponentSettingsDatasetSortField {
        column_name: string;
        dir: OrderDirection;
        reference_name: string;
        reference_name_escaped: string;
        value_type: DatasetFieldValueType;
    }

    export interface ComponentSettingsDatasetExtViz {
        link_to_dataset: YNValues | 'D';
        filters: Array<ComponentSettingsExternalVisualizationMapColumnFilter>;
        report_ext_vis_element_id: number;
        report_ext_vis_applicable_scope: 'row' | 'once';
        // report_ext_vis_display: string;
        report_max_viz_number_to_display: number;
        report_ext_vis_images_in_email: number;
        // report_ext_vis_iframe_height: number;
        report_refer_from_email_to: string;
        dataset_block_uid: string;
    }

    export interface ComponentSettingsTableLike extends ComponentSettings {
        uid: string;
        autoStretch: boolean;
        // breakArea: false;
        // breakColumns: [];
        // component_name: 'Table';
        fields: Array<ComponentSettingsDatasetField>;
        mainFont: string;
        mainFontSize: number;
        zebra_striping: YNValues;
    }

    export interface ComponentSettingsDataset extends ComponentSettingsTableLike {
        fixedColumns: number;
        formationRulesData: Array<ConditionFormatting>;
        // formattedFields: [];
        groupColumns: [] | [number, number];
        groupingArea: boolean;
        breakColumns: [] | [number, number];
        breakArea: boolean;
        headerFormatting: ComponentSettingsDatasetHeader;
        // internal_name: 'dataset';
        // isAdditionalAggregation: false;
        // is_preview_ind: 'Y';
        limit_total_rows: YNValues;
        // no_data_label: 'No Data';
        // no_data_label_settings: { textAlign: 'left' };
        report_index_preview_type: string;
        report_multiple_units_label: string;
        report_no_units_label: string;
        report_single_unit_label: string;
        // showHiddenFields: 'N';
        // show_title: 'N';
        show_top_n_series: number;
        show_totals: YNValues;
        sortFields: Array<ComponentSettingsDatasetSortField>;
        totalsSettings: FieldTextFormatting;
        totals_label: string;
        zebra_striping: YNValues;
        showHiddenFields: YNValues;
        currentInnerWidth?: number;
        extViz: ComponentSettingsDatasetExtViz;
    }

    export interface ComponentSettingsPivotUIField {
        columnSize: number;
        column_sequence: string | number;
        field_type: string;
        reference_name: string | number;
        bgColor?: string;
        textWrap?: boolean;
        textBold?: boolean;
        textItalic?: boolean;
        textColor?: string;
        textAlign?: TextAlignType;
    }

    export interface ComponentSettingsPivot extends ComponentSettingsTableLike {
        // colorsData: [];
        columnFields: Array<ComponentSettingsPivotField>;
        // columnHidden: [];
        columnTotalsSettings: FieldTextFormatting;
        column_total_label: string;
        column_total_placement: 'bottom' | 'top';
        // component_name: 'Pivot Table';
        dataQueries: ComponentSettingsChartDataQueries;
        defaultEmptyValue: string | number;
        // fields: [];
        fixedColumns: number;
        // height: 600;
        // include_in_email: 'Y';
        // include_in_email_attachment: 'Y';
        // internal_name: 'pivot';
        // is_first_apply: true;
        // is_preview_ind: 'Y';
        // mainFont: 'font-arial';
        // mainFontSize: '12';
        measureFields: Array<ComponentSettingsPivotMeasureField>;
        // no_data_label: 'No Data';
        // no_data_label_settings: { textAlign: 'left' };
        // report_multiple_units_label: 'rows';
        // report_no_units_label: 'No rows';
        // report_single_unit_label: 'row';
        rowFields: Array<ComponentSettingsPivotField>;
        rowTotalsSettings: FieldTextFormatting;
        show_column_totals: YNValues;
        show_column_totals_as_percent: YNValues;
        show_row_totals: YNValues;
        show_row_totals_as_percent: YNValues;
        row_total_label: string;
        // show_title: 'N';
        uiFields: Array<ComponentSettingsPivotUIField>;
        currentInnerWidth?: number;
        sortFields: Array<ComponentSettingsDatasetSortField>;
    }
}

// Pivot
export namespace ReportContentNS {
    export interface ComponentSettingsPivotField extends ComponentSettingsDatasetField {
        sortDirection: OrderDirection;
        headerSettings: FieldTextFormatting;
        subtotalSettings: FieldTextFormatting;
        compute_result_total_ind: YNValues;
        sub_total_label: string;
        sub_total_placement: 'top' | 'bottom';
        formattingRules: Array<SimpleFormattingRule>;
        type:
            | 'total'
            | 'row_subtotal'
            | 'column_subtotal'
            | 'column_total'
            | 'row_total'
            | 'measure'
            | 'ui'
            | 'row'
            | 'column';
    }

    export class BlockFactoryPivot extends ReportContentNS.AbstractBlockFactory<ComponentSettingsPivot> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsPivot> {
            const fields: Array<ComponentSettingsDatasetField> = datasetFields.map((f) => {
                return {
                    columnSize: 200,
                    column_name: f.column_name,
                    aggregation_method: '',
                    dataset_column_id: Number(f.dataset_column_id),
                    dataset_filter_column_id: Number(f.dataset_filter_column_id),
                    dataset_filter_id: Number(f.dataset_filter_id),
                    dataset_id: Number(f.dataset_id),
                    display_mask_id: f.display_mask_id,
                    field: f.column_name,
                    id: f.reference_name,
                    reference_name: f.reference_name,
                    reference_name_escaped: f.reference_name_escaped,
                    show_column_in_table_display_ind: 'Y',
                    value_type: f.value_type,
                    override_header_ind: 'N',
                    break_column: 'N',
                    group_column: 'N',
                    compute_result_total_ind: 'Y',
                };
            });

            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'pivot',
                    settings: {
                        defaultEmptyValue: '',
                        is_preview_ind: 'N',
                        is_collapsed_ind: 'N',
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        include_in_email: 'Y',
                        include_in_email_attachment: 'Y',
                        autoStretch: true,
                        internal_name: 'pivot',
                        fields: fields,
                        mainFont: ReportContentNS.AllowedFonts[0].value,
                        mainFontSize: 12,
                        zebra_striping: 'Y',
                        isOnCreation: true,
                        rowFields: [],
                        columnFields: [],
                        uiFields: [],
                        measureFields: [],
                        sortFields: [],
                        column_total_label: 'Overall Total',
                        column_total_placement: 'bottom',
                        show_column_totals: 'Y',
                        show_column_totals_as_percent: 'N',
                        row_total_label: 'Total',
                        show_row_totals: 'Y',
                        show_row_totals_as_percent: 'N',
                        uid: 'block_uid_' + nextUId,
                        columnTotalsSettings: {
                            textBold: true,
                            textAlign: 'right',
                        },
                        rowTotalsSettings: {
                            textBold: true,
                            textAlign: 'right',
                        },
                        fixedColumns: 0,
                        currentInnerWidth: 0,
                        groupFilter: { operator: 'AND', rules: [] },
                        dataQueries: {
                            table: {
                                resultFieldAggregations: {},
                                resultFields: [],
                                sort: [],
                            },
                        },
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: 'block_uid_' + nextUId,
            };
        }
    }

    export type PivotInitialDataResponse = Array<Array<PivotDataCell>>;
    export interface PivotDataCell {
        reference_name: string;
        shift: number;
        colspan?: number;
        style: PivotDataCellStyle;
        type:
            | 'total'
            | 'row_subtotal'
            | 'column_subtotal'
            | 'column_total'
            | 'row_total'
            | 'measure'
            | 'ui'
            | 'row'
            | 'column';
        uid: string;
        value: string | number;
        'value-raw': string | number;
    }
    export interface PivotDataCellStyle {
        columnWidth?: number;
        bgColor?: string;
        textColor?: string;
        textAlign?: TextAlignType;
        textWrap?: boolean;
        [key: string]: any;
    }

    export interface ComponentSettingsPivotMeasureField {
        dataset_column_id: number;
        dataset_filter_column_id: number;
        dataset_filter_id: number;
        dataset_id: number;
        aggregation_function: string;
        display_mask_id: string;
        display_type: string;
        reference_name: string;
        reference_name_escaped: string;
        headerSettings: FieldTextFormatting;
        subtotalSettings: FieldTextFormatting;
        // dataset_filter_column_id: number;
        // dataset_filter_id: number;
        // dataset_column_id: number;
        // dataset_id: number;
        // is_result_ind: YNValues;
        // aggregate_function: string;
        column_name: string;
        // reference_name: 'sales';
        value_type: DatasetFieldValueType;
        // column_instance: 'current';
        // is_derived_ind: YNValues;
        // display_mask_id: '0';
        // column_sequence: 3;
        // name: 'sales';
        // reference_name_escaped: 'sales';
        // id: string;
        // field: 'sales';
        // instance: 'current';
        // aggregation: false;
        label: string;
        // label_new: 'sales';
        // key: 'sales-current';
        // ref_column_name: 'sales';
        sortDirection: OrderDirection;
        sub_total_label: string;
        compute_result_total_ind: YNValues;
        formattingRules: Array<SimpleFormattingRule>;
        // aggregation_function: 'Sum';
        bgColor?: string;
        textWrap?: boolean;
        textBold?: boolean;
        textItalic?: boolean;
        textColor?: string;
        textAlign?: TextAlignType;
    }

    export function fieldToPivotMeasuredField(
        field: ReportContentNS.DatasetField
    ): ReportContentNS.ComponentSettingsPivotMeasureField {
        const aggregations = ReportContentNS.AllowedMeasureFieldAggregations.filter(
            (f) => f.value_type == field.value_type
        );

        // TODO:
        return {
            dataset_column_id: field.dataset_column_id,
            dataset_filter_column_id: field.dataset_filter_column_id,
            dataset_filter_id: field.dataset_filter_id,
            dataset_id: field.dataset_id,
            aggregation_function: aggregations.length ? aggregations[0].name : '',
            display_mask_id: field.display_mask_id ?? '0',
            display_type: '',
            reference_name: field.reference_name,
            reference_name_escaped: field.reference_name_escaped,
            column_name: field.column_name,
            value_type: field.value_type,
            headerSettings: {},
            subtotalSettings: {},
            sortDirection: 'ASC',
            compute_result_total_ind: 'Y',
            sub_total_label: 'Total',
            label: '',
            formattingRules: [],
            // uId: generateMeasureFieldId(),
            // value_type: field.value_type,
            // y_axis: 'left',
        };
    }
}

// Line Chart
export namespace ReportContentNS {
    export class BlockFactoryLineChart extends ReportContentNS.AbstractBlockFactory<ComponentSettingsLineChart> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsLineChart> {
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'line',
                    settings: {
                        uid: uid,
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        is_preview_ind: 'N',
                        include_in_email: 'Y',
                        is_collapsed_ind: 'N',
                        include_in_email_attachment: 'Y',
                        internal_name: 'line',
                        groupFilter: { operator: 'AND', rules: [] },
                        bar_order: 'alphabet',
                        bar_orientation: 'vertical',
                        colorFormationRulesData: [],
                        combine_tooltips: 'Y',
                        fillOpacity: 0.75,
                        queryAgainstEntireDataset: 'N',

                        customYAxis: 'N',
                        dataQueries: {
                            table: {
                                resultFieldAggregations: {},
                                resultFields: [],
                                sort: [],
                            },
                        },

                        display_label: 'N',

                        measureFields: [],

                        merge_small_label: 'Other',
                        minY: null,
                        maxY: null,

                        series: 'single',
                        show_all_series: 'Y',

                        stacking: 'none',
                        group_field: '',
                        system_other_grouping_label: '__system_other_grouping',
                        show_top_n_dir: 'DESC',
                        show_top_n_series: 5,

                        x_axis: {
                            field: '',
                            title: '',
                            value_type: 'numeric',
                            defaultZoom: '',
                            display_mask_id: '0',
                        },
                        y_axis: [
                            { field: '', title: '', value_type: 'numeric', defaultZoom: '', display_mask_id: '0' },
                        ],
                        isOnCreation: true,
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface LineChartInitialDataResponse {
        dataResults: { [key: string]: [AssocArray<string>]; table: [AssocArray<string>] };
    }

    export interface AdditionalContentSettingsLineChart extends ContentSettings {
        page: number;
    }

    export interface MeasureFieldDisplayType {
        name: string;
        label: string;
        icon: IconType;
    }

    export const AllowedMeasureFieldDisplayTypes: Array<MeasureFieldDisplayType> = [
        { name: 'line', label: 'Line', icon: { type: 'mi', value: 'line-chart-with-arrow' } },
        { name: 'column', label: 'Bar', icon: { type: 'mi', value: 'bar-chart' } },
        { name: 'area', label: 'Area', icon: { type: 'mi', value: 'line-chart' } },
    ];

    export interface MeasureFieldAggregationType {
        name: string;
        label: string;
        value_type: DatasetFieldValueType;
    }

    export type BarOrderType = 'alphabet' | 'lowest' | 'highest';
    export type BarOrientationType = 'horizontal' | 'vertical';
    export type LineChartSeriesType = 'single' | 'multiple';

    export const AllowedMeasureFieldAggregations: Array<MeasureFieldAggregationType> = [
        { label: 'Sum', name: 'Sum', value_type: 'numeric' },
        { label: 'Avg', name: 'Avg', value_type: 'numeric' },
        { label: 'Min', name: 'Min', value_type: 'numeric' },
        { label: 'Max', name: 'Max', value_type: 'numeric' },
        { label: 'Count', name: 'Count', value_type: 'numeric' },
        { label: 'Count Distinct', name: 'Count Distinct', value_type: 'numeric' },
        { label: 'Count', name: 'Count', value_type: 'text' },
        { label: 'Count Distinct', name: 'Count Distinct', value_type: 'text' },
        { label: 'Count', name: 'Count', value_type: 'datetime' },
        { label: 'Count Distinct', name: 'Count Distinct', value_type: 'datetime' },
        { label: 'Min', name: 'Min', value_type: 'datetime' },
        { label: 'Max', name: 'Max', value_type: 'datetime' },
    ];

    export interface ComponentSettingsChartMeasureField {
        uId: string;
        // aggregate_function: '';
        // aggregation: false;
        aggregation_function: string;
        color: string;
        // column_instance: 'current';
        column_name: string;
        // column_sequence: 3;
        dataset_column_id: number | null;
        dataset_filter_column_id: number | null;
        dataset_filter_id: number | null;
        dataset_id: number | null;
        display_mask_id: string;
        display_type?: string;
        // field: 'sales';
        // field_type: 'measure';
        // id: 'sales';
        // instance: 'current';
        // is_derived_ind: 'N';
        // is_result_ind: 'Y';
        // key: 'sales-current';
        label: string;
        // label_new: 'sales';
        // name: 'sales';
        // ref_column_name: 'sales';
        reference_name: string;
        reference_name_escaped: string;
        value_type: DatasetFieldValueType;
        y_axis: ChartAxis;
    }

    export interface ComponentSettingsChartDataQueriesTable {
        resultFieldAggregations: AssocArray<string>;
        resultFields: Array<string>;
        sort?: Array<{ field: string; dir: OrderDirection }>;
        sortFields?: Array<{ field: string; dir: OrderDirection }>;
        top?: {
            field: string;
            count: number;
            label: string;
            sort_field?: string;
            dir: OrderDirection;
        };
        group?: {
            operator: 'AND' | 'OR';
            rules: Array<{
                field: string;
                condition: string;
                data: string;
            }>;
        };
        distinct?: boolean;
    }

    export interface ComponentSettingsChartDataQueries {
        [key: string]: ComponentSettingsChartDataQueriesTable;
        table: ComponentSettingsChartDataQueriesTable;
    }

    export interface ComponentSettingsLineChartAxis {
        field: string;
        title: string;
        value_type: DatasetFieldValueType;
        defaultZoom: string;
        display_mask_id: string;
    }

    export interface ComponentSettingsLineChart extends ChartComponentSettings {
        uid: string;
        bar_order: BarOrderType;
        bar_orientation: BarOrientationType;
        colorFormationRulesData: Array<any>;
        combine_tooltips: YNValues;
        // component_name: 'Line/Bar/Area';
        customYAxis: YNValues;

        display_label: YNValues;
        fillOpacity: number;
        // groupFilter: { operator: 'AND'; rules: [] };

        // internal_name: 'line';
        // is_preview_ind: 'N';
        // localPalette: {};

        measureFields: Array<ComponentSettingsChartMeasureField>;
        // merge_small_color: '#0062fe';
        merge_small_label: string;
        minY: number | null;
        maxY: number | null;
        queryAgainstEntireDataset: YNValues;
        // report_multiple_units_label: 'rows';
        // report_no_units_label: 'No rows';
        // report_single_unit_label: 'row';
        series: LineChartSeriesType;
        show_all_series: YNValues;
        // show_title: 'N';
        show_top_n_dir: OrderDirection;
        show_top_n_series: number;
        stacking: string;
        group_field?: string;
        system_other_grouping_label: string;
        x_axis: ComponentSettingsLineChartAxis;
        y_axis?: Array<ComponentSettingsLineChartAxis>;
    }
}

// Pie Chart
export namespace ReportContentNS {
    export class BlockFactoryPieChart extends ReportContentNS.AbstractBlockFactory<ComponentSettingsPieChart> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsPieChart> {
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'chart',
                    settings: {
                        uid: uid,
                        internal_name: 'chart',
                        // component_name: 'Pie',
                        groupFilter: { operator: 'AND', rules: [] },
                        dataQueries: {
                            table: {
                                resultFieldAggregations: {},
                                resultFields: [],
                                sort: [],
                            },
                        },

                        measureFields: [],

                        group_field: 'channel',
                        title: '',
                        show_title: 'N',
                        height: 600,
                        show_in_viewer: 'Y',
                        is_preview_ind: 'N',
                        include_in_email: 'Y',
                        // is_preview_ind: 'Y',
                        include_in_email_attachment: 'Y',
                        is_collapsed_ind: 'N',
                        localPalette: {},
                        display_value: 'N',
                        display_value_total: 'N',
                        merge_small_sections: 'N',
                        merge_small_limit: 5,
                        system_other_grouping_label: '__system_other_grouping',
                        merge_small_label: 'Other',
                        merge_small_color: '#ed6a6a',
                        // colorFormationRulesData: [
                        // {
                        //     "field_value": "corporate sales",
                        //     "text": "corporate sales",
                        //     "value_position": 3,
                        //     "color": "#4ebe50",
                        //     "defaultColor": "#4ebe50"
                        // },
                        // ],
                        report_no_units_label: 'No exceptions',
                        report_single_unit_label: 'exception',
                        report_multiple_units_label: 'exceptions',
                        isOnCreation: true,
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface ComponentSettingsPieChart extends ChartComponentSettings {
        uid: string;
        // component_name: 'Pie';
        measureFields: Array<ComponentSettingsChartMeasureField>;
        group_field: string;
        show_title: YNValues;
        localPalette: AssocArray<string>;
        display_value: YNValues;
        display_value_total: YNValues;
        merge_small_sections: YNValues;
        merge_small_limit: number;
        system_other_grouping_label: string;
        merge_small_label: string;
        merge_small_color: string;
        // colorFormationRulesData: [
        // {
        //     "field_value": "corporate sales",
        //     "text": "corporate sales",
        //     "value_position": 3,
        //     "color": "#4ebe50",
        //     "defaultColor": "#4ebe50"
        // },
        // ],
        report_no_units_label: 'No exceptions';
        report_single_unit_label: 'exception';
        report_multiple_units_label: 'exceptions';
    }
}

// Bubble Chart
export namespace ReportContentNS {
    export class BlockFactoryBubbleChart extends ReportContentNS.AbstractBlockFactory<ComponentSettingsBubbleChart> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsBubbleChart> {
            const uid = 'block_uid_' + nextUId;
            const fieldNumericField = datasetFields.find((f) => f.value_type == 'numeric');
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'bubble_chart',
                    settings: {
                        uid: uid,
                        internal_name: 'bubble_chart',
                        // component_name: 'Pie',

                        dataQueries: {
                            table: {
                                resultFieldAggregations: {},
                                resultFields: [],
                                sort: [],
                            },
                        },

                        component_name: 'Bubble',
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        include_in_email: 'Y',
                        // is_preview_ind: 'Y',
                        include_in_email_attachment: 'Y',
                        is_collapsed_ind: 'N',
                        // localPalette: {},
                        groupFilter: { operator: 'AND', rules: [] },
                        //@ts-ignore
                        name_field: datasetFields[0],
                        //@ts-ignore
                        x_field: fieldNumericField ?? datasetFields[0],
                        //@ts-ignore
                        y_field: fieldNumericField ?? datasetFields[0],
                        //@ts-ignore
                        value_field: datasetFields[0],
                        display_value: 'N',
                        display_label: 'Y',
                        x_axis_plot_intermediate_values: 'Y',
                        y_axis_plot_intermediate_values: 'Y',
                        formattingRules: [],
                        report_no_units_label: 'No exceptions',
                        report_single_unit_label: 'exception',
                        report_multiple_units_label: 'exceptions',

                        isOnCreation: true,
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface ComponentSettingsBubbleChart extends ChartComponentSettings {
        uid: string;
        component_name: 'Bubble';
        name_field: ComponentSettingsChartMeasureField;
        x_field: ComponentSettingsChartMeasureField;
        y_field: ComponentSettingsChartMeasureField;
        value_field: ComponentSettingsChartMeasureField;
        display_value: YNValues;
        display_label: YNValues;
        x_axis_plot_intermediate_values: YNValues;
        y_axis_plot_intermediate_values: YNValues;
        formattingRules: Array<SimpleFormattingRule>;
        report_no_units_label: 'No exceptions';
        report_single_unit_label: 'exception';
        report_multiple_units_label: 'exceptions';
    }
}

// Funnel Chart
export namespace ReportContentNS {
    export class BlockFactoryFunnelChart extends ReportContentNS.AbstractBlockFactory<ComponentSettingsFunnelChart> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsFunnelChart> {
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'funnel_chart',
                    settings: {
                        uid: uid,
                        internal_name: 'funnel_chart',
                        dataQueries: {
                            table: {
                                resultFieldAggregations: {},
                                resultFields: [],
                                sort: [],
                            },
                        },
                        localPalette: {},
                        component_name: 'Funnel',
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        include_in_email: 'Y',
                        // is_preview_ind: 'Y',
                        include_in_email_attachment: 'Y',
                        is_collapsed_ind: 'N',
                        //@ts-ignore
                        value_field: datasetFields[0],
                        //@ts-ignore
                        x_field: datasetFields[0],
                        sort_field: { by_field: 'value', dir: 'DESC', value_type: 'text' },
                        groupFilter: { operator: 'AND', rules: [] },
                        display_value: 'Y',
                        report_no_units_label: 'No exceptions',
                        report_single_unit_label: 'exception',
                        report_multiple_units_label: 'exceptions',
                        isOnCreation: true,
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface ComponentSettingsFunnelChart extends ChartComponentSettings {
        uid: string;
        component_name: 'Funnel';
        localPalette: AssocArray<string>;
        x_field: ComponentSettingsChartMeasureField;
        value_field: ComponentSettingsChartMeasureField;
        sort_field: { by_field: string; dir: OrderDirection; value_type: DatasetFieldValueType };
        display_value: YNValues;
        report_no_units_label: 'No exceptions';
        report_single_unit_label: 'exception';
        report_multiple_units_label: 'exceptions';
    }
}

// Range Chart
export namespace ReportContentNS {
    export class BlockFactoryRangeChart extends ReportContentNS.AbstractBlockFactory<ComponentSettingsRangeChart> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsRangeChart> {
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'range_chart',
                    settings: {
                        uid: uid,
                        internal_name: 'range_chart',
                        dataQueries: {
                            table: {
                                resultFieldAggregations: {},
                                resultFields: [],
                                sort: [],
                            },
                        },
                        component_name: 'Range',
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        include_in_email: 'Y',
                        // is_preview_ind: 'Y',
                        groupFilter: { operator: 'AND', rules: [] },
                        include_in_email_attachment: 'Y',
                        is_collapsed_ind: 'N',
                        //@ts-ignore
                        range_start: datasetFields[0],
                        //@ts-ignore
                        range_end: datasetFields[0],
                        //@ts-ignore
                        x_field: datasetFields[0],
                        display_value: 'Y',
                        report_no_units_label: 'No exceptions',
                        report_single_unit_label: 'exception',
                        report_multiple_units_label: 'exceptions',
                        isOnCreation: true,
                        value_axis_label: '',
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface ComponentSettingsRangeChart extends ChartComponentSettings {
        uid: string;
        component_name: 'Range';
        localPalette: AssocArray<string>;
        x_field: ComponentSettingsChartMeasureField;
        range_start: ComponentSettingsChartMeasureField;
        range_end: ComponentSettingsChartMeasureField;
        display_value: YNValues;
        report_no_units_label: 'No exceptions';
        report_single_unit_label: 'exception';
        report_multiple_units_label: 'exceptions';
        internal_name: 'range_chart';
        value_axis_label: '';
    }
}

// Map Chart
export namespace ReportContentNS {
    export class BlockFactoryMapChart extends ReportContentNS.AbstractBlockFactory<ComponentSettingsMapChart> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsMapChart> {
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'map_chart',
                    settings: {
                        uid: uid,
                        internal_name: 'map_chart',
                        dataQueries: {
                            table: {
                                resultFieldAggregations: {},
                                resultFields: [],
                                sort: [],
                            },
                        },
                        component_name: 'Map',
                        title: '',
                        height: 600,
                        is_collapsed_ind: 'N',
                        show_in_viewer: 'Y',
                        include_in_email: 'Y',
                        // is_preview_ind: 'Y',
                        groupFilter: { operator: 'AND', rules: [] },
                        include_in_email_attachment: 'Y',
                        //@ts-ignore
                        measureFields: [datasetFields[0]],
                        group_field: datasetFields[0].reference_name,
                        display_label: 'with_data_only',
                        display_full_label: 'Y',
                        color_start: '#EFEFFF',
                        color_end: '#7CB5EC',
                        map: 'custom/world.js',
                        report_no_units_label: 'No exceptions',
                        report_single_unit_label: 'exception',
                        report_multiple_units_label: 'exceptions',
                        isOnCreation: true,
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface ComponentSettingsMapChart extends ChartComponentSettings {
        uid: string;
        component_name: 'Map';
        internal_name: 'map_chart';
        measureFields: Array<ComponentSettingsChartMeasureField>;
        group_field: string;
        display_label: YNValues | 'with_data_only';
        display_full_label: YNValues;
        color_start: string;
        color_end: string;
        map: string;
        report_no_units_label: 'No exceptions';
        report_single_unit_label: 'exception';
        report_multiple_units_label: 'exceptions';
    }
}

// Text Block
export namespace ReportContentNS {
    export class BlockFactoryTextBlock extends ReportContentNS.AbstractBlockFactory<ComponentSettingsTextBlock> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsTextBlock> {
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'text_block',
                    settings: {
                        dataQueries: {
                            table: {
                                resultFields: ['*'],
                                resultFieldAggregations: {},
                                limit: 1,
                            },
                        },
                        groupFilter: { operator: 'AND', rules: [] },
                        component_name: 'TextBlock',
                        uid: uid,
                        internal_name: 'text_block',
                        title: '',
                        height: 200,
                        measureFields: [],
                        formulaFields: [],
                        show_in_viewer: 'Y',
                        is_preview_ind: 'N',
                        include_in_email: 'Y',
                        // is_preview_ind: 'Y',
                        include_in_email_attachment: 'Y',
                        is_collapsed_ind: 'N',
                        report_no_units_label: 'No exceptions',
                        report_single_unit_label: 'exception',
                        report_multiple_units_label: 'exceptions',
                        isOnCreation: true,
                        html: '',
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    interface ComponentSettingsTextBlockField {
        column_name: string;
        formattingRules?: Array<SimpleFormattingRule>;
        reference_name: string;
        value_type: DatasetFieldValueType;
    }

    export interface ComponentSettingsTextBlockMeasureField extends ComponentSettingsTextBlockField {
        aggregation_function: string;
        display_mask_id: string;
    }

    export interface ComponentSettingsTextBlockFormulaField extends ComponentSettingsTextBlockField {
        display_mask_id: string;
        expression: string;
        name: string;
    }

    export interface ComponentSettingsTextBlock extends ComponentSettings {
        uid: string;
        component_name: 'TextBlock';
        html: string;
        outputHtml?: string;
        dataQueries: ComponentSettingsTextBlockDataQueries;
        measureFields: Array<ComponentSettingsTextBlockMeasureField>;
        formulaFields: Array<ComponentSettingsTextBlockFormulaField>;
        report_no_units_label: 'No exceptions';
        report_single_unit_label: 'exception';
        report_multiple_units_label: 'exceptions';
    }

    export function fieldToTextBlockMeasuredField(
        field: ReportContentNS.DatasetField
    ): ReportContentNS.ComponentSettingsTextBlockMeasureField {
        const aggregations = ReportContentNS.AllowedMeasureFieldAggregations.filter(
            (f) => f.value_type == field.value_type
        );

        return {
            aggregation_function: aggregations.length ? aggregations[0].name : '',
            column_name: field.column_name,
            reference_name: field.reference_name,
            value_type: field.value_type,
            formattingRules: [],
            display_mask_id: '',
        };
    }

    export interface TextBlockInitialDataResponse {
        dataResults: {
            measurement_time: [AssocArray<string | number>];
            row_count: [{ cnt: number }];
            table: [AssocArray<string | number>];
            values?: [AssocArray<string | number>];
        };
    }

    export interface AdditionalContentSettingsTextBlock extends ContentSettings {
        validation: YNValues;
    }
}

// External Visualization
export namespace ReportContentNS {
    export class BlockFactoryExternalVisualization extends ReportContentNS.AbstractBlockFactory<ComponentSettingsExternalVisualization> {
        public getNewBlock({
            nextUId,
            nextSortOrder,
            datasetFields,
        }: NewBlockContextType): BlockType<ComponentSettingsExternalVisualization> {
            const uid = 'block_uid_' + nextUId;
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: nextUId,
                    datasetRendererBlockId: nextUId,
                    datasetRendererComponentId: nextUId,
                    displayOrder: nextSortOrder,
                    internalName: 'external_visualization',
                    settings: {
                        uid: uid,
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        is_preview_ind: 'N',
                        is_collapsed_ind: 'N',
                        include_in_email: 'Y',
                        include_in_email_attachment: 'Y',
                        internal_name: 'external_visualization',
                        groupFilter: { operator: 'AND', rules: [] },
                        dataQueries: {
                            table: {
                                resultFields: ['*'],
                                resultFieldAggregations: {},
                                limit: 1,
                            },
                        },
                        filters: [],
                        filtersOnDataset: [],
                        // groupFilter: any,
                        // internal_name: 'external_visualization',
                        // is_preview_ind: YNValues,
                        link_to_dataset: 'N',
                        report_ext_vis_applicable_scope: 'row',
                        // report_ext_vis_display: string,
                        report_ext_vis_element_id: 0,
                        // report_ext_vis_iframe_height: number,
                        // report_ext_vis_iframe_height_defined: boolean,
                        report_ext_vis_images_in_email: 10,
                        report_max_viz_number_to_display: 5,
                        // report_multiple_units_label: string,
                        // report_no_units_label: 'No rows',
                        report_refer_from_email_to: 'ext_vis',
                        // report_single_unit_label: 'row',

                        show_title: 'Y',
                        isOnCreation: true,
                    },
                },
                // datasetRendererBlockId: number;
                displayOrder: nextSortOrder,
                // elementId: number;
                uid: uid,
            };
        }
    }

    export interface ExternalVisualizationInitialDataResponse {
        displayOpenLink: YNValues;
        dataResults: { table: [AssocArray<string>] };
        visualizations: [
            {
                filters?: string;
                hash?: string;
                prompts?: Array<any>;
                url: string;
                url_external?: string;
            }
        ];
    }

    export interface ComponentSettingsExternalVisualizationMapColumnFilter {
        // aggregate_function: '';
        // aggregation: false;
        // all_values: 'All';
        // column_instance: 'current';
        column_name: string;
        // column_sequence: 0;
        // compute_result_total_ind: 'N';
        dataset_column_id: number;
        dataset_filter_column_id: number;
        dataset_filter_id: number;
        dataset_id: number;
        display_mask_id?: string;
        display_name_label: string;
        external_filter_id: number;
        // field: 'calendar_date';
        // id: 'calendar_date';
        // instance: 'current';
        // is_derived_ind: 'N';
        // is_result_ind: 'Y';
        // key: 'calendar_date-current';
        // label: 'calendar_date';
        // label_new: 'calendar_date';
        name: string;
        // ref_column_name: string;
        reference_name: string;
        // reference_name_escaped: 'calendar_date';
        // sub_total_placement: 'top';
        // use_formatted_value_ind: 'N';
        value_type: DatasetFieldValueType;
    }

    export interface ComponentSettingsExternalVisualizationDataQueries {
        table?: {
            resultFields: Array<string>;
            resultFieldAggregations: any;
            limit: number;
        };
        filters?: {
            resultFields: Array<string>;
            displayMasks: AssocArray<string>;
            distinct: boolean;
        };
    }

    export interface ComponentSettingsTextBlockDataQueries extends ComponentSettingsExternalVisualizationDataQueries {
        values?: {
            resultFields: Array<string>;
            resultFieldAggregations: any;
        };
        row_count?: {
            resultFields: Array<string>;
            return_count_only: YNValues;
            apply_display_masks_external: YNValues;
        };
        measurement_time?: boolean;
    }

    export interface ComponentSettingsExternalVisualization extends ComponentSettings {
        uid: string;
        dataQueries: ComponentSettingsExternalVisualizationDataQueries;
        filters: Array<ComponentSettingsExternalVisualizationMapColumnFilter>;
        filtersOnDataset: Array<ComponentSettingsExternalVisualizationMapColumnFilter>;
        // filtersOnDataset: [],
        // groupFilter: any,
        // internal_name: 'external_visualization',
        // is_preview_ind: YNValues,
        link_to_dataset: YNValues | 'D';
        report_ext_vis_applicable_scope: 'row' | 'once';
        // report_ext_vis_display: string,
        report_ext_vis_element_id: number;
        // report_ext_vis_iframe_height: number,
        // report_ext_vis_iframe_height_defined: boolean,
        report_ext_vis_images_in_email: number;
        report_max_viz_number_to_display: number;
        // report_multiple_units_label: string,
        // report_no_units_label: 'No rows',
        report_refer_from_email_to: 'ext_vis' | 'self';
        // report_single_unit_label: 'row',
        dataset_block_uid?: string;
        show_title: YNValues;
        userSelectedViz?: string;
    }
}

// Manage Filters
export namespace ReportContentNS {
    export class BlockFactoryManageFilters extends ReportContentNS.AbstractBlockFactory<ComponentSettingsManageFilters> {
        public getNewBlock({}: NewBlockContextType): BlockType<ComponentSettingsManageFilters> {
            return {
                // TODO:
                component: {
                    datasetRendererBlockComponentId: -1,
                    datasetRendererBlockId: -1,
                    datasetRendererComponentId: -1,
                    displayOrder: 0,
                    internalName: 'manage_filters',
                    settings: {
                        uid: 'manage_filters',
                        title: '',
                        height: 600,
                        show_in_viewer: 'Y',
                        is_preview_ind: 'N',
                        is_collapsed_ind: 'N',
                        include_in_email: 'Y',
                        include_in_email_attachment: 'Y',
                        internal_name: 'manage_filters',
                        fields: [],
                        isOnCreation: false,
                        groupFilter: { operator: 'AND', rules: [] },
                        dataQueries: {
                            skip: true,
                        },
                        filterPosition: 'top',
                    },
                },
                displayOrder: -1,
                uid: 'manage_filters',
            };
        }
    }

    export interface ComponentSettingsManageFiltersDataQueries {
        [key: string]: ComponentSettingsChartDataQueriesTable | boolean;
        skip: boolean;
    }

    export interface ManageFiltersInitialDataResponse {
        dataResults: any; // TODO
    }

    export interface ComponentSettingsManageFilters extends ComponentSettings {
        uid: string;
        fields: Array<RendererFilter>;
        requestParams?: AssocArray<any>; // TODO
        dataQueries: ComponentSettingsManageFiltersDataQueries;
        filterPosition: 'top' | 'left' | 'right';
    }

    export interface ManageFiltersInstance {
        dataset_instance_id: number;
        measurement_time: string;
        measurement_time_raw: string;
        rows_number: number;
    }
}

export namespace ReportContentNS {
    // TODO: implement
    const AllowedFactories: {
        [key in ComponentsType]: typeof ReportContentNS.AbstractBlockFactory<ComponentSettings>;
    } = {
        bubble_chart: ReportContentNS.BlockFactoryBubbleChart,
        dataset: ReportContentNS.BlockFactoryDataset,
        chart: ReportContentNS.BlockFactoryPieChart,
        external_visualization: ReportContentNS.BlockFactoryExternalVisualization,
        funnel_chart: ReportContentNS.BlockFactoryFunnelChart,
        jct_31: ReportContentNS.AbstractBlockFactory, //TODO
        kpi: ReportContentNS.AbstractBlockFactory, //TODO
        line: ReportContentNS.BlockFactoryLineChart,
        map_chart: ReportContentNS.BlockFactoryMapChart,
        pivot: ReportContentNS.BlockFactoryPivot,
        range_chart: ReportContentNS.BlockFactoryRangeChart,
        text_block: ReportContentNS.BlockFactoryTextBlock,
        manage_filters: ReportContentNS.BlockFactoryManageFilters,
    };

    export class BlockFactory {
        public static getNewBlock(
            component: AllowedComponentType,
            blockContextProps: NewBlockContextType
        ): BlockType<ComponentSettings> {
            const factoryClass = new AllowedFactories[component.internalName]();
            return factoryClass.getNewBlock(blockContextProps);
        }
    }
}
