import { Box, Input, Stack } from '@mui/material';
import { FormElementControlPropsType, FormControlTextProps } from './index';
import useBundleTranslation from 'i18n';
import styles from './FormTextarea.styles';
import React from 'react';
import Components, { ComponentKey } from 'components/common/ComponentIndex';

export default function FormTextarea({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlTextProps>) {
    delete controlProps.asLabel;
    const maxLength = Number(controlProps.maxLength) || undefined;
    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'components/common/form/form']);

    const leftCharacters =
        controlProps?.value?.length && maxLength
            ? maxLength - controlProps.value.length > 0
                ? maxLength - controlProps.value.length
                : 0
            : maxLength ?? 0;

    const hasHelper = typeof controlProps.helper != 'undefined';
    //ToDO MinRows / MaxRows + check bug of calc height with current paddings (if remove more correct)
    return (
        <>
            <Box sx={controlProps.charactersLeftLabel ? styles.charactersLeftWrapper : undefined}>
                <Stack direction="row" width="100%" sx={{ width: controlProps.width }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Input
                            readOnly={controlProps.readOnly}
                            multiline
                            disabled={controlProps.disabled}
                            name={controlProps.name}
                            value={controlProps.value}
                            type={controlProps.fieldType ?? 'text'}
                            onChange={controlProps.onChange}
                            onBlur={controlProps.onBlur}
                            placeholder={t(controlProps.placeholder ?? '')}
                            rows={controlProps.rows}
                            inputProps={{ maxLength }}
                            className={controlProps.controlClass ?? undefined}
                            data-test={controlProps.uid}
                            fullWidth
                        />
                    </Box>
                    {hasHelper &&
                        React.createElement(Components[controlProps.helper.name as ComponentKey] as React.FC, {
                            // @ts-ignore
                            value: controlProps.value,
                            afterSave: () => {},
                            helperProps: controlProps.helper,
                            controlProps: controlProps,
                        })}
                </Stack>

                {maxLength && controlProps.charactersLeftLabel && (
                    <Box sx={styles.charactersLeftHolder}>
                        {t(controlProps.charactersLeftLabel, {
                            left: leftCharacters,
                        })}
                    </Box>
                )}
            </Box>
        </>
    );
}
