import { alpha, Box, Grid, Stack, Theme, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { TFunction } from 'react-i18next';
import IconMi from 'components/common/icon/IconMi';
import { ButtonConfig, PopupMode, PopupType } from './index';
import CreateButton from './buttons/CreateButton';
import { PopupSettings } from '../popup/Popup';
import { TranslatedText } from 'tools/types';

export default function EmptyGrid({
    emptyTitle,
    emptyIcon,
    createButtons,
    t,
    gridName,
    height,
    controllerUrl,
    gridPopup,
    setPopupGetMethod,
    setPopupComponent,
    setPopupComponentProps,
    setFormPostData,
    setPopupMode,
    setPopupRedirectAfter,
    setPopupUrl,
    setPopupType,
    setPopupConfig,
}: {
    emptyTitle: TranslatedText;
    emptyIcon?: string;
    createButtons: ButtonConfig[];
    controllerUrl: string;
    t: TFunction;
    gridName: string;
    height?: number; //is Page grid
    gridPopup: ReactElement;
    setPopupUrl?: (url: string) => void;
    setPopupConfig?: (popupConfig: PopupSettings) => void;
    setPopupType?: (type: PopupType) => void;
    setPopupMode?: (mode: PopupMode) => void;
    setPopupRedirectAfter?: (redirect: boolean) => void;
    setFormPostData?: (formData: any) => void;
    setPopupGetMethod?: (getMethod: boolean) => void;
    setPopupComponent?: (popupComponent: string) => void;
    setPopupComponentProps?: (popupComponentProps: { [key: string]: string }) => void;
}) {
    const getCreateButtons = () => {
        if (createButtons.length > 0) {
            return createButtons.map((createButtonConfig) => {
                return (
                    <CreateButton
                        key={createButtonConfig.name}
                        config={createButtonConfig}
                        controllerUrl={controllerUrl}
                        setPopupConfig={setPopupConfig}
                        setPopupUrl={setPopupUrl}
                        setPopupType={setPopupType}
                        setPopupMode={setPopupMode}
                        setPopupRedirectAfter={setPopupRedirectAfter}
                        setFormPostData={setFormPostData}
                        setPopupGetMethod={setPopupGetMethod}
                        setPopupComponent={setPopupComponent}
                        setPopupComponentProps={setPopupComponentProps}
                        t={t}
                        id={`grid_${gridName}_buttons_${createButtonConfig.name}_button`}
                    />
                );
            });
        }
    };

    return (
        <Grid
            container
            alignContent={'center'}
            justifyContent={'center'}
            sx={{ width: '100%', height: height ? '80vh' : '200px' }}
        >
            <Stack alignItems={'center'} spacing={2}>
                <IconMi
                    icon={emptyIcon ?? 'template'}
                    sx={{ fontSize: '32px', color: (theme: Theme) => alpha(theme.palette.text.primary, 0.24) }}
                />
                <Typography>{emptyTitle.needTranslation === false ? emptyTitle.text : t(emptyTitle.text)}</Typography>
                <Box>{getCreateButtons()}</Box>
            </Stack>
            {gridPopup}
        </Grid>
    );
}
