import InfoPanel from './InfoPanel';
import React, { useEffect, useState } from 'react';
import { alpha, Stack } from '@mui/material';
import { DatasetDataResponse, FilterData, RulesConfig } from '../index';
import useBundleTranslation from 'i18n';
import AddMetricPanel from './AddMetricPanel';
import MetricPreview from './MetricPreview';
import { datasetViewAPI } from 'api/viewer/dataset';
import { ColumnType, GridData } from 'components/common/grid';
import { useQuery } from '@tanstack/react-query';
import { gridAPI } from 'api/grid';
import ResultPopup from './ResultPopup';

interface IMetricBuilderProps {
    setShowMetricBuilder: (value: boolean) => void;
    datasetData: DatasetDataResponse;
    viewName: string;
    selectedFilterData: FilterData | null;
    filter: RulesConfig;
    filterId: number;
    datasetId: number;
}

export interface MetricItem {
    uid: string;
    name: string;
    measurement_interval_id: string;
    value_column: string;
    date_column: string;
    segment_column: string;
    segment_reuse: string;
    metric_calculate: string;
    template: number;
    filter?: number;
}

export interface SegmentReuse {
    segment_column: string;
    segment_reuse: string;
}

export interface SegmentReuseResponse {
    status: string;
    message: string;
}

export interface MetricResponse {
    uid: string;
    id?: number;
    name: string;
    errormessage?: string;
}

export default function MetricBuilder({
    setShowMetricBuilder,
    datasetData,
    selectedFilterData,
    filter,
    filterId,
    datasetId,
}: IMetricBuilderProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const [metrics, setMetrics] = useState<MetricItem[]>([]);
    const [activeMetricUid, setActiveMetricUid] = useState('');
    const [createLoading, setCreateLoading] = useState(false);
    const [showResultPopup, setShowResultPopup] = useState(false);
    const [metricsList, setMetricsList] = useState<MetricResponse[]>([]);

    const [gridData, setGridData] = useState<GridData>([]);
    const [columns, setColumns] = useState<ColumnType[]>([]);

    const settingsUrl = `data/dataset/${datasetId}/sourced-metric/grid${filterId > 0 ? '?filterId=' + filterId : ''}`;

    const { data, isLoading, refetch } = useQuery<any, Error>([settingsUrl], () => {
        return gridAPI.load(settingsUrl);
    });

    useEffect(() => {
        if (data && typeof data.data?.settings?.columns != 'undefined') {
            setColumns(data.data.settings.columns);
            setGridData(data.data.data.rows);
        }
    }, [data]);

    const onMetricAdd = (newMetric: MetricItem) => {
        setMetrics((prev) => {
            const newValue = [...prev];

            newValue.push(newMetric);

            return newValue;
        });
        setActiveMetricUid(newMetric.uid);
    };

    const onMetricsBuild = async () => {
        if (metrics.length === 0) {
            return;
        }

        setCreateLoading(true);
        const metricsResponseList: MetricResponse[] = [];

        for (const metric of metrics) {
            const response = await datasetViewAPI.createMetric(datasetId, metric);

            if (response.status === 'OK') {
                await datasetViewAPI.collectMetric(datasetId, response.id);
                metricsResponseList.push({
                    name: metric.name,
                    id: response.id,
                    uid: metric.uid,
                });
            }
            if (response.status === 'ERROR') {
                metricsResponseList.push({
                    name: metric.name,
                    errormessage: response.message,
                    uid: metric.uid,
                });
            }
        }

        setMetricsList(metricsResponseList);
        setCreateLoading(false);
    };

    const onMetricRemove = (metricUid: string) => {
        const newMetrics = [...metrics].filter((metric) => metric.uid !== metricUid);

        if (metricUid === activeMetricUid) {
            setActiveMetricUid(newMetrics.length > 0 ? newMetrics[0].uid : '');
        }

        setMetrics(newMetrics);
    };

    useEffect(() => {
        if (metricsList.length > 0) {
            setShowResultPopup(true);

            const successMetrics = metricsList.filter((metric) => metric.id !== undefined);
            const firstErrorMetric = metricsList.filter((metric) => metric.id === undefined).pop();

            setMetrics((prev) => {
                return prev.filter((pMetric) => {
                    const find = successMetrics.find((sMetric) => sMetric.uid === pMetric.uid);

                    return !find;
                });
            });
            setActiveMetricUid(firstErrorMetric ? firstErrorMetric.uid : '');
            refetch();
        }
    }, [metricsList]);

    const onPopupClose = () => {
        setShowResultPopup(false);
        setMetricsList([]);
    };

    return (
        <Stack direction={'column'} alignItems="stretch" sx={{ height: 1, overflow: 'hidden' }}>
            <InfoPanel
                setShowMetricBuilder={setShowMetricBuilder}
                collected={datasetData.updateFormattedDate}
                viewName={selectedFilterData?.name ?? t('all_data_select_label')}
            />
            <Stack
                direction={'row'}
                sx={{
                    height: '100%',
                    backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                    overflow: 'hidden',
                }}
            >
                <AddMetricPanel datasetData={datasetData} filter={filter} onMetricAdd={onMetricAdd} />
                <MetricPreview
                    filterId={filterId}
                    datasetId={datasetId}
                    metrics={metrics}
                    activeMetricUid={activeMetricUid}
                    onBuildMetrics={onMetricsBuild}
                    onMetricChange={setActiveMetricUid}
                    onMetricRemove={onMetricRemove}
                    gridData={gridData}
                    columns={columns}
                    reloadGridData={refetch}
                    buildInProgress={createLoading}
                />
            </Stack>
            {showResultPopup && <ResultPopup metricsList={metricsList} onClose={onPopupClose} />}
        </Stack>
    );
}
