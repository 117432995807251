import React, { useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';

export default function ExtContent(props: IFormLayoutProps) {
    const [defaultValue] = useState(props.hookForm.data.external_report_display);

    useEffect(() => {
        if (!props.hookForm.form.formDidMount) {
            return;
        }
        const newValue = props.hookForm.form.hookFormGetValues('external_report_display');
        if (newValue != defaultValue) {
            props.hookForm.form.hookFormSetValue('external_report_display', newValue, { shouldDirty: true });
        }
    }, [props.hookForm.form.formDidMount]);

    return <FormLayoutComposer props={props} />;
}
