import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { alpha, Stack } from '@mui/material';
import { FormComponentBuilder } from 'components/common/form/layout';

export interface FormControlThemeUsagePreviewProps extends FormControlProps {}

export const setFontWeightValue = (value: string) => {
    const formattedValue = value.toLowerCase().replaceAll(' ', '_');
    switch (formattedValue) {
        case 'thin':
        case 'hairline':
            return 100;
        case 'extra_light':
        case 'extralight':
        case 'ultra_light':
        case 'ultralight':
            return 200;
        case 'light':
            return 300;
        case 'normal':
        case 'regular':
            return 400;
        case 'medium':
            return 500;
        case 'semi_bold':
        case 'semibold':
        case 'demi_bold':
        case 'demibold':
            return 600;
        case 'bold':
            return 700;
        case 'extra_bold':
        case 'extrabold':
        case 'ultra_bold':
        case 'ultrabold':
            return 800;
        case 'black':
        case 'heavy':
            return 900;
        case 'extra_black':
        case 'extrablack':
        case 'ultra_black':
        case 'ultrablack':
            return 950;
    }
    return formattedValue;
};

export default function ThemeUsagePreview({
    controlProps: props,
}: FormElementControlPropsType<FormControlThemeUsagePreviewProps>) {
    const { t } = useBundleTranslation(['app/editor/brand-theme']);

    const [fontId, setFontId] = useState<string>(props.form.hookFormGetValues('font'));
    const [fontStyle, setFontStyle] = useState<string>(props.form.hookFormGetValues('font_style'));
    const [fontWeight, setFontWeight] = useState<string | number>(
        setFontWeightValue(props.form.hookFormGetValues('font_weight'))
    );
    const [fontSize, setFontSize] = useState<string>(props.form.hookFormGetValues('font_size'));

    useEffect(() => {
        const subscription = props.form.hookFormWatch((value, { name, type }) => {
            switch (name) {
                case 'font':
                    setFontId(value[name]);
                    break;
                case 'font_style':
                    setFontStyle(value[name]);
                    break;
                case 'font_weight':
                    setFontWeight(setFontWeightValue(value[name]));
                    break;
                case 'font_size':
                    setFontSize(value[name]);
                    break;
            }
        });

        return () => subscription?.unsubscribe();
    }, [props.form.hookFormWatch]);

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                border: '1px solid',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                borderRadius: 1,
                p: 1.5,
                textAlign: 'center',
                minHeight: '68px',
                color: 'primary.main',
                fontStyle: fontStyle,
                fontWeight: fontWeight,
                fontSize: fontSize + 'px',
                fontFamily: (theme) => `${fontId ? 'CustomFont_' + fontId + ', ' : ''}${theme.font.default}`,
            }}
        >
            {t('usage_preview_text')}
        </Stack>
    );
}

export class FormThemeUsagePreviewBuilder extends FormComponentBuilder {
    prepareProps(): FormControlThemeUsagePreviewProps {
        return { ...this.controlProps };
    }
}
