import { Box, Typography } from '@mui/material';
import styles from '../styles';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { DatasetInfo } from 'components/dataset-viewer/index';
import useBundleTranslation from 'i18n';

export default function Certified({ datasetInfo }: { datasetInfo: DatasetInfo }) {
    const { t } = useBundleTranslation('app/content-workflow/info-card');

    const isCertified = datasetInfo.certified === 'Y';

    return isCertified ? (
        <Box sx={styles.flexStartWrapper}>
            <Typography color={'success.main'} fontSize={14} width={'16px'}>
                <IconMi icon={'certified'} />
            </Typography>
            <Box sx={styles.certifiedMainText}>
                <Typography color={'success.main'} fontWeight={600} display="inline">
                    {t('certified')}
                </Typography>
                {` ${t('by')} `}
                <Typography color={'primary.main'} display="inline">
                    {datasetInfo.certified_by_dn}
                </Typography>
                <Typography display="inline">{` ${t('on')} ${datasetInfo.certified_date}`}</Typography>
            </Box>
        </Box>
    ) : null;
}
