import React, { lazy, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { Box } from '@mui/material';
import { FormComponentBuilder } from 'components/common/form/layout';
import styles from './KpiRuleInfo.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { customRequestAPI } from 'api/api';
const AlertRulePopup = lazy(() => import('components/alert-rule-popup/AlertRulePopup'));

interface alertInfo {
    element_id: number;
    element: string;
    user_defined_name: string;
    type: string;
    alert_visibility: string;
    content: string;
    distribution: string;
    condition_params: any;
    alert_type: any;
    default_name: string;
    alert_rule_id: number;
}

interface alertRuleWizardParams {
    alert_rule_id: number;
    element_id: number;
    global: string;
    segment_value_id: number;
    user: number;
    visualization_element_id: number;
}

interface ruleInfoData {
    alert_info: alertInfo;
    alert_rule_wizard_params: alertRuleWizardParams;
    element_data: any;
    workflow: any;
    isPermitted4EditWorkflow: boolean;
}

export interface FormControlKpiRuleAssignWorkflowLinkProps extends FormControlProps {}

export default function KpiRuleAssignWorkflowLink({
    controlProps,
}: FormElementControlPropsType<FormControlKpiRuleAssignWorkflowLinkProps>) {
    const { t } = useBundleTranslation(['app/editor/kpi-alert-rule/kpi_alert_rule']);

    const [showAlertWizardPopup, setShowAlertWizardPopup] = useState<boolean>(false);
    const [ruleInfo, setRuleInfo] = useState<ruleInfoData>();
    const urlParams = useParams();
    const alertRuleId = urlParams.alertRuleId;
    const navigate = useNavigate();

    useEffect(() => {
        customRequestAPI
            .get(`data/editor/alert-rule/${alertRuleId}/alert-rule-info`)
            .then((response: any) => {
                if (response?.statusText === 'OK') {
                    setRuleInfo(response.data);
                }
            })
            .catch(() => {});
    }, []);

    if (!ruleInfo) return null;

    return (
        <Box sx={styles.infoWrapper}>
            <Box>{ruleInfo.workflow?.name ?? t('assign_workflow_link.alert_workflow_not_set')}</Box>
            <Box>
                <Box
                    component={'a'}
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowAlertWizardPopup(true);
                    }}
                >
                    {t('assign_workflow_link.assign_new_alert_workflow')}
                </Box>
                {/*ToDO uncomment when we have link*/}
                {/*ruleInfo.workflow && ruleInfo.isPermitted4EditWorkflow && (
                    <>
                        {' '}
                        |{' '}
                        <Link
                            to={`editor/workflow/edit/flow/${ruleInfo.workflow.workflow_id}/rl_kpi/${ruleInfo.alert_info.alert_rule_id}`}
                        >
                            {t('assign_workflow_link.edit_alert_workflow')}
                        </Link>
                    </>
                )*/}
            </Box>
            {showAlertWizardPopup && (
                <AlertRulePopup
                    onClose={() => {
                        setShowAlertWizardPopup(false);
                    }}
                    onFinish={(response) => {
                        if (response.data.status == 'OK') {
                            alert(t('wizard_saved_msg'));
                            navigate(0);
                        }
                        setShowAlertWizardPopup(false);
                    }}
                    show={true}
                    alertRuleId={ruleInfo.alert_rule_wizard_params.alert_rule_id}
                    elementId={ruleInfo.alert_rule_wizard_params.element_id}
                    segmentValueId={ruleInfo.alert_rule_wizard_params.segment_value_id}
                    userId={ruleInfo.alert_rule_wizard_params.user}
                    visualizationElementId={ruleInfo.alert_rule_wizard_params.visualization_element_id}
                    global={ruleInfo.alert_rule_wizard_params.global === 'Y' ? 'Y' : 'N'}
                    step={4}
                />
            )}
        </Box>
    );
}

export class FormKpiRuleAssignWorkflowLinkBuilder extends FormComponentBuilder {
    prepareProps(): FormControlKpiRuleAssignWorkflowLinkProps {
        return { ...this.controlProps };
    }
}
