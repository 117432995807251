import { components } from 'react-select';
import IconHandler from 'components/common/icon/IconHandler';

// Default template for MultiValue, override with props.optionComponent
export const ReactSelectMultiValue = (props: any) => {
    return (
        <>
            <components.MultiValue {...props}>
                {<IconHandler icon={props.data.props?.icon} />}
                {props.children}
            </components.MultiValue>
        </>
    );
};
