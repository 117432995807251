import { Box, FormControlLabel, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { FormControlCheckboxProps, FormElementControlPropsType } from './index';
import useBundleTranslation from 'i18n';
import IconHandler from 'components/common/icon/IconHandler';
import { FormComponentBuilder } from 'components/common/form/layout/index';
import StaticInfo from 'components/common/StaticInfo';
import React, { useEffect } from 'react';
import showdown from 'showdown';
import IconMi from 'components/common/icon/IconMi';
const converter = new showdown.Converter();

export default function FormCheckbox({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlCheckboxProps>) {
    const { t } = useBundleTranslation(elementProps?.translationNS ?? 'components/common/form/form');

    const hasIcon = Boolean(controlProps.icon);
    const checked = controlProps.value === true || controlProps.value == 'Y';

    useEffect(() => {
        if (!controlProps.form.formDidMount) {
            return;
        }
        if (controlProps.value == '' && controlProps.value != controlProps.checkedValue) {
            controlProps.form.hookFormSetValue(controlProps.name, controlProps.uncheckedValue);
        }
    }, [controlProps.form.formDidMount]);

    const iconTooltip = controlProps?.tooltip?.icon ? (
        <Tooltip
            title={
                <Box
                    sx={{ '& p': { m: 0 } }}
                    dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(String(t(controlProps.tooltip.icon))),
                    }}
                />
            }
            placement={'top-start'}
        >
            <Box sx={{ display: 'inline-flex', ml: 0.5, color: 'primary.main', verticalAlign: 'middle' }}>
                <IconMi icon="info-circle" fontSize="16" />
            </Box>
        </Tooltip>
    ) : null;

    // TODO: add real style for "selected' icon
    const label = hasIcon ? (
        <IconHandler style={{ color: checked ? '#f00' : '#000' }} icon={controlProps.icon} />
    ) : controlProps.translate ? (
        t(controlProps.label as string)
    ) : (
        controlProps.label
    );

    return (
        <Tooltip
            title={
                controlProps?.tooltip?.label ? (
                    <Box
                        sx={{ '& p': { m: 0 } }}
                        dangerouslySetInnerHTML={{
                            __html: converter.makeHtml(String(t(controlProps?.tooltip?.label ?? ''))),
                        }}
                    />
                ) : (
                    ''
                )
            }
            placement={'top-start'}
            arrow={false}
            disableInteractive
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -15],
                            },
                        },
                    ],
                },
            }}
        >
            <span>
                <FormControlLabel
                    name={controlProps.name}
                    label={
                        <>
                            {label}
                            {iconTooltip}
                        </>
                    }
                    disabled={controlProps.disabled}
                    className={controlProps.checked ? 'control-checkbox--checked' : ''}
                    sx={{
                        alignSelf: 'flex-start',
                        '.highlight-checked &.control-checkbox--checked': {
                            color: (theme) => theme.palette.primary.main,
                        },
                    }}
                    control={
                        <Tooltip
                            title={
                                controlProps?.tooltip?.input ? (
                                    <Box
                                        sx={{ '& p': { m: 0 } }}
                                        dangerouslySetInnerHTML={{
                                            __html: converter.makeHtml(String(t(controlProps?.tooltip?.input ?? ''))),
                                        }}
                                    />
                                ) : (
                                    ''
                                )
                            }
                            placement={'top-start'}
                            arrow={false}
                            disableInteractive
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -15],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <Checkbox
                                className={hasIcon ? 'd-none' : ''}
                                data-test={controlProps.uid}
                                name={controlProps.name}
                                onChange={controlProps.onChange}
                                checked={controlProps.checked}
                                value={controlProps.value}
                                disabled={controlProps.disabled}
                            />
                        </Tooltip>
                    }
                />
                {controlProps.desc && (
                    <Box
                        sx={{
                            pl: '24px', //checkbox size 16px + indent 8px
                            mt: '-3px',
                        }}
                    >
                        <StaticInfo label={t(controlProps.desc as string)} variant="blank" />
                    </Box>
                )}
            </span>
        </Tooltip>
    );
}

export class FormCheckboxBuilder extends FormComponentBuilder {
    prepareProps(
        value: any,
        onChange: (...event: any[]) => void,
        afterChange: (event: any) => void
    ): FormControlCheckboxProps {
        const checkedValue = this.elementProps.componentProps?.checkedValue ?? 'Y';
        const uncheckedValue = this.elementProps.componentProps?.uncheckedValue ?? 'N';
        return {
            ...this.controlProps,
            onChange: (value: any, checked: any) => {
                const v = this.elementProps.componentProps?.asBoolean
                    ? checked
                    : checked
                    ? checkedValue
                    : uncheckedValue;
                onChange(v);
                afterChange(v);
            },
            checked: this.elementProps.componentProps?.asBoolean ? value : value == checkedValue,
            disabled: this.elementProps.componentProps?.disabled ?? false,
            icon: this.elementProps.componentProps?.icon ?? undefined,
            checkedValue: this.elementProps.componentProps?.checkedValue ?? checkedValue,
            uncheckedValue: this.elementProps.componentProps?.uncheckedValue ?? uncheckedValue,
            desc: this.elementProps.componentProps?.desc,
        };
    }
}
