import { ReportContentNS } from 'components/report-content/index';
import { escapeHtml } from 'components/report-content/utils/tools';
import ComponentSettingsTextBlockFormulaField = ReportContentNS.ComponentSettingsTextBlockFormulaField;
import FieldTextFormatting = ReportContentNS.FieldTextFormatting;
import TextBlockInitialDataResponse = ReportContentNS.TextBlockInitialDataResponse;
import { Computation } from 'components/report-content/components/dataset/CellStyler';
import { FieldConditionRuleNS } from 'components/common/field-condition-rule';
import Rule = FieldConditionRuleNS.Rule;

export default function applyFormatting(
    data: TextBlockInitialDataResponse,
    field: ComponentSettingsTextBlockFormulaField,
    computation: Computation
) {
    const reference_name = field['reference_name'];
    if (!data.dataResults?.values?.[0]?.[reference_name]) {
        return false;
    }

    let replace = String(
        data.dataResults.values[0][reference_name + '-formatted'] ?? data.dataResults.values[0][reference_name]
    );

    if (null !== field.formattingRules && 'undefined' != typeof field.formattingRules) {
        let formatting: FieldTextFormatting | false = false;
        field.formattingRules.forEach(function (rule) {
            if (
                computation.calculate(
                    rule as Rule,
                    //@ts-ignore
                    field as ComponentSettingsDatasetField,
                    data.dataResults?.values?.[0]?.[reference_name] ?? null
                )
            ) {
                if (formatting == false) {
                    formatting = {};
                }
                if (rule.textColor) {
                    formatting.textColor = rule.textColor;
                }
                if (rule.bgColor) {
                    formatting.bgColor = rule.bgColor;
                }
                if (typeof rule.textBold != 'undefined') {
                    formatting.textBold = rule.textBold;
                }
                if (typeof rule.textItalic != 'undefined') {
                    formatting.textItalic = rule.textItalic;
                }
            }
            if (formatting) {
                return;
            }
        });

        if (formatting) {
            const f = formatting as FieldTextFormatting;
            replace =
                '<span style="' +
                (f?.textColor ? 'color:' + escapeHtml(f.textColor) + ';' : '') +
                (f?.bgColor ? 'background-color:' + escapeHtml(f.bgColor) + ';' : '') +
                (f?.textBold ? 'font-weight:bold;' : '') +
                (f?.textItalic ? 'font-style:italic;' : '') +
                `">${replace}</span>`;
        }
    }
    return replace;
}
