import { instance } from 'api/api';
import { gridAPI } from 'api/grid';

import React, { useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { Backdrop, CircularProgress } from '@mui/material';
import FormComponent from 'components/common/form/Form';

export default function MassDeletePopup({
    data,
    handleHidePopup,
    url,
    setCheckedKeys,
    reloadGridData,
}: {
    data: {
        ids: Array<string | number>;
    };
    handleHidePopup: () => void;
    url: string;
    setCheckedKeys: (ids: (string | number)[]) => void;
    reloadGridData: () => void;
}) {
    const { t } = useBundleTranslation('main');

    const [open, setOpen] = useState<boolean>(true);
    const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
    const [loadingMaskShow, setLoadingMaskShow] = useState<boolean>(false);

    const formUrl = `data/editor/user/change-owner/form`;
    const singleItem = data.ids.length === 1;
    const deleteItem = t(`mass_delete_items.${singleItem ? 'user' : 'users'}`);

    const popupSettings: PopupSettings = {
        title: t('mass_delete_item_title', { item: deleteItem }),
        textOK: t('mass_delete_popup_confirm'),
        needTranslation: false,
        colorOk: 'error',
        iconOK: 'trash',
    };

    const formPopupSettings: PopupSettings = {
        title: 'change_owner',
        maxWidth: 'popupMd',
    };

    const handleHide = () => {
        setOpen(false);
        setShowFormPopup(false);
        setCheckedKeys && setCheckedKeys([]);
        handleHidePopup();
    };

    const makeDelete = () => {
        const deleteUrl = '/data/editor/user/{userId}/form?mass-delete=true';

        setLoadingMaskShow(true);

        gridAPI
            .massDelete(deleteUrl, data)
            .then(() => {
                if (reloadGridData) {
                    reloadGridData();
                }
                if (setCheckedKeys) {
                    setCheckedKeys([]);
                }
            })
            .finally(() => {
                setLoadingMaskShow(false);
                handleHide();
            });
    };

    const makeAfterConfirmCheck = () => {
        setLoadingMaskShow(true);
        instance
            .post(url, data)
            .then((response) => {
                if (response.data.status === 'OK') {
                    const showForm = response.data.data?.is_owner ?? false;

                    if (showForm) {
                        setShowFormPopup(true);
                    } else {
                        makeDelete();
                    }
                }
            })
            .finally(() => {
                setLoadingMaskShow(false);
            });
    };

    const afterSave = () => {
        reloadGridData();
        handleHide();
    };

    return (
        <>
            {open && (
                <Popup
                    maxWidth={'popupMd'}
                    settings={popupSettings}
                    open={open}
                    onHide={handleHide}
                    onConfirm={makeAfterConfirmCheck}
                    t={t}
                >
                    {t('mass_delete_item_description', {
                        count: data.ids.length > 1 ? data.ids.length : '',
                        item: deleteItem,
                    })}
                </Popup>
            )}
            {showFormPopup && (
                <FormComponent
                    isPopup
                    popupType={'edit'}
                    pk={formUrl}
                    uid={formUrl}
                    settingsUrl={formUrl}
                    onHide={handleHide}
                    popupSettings={formPopupSettings}
                    afterSave={afterSave}
                    postData={data}
                    getMethod
                    translationNS={'app/editor/user/change-owner'}
                />
            )}
            <Backdrop
                sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingMaskShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
