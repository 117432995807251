import React, { useEffect } from 'react';
import { YNValues } from 'tools/types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { ReportContentNS, TextAlignType } from 'components/report-content/index';
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { alpha, Box, MenuItem, Popper, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import AggregationMethods = ReportContentNS.AggregationMethods;
import useBundleTranslation from 'i18n';
import { ReportContentActions } from 'app/editor/report';
import IconHandler from 'components/common/icon/IconHandler';

export default function DatasetHeaderColumnDropDown({
    field,
    anchorEl,
    onChangeColumnSettings,
    onChangeColumnSortSettings,
    onClickAway,
    component,
    actions,
    editPanel,
    blockId,
}: {
    field: ComponentSettingsDatasetField | null;
    anchorEl: any;
    onChangeColumnSettings: (fieldId: string, newSettings: ComponentSettingsDatasetField) => void;
    onChangeColumnSortSettings: (newSortFieldsSettings: Array<ComponentSettingsDatasetSortField>) => void;
    onClickAway: (event: any) => void;
    component: BlockComponent<ComponentSettingsDataset>;
    actions: ReportContentActions;
    editPanel: any;
    blockId?: string;
}) {
    const elementProps = useCustomSimplifiedForm({
        bgColor: field?.bgColor ?? '',
        textColor: field?.textColor ?? '',
        aggregation_method: field?.aggregation_method ?? '',
        textAlign: field?.textAlign ?? '',
    });
    const { t } = useBundleTranslation(['components/report-content']);
    useEffect(() => {
        if (field == null) {
            return;
        }
        elementProps.form.hookFormSetValue('bgColor', field?.bgColor);
        elementProps.form.hookFormSetValue('textColor', field?.textColor);
        elementProps.form.hookFormSetValue('aggregation_method', field?.aggregation_method);
        elementProps.form.hookFormSetValue('textAlign', field?.textAlign);
        elementProps.form.hookFormSetValue('textWrap', field?.textWrap);
    }, [field]);

    const [bgColor, textColor, aggregationMethod, textAlign, textWrap] = elementProps.form.hookFormWatch([
        'bgColor',
        'textColor',
        'aggregation_method',
        'textAlign',
        'textWrap',
    ]);
    useEffect(() => {
        if (field == null) {
            return;
        }
        const newSettings = {
            ...field,
            bgColor: bgColor,
            textColor: textColor,
            aggregation_method: aggregationMethod,
            textWrap: textWrap,
            textAlign: textAlign,
        };
        onChangeColumnSettings(field.id, newSettings);
    }, [bgColor, textColor, aggregationMethod, textWrap, textAlign]);

    const handleHideColumn = () => {
        if (field == null) {
            return;
        }
        const newSettings = { ...field, show_column_in_table_display_ind: 'N' as YNValues };
        onChangeColumnSettings(field.id, newSettings);
    };

    const handleEditColumn = () => {
        if (field == null || !blockId) {
            return;
        }
        actions.openBlockEditor(blockId, editPanel, { editField: field.reference_name });
    };

    const handleWrapText = () => {
        elementProps.form.hookFormSetValue('textWrap', !textWrap);
    };

    if (field == null) {
        return <></>;
    }

    const index = component.settings.fields.findIndex((f) => f.reference_name == field.reference_name);

    const isFrozen = component.settings.fixedColumns - 1 >= index;
    const handleFreezeColumn = () => {
        if (!blockId) {
            return;
        }
        // debugger;
        const newSettings = {
            ...component.settings,
            fixedColumns: index + (component.settings.fixedColumns - 1 >= index ? 0 : 1),
        };
        actions.updateBlockSettings(blockId, newSettings);
    };

    const setSortOrder = (newOrder: 'ASC' | 'DESC' | false) => {
        const list = component.settings.sortFields.slice();

        const index = list.findIndex((sort) => sort.reference_name == field.reference_name);

        if (index != -1) {
            if (newOrder == false) {
                list.splice(index, 1);
            } else {
                list[index].dir = newOrder;
            }
        } else {
            if (newOrder != false) {
                list.push({
                    column_name: field.column_name,
                    dir: newOrder,
                    reference_name: field.reference_name,
                    reference_name_escaped: field.reference_name_escaped,
                    value_type: field.value_type,
                });
            }
        }
        onChangeColumnSortSettings(list);
    };

    const setTextAlign = (textAlign: TextAlignType) => {
        elementProps.form.hookFormSetValue('textAlign', textAlign);
    };

    const sortingField = component.settings.sortFields.find((sort) => sort.reference_name == field.reference_name);
    const sort = sortingField ? sortingField.dir : false;
    return (
        <ClickAwayListener onClickAway={(event) => onClickAway(event)}>
            <Popper open={true} anchorEl={anchorEl} placement={'bottom-start'}>
                <Box
                    sx={{
                        mt: 0.25,
                        backgroundColor: 'background.default',
                        border: '1px solid',
                        borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        borderRadius: 1,
                    }}
                >
                    {field.value_type == 'numeric' && (
                        <MenuItem sx={{ position: 'relative', '&:hover .submenu': { display: 'block' } }}>
                            <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                <IconMi icon={'cube'} fontSize="14" sx={{ color: 'primary.main' }} />
                                <Typography>{t('aggregation')}</Typography>
                            </Stack>
                            <IconMi
                                icon={'chevron-right'}
                                sx={{ position: 'absolute', right: 0.5, fontSize: '14px' }}
                            />

                            <Box
                                className={'submenu'}
                                sx={{
                                    position: 'absolute',
                                    left: '100%',
                                    top: 0,
                                    backgroundColor: 'background.default',
                                    display: 'none',
                                    border: '1px solid',
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    borderRadius: 1,
                                }}
                            >
                                {AggregationMethods.concat([{ value: '', label: 'none' }]).map((method) => {
                                    return (
                                        <MenuItem
                                            onClick={(event) => {
                                                elementProps.form.hookFormSetValue('aggregation_method', method.value);
                                            }}
                                        >
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight:
                                                            aggregationMethod == method.value ? 'bold' : 'normal',
                                                    }}
                                                >
                                                    {t(method.label)}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    );
                                })}
                            </Box>
                        </MenuItem>
                    )}
                    {/*Sorting*/}
                    <MenuItem sx={{ position: 'relative', '&:hover .submenu': { display: 'block' } }}>
                        <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                            <IconMi icon={'pipeline'} fontSize="14" sx={{ color: 'primary.main' }} />
                            <Typography>{t('dataset.sorting')}</Typography>
                        </Stack>
                        <IconMi icon={'chevron-right'} sx={{ position: 'absolute', right: 0.5, fontSize: '14px' }} />

                        <Box
                            className={'submenu'}
                            sx={{
                                position: 'absolute',
                                left: '100%',
                                top: 0,
                                backgroundColor: 'background.default',
                                display: 'none',
                                border: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                borderRadius: 1,
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    setSortOrder('ASC');
                                    onClickAway(event);
                                }}
                            >
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi
                                        icon={'arrow-right'}
                                        fontSize="14"
                                        sx={{ color: 'primary.main', transform: 'rotate(90deg)' }}
                                    />
                                    <Typography sx={{ fontWeight: sort == 'ASC' ? 'bold' : 'normal' }}>
                                        {t('dataset.sort_asc')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    setSortOrder('DESC');
                                    onClickAway(event);
                                }}
                            >
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi
                                        icon={'arrow-right'}
                                        fontSize="14"
                                        sx={{ color: 'primary.main', transform: 'rotate(-90deg)' }}
                                    />
                                    <Typography sx={{ fontWeight: sort == 'DESC' ? 'bold' : 'normal' }}>
                                        {t('dataset.sort_desc')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    setSortOrder(false);
                                    onClickAway(event);
                                }}
                            >
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi icon={'times'} fontSize="14" sx={{ color: 'primary.main' }} />
                                    <Typography sx={{ fontWeight: sort == false ? 'bold' : 'normal' }}>
                                        {t('dataset.not_set')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        </Box>
                    </MenuItem>
                    {/*Alignment*/}
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                component: 'FormHidden',
                                name: 'textAlign',
                            },
                        })}
                    />
                    <MenuItem sx={{ position: 'relative', '&:hover .submenu': { display: 'block' } }}>
                        <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                            <IconMi icon={'alignment-left'} fontSize="14" sx={{ color: 'primary.main' }} />
                            <Typography>{t('dataset.alignment')}</Typography>
                        </Stack>
                        <IconMi icon={'chevron-right'} sx={{ position: 'absolute', right: 0.5, fontSize: '14px' }} />
                        <Box
                            className={'submenu'}
                            sx={{
                                position: 'absolute',
                                left: '100%',
                                top: 0,
                                backgroundColor: 'background.default',
                                display: 'none',
                                border: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                borderRadius: 1,
                            }}
                        >
                            <MenuItem onClick={(event) => setTextAlign('left')}>
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi icon={'alignment-left'} fontSize="14" sx={{ color: 'primary.main' }} />
                                    <Typography sx={{ fontWeight: textAlign == 'left' ? 'bold' : 'normal' }}>
                                        {t('dataset.left')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={(event) => setTextAlign('center')}>
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi icon={'alignment-center'} fontSize="14" sx={{ color: 'primary.main' }} />
                                    <Typography sx={{ fontWeight: textAlign == 'center' ? 'bold' : 'normal' }}>
                                        {t('dataset.center')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={(event) => setTextAlign('right')}>
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi icon={'alignment-right'} fontSize="14" sx={{ color: 'primary.main' }} />
                                    <Typography sx={{ fontWeight: textAlign == 'right' ? 'bold' : 'normal' }}>
                                        {t('dataset.right')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        </Box>
                    </MenuItem>
                    {/*Wrap Text*/}
                    {field.value_type != 'numeric' && (
                        <MenuItem onClick={(event) => handleWrapText()}>
                            <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                <IconMi icon={'text-control'} fontSize="14" sx={{ color: 'primary.main' }} />
                                <Typography>{t(textWrap ? 'dataset.unwrap_text' : 'dataset.wrap_text')}</Typography>
                            </Stack>
                        </MenuItem>
                    )}
                    <MenuItem sx={{ p: 0 }}>
                        <Stack
                            direction={'row'}
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{
                                width: 1,
                                '.colorPicker': {
                                    width: 1,
                                },
                                '.colorPickerIcon': {
                                    border: '1px solid',
                                    backgroundColor: (theme) => textColor ?? theme.palette.text.primary,
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    display: 'flex',
                                    p: '2px',
                                    m: '-2px',
                                    color: 'background.default',
                                },
                                '.colorPickerIconHolder': {
                                    width: 1,
                                    px: 2,
                                    py: 0.75,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: (theme) => `${theme.palette.background.default} !important`,
                                    '.colorPickerIconHolderLabel': {
                                        pl: 1,
                                        flexGrow: 1,
                                    },
                                },
                            }}
                        >
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        component: 'ColorPicker',
                                        props: {
                                            pickerType: 'default',
                                            view: 'icon',
                                            icon: { type: 'mi', value: 'underline', fill: 'background' },
                                            buttonText: 'Text Color',
                                            toggleShow: true,
                                        },
                                        name: 'textColor',
                                    },
                                })}
                            />
                        </Stack>
                    </MenuItem>

                    <MenuItem sx={{ p: 0 }}>
                        <Stack
                            direction={'row'}
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{
                                width: 1,
                                '.colorPicker': {
                                    width: 1,
                                },
                                '.colorPickerIcon': {
                                    border: '1px solid',
                                    backgroundColor: bgColor ?? undefined,
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    display: 'flex',
                                    p: '2px',
                                    m: '-2px',
                                    color: 'text.primary',
                                },
                                '.colorPickerIconHolder': {
                                    width: 1,
                                    px: 2,
                                    py: 0.75,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: (theme) => `${theme.palette.background.default} !important`,
                                    '.colorPickerIconHolderLabel': {
                                        pl: 1,
                                        flexGrow: 1,
                                    },
                                },
                            }}
                        >
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        component: 'ColorPicker',
                                        props: {
                                            pickerType: 'default',
                                            view: 'icon',
                                            icon: { type: 'mi', value: 'color-fill', fill: 'background' },
                                            buttonText: 'Fill Color',
                                            toggleShow: true,
                                        },
                                        name: 'bgColor',
                                    },
                                })}
                            />
                        </Stack>
                    </MenuItem>
                    {/*Hide Column*/}
                    <MenuItem
                        onClick={(event) => {
                            handleHideColumn();
                            onClickAway(event);
                        }}
                    >
                        <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                            <IconMi icon={'times'} fontSize="14" sx={{ color: 'primary.main' }} />
                            <Typography>{t('dataset.hide_column')}</Typography>
                        </Stack>
                    </MenuItem>
                    {/*Freeze Column*/}
                    <MenuItem
                        onClick={(event) => {
                            handleFreezeColumn();
                            onClickAway(event);
                        }}
                    >
                        <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                            <IconHandler icon={{ type: 'fa', value: 'snowflake' }} />
                            <Typography>{t(isFrozen ? 'dataset.unfreeze_column' : 'dataset.freeze_column')}</Typography>
                        </Stack>
                    </MenuItem>
                    {/*Edit Column*/}
                    <MenuItem
                        onClick={(event) => {
                            handleEditColumn();
                            onClickAway(event);
                        }}
                    >
                        <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                            <IconMi icon={'gear'} fontSize="14" sx={{ color: 'primary.main' }} />
                            <Typography>{t('dataset.edit_column')}</Typography>
                        </Stack>
                    </MenuItem>
                </Box>
            </Popper>
        </ClickAwayListener>
    );
}
