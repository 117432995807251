import { alpha, CircularProgress, Grid, Stack } from '@mui/material';
import MetricList from './MetricList';
import { MetricItem } from '../index';
import MetricChart from './MetricChart';
import { useQuery } from '@tanstack/react-query';
import { datasetViewAPI } from 'api/viewer/dataset';
import React, { useState } from 'react';

interface IBodyProps {
    metrics: MetricItem[];
    onMetricChange: (metricUid: string) => void;
    activeMetricUid: string;
    onMetricRemove: (metricUid: string) => void;
    filterId: number;
    datasetId: number;
}

export default function Body({
    metrics,
    onMetricChange,
    activeMetricUid,
    onMetricRemove,
    filterId,
    datasetId,
}: IBodyProps) {
    const [onMetricSwitch, setOnMetricSwitch] = useState(false);

    const { data: chartData, isLoading: chartLoading } = useQuery<any, Error>(
        [`${activeMetricUid}-${datasetId}-${filterId}`],
        () => {
            const metric = metrics.find((metric) => metric.uid === activeMetricUid);

            if (datasetId > 0 && metric) {
                if (filterId > 0) {
                    metric.filter = filterId;
                }

                return datasetViewAPI.loadMetricChartData(datasetId, metric);
            }
        },
        {
            staleTime: 1000 * 60,
        }
    );

    const onMetricChangeLocal = (metricUid: string) => {
        onMetricChange('');
        setOnMetricSwitch(true);

        setTimeout(() => {
            onMetricChange(metricUid);
            setOnMetricSwitch(false);
        }, 500);
    };

    return (
        <Grid
            container
            direction="row"
            alignItems={'center'}
            sx={{ borderBottom: '1px solid', borderColor: (theme) => alpha(theme.palette.text.primary, 0.08) }}
        >
            <Grid
                item
                xs={3}
                sx={{
                    borderRight: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                    minHeight: '300px',
                    pt: 2,
                }}
            >
                <MetricList
                    metrics={metrics}
                    onMetricChange={onMetricChangeLocal}
                    activeMetricUid={activeMetricUid}
                    onMetricRemove={onMetricRemove}
                />
            </Grid>
            <Grid item xs={9}>
                {chartLoading || onMetricSwitch ? (
                    <Stack alignItems={'center'} justifyContent={'center'}>
                        <CircularProgress color="inherit" className={'LoadingPlaceholderIcon'} />
                    </Stack>
                ) : (
                    <MetricChart metrics={metrics} activeMetricUid={activeMetricUid} chartData={chartData} />
                )}
            </Grid>
        </Grid>
    );
}
