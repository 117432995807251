import React, { useEffect, useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { AlertTitle, FormLabel, Input, Link, Stack, Typography } from '@mui/material';
import useBundleTranslation from 'i18n';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { FormComponentValue } from 'components/common/form/layout/control';
import Alert from '@mui/material/Alert';
import { DerivedFieldData, DisplayMask } from '../../index';
import { datasetViewAPI } from 'api/viewer/dataset';
import Expression from './Expression';
import { useBasicAutocomplete } from 'components/common/basic-autocomplite/BasicAutocomplite';

function DerivedPopup({
    show,
    setShowPopup,
    onConfirmPopup,
    datasetId,
    names,
    json,
    derivedField,
    fieldsLabels,
}: {
    show: boolean;
    setShowPopup: (value: boolean) => void;
    onConfirmPopup: (data: DerivedFieldData) => void;
    datasetId: number;
    names: string[];
    json: string;
    derivedField?: DerivedFieldData;
    fieldsLabels: string[];
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const popupSettings: PopupSettings = {
        title: t('derived_field.title'),
        textOK: derivedField ? t('derived_field.update_button') : t('derived_field.create_button'),
    };
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [nameError, setNameError] = useState(false);
    const [displayMaskId, setDisplayMaskId] = useState('');
    const [displayMasks, setDisplayMasks] = useState<DisplayMask[]>([]);
    const [displayMasksSelect, setDisplayMasksSelect] = useState<FormComponentValue[]>([]);
    const { expression, setExpression, expressionError, setExpressionError } = useBasicAutocomplete();
    useEffect(() => {
        setLoading(true);
        datasetViewAPI.getDisplayMasks(datasetId).then((response) => {
            setDisplayMasks(response.data.displayMasks);
        });
        if (derivedField) {
            setName(derivedField.name);
            setDisplayMaskId(derivedField.display_mask_id);
            setExpression(derivedField.expression);
        }
    }, []);

    useEffect(() => {
        const selectData: FormComponentValue[] = [
            {
                label: t('derived_field.form.default'),
                value: '',
            },
        ];

        if (displayMasks.length > 0) {
            selectData.push(
                ...displayMasks.map((item) => {
                    return {
                        value: item.display_mask_id.toString(),
                        label: item.display_example,
                    };
                })
            );
        }

        setDisplayMasksSelect(selectData);
        setLoading(false);
    }, [displayMasks]);

    const onHide = () => {
        setShowPopup(false);
    };

    const onConfirm = () => {
        setExpressionError(false);

        if (isValid()) {
            const params: DerivedFieldData = {
                id: name,
                name,
                display_mask_id: displayMaskId,
                expression,
            };

            datasetViewAPI.validateDerivedField(datasetId, params, json).then((response) => {
                if (response.status === 'OK') {
                    params.info = response.info;
                    onConfirmPopup(params);
                    setShowPopup(false);
                }

                if (response.status === 'ERROR') {
                    setErrorMessage(response.message ?? 'ERROR');
                    setExpressionError(true);
                }
            });
        }
    };

    const isValid = (): boolean => {
        if (name === '') {
            setErrorMessage(t('derived_field.validation_error.name'));
            setNameError(true);
            return false;
        }
        if (expression === '') {
            setErrorMessage(t('derived_field.validation_error.expression'));
            setExpressionError(true);
            return false;
        }
        if (names.includes(name)) {
            setErrorMessage(t('derived_field.validation_error.name_exist'));
            setNameError(true);
            return false;
        }

        return true;
    };

    return show ? (
        <Popup settings={popupSettings} open={show} onHide={onHide} onConfirm={onConfirm} maxWidth="popupMd">
            <Stack direction={'column'} spacing={2}>
                {(nameError || expressionError) && (
                    <Alert variant="outlined" severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage}
                    </Alert>
                )}
                <Stack direction={'column'}>
                    <FormLabel>{t('derived_field.form.name')}</FormLabel>
                    <Input
                        value={name}
                        onChange={(event) => {
                            setNameError(false);
                            setName(event.target.value);
                        }}
                        error={nameError}
                    />
                </Stack>
                <Stack direction={'column'}>
                    <FormLabel>{t('derived_field.form.display_mask')}</FormLabel>
                    <ReactSelect
                        isLoading={loading}
                        data={displayMasksSelect}
                        value={displayMaskId}
                        update={(value) => {
                            setDisplayMaskId(value);
                        }}
                    />
                </Stack>
                <Expression
                    label={t('derived_field.form.expression')}
                    expression={expression}
                    setExpression={setExpression}
                    expressionError={expressionError}
                    setExpressionError={setExpressionError}
                    availableOptions={fieldsLabels}
                />
                <Alert variant="outlined" severity="info">
                    <Typography>{`${t('derived_field.autosuggest')} "["`}</Typography>
                    <Typography>
                        {t('derived_field.learn_the')}
                        <Link target="_blank" href={'http://help.metricinsights.com/m/67081/l/722259'}>
                            {t('derived_field.expression_syntax')}
                        </Link>
                    </Typography>
                </Alert>
            </Stack>
        </Popup>
    ) : null;
}

export default DerivedPopup;
