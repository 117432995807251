import React, { useContext, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import { BasicAutocomplete, useBasicAutocomplete } from 'components/common/basic-autocomplite/BasicAutocomplite';

export interface QueryBuilderFieldsPopupProps {
    onClose: () => void;
    onApply: (data: any) => void;
    updateData: (data: any) => void;
    data: any; //strict
    editIndex: number | null;
    editorData: any;
    editorAct: any;
}

export default function QueryBuilderFieldsPopup(props: QueryBuilderFieldsPopupProps) {
    const {
        updateData = () => {},
        onClose = () => {},
        onApply = () => {},
        data,
        editIndex,
        editorData,
        editorAct,
    } = props;
    const { reportData, reportAct, queryData, configData, helperAct, pluginConfig } = useContext(PluginQBContext);

    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);

    const isAdd = editIndex === null;
    const editElement = !isAdd ? data[editIndex] : {};
    const field = !isAdd ? editElement.field : {};

    //ToDo translations?
    const derivedTypes = [
        ...['formula', 'date'],
        ...(pluginConfig.derivedFieldFormatDateTypeIsAvailable ? ['formatted date'] : []),
    ];
    const dateBasedOnList = ['Timestamp'];
    const timestamps = [
        { value: 'Current Time', label: 'Current Time' },
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
    ];

    if (!configData.fiscal) {
        if (!configData.interval || configData.interval === 'minute') {
            timestamps.push(
                { value: 'CurrentMinute', label: 'Current Minute' },
                { value: 'LastMinute', label: 'Last Minute' }
            );
        }

        if (!configData.interval || configData.interval === 'hour') {
            timestamps.push({ value: 'CurrentHour', label: 'Current Hour' }, { value: 'LastHour', label: 'Last Hour' });
        }

        if (!configData.interval || configData.interval === 'week') {
            timestamps.push({ value: 'CurrentWeek', label: 'Current Week' }, { value: 'LastWeek', label: 'Last Week' });
        }

        if (!configData.interval || configData.interval === 'month') {
            timestamps.push(
                { value: 'CurrentMonth', label: 'Current Month' },
                { value: 'LastMonth', label: 'Last Month' }
            );
        }

        if (!configData.interval || configData.interval === 'quarter') {
            timestamps.push(
                { value: 'CurrentQuarter', label: 'Current Quarter' },
                { value: 'LastQuarter', label: 'Last Quarter' }
            );
        }

        if (!configData.interval || configData.interval === 'year') {
            timestamps.push({ value: 'CurrentYear', label: 'Current Year' }, { value: 'LastYear', label: 'Last Year' });
        }
    }

    const dateGranularityList = [];
    const dateYearColumnsList = [...editorData.dateYearColumns];
    const dateMonthColumnsList = [...editorData.dateMonthColumns];
    const dateDayColumnsList = [...editorData.dateDayColumns];
    const dateHourColumnsList = [...editorData.dateHourColumns];

    if (editorData.dateYearColumns.length) {
        dateBasedOnList.unshift(configData.title + ' data');
        dateGranularityList.push('year');

        if (
            !isAdd &&
            field.isConstructedDate &&
            typeof field.date.year === 'string' &&
            field.date.year.match(/^\d+$/g)
        ) {
            dateYearColumnsList.unshift(helperAct.escapeHtml(field.date.year));
            //dateYearColumnsList.unshift('<option value="' + escapeHtml(field.date.year) + '">' + escapeHtml(field.date.year) + '</option>');
        }

        if (editorData.dateMonthColumns.length) {
            dateGranularityList.push('month');

            if (
                !isAdd &&
                field.isConstructedDate &&
                typeof field.date.month === 'string' &&
                field.date.month.match(/^(0[1-9]|1[0-2])$/g)
            ) {
                dateMonthColumnsList.unshift(helperAct.escapeHtml(field.date.month));
                //dateMonthColumnsList.unshift('<option value="' + escapeHtml(field.date.month) + '">' + escapeHtml(field.date.month) + '</option>');
            }

            if (editorData.dateDayColumns.length) {
                dateGranularityList.push('day');

                if (
                    field.isConstructedDate &&
                    typeof field.date.day === 'string' &&
                    field.date.day.match(/^(0[1-9]|[12]\d|3[01])$/g)
                ) {
                    dateDayColumnsList.unshift(helperAct.escapeHtml(field.date.day));
                    //dateDayColumnsList.unshift('<option value="' + escapeHtml(field.date.day) + '">' + escapeHtml(field.date.day) + '</option>');
                }

                if (editorData.dateHourColumns.length) {
                    dateGranularityList.push('hour');

                    if (
                        field.isConstructedDate &&
                        typeof field.date.hour === 'string' &&
                        field.date.hour.match(/^([01]\d|2[0-3])$/g)
                    ) {
                        dateHourColumnsList.unshift(helperAct.escapeHtml(field.date.hour));
                        //dateHourColumnsList.unshift('<option value="' + escapeHtml(field.date.hour) + '">' + escapeHtml(field.date.hour) + '</option>');
                    }
                }
            }
        }
    }

    let defaultDateGranularity = ['year', 'month', 'day', 'hour'].includes(configData.interval)
        ? configData.interval
        : 'day';
    defaultDateGranularity = !dateGranularityList.includes(defaultDateGranularity) ? 'year' : defaultDateGranularity;
    let editDefaultDateGranularity = '';
    if (!isAdd) {
        if (field.date.hour) {
            editDefaultDateGranularity = 'hour';
        } else if (field.date.day) {
            editDefaultDateGranularity = 'day';
        } else if (field.date.month) {
            editDefaultDateGranularity = 'month';
        }
    }

    const setInitialDerivedType = () => {
        if (isAdd || field.isExpression) return 'formula';
        if (pluginConfig.derivedFieldFormatDateTypeIsAvailable && field.date.format) return 'formatted date';
        return 'date';
    };
    const [derivedName, setDerivedName] = useState<string>(isAdd ? '' : field.name);
    const [derivedType, setDerivedType] = useState<string>(setInitialDerivedType());

    const { expression, setExpression, expressionError, setExpressionError } = useBasicAutocomplete(
        isAdd ? '' : field.expr
    );

    const [dateBasedOn, setDateBasedOn] = useState<string>(
        isAdd
            ? 'Timestamp'
            : field.isConstructedDate && field.date.value != 'Date'
            ? 'Timestamp'
            : configData.title + ' data'
    );
    const [dateValue, setDateValue] = useState<string>(
        isAdd ? 'Current Time' : dateBasedOn == 'Timestamp' ? field.date.value : 'Current Time'
    );

    //ToDo Debug edit for year
    const [dateGranularity, setDateGranularity] = useState<string>(
        isAdd
            ? defaultDateGranularity
            : field.isConstructedDate && field.date.value != 'Date'
            ? defaultDateGranularity
            : editDefaultDateGranularity
    );

    const [dateGranularityYear, setDateGranularityYear] = useState<string>(isAdd ? '' : field.date.year);
    const [dateGranularityMonth, setDateGranularityMonth] = useState<string>(
        isAdd ? '' : dateGranularity != 'year' ? field.date.month : ''
    );
    const [dateGranularityDay, setDateGranularityDay] = useState<string>(
        isAdd ? '' : dateGranularity != 'year' && dateGranularity != 'month' ? field.date.day : ''
    );
    const [dateGranularityHour, setDateGranularityHour] = useState<string>(
        isAdd ? '' : dateGranularity == 'hour' ? field.date.hour : ''
    );

    const dateBasedOnOptions = dateBasedOnList.map((item) => {
        return {
            value: item,
            label: item,
        };
    });

    const dateGranularityOptions = dateGranularityList.map((item) => {
        return {
            value: item,
            label: item,
        };
    });
    const dateYearColumns = dateYearColumnsList.map((item: string) => {
        return {
            value: item,
            label: item,
        };
    });
    const dateMonthColumns = dateMonthColumnsList.map((item: string) => {
        return {
            value: item,
            label: item,
        };
    });
    const dateDayColumns = dateDayColumnsList.map((item: string) => {
        return {
            value: item,
            label: item,
        };
    });
    const dateHourColumns = dateHourColumnsList.map((item: string) => {
        return {
            value: item,
            label: item,
        };
    });

    const [formatDateFieldValue, setFormatDateFieldValue] = useState<string>(isAdd ? '' : field.date.value);
    const [formatDateFieldFormat, setFormatDateFieldFormat] = useState<string>(isAdd ? '' : field.date.format);

    const formatDateColumnsList = [...editorData.dateColumns];
    const formatDateColumns = formatDateColumnsList.map((item: string) => {
        return {
            value: item,
            label: item,
        };
    });

    const getNewField = () => {
        if (derivedType == 'formula') {
            if (derivedName.length) {
                if (derivedName.indexOf('=', 0) !== -1) {
                    alert(t('fields_popup.alert_expression_name_can_not_contain_equal'));
                    return false;
                }

                if (!pluginConfig.derivedFieldNameAllowCommaAndDoubleQuote) {
                    if (derivedName.indexOf(',', 0) !== -1) {
                        alert(t('fields_popup.alert_expression_name_can_not_contain_comma'));
                        return false;
                    }

                    if (derivedName.indexOf('"', 0) !== -1) {
                        alert(t('fields_popup.alert_expression_name_can_not_contain_double_quote'));
                        return false;
                    }
                }

                if (
                    reportAct.hasExpression(derivedName) ||
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'EXPR' &&
                            item.field.name == helperAct.escapeSelector(derivedName)
                    ) !== -1
                ) {
                    //escapeHtml(derivedName) for message
                    alert(t('fields_popup.alert_expression_name_duplicate', { name: derivedName }));
                    return false;
                }
            }
            if (!expression.length) {
                alert(t('fields_popup.alert_expression_is_empty'));
                return false;
            }

            if (
                data.findIndex(
                    (item: any) =>
                        item.field.typeOriginal == 'EXPR' && item.field.expr == helperAct.escapeSelector(expression)
                ) !== -1
            ) {
                alert(t('fields_popup.alert_expression_exist'));
                return false;
            }

            const derivedItem = reportAct.addExpression(expression, derivedName);
            editorAct.setCheckedState(derivedName, true);

            if (!derivedItem) {
                alert(t('fields_popup.alert_expression_not_valid_derived'));
                return false;
            }
        } else if (derivedType == 'date') {
            if (derivedName.length) {
                if (derivedName.indexOf('=', 0) !== -1) {
                    alert(t('fields_popup.alert_field_name_can_not_contain_equal'));
                    return false;
                }

                if (!pluginConfig.derivedFieldNameAllowCommaAndDoubleQuote) {
                    if (derivedName.indexOf(',', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_comma'));
                        return false;
                    }

                    if (derivedName.indexOf('"', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_double_quote'));
                        return false;
                    }
                }

                if (
                    reportAct.hasField(derivedName) ||
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' &&
                            item.field.name == helperAct.escapeSelector(derivedName)
                    ) !== -1
                ) {
                    //escapeHtml(derivedName) for message
                    alert(t('fields_popup.alert_duplicate_name_constructed_date', { name: derivedName }));

                    return false;
                }
            }
            let dateObg = {
                value: dateValue,
                year: dateGranularityYear ?? '',
                month: dateGranularityMonth ?? '',
                day: dateGranularityDay ?? '',
                hour: dateGranularityHour ?? '',
            };

            if (dateBasedOn == 'Timestamp') {
                dateObg = {
                    value: dateValue,
                    year: '',
                    month: '',
                    day: '',
                    hour: '',
                };
                if (
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' &&
                            item.field.date.value == helperAct.escapeSelector(dateObg.value)
                    ) !== -1
                ) {
                    alert(t('fields_popup.alert_date_duplicate_timestamp', { value: dateObg.value }));
                    return false;
                }
            } else {
                dateObg.value = 'Date';
                const currentDate = new Date();

                switch (dateGranularity) {
                    // @ts-ignore
                    case 'year':
                        dateObg.month = '';
                    // @ts-ignore
                    case 'month':
                        dateObg.day = '';
                    case 'day':
                        dateObg.hour = '';
                }

                // @ts-ignore
                switch (dateGranularity) {
                    // @ts-ignore
                    case 'hour':
                        if (!dateObg.hour.length) {
                            dateObg.hour = '00';
                        }
                    // @ts-ignore
                    case 'day':
                        if (!dateObg.day.length) {
                            dateObg.day = '01';
                        }
                    // @ts-ignore
                    case 'month':
                        if (!dateObg.month.length) {
                            dateObg.month = '01';
                        }
                    case 'year':
                        if (!dateObg.year.length) {
                            dateObg.year = '' + currentDate.getFullYear();
                        }
                }

                if (
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' &&
                            item.field.date.year == helperAct.escapeSelector(dateObg.year) &&
                            item.field.date.month == helperAct.escapeSelector(dateObg.month) &&
                            item.field.date.day == helperAct.escapeSelector(dateObg.day) &&
                            item.field.date.hour == helperAct.escapeSelector(dateObg.hour)
                    ) !== -1
                ) {
                    alert(t('fields_popup.alert_date_duplicate'));
                    return false;
                }
            }

            const derivedItem = reportAct.addDate(dateObg, derivedName);
            editorAct.setCheckedState(derivedName, true);

            if (!derivedItem) {
                alert(t('fields_popup.alert_date_not_valid'));
                return false;
            }
        } else if (pluginConfig.derivedFieldFormatDateTypeIsAvailable && derivedType == 'formatted date') {
            if (derivedName.length) {
                if (derivedName.indexOf('=', 0) !== -1) {
                    alert(t('fields_popup.alert_field_name_can_not_contain_equal'));
                    return false;
                }

                if (!pluginConfig.derivedFieldNameAllowCommaAndDoubleQuote) {
                    if (derivedName.indexOf(',', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_comma'));
                        return false;
                    }

                    if (derivedName.indexOf('"', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_double_quote'));
                        return false;
                    }
                }

                if (
                    reportAct.hasField(derivedName) ||
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' &&
                            item.field.name == helperAct.escapeSelector(derivedName)
                    ) !== -1
                ) {
                    //escapeHtml(derivedName) for message
                    alert(t('fields_popup.alert_duplicate_name_constructed_date', { name: derivedName }));

                    return false;
                }
            }

            if (!formatDateFieldValue.length) {
                alert(t('fields_popup.alert_format_date_value_can_not_empty'));
                return false;
            }

            if (!formatDateFieldFormat.length) {
                alert(t('fields_popup.alert_format_date_format_can_not_empty'));
                return false;
            }

            const derivedItem = reportAct.addDate(
                {
                    value: formatDateFieldValue,
                    year: '',
                    month: '',
                    day: '',
                    hour: '',
                    format: formatDateFieldFormat,
                },
                derivedName
            );
            editorAct.setCheckedState(derivedName, true);

            if (!derivedItem) {
                alert(t('fields_popup.alert_date_not_valid'));
                return false;
            }
        }
        return true;
    };

    const getUpdatedField = () => {
        const field = editElement.field;
        const metric = editElement.metric;
        //var selected = page.data.selected;
        let name = derivedName;
        var isRename = name.length && field.name !== name;

        if (!name.length) {
            name = field.name;
        }

        if (derivedType == 'formula') {
            if (name.length && isRename) {
                if (name.indexOf('=', 0) !== -1) {
                    alert(t('fields_popup.alert_expression_name_can_not_contain_equal'));
                    return false;
                }

                if (!pluginConfig.derivedFieldNameAllowCommaAndDoubleQuote) {
                    if (name.indexOf(',', 0) !== -1) {
                        alert(t('fields_popup.alert_expression_name_can_not_contain_comma'));
                        return false;
                    }

                    if (name.indexOf('"', 0) !== -1) {
                        alert(t('fields_popup.alert_expression_name_can_not_contain_double_quote'));
                        return false;
                    }
                }

                if (
                    reportAct.hasExpression(name) ||
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'EXPR' && item.field.name == helperAct.escapeSelector(name)
                    ) !== -1
                ) {
                    //escapeHtml(name) for message
                    alert(t('fields_popup.alert_duplicate_name', { name: name }));
                    return false;
                }
            }
            if (!expression.length) {
                alert(t('fields_popup.alert_expression_empty'));
                return false;
            }

            //ToDo (isRename ? '[data-name!="' + escapeSelector(field.name) + '"]' : '')
            if (
                (isRename || field.expr != expression) &&
                data.findIndex(
                    (item: any) =>
                        item.field.typeOriginal == 'EXPR' &&
                        item.field.expr == helperAct.escapeSelector(expression) &&
                        (isRename ? item.field.name != field.name : true)
                ) !== -1
            ) {
                alert(t('fields_popup.alert_expression_duplicate'));
                return false;
            }

            if (!reportAct.isValidExpression(expression)) {
                alert(t('fields_popup.alert_expression_not_valid'));
                return false;
            }

            if (editIndex === null) return false;

            if (isRename) {
                const newFieldData = { ...field, name: name, expr: expression };
                const updatedFields = { ...reportData.fields, [name]: newFieldData };
                delete updatedFields[field.name];

                reportAct.updateData({
                    fields: updatedFields,
                });

                //Save checked state
                const isChecked = editorAct.getCheckedState(field.name, editElement.isChecked);
                const isAutoChecked = editorAct.isFieldAutoChecked(field.name);
                editorAct.removeCheckedState(field.name);
                editorAct.setCheckedState(name, isChecked, isAutoChecked);
            } else {
                const newFieldData = { ...field, expr: expression };
                reportAct.updateData({
                    fields: { ...reportData.fields, [field.name]: newFieldData },
                });
            }
        } else if (derivedType == 'date') {
            if (name.length && isRename) {
                if (name.indexOf('=', 0) !== -1) {
                    alert(t('fields_popup.alert_field_name_can_not_contain_equal'));
                    return false;
                }

                if (!pluginConfig.derivedFieldNameAllowCommaAndDoubleQuote) {
                    if (name.indexOf(',', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_comma'));
                        return false;
                    }

                    if (name.indexOf('"', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_double_quote'));
                        return false;
                    }
                }

                if (
                    reportAct.hasField(name) ||
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' && item.field.name == helperAct.escapeSelector(name)
                    ) !== -1
                ) {
                    //escapeHtml(derivedName) for message
                    alert(t('fields_popup.alert_duplicate_field', { field: derivedName }));

                    return false;
                }
            }
            let dateObg = {
                value: dateValue,
                year: dateGranularityYear ?? '',
                month: dateGranularityMonth ?? '',
                day: dateGranularityDay ?? '',
                hour: dateGranularityHour ?? '',
            };

            if (dateBasedOn == 'Timestamp') {
                dateObg = {
                    value: dateValue,
                    year: '',
                    month: '',
                    day: '',
                    hour: '',
                };
                //ToDo (isRename ? '[data-name!="' + escapeSelector(field.name) + '"]' : '')
                if (
                    (isRename || field.date.value !== dateObg.value) &&
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' &&
                            item.field.date.value == helperAct.escapeSelector(dateObg.value) &&
                            (isRename ? item.field.name != field.name : true)
                    ) !== -1
                ) {
                    alert(t('fields_popup.alert_duplicate_timestamp', { value: dateObg.value }));
                    return false;
                }
            } else {
                dateObg.value = 'Date';

                switch (dateGranularity) {
                    // @ts-ignore
                    case 'year':
                        dateObg.month = '';
                    // @ts-ignore
                    case 'month':
                        dateObg.day = '';
                    case 'day':
                        dateObg.hour = '';
                }

                //ToDo (isRename ? '[data-name!="' + escapeSelector(field.name) + '"]' : '')
                if (
                    (isRename ||
                        field.date.year != dateObg.year ||
                        field.date.month != dateObg.month ||
                        field.date.day != dateObg.day ||
                        field.date.hour != dateObg.hour) &&
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' &&
                            item.field.date.year == helperAct.escapeSelector(dateObg.year) &&
                            item.field.date.month == helperAct.escapeSelector(dateObg.month) &&
                            item.field.date.day == helperAct.escapeSelector(dateObg.day) &&
                            item.field.date.hour == helperAct.escapeSelector(dateObg.hour) &&
                            (isRename ? item.field.name != field.name : true)
                    ) !== -1
                ) {
                    alert(t('fields_popup.alert_duplicate'));
                    return false;
                }
            }

            //ToDo check add
            const date = reportAct.isValidDate(dateObg);
            if (!date) {
                alert(t('fields_popup.alert_date_is_not_valid'));
                return false;
            }

            if (editIndex === null) return false;

            if (isRename) {
                const newFieldData = { ...field, name: name, date: dateObg };
                const updatedFields = { ...reportData.fields, [name]: newFieldData };
                delete updatedFields[field.name];

                reportAct.updateData({
                    fields: updatedFields,
                });

                //Save checked state
                const isChecked = editorAct.getCheckedState(field.name, editElement.isChecked);
                const isAutoChecked = editorAct.isFieldAutoChecked(field.name);
                editorAct.removeCheckedState(field.name);
                editorAct.setCheckedState(name, isChecked, isAutoChecked);
            } else {
                const newFieldData = { ...field, date: dateObg };
                reportAct.updateData({
                    fields: { ...reportData.fields, [field.name]: newFieldData },
                });
            }
        } else if (pluginConfig.derivedFieldFormatDateTypeIsAvailable && derivedType == 'formatted date') {
            if (name.length && isRename) {
                if (name.indexOf('=', 0) !== -1) {
                    alert(t('fields_popup.alert_field_name_can_not_contain_equal'));
                    return false;
                }

                if (!pluginConfig.derivedFieldNameAllowCommaAndDoubleQuote) {
                    if (name.indexOf(',', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_comma'));
                        return false;
                    }

                    if (name.indexOf('"', 0) !== -1) {
                        alert(t('fields_popup.alert_field_name_can_not_contain_double_quote'));
                        return false;
                    }
                }

                if (
                    reportAct.hasField(name) ||
                    data.findIndex(
                        (item: any) =>
                            item.field.typeOriginal == 'DATE' && item.field.name == helperAct.escapeSelector(name)
                    ) !== -1
                ) {
                    //escapeHtml(derivedName) for message
                    alert(t('fields_popup.alert_duplicate_name_constructed_date', { name: derivedName }));

                    return false;
                }
            }

            if (!formatDateFieldValue.length) {
                alert(t('fields_popup.alert_format_date_value_can_not_empty'));
                return false;
            }

            if (!formatDateFieldFormat.length) {
                alert(t('fields_popup.alert_format_date_format_can_not_empty'));
                return false;
            }

            const formatDateObg = {
                value: formatDateFieldValue,
                year: '',
                month: '',
                day: '',
                hour: '',
                format: formatDateFieldFormat,
            };

            const date = reportAct.isValidDate(formatDateObg);
            if (!date) {
                alert(t('fields_popup.alert_format_date_not_valid_edit'));
                return false;
            }

            if (isRename) {
                const newFieldData = { ...field, name: name, date: formatDateObg };
                const updatedFields = { ...reportData.fields, [name]: newFieldData };
                delete updatedFields[field.name];

                reportAct.updateData({
                    fields: updatedFields,
                });

                //Save checked state
                const isChecked = editorAct.getCheckedState(field.name, editElement.isChecked);
                const isAutoChecked = editorAct.isFieldAutoChecked(field.name);
                editorAct.removeCheckedState(field.name);
                editorAct.setCheckedState(name, isChecked, isAutoChecked);
            } else {
                const newFieldData = { ...field, date: formatDateObg };
                reportAct.updateData({
                    fields: { ...reportData.fields, [field.name]: newFieldData },
                });
            }
        }
        return true;
    };

    const [autocompleteValue, setAutocompleteValue] = useState(''); //do not change. Workaround to use as add tags
    const [openSuggestion, setOpenSuggestion] = useState(false);
    //const [autocompleteFieldVal, setAutocompleteFieldVal] = useState<string>('start');
    const [suggestionState, setSuggestionState] = useState({
        startPos: 0,
        endPos: 0,
        search: '',
    });

    const suggestionStateReset = () => {
        setSuggestionState({
            startPos: 0,
            endPos: 0,
            search: '',
        });
    };

    return (
        <Popup
            settings={{
                title: isAdd ? t('fields_popup.add.title') : t('fields_popup.edit.title'),
                textOK: isAdd ? t('fields_popup.add.ob_btn') : t('fields_popup.edit.ob_btn'),
            }}
            open={true}
            onHide={onClose}
            onConfirm={() => {
                const newFieldData = isAdd ? getNewField() : getUpdatedField();
                if (!newFieldData) return false;
                onClose();

                onApply({
                    derivedName: derivedName,
                    derivedType: derivedType,
                    expression: expression,
                    dateBasedOn: dateBasedOn,
                    dateValue: dateValue,
                    dateGranularity: dateGranularity,
                    dateGranularityYear: dateGranularityYear,
                    dateGranularityMonth: dateGranularityMonth,
                    dateGranularityDay: dateGranularityDay,
                    dateGranularityHour: dateGranularityHour,
                });
            }}
        >
            <Box>
                <Stack direction={'row'} spacing={2}>
                    <Box>
                        <Box>{t('fields_popup.label_field_name')}</Box>
                        <Box>
                            <TextField
                                sx={{ width: '350px' }}
                                value={derivedName}
                                onChange={(event) => {
                                    setDerivedName(event.target.value);
                                }}
                            />

                            {isAdd && (
                                <FormControl sx={{ ml: 2 }}>
                                    <RadioGroup
                                        row
                                        name="row-radio-buttons-group"
                                        value={derivedType}
                                        onChange={(event) => {
                                            setDerivedType((event.target as HTMLInputElement).value);
                                        }}
                                    >
                                        {derivedTypes.map((derivedTypeItem) => {
                                            return (
                                                <FormControlLabel
                                                    key={derivedTypeItem}
                                                    value={derivedTypeItem}
                                                    control={<Radio />}
                                                    label={derivedTypeItem}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Box>
                    </Box>
                </Stack>
                {derivedType == 'formula' && (
                    <Box sx={{ mt: 2 }}>
                        <Box>{t('fields_popup.label_field_expression')}</Box>
                        <BasicAutocomplete
                            options={editorData.expressionColumns}
                            expression={expression}
                            setExpression={setExpression}
                            expressionError={expressionError}
                            setExpressionError={setExpressionError}
                            wrapValue={true}
                        />
                        <Box>{t('fields_popup.autosuggest_note')}</Box>
                    </Box>
                )}
                {derivedType == 'date' && (
                    <>
                        <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
                            <Box sx={{ width: '200px' }}>
                                <Box>{t('fields_popup.label_construct_based_on')}</Box>
                                <ReactSelect
                                    data={dateBasedOnOptions}
                                    value={dateBasedOn}
                                    update={(val) => {
                                        setDateBasedOn(val);
                                    }}
                                />
                            </Box>
                        </Stack>
                        {dateBasedOn == 'Timestamp' && (
                            <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
                                <Box sx={{ width: '150px' }}>
                                    <Box>{t('fields_popup.label_date_to_be')}</Box>
                                    <ReactSelect
                                        data={timestamps}
                                        value={dateValue}
                                        update={(val) => {
                                            setDateValue(val);
                                        }}
                                    />
                                </Box>
                            </Stack>
                        )}
                        {dateBasedOn != 'Timestamp' && (
                            <>
                                <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
                                    <Box sx={{ width: '150px' }}>
                                        <Box>{t('fields_popup.label_date_granularity')}</Box>
                                        <ReactSelect
                                            data={dateGranularityOptions}
                                            value={dateGranularity}
                                            update={(val) => {
                                                setDateGranularity(val);
                                            }}
                                        />
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
                                    <Box sx={{ width: '150px' }}>
                                        <Box>{t('fields_popup.label_year')}</Box>
                                        <ReactSelect
                                            data={dateYearColumns}
                                            value={dateGranularityYear}
                                            update={(val) => {
                                                setDateGranularityYear(val);
                                            }}
                                        />
                                    </Box>

                                    {dateGranularity != 'year' && (
                                        <Box sx={{ width: '150px' }}>
                                            <Box>{t('fields_popup.label_month')}</Box>
                                            <ReactSelect
                                                data={dateMonthColumns}
                                                value={dateGranularityMonth}
                                                update={(val) => {
                                                    setDateGranularityMonth(val);
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {dateGranularity != 'year' && dateGranularity != 'month' && (
                                        <Box sx={{ width: '150px' }}>
                                            <Box>{t('fields_popup.label_day')}</Box>
                                            <ReactSelect
                                                data={dateDayColumns}
                                                value={dateGranularityDay}
                                                update={(val) => {
                                                    setDateGranularityDay(val);
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {dateGranularity == 'hour' && (
                                        <Box sx={{ width: '150px' }}>
                                            <Box>{t('fields_popup.label_hour')}</Box>

                                            <ReactSelect
                                                data={dateHourColumns}
                                                value={dateGranularityHour}
                                                update={(val) => {
                                                    setDateGranularityHour(val);
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Stack>
                            </>
                        )}
                    </>
                )}
                {derivedType == 'formatted date' && (
                    <>
                        <Box sx={{ mt: 2 }}>
                            <Stack direction={'row'} spacing={2}>
                                <Box sx={{ width: '250px' }}>
                                    <Box>{t('fields_popup.label_format_date_field')}</Box>
                                    <ReactSelect
                                        data={formatDateColumns}
                                        value={formatDateFieldValue}
                                        update={(val) => {
                                            setFormatDateFieldValue(val);
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Box>{t('fields_popup.label_format_date_field_format')}</Box>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Box>
                                    <TextField
                                        sx={{ width: '350px' }}
                                        value={formatDateFieldFormat}
                                        onChange={(event) => {
                                            setFormatDateFieldFormat(event.target.value);
                                        }}
                                    />
                                </Box>
                                <Box sx={{ pl: 1 }}>
                                    <Box
                                        component={'a'}
                                        href="https://docs.oracle.com/javase/6/docs/api/java/text/SimpleDateFormat.html"
                                        target="_blank"
                                    >
                                        {t('fields_popup.label_format_date_field_format_link_doc')}
                                    </Box>
                                </Box>
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
        </Popup>
    );
}
