import { ElementType } from 'components/element-viewer/index';
import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useState } from 'react';
import FormComponent from 'components/common/form/Form';
import { PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';

export default function ShareButton({
    element,
    segmentValueId,
    isActionMenuItem,
}: {
    element: ElementType;
    segmentValueId: number;
    isActionMenuItem?: boolean;
}) {
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);

    const [isShareForm, setIsShareForm] = useState<boolean>(false);

    const popupSettings: PopupSettings = { title: t('share_now'), textOK: t('share') };

    const getElementTypeLabel = () => {
        let elTypeTranslation = '';
        switch (element.row.type) {
            case 'metric':
                elTypeTranslation = t('metric');
                break;
            case 'multi-metric chart':
                elTypeTranslation = t('multi_metric');
                break;
            case 'internal report':
                elTypeTranslation = t('internal_report');
                break;
            case 'external report':
                elTypeTranslation = t('external_report');
                break;
            case 'other external content':
                elTypeTranslation = t('external_content');
                break;
            default:
                elTypeTranslation = element.row.type;
                break;
        }
        return t('share_btn_tooltip', {
            elementType: elTypeTranslation,
        });
    };

    const onClickShare = () => {
        setIsShareForm(true);
    };

    const formUrl = `/data/element/share/form?element=${element.row.elementId}&segment=${segmentValueId}`;
    return (
        <>
            {isActionMenuItem ? (
                <ActionMenuItem
                    label={getElementTypeLabel()}
                    desc={t('share_btn_tooltip_menu_desc')}
                    icon="share-element"
                    onClick={onClickShare}
                />
            ) : (
                <Tooltip title={getElementTypeLabel()}>
                    <IconButton sx={{ p: '6px' }} onClick={onClickShare}>
                        <IconMi icon="share-element" fontSize="16" />
                    </IconButton>
                </Tooltip>
            )}
            {isShareForm && (
                <FormComponent
                    pk={'share-now'}
                    uid={'share-now'}
                    settingsUrl={formUrl}
                    popupType={'new'}
                    popupSettings={popupSettings}
                    isPopup
                    onHide={() => setIsShareForm(false)}
                />
            )}
        </>
    );
}
