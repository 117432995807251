import { FormElementProps } from 'components/common/form/index';
import { FormsContextType } from 'components/common/form/hooks/useFormContext';
import { getElementFieldValue } from 'components/common/form/formTools';
import {
    FieldPropsPreg,
    FormComponentDependency,
    OtherFormFieldPropsPreg,
} from 'components/common/form/dependency/index';
import translate from 'components/common/form/dependency/transform-function/translate';
import { logWarning } from 'tools/tools';
import { AssocArray } from 'tools/types';
import logicGate from 'components/common/form/dependency/transform-function/logicGate';
import timestamp from 'components/common/form/dependency/transform-function/timestamp';

const functionPref = '$__FUNC__';

type TransformFunctionType = (elementProps: FormElementProps, formsContext: FormsContextType, args: any) => string;
const allowedFunctions: AssocArray<TransformFunctionType> = {
    translate: translate,
    logicGate: logicGate,
    timestamp: timestamp,
};

export function processEffect(
    elementProps: FormElementProps,
    dep: FormComponentDependency,
    formsContext: FormsContextType
) {
    let result: any = {};
    if (!dep.effect) {
        return result;
    }
    for (let [prop, value] of Object.entries(dep.effect)) {
        if (Boolean(value) !== value && Number(value) !== value) {
            value = getAsFieldValue(String(value), elementProps, formsContext);
        }

        const propTree = prop.split('.');
        if (propTree.length == 2) {
            // TODO: only 1 level supported
            result[propTree[0]] = { ...result[propTree[0]] };
            result[propTree[0]][propTree[1]] = value;
        } else {
            result[prop] = value;
        }
    }
    return result;
}

export function getAsFieldValue(value: string, elementProps: FormElementProps, formsContext: FormsContextType): string {
    // Check for custom function expression;
    if (String(value).startsWith(functionPref)) {
        const result = applyTransformFunction(String(value).substring(functionPref.length), elementProps, formsContext);
        return result == null ? value : result;
    }

    const formExpr = OtherFormFieldPropsPreg.exec(value);
    if (formExpr) {
        // Check for other field dependency
        // $form[formUID].fieldName
        const result = getElementFieldValue(elementProps.form, value, formsContext);
        return result == null ? value : result;
    } else {
        // Replace $fieldName.prop?.prop? to its value
        return value.replace(FieldPropsPreg, (match: string, group: string) => {
            const result = getElementFieldValue(elementProps.form, group, formsContext);
            return result == null ? match : result;
        });
    }
}

function applyTransformFunction(functionArgs: string, elementProps: FormElementProps, formsContext: FormsContextType) {
    let funcData;
    try {
        funcData = JSON.parse(functionArgs) as Array<string>;
    } catch (e) {
        logWarning('Form: wrong JSON data for Transform Function: ' + functionArgs);
        return null;
    }

    if (funcData.length == 0) {
        logWarning('Form: empty JSON data for Transform Function: ' + functionArgs);
        return null;
    }

    const name = funcData.shift() as string;
    if (!Object(allowedFunctions).hasOwnProperty(name)) {
        logWarning('Form: wrong Transform Function name: ' + functionArgs);
        return null;
    }
    return allowedFunctions[name](elementProps, formsContext, funcData);
}
