import { configureStore } from '@reduxjs/toolkit';
import setAuthReducer from './auth';
import popupReducer from './popupSlice';
import breadcrumbsReducer from './breadcrumbsSlice';
import pageMenuReducer from './pageMenuSlice';
import globalSearchReducer from './globalSearchSlice';
import themeEditorReducer from './themeEditorSlice';
import debugReducer from './debugSlice';
import formReducer from './formSlice';
import externalReportReducer from './externalReport';
import reportPaletteReducer from './reportPaletteSlice';
import rebuildUserAccessReducer from './rebuildUserAccessSlice';
import fontReducer from './fontSlice';
import helpLinkReducer from './helpLinkSlice';
import pageTitleReducer from './pageTitleSlice';
import embeddingConfSliceReducer from './embeddingConfSlice';

export const store = configureStore({
    reducer: {
        auth: setAuthReducer,
        popup: popupReducer,
        breadcrumbs: breadcrumbsReducer,
        pageMenu: pageMenuReducer,
        globalSearch: globalSearchReducer,
        themeEditor: themeEditorReducer,
        debug: debugReducer,
        form: formReducer,
        externalReport: externalReportReducer,
        reportPalette: reportPaletteReducer,
        rebuildUA: rebuildUserAccessReducer,
        font: fontReducer,
        helpLink: helpLinkReducer,
        pageTitle: pageTitleReducer,
        embeddingConf: embeddingConfSliceReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
