import { MetricResponse } from './index';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { Box, Button, Stack, Typography } from '@mui/material';
import StaticInfo from 'components/common/StaticInfo';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBundleTranslation from 'i18n';
import MITable from 'components/common/grid/MITable';
import { ColumnType, GridData } from 'components/common/grid';

interface IResultPopupProps {
    metricsList: MetricResponse[];
    onClose: () => void;
}

export default function ResultPopup({ metricsList, onClose }: IResultPopupProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const successMetrics = metricsList.filter((metric) => metric.id !== undefined);
    const errorMetrics = metricsList.filter((metric) => metric.id === undefined);

    const navigate = useNavigate();
    const successCount = successMetrics.length;
    const errorsCount = errorMetrics.length;

    const popupSettings: PopupSettings = {
        title: `${successCount} ${t('add_metric.metric', { count: successCount })} ${t('add_metric.result.built')}`,
        textOK: successCount > 0 ? t('done') : t('ok'),
        noCancel: true,
        subTitle: `${successCount} ${t('add_metric.metric', { count: successCount })} ${t(
            'add_metric.result.created'
        )}`,
    };

    const onViewOnHomepage = () => {
        navigate('/home');
        onClose();
    };

    const columns: ColumnType[] = [
        {
            name: 'name',
            title: t('name'),
        },
        {
            name: 'edit',
            title: '',
            width: 40,
        },
    ];
    const gridData: GridData = successMetrics.map((metric) => {
        return {
            name: {
                component: 'link',
                config: {
                    text: metric.name,
                    href: `/editor/element/metric/${metric.id}`,
                },
            },
            edit: {
                component: 'link',
                config: {
                    href: `/editor/element/metric/${metric.id}`,
                    icon: {
                        type: 'mi',
                        value: 'gear',
                        size: 16,
                        color: 'text.primary',
                    },
                },
            },
        };
    });

    return (
        <Popup
            open
            settings={popupSettings}
            onHide={onClose}
            key={'metrics-result-popup'}
            onConfirm={onClose}
            additionalButtons={[
                <Button variant="outlined" onClick={onViewOnHomepage}>
                    {t('add_metric.result.homepage')}
                </Button>,
            ]}
            maxWidth={'popupMd'}
        >
            <Stack spacing={1}>
                {errorsCount > 0 && (
                    <>
                        <Typography>{`${errorsCount} ${t('add_metric.metric', { count: errorsCount })} ${t(
                            'add_metric.result.failed'
                        )}`}</Typography>
                        <Stack>
                            {errorMetrics.map((metric) => (
                                <Box sx={{ mb: 2 }}>
                                    <StaticInfo type={'error'} label={`${metric.name}: ${metric.errormessage}`} />
                                </Box>
                            ))}
                        </Stack>
                    </>
                )}
                {successCount > 0 && (
                    <Stack>
                        <MITable
                            gridName={'dataset_create_metrics_success'}
                            columns={columns}
                            data={gridData}
                            massActionsConfig={{ enabled: false }}
                            autoHeight
                            columnsWidth={{
                                edit: 50,
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </Popup>
    );
}
