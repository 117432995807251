import { components } from 'react-select';
import React from 'react';

// Default template for Option, override with props.optionComponent
export const ReactSelectOption = (props: any) => {
    return (
        <>
            <components.Option {...props}>
                {props.data.icon}
                {props.children}
            </components.Option>
        </>
    );
};
