import { APIResponse } from 'tools/types';
import { instance } from 'api/api';
import {
    ChartCompareLineSeriesTypeRaw,
    ChartOverlaySeriesTypeRaw,
    ChartPoint,
    ChartPointSeriesMetadata,
    MetricChartCompareLineType,
    MetricChartOverlayType,
    MetricChartState,
    MetricChartViewsMode,
} from 'components/metric/chart';

import { RegularSeriesTooltipData } from 'components/metric/chart/tooltip/RegularSeriesTooltip';

export const viewerMetricAPI = {
    async setSummaryTabsState(elementId: number, isOpen: boolean): Promise<any> {
        const response = await instance.post<APIResponse<any>>('data/viewer/metric/set-summary-tabs-state', {
            elementId: elementId,
            state: isOpen ? 'Y' : 'N',
        });
        return response.data.data;
    },
};

export const metricAPI = {
    async getPoint(
        point: ChartPoint,
        uco: number,
        isCorePoint: boolean,
        isAssocPoint: boolean,
        actualView: MetricChartViewsMode,
        parentElementId: number = 0,
        parentSegmentValueId: number = 0
    ): Promise<RegularSeriesTooltipData> {
        const metadata: ChartPointSeriesMetadata = point.metadata;
        const response = await instance.post<APIResponse<RegularSeriesTooltipData>>(
            `data/metric/point-data/element/${metadata.metric_id}/segment/${metadata.segment_value_id}/uco/${uco}/point/${metadata.metric_instance_id}`,
            {
                view: actualView,
                is_core_point: isCorePoint ? 'Y' : 'N',
                assoc_point: isAssocPoint ? 'Y' : 'N',
                parent: parentElementId,
                parent_segment: parentSegmentValueId,
            }
        );

        return response.data.data;
    },

    async getCompareLines(
        elementId: number,
        segmentValueId: number,
        compareLines: Array<number>
    ): Promise<Array<ChartCompareLineSeriesTypeRaw>> {
        const response = await instance.post<APIResponse<Array<ChartCompareLineSeriesTypeRaw>>>(
            `data/metric/compare-lines-data/element/${elementId}/segment/${segmentValueId}`,
            { compareLines: compareLines }
        );

        return response.data.data;
    },

    async getOverlays(
        elementId: number,
        segmentValueId: number,
        overlaysState: Array<MetricChartOverlayType>
    ): Promise<Array<ChartOverlaySeriesTypeRaw>> {
        const response = await instance.post<APIResponse<Array<ChartOverlaySeriesTypeRaw>>>(
            `data/metric/overlays-data/element/${elementId}/segment/${segmentValueId}`,
            { elements: overlaysState }
        );

        return response.data.data;
    },

    async getCompareLinesInfo(
        element: number,
        compareLines: Array<number>
    ): Promise<Array<MetricChartCompareLineType>> {
        const response = await instance.post<APIResponse<Array<MetricChartCompareLineType>>>(
            `data/element/overlay-metrics/compare-lines-info/${element}`,
            { compareLines: compareLines }
        );

        return response.data.data;
    },

    async saveChartState(element: number, segment: number, uco: number, chartState: MetricChartState): Promise<any> {
        const response = await instance.post<APIResponse<any>>(
            `data/metric/save-chart-state/${element}/${segment}/${uco}`,
            {
                chartState: JSON.stringify(chartState),
            }
        );
        return response.data.data;
    },
};
