import { AnyElementsViewerDataType, ElementRowData, ElementType } from 'components/element-viewer';
import useBundleTranslation from 'i18n';
import { MetricViewerDataType } from 'components/metric/MetricViewer';
import { Box, Stack } from '@mui/material';
import styles from './ElementPreviewSummary.styles';
import IconMi from 'components/common/icon/IconMi';
import ElementTopics from 'components/element-viewer/element-topics/ElementTopics';
import EmailLine from 'components/element-viewer/common/EmailLine';
import React, { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import Moment from 'moment';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import isOwnerVisible from 'tools/owners';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';

export interface IElementPreviewSummary {
    elementInfo: ElementType;
    viewerRequestExtraData: AnyElementsViewerDataType;
    onToggleVisibility: () => void;
    isVisible: boolean;
    isPreview?: boolean;
}

export default function ElementPreviewSummary({
    elementInfo,
    isPreview,
    isVisible,
    onToggleVisibility,
    viewerRequestExtraData,
}: IElementPreviewSummary) {
    let sections = null;

    const { configuration }: UserAuth = useAppSelector((state) => state.auth);
    const { t } = useBundleTranslation(['components/common/element']);
    const [descriptionCut, setDescriptionCut] = useState<boolean>(false);
    const [descriptionExpand, setDescriptionExpand] = useState<boolean>(false);
    const descriptionContentRef = React.useRef<HTMLDivElement>(null);
    const showOwnersConfig = !useConfigConstantValue('HIDE_OWNER_FIELDS');

    const showOwners =
        showOwnersConfig &&
        (isOwnerVisible(configuration.owners.business_owner, 'elements') ||
            isOwnerVisible(configuration.owners.technical_owner, 'elements') ||
            isOwnerVisible(configuration.owners.data_steward, 'elements'));

    if (['metric', 'multi-metric chart'].includes(elementInfo.row.type)) {
        sections = (
            <MetricChartSummarySection
                t={t}
                elementRow={elementInfo.row}
                viewerRequestExtraData={viewerRequestExtraData as MetricViewerDataType}
            />
        );
    }
    const lastUpdate = elementInfo.row.lastDisplayGenerationTime?.length
        ? Moment(elementInfo.row.lastDisplayGenerationTime).format('MMMM DD, yyyy h:m')
        : '';

    const maxDescContainerHeight = 90; //5 rows (1row = 18px)

    useEffect(() => {
        if (descriptionContentRef.current) {
            setDescriptionCut(descriptionContentRef.current.clientHeight > maxDescContainerHeight);
        }
    }, []);

    return (
        <>
            {isVisible && (
                <Box
                    sx={{ width: '360px', ml: 3, flexShrink: 0 }}
                    className={isPreview ? 'summary--preview' : 'summary--default'}
                >
                    <Stack sx={styles.section}>
                        <Stack sx={styles.heading} spacing={1} direction="row" justifyContent="space-between">
                            <Box>{t('summary.summary')}</Box>
                            {!isPreview && (
                                <Box sx={styles.closeIconHandler}>
                                    <IconMi
                                        onClick={onToggleVisibility}
                                        icon={'times'}
                                        fontSize="16"
                                        sx={styles.closeIcon}
                                    />
                                </Box>
                            )}
                        </Stack>
                        {elementInfo.row.description && (
                            <Stack sx={{ ...styles.row, flexDirection: 'column' }}>
                                <Box
                                    sx={{
                                        ...styles.descriptionHolder,
                                        maxHeight:
                                            descriptionCut && !descriptionExpand
                                                ? `${maxDescContainerHeight}px`
                                                : 'none',
                                    }}
                                >
                                    <Box ref={descriptionContentRef}>
                                        {elementInfo.row.descriptionMarkdownInd ? (
                                            <span
                                                className={'markdown-holder'}
                                                dangerouslySetInnerHTML={{
                                                    __html: elementInfo.row.descriptionMarkdown,
                                                }}
                                            />
                                        ) : (
                                            elementInfo.row.description
                                        )}
                                    </Box>
                                </Box>
                                {descriptionCut && (
                                    <Box
                                        onClick={() => {
                                            setDescriptionExpand(!descriptionExpand);
                                        }}
                                        sx={styles.descriptionExpandControl}
                                    >
                                        {descriptionExpand ? t('description_show_less') : t('description_show_more')}
                                    </Box>
                                )}
                            </Stack>
                        )}
                        {elementInfo.certification.enabled && (
                            <Stack sx={styles.row}>
                                <Box sx={styles.value}>
                                    <IconMi
                                        icon="certified"
                                        sx={{
                                            ...styles.icon,
                                            color: elementInfo.certification.color,
                                        }}
                                    />
                                    <Box component="strong" sx={{ color: elementInfo.certification.color }}>
                                        {elementInfo.certification?.name ?? ''} {t('summary.certified')}
                                    </Box>{' '}
                                    {t('summary.by')} {elementInfo.certification.lastUpdatedBy} {t('summary.on')}{' '}
                                    {elementInfo.certification.lastUpdatedTime}
                                </Box>
                            </Stack>
                        )}
                        {sections}
                        <ElementTopics styles={styles} topics={elementInfo.topics} />
                        {showOwners && (
                            <>
                                {isOwnerVisible(configuration.owners.business_owner, 'elements') && (
                                    <Stack sx={styles.row}>
                                        <Box sx={styles.label}>{configuration.owners.business_owner.label}</Box>
                                        <Box sx={styles.value}>
                                            <EmailLine
                                                email={elementInfo.info.businessOwnerEmail}
                                                ownerName={elementInfo.info.businessOwnerDn}
                                                elementName={elementInfo.row.name}
                                            />
                                        </Box>
                                    </Stack>
                                )}
                                {isOwnerVisible(configuration.owners.data_steward, 'elements') &&
                                    elementInfo.info.dataStewardDn?.length && (
                                        <Stack sx={styles.row}>
                                            <Box sx={styles.label}>{configuration.owners.data_steward.label}</Box>
                                            <Box sx={styles.value}>
                                                <EmailLine
                                                    email={elementInfo.info.dataStewardEmail}
                                                    ownerName={elementInfo.info.dataStewardDn}
                                                    elementName={elementInfo.row.name}
                                                />
                                            </Box>
                                        </Stack>
                                    )}
                                {isOwnerVisible(configuration.owners.technical_owner, 'elements') && (
                                    <Stack sx={styles.row}>
                                        <Box sx={styles.label}>{configuration.owners.technical_owner.label}</Box>
                                        <Box sx={styles.value}>
                                            <EmailLine
                                                email={elementInfo.info.technicalOwnerEmail}
                                                ownerName={elementInfo.info.technicalOwnerDn}
                                                elementName={elementInfo.row.name}
                                            />
                                        </Box>
                                    </Stack>
                                )}
                            </>
                        )}

                        {elementInfo.row.lastMeasurementTimeFormatted?.length > 0 && (
                            <Stack sx={styles.row}>
                                <Box sx={styles.label}>{t('summary.last_data_point')}</Box>
                                <Box sx={styles.value}>{elementInfo.row.lastMeasurementTimeFormatted}</Box>
                            </Stack>
                        )}
                        {lastUpdate.length > 0 && (
                            <Stack sx={styles.row}>
                                <Box sx={styles.label}>{t('summary.last_updated')}</Box>
                                <Box sx={styles.value}>{lastUpdate}</Box>
                            </Stack>
                        )}
                        <Stack sx={styles.row}>
                            <Box sx={styles.label}>
                                <IconMi icon="engagement" sx={{ ...styles.icon }} />
                                {t('engagement.engagement')}
                            </Box>
                            <Box sx={styles.value}>{elementInfo.engagement}</Box>
                        </Stack>
                        {elementInfo.customFields.map((section) => (
                            <Stack key={section.cfs_id} sx={styles.section}>
                                <Stack sx={styles.heading}>{section.label}</Stack>
                                {section.fields.map((field) => {
                                    const value = Object.values(field.values).join(', ');
                                    return (
                                        <Stack key={field.id} sx={styles.row}>
                                            <Box sx={styles.label}>{field.name}</Box>
                                            {field.type == 'email' ? (
                                                <Box
                                                    dangerouslySetInnerHTML={{ __html: value }}
                                                    sx={styles.value}
                                                ></Box>
                                            ) : (
                                                <Box
                                                    sx={styles.value}
                                                    className={'markdown-holder'}
                                                    dangerouslySetInnerHTML={{
                                                        __html: value,
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            )}
        </>
    );
}

function MetricChartSummarySection({
    elementRow,
    viewerRequestExtraData,
    t,
}: {
    elementRow: ElementRowData;
    viewerRequestExtraData: MetricViewerDataType;
    t: TFunction;
}) {
    if (viewerRequestExtraData.metrics.length == 0) {
        // At least one metric always should be present
        return <div></div>;
    }
    const metric = viewerRequestExtraData.metrics[0];
    return (
        <>
            <Stack sx={styles.row}>
                <Box sx={styles.label}>{t('summary.current_value')}</Box>
                <Box sx={styles.value}>
                    <Box component="strong">{metric.currValue}</Box> ({elementRow.lastMeasurementTimeFormatted})
                </Box>
            </Stack>
            {metric.metricTileDisplayPctVariance != null && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>% {t('summary.change')}</Box>
                    <Box sx={styles.value}>
                        {metric.metricTileDisplayPctVariance != 0
                            ? metric.metricTileDisplayPctVariance > 0
                                ? t('summary.up')
                                : t('summary.down')
                            : ''}{' '}
                        {Math.abs(metric.metricTileDisplayPctVariance).toFixed(2)}%{' '}
                        {'from last day' == metric.pctVarianceText
                            ? t('summary.from_yesterday')
                            : metric.pctVarianceText}
                    </Box>
                </Stack>
            )}
            {metric.movingAverageValue && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>
                        {metric.metricMovingAverageInterval} {t('summary.avg')}
                    </Box>
                    <Box sx={styles.value}>{metric.movingAverageValue}</Box>
                </Stack>
            )}
            {metric.minValue && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>{t('summary.min_ever')}</Box>
                    <Box sx={styles.value}>
                        <Box component="strong">{metric.minValue}</Box>{' '}
                        {metric.minReachedOn && <>({metric.minReachedOn})</>}
                    </Box>
                </Stack>
            )}
            {metric.maxValue && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>{t('summary.max_ever')}</Box>
                    <Box sx={styles.value}>
                        <Box component="strong">{metric.maxValue}</Box>{' '}
                        {metric.maxReachedOn && <>({metric.maxReachedOn})</>}
                    </Box>
                </Stack>
            )}
        </>
    );
}
