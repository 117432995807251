import React, { useContext, useEffect, useMemo, useState } from 'react';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import ComponentSettingsChartMeasureField = ReportContentNS.ComponentSettingsChartMeasureField;
import ComponentSettingsLineChart = ReportContentNS.ComponentSettingsLineChart;
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { AssocArray } from 'tools/types';
import { RawFormComponentType, FormDataAPIType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { getMeasureFieldOptionForFormUId, MeasureFieldSortingContext } from './edit-panel';
import MeasureFieldsList from './edit-panel/MeasureFieldsList';
import { arrayMoveImmutable } from 'array-move';
import { Alert, alpha, Box, Button, Chip, Divider, FormLabel, Stack } from '@mui/material';
import MeasureFieldForm from './edit-panel/MeasureFieldForm';
import ComponentSettingsLineChartAxis = ReportContentNS.ComponentSettingsLineChartAxis;
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import IconMi from 'components/common/icon/IconMi';
import generateMeasureFieldId = ReportContentNS.generateMeasureFieldId;
import fieldToChartMeasuredField = ReportContentNS.fieldToChartMeasuredField;
import getFirstAllowedFieldAsMeasure = ReportContentNS.getFirstAllowedFieldAsMeasure;
import { useBlendColors } from 'hooks/useDesign';
import SvgIcon from '@mui/material/SvgIcon';
import * as AllIcons from './edit-panel/LineChartEditPanelIcons';
import DatasetField = ReportContentNS.DatasetField;
import validateLineChart from 'components/report-content/components/line-chart/edit-panel/validateLineChart';
import useHandleEditPanelError from 'components/report-content/hooks/useHandleEditPanelError';
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';
import usePalette from 'components/report-content/hooks/usePalette';
import Highcharts from 'highcharts/highstock';

// Field for form
const componentFormFields: Array<keyof ComponentSettingsLineChart> = [
    'display_label',
    'customYAxis',
    'title',
    'show_in_viewer',
    'include_in_email',
    'include_in_email_attachment',
    'series',
    'bar_order',
    'bar_orientation',
    'stacking',
    'group_field',
    'combine_tooltips',
];
const componentNumericFormField: Array<keyof ComponentSettingsLineChart> = ['minY', 'maxY', 'height', 'fillOpacity'];

const getSectionIcon = (iconName: string) => {
    // @ts-ignore
    const componentSvg = AllIcons[iconName];
    return (
        <SvgIcon
            className={'svg-icon-mi'}
            component={componentSvg}
            inheritViewBox
            sx={{
                fontSize: '32px',
                fill: (theme) => useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                color: (theme) => theme.palette.primary.main,
            }}
        />
    );
};

const ucFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
export default function LineChartEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsLineChart> & { block: BlockType<ComponentSettingsLineChart> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const { editPanel, setMessage, message } = useHandleEditPanelError();
    const datasetFields = editPanelContext?.datasetFields ?? [];

    const hasSnapshot = contentSettings.instances.length > 0;

    const { getPaletteColor } = usePalette(
        contentSettings.context,
        contentSettings.elementId,
        Highcharts.getOptions().colors as Array<string>
    );
    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process measure fields settings
        const fields = measureFields.map((field) => {
            return {
                ...field,
                display_type: elementProps.form.hookFormGetValues(
                    getMeasureFieldOptionForFormUId(field, 'display_type')
                ),
                color: elementProps.form.hookFormGetValues(getMeasureFieldOptionForFormUId(field, 'color')),
                aggregation_function: elementProps.form.hookFormGetValues(
                    getMeasureFieldOptionForFormUId(field, 'aggregation_function')
                ),
            };
        });

        if (typeof yAxis[0] != 'undefined') {
            yAxis[0].title = elementProps.form.hookFormGetValues('yAxis0Title');
        }
        if (typeof yAxis[1] != 'undefined') {
            yAxis[1].title = elementProps.form.hookFormGetValues('yAxis1Title');
        }

        const newSettings: ComponentSettingsLineChart = {
            ...component.settings,
            x_axis: { ...xAxis, title: elementProps.form.hookFormGetValues('xAxisTitle') },
            y_axis: yAxis,
            measureFields: fields,
        } as ComponentSettingsLineChart;

        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

        let dataQueries: ComponentSettingsChartDataQueries = {
            table: {
                resultFields: [newSettings.x_axis.field],
                resultFieldAggregations: {},
                sort: [{ field: newSettings.x_axis.field, dir: 'ASC' }],
            },
        };

        newSettings.queryAgainstEntireDataset = newSettings.x_axis.field == 'measurement_time' ? 'Y' : 'N';
        // Build new dataQueries object (legacy format)
        if (
            'multiple' === newSettings.series &&
            1 === newSettings.measureFields.length &&
            newSettings.group_field != '' &&
            null !== newSettings.group_field &&
            typeof newSettings.group_field != 'undefined'
        ) {
            dataQueries['table'].resultFields.push(newSettings.group_field);

            if ('N' === newSettings.show_all_series) {
                const field = datasetFields.find((f) => f.reference_name == newSettings.group_field);
                if (field?.value_type === 'datetime') {
                    newSettings.system_other_grouping_label = '1970-01-01 00:00:11';
                } else if (field?.value_type === 'numeric') {
                    newSettings.system_other_grouping_label = '987654321';
                } else {
                    newSettings.system_other_grouping_label = '__system_other_grouping';
                }

                dataQueries.table.top = {
                    field: newSettings.group_field,
                    count: newSettings.show_top_n_series,
                    label: newSettings.system_other_grouping_label,
                    dir: newSettings.show_top_n_dir,
                };

                if (isHighstockChart && 'Y' != newSettings.queryAgainstEntireDataset) {
                    dataQueries.table.top.sort_field = newSettings.x_axis.field;
                }
            }
        }

        newSettings.measureFields.forEach((field) => {
            dataQueries['table'].resultFields.push(field.reference_name);
            dataQueries['table'].resultFieldAggregations[field.reference_name] = field.aggregation_function;
        });

        const resultSettings = {
            ...newSettings,
            dataQueries: dataQueries,
        } as ComponentSettingsLineChart;

        const validationMsg = validateLineChart(resultSettings, t);
        setMessage(validationMsg);
        if (!validationMsg) {
            editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
        }
    };

    const getInitialMeasureFields = (): Array<ComponentSettingsChartMeasureField> => {
        // If MeasureFields are empty - add first fields as measure;
        if (component.settings.measureFields.length == 0) {
            const measureField = getFirstAllowedFieldAsMeasure(datasetFields, [], fieldToChartMeasuredField);
            if (!measureField) {
                return [];
            }
            measureField.color = getPaletteColor(measureField.reference_name, 0, false) ?? '';
            return [measureField];
        } else {
            // Legacy measure fields has no unique id field
            return component.settings.measureFields.map((f) => ({ ...f, uId: f.uId ?? generateMeasureFieldId() }));
        }
    };
    const [measureFields, setMeasureFields] = useState<Array<ComponentSettingsChartMeasureField>>(
        getInitialMeasureFields()
    );

    const getInitialXAxis = () => {
        let xAxis = component.settings.x_axis;
        if (xAxis.field == '') {
            xAxis.defaultZoom = 'all';

            if (hasSnapshot) {
                xAxis.field = 'measurement_time';
                xAxis.value_type = 'datetime';
                xAxis.display_mask_id = '0';
                return xAxis;
            }

            let field = datasetFields.find((f) => f.value_type != 'numeric');
            if (!field) {
                field = datasetFields[0];
            }
            xAxis.field = field.reference_name;
            xAxis.value_type = field.value_type;
            xAxis.display_mask_id = field.display_mask_id ?? '0';
        }
        return xAxis;
    };
    const [xAxis, setXAxis] = useState<ComponentSettingsLineChartAxis>(getInitialXAxis());
    const [yAxis, setYAxis] = useState<Array<ComponentSettingsLineChartAxis>>(component.settings.y_axis ?? []);

    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        xAxisField: xAxis.field,
        xAxisTitle: xAxis.title,
        yAxis0Title: yAxis[0]?.title ?? '',
        yAxis1Title: yAxis[1]?.title ?? '',
        xAxisFieldZoom: xAxis.defaultZoom ?? '',
    };
    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));
    measureFields.forEach((field) => {
        defaultState[getMeasureFieldOptionForFormUId(field, 'display_type')] = field.display_type ?? 'line';
        defaultState[getMeasureFieldOptionForFormUId(field, 'color')] = field.color;
        defaultState[getMeasureFieldOptionForFormUId(field, 'aggregation_function')] = field.aggregation_function;
    });

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Measurement fields
    const [selectedMeasure, setSelectedMeasure] = useState<ComponentSettingsChartMeasureField | null>();
    const handleSelectMeasureField = (field: ComponentSettingsChartMeasureField) => {
        setSelectedMeasure(field);
    };
    const handleMeasureFieldsSort = (oldIndex: number, newIndex: number) => {
        setMeasureFields(arrayMoveImmutable(measureFields, oldIndex, newIndex));
    };
    const handleMeasureFieldUpdate = (field: ComponentSettingsChartMeasureField) => {
        const index = measureFields.findIndex((f) => f.uId == field.uId);
        if (index == -1) {
            return;
        }
        const list = measureFields.slice();
        list[index] = field;
        setMeasureFields(list);
        setSelectedMeasure(null);
    };

    const handleAddMeasureField = () => {
        const measureField = getFirstAllowedFieldAsMeasure(datasetFields, measureFields, fieldToChartMeasuredField);
        if (!measureField) {
            return;
        }
        for (let i = 0; i < 100; i++) {
            let color = getPaletteColor(measureField.reference_name, i, false) ?? '';
            if (measureFields.findIndex((f) => f.color == color) == -1) {
                measureField.color = color;
                break;
            }
        }
        // Set default display type
        const fieldFormDisplayType = getMeasureFieldOptionForFormUId(measureField, 'display_type');
        elementProps.form.hookFormSetValue(fieldFormDisplayType, measureField.display_type);

        const list = measureFields.slice();
        list.push(measureField);
        setMeasureFields(list);
    };

    const handleRemoveMeasureField = (field: ComponentSettingsChartMeasureField) => {
        const index = measureFields.findIndex((f) => f.uId == field.uId);
        if (index == -1) {
            return false;
        }
        const list = measureFields.slice();
        list.splice(index, 1);
        setMeasureFields(list);
    };

    // Add or remove right axis according to measures field configuration
    useEffect(() => {
        let hasRightAxis = false;
        measureFields.every((f) => {
            if (f.y_axis == 'right') {
                hasRightAxis = true;
                return false;
            }
            return true;
        });
        if (hasRightAxis && yAxis.length == 1) {
            // Add new axis
            const axis = yAxis.slice();
            axis.push({
                defaultZoom: '',
                display_mask_id: '',
                field: '',
                title: '',
                value_type: 'text',
            });
            setYAxis(axis);
        } else if (yAxis.length == 2) {
            // Remove axis
            const axis = yAxis.slice();
            axis.splice(1, 1);
            setYAxis(axis);
        }
    }, [measureFields]);

    // xAxis settings
    const xAxisFieldName = elementProps.form.hookFormWatch('xAxisField');
    useEffect(() => {
        let field = datasetFields.find((f) => f.reference_name_escaped == xAxisFieldName);
        if (xAxisFieldName == 'measurement_time') {
            field = { value_type: 'datetime', display_mask_id: '' } as DatasetField;
        }
        setXAxis((prev) => {
            if (!field) {
                return prev;
            }
            return {
                ...prev,
                field: xAxisFieldName,
                value_type: field.value_type,
                display_mask_id: field.display_mask_id ?? '',
            };
        });
    }, [xAxisFieldName]);

    const xAxisFieldZoom = elementProps.form.hookFormWatch('xAxisFieldZoom');
    useEffect(() => {
        setXAxis((prev) => {
            return {
                ...prev,
                defaultZoom: xAxisFieldZoom,
            };
        });
    }, [xAxisFieldZoom]);

    const isCustomYAxis = elementProps.form.hookFormWatch('customYAxis') == 'Y';

    let stackingIcon = 'line';
    if (measureFields.length && measureFields[0].display_type) {
        const fieldFormDisplayType = getMeasureFieldOptionForFormUId(measureFields[0], 'display_type');
        stackingIcon = elementProps.form.hookFormGetValues(fieldFormDisplayType);
    }
    let headerTitle = stackingIcon;
    if (measureFields.length != 1) {
        headerTitle = 'all';
    }

    const barOrientation = elementProps.form.hookFormWatch('bar_orientation') ?? 'vertical';
    const barOrder = elementProps.form.hookFormWatch('bar_order');
    const series = elementProps.form.hookFormWatch('series');
    const groupField = elementProps.form.hookFormWatch('group_field');

    let isBarOrientationPossible = false;
    if (measureFields.length) {
        let cntColumn = 0;
        measureFields.forEach((f) => {
            const fieldFormDisplayType = getMeasureFieldOptionForFormUId(f, 'display_type');
            if ('column' == elementProps.form.hookFormGetValues(fieldFormDisplayType)) {
                cntColumn++;
            }
        });
        if (cntColumn > 0) {
            isBarOrientationPossible = true;
        }
    }

    let isHighstockChart = false;
    if (measureFields.length) {
        isHighstockChart = 'datetime' == xAxis.value_type;

        if ('horizontal' == barOrientation) {
            measureFields.forEach((f) => {
                const fieldFormDisplayType = getMeasureFieldOptionForFormUId(f, 'display_type');
                if ('column' == elementProps.form.hookFormGetValues(fieldFormDisplayType)) {
                    isHighstockChart = false;
                }
            });
        }

        if ('alphabet' != barOrder) {
            isHighstockChart = false;
        }
    }

    let isFillOpacityPossible = false;
    if (measureFields.length) {
        measureFields.forEach((f) => {
            const fieldFormDisplayType = getMeasureFieldOptionForFormUId(f, 'display_type');
            if (elementProps.form.hookFormGetValues(fieldFormDisplayType) == 'area') {
                isFillOpacityPossible = true;
            }
        });
    }

    const isStackingPossible = useMemo(() => {
        let cntColumn = measureFields.length;
        return cntColumn > 1 || (1 === cntColumn && 'multiple' == series && groupField?.length > 0);
    }, [measureFields, groupField, series]);

    let xAxisList = datasetFields.map((field) => ({
        label: field.column_name,
        value: field.reference_name_escaped,
    }));

    if (hasSnapshot) {
        xAxisList = [
            {
                label: 'Snapshot Date',
                value: 'measurement_time',
            },
        ].concat(xAxisList);
    }
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <>
            {selectedMeasure == null && (
                <Stack height={1}>
                    {/*Main Panel*/}
                    <Box flexShrink={0}>
                        <BlockEditPanelHeader
                            title={t('line_chart.edit_title_' + headerTitle)}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                    {/*@ts-ignore*/}
                    <Stack ref={editPanel} sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                        {message.length > 0 && (
                            <Alert severity={'error'} variant={'standard'}>
                                {message}
                            </Alert>
                        )}
                        <Box>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: { component: 'FormText', name: 'title', label: t('title') },
                                })}
                            />
                        </Box>
                        <Divider />

                        <Box>
                            <FormLabel>X-Axis</FormLabel>
                            <ReactHookFormController
                                componentValues={xAxisList}
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    ...{
                                        component: {
                                            component: 'FormSelect',
                                            name: 'xAxisField',
                                        } as RawFormComponentType,
                                    },
                                })}
                            />
                        </Box>

                        {isHighstockChart && (
                            <Box>
                                <FormLabel>Default Zoom</FormLabel>

                                <ReactHookFormController
                                    componentValues={[
                                        { value: '1m', label: '1m' },
                                        { value: '3m', label: '3m' },
                                        { value: '6m', label: '6m' },
                                        { value: 'ytd', label: 'YTD' },
                                        { value: '1y', label: '1y' },
                                        { value: 'all', label: t('all') },
                                    ]}
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...{
                                            component: {
                                                component: 'FormSelect',
                                                name: 'xAxisFieldZoom',
                                            } as RawFormComponentType,
                                        },
                                    })}
                                />
                            </Box>
                        )}

                        {/*MeasureFields Sort Section*/}
                        <Divider />
                        <Box>
                            <FormLabel>{t('measure')}</FormLabel>
                            <MeasureFieldSortingContext.Provider
                                value={{
                                    elementProps: elementProps,
                                    selectField: handleSelectMeasureField,
                                    removeField: handleRemoveMeasureField,
                                    onUpdateMeasureField: handleMeasureFieldUpdate,
                                    onSortEnd: handleMeasureFieldsSort,
                                    datasetFields: datasetFields,
                                }}
                            >
                                <MeasureFieldsList fields={measureFields} />
                            </MeasureFieldSortingContext.Provider>
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    onClick={() => handleAddMeasureField()}
                                    variant={'light'}
                                    startIcon={<IconMi icon={'new'} />}
                                >
                                    {t('line_chart.measure')}
                                </Button>
                            </Box>
                        </Box>
                        <Divider />
                        {measureFields.length == 1 && (
                            <Box>
                                <FormLabel>{t('series')}</FormLabel>
                                <ReactHookFormController
                                    componentValues={[
                                        {
                                            value: 'single',
                                            //@ts-ignore
                                            label: (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {t('single') + ' ' + t(headerTitle)}
                                                    <Box sx={{ ml: 1, display: 'inline-flex' }}>
                                                        {getSectionIcon('Single' + ucFirst(headerTitle))}
                                                    </Box>
                                                </Box>
                                            ),
                                        },
                                        {
                                            value: 'multiple',
                                            //@ts-ignore
                                            label: (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {t('multiple') + ' ' + t(headerTitle)}s
                                                    <Box sx={{ ml: 1, display: 'inline-flex' }}>
                                                        {getSectionIcon('Multiple' + ucFirst(headerTitle))}
                                                    </Box>
                                                </Box>
                                            ),
                                        },
                                    ]}
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...{
                                            component: {
                                                component: 'FormRadioGroup',
                                                name: 'series',
                                            } as RawFormComponentType,
                                        },
                                    })}
                                />
                            </Box>
                        )}
                        {measureFields.length == 1 && series == 'multiple' && (
                            <Box>
                                <FormLabel>{t(`line_chart.line_pre_${stackingIcon}`)}</FormLabel>
                                <ReactHookFormController
                                    componentValues={[
                                        {
                                            label: t('none'),
                                            value: '',
                                        },
                                    ].concat(
                                        datasetFields.map((field) => ({
                                            label: field.column_name,
                                            value: field.reference_name_escaped,
                                        }))
                                    )}
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...{
                                            component: {
                                                component: 'FormSelect',
                                                name: 'group_field',
                                            } as RawFormComponentType,
                                        },
                                    })}
                                />
                            </Box>
                        )}
                        {isStackingPossible && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ mr: 1, display: 'inline-flex' }}>
                                    {getSectionIcon('Stacked' + ucFirst(stackingIcon))}
                                </Box>
                                <Box sx={{ mr: 2 }}>Stacking</Box>
                                <Box sx={{ mt: -1 }}>
                                    <ReactHookFormController
                                        componentValues={[
                                            { value: 'none', label: t('none') },
                                            { value: 'normal', label: t('normal') },
                                            { value: 'percent', label: t('percent') },
                                        ]}
                                        elementProps={prepareFormElementProps({
                                            ...elementProps,
                                            ...{
                                                component: {
                                                    component: 'FormRadioGroup',
                                                    name: 'stacking',
                                                } as RawFormComponentType,
                                            },
                                        })}
                                    />
                                </Box>
                            </Box>
                        )}
                        {isBarOrientationPossible && (
                            <Box>
                                <FormLabel>{t('line_chart.orientation')}</FormLabel>
                                <ReactHookFormController
                                    componentValues={[
                                        { value: 'vertical', label: t('line_chart.vertical') },
                                        { value: 'horizontal', label: t('line_chart.horizontal') },
                                    ]}
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...{
                                            component: {
                                                component: 'FormRadioGroup',
                                                name: 'bar_orientation',
                                            } as RawFormComponentType,
                                        },
                                    })}
                                />
                            </Box>
                        )}
                        {series == 'single' && (
                            <Box>
                                <FormLabel>{t('order_by')}</FormLabel>
                                <ReactHookFormController
                                    componentValues={[
                                        {
                                            label: t('sort_order.alphabet'),
                                            value: 'alphabet',
                                        },
                                        {
                                            label: t('sort_order.lowest'),
                                            value: 'lowest',
                                        },
                                        {
                                            label: t('sort_order.highest'),
                                            value: 'highest',
                                        },
                                    ]}
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...{
                                            component: {
                                                component: 'FormSelect',
                                                name: 'bar_order',
                                            } as RawFormComponentType,
                                        },
                                    })}
                                />
                            </Box>
                        )}
                        {isHighstockChart && (
                            <Box>
                                <EditPanelCheckBox
                                    elementProps={elementProps}
                                    name={'combine_tooltips'}
                                    label={t('line_chart.combine_data_points')}
                                />
                            </Box>
                        )}
                        {isFillOpacityPossible && (
                            <Box>
                                <FormLabel>{t('line_chart.fill_opacity')}</FormLabel>
                                <ReactHookFormController
                                    componentValues={[
                                        { value: '0.25', label: '25%' },
                                        { value: '0.5', label: '50%' },
                                        { value: '0.75', label: '75%' },
                                        { value: '1', label: '100%' },
                                    ]}
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        ...{
                                            component: {
                                                component: 'FormSelect',
                                                name: 'fillOpacity',
                                            } as RawFormComponentType,
                                        },
                                    })}
                                />
                            </Box>
                        )}
                        {/*Axis Settings*/}
                        <Divider />
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'display_label'}
                                label={t('line_chart.display_label')}
                            />
                        </Box>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>{t('line_chart.x_axis_title')}</Box>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        component: 'FormText',
                                        name: 'xAxisTitle',
                                        label: '',
                                    },
                                })}
                            />
                        </Stack>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>{t('line_chart.y_axis_title')}</Box>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        component: 'FormText',
                                        name: 'yAxis0Title',
                                        label: '',
                                    },
                                })}
                            />
                        </Stack>
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'customYAxis'}
                                label={t('line_chart.custom_chart_range')}
                            />
                        </Box>
                        <Box className={isCustomYAxis ? '' : 'd-none'}>
                            <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>{t('line_chart.minimum')} Y</Box>
                                <Box sx={{ width: '100px' }}>
                                    <ReactHookFormController
                                        elementProps={prepareFormElementProps({
                                            ...elementProps,
                                            inputFilter: 'int',
                                            component: {
                                                component: 'FormText',
                                                name: 'minY',
                                                label: '',
                                            },
                                        })}
                                    />
                                </Box>
                                <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>{t('line_chart.maximum')} Y</Box>
                                <Box sx={{ width: '100px' }}>
                                    <ReactHookFormController
                                        elementProps={prepareFormElementProps({
                                            ...elementProps,
                                            inputFilter: 'int',
                                            component: {
                                                component: 'FormText',
                                                name: 'maxY',
                                                label: '',
                                            },
                                        })}
                                    />
                                </Box>
                            </Stack>
                            <Box sx={{ mt: 0.5, color: 'text.secondary' }}>{t('line_chart.use_smart_defaults')}</Box>
                        </Box>
                        <Divider />

                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                            <Box sx={{ width: '72px' }}>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        inputFilter: 'int',
                                        component: { component: 'FormText', name: 'height', label: '' },
                                    })}
                                />
                            </Box>
                        </Stack>
                        <Divider />
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'show_in_viewer'}
                                label={t('show_in_viewer')}
                            />
                        </Box>
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'include_in_email'}
                                label={t('include_in_email')}
                            />
                        </Box>
                        <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                        <Box>
                            <GetEmbedCode
                                contentSettings={contentSettings}
                                height={actualHeight}
                                blockUID={block.uid}
                                t={t}
                            />
                        </Box>
                    </Stack>
                    <Box flexShrink={0}>
                        <BlockEditPanelControls
                            onApply={handleApply}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                </Stack>
            )}
            {selectedMeasure != null && (
                <MeasureFieldForm
                    field={selectedMeasure}
                    onApply={handleMeasureFieldUpdate}
                    onCancel={() => setSelectedMeasure(null)}
                    title={'Line Chart'}
                />
            )}
        </>
    );
}
