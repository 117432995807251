import { ReportContentNS, TextAlignType } from 'components/report-content';
import FieldTextFormatting = ReportContentNS.FieldTextFormatting;
import React, { useEffect, useState } from 'react';
import EditPanelColorPicker from './EditPanelColorPicker';
import { FormComponentValue } from 'components/common/form/layout/control';
import FormToggleButton from 'components/common/form/layout/control/FormToggleButton';
import IconMi from 'components/common/icon/IconMi';
import { Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import EditPanelToggleButton from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelToggleButton';
import useBundleTranslation from 'i18n';

export default function FieldTextFormattingLine({
    field,
    onChange,
    fieldsList,
    hasAlign = false,
    hasWrap = false,
}: {
    field: FieldTextFormatting;
    onChange: (field: FieldTextFormatting) => void;
    hasAlign?: boolean;
    hasWrap?: boolean;
    fieldsList?: Array<{ value: string; label: string }>;
}) {
    const elementProps = useCustomSimplifiedForm(field);
    const watch = elementProps.form.hookFormWatch();
    const [textAlign, setTextAlign] = useState<TextAlignType>(field.textAlign ?? 'left');
    const [applyTo, setApplyTo] = useState<string>(String(field.applyTo) ?? '-1');
    const { t } = useBundleTranslation(['components/report-content']);

    useEffect(() => {
        const fullWatch = { ...watch };

        if (hasAlign) {
            fullWatch.textAlign = textAlign;
        }
        if (fieldsList) {
            fullWatch.applyTo = applyTo;
        }

        const sortedFullWatch = Object.fromEntries(Object.entries(fullWatch).sort(([a], [b]) => a.localeCompare(b)));
        const sortedField = Object.fromEntries(Object.entries(field).sort(([a], [b]) => a.localeCompare(b)));
        if (JSON.stringify(sortedFullWatch) != JSON.stringify(sortedField)) {
            onChange(fullWatch);
        }
    }, [watch, textAlign, applyTo]);

    const style = {
        display: 'inline-block',
        paddingLeft: '6px',
    };
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {fieldsList && <Box sx={{ flexShrink: 0 }}>{t('label_formatting_apply')}</Box>}

            <EditPanelColorPicker
                elementProps={elementProps}
                name={'textColor'}
                icon={{ type: 'mi', value: 'underline', fill: 'text' }}
            />

            <EditPanelColorPicker
                elementProps={elementProps}
                name={'bgColor'}
                icon={{ type: 'mi', value: 'color-fill', fill: 'text' }}
            />

            {hasWrap && <EditPanelToggleButton elementProps={elementProps} icon={'text-control'} name={'textWrap'} />}
            <EditPanelToggleButton elementProps={elementProps} icon={'bold-font'} name={'textBold'} />
            <EditPanelToggleButton elementProps={elementProps} icon={'italic-font'} name={'textItalic'} />
            {hasAlign && (
                <ToggleButtonGroup
                    color="primary"
                    value={textAlign}
                    exclusive
                    onChange={(event, value) => {
                        if (value != null) {
                            setTextAlign(value);
                        }
                    }}
                >
                    {[
                        {
                            value: 'left',
                            label: '',
                            props: {
                                icon: {
                                    value: 'alignment-left',
                                    type: 'mi',
                                },
                            },
                        },
                        {
                            value: 'center',
                            label: '',
                            props: {
                                icon: {
                                    value: 'alignment-center',
                                    type: 'mi',
                                },
                            },
                        },
                        {
                            value: 'right',
                            label: '',
                            props: {
                                icon: {
                                    value: 'alignment-right',
                                    type: 'mi',
                                },
                            },
                        },
                    ].map((v: FormComponentValue) => (
                        <FormToggleButton key={v.value} value={v.value}>
                            {v.props?.icon && <IconMi icon={v.props.icon.value} fontSize={'16'} />}
                            {v.label}
                        </FormToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}
            {fieldsList && (
                <>
                    <Box sx={{ flexShrink: 0 }}>{t('label_formatting_to')}</Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <ReactSelect
                            data={[{ value: '-1', label: t('label_formatting_all_row') }].concat(fieldsList)}
                            value={[applyTo]}
                            update={(value) => setApplyTo(value)}
                        />
                    </Box>
                </>
            )}
        </Stack>
    );
}
