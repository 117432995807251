import { Box, Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import ActionsDropdown from './ActionsDropdown';
import LineageIconButton from 'components/lineage/LineageIconButton';
import { DatasetInfo, ElementsAmount, FilterData } from '../index';
import { useNavigate } from 'react-router-dom';
import useBundleTranslation from 'i18n';
import SeparatorItem from '../SeparatorItem';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import GridComponent from 'components/common/grid/GridComponent';
import InfoIconButton from './button/InfoIconButton';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';

function ControlsPanel({
    elementsAmount,
    datasetId,
    filterId,
    instanceId,
    json,
    selectedFilterData,
    filterDirty,
    saveFilter,
    switchFilterVisibility,
    setShowLoader,
    datasetInfo,
    reloadDatasetView,
    setShowMetricBuilder,
    hasNumericAndDateFields,
}: {
    elementsAmount: ElementsAmount;
    datasetId: number;
    filterId: string;
    instanceId: number;
    json: string;
    selectedFilterData: FilterData | null;
    filterDirty: boolean;
    saveFilter: () => Promise<boolean>;
    switchFilterVisibility: () => Promise<boolean>;
    setShowLoader: (value: boolean) => void;
    setShowMetricBuilder: (value: boolean) => void;
    datasetInfo: DatasetInfo;
    reloadDatasetView: (withFilter?: boolean, newFilterId?: number) => void;
    hasNumericAndDateFields: boolean;
}) {
    const navigate = useNavigate();
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [showSourcedPopup, setShowSourcedPopup] = useState(false);

    const getElementsAmountLabel = () => {
        const parts: string[] = [];
        let result = '';

        if (elementsAmount.reports > 0) {
            parts.push(
                `${elementsAmount.reports} ${
                    elementsAmount.reports > 1 ? t('control_panel.reports_label') : t('control_panel.report_label')
                }`
            );
        }
        if (elementsAmount.metrics > 0) {
            parts.push(
                `${elementsAmount.metrics} ${
                    elementsAmount.metrics > 1 ? t('control_panel.metrics_label') : t('control_panel.metric_label')
                }`
            );
        }
        if (elementsAmount.datasets > 0) {
            parts.push(
                `${elementsAmount.datasets} ${
                    elementsAmount.datasets > 1 ? t('control_panel.datasets_label') : t('control_panel.dataset_label')
                }`
            );
        }
        if (elementsAmount.segments > 0) {
            parts.push(
                `${elementsAmount.segments} ${
                    elementsAmount.segments > 1 ? t('control_panel.segments_label') : t('control_panel.segment_label')
                }`
            );
        }

        if (parts.length > 0) {
            result = parts.slice(0, -1).join(', ');
            if (result != '') {
                result += ' & ';
            }
            result += parts.slice(-1);
        }

        return result;
    };

    const onSourcedPopupClick = () => {
        setShowSourcedPopup(true);
    };

    const onSourcedPopupHide = () => {
        setShowSourcedPopup(false);
    };

    const sourcedPopupSettings: PopupSettings = {
        title: t('associated.title'),
        noCancel: true,
        noOk: true,
    };

    const lineageElementType = filterId > '' ? 'dataset-view' : 'dataset';
    const lineageElementId = filterId > '' ? filterId : String(datasetId);

    const allowedCreatePublic = useIsAdminOrHasPrivilege('PRIV_CREATE_PUBLIC_VIEW');

    return (
        <>
            <Stack direction={'row'} spacing={1} alignItems="center">
                {getElementsAmountLabel() > '' && (
                    <>
                        <Button variant={'outlined'} color={'neutral'} onClick={onSourcedPopupClick}>
                            {getElementsAmountLabel()}
                        </Button>
                        <Box sx={{ px: 0.5 }}>
                            <SeparatorItem />
                        </Box>
                    </>
                )}
                <Box>
                    <InfoIconButton datasetInfo={datasetInfo} />
                    <LineageIconButton elementType={lineageElementType} elementId={lineageElementId} isPanelIcon />
                </Box>
                <Box sx={{ px: 0.5 }}>
                    <SeparatorItem />
                </Box>
                <ActionsDropdown
                    datasetId={datasetId}
                    filterId={Number(filterId)}
                    instanceId={instanceId}
                    json={json}
                    selectedFilterData={selectedFilterData}
                    filterDirty={filterDirty}
                    saveFilter={saveFilter}
                    switchFilterVisibility={switchFilterVisibility}
                    setShowLoader={setShowLoader}
                    reloadDatasetView={reloadDatasetView}
                    setShowMetricBuilder={setShowMetricBuilder}
                    hasNumericAndDateFields={hasNumericAndDateFields}
                />
                {allowedCreatePublic && (
                    <Button
                        data-test={'editor-button'}
                        variant="outlined"
                        color="neutral"
                        className={'min-width--icon'}
                        onClick={() => {
                            navigate(`/editor/dataset/${datasetId}`);
                        }}
                    >
                        <IconMi icon={'gear'} fontSize="16" />
                    </Button>
                )}
            </Stack>
            {showSourcedPopup && (
                <Popup
                    settings={sourcedPopupSettings}
                    open={showSourcedPopup}
                    onHide={onSourcedPopupHide}
                    onConfirm={onSourcedPopupHide}
                    maxWidth="popupLg"
                >
                    <GridComponent
                        formGrid
                        uid={`dataset_${datasetId}_filter_${filterId}_sourced_grid`}
                        settingsUrl={`/data/editor/dataset/${datasetId}/filter/${filterId}/sourced/grid`}
                        t={t}
                    />
                </Popup>
            )}
        </>
    );
}

export default ControlsPanel;
