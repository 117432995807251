import React, { useEffect, useMemo, useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { editorTopicsAPI, TopicsInfo } from 'api/editor/topics';
import { alpha, Stack } from '@mui/material';
import GlossaryPopupLeftPanel from './GlossaryPopupLeftPanel';
import GlossaryPopupBody from './GlossaryPopupBody';
import useBundleTranslation from 'i18n';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { createPortal } from 'react-dom';

export default function GlossaryTermPopup({
    showPopup,
    setShowPopup,
    topicIds,
    topicId,
}: {
    showPopup: boolean;
    setShowPopup: (show: boolean) => void;
    topicIds: number[];
    topicId: number;
}) {
    const { t } = useBundleTranslation('components/glossary-term/glossary');

    const [topicsInfo, setTopicsInfo] = useState<TopicsInfo>({});
    const [readyToShow, setReadyToShow] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState<number>(topicId);

    useEffect(() => {
        if (showPopup && topicIds.length > 0) {
            editorTopicsAPI.getTopicsInfo(topicIds).then((response) => {
                if (response.status === 'OK' && response.data) {
                    const rawData = response.data;
                    delete rawData['default-topic-type'];
                    setTopicsInfo(rawData);
                    setReadyToShow(true);
                }
            });
        } else {
            setReadyToShow(false);
        }
    }, [showPopup, topicIds]);

    const popupSettings: PopupSettings = {
        noButtons: true,
        title: t('popup_title'),
    };

    const glossaryTerms = useMemo(() => Object.keys(topicsInfo), [topicsInfo]);

    return readyToShow && glossaryTerms.length > 0 ? (
        <Popup
            settings={popupSettings}
            open={readyToShow}
            onHide={() => {
                setShowPopup(false);
                setReadyToShow(false);
            }}
            maxWidth={'popupXl'}
            sx={{ '.MuiPaper-root': { width: '1176px !important', maxWidth: 'none !important' } }}
        >
            <Stack direction={'row'} key={'glossary-terms-container'} sx={{ margin: '-16px -24px' }}>
                <GlossaryPopupLeftPanel
                    setSelectedTopic={setSelectedTopic}
                    selectedTopic={selectedTopic}
                    topicsInfo={topicsInfo}
                />
                <GlossaryPopupBody selectedTopic={selectedTopic} topicsInfo={topicsInfo} />
            </Stack>
        </Popup>
    ) : (
        createPortal(
            <LoadingPlaceholder
                sx={{
                    position: 'absolute',
                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                    color: (theme) => alpha(theme.palette.text.primary, 0.4),
                    zIndex: 2000,
                }}
            />,
            document.body
        )
    );
}
