import React, { useEffect, useState } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { GridData, MassActionsProps } from 'components/common/grid';
import { SectionsDataArray } from './glossary-expanded';
import Topic from './glossary-expanded/Topic';

export default function GlossaryExpandedView({
    data,
    isLoading,
    massActionsProps,
}: {
    data: GridData;
    isLoading?: boolean;
    massActionsProps?: MassActionsProps;
}) {
    const [sections, setSections] = useState<SectionsDataArray>({});

    useEffect(() => {
        const newSections: SectionsDataArray = {};

        data.forEach((row) => {
            const ttInfo = row.tt_name;
            const topicId = Number(row['topic_type_id']);
            const topicName = row.name;

            if (typeof ttInfo === 'object' && typeof topicName === 'object') {
                if (!newSections.hasOwnProperty(topicId)) {
                    newSections[topicId] = {
                        text: ttInfo.config['text'] ?? '',
                        href: ttInfo.config['href'] ?? '',
                        icon: ttInfo.config['icon'] ?? null,
                        topics: [],
                    };
                }

                newSections[topicId].topics.push({
                    topicId: Number(row['topic_id']),
                    text: topicName.config['text'] ?? '',
                    href: topicName.config['href'] ?? '',
                    icon: ttInfo.config['icon'] ?? null,
                    definition: String(row.definition),
                    description: String(row.description),
                    boUser: String(row.bo_user),
                    boUserEmail: String(row.bo_user_email),
                    dsUser: String(row.ds_user),
                    dsUserEmail: String(row.ds_user_email),
                    toUser: String(row.to_user),
                    toUserEmail: String(row.to_user_email),
                    dashboardElementTopicAmount: Number(row.dashboard_element_topic_amount),
                    datasetTopicAmount: Number(row.dataset_topic_amount),
                    notificationScheduleDistributionTopicAmount: Number(
                        row.notification_schedule_distribution_topic_amount
                    ),
                    pluginConnectionProfileTopicAmount: Number(row.plugin_connection_profile_topic_amount),
                    portalPageTopicAmount: Number(row.portal_page_topic_amount),
                    sourceDatabaseConnectionTopicAmount: Number(row.source_database_connection_topic_amount),
                });
            }
        });

        setSections(newSections);
    }, [data]);

    const sectionIds = Object.keys(sections);

    return isLoading ? null : (
        <Container className={'mi-container-grid'} maxWidth={'lg'} component="main" disableGutters={true}>
            <Stack>
                {sectionIds.map((topic, index) => {
                    const sectionData = sections[Number(topic)];

                    return (
                        <Stack key={index} sx={{ paddingTop: '8px' }}>
                            <Typography variant="heading" width={'100%'}>
                                {sectionData.text}
                            </Typography>
                            {sectionData.topics.map((topicData, index) => (
                                <Topic
                                    key={index}
                                    sectionData={sectionData}
                                    topicData={topicData}
                                    massActionsProps={massActionsProps}
                                />
                            ))}
                        </Stack>
                    );
                })}
            </Stack>
        </Container>
    );
}
