import React, { Suspense, useEffect } from 'react';
import EmbedWrapper from 'app/home/EmbedWrapper';
import Preview from 'app/home/tile/preview/Preview';
import { useHomeContext } from 'app/home/context';

function EmbedPreviewChild() {
    const ctx = useHomeContext();
    const [tiles, tilePreview, setTilePreview] = [ctx.tiles, ctx.tilePreview, ctx.setTilePreview];
    useEffect(() => {
        if (tiles.length > 0) setTilePreview(tiles[0]);
    }, [tiles]);

    return (
        <Suspense>
            {tilePreview && (
                <Preview tile={tilePreview} onNextPreviewClick={(next: boolean) => null} hideNextPrevButtons={true} />
            )}
        </Suspense>
    );
}

export default function EmbedPreview({ dataParams }: { dataParams: { [key: string]: string } }) {
    return (
        <EmbedWrapper dataParams={dataParams}>
            <EmbedPreviewChild />
        </EmbedWrapper>
    );
}
