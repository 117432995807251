import React, { useEffect, useMemo } from 'react';
import { Container } from '@mui/material';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { useWatch } from 'react-hook-form';
import useTemplateFormTools from 'components/common/form/hooks/useTemplateFormTools';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { customTabProps, ITabProps } from 'components/common/form/layout/tab';
import { FormElementComponent } from 'components/common/form/element/FormElementComponent';
import FormTabContentDefaultStacks from 'components/common/form/layout/tab/FormTabContentDefaultStacks';

export default function ExampleForm(props: IFormLayoutProps) {
    const advancedTab = <AdvancedTab {...customTabProps(props, 'ext_report_other')} />;
    return <FormLayoutComposer props={{ ...props, customTabs: { ext_report_other: advancedTab } }} />;
}

function AdvancedTab(tabProps: ITabProps) {
    const { getElementProps, hookForm } = useTemplateFormTools(tabProps);

    const ratioInd = useWatch({
        name: 'calc_iframe_height_on_image_aspect_ratio_ind',
        defaultValue: 'Y',
        control: hookForm.hookFormControl,
    });

    useEffect(() => {
        hookForm.hookFormSetValue('some_custom_field_text', ratioInd);
    }, [ratioInd]);

    return (
        <FormTabContentDefaultStacks {...tabProps}>
            <></>
            <>
                <div>Custom Tab with Custom Components</div>
                <FormElementComponent {...getElementProps('calc_iframe_height_on_image_aspect_ratio_ind')} />
                <FormElementComponent {...getElementProps('external_report_iframe_height')} />
                <FormElementComponent {...getElementProps('external_report_refresh_iframe_ind')} />
                xxx{ratioInd}xx
                <div>This is custom component</div>
                <Container maxWidth={'sm'} component="main" disableGutters={true}>
                    {useMemo(
                        () => (
                            <TemplateFormComponent
                                elementProps={tabProps.elementProps}
                                component={'ChooseIcon'}
                                name={'some_custom_field'}
                                props={{
                                    type: 'none',
                                    value: '',
                                }}
                            />
                        ),
                        []
                    )}
                </Container>
                <Container maxWidth={'sm'} component="main" disableGutters={true}>
                    <TemplateFormComponent
                        elementProps={tabProps.elementProps}
                        component={'FormText'}
                        name={'some_custom_field_text'}
                        props={{ label: 'Text Field 1' }}
                    />
                </Container>
                <Container maxWidth={'sm'} component="main" disableGutters={true}>
                    <TemplateFormComponent
                        elementProps={tabProps.elementProps}
                        component={'FormText'}
                        name={'super_field'}
                        props={{ label: 'Text Field 2' }}
                    />
                </Container>
                <Container maxWidth={'sm'} component="main" disableGutters={true}>
                    <TemplateFormComponent
                        elementProps={tabProps.elementProps}
                        component={'FormText'}
                        name={'data_source'}
                        props={{ label: 'Text Field 2' }}
                    />
                </Container>
            </>
        </FormTabContentDefaultStacks>
    );
}
