import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { AssocArray } from 'tools/types';
import { arrayMoveImmutable } from 'array-move';
import { getFieldOptionForFormName, FieldSortingContext } from './edit-panel';
import FieldForm from './edit-panel/FieldForm';
import FieldsSortOrder from './edit-panel/FieldsSortOrder';
import FieldsList, { SortListType } from './edit-panel/FieldsList';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import FieldTextFormattingLine from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FieldTextFormattingLine';
import FieldConditionRule from 'components/common/field-condition-rule/FieldConditionRule';
import ConditionalFormattingList from './edit-panel/condition-formatting/ConditionalFormattingList';
import { ConditionFormatting, ConditionFormattingContext } from './edit-panel/condition-formatting';
import { FieldConditionRuleNS } from 'components/common/field-condition-rule';
import Rule = FieldConditionRuleNS.Rule;
import FieldTextFormatting = ReportContentNS.FieldTextFormatting;
import AllowedFonts = ReportContentNS.AllowedFonts;
import { alpha, Box, Button, FormLabel, Stack, Tooltip } from '@mui/material';
import LegacyRuleFieldSuffix = FieldConditionRuleNS.LegacyRuleFieldSuffix;
import AllowedFontSizes = ReportContentNS.AllowedFontSizes;
import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import Divider from '@mui/material/Divider';
import IconMi from 'components/common/icon/IconMi';
import ShowInViewerHeight from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/ShowInViewerHeight';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';
import FontSize from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/FontSize';
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import { fieldToSortField } from 'components/report-content/components/dataset/index';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';

function generateFormattingRuleId(): string {
    return Date.now().toString(36) + Math.random().toString(36);
}

// Field for form
const componentFormFields: Array<keyof ComponentSettingsDataset> = [
    'title',
    'mainFont',
    'showHiddenFields',
    'zebra_striping',
    'include_in_email',
    'include_in_email_attachment',
    'show_in_viewer',
    'limit_total_rows',
    'totals_label',
    'show_totals',
    'report_index_preview_type',
    'report_no_units_label',
    'report_single_unit_label',
    'report_multiple_units_label',
];
const componentNumericFormField: Array<keyof ComponentSettingsDataset> = [
    'mainFontSize',
    'height',
    'show_top_n_series',
];

export default function DatasetEditPanel({
    component,
    block,
    contentSettings,
}: ComponentUpdateProps<ComponentSettingsDataset> & { block: BlockType<ComponentSettingsDataset> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const [fieldsState, setFieldsState] = useState<Array<ComponentSettingsDatasetField>>(component.settings.fields);
    const [sortFields, setSortFields] = useState<Array<ComponentSettingsDatasetSortField>>(
        component.settings.sortFields
    );
    const [headerFormatting, setHeaderFormatting] = useState(component.settings.headerFormatting);
    const [totalsFormatting, setTotalsFormatting] = useState(component.settings.totalsSettings);

    const [conditionFormattingState, setConditionFormattingState] = useState<Array<ConditionFormatting>>(
        // Legacy formatting rules has no id field
        component.settings.formationRulesData?.map((formatting, index) => ({
            ...formatting,
            id: formatting.id ?? generateFormattingRuleId(),
        })) ?? []
    );
    const [selectFormatting, setSelectFormatting] = useState<ConditionFormatting | null>(null);
    const [isNewSelectedFormatting, setIsNewSelectedFormatting] = useState(false);
    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        title: component.settings?.title ?? '',
        mainFont: component.settings?.mainFont ?? AllowedFonts[0].value,
        mainFontSize: String(component.settings?.mainFontSize ?? AllowedFontSizes[0]),
        height: component.settings?.height ?? '',
        bgColor: component.settings?.headerFormatting?.bgColor ?? '',
        textColor: component.settings?.headerFormatting?.textColor ?? '',
        textWrap: component.settings?.headerFormatting?.textWrap ?? false,
        textBold: component.settings?.headerFormatting?.textBold ?? false,
        textItalic: component.settings?.headerFormatting?.textItalic ?? false,
        showHiddenFields: component.settings.showHiddenFields ?? 'N',

        zebra_striping: component.settings.zebra_striping ?? 'Y',
        include_in_email: component.settings.include_in_email ?? 'N',
        include_in_email_attachment: component.settings.include_in_email_attachment ?? 'N',
        show_in_viewer: component.settings.show_in_viewer ?? 'N',

        limit_total_rows: component.settings.limit_total_rows ?? 'N',
        show_top_n_series: component.settings.show_top_n_series ?? 1000,

        show_totals: component.settings.show_totals ?? 'N',
        totals_label: component.settings.totals_label ?? 'Overall Total',

        breakArea: component.settings.breakArea ? 'Y' : 'N',
        groupingArea: component.settings.groupingArea ? 'Y' : 'N',

        report_index_preview_type: component.settings.report_index_preview_type ?? 'dataset',
        report_multiple_units_label: component.settings.report_multiple_units_label ?? 'rows',
        report_no_units_label: component.settings.report_no_units_label ?? 'No rows',
        report_single_unit_label: component.settings.report_single_unit_label ?? 'row',
    };

    let allIsVisible = true;
    fieldsState.forEach((field) => {
        defaultState[getFieldOptionForFormName(field, 'show_column_in_table_display_ind')] =
            field.show_column_in_table_display_ind;
        if (field.show_column_in_table_display_ind == 'N') {
            allIsVisible = false;
        }
    });

    if (allIsVisible) {
        defaultState.showHiddenFields = 'N';
    }

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Process fields settings
        const fields = fieldsState.map((field) => {
            return {
                ...field,
                show_column_in_table_display_ind: elementProps.form.hookFormGetValues(
                    getFieldOptionForFormName(field, 'show_column_in_table_display_ind')
                ),
            };
        });

        const isGroupingArea = elementProps.form.hookFormGetValues('groupingArea') == 'Y';
        const isBreakArea = elementProps.form.hookFormGetValues('breakArea') == 'Y';

        if (!isBreakArea) {
            fields.forEach((f) => (f.break_column = 'N'));
        }
        if (!isGroupingArea) {
            fields.forEach((f) => (f.group_column = 'N'));
        }

        const breakColumnsLength = fields.filter((f) => f.break_column == 'Y').length;
        const groupingColumnsLength = fields.filter((f) => f.group_column == 'Y').length;
        const newSettings: ComponentSettingsDataset = {
            ...component.settings,
            headerFormatting: {
                ...component.settings.headerFormatting,
                ...headerFormatting,
            },
            totalsSettings: { ...totalsFormatting },
            formationRulesData: conditionFormattingState,
            sortFields: sortFields,
            fields: fields,
            groupingArea: isGroupingArea,
            breakArea: isBreakArea,
            breakColumns: breakColumnsLength == 0 ? [] : [0, breakColumnsLength],
            groupColumns: groupingColumnsLength == 0 ? [] : [breakColumnsLength, groupingColumnsLength],
        } as ComponentSettingsDataset;
        //@ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        //@ts-ignore
        componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));
        editPanelContext?.updateBlockSettings(block.uid, newSettings, true);
    };

    // Dataset Field Settings
    const preSelectedField = fieldsState.find((f) => f.reference_name == editPanelContext?.panelOptions?.editField);
    const [wasPreselectedField, setWasPreselectedField] = useState(preSelectedField != null);
    const [editField, setEditField] = useState<ComponentSettingsDatasetField | null>(preSelectedField ?? null);
    const handleFieldFormApply = (field: ComponentSettingsDatasetField) => {
        elementProps.form.hookFormSetValue(
            getFieldOptionForFormName(field, 'show_column_in_table_display_ind'),
            field.show_column_in_table_display_ind
        );

        const fieldIndex = fieldsState.findIndex((fs) => fs.id == field.id);
        if (fieldIndex != -1) {
            const list = fieldsState.slice();
            list[fieldIndex] = { ...list[fieldIndex], ...field };
            setFieldsState(list);
        }
        if (wasPreselectedField) {
            setWasPreselectedField(false);
        } else {
            setEditField(null);
        }
    };
    useLayoutEffect(() => {
        if (preSelectedField != null && !wasPreselectedField) {
            handleApply();
        }
    }, [wasPreselectedField]);

    const handleSelectField = (field: ComponentSettingsDatasetField) => {
        setEditField({
            ...field,
            show_column_in_table_display_ind: elementProps.form.hookFormGetValues(
                getFieldOptionForFormName(field, 'show_column_in_table_display_ind')
            ),
        });
    };

    const handleChangeColumnSortSettings = (sortFields: Array<ComponentSettingsDatasetSortField>) => {
        setSortFields(sortFields.slice());
    };

    // Formatting Rules
    const handleSelectFormatting = (formatting: ConditionFormatting) => {
        setSelectFormatting(formatting);
    };
    const handleDeleteFormatting = (formatting: ConditionFormatting) => {
        const index = conditionFormattingState.findIndex((f) => f.id == formatting.id);
        if (index != -1) {
            const list = conditionFormattingState.slice();
            list.splice(index, 1);
            setConditionFormattingState(list);
        }
    };
    const handleFieldsSort = (oldIndex: number, newIndex: number, reference?: string, listType?: SortListType) => {
        const field = fieldsState.find((f) => f.reference_name_escaped == reference);
        const makeVisible = (field: ComponentSettingsDatasetField) => {
            const name = getFieldOptionForFormName(field, 'show_column_in_table_display_ind');
            elementProps.form.hookFormSetValue(name, 'Y');
            field.show_column_in_table_display_ind = 'Y';
        };

        if (field) {
            field.break_column = 'N';
            field.group_column = 'N';
            if (listType == 'section') {
                field.break_column = 'Y';
                field.textAlign = 'center';
                field.textBold = true;
                makeVisible(field);
            } else if (listType == 'grouping') {
                field.group_column = 'Y';
                makeVisible(field);
            }
        }
        setFieldsState(arrayMoveImmutable(fieldsState, oldIndex, newIndex));
    };
    const handleFormattingRulesSort = (oldIndex: number, newIndex: number) => {
        setConditionFormattingState(arrayMoveImmutable(conditionFormattingState, oldIndex, newIndex));
    };
    const handleAddFormattingRule = () => {
        const list = conditionFormattingState.slice();
        const formatting: ConditionFormatting = {
            id: generateFormattingRuleId(),
            rules: [
                {
                    compare_condition: 'a value',
                    condition: 'equals',
                    data: 0,
                    data_second: null,
                    field: fieldsState[0].reference_name_escaped + LegacyRuleFieldSuffix,
                    field_second: '',
                },
            ],
            format: {
                applyTo: '-1',
            },
        };
        list.push(formatting);
        setIsNewSelectedFormatting(true);
        setConditionFormattingState(list);
        handleSelectFormatting(formatting);
    };

    const limitRows = elementProps.form.hookFormWatch('limit_total_rows') == 'Y';
    const showTotals = elementProps.form.hookFormWatch('show_totals') == 'Y';

    const [hasHiddenFields, setHasHiddenFields] = useState(false);
    let newHasHiddenFields = false;
    fieldsState.forEach((field) => {
        const name = getFieldOptionForFormName(field, 'show_column_in_table_display_ind');
        if (elementProps.form.hookFormGetValues(name) == 'N') {
            newHasHiddenFields = true;
        }
    });
    if (hasHiddenFields != newHasHiddenFields) {
        setHasHiddenFields(newHasHiddenFields);
    }

    const breakArea = elementProps.form.hookFormWatch('breakArea') == 'Y';
    const groupingArea = elementProps.form.hookFormWatch('groupingArea') == 'Y';
    const previewType = elementProps.form.hookFormWatch('report_index_preview_type');

    // Sync fields sorting with break and grouping areas
    useEffect(() => {
        let oldSortFields = sortFields.slice();
        fieldsState
            .filter((f) => {
                return (breakArea && f.break_column == 'Y') || (groupingArea && f.group_column == 'Y');
            })
            .forEach((f, index) => {
                const sortFieldIndex = oldSortFields.findIndex(
                    (s) => s.reference_name_escaped == f.reference_name_escaped
                );
                if (sortFieldIndex != -1) {
                    // Move existing field
                    if (sortFieldIndex != index) {
                        oldSortFields = arrayMoveImmutable(oldSortFields, sortFieldIndex, index);
                    }
                } else {
                    // Add field to list
                    oldSortFields.splice(index, 0, fieldToSortField(f, 'ASC'));
                }
            });
        if (JSON.stringify(oldSortFields) != JSON.stringify(sortFields)) {
            setSortFields(oldSortFields);
        }
    }, [breakArea, groupingArea, JSON.stringify(fieldsState)]);
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <>
            {editField == null && selectFormatting == null && (
                <Stack height={1}>
                    {/*Main Panel*/}
                    <Box flexShrink={0}>
                        <BlockEditPanelHeader
                            title={t('dataset.edit_title')}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                    <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                        <Box>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: { component: 'FormText', name: 'title', label: t('name') },
                                })}
                            />
                        </Box>
                        <FontSize elementProps={elementProps} t={t} />
                        <Stack direction={'row'} alignItems={'center'}>
                            <Box sx={{ pr: 1 }}>{t('dataset.label_header_format')}</Box>
                            <FieldTextFormattingLine
                                field={headerFormatting}
                                onChange={(fieldFormatting) => setHeaderFormatting(fieldFormatting)}
                                hasWrap
                            />
                        </Stack>

                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Box
                                sx={{
                                    '.MuiFormControlLabel-root': {
                                        mr: 0,
                                    },
                                }}
                            >
                                <EditPanelCheckBox
                                    elementProps={elementProps}
                                    name={'limit_total_rows'}
                                    label={t('dataset.limit_total_rows')}
                                />
                            </Box>

                            {limitRows && (
                                <>
                                    <Box
                                        sx={{
                                            width: '1px',
                                            height: '16px',
                                            backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                        }}
                                    />
                                    <Box>{t('dataset.limit_show_first')}</Box>

                                    <Box sx={{ width: '72px' }}>
                                        <ReactHookFormController
                                            elementProps={prepareFormElementProps({
                                                ...elementProps,
                                                inputFilter: 'int',
                                                component: {
                                                    component: 'FormText',
                                                    name: 'show_top_n_series',
                                                    label: '',
                                                },
                                            })}
                                        />
                                    </Box>
                                </>
                            )}
                        </Stack>
                        {/*Columns Sort Section*/}
                        <Divider />
                        <Stack direction={'row'} spacing={1}>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'breakArea'}
                                label={t('dataset.sort_area_section')}
                            />
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'groupingArea'}
                                label={t('dataset.sort_area_grouping')}
                            />
                        </Stack>

                        <FieldSortingContext.Provider
                            value={{
                                elementProps: elementProps,
                                selectField: handleSelectField,
                                onSortEnd: handleFieldsSort,
                            }}
                        >
                            <FieldsList
                                breakArea={breakArea}
                                groupingArea={groupingArea}
                                fields={fieldsState}
                                settings={component.settings}
                            />
                        </FieldSortingContext.Provider>
                        <Box>
                            <Tooltip title={hasHiddenFields ? '' : t('dataset.hide_tooltip')}>
                                <div style={{ width: '200px' }}>
                                    <EditPanelCheckBox
                                        elementProps={elementProps}
                                        name={'showHiddenFields'}
                                        label={t('dataset.hide_unused_columns')}
                                        disabled={!hasHiddenFields}
                                    />
                                </div>
                            </Tooltip>
                        </Box>
                        {/*Field SortOrder Section*/}
                        <Divider />
                        <FieldsSortOrder
                            onChangeColumnSortSettings={handleChangeColumnSortSettings}
                            fields={fieldsState}
                            sortFields={sortFields}
                            breakArea={breakArea}
                            groupingArea={groupingArea}
                        />
                        {/*Condition Formatting*/}
                        <Divider />
                        <ConditionFormattingContext.Provider
                            value={{
                                fields: fieldsState,
                                selectFormatting: handleSelectFormatting,
                                deleteFormatting: handleDeleteFormatting,
                                onSortEnd: handleFormattingRulesSort,
                            }}
                        >
                            <ConditionalFormattingList formatting={conditionFormattingState} />
                        </ConditionFormattingContext.Provider>
                        <Box>
                            <Button
                                onClick={() => handleAddFormattingRule()}
                                startIcon={<IconMi icon={'new'} />}
                                variant="light"
                            >
                                {t('conditional_formatting.add_new_field')}
                            </Button>
                        </Box>
                        {/* Totals */}
                        <Divider />
                        <Stack direction={'row'} alignItems={'center'}>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'show_totals'}
                                label={t('dataset.grand_totals')}
                            />

                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ display: showTotals ? undefined : 'none', flexGrow: 1 }}
                            >
                                <Box sx={{ mr: 1 }}>{t('dataset.totals_label')}</Box>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        component: {
                                            component: 'FormText',
                                            name: 'totals_label',
                                            label: '',
                                        },
                                    })}
                                />
                            </Stack>
                        </Stack>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            sx={{ display: showTotals ? undefined : 'none', mr: 1 }}
                            spacing={1}
                        >
                            <Box>{t('dataset.totals_label_format')}</Box>
                            <FieldTextFormattingLine
                                field={totalsFormatting}
                                onChange={(fieldFormatting) => setTotalsFormatting(fieldFormatting)}
                            />
                        </Stack>
                        {/*Show on Tile*/}
                        {component.settings.is_preview_ind == 'Y' && (
                            <>
                                <Divider />
                                <Stack direction={'column'} spacing={1}>
                                    <Box>
                                        <FormLabel>{t('dataset.report_index_preview_type')}</FormLabel>
                                        <Stack direction={'row'}>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <ReactHookFormController
                                                    componentValues={[
                                                        { value: 'rows', label: t('dataset.row_count') },
                                                        { value: 'dataset', label: t('dataset.table_image') },
                                                    ]}
                                                    elementProps={prepareFormElementProps({
                                                        ...elementProps,
                                                        ...{
                                                            component: {
                                                                component: 'FormSelect',
                                                                name: 'report_index_preview_type',
                                                            } as RawFormComponentType,
                                                        },
                                                    })}
                                                />
                                            </Box>
                                        </Stack>
                                    </Box>
                                    {previewType == 'rows' && (
                                        <Box>
                                            <Stack direction={'column'} spacing={1}>
                                                <FormLabel>{t('dataset.filters_are_ignored')}</FormLabel>
                                                <FormLabel>{t('dataset.when_report_has')}</FormLabel>
                                                {[
                                                    'report_no_units_label',
                                                    'report_single_unit_label',
                                                    'report_multiple_units_label',
                                                ].map((f) => (
                                                    <Box flexGrow="1" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                        <StaticAddon sx={{ minWidth: '104px' }}>
                                                            {t(`dataset.${f}`)}
                                                        </StaticAddon>
                                                        <Box>
                                                            <ReactHookFormController
                                                                elementProps={prepareFormElementProps({
                                                                    ...elementProps,
                                                                    component: {
                                                                        component: 'FormText',
                                                                        name: f,
                                                                        label: '',
                                                                    },
                                                                })}
                                                            />
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Stack>
                                        </Box>
                                    )}
                                </Stack>
                            </>
                        )}
                        <Divider />
                        <ShowInViewerHeight elementProps={elementProps} t={t} />
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'include_in_email'}
                                label={t('include_in_email')}
                            />
                        </Box>
                        <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                        <Box>
                            <EditPanelCheckBox
                                elementProps={elementProps}
                                name={'zebra_striping'}
                                label={t('dataset.display_banded_rows')}
                            />
                        </Box>
                        <GetEmbedCode
                            contentSettings={contentSettings}
                            height={actualHeight}
                            blockUID={block.uid}
                            t={t}
                        />
                    </Stack>
                    <Box flexShrink={0}>
                        <BlockEditPanelControls
                            onApply={handleApply}
                            onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                        />
                    </Box>
                </Stack>
            )}
            {editField != null && (
                <>
                    {/*Field Edit Panel*/}
                    <FieldForm
                        fields={fieldsState}
                        field={editField}
                        onApply={handleFieldFormApply}
                        onCancel={() => {
                            if (wasPreselectedField) {
                                setWasPreselectedField(false);
                            } else {
                                setEditField(null);
                            }
                        }}
                        title={t('dataset.edit_title')}
                    />
                </>
            )}
            {selectFormatting != null && (
                <FieldConditionRule
                    formatting={selectFormatting}
                    onCancel={() => {
                        if (isNewSelectedFormatting) {
                            handleDeleteFormatting(selectFormatting);
                            setIsNewSelectedFormatting(false);
                        }
                        setSelectFormatting(null);
                    }}
                    onApply={(rule: Rule, format: FieldTextFormatting) => {
                        setIsNewSelectedFormatting(false);
                        const list = conditionFormattingState.slice();
                        const index = list.findIndex((c) => c.id == selectFormatting.id);
                        if (index != -1) {
                            list[index] = { id: list[index].id, rules: [rule], format: { ...format } };
                            setConditionFormattingState(list);
                            setSelectFormatting(null);
                        }
                    }}
                    fieldsList={component.settings.fields}
                    title={t('dataset.edit_title')}
                />
            )}
        </>
    );
}
