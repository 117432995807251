import { TooltipFormatterContextObject } from 'highcharts/highstock';
import React from 'react';

export default function FunnelChartTooltip({ context }: { context: TooltipFormatterContextObject }) {
    const point = context.point as typeof context.point & { y_f: number; name: string };
    return (
        <div>
            <span style={{ color: String(context.color) }} dangerouslySetInnerHTML={{ __html: point.name }}></span>:{' '}
            {point.y_f}
        </div>
    );
}
