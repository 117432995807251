import { ColumnType, GridData, MassActionsProps } from 'components/common/grid';
import MITable from 'components/common/grid/MITable';
import React from 'react';
import useBundleTranslation from 'i18n';
import { Box } from '@mui/material';

function SimpleFieldsSelectorTable({
    data,
    selectedColumns,
    onSelectedChange,
    type,
}: {
    data: GridData;
    selectedColumns: string[];
    onSelectedChange: (keys: string[], checked: boolean) => void;
    type: 'text' | 'track';
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const label = type === 'text' ? t('fields_selector.text_fields_label') : t('fields_selector.track_fields_label');

    const columns: ColumnType[] = [
        {
            title: label,
            name: 'label',
            resizable: false,
        },
    ];

    const handleCheckRow = (event: any) => {
        const checked = event.target.checked;
        const value = event.target.value;
        onSelectedChange([value], checked);
    };

    const handleCheckAllRows = (event: any) => {
        const checked = event.target.checked;
        onSelectedChange(
            data.map((item) => item.id as string),
            checked
        );
    };

    const massActionsProps: MassActionsProps = {
        checkedRowsKeys: selectedColumns,
        handleCheckRow: handleCheckRow,
        handleCheckAllRows: handleCheckAllRows,
        checkAllChecked: () => {
            let result = true;

            data.forEach((item) => {
                if (result && !selectedColumns.includes(item.id as string)) {
                    result = false;
                    return;
                }
            });

            return result;
        },
    };

    return data.length > 0 ? (
        <Box sx={{ '.rs-table-row--selected-row .rs-table-cell': { backgroundColor: 'background.default' } }}>
            <MITable
                autoHeight
                gridName={'dataset_view_simple_fields'}
                data={data}
                columns={columns}
                massActionsConfig={{ enabled: true }}
                massActionsProps={massActionsProps}
            />
        </Box>
    ) : null;
}

export default SimpleFieldsSelectorTable;
