import { FormLabel, Stack } from '@mui/material';
import React from 'react';
import { BasicAutocomplete } from 'components/common/basic-autocomplite/BasicAutocomplite';

function Expression({
    label,
    expression,
    setExpression,
    expressionError,
    setExpressionError,
    availableOptions,
}: {
    label: string;
    expression: string;
    setExpression: (value: string) => void;
    expressionError: boolean;
    setExpressionError: (value: boolean) => void;
    availableOptions: string[];
}) {
    return (
        <Stack direction={'column'}>
            <FormLabel>{label}</FormLabel>
            <BasicAutocomplete
                options={availableOptions.map((v) => ({ label: v, value: v }))}
                expression={expression}
                setExpression={setExpression}
                expressionError={expressionError}
                setExpressionError={setExpressionError}
            />
        </Stack>
    );
}

export default Expression;
