import React, { useEffect } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';

export default function SegmentValueForm(props: IFormLayoutProps) {
    const valueInt = props.hookForm.form.hookFormWatch('value_int');
    const valueChar = props.hookForm.form.hookFormWatch('value_varchar');

    useEffect(() => {
        if (!props.isNew) {
            return;
        }
        const value = valueInt.length ? valueInt : valueChar;
        const fieldState = props.hookForm.form.hookFormGetFieldState(
            'value_display_name',
            props.hookForm.form.hookFormState
        );
        if (!fieldState.isDirty) {
            props.hookForm.form.hookFormSetValue('value_display_name', value);
        }
    }, [valueInt, valueChar]);

    return <FormLayoutComposer props={props} />;
}
