import { TooltipFormatterContextObject } from 'highcharts/highstock';
import React from 'react';

export default function PieChartTooltip({ context }: { context: TooltipFormatterContextObject }) {
    const point = context.point as typeof context.point & { key: { y_f: number; name_f: string } };
    return (
        <div>
            <span
                style={{ color: String(context.color) }}
                dangerouslySetInnerHTML={{ __html: point.key.name_f }}
            ></span>
            : {point.key.y_f}
        </div>
    );
}
