import { Popup } from 'components/common/popup/Popup';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { instance } from 'api/api';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { APIResponse } from 'tools/types';
import useBundleTranslation from 'i18n';
import { ExportResponse } from 'app/content/export/index';
import MITable from 'components/common/grid/MITable';
import { GridData } from 'components/common/grid';
import StaticInfo from 'components/common/StaticInfo';
import { useNavigate } from 'react-router-dom';

export default function ExportResultPopup({
    popupOpen,
    setPopupOpen,
    formData,
}: {
    popupOpen: boolean;
    setPopupOpen: (state: boolean) => void;
    formData: { [key: string]: any };
}) {
    const { t } = useBundleTranslation('components/common/export');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (popupOpen) {
            setIsVisible(true);
        } else {
            // Give time for the popup to disappear
            window.setTimeout(() => {
                setIsVisible(false);
                remove();
            }, 100);
        }
    }, [popupOpen]);

    const {
        data: exportResultData,
        refetch: refetch,
        remove,
        isLoading,
    } = useQuery<APIResponse<false | ExportResponse>, Error>(
        ['exportResult'],
        () => instance.post('/data/admin/content/export/result', formData).then((response) => response.data),
        { enabled: isVisible }
    );

    return (
        <Popup
            onHide={() => {
                setPopupOpen(false);
                navigate('/admin/content');
            }}
            open={popupOpen}
            settings={{ title: t('export_results'), noCancel: true, textOK: t('done') }}
        >
            {exportResultData && !isLoading ? (
                <ExportResult exportResult={exportResultData.data} />
            ) : (
                <LoadingPlaceholder />
            )}
        </Popup>
    );
}

function ExportResult({ exportResult }: { exportResult: false | ExportResponse }) {
    const { t } = useBundleTranslation('components/common/export');
    if (exportResult == false) {
        return <StaticInfo type={'error'} label={t('none_elements')}></StaticInfo>;
    }
    const messageBox = exportResult.failed > 0 ? t('has_failed', { count: exportResult.success }) : t('has_success');

    const [gridData, setGridData] = useState<GridData>([]);
    useEffect(() => {
        setGridData(
            exportResult.grid.map((row) => {
                let message = t('success');
                // TODO: add decoration ?
                if (row.name == 'Note') {
                    message = row.message;
                } else {
                    if (row.status != 'success') {
                        message = row.message;
                    }
                }
                return {
                    num: String(row.num),
                    name: row.name,
                    message: message,
                };
            })
        );
    }, [exportResult.grid]);

    return (
        <div>
            <StaticInfo type={exportResult.failed > 0 ? 'info' : 'success'} label={messageBox} />
            <br />
            <MITable
                gridName={'export_results'}
                autoHeight
                data={gridData}
                isLoading={false}
                columns={[
                    { title: '#', name: 'num' },
                    { title: t('name'), name: 'name' },
                    { title: t('message'), name: 'message' },
                ]}
                massActionsConfig={{ enabled: false }}
            />
            <a href={`/data/admin/content/download/${exportResult.exportImportId}`}>{t('download')}</a>
        </div>
    );
}
