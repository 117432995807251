import { Box, FormControl, FormLabel, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { Popup } from 'components/common/popup/Popup';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { useQuery } from '@tanstack/react-query';
import { formAPI } from 'api/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import ReactHookFormController, { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';
import BookmarkManager from 'components/external-reference/bookmark/BookmarManager';
import useBookmarks from 'components/external-reference/hooks/useBookmarks';
import { ElementViewerDataType, prepareSegments } from 'components/element-viewer';
import { ExternalReportViewerDataType } from 'app/extreport/ExternalReportViewer';
import { FilterType, useApplyFilterValueMap } from 'components/external-reference/Filter';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { FormRendererAPIType } from 'components/common/form';
import { instance } from 'api/api';
import { useNavigate } from 'react-router-dom';

interface BurstFilterCellProps extends CustomCellProps {
    elementId: number;
    segmentValueId: number;
    externalId: number;
    listId: number;
    isSubscription: string;
}

export default function BurstFilterCell({
    elementId,
    segmentValueId,
    externalId,
    listId,
    isSubscription,
}: BurstFilterCellProps) {
    const { t } = useBundleTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleClick = () => {
        if (isSubscription == 'Y') {
            navigate(`/extreport/${elementId}#show_alert_wizard`);
            return;
        }

        setOpen(true);
    };

    const [form, setForm] = useState<FormRendererAPIType>();
    const [isLoading, setIsLoading] = useState(false);

    const [isDirty, setIsDirty] = useState(false);
    useEffect(() => {
        const subscription = form?.hookFormWatch((value, { name, type }) => {
            if (!name) {
                return;
            }
            setIsDirty(true);
        });
        return () => subscription?.unsubscribe();
    }, [form?.hookFormWatch]);

    return (
        <>
            <Typography sx={{ py: 1 }}>
                <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
                    <IconMi icon={'filter'} fontSize={'16'} />
                    Filters
                </Box>
            </Typography>
            <Popup
                settings={{
                    noCancel: true,
                    title: t('filters'),
                }}
                okDisabled={!isDirty}
                open={open}
                onHide={() => {
                    setOpen(false);
                }}
                onConfirm={() => {
                    setIsLoading(true);
                    if (!form) {
                        return;
                    }
                    const formData = new FormData();
                    const data = form.hookFormGetValues();
                    for (let key in data) {
                        formData.append(key, data[key]);
                    }
                    instance
                        .post(
                            `/data/notification/distribution-list-item/${elementId}/${segmentValueId}/${externalId}/${listId}/form`,
                            formData
                        )
                        .then((response) => {
                            setIsLoading(false);
                            setIsDirty(false);
                        });
                }}
            >
                <>
                    {isLoading && <LoadingPlaceholder />}
                    <FiltersFormWrapper
                        setForm={(form) => setForm(form)}
                        elementId={elementId}
                        segmentValueId={segmentValueId}
                        externalId={externalId}
                        listId={listId}
                    />
                </>
            </Popup>
        </>
    );
}

function FiltersFormWrapper({
    elementId,
    segmentValueId,
    externalId,
    listId,
    setForm,
}: {
    elementId: number;
    segmentValueId: number;
    externalId: number;
    listId: number;
    setForm: (form: FormRendererAPIType) => void;
}) {
    const { data, remove } = useQuery<any | void, Error>(['burstFilter', elementId, segmentValueId, externalId], () => {
        return formAPI
            .load(
                `/data/notification/distribution-list-item/${elementId}/${segmentValueId}/${externalId}/${listId}/form`
            )
            .then((response: any) => response?.data?.data);
    });

    useEffect(() => {
        return () => remove();
    }, []);

    if (!data) {
        return <LoadingPlaceholder />;
    }

    return (
        <FiltersForm
            setForm={setForm}
            filtersRequestData={data.filtersData}
            segmentData={data.segmentData}
            elementId={elementId}
            segmentValueId={segmentValueId}
            externalId={externalId}
            defaultData={data.defaults}
            formValues={data.values}
        />
    );
}

function useMapData(externalId: number, elementId: number, mapId: number) {
    // Map Data
    const { data: firstColumnData } = useQuery<any | void, Error>(['firstColumn'], () => {
        return formAPI
            .load(`/data/editor/external-reference-filter/get-filters/reference/${externalId}/element/${elementId}`)
            .then((response: any) => {
                return response?.data?.data?.filters;
            });
    });
    const [firstColumn, setFirstColumn] = useState([]);
    useEffect(() => {
        if (!firstColumnData) {
            return;
        }
        const result = firstColumnData.map((row: any) => ({
            label: row.display_name ?? row.name,
            value: row.external_filter_id,
        }));
        setFirstColumn(result);
    }, [firstColumnData]);

    const { data: secondColumnData } = useQuery<Array<any> | void, Error>(['secondColumn', mapId], () => {
        //@ts-ignore
        if (mapId == '' || mapId == 0) {
            return [];
        }
        return formAPI.load(`/data/notification/filters-map/get-map-columns/${mapId}`).then((response: any) => {
            return response?.data?.data;
        });
    });
    const [secondColumn, setSecondColumn] = useState([]);
    useEffect(() => {
        if (!secondColumnData) {
            return;
        }
        const result = secondColumnData.map((row: any) => ({ label: row.label, value: row.value }));
        //@ts-ignore
        setSecondColumn(result);
    }, [secondColumnData]);

    const isLoaded = firstColumnData && secondColumnData;
    return { firstColumn, secondColumn, isLoaded };
}

function MapDataForm({
    mapData,
    mapDataCallback,
    isOpen,
}: {
    mapData: any;
    mapDataCallback: (data: any) => void;
    isOpen: boolean;
}) {
    const { t } = useBundleTranslation(['components/notification/burst']);
    const elementProps = useCustomSimplifiedForm({
        all_values: mapData?.all_values ?? 'All',
        all_values_ext: mapData?.all_values_ext ?? null,
        null_values: mapData?.null_values ?? null,
        null_values_ext: mapData?.null_values_ext ?? null,
        use_formatted_value_ind: mapData?.use_formatted_value_ind ?? 'N',
    });

    return (
        <Popup
            settings={{
                noCancel: true,
                title: t('filter.map_label'),
            }}
            open={isOpen}
            onHide={() => {
                mapDataCallback(mapData);
            }}
            onConfirm={() => {
                mapDataCallback(elementProps.form.hookFormGetValues());
            }}
        >
            <Stack spacing={2}>
                <Grid container>
                    <Grid xs={5}>
                        <FormControl fullWidth>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        uid: 'all_values_ext',
                                        name: 'all_values_ext',
                                        component: 'FormText',
                                    },
                                    componentProps: {
                                        label: t('filter.all_values_ext'),
                                    },
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={2} sx={{ fontSize: '16px', paddingTop: '24px', textAlign: 'center' }}>
                        <IconMi icon={'arrow-right'} />
                    </Grid>
                    <Grid xs={5}>
                        <FormControl fullWidth>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        uid: 'all_values',
                                        name: 'all_values',
                                        component: 'FormText',
                                    },
                                    componentProps: {
                                        label: t('filter.all_values'),
                                    },
                                })}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '17px;' }}>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                component: 'FormCheckbox',
                                name: 'use_formatted_value_ind',
                                label: t('filter.use_formatted_value_ind'),
                            },
                        })}
                    />
                </Box>
            </Stack>
        </Popup>
    );
}

function FiltersForm({
    defaultData,
    formValues,
    elementId,
    segmentValueId,
    externalId,
    filtersRequestData,
    segmentData,
    setForm,
}: {
    defaultData: any;
    formValues: any;
    elementId: number;
    segmentValueId: number;
    externalId: number;
    filtersRequestData: any;
    segmentData: Array<any>;
    setForm: (form: FormRendererAPIType) => void;
}) {
    const { t } = useBundleTranslation(['components/notification/burst']);
    const elementProps = useCustomSimplifiedForm(defaultData);

    const overrideInd = elementProps.form.hookFormWatch('override_external_filters_ind') == 'Y';
    const externalMethod = elementProps.form.hookFormWatch('external_filters_usage_method');
    const mapId = elementProps.form.hookFormWatch('external_filters_user_map_id');

    const [selectedBookmark, setSelectedBookmark] = useState<BookmarkType | undefined>();
    const {
        bookmarks,
        APIUpdateBookmark,
        APIDeleteBookmark,
        APIDuplicateBookmark,
        APISortBookmarks,
        filtersData,
        APIIsLoading: isLoading,
    } = useBookmarks(
        { filtersData: filtersRequestData } as ExternalReportViewerDataType,
        { elementData: { row: { elementId: elementId } }, segmentData: segmentData } as ElementViewerDataType,
        useApplyFilterValueMap(prepareSegments(filtersRequestData.data.filtersList.slice(), segmentData).filtersList),
        [],
        undefined,
        defaultData['notification_schedule_distribution_item_id']
    );

    const [filters, setFilters] = useState<Array<FilterType>>([]);
    useEffect(() => {
        const { filtersList } = prepareSegments(filtersData?.filtersList ?? [], segmentData);
        setFilters(useApplyFilterValueMap(filtersList.slice()));
    }, [filtersData?.filtersList]);

    const { firstColumn, secondColumn, isLoaded } = useMapData(externalId, elementId, mapId);
    const [mapActualState, setMapActualState] = useState(
        formValues?.map_data?.map((r: any) => ({
            firstColumn: r.filterColumn,
            secondColumn: r.mapColumn,
            data: r?.data ?? null,
        })) ?? []
    );

    const [formReady, setFormReady] = useState(false);
    useEffect(() => {
        if (isLoaded) {
            setTimeout(() => {
                setForm(elementProps.form);
                setFormReady(true);
            }, 100);
        }
    }, [isLoaded]);

    const [mapData, setMapData] = useState<any>(null);
    const mapDataRef = useRef<(data: any) => void>();
    const [mapFormOpen, setMapFormOpen] = useState(false);
    const handleGearClick = (data: any, setData: (data: any) => void) => {
        setMapData(data);
        mapDataRef.current = setData;
        setMapFormOpen(true);
    };

    return (
        <>
            {!formReady && <LoadingPlaceholder />}
            {mapFormOpen && (
                <MapDataForm
                    isOpen={mapFormOpen}
                    mapData={mapData}
                    mapDataCallback={(data) => {
                        //@ts-ignore
                        mapDataRef.current(data);
                        setMapFormOpen(false);
                    }}
                />
            )}
            <Stack spacing={2}>
                <Box>{t('filter.form_desc')}</Box>
                <Box sx={{ marginTop: '17px;' }}>
                    <FormControl>
                        <FormLabel>{t('filter.filter_settings')}</FormLabel>
                        <TemplateFormComponent
                            elementProps={elementProps}
                            component={'FormRadioGroup'}
                            componentValues={[
                                { label: t('filter.use_viewing'), value: 'N' },
                                { label: t('filter.custom_burst'), value: 'Y' },
                            ]}
                            name={'override_external_filters_ind'}
                        />
                    </FormControl>
                </Box>

                <Stack className={overrideInd ? '' : 'd-none'}>
                    <Grid container>
                        <Grid xs={3}>
                            <FormControl>
                                <FormLabel>{t('filter.filters_are')}</FormLabel>
                                <TemplateFormComponent
                                    elementProps={elementProps}
                                    component={'FormRadioGroup'}
                                    componentValues={[
                                        { label: t('manual'), value: 'standard' },
                                        { label: t('user_map'), value: 'user map' },
                                    ]}
                                    name={'external_filters_usage_method'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6} className={externalMethod == 'user map' ? '' : 'd-none'}>
                            <FormControl fullWidth>
                                <FormLabel>{t('user_map')}</FormLabel>
                                <TemplateFormComponent
                                    elementProps={elementProps}
                                    component={'FormSelect'}
                                    componentValues={formValues.external_filters_user_map_id}
                                    name={'external_filters_user_map_id'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box sx={{ marginTop: '17px;' }} className={externalMethod == 'user map' ? '' : 'd-none'}>
                        <>
                            {isLoaded ? (
                                <TemplateFormComponent
                                    name={'map-data'}
                                    elementProps={elementProps}
                                    component={'SelectMap'}
                                    props={{
                                        data: {
                                            handleGearClick: handleGearClick,
                                            uniqueRule: 'both',
                                            firstColumn: {
                                                label: t('filter.filter'),
                                                source: {
                                                    type: 'manual',
                                                    manualData: firstColumn,
                                                },
                                            },
                                            secondColumn: {
                                                label: t('filter.user_map_column'),
                                                source: {
                                                    type: 'manual',
                                                    manualData: secondColumn,
                                                },
                                            },
                                            actualValues: mapActualState,
                                        },
                                    }}
                                />
                            ) : (
                                <LoadingPlaceholder />
                            )}
                        </>
                    </Box>

                    <Box sx={{ marginTop: '17px;' }} className={externalMethod == 'standard' ? '' : 'd-none'}>
                        <BookmarkManager
                            filterCombination
                            setOnEditMode={() => {}}
                            bookmarkForEdit={selectedBookmark}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            bookmarks={bookmarks}
                            filters={filters}
                            siblingTabs={[]}
                            onDeleteClick={APIDeleteBookmark}
                            onDuplicateClick={APIDuplicateBookmark}
                            onSortEnd={APISortBookmarks}
                            onUpdateClick={(bookmark: BookmarkType) => {
                                APIUpdateBookmark(bookmark, 0);
                                if (selectedBookmark) {
                                    //handleHideManager();
                                }
                            }}
                        />
                    </Box>

                    <Box sx={{ marginTop: '17px;' }}>
                        <FormControl>
                            <FormLabel>{t('filter.images')}</FormLabel>
                            <TemplateFormComponent
                                elementProps={elementProps}
                                component={'FormRadioGroup'}
                                componentValues={[
                                    { label: t('filter.viewer'), value: 'viewer' },
                                    { label: t('filter.external'), value: 'external' },
                                ]}
                                name={'image_link_to'}
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ marginTop: '17px;' }} className={externalMethod == 'user map' ? '' : 'd-none'}>
                        <FormControl>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    inputFilter: 'int',
                                    component: {
                                        uid: 'max_images_in_email',
                                        name: 'max_images_in_email',
                                        component: 'FormText',
                                    },
                                    componentProps: {
                                        label: t('filter.max_inst'),
                                    },
                                })}
                            />
                        </FormControl>
                    </Box>
                </Stack>
            </Stack>
        </>
    );
}
