import { MassActionCheckCellProps } from 'components/common/grid/';
import { Cell } from 'rsuite-table';
import { Checkbox, Grid } from '@mui/material';
import { SxProps } from '@mui/system';

export default function MassActionCheckCell({
    rowData,
    onChange,
    dataKey,
    column,
    checkedRowsKeys,
    uid,
    ...props
}: MassActionCheckCellProps) {
    const checked = () => {
        if (checkedRowsKeys) {
            return checkedRowsKeys.some((item) => item == rowData[dataKey]);
        }

        return false;
    };

    const gridProps: SxProps = { height: 1, alignItems: 'center', justifyContent: 'right', position: 'relative' };

    return (
        <Cell {...props} className={`rs-table-cell--checkbox ${checked() ? 'rs-table-cell--selected-row' : ''}`}>
            <Grid container sx={gridProps} className={'checkbox-holder disabled-checkbox-cursor-mod--not-allowed'}>
                <Checkbox
                    data-test={`grid_${uid}_checkbox_column_check_${rowData[dataKey]}`}
                    sx={{ padding: 0 }}
                    value={rowData[dataKey]}
                    onChange={onChange}
                    checked={checked()}
                    disabled={Boolean(rowData['unselectable'])}
                />
            </Grid>
        </Cell>
    );
}
