import React from 'react';
import { Box, Stack } from '@mui/material';
import useBundleTranslation from 'i18n';
import styles from './styles';
import IconMi from 'components/common/icon/IconMi';
import format from 'date-fns/format';
import { TileInfo } from 'app/home/index.functions';

export default function InfoCertification({ tile, showDivider = true }: { tile: TileInfo; showDivider?: boolean }) {
    const { t } = useBundleTranslation('app/content-workflow/info-card');

    if (tile.certified_ind === 'N') {
        return null;
    }

    return (
        <Stack direction="row" sx={styles.sectionWrapper}>
            <Box sx={styles.iconHolder}>
                <IconMi
                    icon="certified"
                    sx={{ fontSize: '16px', color: tile.certification_level_color || 'success.main' }}
                />
            </Box>
            <Box sx={styles.breakWordValue}>
                <Box component="span" sx={{ color: tile.certification_level_color || 'success.main', fontWeight: 600 }}>
                    {tile.certification_level_name && `${tile.certification_level_name} `}
                    {t('certified')}
                </Box>
                <Box component="span">{` ${t('by')} `}</Box>
                <Box
                    component="span"
                    sx={{ color: 'primary.main', cursor: 'pointer' }}
                    onClick={() => {
                        window.location.href = `mailto:${tile.last_certified_by_email}`;
                    }}
                >
                    {tile.last_certified_by_name}
                </Box>
                <Box component="span">
                    {` ${t('on')} `}
                    {tile.last_certified_time && '0000-00-00 00:00:00' != tile.last_certified_time
                        ? format(new Date(tile.last_certified_time), 'M/dd/yyyy')
                        : ''}
                </Box>
            </Box>
        </Stack>
    );
}
