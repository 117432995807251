import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import ControlsPanel from 'components/element-viewer/ControlsPanel';
import { ElementViewerDataType } from 'components/element-viewer';
import { DashboardElementType } from 'tools/types';
import { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';

export default function TopPanel({
    viewerData,
    tileName,
    openElement,
    bookmarksContext,
}: {
    viewerData?: ElementViewerDataType;
    tileName: string;
    openElement: (type: DashboardElementType, elementId: number, segmentValueId: number) => void;
    bookmarksContext?: IBookmarksContext;
}) {
    const element = viewerData?.elementData;

    if (element === undefined) {
        return null;
    }

    const onNameClick = () => {
        if (!element.row.type) {
            return;
        }
        return openElement(element.row.type, element.row.elementId, viewerData?.segmentValueId ?? 0);
    };

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography fontSize={18} color={'primary.main'} sx={{ fontWeight: 600 }}>
                <Box
                    data-test={'preview-tile-name'}
                    component={'span'}
                    dangerouslySetInnerHTML={{ __html: tileName }}
                    sx={{ cursor: 'pointer' }}
                    onClick={onNameClick}
                />
            </Typography>
            <Stack direction="row" alignItems="center">
                <ControlsPanel
                    bookmarksContext={bookmarksContext}
                    context="preview"
                    element={element}
                    segmentValueId={viewerData?.segmentValueId ?? 0}
                    onFavoritesChange={() => {}}
                    alertStatus={viewerData?.alertStatus}
                />
            </Stack>
        </Stack>
    );
}
