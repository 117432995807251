import { AsyncGridProps, ButtonConfig, ColumnsWidth, ColumnType, getColumnLabel, VisibleColumns } from './index';
import { useFetchGridData } from './hooks/useFetchGridData';
import { useGridPopup } from './hooks/useGridPopup';
import useBundleTranslation from 'i18n';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGridMassActions } from './hooks/useGridMassActions';
import EmptyGrid from './EmptyGrid';
import { alpha, Container, Grid, TablePagination, Typography } from '@mui/material';
import TableFilters from './TableFilters';
import GridLoading from './GridLoading';
import MITable from './MITable';
import NoSearchResult from './NoSearchResult';
import TableButtons from './TableButtons';
import GridPopup from './GridPopup';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useNavigate } from 'react-router-dom';
import { simulateMouseClick } from 'tools/tools';
import { useInView } from 'react-intersection-observer';
import { GridViewComponents } from 'grid-views';

export default function AsyncGridComponent(props: AsyncGridProps) {
    const {
        error,
        resetFiltersButton,
        filtersList,
        searchField,
        gridData,
        isLoading,
        isError,
        columns,
        filtersValue,
        order,
        orderBy,
        legendConfig,
        pagerOptions,
        totalCount,
        pageIndex,
        gridTitle,
        gridDescription,
        dragAndDrop,
        massActions,
        buttons,
        controllerUrl,
        emptyTitle,
        emptyIcon,
        hideEmpty,
        resetColumns,
        switchColumns,
        widthToggle,
        infoButton,
        gridName,
        visibleFilters,
        multilines,
        handleDropRow,
        setPageIndex,
        onSortColumn,
        handleFilterValueChange,
        resetFilters,
        reloadGridData,
        saveColumns,
        gridParams,
        noSearchResult,
        rowExtraInfoConfig,
        changeViewConfig,
        gridReorderLoading,
    } = useFetchGridData(props.uid, props.settingsUrl, props.relatedFields);

    const {
        popupUrl,
        setPopupUrl,
        popupConfig,
        setPopupConfig,
        popupType,
        setPopupType,
        popupMode,
        setPopupMode,
        popupRedirectAfter,
        setPopupRedirectAfter,
        formPostData,
        setFormPostData,
        popupGetMethod,
        setPopupGetMethod,
        popupComponent,
        setPopupComponent,
        handleHidePopup,
        handleConfirmPopup,
        setPopupComponentProps,
        popupComponentProps,
    } = useGridPopup(props.uid);

    const navigate = useNavigate();

    const gridButtons: Array<ButtonConfig> = props.hideButtons ? [] : buttons;

    const t = undefined !== props.t ? props.t : useBundleTranslation(['components/common/grid/grid'])['t'];

    const boxTableRef = useRef<HTMLDivElement>(null);

    const [gridWidth, setGridWidth] = useState(0);
    const [columnsWidth, setColumnsWidth] = useState<ColumnsWidth>({});
    const [readyToDraw, setReadyToDraw] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState<VisibleColumns>({});
    const [visibilityChanged, setVisibilityChanged] = useState(false);
    const [fullwidthMode, setFullwidthMode] = useState('fixed');
    const [gridDataChanged, setGridDataChanged] = useState(false);
    const [gridParamsApplied, setGridParamsApplied] = useState(false);
    const [showEditSelector, setShowEditSelector] = useState('');
    const [isStickyGridButtons, setIsStickyGridButtons] = useState(false);
    const [gridView, setGridView] = useState('default');

    const handleColumnSwitch = (columnName: string, visible: boolean) => {
        setVisibilityChanged(true);
        setVisibleColumns((prevState) => {
            const newState = { ...prevState };
            newState[columnName].visible = visible;

            return newState;
        });
    };

    const filtered = useMemo(() => {
        let result = false;
        const filters = Object.values(filtersValue);
        filters.forEach((filter) => {
            if (filter !== '') {
                result = true;
            }
        });

        return result;
    }, [filtersValue]);
    const emptyData = gridData.length === 0 && !filtered;
    const emptyPageGridData = !props.formGrid && emptyData && !isLoading;
    const massActionsProps = useGridMassActions(gridData, massActions);
    const isDraggable = dragAndDrop?.enabled;
    const showCheckboxColumn = massActions.enabled && massActionsProps !== undefined;

    const reCalcColumnsWidth = (newGridWidth: number = gridWidth, visColumns: VisibleColumns = visibleColumns) => {
        if (newGridWidth > 0) {
            setColumnsWidth({});

            let clearGridWidth = newGridWidth - (isDraggable ? 50 : 0) - (showCheckboxColumn ? 24 : 0),
                totalColumnsWidth = 0,
                columnsMultiplier = 0;

            columns.forEach((column: ColumnType) => {
                if (!column.hidden && visColumns[column.name] && visColumns[column.name].visible) {
                    totalColumnsWidth += Number(column.width ?? 50);
                }
            });

            if (totalColumnsWidth > 0) {
                columnsMultiplier = clearGridWidth / totalColumnsWidth;
                columns.forEach((column: ColumnType) => {
                    columnsWidth[column.name] = columnsMultiplier * (column.width ?? 50);
                });
                setColumnsWidth(columnsWidth);
                return columnsWidth;
            }
        }

        return null;
    };

    const onContainerResize = () => {
        if (boxTableRef && boxTableRef.current) {
            setGridWidth(boxTableRef.current.offsetWidth);
        }
    };

    const resizeObserver = new ResizeObserver(onContainerResize);

    const [refFooter, inView, entry] = useInView({
        /* Optional options */
        root: document.querySelector('#page-scroll-grid-sticky'),
        threshold: 0.1,
        initialInView: true,
    });

    useEffect(() => {
        setIsStickyGridButtons(
            !!(!inView && entry && entry.rootBounds && entry.rootBounds.top < entry?.boundingClientRect?.top)
        );
    }, [inView]);

    useEffect(() => {
        if (readyToDraw && boxTableRef && boxTableRef.current) {
            resizeObserver.observe(boxTableRef.current);
        }
    }, [readyToDraw]);

    useEffect(() => {
        window.addEventListener('resize', onContainerResize);

        return () => {
            window.removeEventListener('resize', onContainerResize);
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!isLoading && columns.length > 0 && props.formGrid && props.onDataStoreChange) {
            if (!filtered || gridDataChanged) {
                props.onDataStoreChange(gridData);
                setGridDataChanged(false);
            }

            if (gridData.length === 0) {
                setReadyToDraw(true);
            }
        }
    }, [gridData, isLoading]);

    useEffect(() => {
        if (visibilityChanged) {
            const colWidth = reCalcColumnsWidth();
            if (colWidth && gridName > '') {
                setVisibilityChanged(false);
                saveColumns(colWidth, visibleColumns, fullwidthMode, gridView).then(() => {
                    reloadGridData();
                });
            }
        }
    }, [visibleColumns, visibilityChanged]);

    useEffect(() => {
        if (
            gridParams &&
            gridParams.fullwidthMode &&
            boxTableRef &&
            boxTableRef.current &&
            fullwidthMode !== gridParams.fullwidthMode
        ) {
            const newGridWidth = boxTableRef.current.offsetWidth;
            const colWidth = reCalcColumnsWidth(newGridWidth);
            if (colWidth) {
                saveColumns(colWidth, visibleColumns, fullwidthMode, gridView).then(() => {
                    reloadGridData();
                });
            }
        }
    }, [fullwidthMode]);

    useEffect(() => {
        if (gridWidth > 0 && !gridParams) {
            reCalcColumnsWidth();
        }
    }, [gridWidth]);

    const onFullwidthChange = (mode: string) => {
        setFullwidthMode(mode);
    };

    useEffect(() => {
        if (!isLoading && columns.length > 0) {
            const visColumns: VisibleColumns = {};
            columns.forEach((column) => {
                if (column.hidden) {
                    return;
                }

                const label = getColumnLabel(column, t);

                const isColumnVisible =
                    gridParams && gridParams.columns.hasOwnProperty(column.name)
                        ? !Boolean(gridParams.columns[column.name].hidden)
                        : column.visible ?? true;

                visColumns[column.name] = {
                    label: label > '' ? label : column.name,
                    hidable: column.hidable ?? true,
                    visible: isColumnVisible,
                };
            });
            setVisibleColumns(visColumns);

            if (gridWidth > 0) {
                if (gridParams && !gridParamsApplied) {
                    const gridParamsWidth: ColumnsWidth = {};
                    columns.forEach((column) => {
                        if (gridParams.columns.hasOwnProperty(column.name)) {
                            gridParamsWidth[column.name] = Number(gridParams.columns[column.name].width) ?? 50;
                        } else {
                            gridParamsWidth[column.name] = 50;
                        }
                    });
                    setColumnsWidth(gridParamsWidth);
                    if (widthToggle && gridParams.fullwidthMode > '') {
                        setFullwidthMode(gridParams.fullwidthMode);
                    }
                    setGridView(gridParams.gridView ?? 'default');
                    setGridParamsApplied(true);
                }

                if (!gridParams) {
                    const colWidth = reCalcColumnsWidth(gridWidth, visColumns);
                    if (colWidth) {
                        saveColumns(colWidth, visColumns, fullwidthMode, gridView);
                    }
                }
            }
            setReadyToDraw(true);
        }
    }, [gridWidth, isLoading, gridParams, columns]);

    const onColumnResize = useCallback(
        (columnWidth?: number, dataKey?: string) => {
            if (dataKey && columnWidth) {
                columnsWidth[dataKey] = columnWidth;
                //todo: temp changes for MI-24833
                console.log(`Column Width "${dataKey}":`, Math.round(columnWidth));
                setColumnsWidth(columnsWidth);
                if (saveColumns) {
                    saveColumns(columnsWidth, visibleColumns, fullwidthMode, gridView);
                }
            }
        },
        [columnsWidth, saveColumns, visibleColumns, fullwidthMode, gridView]
    );

    useEffect(() => {
        if (!isLoading && showEditSelector > '') {
            setTimeout(() => {
                const element = document.querySelector(showEditSelector);
                setShowEditSelector('');
                simulateMouseClick(element);
            }, 200);
        }
    }, [isLoading, showEditSelector]);

    const resetGridSettings = useCallback(() => {
        const defaultVisibleColumns: VisibleColumns = {};
        columns.forEach((column) => {
            if (column.hidden) {
                return;
            }

            const label = getColumnLabel(column, t);

            const isColumnVisible = column.visible ?? true;

            defaultVisibleColumns[column.name] = {
                label: label > '' ? label : column.name,
                hidable: column.hidable ?? true,
                visible: isColumnVisible,
            };
        });
        setVisibleColumns(defaultVisibleColumns);

        const colWidth = reCalcColumnsWidth(gridWidth, defaultVisibleColumns);
        if (colWidth) {
            saveColumns(colWidth, defaultVisibleColumns, fullwidthMode, gridView).then(() => {
                reloadGridData();
            });
        }
    }, [saveColumns, visibleColumns, fullwidthMode, reloadGridData, gridView]);

    const afterDataSave = (response: any) => {
        if (response.data && response.data.redirectUrl > '' && response.data.forceRedirect === true) {
            window.location.href = response.data.redirectUrl;
        }

        if (response.data.status === 'WARNING' && response.data.message) {
            alert(response.data.message);
        }

        if (popupRedirectAfter) {
            const redirectUrl =
                response.data && response.data.redirectUrl ? response.data.redirectUrl : response.redirectUrl ?? '';

            if (redirectUrl > '') {
                if (response.data.download || response.download) {
                    window.location.href = response.data.redirectUrl;
                    return;
                }

                setTimeout(() => navigate(redirectUrl), 300);
            }
        } else {
            if (setGridDataChanged) {
                setGridDataChanged(true);
            }
            if (reloadGridData) {
                if (response.data.showEditPopup === true) {
                    reloadGridData().then(() => {
                        const itemSelector = `[data-test='grid_${gridName}_edit_column_${response.data.id}_cell'] button`;
                        setShowEditSelector(itemSelector);
                    });
                } else {
                    reloadGridData();
                }
            }
            if (massActionsProps.setCheckedKeys) {
                massActionsProps.setCheckedKeys([]);
            }
        }
    };

    useEffect(() => {
        if (props.reloadTrigger > 0) {
            reloadGridData();
        }
    }, [props?.reloadTrigger]);

    const onChangeView = useCallback(
        (value: string) => {
            setGridView(value > '' ? value : 'default');
            if (saveColumns) {
                saveColumns(columnsWidth, visibleColumns, fullwidthMode, value);
            }
        },
        [saveColumns, columnsWidth, visibleColumns, fullwidthMode]
    );

    useEffect(() => {
        //hide container for empty grid
        //ToDo fix for grid inside wrapper component (hide wrapper with other elements)
        /*
        const componentContainer =
            boxTableRef && boxTableRef.current ? boxTableRef.current.closest('.form-component-holder') : null;

        if (hideEmpty && componentContainer && !gridButtons?.length) {
            if (emptyData) {
                componentContainer.classList.add('d-none-empty-grid');
            } else {
                componentContainer.classList.remove('d-none-empty-grid');
            }
        }*/
    }, [emptyData, hideEmpty]);

    const gridPopup = (
        <GridPopup
            uid={'grid_popup_' + props.uid}
            popupUrl={popupUrl}
            handleHidePopup={handleHidePopup}
            handleConfirmPopup={handleConfirmPopup}
            popupConfig={popupConfig}
            popupType={popupType}
            popupMode={popupMode}
            reloadGridData={reloadGridData}
            formPostData={formPostData}
            getMethod={popupGetMethod}
            component={popupComponent}
            componentProps={popupComponentProps}
            setCheckedKeys={massActionsProps.setCheckedKeys}
            gridData={gridData}
            afterSave={afterDataSave}
        />
    );

    if (emptyPageGridData) {
        return (
            <EmptyGrid
                emptyTitle={emptyTitle}
                emptyIcon={emptyIcon}
                createButtons={gridButtons ? gridButtons.filter((config) => config.type === 'create') : []}
                controllerUrl={controllerUrl}
                t={t}
                gridName={gridName}
                height={props.height}
                gridPopup={gridPopup}
                setPopupUrl={setPopupUrl}
                setPopupConfig={setPopupConfig}
                setPopupType={setPopupType}
                setPopupMode={setPopupMode}
                setPopupRedirectAfter={setPopupRedirectAfter}
                setFormPostData={setFormPostData}
                setPopupGetMethod={setPopupGetMethod}
                setPopupComponent={setPopupComponent}
                setPopupComponentProps={setPopupComponentProps}
            />
        );
    }

    const gridContainerXs = !(fullwidthMode === 'full' && widthToggle) ? 'lg' : false;

    const gridBodyComponentProps = {
        data: gridData,
        columns: columns,
        isLoading: isLoading,
        onSortColumn: onSortColumn,
        order: order,
        orderBy: orderBy,
        legendConfig: legendConfig,
        dragAndDrop: dragAndDrop,
        handleDropRow: handleDropRow,
        massActionsConfig: massActions,
        reloadGridData: reloadGridData,
        onColumnResize: onColumnResize,
        massActionsProps: massActionsProps,
        setPopupUrl: setPopupUrl,
        setPopupConfig: setPopupConfig,
        autoHeight: true,
        height: props.height,
        setPopupType: setPopupType,
        setPopupMode: setPopupMode,
        setPopupComponent: setPopupComponent,
        setPopupComponentProps: setPopupComponentProps,
        t: t,
        multilines: multilines,
        columnsWidth: columnsWidth,
        setShowLoader: setShowLoader,
        gridName: gridName,
        visibleColumns: visibleColumns,
        rowExtraInfoConfig: rowExtraInfoConfig,
        gridReorderLoading,
        form: props.form,
        setGridDataChanged: setGridDataChanged,
        uid: props.uid,
    };

    const bodyComponent = GridViewComponents[gridView] ?? MITable;
    const gridBodyComponent = React.createElement(bodyComponent, gridBodyComponentProps);

    return (
        <Container className={'mi-container-grid'} maxWidth={gridContainerXs} component="main" disableGutters={true}>
            <Grid item container direction="column" data-test={`grid_${props.uid}`} ref={boxTableRef}>
                <Grid item data-test={`grid_${props.uid}_filters_container`} xs={12}>
                    {!(emptyData && true === hideEmpty) && (
                        <TableFilters
                            uid={props.uid}
                            isEmptyState={emptyData}
                            filtersList={filtersList}
                            searchField={searchField}
                            filterValues={filtersValue}
                            setFiltersValue={handleFilterValueChange}
                            resetFilters={resetFilters}
                            resetFiltersButton={resetFiltersButton}
                            resetGridSettings={resetGridSettings}
                            resetColumns={resetColumns}
                            switchColumns={switchColumns}
                            gridTitle={gridTitle.needTranslation === false ? gridTitle.text : t(gridTitle.text)}
                            gridDescription={props.gridDescription ?? gridDescription}
                            gridTranslate={t}
                            infoButton={infoButton}
                            gridName={gridName}
                            initVisibleFilters={visibleFilters}
                            visibleColumns={visibleColumns}
                            handleColumnSwitch={handleColumnSwitch}
                            fullwidthMode={fullwidthMode}
                            onFullwidthChange={onFullwidthChange}
                            widthToggle={widthToggle}
                            setPageIndex={setPageIndex}
                            removeHeaderIndent={props.removeHeaderIndent}
                            isLoading={isLoading || !readyToDraw}
                            onChangeView={onChangeView}
                            changeViewConfig={changeViewConfig}
                            gridView={gridView}
                        />
                    )}
                </Grid>
                {isLoading || !readyToDraw ? (
                    <Grid item container justifyContent="center" data-test={`grid_${props.uid}_loading`}>
                        <GridLoading />
                    </Grid>
                ) : (
                    <Grid item data-test={`grid_${props.uid}_container`}>
                        {isError ? (
                            <span>Error: {error!.message}</span>
                        ) : gridData.length > 0 ? (
                            gridBodyComponent
                        ) : null}
                        {gridData.length === 0 && filtered && (
                            <NoSearchResult
                                emptyText={noSearchResult?.emptyText ? t(noSearchResult?.emptyText) : ''}
                                icon={noSearchResult?.icon}
                            />
                        )}
                        <Grid
                            item
                            container
                            className={`grid-footer ${
                                gridButtons && gridButtons.length > 0 ? 'grid-footer--buttons-exist' : ''
                            } ${isStickyGridButtons ? 'grid-footer--sticky-mod' : ''} ${
                                fullwidthMode === 'full' ? 'grid--full-mod' : ''
                            }`}
                            ref={refFooter}
                            sx={{
                                mt: emptyData && true === hideEmpty ? undefined : '6px',
                            }}
                        >
                            <Grid item container className={`grid-footer-content-wrap`} justifyContent="center">
                                <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={'grid-footer-content'}
                                >
                                    <Grid item>
                                        <Grid container direction="row" spacing={2} alignItems="center">
                                            {gridButtons && gridButtons.length > 0 && (
                                                <TableButtons
                                                    uid={props.uid}
                                                    controllerUrl={controllerUrl}
                                                    gridButtons={gridButtons}
                                                    massActionsProps={massActionsProps}
                                                    reloadGridData={reloadGridData}
                                                    setPopupUrl={setPopupUrl}
                                                    setPopupConfig={setPopupConfig}
                                                    setPopupType={setPopupType}
                                                    setPopupMode={setPopupMode}
                                                    setPopupRedirectAfter={setPopupRedirectAfter}
                                                    setFormPostData={setFormPostData}
                                                    setPopupGetMethod={setPopupGetMethod}
                                                    setPopupComponent={setPopupComponent}
                                                    setPopupComponentProps={setPopupComponentProps}
                                                    gridData={gridData}
                                                    massActionsConfig={massActions}
                                                    t={t}
                                                    setShowLoader={setShowLoader}
                                                    filtersValue={filtersValue}
                                                />
                                            )}
                                            {gridData.length === 0 &&
                                            props.formGrid &&
                                            !filtered &&
                                            false === hideEmpty ? (
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {emptyTitle.needTranslation === false
                                                            ? emptyTitle.text
                                                            : t(emptyTitle.text)}
                                                    </Typography>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    {pagerOptions && pagerOptions.visible && gridData.length > 0 && totalCount > 20 ? (
                                        <TablePagination
                                            data-test={`grid_${props.uid}_pagination`}
                                            rowsPerPage={pagerOptions.pageSize}
                                            rowsPerPageOptions={[pagerOptions.pageSize]}
                                            component="div"
                                            count={totalCount}
                                            page={pageIndex}
                                            showFirstButton
                                            showLastButton
                                            sx={{
                                                '.MuiToolbar-root .MuiTablePagination-actions .MuiIconButton-root': {
                                                    padding: '3px 10px',
                                                },
                                            }}
                                            onPageChange={(event, page) => setPageIndex(page)}
                                            labelDisplayedRows={({ from, to, count }) =>
                                                `${from}-${to} ${t('pagination_of')} ${count}`
                                            }
                                        />
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {gridPopup}
                {showLoader && (
                    <LoadingPlaceholder
                        sx={{
                            position: 'absolute',
                            backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                            color: (theme) => alpha(theme.palette.text.primary, 0.5),
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                    />
                )}
            </Grid>
        </Container>
    );
}
