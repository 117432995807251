import { alpha, Theme } from '@mui/material';

export default {
    resizableComponent: {
        display: 'flex',
        flexDirection: 'column',
    },
    extraSizeContainer: {
        width: 'calc(100% + 288px)',
    },
    container: {
        position: 'relative',
    },
    divider: {
        width: '1px',
        height: '16px',
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        marginRight: '4px',
        marginLeft: '4px',
        alignSelf: 'center',
    },
    commandContainer: {
        backgroundColor: 'background.default',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
    },
    commandHeader: {
        flexShrink: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
        borderBottom: 0,
        '.isExpandedPanel &': {
            borderTopRightRadius: 0,
        },
    },
    commandContent: {
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        '.editor': {
            counterReset: 'line',
            /*border: 1px solid red;*/
        },
        '.editor #codeArea': {
            outline: 'none',
            paddingLeft: '56px !important',
        },
        '.editor pre': {
            paddingLeft: '56px !important',
            minHeight: '100%',
            display: 'block !important',
            flexGrow: 1,
            height: 'auto',
            backgroundColor: (theme: Theme) => `${alpha(theme.palette.text.primary, 0.04)} !important`,
            '.token.atrule, .token.attr-value, .token.keyword': {
                color: (theme: Theme) => theme.palette.primary.main,
                textShadow: '0px 0px 1px currentColor', //emulate fontWeight: 700 to reject bug with cursor FF
            },
        },
        '.editor pre:before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            height: '100%',
            width: '40px',
            backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        },
        '.editor .editorLineNumber': {
            position: 'absolute',
            left: 0,
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
            textAlign: 'right',
            width: '40px',
            fontWeight: 400,
            pr: 1,
        },
    },
    commandFooter: {
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        borderBottomRightRadius: '3px',
        borderBottomLeftRadius: '3px',
        borderTop: 0,
        p: 1,
        '.isExpandedPanel &': {
            borderBottomRightRadius: 0,
        },
    },
    commandRightPanel: {
        width: '288px',
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        borderTopRightRadius: '3px',
        borderLeft: 0,
        flexShrink: 0,
    },
    editorComponent: {
        //pass styles to external plugin
        outline: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
    },
    validationMsgBlock: {
        padding: 1,
        mt: 1,
        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        borderRadius: '3px',
    },
    validationError: {
        borderColor: (theme: Theme) => alpha(theme.palette.error.main, 0.8),
        backgroundColor: (theme: Theme) => alpha(theme.palette.error.main, 0.04),
    },
    validationWarning: {
        borderColor: (theme: Theme) => alpha(theme.palette.warning.main, 0.8),
        backgroundColor: (theme: Theme) => alpha(theme.palette.warning.main, 0.04),
    },
    validationSuccess: {
        borderColor: (theme: Theme) => alpha(theme.palette.success.main, 0.8),
        backgroundColor: (theme: Theme) => alpha(theme.palette.success.main, 0.04),
    },
    commandActions: {
        pt: 2,
        flexShrink: 0,
        borderTop: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        '& > *': {
            flexShrink: 0,
        },
    },
    rightSidePanel: {
        position: 'absolute',
        left: '100%',
        top: 0,
        bottom: 0,
        width: '300px',
    },
};
