import React, { useContext, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, FormControlLabel, Radio, RadioGroup, Stack, TextField, Tooltip } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { PluginQBContext } from 'components/plugin-query-builder/PluginQueryBuilder';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';

export interface QueryBuilderFilterPopupProps {
    onClose: () => void;
    onApply: (data: any) => void;
    editElement?: any;
}

export default function QueryBuilderFilterPopup(props: QueryBuilderFilterPopupProps) {
    const { t } = useBundleTranslation(['components/plugin-query-builder/plugin-query-builder']);
    const { onClose = () => {}, onApply = () => {}, editElement } = props;
    const { reportData, reportAct, queryData, queryAct, configData, helperAct, pluginConfig } =
        useContext(PluginQBContext);

    const isAdd = !editElement;

    const [conditionVal, setConditionVal] = useState<string>(isAdd ? '' : editElement.condition);
    const [valueData, setValueData] = useState<string>('');
    const [fieldVal, setFieldVal] = useState<string>(isAdd ? '' : editElement.column);
    const [dateValType, setDateValType] = useState<string>('pattern');

    const conditionVariants: any = {
        equal: {
            value: '==',
        },
        not_equal: {
            value: '!=',
        },
        greater: {
            value: '>',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        less: {
            value: '<',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        greater_or_equal: {
            value: '>=',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        less_or_equal: {
            value: '<=',
            filedType: ['INTEGER', 'DECIMAL', 'DATE', 'EXPR'],
        },
        in_list: {
            value: 'in',
            label: t('filter_condition_label.in_list'),
            filedType: ['INTEGER', 'DECIMAL', 'TEXT', 'EXPR'],
        },
        empty: {
            value: 'is null',
            label: t('filter_condition_label.empty'),
        },
        not_empty: {
            value: 'is not null',
            label: t('filter_condition_label.not_empty'),
        },
    };

    if (pluginConfig.removeFilterConditionIn) {
        delete conditionVariants.in_list;
    }

    let conditionOptions: any[] = [];
    Object.keys(conditionVariants).forEach((conditionKey) => {
        const conditionSettings = conditionVariants[conditionKey];
        const isAvailableForFieldType =
            !pluginConfig.checkFilterConditionByFieldType || //turn off check like qlik, cogos ad etc
            !conditionSettings.filedType || //has no condition, available for all fields
            conditionSettings.filedType.includes(reportData.fields[fieldVal]?.type); //pass condition
        if (isAvailableForFieldType) {
            conditionOptions.push({
                value: conditionSettings.value,
                label: conditionSettings.label ?? conditionSettings.value,
            });
        }
    });

    const fieldsOptions = Object.values(reportData.fields).map((field: any) => {
        return {
            value: helperAct.escapeHtml(field.name),
            label: helperAct.escapeHtml(field.name),
        };
    });

    const getCalendarData = (data: string): string => {
        if (!data.match(/\$[0-9]{4}-[0-9]{2}-[0-9]{2}/g)) {
            const now = new Date();
            return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        }
        return data;
    };
    const elementProps = useCustomSimplifiedForm({
        ruleDataCalendar: '',
    });

    useEffect(() => {
        if (!isAdd) {
            if (reportData.fields[fieldVal].type !== 'DATE') {
                setValueData(helperAct.escapeHtml(editElement.value));
            } else {
                if (configData.pattern && editElement.value == helperAct.escapeHtml(configData.pattern)) {
                    setValueData(helperAct.escapeHtml(configData.pattern));
                    setDateValType('pattern');
                } else {
                    elementProps.form.hookFormSetValue('ruleDataCalendar', helperAct.escapeHtml(editElement.value));
                    setDateValType('date');
                    setValueData('');
                }
            }
        }
    }, []);

    return (
        <Popup
            settings={{
                title: isAdd ? t('filter_popup.add.title') : t('filter_popup.edit.title'),
                textOK: isAdd ? t('filter_popup.add.ob_btn') : t('filter_popup.edit.ob_btn'),
                maxWidth: 'popupLg',
            }}
            open={true}
            onHide={onClose}
            onConfirm={() => {
                if (!fieldVal) {
                    alert(t('filter_popup.alert_select_column'));
                    return false;
                }

                if (!conditionVal) {
                    alert(t('filter_popup.alert_select_condition'));
                    return false;
                }

                let value: any = valueData;
                let condition: any = conditionVal;

                if (reportData.fields[fieldVal].type === 'DATE') {
                    if (configData.pattern && dateValType == 'pattern') {
                        value = helperAct.escapeHtml(configData.pattern);
                    } else {
                        value = elementProps.form.hookFormGetValues('ruleDataCalendar');
                        if (!value) {
                            alert(t('filter_popup.alert_choose_date'));
                            return false;
                        }
                    }
                }
                if (['is null', 'is not null'].includes(conditionVal)) {
                    value = null;
                }

                if (!isAdd && !(editElement.condition != conditionVal || editElement.value != value)) {
                    //Edit without changes. Close popup
                    onClose();
                    return false;
                }

                const isStrictDuplicate =
                    queryData.filters.findIndex((item: any) => {
                        return item.column == fieldVal && item.condition == conditionVal && item.value == value;
                    }) !== -1;

                if (isStrictDuplicate) {
                    if (isAdd) {
                        alert(
                            pluginConfig.isNonStrictCheckFilterDuplicates
                                ? t('filter_popup.alert_duplicate_non_strict')
                                : t('filter_popup.alert_duplicate')
                        );
                        return false;
                    } else {
                        alert(t('filter_popup.alert_duplicate_edit'));
                        return false;
                    }
                }

                const isEqualCondition = ['==', 'in'].includes(conditionVal);
                //Non Strict duplicates
                let duplicates = pluginConfig.isNonStrictCheckFilterDuplicates
                    ? queryData.filters
                          .filter((item: any) => {
                              //skip current Edit Element
                              if (isAdd) {
                                  return true;
                              } else {
                                  return !(
                                      item.column === editElement.column &&
                                      item.condition === editElement.condition &&
                                      item.value === editElement.value
                                  );
                              }
                          })
                          .filter((item: any) => {
                              const conditionToCheck =
                                  !pluginConfig.removeFilterConditionIn && isEqualCondition
                                      ? ['==', 'in']
                                      : [conditionVal];
                              return item.column == fieldVal && conditionToCheck.includes(item.condition);
                          })
                    : [];

                console.log(pluginConfig, isStrictDuplicate, duplicates, queryData);
                let newFilters = [...queryData.filters];
                if (!pluginConfig.removeFilterConditionIn && duplicates.length > 0) {
                    console.log(
                        111,
                        isEqualCondition,
                        reportAct.hasField(fieldVal),
                        reportData.fields[fieldVal].type !== 'DATE'
                    );
                    if (
                        isEqualCondition &&
                        reportAct.hasField(fieldVal) &&
                        reportData.fields[fieldVal].type !== 'DATE'
                    ) {
                        let values = duplicates.map((filter: any) => filter.value);
                        if (isAdd) {
                            values.push(value);
                        } else {
                            values.unshift(value);
                        }

                        const uniqueArray = (array: string[]) => {
                            return array.filter((value, index, arr) => {
                                return arr.indexOf(value) === index;
                            });
                        };
                        value = uniqueArray(values.join(', ').split(/\s*,\s*/)).join(', ');
                        condition = 'in';
                        duplicates.forEach((filter: any) => {
                            newFilters = queryAct.removeFilter(filter);
                        });
                        duplicates = [];
                    }
                }

                if (isAdd) {
                    if (duplicates.length > 0) {
                        alert(t('filter_popup.alert_duplicate_non_strict'));
                        return false;
                    }
                } else {
                    if (duplicates.length > 0) {
                        alert(t('filter_popup.alert_duplicate_edit'));
                        return false;
                    }
                }

                const newFilterData = {
                    column: fieldVal,
                    condition: condition,
                    value: value,
                };
                if (isAdd) {
                    queryAct.addFilter(newFilterData.column, newFilterData.condition, newFilterData.value);
                } else {
                    const editFilterIndex = newFilters.findIndex((item: any) => {
                        return (
                            item.column === editElement.column &&
                            item.condition === editElement.condition &&
                            item.value === editElement.value
                        );
                    });

                    newFilters.splice(editFilterIndex, 1, newFilterData);
                    queryAct.updateData({ filters: newFilters });
                }

                onApply({
                    column: fieldVal,
                    condition: condition,
                    value: value,
                });
            }}
        >
            <Stack direction={'row'} spacing={2}>
                <Box sx={{ width: '250px' }}>
                    <Box>{t('filter_popup.label_filter_on')}</Box>

                    {isAdd ? (
                        <ReactSelect
                            data={fieldsOptions}
                            value={fieldVal}
                            update={(name) => {
                                setDateValType(configData.pattern ? 'pattern' : 'date');
                                elementProps.form.hookFormSetValue('ruleDataCalendar', '');
                                setValueData('');
                                setFieldVal(name);
                            }}
                        />
                    ) : (
                        <Tooltip title={editElement.column}>
                            <Box
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    height: (theme) => theme.size.defaultHeight,
                                    display: 'flex',
                                    alignItems: 'center',
                                    px: 1,
                                }}
                            >
                                {editElement.column}
                            </Box>
                        </Tooltip>
                    )}
                </Box>
                <Box sx={{ width: '130px' }}>
                    <Box>{t('filter_popup.label_condition')}</Box>
                    <ReactSelect
                        data={conditionOptions}
                        value={conditionVal}
                        update={(val) => {
                            if (val == 'is null' || val == 'is not null') {
                                setValueData('');
                            }
                            setConditionVal(val);
                        }}
                    />
                </Box>
                {conditionVal !== 'is null' && conditionVal !== 'is not null' && (
                    <Box>
                        <Box>{t('filter_popup.label_value')}</Box>
                        {reportData.fields[fieldVal]?.type === 'DATE' ? (
                            <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                {configData.pattern && (
                                    <RadioGroup
                                        sx={{ flexWrap: 'nowrap', mr: -2 }}
                                        row
                                        name="row-radio-buttons-group"
                                        value={dateValType}
                                        onChange={(event) => {
                                            setDateValType((event.target as HTMLInputElement).value);
                                        }}
                                    >
                                        <FormControlLabel
                                            value="pattern"
                                            control={<Radio />}
                                            label={helperAct.escapeHtml(configData.pattern)}
                                        />
                                        <FormControlLabel value="date" control={<Radio />} label="" />
                                    </RadioGroup>
                                )}
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        component: {
                                            component: 'FormDatePicker',
                                            name: 'ruleDataCalendar',
                                            props: {
                                                placeholder: 'Choose date',
                                                showTimeSelect: configData.interval === 'minute',
                                                timeIntervals: 1,
                                            },
                                        },
                                    })}
                                />
                            </Box>
                        ) : (
                            <TextField
                                value={valueData}
                                onChange={(event) => {
                                    setValueData(event.target.value);
                                }}
                            />
                        )}
                    </Box>
                )}
            </Stack>
        </Popup>
    );
}
