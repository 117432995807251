import {
    FormDataAPIType,
    FormLayoutType,
    FormPanelSettings,
    FormType,
    RawFormComponentType,
    RawFormElementProps,
} from 'components/common/form/index';
import { FormUseTabs } from 'components/common/form/hooks/useFormTabs';
import { IFormTabComponentRecord } from 'components/common/form/formTools';
import { TFunction } from 'react-i18next';
import { PopupSettings } from 'components/common/popup/Popup';
import FormSubFormPopup, { ISubFormPopupProps } from 'components/common/form/renderer/page/FormSubFormPopup';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { FormDebugWindow } from 'components/common/form/FormDebugWindow';
import FormPopupLayout from 'components/common/form/renderer/FormPopupLayout';
import FormAsidePanelLayout from 'components/common/form/renderer/FormAsidePanelLayout';
import FormPageLayout from 'components/common/form/renderer/FormPageLayout';
import { AssocArray } from 'tools/types';
import ConfirmPopup, { IConfirmPopupProps } from 'components/common/form/renderer/page/FormConfirmPopup';

export interface IFormLayoutProps {
    uid: string;
    elementProps: RawFormElementProps;
    tabs: FormUseTabs;
    componentsMap: Array<IFormTabComponentRecord>;
    formLayout: FormLayoutType;
    hookForm: FormDataAPIType;
    t: TFunction<string>;
    // onSubmit: (data: any) => Promise<any>;
    sharedComponents: Array<RawFormComponentType>;
    panelSettings?: FormPanelSettings;
    isNew: boolean;
    asPopupProps: {
        popupType: FormType;
        popupSettings: PopupSettings;
        onHide: () => void;
        isOpen: boolean;
    };
    confirmPopupProps: IConfirmPopupProps;
    subFormPopup: ISubFormPopupProps;
    customTabs?: AssocArray<JSX.Element>;
    actionSettings: any;
}

interface IFormLayoutComposerProps {
    props: IFormLayoutProps;
    pageLayout?: ReactJSXElement;
    popupLayout?: ReactJSXElement;
    asideLayout?: ReactJSXElement;
}

export function FormLayoutComposer({ props, pageLayout, popupLayout, asideLayout }: IFormLayoutComposerProps) {
    asideLayout = asideLayout || <FormAsidePanelLayout {...props} />;
    popupLayout = popupLayout || <FormPopupLayout {...props} />;
    pageLayout = pageLayout || <FormPageLayout {...props} />;

    const isDebugMode = useSelector((state: any) => state.debug.status);
    if (props.formLayout != 'page') {
        return props.formLayout == 'sidePanel' ? asideLayout : popupLayout;
    }

    return (
        <>
            {useMemo(
                () => (
                    <ConfirmPopup {...props.confirmPopupProps} />
                ),
                [props.confirmPopupProps.isOpen]
            )}
            {!props.isNew && <FormSubFormPopup {...props.subFormPopup} />}
            {pageLayout}
            {isDebugMode && <FormDebugWindow formTabs={props.tabs.formTabs} elementProps={props.elementProps} />}
        </>
    );
}
