import React, { useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import { ChartTooltipProps } from 'components/metric/chart/tooltip/RegularSeriesTooltip';
import { ChartPointEvent } from 'components/metric/chart/series/getEventSeries';
import AddComment from 'components/metric/chart/tooltip/AddComment';
import useAddComment from 'components/metric/chart/tooltip/useAddComment';
import DeleteItemWall from 'components/metric/chart/tooltip/DeleteItemWall';
import EditItemWall from 'components/metric/chart/tooltip/EditItemWall';
import useWallContext, { wallDeleteEvent } from 'components/wall/hooks/useWallContext';
import { Box, Divider } from '@mui/material';
import { useIsAdmin, useUserInfo } from 'hooks/useUserPrivilege';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { createPortal } from 'react-dom';

export interface ChartEventTooltipProps extends ChartTooltipProps {
    point: ChartPointEvent;
    permittedCalendars: Array<any>;
}

export default function EventTooltip({ point, t, isSelected, onClose, permittedCalendars }: ChartEventTooltipProps) {
    const metadata = point.metadata;
    const { handleAddCommentClick, setEditEventId } = useAddComment(
        'notable_event',
        String(point?.metadata?.key),
        onClose
    );

    const { triggerOnUpdate, wallActions } = useWallContext(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleDeleteClick = () => {
        if (!confirm(t('delete_event_confirm'))) {
            return;
        }
        setIsLoading(true);
        onClose();
        wallDeleteEvent(Number(metadata.key))
            .then((response) => {
                if (response.data?.status == 'OK') {
                    wallActions.onDeleteEvent(Number(metadata.key));
                }
            })
            .finally(() => {
                setIsLoading(false);
                triggerOnUpdate({});
            });
    };
    const handleEditClick = () => {
        setEditEventId(Number(metadata.key));
    };
    const userInfo = useUserInfo();
    const isAdmin = useIsAdmin();
    let allowEdit = false;
    if (
        point.metadata?.nec?.nec_fetch_method == 'manual' &&
        (isAdmin ||
            userInfo.username == point.metadata?.nec?.nec_owner ||
            (point.metadata?.nec?.nec_id && permittedCalendars[point.metadata?.nec?.nec_id] > 0))
    ) {
        allowEdit = true;
    }

    return (
        <Box
            sx={{
                py: 1,
                px: 1.5,
            }}
        >
            {isLoading && createPortal(<LoadingPlaceholder />, document.body)}
            <Box sx={{ fontWeight: 600, pr: isSelected ? 1.5 : undefined }}>
                {t('label_value_on_time', {
                    value: metadata.label.text,
                    time: metadata.eventStartTimeFormatted,
                })}
            </Box>

            {isSelected && (
                <Box
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        p: 0.625,
                        right: 0,
                        top: 0,
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <IconMi icon={'times'} />
                </Box>
            )}

            <Box>{metadata.label.description}</Box>

            {isSelected && (
                <>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ flexGrow: 1, ml: -0.5 }}>
                            <AddComment onClick={handleAddCommentClick} />
                        </Box>
                        {allowEdit && (
                            <Box sx={{ flexShrink: 0 }}>
                                <EditItemWall
                                    onClick={() => {
                                        handleEditClick();
                                    }}
                                />
                                <DeleteItemWall
                                    onClick={() => {
                                        handleDeleteClick();
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}
