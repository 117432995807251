import { useParams } from 'react-router-dom';
import { processSettingsUrl } from 'components/common/form/formTools';
import { FormControlDataFetchCommandProps } from 'components/common/form/DataFetchCommand';
import useBundleTranslation from 'i18n';
import pollingService from 'tools/pollingService';
import { AssocArray } from 'tools/types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BodyLoadingPlaceholder } from 'components/common/loading-placeholder/LoadingPlaceholder';
import {
    IValidationResponse,
    IValidationResponseDataDependencySuccess,
} from 'components/common/form/data-fetch-command/validation/index';
import getSuccessMessage from 'components/common/form/data-fetch-command/validation/getSuccessMessage';
import { DataFetchCommandNS } from 'components/common/form/data-fetch-command/index';
import prepareRequestData = DataFetchCommandNS.prepareRequestData;
import DataFetchCommandSubstitution from 'components/common/form/data-fetch-command/substitution/DataFetchCommandSubstitution';
import { useRequiredFields } from 'components/common/form/data-fetch-command/substitution/hooks/useRequiredFields';
import { getPostArgs } from 'components/common/form/data-fetch-command/substitution';
import { editorAPI } from 'api/editor';
import {
    DataValidateCollectContext,
    GenericValidationResult,
    ProcessValidationResultPromise,
} from 'components/common/form/data-validate-collect/useDataValidateCollect';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box } from '@mui/material';

export default function DataFetchCommandValidation({
    children,
    controlProps,
    onResult,
    matchedPatterns,
    lastValidationData = {},
    setLastValidationData,
    onChangeValidationLabel,
    setValidationAction,
}: {
    children: any;
    controlProps: FormControlDataFetchCommandProps;
    matchedPatterns: Array<string>;
    onResult: <T extends IValidationResponse>(
        isSuccess: boolean,
        message: string,
        data: T
    ) => Promise<ProcessValidationResultPromise>;
    lastValidationData: AssocArray<string>;
    setLastValidationData: (data: AssocArray<string>) => void;
    onChangeValidationLabel: (validationLabelElement: ReactJSXElement | null) => void;
    setValidationAction?: (action: any) => void;
}) {
    const urlParams = useParams();
    const url = processSettingsUrl(controlProps.validationUrl, Object.keys(urlParams), urlParams);
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    let [validationStatus, setValidationStatus] = useState<[boolean, number]>([false, 0]);

    const hist = controlProps.form.hookFormWatch('save_historical_instances_ind');
    const mtSource = controlProps.form.hookFormWatch('measurement_time_source');
    const mtColumn = controlProps.form.hookFormWatch('measurement_time_ds_column_id');
    const dataValidateCollectContext = useContext(DataValidateCollectContext);
    const fullMatchedPatterns = useMemo(() => {
        const list = matchedPatterns.slice();
        if (controlProps.type == 'dataset') {
            if (
                hist == 'Y' &&
                mtSource == 'ds_column' &&
                mtColumn != null &&
                String(mtColumn) > '' &&
                !list.includes(':measurement_time')
            ) {
                list.push(':measurement_time');
            }
        }
        return list;
    }, [hist, mtSource, mtColumn, matchedPatterns]);

    const fullMatchedPatternsRef = useRef(fullMatchedPatterns);
    useEffect(() => {
        fullMatchedPatternsRef.current = fullMatchedPatterns;
    }, [fullMatchedPatterns]);

    const requiredFields = useRequiredFields(fullMatchedPatternsRef.current, lastValidationData);
    useEffect(() => {
        requiredFieldsRef.current = requiredFields;
    }, [requiredFields]);
    const requiredFieldsRef = useRef(requiredFields);
    const [fieldsForForm, setFieldsForForm] = useState(requiredFieldsRef.current);

    const onValidationSuccess = function <T extends IValidationResponseDataDependencySuccess>(data: T) {
        setValidationStatus([false, 0]);
        return onResult(true, getSuccessMessage(controlProps.type, data, t, 'validate'), data);
    };

    const onValidationError = function (data: any) {
        setValidationStatus([false, 0]);
        return onResult(false, String(data.message), data);
    };

    const cancelValidationRef = useRef<null | Function>(null);
    const validationRun = (substitutions: AssocArray<string>): Promise<GenericValidationResult> => {
        return new Promise((resolve, reject) => {
            setLastValidationData(substitutions);

            const { create, cancel } = pollingService({
                onSuccess: async (data: any, responsePoolingData) => {
                    try {
                        await onValidationSuccess(data);
                        resolve({ status: true, data: data });
                    } catch (e) {}
                },
                onError: async (data) => {
                    await onValidationError(data);
                    resolve({ status: false, data: data });
                },
                onPplIdChange: (id) => setValidationStatus([true, id]),
                requestData: prepareRequestData(substitutions, controlProps, url),
            });
            create();
            cancelValidationRef.current = cancel;
            setValidationStatus([true, 0]);
        });
    };

    const [isValidationCalled, setIsValidationCalled] = useState<boolean>(false);

    // Reference to resolve for Promise after user fill substitution and finish validation
    const substitutionResolve = useRef(null);
    const handleValidationClick = (ignoreLastValidationData: boolean): Promise<GenericValidationResult> => {
        if (ignoreLastValidationData) {
            setFieldsForForm(fullMatchedPatternsRef.current);
        } else {
            setFieldsForForm(requiredFieldsRef.current);
        }

        if (Object.keys(lastValidationData).length && !ignoreLastValidationData) {
            return handleStartValidation(lastValidationData);
        }

        setIsValidationCalled(true);
        return new Promise((resolve, reject) => {
            //@ts-ignore
            substitutionResolve.current = resolve;
        });
    };

    useEffect(() => {
        // Register function call in formContext
        if (dataValidateCollectContext) {
            if (!dataValidateCollectContext.legacyHandleValidate.current) {
                //@ts-ignore
                dataValidateCollectContext.legacyHandleValidate.current = handleValidationClick;
            }
        }
        if (setValidationAction) setValidationAction(handleValidationClick);

        return () => {
            // Register function call in formContext
            if (dataValidateCollectContext) {
                //@ts-ignore
                dataValidateCollectContext.legacyHandleValidate.current = undefined;
            }
            if (setValidationAction) setValidationAction(null);
        };
    }, []);

    const handleStartValidation = (args: any) => {
        setIsValidationCalled(false);
        return validationRun({
            ...args,
            ...getPostArgs(controlProps.type, controlProps.dataSource, controlProps.form),
        });
    };

    const handleCancelValidation = (pplId: number) => {
        if (cancelValidationRef.current) {
            cancelValidationRef.current(pplId);
            setValidationStatus([false, 0]);
        }
    };

    const [label, setLabel] = useState('');
    useEffect(() => {
        const segmentId = Number(
            lastValidationData.segment_id ?? controlProps.form.hookFormGetValues('segment_id') ?? 0
        );
        editorAPI.getSegmentValuesList(segmentId).then((segmentValuesList) => {
            if (Object.keys(lastValidationData).length > 0 && fullMatchedPatternsRef.current.length > 0) {
                if (segmentId && lastValidationData.segment_value_id) {
                    const segment = segmentValuesList.find((s) => s.value == lastValidationData.segment_value_id);
                    if (segment) {
                        setLabel(segment.label);
                    }
                } else {
                    if (typeof Object.values(lastValidationData)[0] == 'string') {
                        setLabel(Object.values(lastValidationData)[0]);
                    }
                }
            }
        });
    }, [lastValidationData, fullMatchedPatternsRef.current]);

    useEffect(() => {
        onChangeValidationLabel(
            label.length > 0 ? (
                <Box>
                    {t('validate_for')}: {label}{' '}
                    <Box component="span" className={'invert-link'} onClick={() => handleValidationClick(true)}>
                        {t('change_value')}
                    </Box>
                </Box>
            ) : null
        );
    }, [label]);
    return (
        <>
            <BodyLoadingPlaceholder dataTest={'data-fetch-command-loading-placeholder'} open={validationStatus[0]}>
                <span onClick={() => handleCancelValidation(validationStatus[1])}>Cancel</span>
            </BodyLoadingPlaceholder>
            {isValidationCalled && (
                <DataFetchCommandSubstitution
                    mode={'validate'}
                    matchedPatterns={fullMatchedPatternsRef.current}
                    requiredFields={fieldsForForm}
                    defaults={lastValidationData}
                    start={(...args) => {
                        const result = handleStartValidation(...args);
                        if (substitutionResolve.current) {
                            return result.then((data) => {
                                setTimeout(() => {
                                    //@ts-ignore
                                    substitutionResolve.current(data);
                                }, 100);
                            });
                        }
                        return result;
                    }}
                    cancel={() => setIsValidationCalled(false)}
                    type={controlProps.type}
                    editorForm={controlProps.form}
                    dataSource={controlProps.dataSource}
                />
            )}
            {children !== false ? <span onClick={() => handleValidationClick(false)}>{children}</span> : null}
        </>
    );
}
