import { instance } from './api';
import { APIResponse } from 'tools/types';
import { FormComponentValue } from 'components/common/form/layout/control';

interface SegmentValue {
    segment_value_id: number;
    value_display_name: string;
}

export const editorAPI = {
    async getEditorDatasetfilter(id: string): Promise<any> {
        const response = await instance.get(`/editor/datasetfilter/index/ds/${id}`);
        return response.data;
    },
    async uploadEditorImagePreview(url: string, file: File): Promise<boolean> {
        const formData = new FormData();
        formData.append('image_file', file);

        const response = await instance.post(url, formData);
        return response.data.status === 'OK';
    },
    async restoreDefaultImage(elementId: string): Promise<boolean> {
        const response = await instance.post(`/data/editor/element/${elementId}/delete-image`);
        return response.data.status === 'OK';
    },
    async getSegmentValuesList(segmentId: number): Promise<Array<FormComponentValue>> {
        const response = await instance.get<APIResponse<Array<SegmentValue>>>(
            `/data/editor/segment/${segmentId}/get-values-list`
        );
        if (response.data.status == 'OK') {
            return response.data.data.map((s) => ({ label: s.value_display_name, value: String(s.segment_value_id) }));
        }
        return [];
    },
    async changeSegmentVisibility(url: string, formData: FormData): Promise<APIResponse<any>> {
        const response = await instance.post(url, formData);

        return response.data;
    },
    async getEmbedCodesViewModes(elementId: number): Promise<FormComponentValue[]> {
        const response = await instance.get(`/data/dashboard/element/${elementId}/embeded-codes-view-modes`);

        return response.data.data;
    },
};
