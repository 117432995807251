import { instance } from 'api/api';
import { ReportContentNS } from 'components/report-content';
import ContentSettings = ReportContentNS.ContentSettings;
import { APIResponse } from 'tools/types';
import { FormComponentValue } from 'components/common/form/layout/control';
import { ISaveReportContent } from 'form-templates/ReportForm';
import ManageFiltersInstance = ReportContentNS.ManageFiltersInstance;
import {
    DatasourceColumnsDataResponse,
    ReportFilterSegmentValuesData,
} from 'components/report-content/hooks/useReportContentSettings';
export const reportAPI = {
    async getContentEditorData(elementId: number): Promise<ReportContentNS.EditorData> {
        const response = await instance.get<APIResponse<ReportContentNS.EditorData>>(
            `data/editor/report/${elementId}/get-content-editor-data`
        );
        return response.data.data;
    },

    async getComponentData<T extends ContentSettings>(
        settings: ReportContentNS.ComponentSettings,
        contentSettings: T,
        filters: string | null
    ): Promise<any> {
        const settingsToPost = { ...contentSettings, instances: undefined };

        const response = await instance.post<APIResponse<any>>(
            `data/report/component-data/${contentSettings.elementId}/${contentSettings.segmentValueId}`,
            {
                properties: JSON.stringify(settingsToPost),
                settings: JSON.stringify(settings),
                filters: filters,
            }
        );
        return response.data.data;
    },

    async getInstances(
        elementId: number,
        segmentValueId: number
    ): Promise<{ instances: Array<ManageFiltersInstance>; hasInstances: boolean }> {
        if (!elementId) {
            return {
                instances: [],
                hasInstances: false,
            };
        }

        const response = await instance.get<APIResponse<any>>(`data/report/renderer-instances/${elementId}`, {
            params: {
                segment: segmentValueId,
            },
        });
        return {
            instances: response.data.data?.instances ?? [],
            hasInstances: response.data.data?.hasInstances ?? false,
        };
    },

    async getSegmentValuesList(
        elementId: number,
        segmentValueId: number,
        measurement_time?: string
    ): Promise<ReportFilterSegmentValuesData> {
        let config = {};
        if (measurement_time) {
            config = {
                params: {
                    measurement_time: measurement_time,
                },
            };
        }
        const response = await instance.get<APIResponse<ReportFilterSegmentValuesData>>(
            `data/report/renderer-segments/${elementId}`,
            config
        );
        return response.data.data;
    },
    async getColumnsSource(dataset: string, filter: string): Promise<boolean> {
        const response = await instance.get<APIResponse<DatasourceColumnsDataResponse>>(
            `data/dataset/${dataset}/get-columns-for-source/filter/${filter}`
        );
        return response.data.data.has_access_map;
    },
    async getReportVisualizationList(elementId: number): Promise<Array<FormComponentValue>> {
        const response = await instance.get<APIResponse<Array<FormComponentValue>>>(
            `data/editor/report/${elementId}/get-external-visualizations-list`
        );
        return response.data.data;
    },

    async saveReportContent(elementId: number, data: ISaveReportContent): Promise<any> {
        const response = await instance.post<APIResponse<Array<any>>>(
            `data/editor/report/save-renderer-blocks/element/${elementId}`,
            data
        );
        return response.data;
    },
};
