import { FormPanelButtonProps } from 'components/common/form';
import useBundleTranslation from 'i18n';
import { Button } from '@mui/material';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import { useNavigate } from 'react-router-dom';
import { instance } from 'api/api';

export default function FormPanelButtonUpdateLiveChart(props: FormPanelButtonProps<any>) {
    const { t } = useBundleTranslation(props.button.translationNS ?? 'components/common/form/form');
    const label = typeof props?.button?.label == 'undefined' ? 'update_live_chart' : props.button.label;
    const navigate = useNavigate();

    const handleClick = () => {
        //@ts-ignore
        instance.get(props.button.updateUrl).then((response) => {
            if (response.data.status == 'OK') {
                //@ts-ignore
                navigate(props.button.url);
                return;
            }
            alert(response.data.message);
        });
    };

    return (
        <>
            <Button
                onClick={() => handleClick()}
                type="button"
                variant={'contained'}
                color="success"
                startIcon={<IconMi icon="thumbs-up" />}
            >
                {t(label)}
            </Button>
        </>
    );
}
