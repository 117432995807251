import { lazy } from 'react';
import { userRoutes } from 'routes/editor/user';
import { RouteItemType } from 'routes/index';
const MetadataPage = lazy(() => import('app/editor/plugin-source/MetadataPage'));
const UserAlerts = lazy(() => import('app/editor/user-alerts/UserAlerts'));
const FormPage = lazy(() => import('components/common/FormPage'));
const Page = lazy(() => import('components/common/Page'));
const OAuthConfirmPage = lazy(() => import('components/common/page/OAuthConfirmPage'));

export const editorRoutes: Array<RouteItemType> = [
    /*
        Save help links
        {
            path: ['/editor/chartdisplayformat'],
            index+editor
            props: { helpLink: 'https://help.metricinsights.com/m/10198/l/116406-add-a-new-display-mask' },
        },
         {
            path: ['/editor/chartlayout'],
            index+editor
            props: { helpLink: 'https://help.metricinsights.com/m/Deployment_and_Configuration/l/122366-create-update-chart-layouts' },
        },
        {
            path: ['/editor/chartinginterval'],
            index+editor
            props: { helpLink: 'https://help.metricinsights.com/m/Other_Topics/l/122369-update-measurement-interval-settings' },
        },
        {
            path: ['/editor/eventcalendarentry'],
            index
            props: { helpLink: 'https://help.metricinsights.com/m/10037/l/104482-define-an-event-calendar' },
        },
        {
            path: ['/editor/multimetric'],
            edit+draw
            props: { helpLink: 'https://help.metricinsights.com/m/Creating_Basic_Metrics_and_Reports/l/104401-create-a-simple-non-segmented-multi-metric' },
        },
        wizardreportstep1+wizardreportstep2+wizardreportstep3

        'chart'=>array(
    			'edit'=> 'http://help.metricinsights.com/m/Creating_Basic_Metrics_and_Reports/l/104408-develop-report-chart-based-on-the-result-set-without-a-pivot'
    		),
    		'pivot'=>array(
                'edit'=> 'http://help.metricinsights.com/m/Creating_Basic_Metrics_and_Reports/l/104409-create-a-pivot-table-in-a-report'
    		),

        'service'=>array(
            'draw'=> 'http://help.metricinsights.com/m/Connecting_to_Data_Sources/l/104468',
            'edit'=> 'http://help.metricinsights.com/m/Connecting_to_Data_Sources/l/104468',
            'index'=> array(
                'authenticated' => array(
                    'value' => 'external',
                    'url'   => 'http://help.metricinsights.com/m/Connecting_to_Data_Sources/l/104468-connecting-to-a-web-service'
                ),
                '/default/' => 'http://help.metricinsights.com/m/Connecting_to_Data_Sources/l/104448-how-to-link-to-other-external-content'
            )
        ),
    */
    {
        path: ['/editor/custom-field'],
        name: 'custom_field.name',
        component: Page,
        props: { settingsUrl: '/data/editor/custom-field/index', pageMenu: 'customFields' },
    },
    {
        path: ['/editor/custom-field/:customFieldId'],
        name: 'custom_field.editor.name',
        component: FormPage,
        props: {
            uid: 'customFieldForm',
            pk: 'customFieldId',
            settingsUrl: '/data/editor/custom-field/:customFieldId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'custom_field.name',
                        href: '/editor/custom-field',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/custom-field/section'],
        name: 'custom_field.section.name',
        component: Page,
        props: { settingsUrl: '/data/editor/custom-field/section/index', pageMenu: 'customFields' },
    },
    {
        path: ['/editor/custom-field/section/:customFieldSectionId'],
        name: 'custom_field.section.editor.name',
        component: FormPage,
        props: {
            uid: 'customFieldSectionForm',
            pk: 'customFieldSectionId',
            settingsUrl: '/data/editor/custom-field/section/:customFieldSectionId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'custom_field.section.name',
                        href: '/editor/custom-field/section',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/custom-field/autosync'],
        name: 'custom_field.sync.name',
        component: Page,
        props: { settingsUrl: '/data/editor/custom-field/autosync/index', pageMenu: 'customFields' },
    },
    {
        path: ['/editor/custom-field/autosync/:id'],
        name: 'custom_field.sync.editor.name',
        component: FormPage,
        props: {
            uid: 'customFieldSyncForm',
            pk: 'id',
            settingsUrl: '/data/editor/custom-field/autosync/:id/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'custom_field.sync.name',
                        href: '/editor/custom-field/autosync',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/date-format'],
        name: 'date_format.name',
        component: Page,
        props: { settingsUrl: '/data/editor/date-format/index' },
    },
    {
        path: ['/editor/dependency'],
        name: 'dependency.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/dependency/index',
            pageMenu: 'collectionStorage',
            helpLink:
                'https://help.metricinsights.com/m/Automating_the_Collection_of_Data/l/104474-setup-data-dependencies-for-a-data-collection-trigger',
        },
    },
    {
        path: ['/editor/dependency/:dependencyId'],
        name: 'dependency.editor.name',
        component: FormPage,
        props: {
            uid: 'dependency',
            pk: 'dependencyId',
            settingsUrl: '/data/editor/dependency/:dependencyId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'dependency.name',
                        href: '/editor/dependency',
                    },
                ],
            },
            helpLink:
                'https://help.metricinsights.com/m/Automating_the_Collection_of_Data/l/104474-setup-data-dependencies-for-a-data-collection-trigger',
        },
    },
    {
        path: ['/editor/glossary'],
        name: 'glossary.name',
        component: Page,
        props: { settingsUrl: '/data/editor/glossary/index', pageMenu: 'glossary' },
    },
    {
        path: ['/editor/glossary/:glossaryId'],
        name: 'glossary.editor.name',
        component: FormPage,
        props: {
            uid: 'glossaryForm',
            pk: 'glossaryId',
            settingsUrl: '/data/editor/glossary/:glossaryId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'glossary.name',
                        href: '/editor/glossary',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/glossary/section'],
        name: 'glossary.section.name',
        component: Page,
        props: { settingsUrl: '/data/editor/glossary/section/index', pageMenu: 'glossary' },
    },
    {
        path: ['/editor/glossary/section/:topicTypeId'],
        name: 'glossary.section.editor.name',
        component: FormPage,
        props: {
            uid: 'topicTypeForm',
            pk: 'topicTypeId',
            settingsUrl: '/data/editor/glossary/section/:topicTypeId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'glossary.name',
                        href: '/editor/glossary',
                    },
                    {
                        label: 'glossary.section.name',
                        href: '/editor/glossary/section',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/glossary/import'],
        name: 'glossary.import.name',
        component: Page,
        props: { settingsUrl: '/data/editor/glossary/import/index', pageMenu: 'glossary' },
    },
    {
        path: ['/editor/glossary/import/:glossaryImportId'],
        name: 'glossary.import.editor.name',
        component: FormPage,
        props: {
            uid: 'glossaryImport',
            pk: 'glossaryImportId',
            settingsUrl: '/data/editor/glossary/import/:glossaryImportId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'glossary.import.name',
                        href: '/editor/glossary/import',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/glossary/sync'],
        name: 'glossary.sync.name',
        component: Page,
        props: { settingsUrl: '/data/editor/glossary/sync/index', pageMenu: 'glossary' },
    },
    {
        path: ['/editor/glossary/sync/:topicSyncId'],
        name: 'glossary.sync.editor.name',
        component: FormPage,
        props: {
            uid: 'topicSyncForm',
            pk: 'topicSyncId',
            settingsUrl: '/data/editor/glossary/sync/:topicSyncId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'glossary.sync.name',
                        href: '/editor/glossary/sync',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/topic'],
        name: 'topic.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/topic/index',
            helpLink: 'https://help.metricinsights.com/m/Making_Content_Discoverable/l/116421-create-a-topic',
        },
    },
    {
        path: ['/editor/topic/:topicId'],
        name: 'topic.editor.name',
        component: FormPage,
        props: {
            uid: 'topic',
            pk: 'topicId',
            settingsUrl: '/data/editor/topic/:topicId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'topic.name',
                        href: '/editor/topic',
                    },
                ],
            },
            helpLink: 'https://help.metricinsights.com/m/Making_Content_Discoverable/l/116421-create-a-topic',
        },
    },
    {
        path: ['/editor/content-workflow'],
        name: 'content_workflow.name',
        component: Page,
        props: { settingsUrl: '/data/editor/content-workflow/index' },
    },
    {
        path: ['/editor/content-workflow/:contentWorkflowId'],
        name: 'content_workflow.editor.name',
        component: FormPage,
        props: {
            uid: 'contentWorkflowForm',
            pk: 'contentWorkflowId',
            settingsUrl: '/data/editor/content-workflow/:contentWorkflowId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'content_workflow.name',
                        href: '/editor/content-workflow',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/document'],
        name: 'document.name',
        component: Page,
        props: { settingsUrl: '/data/editor/document/index' },
    },
    {
        path: ['/editor/document/:documentId'],
        name: 'document.editor.name',
        component: FormPage,
        props: {
            uid: 'document',
            pk: 'documentId',
            settingsUrl: '/data/editor/document/:documentId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'document.name',
                        href: '/editor/document',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/announcement'],
        name: 'announcement.name',
        component: Page,
        props: { settingsUrl: '/data/editor/announcement/index' },
    },
    {
        path: ['/editor/announcement/:announcementId'],
        name: 'announcement.editor.name',
        component: FormPage,
        props: {
            uid: 'announcement',
            pk: 'announcementId',
            settingsUrl: '/data/editor/announcement/:announcementId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'announcement.name',
                        href: '/editor/announcement',
                    },
                ],
                instanceDataKey: 'subject',
            },
        },
    },
    {
        path: ['/editor/remote-data-processor'],
        name: 'remote_collector.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/remote-data-processor/index',
            pageMenu: 'collectionStorage',
            helpLink: 'https://help.metricinsights.com/m/10035/l/107313-configure-a-remote-data-collector',
        },
    },
    {
        path: ['/editor/remote-data-processor/:remoteCollectorId'],
        name: 'remote_collector.editor.name',
        component: FormPage,
        props: {
            uid: 'remoteCollectorForm',
            pk: 'remoteCollectorId',
            settingsUrl: '/data/editor/remote-data-processor/:remoteCollectorId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'remote_collector.name',
                        href: '/editor/remote-data-processor',
                    },
                ],
            },
            helpLink: 'https://help.metricinsights.com/m/10035/l/107313-configure-a-remote-data-collector',
        },
    },
    {
        path: ['/editor/document-type'],
        name: 'document_type.name',
        component: Page,
        props: { settingsUrl: '/data/editor/document-type/index' },
    },
    {
        path: ['/editor/document-type/:documentTypeId'],
        name: 'document_type.editor.name',
        component: FormPage,
        props: {
            uid: 'documentTypeForm',
            pk: 'documentTypeId',
            settingsUrl: '/data/editor/document-type/:documentTypeId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'document_type.name',
                        href: '/editor/document-type',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/email-template'],
        name: 'digest_template.name',
        component: Page,
        props: { settingsUrl: '/data/editor/email-template/index', pageMenu: 'ftp' },
    },
    {
        path: ['/editor/email-template/:digestTemplateId'],
        name: 'digest_template.editor.name',
        component: FormPage,
        props: {
            uid: 'digestTemplateForm',
            pk: 'digestTemplateId',
            settingsUrl: '/data/editor/email-template/:digestTemplateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'digest_template.digest_name',
                        href: '/editor/email-template',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/proxy'],
        name: 'proxy.name',
        component: Page,
        props: { settingsUrl: '/data/editor/proxy/index', pageMenu: 'collectionStorage' },
    },
    {
        path: ['/editor/proxy/:proxyId'],
        name: 'proxy.editor.name',
        component: FormPage,
        props: {
            uid: 'proxyForm',
            pk: 'proxyId',
            settingsUrl: '/data/editor/proxy/:proxyId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'proxy.name',
                        href: '/editor/proxy',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/event-calendar'],
        name: 'event_calendar.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/event-calendar/index',
            helpLink: 'https://help.metricinsights.com/m/10037/l/104482-define-an-event-calendar',
        },
    },
    {
        path: ['/editor/event-calendar/:eventCalendarId'],
        name: 'event_calendar.editor.name',
        component: FormPage,
        props: {
            uid: 'eventCalendarForm',
            pk: 'eventCalendarId',
            settingsUrl: '/data/editor/event-calendar/:eventCalendarId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'event_calendar.name',
                        href: '/editor/event-calendar',
                    },
                ],
            },
            helpLink: 'https://help.metricinsights.com/m/10037/l/104482-define-an-event-calendar',
        },
    },
    {
        path: ['/editor/ftp'],
        name: 'ftp.name',
        component: Page,
        props: { settingsUrl: '/data/editor/ftp/index', pageMenu: 'ftp' },
    },
    {
        path: ['/editor/ftp/:ftpId'],
        name: 'ftp.editor.name',
        component: FormPage,
        props: {
            uid: 'ftpForm',
            pk: 'ftpId',
            settingsUrl: '/data/editor/ftp/:ftpId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'ftp.name',
                        href: '/editor/ftp',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/schedule'],
        name: 'notification_schedules.name',
        component: Page,
        props: { settingsUrl: '/data/editor/schedule/index', pageMenu: 'ftp' },
    },
    {
        path: ['/editor/schedule/:nsId'],
        name: 'notification_schedules.editor.name',
        component: FormPage,
        props: {
            uid: 'nsForm',
            pk: 'nsId',
            settingsUrl: '/data/editor/schedule/:nsId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'notification_schedules.name',
                        href: '/editor/schedule',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/schedule/:nsId/short'],
        name: 'notification_schedules.editor.name',
        component: FormPage,
        props: {
            uid: 'nsShortForm',
            pk: 'nsId',
            settingsUrl: '/data/editor/schedule/:nsId/short/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'notification_schedules.name',
                        href: '/editor/schedule',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/shared-drive'],
        name: 'shared_drive.name',
        component: Page,
        props: { settingsUrl: '/data/editor/shared-drive/index', pageMenu: 'ftp' },
    },
    {
        path: ['/editor/shared-drive/:sharedDriveId'],
        name: 'shared_drive.editor.name',
        component: FormPage,
        props: {
            uid: 'sharedDriveForm',
            pk: 'sharedDriveId',
            settingsUrl: '/data/editor/shared-drive/:sharedDriveId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'shared_drive.name',
                        href: '/editor/shared-drive',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/ms-sharepoint'],
        name: 'share_point.name',
        component: Page,
        props: { settingsUrl: '/data/editor/ms-sharepoint/index', pageMenu: 'ftp' },
    },
    {
        path: ['/editor/ms-sharepoint/:sharePointId'],
        name: 'share_point.editor.name',
        component: FormPage,
        props: {
            uid: 'sharePointForm',
            pk: 'sharePointId',
            settingsUrl: '/data/editor/ms-sharepoint/:sharePointId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'share_point.name',
                        href: '/editor/ms-sharepoint',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/ext-content-type'],
        name: 'content_type.name',
        component: Page,
        props: { settingsUrl: '/data/editor/ext-content-type/index' },
    },
    {
        path: ['/editor/ext-content-type/:contentTypeId'],
        name: 'content_type.editor.name',
        component: FormPage,
        props: {
            uid: 'contentTypeForm',
            pk: 'contentTypeId',
            settingsUrl: '/data/editor/ext-content-type/:contentTypeId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'content_type.name',
                        href: '/editor/ext-content-type',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/ext-report-type'],
        name: 'report_type.name',
        component: Page,
        props: { settingsUrl: '/data/editor/ext-report-type/index' },
    },
    {
        path: ['/editor/ext-report-type/:reportTypeId'],
        name: 'report_type.editor.name',
        component: FormPage,
        props: {
            uid: 'reportTypeForm',
            pk: 'reportTypeId',
            settingsUrl: '/data/editor/ext-report-type/:reportTypeId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'report_type.name',
                        href: '/editor/ext-report-type',
                    },
                ],
                instanceDataKey: 'reporting_tool_name',
            },
        },
    },
    {
        path: ['/editor/dataset'],
        name: 'dataset.name',
        component: Page,
        props: { settingsUrl: '/data/editor/dataset/index', pageMenu: 'dataset' },
    },
    {
        path: ['/editor/dataset/:datasetId'],
        name: 'dataset.editor.name',
        component: FormPage,
        props: {
            uid: 'datasetForm',
            pk: 'datasetId',
            settingsUrl: '/data/editor/dataset/:datasetId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'dataset.name',
                        href: '/editor/dataset',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/virtual-dataset'],
        name: 'ext_dataset.name',
        component: Page,
        props: { settingsUrl: '/data/editor/virtual-dataset/index', pageMenu: 'dataset' },
    },
    {
        path: ['/editor/virtual-dataset/:datasetId'],
        name: 'ext_dataset.editor.name',
        component: FormPage,
        props: {
            uid: 'extDatasetForm',
            pk: 'datasetId',
            settingsUrl: '/data/editor/virtual-dataset/:datasetId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'ext_dataset.name',
                        href: '/editor/virtual-dataset',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/dataset-access-map'],
        name: 'dataset_access_map.name',
        component: Page,
        props: { settingsUrl: '/data/editor/dataset-access-map/index', pageMenu: 'dataset' },
    },
    {
        path: ['/editor/dataset-access-map/:datasetId'],
        name: 'dataset_access_map.editor.name',
        component: FormPage,
        props: {
            uid: 'datasetAccessMapForm',
            pk: 'datasetId',
            settingsUrl: '/data/editor/dataset-access-map/:datasetId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'dataset_access_map.name',
                        href: '/editor/dataset-access-map',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/dataset-filter'],
        name: 'dataset_filter.name',
        component: Page,
        props: { settingsUrl: '/data/editor/dataset-filter/index', pageMenu: 'dataset' },
    },
    {
        path: ['/editor/category'],
        name: 'category.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/category/index',
            helpLink:
                'https://help.metricinsights.com/m/Jump_Start_Guide_for_Administrators/l/496701-create-a-category',
        },
    },
    {
        path: ['/editor/category/:categoryId'],
        name: 'category.editor.name',
        component: FormPage,
        props: {
            uid: 'categoryForm',
            pk: 'categoryId',
            settingsUrl: '/data/editor/category/:categoryId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'category.name',
                        href: '/editor/category',
                    },
                ],
                instanceDataKey: 'category',
            },
            helpLink:
                'https://help.metricinsights.com/m/Jump_Start_Guide_for_Administrators/l/496701-create-a-category',
        },
    },
    {
        path: ['/editor/command'],
        name: 'command.name',
        component: Page,
        props: { settingsUrl: '/data/editor/command/index' },
    },
    {
        path: ['/editor/command/:commandId'],
        name: 'command.editor.name',
        component: FormPage,
        props: {
            uid: 'commandForm',
            pk: 'commandId',
            settingsUrl: '/data/editor/command/:commandId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'command.name',
                        href: '/editor/command',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/measurement-interval'],
        name: 'measurement_interval.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/measurement-interval/index',
            helpLink:
                'https://help.metricinsights.com/m/Deployment_and_Configuration/l/122369-update-measurement-interval-settings',
        },
    },
    {
        path: ['/editor/measurement-interval/:measurementIntervalId'],
        name: 'measurement_interval.editor.name',
        component: FormPage,
        props: {
            uid: 'measurementIntervalForm',
            pk: 'measurementIntervalId',
            settingsUrl: '/data/editor/measurement-interval/:measurementIntervalId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'measurement_interval.name',
                        href: '/editor/measurement-interval',
                    },
                ],
            },
            helpLink:
                'https://help.metricinsights.com/m/Deployment_and_Configuration/l/122369-update-measurement-interval-settings',
        },
    },
    {
        path: ['/editor'],
        name: 'element.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/element/index',
            pageTitle: { value: 'element.title' },
            helpLink:
                'https://help.metricinsights.com/m/10034/l/104378-should-i-use-a-metric-multi-metric-or-report-to-visualize-my-data',
        },
    },
    {
        path: ['/editor/element/extreport/:elementId'],
        name: 'extreport.editor.name',
        component: FormPage,
        props: {
            uid: 'extreportForm',
            pk: 'elementId',
            settingsUrl: '/data/editor/element/extreport/:elementId/form',
            passSearchParams: true,
            breadcrumbs: {
                list: [
                    {
                        label: 'extreport.editor.breadcrumb',
                        href: '/editor',
                    },
                ],
            },
            helpLink:
                'https://help.metricinsights.com/m/Connecting_to_Data_Sources/l/104449-how-to-link-to-a-parameterized-external-report',
        },
    },
    {
        path: ['/editor/element/ext-content/:elementId'],
        name: 'extcontent.editor.name',
        component: FormPage,
        props: {
            uid: 'extcontentForm',
            pk: 'elementId',
            settingsUrl: '/data/editor/element/ext-content/:elementId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'extcontent.editor.breadcrumb',
                        href: '/editor',
                    },
                ],
            },
            helpLink:
                'https://help.metricinsights.com/m/Connecting_to_Data_Sources/l/104448-how-to-link-to-external-report-content',
        },
    },
    {
        path: ['/editor/element/metric/:elementId'],
        name: 'metric.editor.name',
        component: FormPage,
        props: {
            uid: 'metricForm',
            pk: 'elementId',
            settingsUrl: '/data/editor/element/metric/:elementId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'metric.name',
                        href: '/editor',
                    },
                ],
            },
            helpLink: 'https://help.metricinsights.com/m/Creating_Basic_Metrics_and_Reports/c/44573',
        },
    },
    {
        path: ['/editor/element/metric/:elementId/manual'],
        name: 'metric.editor.manual',
        component: FormPage,
        props: {
            uid: 'metricForm',
            pk: 'elementId',
            settingsUrl: '/data/editor/element/metric/:elementId/manual/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'metric.name',
                        href: '/editor',
                    },
                ],
            },
            helpLink:
                'https://help.metricinsights.com/m/10034/l/104380-what-options-are-available-for-manually-loading-metric-and-report-data',
        },
    },
    {
        path: ['/editor/element/report/:elementId'],
        name: 'report.editor.name',
        component: FormPage,
        props: {
            uid: 'reportForm',
            pk: 'elementId',
            settingsUrl: '/data/editor/element/report/:elementId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'report.name',
                        href: '/editor',
                    },
                ],
            },
            helpLink:
                'https://help.metricinsights.com/m/Creating_Basic_Metrics_and_Reports/l/104406-create-a-simple-non-segmented-report',
        },
    },
    {
        path: ['/editor/ext-report-mass/plugin/:plugin'],
        name: 'extreport.editor.name',
        component: FormPage,
        props: {
            uid: 'extReportMassForm',
            pk: 'plugin',
            settingsUrl: '/data/editor/ext-report-mass/:plugin/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'ext_report_mass.name',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/ext-content-mass/plugin/:plugin'],
        name: 'extcontent.editor.name',
        component: FormPage,
        props: {
            uid: 'extContentMassForm',
            pk: 'plugin',
            settingsUrl: '/data/editor/ext-content-mass/:plugin/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'ext_content_mass.name',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/plugin-url-template'],
        name: 'plugin_url_template.name',
        component: Page,
        props: { settingsUrl: '/data/editor/plugin-url-template/index' },
    },
    {
        path: ['/editor/trigger'],
        name: 'Triggers',
        component: Page,
        props: {
            settingsUrl: '/data/editor/trigger/index',
            pageMenu: 'collectionStorage',
            helpLink:
                'https://help.metricinsights.com/m/Automating_the_Collection_of_Data/l/104471-define-a-scheduled-data-collection-triggers',
        },
    },
    {
        path: ['/editor/trigger/:triggerId'],
        name: 'trigger Editor',
        component: FormPage,
        props: {
            uid: 'triggerForm',
            pk: 'triggerId',
            settingsUrl: '/data/editor/trigger/:triggerId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'trigger.name',
                        href: '/editor/trigger',
                    },
                ],
            },
            helpLink:
                'https://help.metricinsights.com/m/Automating_the_Collection_of_Data/l/104471-define-a-scheduled-data-collection-triggers',
        },
    },
    {
        path: ['/editor/segment'],
        name: 'segment.name',
        component: Page,
        props: { settingsUrl: '/data/editor/segment/index', helpLink: 'https://help.metricinsights.com/m/10197' },
    },
    {
        path: ['/editor/segment/:segmentId'],
        name: 'segment.editor.name',
        component: FormPage,
        props: {
            uid: 'segmentForm',
            pk: 'segmentId',
            settingsUrl: '/data/editor/segment/:segmentId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'segment.name',
                        href: '/editor/segment',
                    },
                ],
            },
            helpLink: 'https://help.metricinsights.com/m/10197',
        },
    },
    {
        path: ['/editor/data-source'],
        name: 'data_source.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/data-source/index',
            pageMenu: 'collectionStorage',
            helpLink: 'https://help.metricinsights.com/m/10035',
        },
    },
    {
        path: ['/editor/data-source/:sourceId'],
        name: 'source.editor.name',
        component: FormPage,
        props: {
            uid: 'sourceForm',
            pk: 'sourceId',
            settingsUrl: '/data/editor/data-source/:sourceId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'source.name',
                        href: '/editor/data-source',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/plugin-source/:profileId'],
        name: 'plugin_connection_profile.editor.name',
        component: FormPage,
        props: {
            uid: 'pluginConnectionProfileForm',
            pk: 'profileId',
            settingsUrl: '/data/editor/plugin-source/:profileId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'plugin_connection_profile.name',
                        href: '/editor/data-source',
                    },
                ],
            },
            helpLink: 'https://help.metricinsights.com/m/10035',
        },
    },
    {
        path: ['/editor/plugin-source/metadata/reference?/source-dataset?/external-source?/:entityId'],
        name: 'plugin_source_metadata.name',
        component: MetadataPage,
    },
    /*{
        path: ['/editor/recent-alerts'],
        name: 'alerts.name',
        component: Page,
        props: { settingsUrl: '/data/editor/segment/index', pageMenu: 'alerts' },
    },*/
    {
        path: ['/editor/kpi-alert-rule'],
        name: 'alerts.kpi',
        component: Page,
        props: { settingsUrl: '/data/editor/kpi-alert-rule/index', pageMenu: 'alerts' },
    },
    {
        path: ['/editor/user-alerts/:userId?'],
        name: 'alerts.user',
        component: UserAlerts,
        props: { pageMenu: 'alerts' },
    },
    {
        path: ['/editor/kpi-alert-rule/:alertRuleId'],
        name: 'alerts.editor.kpi',
        component: FormPage,
        props: {
            uid: 'kpiAlertRuleForm',
            pk: 'alertRuleId',
            settingsUrl: '/data/editor/kpi-alert-rule/:alertRuleId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'alerts.kpi',
                        href: '/editor/kpi-alert-rule',
                    },
                ],
            },
        },
    },

    {
        path: ['/editor/ppt'],
        name: 'ppt.name',
        component: Page,
        props: { settingsUrl: '/data/editor/ppt/index' },
    },
    {
        path: ['/editor/ppt/:pptId'],
        name: 'ppt.editor.name',
        component: FormPage,
        props: {
            uid: 'pptForm',
            pk: 'pptId',
            settingsUrl: '/data/editor/ppt/:pptId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'ppt.name',
                        href: '/editor/ppt',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/sintemplate'],
        name: 'sintemplate.name',
        component: Page,
        props: { settingsUrl: '/data/editor/sintemplate/index' },
    },
    {
        path: ['/editor/sintemplate/:templateId'],
        name: 'sintemplate.editor.name',
        component: FormPage,
        props: {
            uid: 'sintemplateForm',
            pk: 'templateId',
            settingsUrl: '/data/editor/sintemplate/:templateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'sintemplate.name',
                        href: '/editor/sintemplate',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/template'],
        name: 'template.name',
        component: Page,
        props: { settingsUrl: '/data/editor/template/index', pageMenu: 'template' },
    },
    {
        path: ['/editor/template/:templateId'],
        name: 'template.editor.name',
        component: FormPage,
        props: {
            uid: 'templateForm',
            pk: 'templateId',
            settingsUrl: '/data/editor/template/:templateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'template.common.name',
                        href: '/editor/template',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/template/metric'],
        name: 'template.name',
        component: Page,
        props: { settingsUrl: '/data/editor/template/metric/index', pageMenu: 'template' },
    },
    {
        path: ['/editor/template/metric/:templateId'],
        name: 'template.editor.metric.name',
        component: FormPage,
        props: {
            uid: 'templateMetricForm',
            pk: 'templateId',
            settingsUrl: '/data/editor/template/metric/:templateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'template.metric.name',
                        href: '/editor/template',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/template/external-report'],
        name: 'template.external_report.name',
        component: Page,
        props: { settingsUrl: '/data/editor/template/external-report/index', pageMenu: 'template' },
    },
    {
        path: ['/editor/template/external-report/:templateId'],
        name: 'template.editor.external_report.name',
        component: FormPage,
        props: {
            uid: 'templateExternalReportForm',
            pk: 'templateId',
            settingsUrl: '/data/editor/template/external-report/:templateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'template.external_report.name',
                        href: '/editor/template/external-report',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/template/external-content'],
        name: 'template.other_external_content.name',
        component: Page,
        props: { settingsUrl: '/data/editor/template/external-content/index', pageMenu: 'template' },
    },
    {
        path: ['/editor/template/external-content/:templateId'],
        name: 'template.editor.external_content.name',
        component: FormPage,
        props: {
            uid: 'templateExternalContentForm',
            pk: 'templateId',
            settingsUrl: '/data/editor/template/external-content/:templateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'template.external_content.name',
                        href: '/editor/template/external-content',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/template/segment'],
        name: 'template.segment.name',
        component: Page,
        props: { settingsUrl: '/data/editor/template/segment/index', pageMenu: 'template' },
    },
    {
        path: ['/editor/template/segment/:templateId'],
        name: 'template.editor.segment.name',
        component: FormPage,
        props: {
            uid: 'templateSegmentForm',
            pk: 'templateId',
            settingsUrl: '/data/editor/template/segment/:templateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'template.segment.name',
                        href: '/editor/template/segment',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/template/dataset'],
        name: 'template.dataset.name',
        component: Page,
        props: { settingsUrl: '/data/editor/template/dataset/index', pageMenu: 'template' },
    },

    {
        path: ['/editor/template/dataset/:templateId'],
        name: 'template.editor.dataset.name',
        component: FormPage,
        props: {
            uid: 'templateDatasetForm',
            pk: 'templateId',
            settingsUrl: '/data/editor/template/dataset/:templateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'template.dataset.name',
                        href: '/editor/template/dataset',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/user/defaults'],
        name: 'user.defaults.name',
        component: Page,
        props: { settingsUrl: '/data/editor/user/defaults/index', pageMenu: 'users' },
    },
    {
        path: ['/editor/user/defaults/user-template/:userPreferenceTemplateId'],
        name: 'user_preference_template.editor.name',
        component: FormPage,
        props: {
            uid: 'userPreferenceTemplateForm',
            pk: 'userPreferenceTemplateId',
            settingsUrl: '/data/editor/user/defaults/user-template/:userPreferenceTemplateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'user.defaults.name',
                        href: '/editor/user/defaults',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/user/defaults/alert-template/:userPreferenceTemplateId'],
        name: 'alert_preference_template.editor.name',
        component: FormPage,
        props: {
            uid: 'alertPreferenceTemplates',
            pk: 'userPreferenceTemplateId',
            settingsUrl: '/data/editor/user/defaults/alert-template/:userPreferenceTemplateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'user.defaults.name',
                        href: '/editor/user/defaults',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/user/defaults/email-template/:userPreferenceTemplateId'],
        name: 'notification_preference_template.editor.name',
        component: FormPage,
        props: {
            uid: 'emailNotificationsTemplates',
            pk: 'userPreferenceTemplateId',
            settingsUrl: '/data/editor/user/defaults/email-template/:userPreferenceTemplateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'user.defaults.name',
                        href: '/editor/user/defaults',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/identity-profile'],
        name: 'identity_profile.name',
        component: Page,
        props: { settingsUrl: '/data/editor/identity-profile/index' },
    },
    {
        path: ['/editor/identity-profile/:identityProfileId'],
        name: 'identity_profile.editor.name',
        component: FormPage,
        props: {
            uid: 'identityProfileForm',
            pk: 'identityProfileId',
            settingsUrl: '/data/editor/identity-profile/:identityProfileId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'identity_profile.name',
                        href: '/editor/identity-profile',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/hf-template/:emailTemplateId'],
        name: 'email_template.editor.name',
        component: FormPage,
        props: {
            uid: 'emailTemplateForm',
            pk: 'emailTemplateId',
            settingsUrl: '/data/editor/hf-template/:emailTemplateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'email_template.name',
                        href: '/editor/email-template',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/external-application'],
        name: 'external_application.name',
        component: Page,
        props: { settingsUrl: '/data/editor/external-application/index' },
    },
    {
        path: ['/editor/external-application/:externalApplicationId'],
        name: 'external_application.editor.name',
        component: FormPage,
        props: {
            uid: 'externalApplicationForm',
            pk: 'externalApplicationId',
            settingsUrl: '/data/editor/external-application/:externalApplicationId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'external_application.name',
                        href: '/editor/external-application',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/ms-teams'],
        name: 'ms_teams.editor.name',
        component: FormPage,
        props: {
            uid: 'msteams',
            settingsUrl: '/data/editor/ms-teams/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'ms_teams.name',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/page'],
        name: 'page.name',
        component: Page,
        props: { settingsUrl: '/data/editor/page/index', pageMenu: 'page' },
    },
    {
        path: ['/editor/page/:portalPageId'],
        name: 'page.editor.name',
        component: FormPage,
        props: {
            uid: 'pageForm',
            pk: 'portalPageId',
            settingsUrl: '/data/editor/page/:portalPageId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'page.name',
                        href: '/editor/page',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/page/template'],
        name: 'page.template.name',
        component: Page,
        props: { settingsUrl: '/data/editor/page/template/index', pageMenu: 'page' },
    },
    {
        path: ['/editor/page/template/:portalPageTemplateId'],
        name: 'page.template.editor.name',
        component: FormPage,
        props: {
            uid: 'portalPageTemplateForm',
            pk: 'portalPageTemplateId',
            settingsUrl: '/data/editor/page/template/:portalPageTemplateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'page.template.breadcrumb.name',
                        href: '/editor/page/template',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/page/layout'],
        name: 'page.layout.name',
        component: Page,
        props: { settingsUrl: '/data/editor/page/layout/index', pageMenu: 'page' },
    },
    {
        path: ['/editor/page/layout/:portalPageLayoutId'],
        name: 'page.layout.editor.name',
        component: FormPage,
        props: {
            uid: 'portalPageLayoutForm',
            pk: 'portalPageLayoutId',
            settingsUrl: '/data/editor/page/layout/:portalPageLayoutId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'page.layout.editor.name',
                        href: '/editor/page/layout',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/page/asset/:pageAssetId'],
        name: 'page.asset.editor.name',
        component: FormPage,
        props: {
            uid: 'pageAsset',
            pk: 'pageAssetId',
            settingsUrl: '/data/editor/page/asset/:pageAssetId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'page.name',
                        href: '/editor/page',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/page/entity/:portalPageEntityId'],
        name: 'page.entity.editor.name',
        component: FormPage,
        props: {
            uid: 'PageEntity',
            pk: 'portalPageEntityId',
            settingsUrl: '/data/editor/page/entity/:portalPageEntityId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'page.entity.name',
                    },
                ],
            },
        },
    },

    {
        path: ['/editor/page/global-asset'],
        name: 'page.global_tag.name',
        component: Page,
        props: { settingsUrl: '/data/editor/page/global-asset/index', pageMenu: 'page' },
    },
    {
        path: ['/editor/page/global-asset/:portalPageGlobalTagId'],
        name: 'page.global_tag.editor.name',
        component: FormPage,
        props: {
            uid: 'portalPageGlobalTagForm',
            pk: 'portalPageGlobalTagId',
            settingsUrl: '/data/editor/page/global-asset/:portalPageGlobalTagId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'page.global_tag.editor.name',
                        href: '/editor/page/global-asset',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/slack'],
        name: 'slack.editor.name',
        component: FormPage,
        props: {
            uid: 'slack',
            settingsUrl: '/data/editor/slack/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'slack.name',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/slack/channel'],
        name: 'slack.channel.name',
        component: Page,
        props: { settingsUrl: '/data/editor/slack/channel/index', pageMenu: 'ftp' },
    },
    {
        path: ['/editor/slack/channel/:channelId'],
        name: 'slack.channel.editor.name',
        component: FormPage,
        props: {
            uid: 'slackChannel',
            pk: 'channelId',
            settingsUrl: '/data/editor/slack/channel/:channelId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'slack.channel.name',
                        href: '/editor/slack/channel',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/service/:action'],
        name: 'service.name',
        component: OAuthConfirmPage,
        props: {},
    },
    {
        path: ['/editor/target'],
        name: 'target.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/target/index',
            helpLink: 'https://help.metricinsights.com/m/Adding_Context_to_Metrics/l/104489-targets-overview',
        },
    },

    {
        path: ['/editor/target/:targetId'],
        name: 'target.editor.name',
        component: FormPage,
        props: {
            uid: 'targetForm',
            pk: 'targetId',
            settingsUrl: '/data/editor/target/:targetId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'target.name',
                        href: '/editor/target',
                    },
                ],
            },
            helpLink: 'https://help.metricinsights.com/m/Adding_Context_to_Metrics/l/104489-targets-overview',
        },
    },
    {
        path: ['/editor/target-type'],
        name: 'target_type.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/target-type/index',
        },
    },

    {
        path: ['/editor/target-type/:targetTypeId'],
        name: 'target_type.editor.name',
        component: FormPage,
        props: {
            uid: 'targetTypeForm',
            pk: 'targetTypeId',
            settingsUrl: '/data/editor/target-type/:targetTypeId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'target_type.name',
                        href: '/editor/target-type',
                    },
                ],
            },
        },
    },

    {
        path: ['/editor/global-assets/file/'],
        name: 'javascript_chart_template.file.name',
        component: Page,
        props: { settingsUrl: '/data/editor/global-assets/file/index', pageMenu: 'page' },
    },
    {
        path: ['/editor/global-assets/file/:javascriptChartTemplateFileId'],
        name: 'javascript_chart_template.file.editor.name',
        component: FormPage,
        props: {
            uid: 'javascriptChartTemplateFile',
            pk: 'javascriptChartTemplateFileId',
            settingsUrl: '/data/editor/global-assets/file/:javascriptChartTemplateFileId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'javascript_chart_template.file.name',
                        href: '/editor/global-assets/file',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/global-assets/folder/'],
        name: 'javascript_chart_template.folder.name',
        component: Page,
        props: { settingsUrl: '/data/editor/global-assets/folder/index' },
    },
    {
        path: ['/editor/global-assets/folder/:javascriptChartTemplateFolderId'],
        name: 'javascript_chart_template.folder.editor.name',
        component: FormPage,
        props: {
            uid: 'javascriptChartTemplateFolder',
            pk: 'javascriptChartTemplateFolderId',
            settingsUrl: '/data/editor/global-assets/folder/:javascriptChartTemplateFolderId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'javascript_chart_template.folder.editor.name',
                        href: '/editor/global-assets/file',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/javascript-chart-template'],
        name: 'javascript_chart_template.name',
        component: Page,
        props: { settingsUrl: '/data/editor/javascript-chart-template/index', pageMenu: 'javascriptChartTemplate' },
    },
    {
        path: ['/editor/javascript-chart-template/:javascriptChartTemplateId'],
        name: 'javascript_chart_template.editor.name',
        component: FormPage,
        props: {
            uid: 'javascriptChartTemplateForm',
            pk: 'javascriptChartTemplateId',
            settingsUrl: '/data/editor/javascript-chart-template/:javascriptChartTemplateId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'javascript_chart_template.editor.name',
                        href: '/editor/javascript-chart-template',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/javascript-chart-engine'],
        name: 'javascript_chart_engine.name',
        component: Page,
        props: { settingsUrl: '/data/editor/javascript-chart-engine/index', pageMenu: 'javascriptChartTemplate' },
    },
    {
        path: ['/editor/javascript-chart-engine/:id'],
        name: 'javascript_chart_engine.editor.name',
        component: FormPage,
        props: {
            uid: 'javascriptChartEngineForm',
            pk: 'id',
            settingsUrl: '/data/editor/javascript-chart-engine/:id/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'javascript_chart_engine.editor.name',
                        href: '/editor/javascript-chart-engine',
                    },
                ],
            },
        },
    },
    {
        path: ['/editor/mask'],
        name: 'mask.name',
        component: Page,
        props: {
            settingsUrl: '/data/editor/mask/index',
            pageMenu: 'javascriptChartTemplate',
            helpLink: 'https://help.metricinsights.com/m/10198/l/116406-add-a-new-display-mask',
        },
    },
    {
        path: ['/editor/mask/:maskId'],
        name: 'mask.editor.name',
        component: FormPage,
        props: {
            uid: 'maskForm',
            pk: 'maskId',
            settingsUrl: '/data/editor/mask/:maskId/form',
            breadcrumbs: {
                list: [
                    {
                        label: 'mask.name',
                        href: '/editor/mask',
                    },
                ],
                instanceDataKey: 'display_example',
            },
            helpLink: 'https://help.metricinsights.com/m/10198/l/116406-add-a-new-display-mask',
        },
    },
    ...userRoutes,
];
