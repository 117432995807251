import { FormComponentValue } from 'components/common/form/layout/control';
import React, { useEffect } from 'react';
import { Box, Stack, IconButton } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { ColumnName, RowValues } from './index';
import { alpha } from '@mui/material/styles';
import IconMi from 'components/common/icon/IconMi';

export default function SelectMapRow({
    firstColumn,
    secondColumn,
    values,
    onSelectChange,
    onDataChange,
    onRemoveRow,
    handleGearClick,
}: {
    firstColumn: Array<FormComponentValue>;
    secondColumn: Array<FormComponentValue>;
    values: RowValues;
    onSelectChange: (value: any, column: ColumnName) => void;
    onDataChange: (data: any) => void;
    onRemoveRow: () => void;
    handleGearClick?: (data: any, setData: (data: any) => void) => void;
}) {
    const handleFirstSelectChange = function (value: any) {
        onSelectChange(value, 'first');
    };

    const handleSecondSelectChange = function (value: any) {
        onSelectChange(value, 'second');
    };

    useEffect(() => {
        const valueExists = firstColumn.findIndex((row) => row.value == values.firstColumn);
        if (valueExists == -1) {
            handleFirstSelectChange('');
        }
    }, [firstColumn]);

    useEffect(() => {
        const valueExists = secondColumn.findIndex((row) => row.value == values.secondColumn);
        if (valueExists == -1) {
            handleSecondSelectChange('');
        }
    }, [secondColumn]);

    return (
        <Stack
            width={1}
            direction="row"
            sx={{
                py: 1,
                borderBottom: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
            }}
        >
            <Stack direction="row" flexGrow={1}>
                <Box width={1}>
                    <ReactSelect data={firstColumn} value={values.firstColumn} update={handleFirstSelectChange} />
                </Box>
                <Stack direction="row" alignItems="center" flexShrink={0} sx={{ px: 1 }}>
                    =
                </Stack>
                <Box width={1}>
                    <ReactSelect data={secondColumn} value={values.secondColumn} update={handleSecondSelectChange} />
                </Box>
            </Stack>
            <Box flexShrink={0} sx={{ pl: 1 }}>
                {handleGearClick && (
                    <IconButton
                        onClick={() => handleGearClick(values.data, onDataChange)}
                        sx={{ color: 'text.primary', padding: 0.75, minHeight: (theme) => theme.size.defaultHeight }}
                    >
                        <IconMi icon="gear" />
                    </IconButton>
                )}
                <IconButton
                    onClick={onRemoveRow}
                    sx={{ color: 'text.primary', padding: 0.75, minHeight: (theme) => theme.size.defaultHeight }}
                >
                    <IconMi icon="times" />
                </IconButton>
            </Box>
        </Stack>
    );
}
