import IconMi from 'components/common/icon/IconMi';
import { Button } from '@mui/material';
import React, { createContext, useState } from 'react';
import FormComponent from 'components/common/form/Form';
import { AsyncPopupContextType, AsyncPopupContext } from 'tools/AsyncPopupContext';
import { metricAPI } from 'api/viewer/metric';
import { MetricChartCompareLineType, MetricChartOverlayType } from 'components/metric/chart';
import useBundleTranslation from 'i18n';
import { useTheme } from '@mui/material/styles';

export interface MetricOverlaysContextType {
    onChange: (popupId: string, value: any) => void;
    onDelete: (popupId: string, value: any) => void;
    onUpdate: (popupId: string, value: any) => void;
}

export const MetricOverlaysContext = createContext<MetricOverlaysContextType | null>(null);

let selectedValues: Array<string> = [];
export default function OverlayMetrics({
    elementId,
    segmentValueId,
    userChartOverlay,
    addCompareLines,
    removeCompareLines,
    updateCompareLine,
    addOverlays,
    removeOverlays,
    updateOverlay,
}: {
    elementId: number;
    segmentValueId: number;
    userChartOverlay: number;
    addCompareLines: (compareLines: Array<MetricChartCompareLineType>) => void;
    removeCompareLines: (compareLines: Array<number>) => void;
    updateCompareLine: (id: number, color: string, dashStyle: string) => void;
    addOverlays: (overlays: Array<MetricChartOverlayType>) => void;
    removeOverlays: (overlays: Array<{ elementId: number; segmentValueId: number }>) => void;
    updateOverlay: (overlay: MetricChartOverlayType) => void;
}) {
    const { t } = useBundleTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const appTheme = useTheme();

    const popupSettings = {
        title: 'overlay_metrics',
        textOK: 'button_save',
    };

    // const [selectedValues, setSelectedValues] = useState<Array<string>>([]);
    const handleOnApply = async (popupId: string) => {
        if (popupId == 'compareLinesGrid') {
            if (selectedValues.length) {
                const result = metricAPI.getCompareLinesInfo(
                    elementId,
                    selectedValues.map((id) => Number(id))
                );
                addCompareLines(await result);
            }
        } else {
            if (selectedValues.length) {
                const overlays = selectedValues.map((id: string) => {
                    const tmp = id.split('_');
                    return {
                        element_id: Number(tmp[0]),
                        segment_value_id: Number(tmp[1]),
                        metric_chart_display_type: 'line',
                        dashStyle: 'line',
                        axis: 'right',
                        color: appTheme.componentSettings.highstockChart.defaultOverlay,
                        marker: { symbol: 'circle' },
                    } as MetricChartOverlayType;
                });
                addOverlays(overlays);
            }
        }
    };
    const handleOnCancel = () => {};

    const handleChange = (popupId: string, value: any) => {
        selectedValues = value;
    };

    const handleDelete = (popupId: string, value: any) => {
        if (popupId == 'compareLinesGrid') {
            removeCompareLines([Number(value)]);
        } else {
            removeOverlays([value]);
        }
    };

    const handleUpdate = (popupId: string, value: any) => {
        if (popupId == 'compareLinesGrid') {
            updateCompareLine(value.id, value.color, value.dashStyle);
        } else {
            updateOverlay(value);
        }
    };

    const AsyncContext: AsyncPopupContextType = {
        onApply: handleOnApply,
        onClose: handleOnCancel,
    };

    const OverlayContext: MetricOverlaysContextType = {
        onChange: handleChange,
        onDelete: handleDelete,
        onUpdate: handleUpdate,
    };

    return (
        <>
            <Button variant="light" startIcon={<IconMi icon="layers" />} onClick={() => setIsOpen(true)}>
                {t('overlay_metrics')}
            </Button>
            <MetricOverlaysContext.Provider value={OverlayContext}>
                <AsyncPopupContext.Provider value={AsyncContext}>
                    {isOpen && (
                        <FormComponent
                            pk={'element-event'}
                            uid={'element-event'}
                            settingsUrl={
                                '/data/chart/overlay/manage/form/element/' +
                                elementId +
                                '/segment/' +
                                segmentValueId +
                                '/uco/' +
                                userChartOverlay
                            }
                            popupType={'new'}
                            popupSettings={popupSettings}
                            isPopup
                            onHide={() => setIsOpen(false)}
                        />
                    )}
                </AsyncPopupContext.Provider>
            </MetricOverlaysContext.Provider>
        </>
    );
}
