import React, { useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { useSelector } from 'react-redux';
import { formSliceState } from 'store/formSlice';
import { instance } from 'api/api';
import { APIResponse } from 'tools/types';
import { InfoPopupWrapper, useInfoPopup } from 'components/common/popup/InfoPopup';
import { Box, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import { AxiosResponse } from 'axios';
import useBundleTranslation from 'i18n';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getEditUrl, getViewUrl, logWarning } from 'tools/tools';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { ActAfterSaveSuccess, ActSubmit } from 'components/common/form/act';
import {
    DataValidateCollectContext,
    useDataValidateCollect,
} from 'components/common/form/data-validate-collect/useDataValidateCollect';

interface IInfoResponse {
    columns: string[];
    elements: any[];
    datasets: any[];
    sourced_datasets: any[];
    filters: any[];
    models: any[];
    external_reports: any[];
    bursts: any[];
    auto_external_filters: any[];
    sourced_external_filters: any[];
    sourced_custom_fields: any[];
    um: any[];
}

function InfoPopupElementsBlock({ t, type, list }: { t: TFunction; type: keyof IInfoResponse; list: Array<any> }) {
    if (type == 'columns' || type == 'um') {
        return null;
    }

    const result: Array<{ name: string; href: string }> = [];

    list.forEach((item) => {
        let href = '';
        switch (type) {
            case 'elements':
                href = (item.type == 'metric' ? getViewUrl('metric') : getEditUrl('report')) + +item.element_id;
                break;
            case 'datasets':
            case 'sourced_datasets':
            case 'models':
                href = getEditUrl('dataset') + item.dataset_id;
                break;
            case 'filters':
                href = `/dataset/index/index/ds/${item.dataset_id}/filter/${item.dataset_filter_id}`;
                break;
            case 'external_reports':
                href = getEditUrl('external-report') + item.element_id;
                break;
            case 'bursts':
                href = getEditUrl('burst') + item.notification_schedule_distribution_id;
                break;
        }
        result.push({
            name: item.name,
            href: href,
        });
    });

    if (result.length == 0) {
        return null;
    }

    return (
        <Box>
            {t('reassign', { type: t(`type.${type}`) })}
            <ul>
                {result.map((i, index) =>
                    i.href.length ? (
                        <li key={index}>
                            <Link target={'_blank'} to={i.href}>
                                {i.name}
                            </Link>
                        </li>
                    ) : (
                        <li key={index}>{i.name}</li>
                    )
                )}
            </ul>
        </Box>
    );
}

function InfoPopupContent({ data }: { data: IInfoResponse }) {
    const { t } = useBundleTranslation('app/editor/dataset/column');

    const list: Array<keyof IInfoResponse> = [
        'columns',
        'elements',
        'datasets',
        'sourced_datasets',
        'filters',
        'models',
        'external_reports',
        'bursts',
        'auto_external_filters',
        'sourced_external_filters',
        'sourced_custom_fields',
    ];

    const totals: Array<string> = [];
    list.forEach((key) => {
        if (data?.[key]?.length > 0 && key != 'columns') {
            const count = data[key].length;
            totals.push(`${count} ` + t(`type.${key}`, { count: count }));
        }
    });

    if (data?.um?.length > 0) {
        totals.push(t('type.um'));
    }

    const alertMessage = t('errorMessage', { line: totals.join(' ' + t('and') + ' ') });
    let message = 'Columns: ' + data.columns.join(', ');

    const blocks = list.map((key) => {
        return <InfoPopupElementsBlock key={key} t={t} type={key} list={data[key]} />;
    });

    return (
        <Stack direction={'column'} spacing={1}>
            <Alert severity={'error'} variant={'standard'}>
                <Box>{alertMessage}</Box>
            </Alert>
            <Box>{message}</Box>
            {blocks}
        </Stack>
    );
}

export default function DatasetForm(props: IFormLayoutProps) {
    const miId = props.hookForm.form.hookFormWatch('measurement_interval_id');
    const formKey = props.hookForm.form.formKey;
    const tmpValues: any = useSelector((state: any) => {
        const form = (state?.form as formSliceState).formElementsValues[formKey];
        if (form && typeof form['measurement_time_calc_command_id'] != 'undefined') {
            return form['measurement_time_calc_command_id'];
        }
        return [];
    });

    const infoPopup = useInfoPopup();

    useEffect(() => {
        if (!shouldUpdate) {
            return;
        }
        const v = tmpValues.find((row: any) => {
            row?.props?.isDefault == 'Y';
        });
        if (v) {
            props.hookForm.form.hookFormSetValue('measurement_time_calc_command_id', v.value);
        }

        setShouldUpdate(false);
    }, [JSON.stringify(tmpValues)]);

    const [shouldUpdate, setShouldUpdate] = useState(false);
    useEffect(() => {
        if (!props.hookForm.form.hookFormState.dirtyFields['measurement_interval_id']) {
            return;
        }
        setShouldUpdate(true);
    }, [miId]);

    const [isLoading, setIsLoading] = useState(false);
    const [lastValidationIteration, setLastValidationIteration] = useState(0);

    const dataValidateCollect = useDataValidateCollect(props.hookForm.form);

    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            public async exec(data: any): Promise<any> {
                const runValidation = async () => {
                    return new Promise((resolve, reject) => {
                        const isValidated =
                            props.hookForm.form.hookFormGetValues('data_fetch_command_validated_ind') == 'Y';

                        const updateTime = props.hookForm.form.hookFormGetValues('last_column_metadata_update_time');
                        // const isEnabled = true;
                        const isEnabled = Boolean(updateTime) && updateTime != '0000-00-00 00:00:00';
                        if (isEnabled && !isValidated) {
                            window.location.hash = 'dataset_collection';
                            // Execute validation process
                            if (dataValidateCollect.legacyHandleValidate.current) {
                                return dataValidateCollect.legacyHandleValidate
                                    .current(false)
                                    .then((validationResult) => {
                                        if (validationResult?.status) {
                                            resolve(true);
                                        } else {
                                            reject(false);
                                        }
                                    });
                            } else {
                                logWarning('legacyHandleValidate not registered, cannot perform validation');
                                resolve(true);
                                return;
                            }
                        }
                        resolve(true);
                    });
                };

                const preSubmitAction = new Promise(async (resolve, reject) => {
                    if (props.formLayout == 'popup') {
                        resolve(true);
                        return;
                    }
                    // Call validation if needed
                    try {
                        await runValidation();
                    } catch (e) {
                        reject(false);
                        return;
                    }

                    // Check for deleted columns
                    const method = props.hookForm.form.hookFormGetValues('data_fetch_method');
                    const validationIteration = props.hookForm.form.hookFormGetValues('validationIteration') ?? 0;
                    if (method == 'manual' || validationIteration == lastValidationIteration) {
                        resolve(true);
                        return;
                    }
                    const datasetId = props.hookForm.form.hookFormGetValues('dataset_id');
                    setIsLoading(true);
                    return instance
                        .post(`/data/editor/dataset/${datasetId}/validate-dataset-columns`)
                        .then((response: AxiosResponse<APIResponse<IInfoResponse>>) => {
                            if (response.data.status == 'ERROR') {
                                infoPopup.setContent(<InfoPopupContent data={response.data.data} />);
                                infoPopup.setIsOpen(true);
                                reject(false);
                            } else {
                                resolve(true);
                            }
                        })
                        .finally(() => {
                            setIsLoading(false);
                            reject(false);
                        });
                });
                return preSubmitAction
                    .then(() => super.exec(props.hookForm.form.hookFormGetValues()))
                    .catch((e) => false);
            }
        }
    );

    props.hookForm.form.formAct.overrideAct(
        'afterSaveSuccess',
        class extends ActAfterSaveSuccess {
            async exec(response: any, data: any): Promise<any> {
                const validationIteration = props.hookForm.form.hookFormGetValues('validationIteration') ?? 0;
                setLastValidationIteration(validationIteration);
                return super.exec(response, data);
            }
        }
    );
    return (
        <DataValidateCollectContext.Provider value={dataValidateCollect}>
            {isLoading && <LoadingPlaceholder />}
            <InfoPopupWrapper infoPopup={infoPopup} />
            <FormLayoutComposer props={props} />
        </DataValidateCollectContext.Provider>
    );
}
