import React, { lazy, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { Box, Button, Container, Stack, TextField, Theme } from '@mui/material';
import { FormComponentBuilder } from 'components/common/form/layout';
import styles from './KpiRuleInfo.styles';
import IconMi from 'components/common/icon/IconMi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { customRequestAPI } from 'api/api';
import { Popup } from 'components/common/popup/Popup';
const AlertRulePopup = lazy(() => import('components/alert-rule-popup/AlertRulePopup'));
import { getAlertConditionText } from 'components/alert-rule-popup/parts/AlertRuleCriteria';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from 'store/breadcrumbsSlice';
const AlertRuleChart = lazy(() => import('components/alert-rule-popup/AlertRuleChart'));

interface alertInfo {
    element_id: number;
    element: string;
    user_defined_name: string;
    type: string;
    alert_visibility: string;
    content: string;
    distribution: string;
    condition_params: any;
    alert_type: any;
    default_name: string;
    alert_rule_id: number;
    owner_name: string;
    owner_subscribed_ind: string;
}

interface alertRuleWizardParams {
    alert_rule_id: number;
    element_id: number;
    global: string;
    segment_value_id: number;
    user: number;
    visualization_element_id: number;
}

interface ruleInfoData {
    alert_info: alertInfo;
    alert_rule_wizard_params: alertRuleWizardParams;
    element_data: any;
    workflow: any;
    isPermitted4EditWorkflow: boolean;
}

export interface FormControlKpiRuleInfoProps extends FormControlProps {
    isPrivateMode?: boolean;
}

export default function KpiRuleInfo({ controlProps: props }: FormElementControlPropsType<FormControlKpiRuleInfoProps>) {
    const { isPrivateMode = false } = props;
    const { t } = useBundleTranslation([
        'app/editor/kpi-alert-rule/kpi_alert_rule',
        'components/alert-rule-popup/alert_rule_popup',
    ]);
    const [showEditNamePopup, setShowEditNamePopup] = useState<boolean>(false);
    const [showAlertWizardPopup, setShowAlertWizardPopup] = useState<boolean>(false);
    const [alertWizardStep, setAlertWizardStep] = useState<number>(1);

    const [ruleInfo, setRuleInfo] = useState<ruleInfoData>();
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [newNameVal, setNewNameVal] = useState<string>('');
    const urlParams = useParams();
    const alertRuleId = urlParams.alertRuleId;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setBreadcrumb = (ruleInfo: ruleInfoData) => {
        dispatch(
            setBreadcrumbs([
                {
                    label: t('breadcrumbs_label'),
                    href: `/editor/kpi-alert-rule`,
                    translate: false,
                },
                {
                    label: ruleInfo.alert_info.element,
                    href: `/chart/${ruleInfo.alert_info.element_id}/`,
                    translate: false,
                },
                {
                    label: ruleInfo.alert_info.user_defined_name,
                    translate: false,
                },
            ])
        );
    };

    useEffect(() => {
        customRequestAPI
            .get(`data/editor/alert-rule/${alertRuleId}/alert-rule-info`)
            .then((response: any) => {
                if (response?.statusText === 'OK') {
                    setRuleInfo(response.data);
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (ruleInfo && !isPrivateMode) {
            setBreadcrumb(ruleInfo);
        }
    }, [ruleInfo]);

    useEffect(() => {
        if (ruleInfo && !isPrivateMode) {
            setIsChanged(newNameVal != ruleInfo.alert_info.default_name);
        }
    }, [newNameVal, ruleInfo]);

    if (!ruleInfo) return null;

    let rowList = [];
    if (isPrivateMode) {
        rowList = ['visibility', 'created_by', 'creator_subscription_status'];
    } else {
        rowList = ['element', 'name', 'type_visibility', 'condition', 'content'];
        rowList.push(ruleInfo.workflow ? 'workflow' : 'distribution');
    }
    const hasNoAction = ['element', 'created_by', 'creator_subscription_status', 'workflow'];

    const revertDisabled = ruleInfo.alert_info.alert_type == 'compound';

    const getValue = (type: string) => {
        switch (type) {
            case 'element':
                return (
                    <Box component={Link} sx={styles.elementHolder} to={`/chart/${ruleInfo.alert_info.element_id}/`}>
                        {ruleInfo.alert_info.element}
                    </Box>
                );
            case 'name':
                return ruleInfo.alert_info.user_defined_name;
            case 'type_visibility':
                return (
                    <Box>
                        <Box className={'color-warn-solid'}>
                            {ruleInfo.alert_info.type == 'Compound'
                                ? t('type_compound_label')
                                : ruleInfo.alert_info.type}
                        </Box>
                        <Box>
                            {t(
                                `visibility_${
                                    ruleInfo.alert_info.alert_visibility === 'public' ? 'public' : 'private'
                                }_label`
                            )}
                        </Box>
                    </Box>
                );
            case 'condition':
                const miIntervalUnit = ruleInfo?.element_data?.mi_interval_unit ?? '';
                const conditionParams = JSON.parse(ruleInfo.alert_info.condition_params);
                return (
                    <Box>
                        {t(
                            `condition_${
                                ruleInfo.alert_info.condition_params.alertType === 'stoplight' ? 'stoplight' : 'other'
                            }_prefix`
                        )}{' '}
                        {getAlertConditionText({ ...conditionParams, mi_interval_unit: miIntervalUnit }, t)}
                    </Box>
                );
            case 'content':
                if (ruleInfo.alert_info.content) {
                    return <Box component={'span'} dangerouslySetInnerHTML={{ __html: ruleInfo.alert_info.content }} />;
                }
                return t('content_empty_label');
            case 'distribution':
                return (
                    <Box component={'span'} dangerouslySetInnerHTML={{ __html: ruleInfo.alert_info.distribution }} />
                );
            case 'workflow':
                return (
                    <Box>
                        <Box>
                            {t('workflow_label_prefix')}{' '}
                            <Box className={'color-warn-solid'}>{ruleInfo.workflow.name}</Box>
                        </Box>
                        <Box>{t('workflow_label')}</Box>
                    </Box>
                );
            case 'visibility':
                return (
                    <Box>
                        <Box className={'color-warn-solid'}>{t('alert_settings_visibility_private')}</Box>
                        <Box>{t('alert_settings_visibility_not_visible')}</Box>
                    </Box>
                );
            case 'created_by':
                return (
                    <Box color={'text.primary'} className={'fw-normal'}>
                        {ruleInfo.alert_info.owner_name}
                    </Box>
                );
            case 'creator_subscription_status':
                return (
                    <Box color={'text.primary'} className={'fw-normal'}>
                        {ruleInfo.alert_info.owner_subscribed_ind == 'Y'
                            ? t('creator_subscription_status_y')
                            : t('creator_subscription_status_n')}
                    </Box>
                );
        }
    };

    const clickAction = (type: string) => {
        switch (type) {
            case 'name':
                setNewNameVal(ruleInfo.alert_info.user_defined_name);
                setShowEditNamePopup(true);
                break;
            case 'visibility':
            case 'type_visibility':
                setAlertWizardStep(1);
                setShowAlertWizardPopup(true);
                break;
            case 'condition':
                setAlertWizardStep(2);
                setShowAlertWizardPopup(true);
                break;
            case 'content':
                setAlertWizardStep(3);
                setShowAlertWizardPopup(true);
                break;
            case 'distribution':
                setAlertWizardStep(2);
                setShowAlertWizardPopup(true);
                break;
            case 'workflow':
                //change tab $('#top_tab_recipients_tab').click();
                window.location.hash = 'recipients';
                break;
            default:
                alert(type);
        }
    };

    const saveNewName = () => {
        customRequestAPI
            .post(`/data/editor/alert-rule/${alertRuleId}/edit-name`, {
                changed: !isChanged ? 'N' : 'Y',
                name: newNameVal,
                id: alertRuleId,
            })
            .then((response: any) => {
                if (response.data?.status === 'OK') {
                    setRuleInfo((prevState) => {
                        const state: any = prevState ?? {};
                        const alertInfo = { ...{}, ...state?.alert_info };
                        alertInfo.user_defined_name = response.data.data;
                        return { ...state, alert_info: alertInfo };
                    });
                    setShowEditNamePopup(false);
                } else {
                    alert(response.data.message);
                }
            })
            .catch(() => {});
    };

    return (
        <Box sx={styles.infoWrapper}>
            <Container maxWidth={'lg'} component="main" disableGutters={true}>
                <Box sx={styles.infoTable}>
                    {rowList.map((row) => {
                        const noAction = hasNoAction.includes(row);
                        return (
                            <Box sx={styles.infoRow} className={noAction ? undefined : 'actionRow'} key={row}>
                                <Box sx={styles.infoRowLabel}>{t(`info_row_label.${row}`)}</Box>
                                <Box
                                    sx={styles.infoRowValue}
                                    className={'c_dependentOfSetColors'}
                                    onClick={() => {
                                        if (!noAction) clickAction(row);
                                    }}
                                >
                                    {getValue(row)}
                                </Box>
                                <Box sx={styles.infoRowAction}>
                                    {!noAction && (
                                        <Button
                                            sx={styles.infoRowActionBtn}
                                            variant="light"
                                            startIcon={<IconMi icon={'gear'} />}
                                            onClick={() => {
                                                clickAction(row);
                                            }}
                                        >
                                            {t(`info_row_action.${row}`)}
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                {!isPrivateMode && (
                    <AlertRuleChart
                        elementId={ruleInfo.element_data.element_id}
                        segmentValueId={ruleInfo.element_data.segment_value_id}
                    />
                )}
            </Container>
            {showAlertWizardPopup && (
                <AlertRulePopup
                    onClose={() => {
                        setShowAlertWizardPopup(false);
                    }}
                    onFinish={(response) => {
                        if (response.data.status == 'OK') {
                            alert(t('wizard_saved_msg'));
                            navigate(0);
                        }
                        setShowAlertWizardPopup(false);
                    }}
                    show={true}
                    alertRuleId={ruleInfo.alert_rule_wizard_params.alert_rule_id}
                    elementId={ruleInfo.alert_rule_wizard_params.element_id}
                    segmentValueId={ruleInfo.alert_rule_wizard_params.segment_value_id}
                    userId={ruleInfo.alert_rule_wizard_params.user}
                    visualizationElementId={ruleInfo.alert_rule_wizard_params.visualization_element_id}
                    global={ruleInfo.alert_rule_wizard_params.global === 'Y' ? 'Y' : 'N'}
                    step={alertWizardStep}
                />
            )}
            {showEditNamePopup && (
                <Popup
                    maxWidth={'popupSm'}
                    settings={{
                        title: t('edit_name_popup.title'),
                        noCancel: true,
                    }}
                    onHide={() => {
                        setShowEditNamePopup(false);
                    }}
                    onConfirm={saveNewName}
                    open
                >
                    <Box>
                        <TextField
                            fullWidth={true}
                            name={'new_alert_rule_name'}
                            autoFocus={true}
                            value={newNameVal}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setNewNameVal(event.target.value);
                            }}
                            InputProps={{
                                endAdornment:
                                    !revertDisabled && isChanged ? (
                                        <Stack
                                            sx={{
                                                flexShrink: 0,
                                                flexDirection: 'row',
                                                alignItem: 'center',
                                                pr: '6px',
                                                cursor: 'pointer',
                                                color: (theme: Theme) => theme.palette.text.secondary,
                                                '&:hover': {
                                                    color: (theme: Theme) => theme.palette.primary.main,
                                                },
                                            }}
                                            onClick={() => {
                                                setNewNameVal(ruleInfo.alert_info.default_name);
                                            }}
                                        >
                                            <IconMi icon="rotate-ccw" fontSize="16" />
                                        </Stack>
                                    ) : undefined,
                            }}
                        />
                    </Box>
                </Popup>
            )}
        </Box>
    );
}

export class FormKpiRuleInfoBuilder extends FormComponentBuilder {
    prepareProps(): FormControlKpiRuleInfoProps {
        return {
            ...this.controlProps,
            isPrivateMode: this.elementProps.componentProps?.isPrivateMode ?? false,
        };
    }
}
