export type TFormAct = (...args: any[]) => Promise<any>;
export interface IFromActsClass {
    submit: ActSubmit;
    save: ActSave;
    afterSaveError: ActAfterSaveError;
    afterSaveSuccess: ActAfterSaveSuccess;
    [key: string | number]: ActFunc;
}

export class FormActs<T extends IFromActsClass> {
    public act: T;
    constructor(formActs: T) {
        this.act = formActs;
    }
    public overrideAct<D extends typeof ActFunc>(formActName: keyof T, classRef: D) {
        //@ts-ignore
        this.act[formActName] = new classRef(
            this.act?.[formActName]?.baseImplementation ??
                function () {
                    return Promise.resolve();
                }
        );
    }
}

export abstract class ActFunc {
    // protected formActions;
    public baseImplementation: TFormAct;
    public constructor(baseImplementation: TFormAct) {
        // this.formActions = formActions;
        this.baseImplementation = baseImplementation;
    }
    public abstract exec(...args: any[]): Promise<any>;
}

export class ActSubmit extends ActFunc {
    public async exec(data: any): Promise<any> {
        // console.log('Form act ActSubmit');
        return this.baseImplementation(data);
    }
}

export class ActSave extends ActFunc {
    public async exec(data: any): Promise<any> {
        // console.log('Form act ActSave');
        return this.baseImplementation(data);
    }
}

export class ActAfterSaveError extends ActFunc {
    public async exec(response: any): Promise<any> {
        // console.log('Form act ActAfterSaveError');
        return this.baseImplementation(response);
    }
}

export class ActAfterSaveSuccess extends ActFunc {
    public async exec(response: any, data: any): Promise<any> {
        // console.log('Form act ActAfterSaveSuccess');
        return this.baseImplementation(response, data);
    }
}
