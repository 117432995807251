import { alpha, Button, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import useBundleTranslation from 'i18n';

interface IHeaderProps {
    count: number;
    onBuildMetrics: () => void;
    buildInProgress: boolean;
}

export default function Header({ count, onBuildMetrics, buildInProgress }: IHeaderProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    return (
        <Stack
            direction="row"
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
                padding: '10px 24px',
                borderBottom: '1px solid',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
            }}
        >
            <Typography fontSize={14} fontWeight={600} color={'primary.main'}>{`${count} ${
                count === 1 ? t('metric_preview.metric_defined') : t('metric_preview.metrics_defined')
            }`}</Typography>
            <Button
                variant="light"
                color={'success'}
                startIcon={<IconMi icon="new" />}
                onClick={onBuildMetrics}
                disabled={buildInProgress || count === 0}
            >
                {t('metric_preview.build_all')}
            </Button>
        </Stack>
    );
}
