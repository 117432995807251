import {
    FormComponentValue,
    FormControlProps,
    FormElementControlPropsType,
} from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import IconMi from 'components/common/icon/IconMi';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { customScriptAPI } from 'api/admin/custom-script';
import { Popup } from 'components/common/popup/Popup';
import GridComponent from 'components/common/grid/GridComponent';
import useBundleTranslation from 'i18n';
import { useQueryClient } from '@tanstack/react-query';

export interface RunCustomScriptProps extends FormControlProps {
    historyGridUid: string;
    paramSetGrid: string;
    editorHistoryGrid: string;
    runScriptUrl: string;
}

export default function RunCustomScript({ controlProps }: FormElementControlPropsType<RunCustomScriptProps>) {
    const { t } = useBundleTranslation(['app/admin/runscript']);

    const gridValue = controlProps.form.hookFormWatch(controlProps.paramSetGrid);
    const [availableParamSets, setAvailableParamSets] = useState<FormComponentValue[]>([]);
    const [selectedParamSet, setSelectedParamSet] = useState('');
    const [showLoadingPlaceholder, setShowLoadingPlaceholder] = useState(false);
    const [showOutputPopup, setShowOutputPopup] = useState(false);
    const [showEditorHistoryPopup, setShowEditorHistoryPopup] = useState(false);
    const [outputMessage, setOutputMessage] = useState('');

    const queryClient = useQueryClient();

    useEffect(() => {
        if (gridValue && gridValue.length > 0) {
            setAvailableParamSets(
                gridValue.map((gridRow: { name: string; id: string }) => {
                    return {
                        label: gridRow.name,
                        value: gridRow.id,
                    };
                })
            );
        }
    }, [gridValue]);

    const onRunScriptClick = () => {
        setShowLoadingPlaceholder(true);
        customScriptAPI
            .runCustomScript(controlProps.runScriptUrl, selectedParamSet)
            .then((response) => {
                if (response.status === 'OK') {
                    setShowOutputPopup(true);
                    setOutputMessage(response.data.output);
                    queryClient.invalidateQueries([controlProps.historyGridUid]);
                }
            })
            .finally(() => {
                setShowLoadingPlaceholder(false);
            });
    };

    const onEditorHistoryClick = () => {
        setShowEditorHistoryPopup(true);
    };

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} spacing={1}>
                    <Box sx={{ width: '150px' }}>
                        <ReactSelect
                            data={availableParamSets}
                            value={selectedParamSet}
                            update={(value) => setSelectedParamSet(value)}
                        />
                    </Box>
                    <Button
                        variant={'contained'}
                        color={'success'}
                        startIcon={<IconMi icon="play" />}
                        onClick={onRunScriptClick}
                    >
                        {t('run_button')}
                    </Button>
                </Stack>
                <Button variant={'outlined'} startIcon={<IconMi icon="history" />} onClick={onEditorHistoryClick}>
                    {t('history_button')}
                </Button>
            </Stack>
            {showLoadingPlaceholder && (
                <LoadingPlaceholder>
                    <Typography>{t('running_loading')}</Typography>
                </LoadingPlaceholder>
            )}
            {showOutputPopup && (
                <Popup
                    settings={{
                        noOk: true,
                        title: t('output_header'),
                    }}
                    open={showOutputPopup}
                    onHide={() => {
                        setShowOutputPopup(false);
                        setOutputMessage('');
                    }}
                >
                    <Typography>{outputMessage}</Typography>
                </Popup>
            )}
            {showEditorHistoryPopup && (
                <Popup
                    settings={{
                        noOk: true,
                        title: t('history_header'),
                    }}
                    open={showEditorHistoryPopup}
                    onHide={() => {
                        setShowEditorHistoryPopup(false);
                    }}
                >
                    <GridComponent
                        uid={`editor-history-${controlProps.editorHistoryGrid}`}
                        settingsUrl={controlProps.editorHistoryGrid}
                        formGrid
                    />
                </Popup>
            )}
        </>
    );
}

export class RunCustomScriptBuilder extends FormComponentBuilder {
    prepareProps(): RunCustomScriptProps {
        return {
            ...this.controlProps,
            paramSetGrid: this.elementProps.componentProps?.paramSetGrid,
            editorHistoryGrid: this.elementProps.componentProps?.editorHistoryGrid,
            runScriptUrl: this.elementProps.componentProps?.runScriptUrl,
            historyGridUid: this.elementProps.componentProps?.historyGridUid,
        };
    }
}
