import { ReportContentNS } from 'components/report-content/index';
import React, { useEffect, useRef, useState } from 'react';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Chart as HChart } from 'highcharts';
import { ChartPoint } from 'components/metric/chart';
import Chart from 'components/chart/Chart';
import ComponentSettingsRangeChart = ReportContentNS.ComponentSettingsRangeChart;
import useRCChartData from 'components/report-content/hooks/useRCChartData';
import TooltipWrapper from 'components/report-content/utils/TooltipWrapper';
import RangeChartTooltip from 'components/report-content/components/range-chart/RangeChartTooltip';
import { applyChartOptionsForThumbnail, escapeHtml, parseDate } from 'components/report-content/utils/tools';
import { AssocArray } from 'tools/types';
import useComponentReady from 'components/report-content/hooks/useComponentReady';

export default function RangeChart({
    contentSettings,
    component,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsRangeChart>) {
    const { initialData, data } = useRCChartData(component, contentSettings, updateBlockSettings, true);
    const isThumbnail = contentSettings.forScreenShot && window.location.href.includes('thumbnail');
    const [formattedValues, setFormattedValues] = useState<AssocArray<string>>({});
    const formattedValuesRef = useRef(formattedValues);
    useEffect(() => {
        formattedValuesRef.current = formattedValues;
    }, [formattedValues]);

    const options = {
        chart: {
            animation: false,
            type: 'columnrange',
            inverted: true,
            style: {
                fontFamily: 'Arial, helvetica, sans-serif',
            },
            height: 600,
        },
        legend: { enabled: false },
        credits: { enabled: false },
        rangeSelector: { enabled: false },
        navigator: { enabled: false },
        scrollbar: { enabled: false },
        exporting: { enabled: false },
        title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            y: 0,
        },
        // tooltip: toolTipOptions,
        plotOptions: {
            columnrange: {
                dataLabels: {
                    enabled: false,
                    formatter: function (): any {
                        //@ts-ignore;
                        return formattedValuesRef.current[String(this.y)] ?? '';
                    },
                },
            },
            series: {
                animation: !contentSettings.forScreenShot,
                point: {
                    events: {
                        click: function () {
                            // self.drawDrillToTooltip(this);
                        },
                    },
                },
            },
        },
        xAxis: {
            categories: [] as Array<any>,
        } as any,
        yAxis: {
            title: {
                text: escapeHtml(component.settings?.value_axis_label),
                useHTML: true,
            },
        } as any,
        series: [
            {
                name: component.settings.x_field.column_name,
                data: [] as Array<any>,
            },
        ],
    };

    const [chartOptions, setChartOptions] = useState(options);
    useEffect(() => {
        if (isThumbnail) {
            //@ts-ignore
            applyChartOptionsForThumbnail(options);
        } else {
            options.chart.height = component.settings.height;
        }

        if ('Y' === component.settings.display_value) {
            options.plotOptions.columnrange.dataLabels.enabled = true;
        }

        if (data.length == 0 || typeof initialData == 'undefined') {
            // if (!isPreview) {
            //     self.triggerAfterDataLoaded(false);
            // }
            // TODO:
            // self.el
            //     .find('.block-wrapper-chart-container:first')
            //     .html(
            //         '<img style="width:' +
            //             (self.settings.width > 0 ? self.settings.width + 'px' : '100%') +
            //             ';height:' +
            //             (self.settings.height > 0 ? self.settings.height + 'px' : '100%') +
            //             ';" src="' +
            //             globalConstants.homeSite +
            //             'img/default_preview_no_data.png' +
            //             '"/>'
            //     );
        } else {
            const newFormattedValues: AssocArray<string> = {};

            const addNewFormattedValue = (v: any, range: 'end' | 'start') => {
                newFormattedValues[
                    String(
                        'datetime' == component.settings[`range_${range}`].value_type &&
                            -1 ==
                                ['Count', 'Count Distinct'].indexOf(
                                    component.settings[`range_${range}`].aggregation_function
                                )
                            ? parseDate(v[component.settings[`range_${range}`].reference_name]).getTime()
                            : v[component.settings[`range_${range}`].reference_name]
                    )
                ] =
                    v[component.settings[`range_${range}`].reference_name + '-formatted'] ??
                    v[component.settings[`range_${range}`].reference_name];
            };

            // if (!isPreview) {
            //     self.triggerAfterDataLoaded(true);
            // }
            if (
                'datetime' == component.settings.range_start.value_type &&
                -1 == ['Count', 'Count Distinct'].indexOf(component.settings.range_start.aggregation_function)
            ) {
                options.yAxis.type = 'datetime';
            }

            if ('datetime' == component.settings.x_field.value_type) {
                options.xAxis.type = 'datetime';
                options.xAxis.labels = { format: '{value:%m/%d/%y}' };
            }
            if (component.settings.range_start.reference_name != component.settings.range_end.reference_name) {
                for (const [k, v] of Object.entries(data)) {
                    options.xAxis.categories.push(
                        'datetime' == component.settings.x_field.value_type &&
                            v[component.settings.x_field.reference_name]
                            ? parseDate(v[component.settings.x_field.reference_name])
                            : v[component.settings.x_field.reference_name]
                    );
                    options.series[0].data.push([
                        'datetime' == component.settings.range_start.value_type &&
                        -1 == ['Count', 'Count Distinct'].indexOf(component.settings.range_start.aggregation_function)
                            ? parseDate(v[component.settings.range_start.reference_name]).getTime()
                            : parseFloat(v[component.settings.range_start.reference_name]),
                        'datetime' == component.settings.range_end.value_type &&
                        -1 == ['Count', 'Count Distinct'].indexOf(component.settings.range_end.aggregation_function)
                            ? parseDate(v[component.settings.range_end.reference_name]).getTime()
                            : parseFloat(v[component.settings.range_end.reference_name]),
                    ]);

                    addNewFormattedValue(v, 'start');
                    addNewFormattedValue(v, 'end');
                }
            } else {
                const mainData = data.slice();
                const secondaryData =
                    initialData.dataResults['table-' + component.settings.range_end.aggregation_function];
                // TODO:
                if (typeof secondaryData == 'undefined') {
                    return;
                }
                for (const [k, v] of Object.entries(secondaryData)) {
                    const cat = v[component.settings.x_field.reference_name];
                    const find = mainData.find((a) => a[component.settings.x_field.reference_name] == cat);

                    if ('undefined' == typeof find) {
                        mainData.push({
                            [component.settings.x_field.reference_name]: cat,
                            [component.settings.range_end.reference_name + '-formatted']: '0',
                            [component.settings.range_end.reference_name]: 0,
                        } as any);
                    }
                }

                for (const [k, v] of Object.entries(mainData)) {
                    const cat = v[component.settings.x_field.reference_name];
                    options.xAxis.categories.push(
                        'datetime' == component.settings.x_field.value_type && cat ? parseDate(cat) : cat
                    );

                    const tmp: any = [
                            'datetime' == component.settings.range_start.value_type &&
                            -1 ==
                                ['Count', 'Count Distinct'].indexOf(component.settings.range_start.aggregation_function)
                                ? parseDate(v[component.settings.range_start.reference_name]).getTime()
                                : parseFloat(v[component.settings.range_start.reference_name]),
                        ],
                        find = secondaryData.find((a) => a[component.settings.x_field.reference_name] == cat);

                    addNewFormattedValue(v, 'start');

                    if ('undefined' != typeof find) {
                        tmp.push(
                            'datetime' == component.settings.range_end.value_type &&
                                -1 ==
                                    ['Count', 'Count Distinct'].indexOf(
                                        component.settings.range_end.aggregation_function
                                    )
                                ? parseDate(find[component.settings.range_end.reference_name]).getTime()
                                : parseFloat(find[component.settings.range_end.reference_name])
                        );
                        addNewFormattedValue(find, 'end');
                    } else {
                        tmp.push(0);
                    }

                    options.series[0].data.push(tmp);
                }
            }
            setFormattedValues((prevState) => ({ ...prevState, ...newFormattedValues }));
        }

        // if (isPreview) {
        //     setTimeout(function () {
        //         alert('generated');
        //     }, 200);
        // }

        // Delay for formattedValues update;
        setTimeout(() => setChartOptions(options));
    }, [data, initialData, component.settings]);

    const [chart, setChart] = useState<HChart>();
    const [hoveredChartPoint, setHoveredChartPoint] = useState<ChartPoint | null>(null);
    useComponentReady(chart);

    return initialData ? (
        <div style={{ overflow: 'hidden', maxHeight: component.settings.height + 'px' }}>
            {/*@ts-ignore*/}
            <Chart chartOptions={chartOptions} afterChartCreated={(chart: HChart) => setChart(chart)} />
            {chart && (
                <TooltipWrapper chart={chart}>
                    {(formatterContext) => (
                        <RangeChartTooltip formattedValues={formattedValues} context={formatterContext} />
                    )}
                </TooltipWrapper>
            )}
        </div>
    ) : (
        // TODO: add loading mask
        <span></span>
    );
}
