import IconMi from 'components/common/icon/IconMi';
import {
    alpha,
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { VisibleColumns } from '../index';
import useBundleTranslation from 'i18n';

export default function ControlButtons({
    resetColumns,
    resetGridSettings,
    uid,
    switchColumns,
    handleColumnSwitch,
    visibleColumns,
    fullwidthMode,
    widthToggle,
    onFullwidthChange,
}: {
    resetColumns: boolean;
    switchColumns: boolean;
    resetGridSettings: () => void;
    uid?: string;
    visibleColumns: VisibleColumns;
    handleColumnSwitch: (columnName: string, visible: boolean) => void;
    fullwidthMode: string;
    widthToggle: boolean;
    onFullwidthChange: (value: string) => void;
}) {
    const { t } = useBundleTranslation();

    const switchColumnAnchorRef = useRef<HTMLButtonElement>(null);
    const [openColumnsMenu, setOpenColumnsMenu] = useState(false);

    const columnsMenuClose = (event: Event | React.SyntheticEvent) => {
        if (switchColumnAnchorRef.current && switchColumnAnchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenColumnsMenu(false);
    };

    const columnMenuOpen = () => {
        setOpenColumnsMenu((prevOpen) => !prevOpen);
    };

    const onResetGridClick = () => {
        resetGridSettings();
        setOpenColumnsMenu(false);
    };

    const visibleHidableColumns: VisibleColumns = {};

    Object.keys(visibleColumns).map((columnName, index) => {
        if (visibleColumns[columnName].hidable) {
            visibleHidableColumns[columnName] = visibleColumns[columnName];
        }
    });

    const visibleHidableColumnsNames = Object.keys(visibleHidableColumns);

    return resetColumns || switchColumns ? (
        <ClickAwayListener onClickAway={columnsMenuClose}>
            <>
                <Button
                    variant="outlined"
                    data-test={`grid_${uid}_switch_columns_button`}
                    ref={switchColumnAnchorRef}
                    onClick={columnMenuOpen}
                    sx={{
                        backgroundColor: (theme) => (openColumnsMenu ? alpha(theme.palette.primary.main, 0.24) : ''),
                    }}
                    className={'min-width--icon'}
                >
                    <IconMi icon="columns" fontSize={'16'} />
                </Button>
                <Menu
                    open={openColumnsMenu}
                    onClose={columnsMenuClose}
                    anchorEl={switchColumnAnchorRef.current}
                    sx={{ '.MuiMenu-list': { py: 0 } }}
                >
                    <Stack direction={'column'} spacing={2} sx={{ width: '245px' }}>
                        {widthToggle && (
                            <Box
                                sx={{
                                    borderBottom: '1px solid',
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    padding: '16px',
                                }}
                            >
                                <ToggleButtonGroup
                                    color="primary"
                                    value={fullwidthMode}
                                    exclusive
                                    onChange={(event, value) => {
                                        if (value) {
                                            onFullwidthChange(value);
                                            setOpenColumnsMenu(false);
                                        }
                                    }}
                                    fullWidth
                                >
                                    <ToggleButton value="fixed">{`Fixed Width`}</ToggleButton>
                                    <ToggleButton value="full">{`Full Width`}</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        )}
                        {switchColumns && visibleHidableColumnsNames.length > 0 && (
                            <Box sx={{ padding: widthToggle ? '0 16px' : '16px 16px 0', marginTop: 0 }}>
                                <Typography fontWeight={600} fontSize={12} sx={{ paddingBottom: '6px' }}>
                                    {t('show_columns')}
                                </Typography>
                                {visibleHidableColumnsNames.map((columnName, index) => {
                                    return (
                                        <MenuItem
                                            key={columnName}
                                            value={columnName}
                                            onClick={() => {
                                                handleColumnSwitch(
                                                    columnName,
                                                    !visibleHidableColumns[columnName].visible
                                                );
                                            }}
                                            data-test={`grid_${uid}_switch_column_item_${columnName}`}
                                            sx={{
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                borderTop: index > 0 ? '1px solid' : '',
                                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                                paddingTop: '3px',
                                                paddingBottom: '3px',
                                            }}
                                        >
                                            <Checkbox
                                                sx={{ paddingLeft: '0px' }}
                                                checked={visibleHidableColumns[columnName].visible}
                                            />
                                            <ListItemText
                                                sx={{
                                                    color: visibleHidableColumns[columnName].visible
                                                        ? 'primary.main'
                                                        : '',
                                                }}
                                                primary={visibleHidableColumns[columnName].label}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </Box>
                        )}
                        {resetColumns && (
                            <Box
                                sx={{
                                    borderTop: '1px solid',
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    padding: '16px',
                                }}
                            >
                                <Button
                                    variant="light"
                                    onClick={onResetGridClick}
                                    data-test={`grid_${uid}_reset_columns_button`}
                                    fullWidth
                                >
                                    {t('reset_column_width')}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </Menu>
            </>
        </ClickAwayListener>
    ) : null;
}
