import { IconButton, Typography, Grid, Tooltip, CircularProgress, Backdrop } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { gridAPI } from 'api/grid';
import { CustomCellProps } from 'components/common/grid/';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import AsyncPopup from 'components/common/popup/AsyncPopup';

export default function DeleteCell({
    text,
    href,
    confirmation,
    reloadGridData,
    t,
    confirmationUrl,
    remove,
    icon,
    data,
}: CustomCellProps) {
    if (undefined === t) t = useBundleTranslation('main')['t'];
    const tMain = useBundleTranslation('main')['t'];

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [openAsyncConfirmation, setOpenAsyncConfirmation] = useState<boolean>(false);
    const [loadingMaskShow, setLoadingMaskShow] = useState<boolean>(false);

    const popupSettings: PopupSettings = {
        title: text,
        textOK: remove ? 'remove' : 'delete',
        colorOk: !remove ? 'error' : undefined,
        iconOK: !remove ? 'trash' : undefined,
        maxWidth: 'popupSm',
    };

    const onClick = () => {
        if (confirmationUrl && confirmationUrl > '') {
            setOpenAsyncConfirmation(true);
            return;
        }

        setOpenConfirmation(true);
    };

    const itemName = useMemo(() => {
        const name = data ? (data['name'] || data['display_name']) ?? '' : '';

        if (typeof name === 'string') {
            return name;
        }

        return name.config.text;
    }, [data]);

    const makeDelete = () => {
        setLoadingMaskShow(true);
        if (href) {
            gridAPI
                .delete(href)
                .then((response) => {
                    if (response.data.status == 'ERROR') {
                        alert(response.data?.message ?? response.data.messages.join(' '));
                    } else {
                        if (reloadGridData) {
                            reloadGridData();
                        }
                    }
                })
                .finally(() => {
                    setOpenConfirmation(false);
                    setOpenAsyncConfirmation(false);
                    setLoadingMaskShow(false);
                });
        }
    };

    return (
        <>
            <Grid container sx={{ height: 1, alignItems: 'center', justifyContent: 'end' }}>
                <Tooltip title={tMain(remove ? 'remove' : 'delete')}>
                    <IconButton aria-label="delete" size={'small'} onClick={onClick} sx={{ color: 'text.primary' }}>
                        <IconMi icon={icon ? icon.value : remove ? 'times' : 'delete'} fontSize={'16'} />
                    </IconButton>
                </Tooltip>
                {openConfirmation && (
                    <Popup
                        settings={popupSettings}
                        open={openConfirmation}
                        t={t}
                        onHide={() => setOpenConfirmation(false)}
                        onConfirm={makeDelete}
                        maxWidth={'popupSm'}
                    >
                        <Typography>{t(confirmation ?? '', { name: itemName })}</Typography>
                        <Backdrop
                            sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loadingMaskShow}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Popup>
                )}
                {openAsyncConfirmation && confirmationUrl && (
                    <AsyncPopup
                        settingsUrl={confirmationUrl}
                        onHide={() => setOpenAsyncConfirmation(false)}
                        onConfirm={makeDelete}
                        popupSettings={popupSettings}
                    />
                )}
            </Grid>
        </>
    );
}
