import { useMemo, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Card, CardContent, CircularProgress, Typography, Tooltip, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ReactSelect from 'components/common/react-select/ReactSelect';
import { editorSourceReportAPI, ElementColumn } from 'api/editor/source-report';

import styles from 'components/common/form/data-fetch-command/CommandEditor.styles';

interface IProps {
    onChange: (command: string) => void;
    dataSource: string;
}

export default function CommandEditor({ onChange, dataSource }: IProps) {
    const [selectedTable, setSelectedTable] = useState('');
    const { t } = useBundleTranslation(['app/editor/dataset']);

    const { data: tablesList } = useQuery<[string, string][], Error>(
        ['fetchDatasetEditorElements_' + dataSource],
        () => {
            if (!dataSource) {
                return [];
            }
            return editorSourceReportAPI.getSourceReportElements(dataSource);
        }
    );

    const { data: columnsList, isFetching } = useQuery<Array<ElementColumn>, Error>(
        [`fetchDatasetEditorElementsColumns_${selectedTable}_${dataSource}`],
        () => {
            if (!selectedTable || !dataSource) {
                return [];
            }
            return editorSourceReportAPI.getSourceReportColumns(dataSource, selectedTable);
        }
    );

    const entries = useMemo(() => {
        setSelectedTable('');
        if (!tablesList) return [];

        // @ts-ignore
        return tablesList.map(([value, label]) => ({
            label,
            value,
        }));
    }, [tablesList]);

    const sourceType = useMemo(() => {
        const tmp = dataSource.split('_');
        return tmp?.[1] == 'sql' ? 'tables' : 'datasets';
    }, [dataSource]);

    return (
        <Card sx={styles.container}>
            <CardContent sx={styles.content}>
                <Box sx={styles.box}>
                    <Typography variant="body1" gutterBottom component="div">
                        {t(`available_${sourceType}`)}
                    </Typography>
                    <ReactSelect
                        placeholder={t(`${sourceType}_select_placeholder`)}
                        data={entries}
                        value={selectedTable}
                        update={setSelectedTable}
                    />
                </Box>

                {!isFetching ? (
                    <>
                        {selectedTable && (
                            <>
                                <Box sx={styles.box}>
                                    <>
                                        <Typography sx={styles.title} variant="body1" gutterBottom component="div">
                                            Tables
                                        </Typography>
                                        <ColumnsList list={[{ column_name: selectedTable }]} onChange={onChange} />
                                    </>
                                </Box>

                                <Box sx={{ ...styles.box, ...styles.scrollContainer }}>
                                    <>
                                        <Typography sx={styles.title} variant="body1" gutterBottom component="div">
                                            Columns
                                        </Typography>
                                        <ColumnsList list={columnsList ?? []} onChange={onChange} />
                                    </>
                                </Box>
                            </>
                        )}
                    </>
                ) : (
                    <Box sx={styles.loader}>
                        <CircularProgress size={20} />
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

function ColumnsList({ list, onChange }: { list: Array<ElementColumn>; onChange: (command: string) => void }) {
    const { t } = useBundleTranslation(['app/editor/dataset']);
    return (
        <>
            {list.map((item) => (
                <Box key={item.column_name} sx={{ display: 'flex', fontFamily: (theme) => theme.font.monospace }}>
                    <Tooltip title={`${t('click_to_add')}`}>
                        <Typography
                            onClick={() => onChange(item.column_name)}
                            sx={styles.command}
                            variant="body1"
                            component="div"
                        >
                            {item.column_name}
                        </Typography>
                    </Tooltip>
                    {item.data_type && (
                        <Typography sx={styles.type} variant="body1" component="div">
                            {item.data_type}
                        </Typography>
                    )}
                </Box>
            ))}
        </>
    );
}
