import { lazy, useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, Button, DialogActions, Stack } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import IconMi from 'components/common/icon/IconMi';
import { Link } from 'react-router-dom';
import AlertRuleList from 'components/alert-subscriptions/parts/AlertRuleList';
import { alertSubscriptionAPI } from 'api/api';
import SubscriptionList from 'components/alert-subscriptions/parts/SubscriptionList';
import { AlertRuleElementData } from 'components/alert-subscriptions/index';
import AddAlertMetric4ReportPopup from 'components/alert-rule-popup/AddAlertMetric4ReportPopup';
import IncludeInBurstPopup from 'components/distribution-list/IncludeInBurstPopup';
import styles from './AlertSubscriptionPopup.styles';
import { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';
import * as React from 'react';
const AlertRulePopup = lazy(() => import('components/alert-rule-popup/AlertRulePopup'));

interface wasChangeState {
    elementSubscription_isSubscribed: boolean;
    elementSubscription_subscribeType: boolean;
    elementSubscription_segments: boolean;
    elementSubscription_bookmarks: boolean;
    alertRuleList_isSubscribed: string[];
    alertRuleList_isSubscriptions: string[];
    alertRuleList_notifications: string[];
}

interface mainData {
    elementInfo: AlertRuleElementData;
    alsoSentVia: alsoSentData[];
    segments: any;
    alerts: any;
    default: any;
    options: any;
    notification_box_template: string;
    priv: any;
}

interface alsoSentData {
    id: number | string;
    name: string;
    notification_type: string;
    manage_content: string;
    enabled_ind: string;
}
export interface AlertSubscriptionPopupProps {
    onUpdateAlertStatusData?: (newData: any) => void;
    onClose: () => void;
    elementId: number;
    segmentId: number;
    reverseLink?: boolean;
    bookmarksContext?: IBookmarksContext;
}

//AlertRuleList2.openPopup
//engine/application/notification/views/scripts/alertrule/alert-rule-list-popup.phtml
export default function AlertSubscriptionPopup(props: AlertSubscriptionPopupProps) {
    const { onUpdateAlertStatusData = () => {}, onClose = () => {}, elementId, segmentId, reverseLink = false } = props;

    const { t } = useBundleTranslation(['components/alert-subscription/alert_subscription']);
    const [mainData, setMainData] = useState<mainData>();
    const [initialAlertRules, setInitialAlertRules] = useState<any>();
    const [subscriptionListData, setSubscriptionListData] = useState<any>();
    const [changesStateHolder, setChangesStateHolder] = useState<wasChangeState>({
        elementSubscription_isSubscribed: false,
        elementSubscription_subscribeType: false,
        elementSubscription_segments: false,
        elementSubscription_bookmarks: false,
        alertRuleList_isSubscribed: [],
        alertRuleList_isSubscriptions: [],
        alertRuleList_notifications: [],
    });
    const [settings, setSettings] = useState<any>({
        maxSegmentsInSegmentLine: 3,
    });

    const [showBurstPopup, setShowBurstPopup] = useState<boolean>(false);
    const [showAddMetricPopup, setShowAddMetricPopup] = useState<boolean>(false);
    const [showAlertWizardPopup, setShowAlertWizardPopup] = useState<boolean>(false);
    const [alertWizardPopupData, setAlertWizardPopupData] = useState<any>({});

    const getMainData = (reloadAlerts?: boolean) => {
        alertSubscriptionAPI
            .getData(elementId, segmentId)
            .then((response) => {
                if (response.statusText === 'OK') {
                    let data = response.data;
                    if (data.alerts) {
                        const alertsArray = [];
                        for (let id in data.alerts) {
                            if (data.alerts[id]?.notification?.send_alert_to_phone === 'Y') {
                                data.alerts[id].notification.send_notification_via = 'phone_alert';
                            }
                            alertsArray.push(data.alerts[id]);
                        }
                        data.alerts = alertsArray;
                    }
                    if (reloadAlerts) {
                        //update list after add new alert
                        if (mainData) {
                            const newMainData = {
                                ...mainData,
                                alerts: [...mainData.alerts, data.alerts[data.alerts.length - 1]],
                            };
                            setMainData(newMainData);
                            updateAlertStatus(newMainData.alerts);
                        }
                        setInitialAlertRules(data.alerts);
                    } else {
                        //initial load
                        setMainData(data);
                        setInitialAlertRules(data.alerts);
                    }
                }
            })
            .catch(() => {});
    };

    useEffect(() => {
        getMainData();
    }, []);

    if (!mainData) return null;

    const wasChanged = Object.values(changesStateHolder).some((state) => {
        return (Array.isArray(state) && state.length) || state === true;
        //ToDo make more flexible: example for notification if it not subscribed = ignore changes
    });
    const isMetric = !['internal report', 'external report', 'multi-metric chart'].includes(mainData.elementInfo.type);
    const popupTitleKey = isMetric
        ? 'metric'
        : mainData.elementInfo.type === 'multi-metric chart'
        ? 'multi_metric'
        : 'report';

    const isHasPrivilege = mainData.priv?.createEditBursts;
    const isHasPrivilegeAlertRule = mainData.priv?.createAlertRule;

    const isShowAlsoSentBlock =
        isHasPrivilege &&
        mainData.alsoSentVia.some((item) => {
            return item.manage_content === 'Y';
        });
    const isShowCreateBurstBlock = isHasPrivilege; /*&&
        !isShowAlsoSentBlock &&
        ['internal report', 'external report'].includes(mainData.elementInfo.type)*/ //extra part for class

    const pref_favoriteElements = (id: string | number, type: string) => {
        alert('pref_favoriteElements id:' + id);
    };

    const openAddToBurstPopup = () => {
        if (wasChanged) {
            if (!confirm(t('init_new_lost_data'))) return;
        }
        setShowBurstPopup(true);
    };

    const isSubscriptionListExist = mainData.segments.report === 'Y';

    const elementSubscriptionSave = () => {
        const data = {
            is_subscribed: subscriptionListData.subscriptionInfo.is_subscribed,
            subscription_type: subscriptionListData.subscriptionInfo.subscription_type,
            folder_id: subscriptionListData.subscriptionInfo.folder_id,
            segments: subscriptionListData.subscriptionInfo.segments.map((item: any) => item.id),
            bookmark_segment: mainData.elementInfo.segmentId,
            bookmarks: subscriptionListData.bookmarksInfo ?? [],
        };

        alertSubscriptionAPI
            .elementSubscriptionSaveData(mainData.elementInfo.elementId, mainData.elementInfo.segmentId, data)
            .then((response) => {
                if (response.data?.status === 'OK' && response.data?.folder_id > 0) {
                    onUpdateAlertStatusData({ folder_set_ind: response.data.folder_set_ind });
                    /* //Useless: folder_id setup
                        self.subscriptionInfo.folder_id = response.folder_id;
                    */
                }
            })
            .catch(() => {});
    };

    const getDataForSave = () => {
        return {
            alerts: mainData.alerts.map((alert: any) => {
                const getSelectedSegmentValues = alert.subscriptions
                    .filter((item: any) => {
                        return !(item.user_alert_rule == -1 && alert.subscribed == 'N');
                    })
                    .map((item: any) => Number(item.segment_value_id));

                const notificationFormatted = alert.notification;
                delete notificationFormatted.workflow_role_name;
                if (notificationFormatted.send_notification_via == 'phone_alert') {
                    notificationFormatted.send_notification_via = 'sms';
                    notificationFormatted.send_alert_to_phone = 'Y';
                } else {
                    notificationFormatted.send_alert_to_phone = 'N';
                }

                return {
                    id: alert.id,
                    element_id: alert.elementId,
                    subscribed: alert.subscribed,
                    segments: getSelectedSegmentValues,
                    notification: notificationFormatted, //AlertNotification.prototype.getFormattedForSave
                };
            }),
            element_id: mainData.elementInfo.elementId,
            segment_id: mainData.elementInfo.segmentId,
        };
    };

    const updateAlertStatus = (alertsData: any) => {
        const subscribedItems = alertsData.filter((alert: any) => alert.subscribed == 'Y');
        const amount = subscribedItems.length;
        const isSubscribed = amount > 0;
        onUpdateAlertStatusData({
            send: isSubscribed ? 'Y' : 'N',
            digest_count: amount,
            immediate_count: amount,
        });
    };
    const saveChanges = () => {
        const data = getDataForSave();
        if (isSubscriptionListExist) elementSubscriptionSave();

        alertSubscriptionAPI
            .saveData(data)
            .then((response) => {
                if (response.data?.status === 'OK') {
                    //ToDo Update external dependencies after save
                    /*
                    mask.hide();
                    alertRule.reloadWall();

                    self.popup.close();
                    self.cleanUpInstance();
                    init.reloadTopMenuIssues();
                    */

                    updateAlertStatus(mainData.alerts);
                    onClose();
                }
            })
            .catch(() => {});
    };

    const wasChangedUpdate = (dataUpdated: any) => {
        setChangesStateHolder({
            ...changesStateHolder,
            ...dataUpdated,
        });
    };

    const getInitialAlert = (id: number) => {
        return initialAlertRules.find((item: any) => item.id == id);
    };

    const updateArrayChangesStateHolder = (alertRule: any, type: string) => {
        const initial = getInitialAlert(alertRule.id);
        let key = '';
        let isChanged = true;
        switch (type) {
            case 'subscribed':
                key = 'alertRuleList_isSubscribed';
                isChanged = initial.subscribed != alertRule.subscribed;
                break;
            case 'subscriptions':
                key = 'alertRuleList_isSubscriptions';
                //different count = changed
                isChanged = initial.subscriptions.length != alertRule.subscriptions.length;
                if (!isChanged) {
                    //same count => check every item by id. if one of id don't find then changed
                    isChanged = initial.subscriptions.some((item: any) => {
                        return !alertRule.subscriptions.find((newItem: any) => newItem.id === item.id);
                    });
                }
                break;
            case 'notification':
                key = 'alertRuleList_notifications';
                isChanged = Object.keys(initial.notification).some(
                    (propertyKey) => initial.notification[propertyKey] !== alertRule.notification[propertyKey]
                );
                break;
        }

        // @ts-ignore
        const currentStateArray = changesStateHolder[key];
        const indexInState = currentStateArray.indexOf(alertRule.id);
        const isExist = indexInState != -1;

        if (isChanged && !isExist) {
            //add to state
            currentStateArray.push(alertRule.id);
        } else if (!isChanged && isExist) {
            //remove from state
            currentStateArray.splice(indexInState, 1);
        }
        setChangesStateHolder({ ...changesStateHolder, [key]: currentStateArray });
    };
    const updateAlertItemsData = (newAlertRuleItemsData: any) => {
        const newItems = Array.isArray(newAlertRuleItemsData) ? newAlertRuleItemsData : [newAlertRuleItemsData];

        const newAlerts = mainData.alerts.map((item: any) => {
            const newDataForThisAlert = newItems.find((newItem) => newItem.id === item.id);
            return newDataForThisAlert ?? item;
        });

        setMainData({ ...mainData, alerts: newAlerts });
    };

    const addNewRuleAction = () => {
        if (isSubscriptionListExist) {
            setShowAddMetricPopup(true);
        } else {
            setAlertWizardPopupData({
                elementId: mainData.elementInfo.elementId,
                segmentValueId: mainData.elementInfo.segmentId,
                visualizationElementId: mainData.elementInfo.elementId,
                //userId: el.data('user_id')
            });
            setShowAlertWizardPopup(true);
        }
    };
    const dataTestAttr = 'alert_subscription_popup';
    const dialogActions = (
        <DialogActions sx={{ justifyContent: 'normal', py: 2, px: 3, flexWrap: 'wrap' }}>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                <Stack direction={'row'} spacing={2}>
                    <Button
                        variant={'contained'}
                        onClick={saveChanges}
                        disabled={!wasChanged}
                        data-test={dataTestAttr + '_ok_button'}
                    >
                        {t('save')}
                    </Button>
                    <Button
                        variant={'outlined'}
                        onClick={onClose}
                        color={'neutral'}
                        data-test={dataTestAttr + '_cancel_button'}
                    >
                        {t('cancel')}
                    </Button>
                </Stack>
                {isShowCreateBurstBlock && (
                    <Button
                        className="dAddToBurst"
                        onClick={() => {
                            openAddToBurstPopup();
                        }}
                        variant={'outlined'}
                        startIcon={<IconMi icon={'burst'} />}
                        data-test={dataTestAttr + '_send_via_burst_button'}
                    >
                        {t('link_send_via_burst')}
                    </Button>
                )}
            </Stack>
        </DialogActions>
    );

    return (
        <Box>
            <Popup
                settings={{
                    title: t(`popup_title.${popupTitleKey}`) + (isMetric ? ` ${mainData.elementInfo.name}` : ''),
                    customActionPanel: dialogActions,
                    uid: dataTestAttr,
                }}
                maxWidth="popupMd"
                okDisabled={!wasChanged}
                open={true}
                onHide={onClose}
                onConfirm={saveChanges}
            >
                {!isMetric && (
                    <>
                        {isSubscriptionListExist && (
                            <SubscriptionList
                                elementInfo={mainData.elementInfo}
                                maxSegmentsInSegmentLine={settings.maxSegmentsInSegmentLine}
                                onChangeState={wasChangedUpdate}
                                onUpdateData={(data) => {
                                    setSubscriptionListData(data);
                                }}
                                bookmarksContext={props.bookmarksContext}
                            />
                        )}
                    </>
                )}
                <Box className="dAlertRuleListWrapper">
                    <AlertRuleList
                        data={mainData}
                        maxSegmentsInSegmentLine={settings.maxSegmentsInSegmentLine}
                        reverseLink={reverseLink}
                        clickAddNewRule={addNewRuleAction}
                        onUpdateAlertRuleData={(alertRule, type) => {
                            updateArrayChangesStateHolder(alertRule, type);
                            updateAlertItemsData(alertRule);
                        }}
                        onNotificationChange={(notificationData, alertRule) => {
                            const singleChange = alertRule;

                            if (singleChange) {
                                const alertRuleUpdated = { ...alertRule, notification: notificationData };
                                updateArrayChangesStateHolder(alertRuleUpdated, 'notification');
                                updateAlertItemsData(alertRuleUpdated);
                            } else {
                                delete notificationData.workflow_role_name; //ignore this field. Maybe we should leave previous data
                                const newAlertsData = mainData.alerts
                                    .filter((alert: any) => alert.subscribed == 'Y')
                                    .map((alert: any) => {
                                        const alertRuleUpdated = { ...alert, notification: notificationData };
                                        updateArrayChangesStateHolder(alertRuleUpdated, 'notification');
                                        return alertRuleUpdated;
                                    });
                                updateAlertItemsData(newAlertsData);
                            }
                        }}
                    />
                </Box>
                <Box sx={{ overflow: 'hidden' }}>
                    {isHasPrivilegeAlertRule &&
                        ['internal report', 'external report'].includes(mainData.elementInfo.type) && (
                            <Button
                                sx={styles.addRuleBasedLink}
                                variant={'outlined'}
                                color={'neutral'}
                                onClick={(event) => {
                                    event.preventDefault();
                                    addNewRuleAction();
                                }}
                            >
                                {t('link_add_metric_based_alert_rule')}
                            </Button>
                        )}
                    {isShowAlsoSentBlock && (
                        <Box id="alsoSentVia" sx={styles.alsoSentBlock}>
                            {t('also_sent_in')}{' '}
                            {mainData.alsoSentVia
                                .filter((item) => item.manage_content === 'Y')
                                .map((item, index) => {
                                    const separator = index ? ', ' : '';
                                    const disabled = item.enabled_ind === 'N';
                                    const disabledLabel = disabled ? ` (${t('also_sent_in_disabled')})` : '';
                                    if ('Burst' === item.notification_type) {
                                        return (
                                            <Box
                                                component="span"
                                                key={item.id}
                                                sx={{
                                                    ...styles.alsoSentItem,
                                                    color: disabled ? 'text.secondary' : undefined,
                                                }}
                                            >
                                                {separator}
                                                <Box
                                                    component={Link}
                                                    sx={styles.alsoSentItemLink}
                                                    to={`/notification/burst/${item.id}`}
                                                    className={'invert-link'}
                                                >
                                                    {item.name}
                                                </Box>
                                                {disabledLabel}
                                            </Box>
                                        );
                                    } else {
                                        return (
                                            <Box
                                                component="span"
                                                key={item.id}
                                                sx={{
                                                    ...styles.alsoSentItem,
                                                    color: disabled ? 'text.secondary' : undefined,
                                                }}
                                            >
                                                {separator}
                                                <Box
                                                    component="span"
                                                    sx={styles.alsoSentItemLink}
                                                    onClick={() => {
                                                        pref_favoriteElements(item.id, 'custom');
                                                    }}
                                                >
                                                    {item.name}
                                                </Box>
                                                {disabledLabel}
                                            </Box>
                                        );
                                    }
                                })}
                        </Box>
                    )}
                </Box>
            </Popup>
            {showBurstPopup && (
                <IncludeInBurstPopup
                    onClose={() => {
                        setShowBurstPopup(false);
                    }}
                    elementId={mainData.elementInfo.elementId}
                    segmentId={mainData.elementInfo.segmentId}
                />
            )}
            {showAddMetricPopup && (
                <AddAlertMetric4ReportPopup
                    onClose={() => {
                        setShowAddMetricPopup(false);
                    }}
                    onApply={(metricId) => {
                        setAlertWizardPopupData({
                            elementId: Number(metricId),
                            reportId: mainData.elementInfo.elementId,
                            segmentValueId: -1,
                            //userId: params.user
                        });
                        setShowAlertWizardPopup(true);
                        setShowAddMetricPopup(false);
                    }}
                    reportId={mainData.elementInfo.elementId}
                />
            )}

            {showAlertWizardPopup && (
                <AlertRulePopup
                    elementId={alertWizardPopupData.elementId ?? undefined}
                    reportId={alertWizardPopupData.reportId ?? undefined}
                    segmentValueId={alertWizardPopupData.segmentValueId ?? undefined}
                    visualizationElementId={alertWizardPopupData.visualizationElementId ?? undefined}
                    onClose={() => {
                        setShowAlertWizardPopup(false);
                    }}
                    onFinish={(response) => {
                        if (response.data.status == 'OK') {
                            getMainData(true);
                        }
                        setShowAlertWizardPopup(false);
                    }}
                    show={true}
                />
            )}
        </Box>
    );
}

//ToDo Save button with icon popup_btn_save
