import React, { useEffect, useMemo, useState } from 'react';
import { Button, Box, FormControl, FormLabel, Input } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import { TFunction } from 'react-i18next';
import StaticInfo from 'components/common/StaticInfo';

export interface JDBCStringProps {
    timezone: string;
    template: string;
    tzTemplate: string | null;
    host: string;
    port: string;
    db: string;
    currentJDBCString: string;
    prevJDBCString: string;
    setCurrentJDBCString: (value: string) => void;
    setPrevJDBCString: (value: string) => void;
    isDefault: boolean;
    t: TFunction;
}

const JDBCString = ({
    timezone,
    template,
    tzTemplate,
    t,
    host,
    port,
    db,
    currentJDBCString,
    setCurrentJDBCString,
    prevJDBCString,
    setPrevJDBCString,
    isDefault,
}: JDBCStringProps) => {
    const [isRestored, toggleRestored] = useState(false);

    const buildJDBCString = (): string => {
        if (template) {
            let jdbcString = template.replace('%h', host).replace('%p', port).replace('%d', db);

            if (tzTemplate && timezone != '') {
                let tzString = tzTemplate.replace('%tz', timezone);
                if (jdbcString.includes('?') && tzString.includes('?')) {
                    tzString = tzString.replace('?', '&');
                }
                jdbcString += tzString;
            }

            return jdbcString;
        }

        return '';
    };

    const [userPostfix, setUserPostfix] = useState(currentJDBCString.substring(buildJDBCString().length));

    useEffect(() => {
        const userPostfix = currentJDBCString.substring(buildJDBCString().length);

        if (isJdbcStringIncorrect) {
            setUserPostfix('');
        } else {
            setUserPostfix(userPostfix);
        }
    }, [currentJDBCString]);

    const isJdbcStringIncorrect = useMemo(() => {
        const calcJDBC = buildJDBCString();
        const currentJDBC = currentJDBCString.substring(0, calcJDBC.length);

        return calcJDBC !== currentJDBC;
    }, [currentJDBCString]);

    useEffect(() => {
        if (template > '') {
            const jdbc = buildJDBCString();

            if (currentJDBCString === '' || !isJdbcStringIncorrect) {
                setCurrentJDBCString(jdbc + userPostfix);
            }
        }
    }, [port, db, host, template, timezone]);

    const resetOptions = () => {
        toggleRestored(true);
        setPrevJDBCString(currentJDBCString);
        setCurrentJDBCString(buildJDBCString());
    };

    return (
        <>
            <FormControl fullWidth>
                <FormLabel>{t('jdbc_string')}</FormLabel>
                <Box sx={{ flexGrow: 1 }}>
                    <Input
                        disabled={isDefault}
                        multiline
                        name={'jdbc_string'}
                        value={currentJDBCString}
                        type={'text'}
                        onChange={(event) => {
                            setCurrentJDBCString(event.target.value);
                        }}
                        data-test={'jdbc_string'}
                        fullWidth
                    />
                </Box>
            </FormControl>

            {isRestored && !isJdbcStringIncorrect && prevJDBCString.length > 0 && (
                <Box sx={{ marginTop: (theme) => theme.size.pxValue(theme.size.componentFormIndent) }}>
                    <StaticInfo label={t('jdbc_restore_hint', { value: prevJDBCString })} />
                </Box>
            )}

            {isJdbcStringIncorrect && (
                <Box sx={{ marginTop: (theme) => theme.size.pxValue(theme.size.componentFormIndent) }}>
                    <StaticInfo label={t('jdbc_hint')} />
                </Box>
            )}

            <Box sx={{ marginTop: (theme) => theme.size.pxValue(theme.size.componentFormIndent) }}>
                <Button
                    onClick={resetOptions}
                    variant="outlined"
                    startIcon={<IconMi icon={'undo'} />}
                    disabled={isDefault || !isJdbcStringIncorrect}
                >
                    {t('reset_to_default')}
                </Button>
            </Box>
        </>
    );
};

export default JDBCString;
