import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, CellMeasurerCache, InfiniteLoader, List, ListRowProps } from 'react-virtualized';
import { ReportContentNS } from 'components/report-content/index';
import DataRowType = ReportContentNS.DataRowType;
import DatasetLine from 'components/report-content/components/dataset/parts/DatasetLine';
import ComponentProps = ReportContentNS.ComponentProps;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import { Box } from '@mui/material';
import DatasetBreakLine from 'components/report-content/components/dataset/parts/DatasetBreakLine';
import { DatasetCellValue } from 'components/report-content/components/dataset/index';
import { getFontSizeStyle } from 'components/report-content/utils/tools';

const DefaultHeight = 11;

// Break Line
// Get previous and current Break Value
// If different - add line
export function getDatasetBreakLine(settings: ComponentSettingsDataset, rows: Array<DataRowType>, index: number) {
    const getBreakValue = (row: DataRowType) => {
        if (!settings.breakColumns.length || !row) {
            return '';
        }
        const result: Array<string | number | null> = [];
        settings.fields
            .filter((f) => f.break_column == 'Y')
            .forEach((field) => {
                result.push(row[field.reference_name + '-formatted'] ?? row[field.reference_name]);
            });
        return result.join(' | ');
    };

    let prevBreakValue = '';
    let currentBreakValue = '';
    if (settings.breakArea) {
        prevBreakValue = index > 0 ? getBreakValue(rows[index - 1]) : '';
        currentBreakValue = getBreakValue(rows[index]);
    }
    return { prevBreakValue, currentBreakValue };
}

function getRowValue(row: DataRowType | false, reference: string) {
    return row ? row[reference + '-formatted'] ?? row[reference] : '';
}

export default function DatasetBody({
    totals,
    totalRows,
    contentSettings,
    component,
    rows,
    cellStyler,
    onPageChange,
    onHorizontalScroll,
    updateRealWidth,
    actions,
    onTopBreakLineChange,
}: ComponentProps<ComponentSettingsDataset> & {
    totals: DataRowType;
    totalRows: number;
    rows: Array<DataRowType>;
    cellStyler: CellStyler;
    onPageChange: (page: number) => Promise<any> | void;
    onHorizontalScroll: (newPosition: number) => void;
    updateRealWidth: (width: number) => void;
    onTopBreakLineChange: (index: number, offset: number) => void;
}) {
    const addOneRowForTotals = component.settings.show_totals == 'Y' ? 1 : 0;
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const listRef = useRef(null);

    const fullRowHeight = DefaultHeight + Number(component.settings.mainFontSize ?? 12);

    const cache = useMemo(() => {
        return new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: fullRowHeight,
        });
    }, []);

    const syncBreakLines = () => {
        if (!component.settings.breakArea) {
            return;
        }
        //@ts-ignore
        const breakLines: HTMLCollectionOf<HTMLDivElement> =
            wrapperRef.current?.getElementsByClassName('dataset-renderer-break-line') ?? [];
        for (let i = 0; i < breakLines.length; i++) {
            //@ts-ignore
            const offsetTop =
                breakLines[i].closest('.dataset-renderer-virtual-line-wrapper')?.getBoundingClientRect().top ?? 0;

            const wrapperTop = wrapperRef.current?.getBoundingClientRect().top ?? 0;
            const index = Number(breakLines[i].dataset.break ?? 0);

            if (offsetTop - wrapperTop + fullRowHeight >= 0) {
                onTopBreakLineChange(index, offsetTop - wrapperTop + fullRowHeight);
                break;
            }
        }
    };

    const [evenMap, setEvenMap] = useState<Array<boolean>>([]);
    const evenMapRef = useRef(evenMap);
    useEffect(() => {
        evenMapRef.current = evenMap;
    }, [evenMap]);

    const rowRenderer = ({ key, style, index }: ListRowProps) => {
        const isTotalLine = Boolean(addOneRowForTotals && totalRows == index);

        // Trigger clientWidth change for Dataset Inner Block
        // Used for Columns autoStretch
        setTimeout(() => {
            const width = (wrapperRef.current?.firstChild as HTMLElement)?.clientWidth ?? 0;
            if (width != 0) {
                updateRealWidth(width);
            }
        }, 0);

        const breakLine = getDatasetBreakLine(component.settings, rows, index);
        setTimeout(() => {
            if (!wrapperRef.current) {
                return;
            }
            let maxHeight = fullRowHeight;
            const cellList =
                wrapperRef.current?.querySelector(`[data-rowkey="${key}"]`)?.getElementsByClassName('wrapper-cell') ??
                [];
            for (let i = 0; i < cellList.length; i++) {
                if (cellList[i].clientHeight > maxHeight) {
                    maxHeight = cellList[i].clientHeight;
                }
            }
            // Add height for Break Line
            if (breakLine.currentBreakValue != breakLine.prevBreakValue && index != 0) {
                // -1 to fix gap between lines
                maxHeight += fullRowHeight - 1;
            }
            // syncBreakLines();

            if (cache.rowHeight({ index: index }) != maxHeight) {
                cache.set(index, 0, 0, maxHeight);
                // @ts-ignore
                listRef.current.recomputeRowHeights(index);
            }
        }, 0);
        delete style.width;

        const breakLineWidth = component.settings.currentInnerWidth ?? 1128;
        // Prepare Cells List for Line
        const cellList: Array<DatasetCellValue> = [];
        const row = isTotalLine ? totals : rows[index];
        component.settings.fields
            .filter((field) => field.show_column_in_table_display_ind == 'Y' && field.break_column != 'Y')
            .forEach((field, j) => {
                let v = getRowValue(row, field.reference_name);
                if (isTotalLine && field.compute_result_total_ind == 'N') {
                    v = '';
                }
                v = isTotalLine && j == 0 ? component.settings.totals_label + ': ' + (v ?? '') : v;

                let hiddenByGrouping = false;
                let isEven = index % 2 == 0;
                // Calculate grouping columns
                if (component.settings.groupingArea) {
                    if (field.group_column == 'Y') {
                        if (index != 0) {
                            const prevRow = rows[index - 1];
                            if (getRowValue(prevRow, field.reference_name) == v) {
                                hiddenByGrouping = true;
                            }
                        }
                    }
                    if (cellList.length > 0) {
                        const prevCell = cellList[cellList.length - 1];
                        if (!prevCell.hidden) {
                            hiddenByGrouping = false;
                        }
                    } else {
                        // Has break column
                        if (breakLine.currentBreakValue != breakLine.prevBreakValue) {
                            hiddenByGrouping = false;
                        }
                    }
                    // Calculate isEven
                    if (cellList.length == 0 && field.group_column == 'Y') {
                        if (index > 0 && typeof evenMapRef.current[index - 1] != undefined) {
                            if (hiddenByGrouping) {
                                isEven = evenMapRef.current[index - 1];
                            } else {
                                isEven = !evenMapRef.current[index - 1];
                            }
                        } else {
                            isEven = true;
                        }
                    }
                }
                if (cellList.length == 0) {
                    evenMapRef.current[index] = isEven;
                }

                cellList.push({
                    field: field,
                    value: v,
                    hidden: hiddenByGrouping,
                });
            });

        const isEven = evenMapRef.current?.[index] ?? false;
        return (
            <div className={'dataset-renderer-virtual-line-wrapper'} style={style} key={key}>
                {breakLine.prevBreakValue != breakLine.currentBreakValue && index != 0 && (
                    <div style={{ position: 'sticky', width: `${breakLineWidth}px`, left: 0 }}>
                        <DatasetBreakLine
                            index={index}
                            component={component}
                            row={rows[index]}
                            cellStyler={cellStyler}
                            text={breakLine.currentBreakValue}
                        />
                    </div>
                )}
                <div
                    className={'block-wrapper-body-line dataset-renderer-data-line' + (isEven ? ' even' : '')}
                    data-rowkey={key}
                >
                    <DatasetLine
                        cellList={cellList}
                        isEven={isEven}
                        isTotalLine={isTotalLine}
                        component={component}
                        contentSettings={contentSettings}
                        cellStyler={cellStyler}
                        actions={actions}
                        row={row}
                        index={index}
                    />
                </div>
            </div>
        );
    };

    const isRowLoaded = ({ index }: { index: number }) => !!rows[index];
    const loadMoreRows = ({ startIndex }: { startIndex: number }) => {
        // TODO: not work in proper way
        const pageToLoad = Math.floor(startIndex / contentSettings.pageSize);
        return onPageChange(pageToLoad);
    };

    const listElementId = 'DatasetListComponent_' + component.datasetRendererBlockComponentId;

    const handleScroll = () => {
        const el = document.getElementById(listElementId);
        if (!el) {
            return;
        }
        onHorizontalScroll(el.scrollLeft);
    };

    const fontStyle = getFontSizeStyle(component.settings);
    return (
        <Box
            sx={{ fontStyle }}
            style={{
                overflowX: 'auto',
                maxHeight: component.settings.height + 'px',
            }}
        >
            <InfiniteLoader
                isRowLoaded={isRowLoaded}
                /*@ts-ignore*/
                loadMoreRows={loadMoreRows}
                rowCount={totalRows + addOneRowForTotals}
            >
                {({ onRowsRendered }) => {
                    return (
                        // @ts-ignore
                        <AutoSizer disableHeight defaultWidth={1200}>
                            {({ width }) => {
                                const el = document.getElementById(listElementId);
                                if (el && el.getAttribute('scroll-event-attached') != 'true') {
                                    el.setAttribute('scroll-event-attached', 'true');
                                    el.addEventListener('scroll', handleScroll);
                                }
                                return (
                                    <Box ref={wrapperRef} className={'report-data-body report-data-body--dataset'}>
                                        {/*@ts-ignore*/}
                                        <List
                                            className={'react-virtualized--fix-scroll'}
                                            id={listElementId}
                                            // className={styles.List}
                                            height={Number(component.settings.height)}
                                            autoHeight={totalRows + addOneRowForTotals < 80}
                                            overscanRowCount={50}
                                            onRowsRendered={(info) => {
                                                onRowsRendered(info);
                                                syncBreakLines();
                                            }}
                                            // ref={registerChild}
                                            ref={listRef}
                                            rowCount={totalRows + addOneRowForTotals}
                                            rowHeight={(index) => cache.rowHeight(index)}
                                            rowRenderer={rowRenderer}
                                            // scrollToIndex={scrollToIndex}
                                            width={width}
                                            onScroll={syncBreakLines}
                                        />
                                    </Box>
                                );
                            }}
                        </AutoSizer>
                    );
                }}
            </InfiniteLoader>
        </Box>
    );
}
