import React from 'react';
import { Box, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import styles from './SiblingTabsMenu.styles';

export interface SiblingTabType {
    referenceId: number;
    title: string;
}

export default function SiblingTabsMenu({
    tabs,
    selectedReferenceId,
    onTabSelect,
    isOpen,
    onMenuToggle,
}: {
    tabs: Array<SiblingTabType>;
    selectedReferenceId: number;
    isOpen: boolean;
    onTabSelect: (tabId: number) => void;
    onMenuToggle: () => void;
}) {
    if (tabs.length == 0) {
        return null;
    }

    return (
        <>
            {isOpen ? (
                <Box sx={styles.openedContainer}>
                    <Stack sx={styles.listHolder}>
                        <Stack direction="row" alignItems="center" onClick={onMenuToggle} sx={styles.itemList}>
                            <IconMi icon="arrow-right" sx={styles.collapseIcon} />
                        </Stack>

                        {tabs.map((tab) => {
                            return (
                                <Box
                                    key={tab.referenceId}
                                    sx={styles.itemList}
                                    className={tab.referenceId == selectedReferenceId ? 'active' : ''}
                                    onClick={() => onTabSelect(tab.referenceId)}
                                >
                                    {tab.title}
                                </Box>
                            );
                        })}
                    </Stack>
                </Box>
            ) : (
                <Box onClick={onMenuToggle} sx={styles.expandBtnHolder}>
                    <Box sx={styles.expandBtn}>
                        <IconMi icon="arrow-right" />
                    </Box>
                </Box>
            )}
        </>
    );
}
