import { Box, Checkbox, Grid, Stack, Theme, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { getIcon } from 'components/common/grid/cell';
import React, { useMemo } from 'react';
import { MassActionsProps } from 'components/common/grid';
import { SectionsData, TopicType } from './index';
import IconMi from 'components/common/icon/IconMi';
import styles from './styles';
import Owner from './Owner';
import ViewAll from './ViewAll';
import { alpha } from '@mui/material/styles';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import isOwnerVisible from 'tools/owners';

export default function Topic({
    sectionData,
    topicData,
    massActionsProps,
}: {
    sectionData: SectionsData;
    topicData: TopicType;
    massActionsProps?: MassActionsProps;
}) {
    const { configuration }: UserAuth = useAppSelector((state) => state.auth);

    const rowChecked = useMemo(() => {
        if (massActionsProps && massActionsProps.checkedRowsKeys) {
            return massActionsProps.checkedRowsKeys.some((item) => item == topicData.topicId);
        }

        return false;
    }, [massActionsProps, topicData.topicId]);

    const showOwners = useMemo(() => {
        return (
            (isOwnerVisible(configuration.owners.business_owner, 'glossary') ||
                isOwnerVisible(configuration.owners.technical_owner, 'glossary') ||
                isOwnerVisible(configuration.owners.data_steward, 'glossary')) &&
            (topicData.boUser !== '' || topicData.toUser !== '' || topicData.dsUser !== '')
        );
    }, [configuration]);

    return (
        <Grid
            container
            direction={'row'}
            sx={{
                borderBottom: '1px solid',
                borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: rowChecked ? (theme: Theme) => `${alpha(theme.palette.primary.main, 0.08)}` : '',
                borderLeft: rowChecked ? (theme: Theme) => `1px solid ${theme.palette.primary.main}` : '',
            }}
        >
            <Grid item xs={5} container direction={'row'} flexWrap={'nowrap'}>
                {massActionsProps && (
                    <Grid
                        item
                        sx={{ height: 1, alignItems: 'center', justifyContent: 'right' }}
                        className={'checkbox-holder'}
                    >
                        <Checkbox
                            data-test={`grid_glossary_expanded_checkbox_column_check_all`}
                            sx={{ padding: '5px 10px 5px 5px' }}
                            onChange={massActionsProps.handleCheckRow}
                            value={topicData.topicId}
                            checked={rowChecked}
                        />
                    </Grid>
                )}
                <Grid item sx={{ alignItems: 'center' }}>
                    <Stack spacing={1} sx={{ pr: 1.5 }}>
                        <Typography noWrap sx={{ paddingTop: '5px' }}>
                            <Box
                                component={Link}
                                to={topicData.href}
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    fontWeight: '600',
                                }}
                            >
                                {sectionData.icon && getIcon(sectionData.icon, { mr: 0.5 })}
                                {topicData.text}
                            </Box>
                        </Typography>
                        <Typography>{topicData.definition}</Typography>
                        <Typography>{topicData.description}</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid item container xs={4}>
                {showOwners && (
                    <Box sx={styles.flexStartWrapper}>
                        <Typography fontSize={14} width={'16px'}>
                            <IconMi icon={'user'} />
                        </Typography>
                        <Stack direction={'column'}>
                            {isOwnerVisible(configuration.owners.business_owner, 'glossary') && (
                                <Owner
                                    type={configuration.owners.business_owner.label}
                                    name={topicData.boUser}
                                    email={topicData.boUserEmail}
                                />
                            )}
                            {isOwnerVisible(configuration.owners.technical_owner, 'glossary') && (
                                <Owner
                                    type={configuration.owners.technical_owner.label}
                                    name={topicData.toUser}
                                    email={topicData.toUserEmail}
                                />
                            )}
                            {isOwnerVisible(configuration.owners.data_steward, 'glossary') && (
                                <Owner
                                    type={configuration.owners.data_steward.label}
                                    name={topicData.dsUser}
                                    email={topicData.dsUserEmail}
                                />
                            )}
                        </Stack>
                    </Box>
                )}
            </Grid>
            <Grid item xs={3}>
                <ViewAll sectionName={sectionData.text} topicData={topicData} />
            </Grid>
        </Grid>
    );
}
