import React, { lazy, useEffect, useMemo, useState } from 'react';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
const VisNetwork = lazy(() => import('./VisNetwork'));
import {
    getLineageType,
    LineageData,
    LineageNode,
    LineageProps,
    NodesInfoObject,
    Orientation,
    RootNode,
} from './index';
import { lineageAPI } from 'api/lineage';
import { getLineageDiagramConfig } from './config';
import { LinageDataProcessor } from './LinageDataProcessor';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { IdType } from 'vis-network/declarations/network/Network';
import { alpha, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getImageByType, getPluginIcon } from './Icons';
import useBundleTranslation from 'i18n';
import { AssocArray } from 'tools/types';

const Lineage = ({ showLineage, onHide, id, type }: LineageProps) => {
    const appTheme = useTheme();
    const { t } = useBundleTranslation('components/common/lineage/lineage');

    const popupSettings: PopupSettings = {
        noButtons: true,
        title: t('lineage'),
        customMaxWidth: '100%',
    };

    const dataProcessor = new LinageDataProcessor();

    const [nodesInfo, setNodesInfo] = useState<NodesInfoObject>({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<LineageData | null>(null);
    const [processedNodes, setProcessedNodes] = useState<LineageNode[]>([]);
    const [showLegend, setShowLegend] = useState(false);
    const [orientation, setOrientation] = useState<Orientation>('UD');
    const [rawData, setRawData] = useState<LineageData>();
    const [maxTotalNodes, setMaxTotalNodes] = useState<number>(0);
    const [externalIcons, setExternalIcons] = useState<AssocArray<string>>({});
    const [previousNode, setPreviousNode] = useState<RootNode | null>(null);

    const focusThisNode = (nodeId: IdType, nodeType: string) => {
        dataProcessor.clearData();
        setLoading(true);

        lineageAPI.getDiagramConfig(getLineageType(nodeType), nodeId.toString()).then((response) => {
            setLoading(false);
            setRawData(response.data);
        });
    };

    const getStyledNodes = (processedNodes: LineageNode[]) => {
        return processedNodes.map((node) => {
            if (node.params.type === 'root') {
                node.shapeProperties = { borderDashes: [7, 3] };
                node.borderWidth = 2;
            }

            if (node.params.certified) {
                node.color = {
                    border: alpha(appTheme.palette.success.main, 0.5),
                    hover: {
                        background: '#E8F0DE',
                        border: appTheme.palette.success.main,
                    },
                    highlight: {
                        background: '#E8F0DE',
                        border: appTheme.palette.success.main,
                    },
                };
                node.font = {
                    color: appTheme.palette.success.main,
                };
                node.image = getImageByType(node.group, false, appTheme.palette.success.main);
            }

            if (node.params.type === 'collapsed') {
                node.imagePadding = 2;
                node.image = getImageByType(node.group, true, appTheme.palette.primary.main);
            }

            if ((node.group === 'plugin' || node.group === 'external-dataset') && node.params.pluginName) {
                const icon = getPluginIcon(node.params.pluginName, externalIcons);

                if (icon) {
                    node.image = icon as string;
                }
            }

            return node;
        });
    };

    useEffect(() => {
        if (rawData) {
            dataProcessor.prepareData(rawData);
            const { processedNodes, filteredEdges, nodesInfo } = dataProcessor.getData();
            const processedStyledNodes = getStyledNodes(processedNodes);
            setProcessedNodes(processedStyledNodes);
            setData({ nodes: processedStyledNodes, edges: filteredEdges });
            setNodesInfo(nodesInfo);
        }

        setLoading(false);
    }, [rawData]);

    useEffect(() => {
        setData(null);

        if (showLineage) {
            setLoading(true);

            lineageAPI
                .drawLineageDiagram(getLineageType(type), id)
                .then((response) => {
                    setOrientation(response.data.lineageDiagramConfig.direction);
                    setShowLegend(response.data.lineageDiagramConfig.showSetting);
                    setRawData(response.data.lineageDiagramData);
                    setMaxTotalNodes(response.data.maxTotalNodes);
                    setExternalIcons(response.data.externalIcons);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [showLineage]);

    const options = getLineageDiagramConfig(appTheme);

    const onlyOneNodePresent = useMemo(() => {
        return processedNodes.length === 1;
    }, [processedNodes.length]);

    const saveConfig = (newOrientation: Orientation, newShowLegend: boolean) => {
        lineageAPI.saveDiagramConfig({
            direction: newOrientation,
            showSetting: newShowLegend,
        });
    };

    const onOrientationChange = (newOrientation: Orientation) => {
        setOrientation(newOrientation);
        saveConfig(newOrientation, showLegend);
    };

    const onShowLegendChange = (newShowLegend: boolean) => {
        setShowLegend(newShowLegend);
        saveConfig(orientation, newShowLegend);
    };

    return showLineage ? (
        <Popup
            settings={popupSettings}
            open={showLineage}
            onHide={onHide}
            scrollType={'paper'}
            className={'linagePopup'}
            sx={{
                '&.linagePopup .MuiDialog-paperScrollPaper': {
                    height: '100%',
                    minHeight: '350px',
                },
                '&.linagePopup .MuiDialogContent-root': {
                    overflowY: 'auto',
                },
            }}
        >
            {loading && <LoadingPlaceholder />}
            {!loading && data && !onlyOneNodePresent && (
                <Box sx={{ py: 1, height: '100%' }}>
                    <VisNetwork
                        data={data}
                        options={options}
                        nodesInfo={nodesInfo}
                        initNodes={processedNodes}
                        showLegend={showLegend}
                        orientation={orientation}
                        setShowLegend={onShowLegendChange}
                        setOrientation={onOrientationChange}
                        focusThisNode={focusThisNode}
                        maxTotalNodes={maxTotalNodes}
                        externalIcons={externalIcons}
                        previousNode={previousNode}
                        setPreviousNode={setPreviousNode}
                    />
                </Box>
            )}
            {!loading && data && onlyOneNodePresent && (
                <Box sx={{ height: 600 }}>
                    <Typography>{t('not_available')}</Typography>
                </Box>
            )}
        </Popup>
    ) : null;
};

export default Lineage;
