import { useMemo, useState } from 'react';
import { openInNewTab } from 'tools/tools';

export interface Topic {
    name: string;
    topicId: number;
}

export interface ElementTopicSection {
    faIcon: string | null;
    icon: string | null;
    iconType: string | null;
    topicTypeName: string;
    topics: Array<Topic>;
    useIcon: string | null;
    isDefault: boolean;
}

export interface ElementTopicsDataType {
    [key: string]: ElementTopicSection;
}

export function useTopicsLink(topicsList: Array<Topic>) {
    const [showTermsPopup, setShowTermsPopup] = useState(false);
    const [selectedTopicId, setSelectedTopicId] = useState(0);

    const topicIds: number[] = useMemo(() => topicsList.map((topic) => topic.topicId), [topicsList]);

    const handleTopicClick = (isDefault: boolean, topicId: number) => {
        if (isDefault) {
            openInNewTab(`/search/global?tag=${topicId}`);
            return;
        }
        setSelectedTopicId(topicId);
        setShowTermsPopup(true);
    };

    return { handleTopicClick, showTermsPopup, setShowTermsPopup, selectedTopicId, topicIds };
}
