import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import useBundleTranslation from 'i18n';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsPieChart = ReportContentNS.ComponentSettingsPieChart;
import { Box, Button, Divider, FormLabel, Stack } from '@mui/material';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import IconMi from 'components/common/icon/IconMi';
import { FormDataAPIType, RawFormComponentType } from 'components/common/form';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { AssocArray } from 'tools/types';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { prepareFormElementProps } from 'components/common/form/formTools';
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import fieldToChartMeasuredField = ReportContentNS.fieldToChartMeasuredField;
import EditPanelColorPicker from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelColorPicker';
import Highcharts from 'highcharts/highstock';
import prepareDataForSeries, {
    PieChartSeriesRow,
} from 'components/report-content/components/pie-chart/helpers/prepareDataForSeries';
import usePalette from 'components/report-content/hooks/usePalette';
import AggregationField from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/AggregationField';
import useRCChartData from 'components/report-content/hooks/useRCChartData';
import DatasetField = ReportContentNS.DatasetField;
import ComponentSettingsChartMeasureField = ReportContentNS.ComponentSettingsChartMeasureField;
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';

const formattingPref = 'formatting_color_';
// Field for form
const componentFormFields: Array<keyof ComponentSettingsPieChart> = [
    'title',
    'show_in_viewer',
    'include_in_email',
    'include_in_email_attachment',
    'group_field',
    'merge_small_sections',
    'merge_small_limit',
    'merge_small_label',
    'merge_small_color',
    'display_value',
    'display_value_total',
];
const componentNumericFormField: Array<keyof ComponentSettingsPieChart> = ['height'];

function buildDataQueries(
    settings: ComponentSettingsPieChart,
    datasetFields: Array<DatasetField>,
    measuredField: ComponentSettingsChartMeasureField
): ComponentSettingsChartDataQueries {
    const dataQueries: ComponentSettingsChartDataQueries = {
        table: {
            resultFields: [settings.group_field, measuredField.reference_name],
            resultFieldAggregations: {
                [measuredField.reference_name]: measuredField.aggregation_function,
            },
            sort: [{ field: settings.group_field, dir: 'ASC' }],
        },
    };

    if (settings.merge_small_sections == 'Y') {
        dataQueries.table.top = {
            field: settings.group_field,
            count: settings.merge_small_limit,
            label: settings.system_other_grouping_label,
            dir: 'DESC',
        };
    }
    return dataQueries;
}

function getNewSettings(
    settings: ComponentSettingsPieChart,
    datasetFields: Array<DatasetField>,
    measuredField: ComponentSettingsChartMeasureField,
    elementProps: FormDataAPIType,
    formattingList: Array<PieChartSeriesRow>
): ComponentSettingsPieChart {
    // Process measure fields settings
    const newSettings: ComponentSettingsPieChart = {
        ...settings,
        measureFields: [measuredField],
    } as ComponentSettingsPieChart;
    //@ts-ignore
    componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
    //@ts-ignore
    componentNumericFormField.forEach((f) => (newSettings[f] = Number(elementProps.form.hookFormGetValues(f))));

    if (newSettings.merge_small_sections == 'Y') {
        const field = datasetFields.find((f) => f.reference_name == newSettings.group_field);
        if (field?.value_type === 'datetime') {
            newSettings.system_other_grouping_label = '1970-01-01 00:00:11';
        } else if (field?.value_type === 'numeric') {
            newSettings.system_other_grouping_label = '987654321';
        } else {
            newSettings.system_other_grouping_label = '__system_other_grouping';
        }
    }

    const newLocalPalette = { ...newSettings.localPalette };
    formattingList.forEach((f) => {
        newLocalPalette[f.name] = elementProps.form.hookFormGetValues(formattingPref + f.name);
    });

    return {
        ...newSettings,
        localPalette: newLocalPalette,
        dataQueries: buildDataQueries(newSettings, datasetFields, measuredField),
    } as ComponentSettingsPieChart;
}

export default function PieChartEditPanel({
    component,
    block,
    contentSettings,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsPieChart> & { block: BlockType<ComponentSettingsPieChart> }) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const datasetFields = editPanelContext?.datasetFields ?? [];
    const [newSettings, setNewSettings] = useState(component.settings);
    const { data, isLoading } = useRCChartData(
        { ...component, settings: newSettings },
        contentSettings,
        updateBlockSettings,
        false
    );

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        const resultSettings = getNewSettings(
            component.settings,
            datasetFields,
            measuredField,
            elementProps,
            formattingList
        );

        editPanelContext?.updateBlockSettings(block.uid, resultSettings, true);
    };

    // Prepare data for Form
    const defaultState: AssocArray<any> = {};

    // Add simple fields to form
    componentFormFields.forEach((f) => (defaultState[f] = component.settings[f]));
    componentNumericFormField.forEach((f) => (defaultState[f] = Number(component.settings[f])));

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    const [measuredField, setMeasuredField] = useState(
        component.settings.measureFields.length == 0
            ? fieldToChartMeasuredField(datasetFields[0])
            : component.settings.measureFields[0]
    );

    const groupField = elementProps.form.hookFormWatch('group_field');
    useEffect(() => {
        setNewSettings(getNewSettings(component.settings, datasetFields, measuredField, elementProps, formattingList));
    }, [measuredField, groupField]);

    const mergeSmallSections = elementProps.form.hookFormWatch('merge_small_sections') == 'Y';

    const { getPaletteColor, localPalette } = usePalette(
        'edit',
        contentSettings.elementId,
        Highcharts.getOptions().colors as Array<string>,
        component.settings.localPalette
    );

    const buildFormattingList = (settings: ComponentSettingsPieChart, data: Array<any>) => {
        return prepareDataForSeries(settings, data, getPaletteColor);
    };

    const [formattingList, setFormattingList] = useState<Array<PieChartSeriesRow>>([]);
    const formattingListRef = useRef(formattingList);
    const [hasChangedColors, setHasChangedColors] = useState(false);
    useEffect(() => {
        formattingListRef.current = formattingList;
        const index = formattingList.findIndex((f) => f.color != f.key.default_color);
        setHasChangedColors(index != -1);
    }, [formattingList]);

    useEffect(() => {
        const list = buildFormattingList({ ...newSettings, measureFields: [measuredField] }, data);
        list.forEach((f) => {
            elementProps.form.hookFormSetValue(formattingPref + f.name, f.color);
        });
        setFormattingList(list);
    }, [newSettings, data, localPalette]);

    const handleResetColorClick = (name: string, color?: string) => {
        elementProps.form.hookFormSetValue(formattingPref + name, color);
    };

    const handleResetAllColorsClick = () => {
        formattingList.forEach((f) => {
            if (f.color != f.key.default_color) {
                elementProps.form.hookFormSetValue(formattingPref + f.name, f.key.default_color);
            }
        });
    };

    useEffect(() => {
        const subscription = elementProps.form.hookFormWatch((value, { name, type }) => {
            if (name?.startsWith(formattingPref)) {
                const list = formattingListRef.current.slice();
                const index = list.findIndex((f) => formattingPref + f.name == name);
                if (index != -1) {
                    list[index].color = value[name];
                    setFormattingList(list);
                }
            }
        });
        return () => subscription?.unsubscribe();
    }, [elementProps.form.hookFormWatch]);
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <Stack height={1}>
            {/*Main Panel*/}
            <Box flexShrink={0}>
                <BlockEditPanelHeader
                    title={t('pie_chart.edit_title')}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
            <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                <Box>
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: { component: 'FormText', name: 'title', label: t('title') },
                        })}
                    />
                </Box>
                <Divider />

                <Box>
                    <FormLabel>{t('pie_chart.selector_labels')}</FormLabel>
                    <ReactHookFormController
                        componentValues={datasetFields.map((field) => ({
                            label: field.column_name,
                            value: field.reference_name_escaped,
                        }))}
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            ...{
                                component: {
                                    component: 'FormSelect',
                                    name: 'group_field',
                                } as RawFormComponentType,
                            },
                        })}
                    />
                </Box>

                <Box>
                    <AggregationField
                        label={t('pie_chart.sector_value')}
                        allowedFields={datasetFields}
                        measuredField={measuredField}
                        setMeasuredField={setMeasuredField}
                        fieldToMeasuredField={fieldToChartMeasuredField}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'merge_small_sections'}
                        label={t('pie_chart.merge_small_sections')}
                    />
                </Box>

                <Stack
                    className={mergeSmallSections ? '' : 'd-none'}
                    direction={'row'}
                    spacing={1}
                    alignItems={'center'}
                >
                    <Box flexShrink={0}>{t('pie_chart.merge_small_limit')}</Box>
                    <Box width={'60px'}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: {
                                    component: 'FormText',
                                    name: 'merge_small_limit',
                                    label: '',
                                },
                            })}
                        />
                    </Box>
                    <Box flexShrink={0}>{t('pie_chart.merge_small_limit_sectors')}</Box>
                </Stack>
                <Stack
                    className={mergeSmallSections ? '' : 'd-none'}
                    direction={'row'}
                    spacing={1}
                    alignItems={'center'}
                >
                    <Box flexShrink={0}>{t('pie_chart.merge_small_label')}</Box>
                    <Box width={'200px'}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: {
                                    component: 'FormText',
                                    name: 'merge_small_label',
                                    label: '',
                                },
                            })}
                        />
                    </Box>
                    <Box flexShrink={0}>
                        <EditPanelColorPicker
                            elementProps={elementProps}
                            name={'merge_small_color'}
                            icon={{ type: 'mi', value: 'color-fill', fill: 'text' }}
                        />
                    </Box>
                </Stack>

                <Divider />
                <Box>
                    <EditPanelCheckBox elementProps={elementProps} name={'display_value'} label={t('display_value')} />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'display_value_total'}
                        label={t('pie_chart.display_value_total')}
                    />
                </Box>
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Box sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}>Height (px) </Box>
                    <Box sx={{ width: '72px' }}>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                inputFilter: 'int',
                                component: { component: 'FormText', name: 'height', label: '' },
                            })}
                        />
                    </Box>
                </Stack>
                <Divider />

                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'show_in_viewer'}
                        label={t('show_in_viewer')}
                    />
                </Box>
                <Box>
                    <EditPanelCheckBox
                        elementProps={elementProps}
                        name={'include_in_email'}
                        label={t('include_in_email')}
                    />
                </Box>
                <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                <Box>
                    <GetEmbedCode contentSettings={contentSettings} height={actualHeight} blockUID={block.uid} t={t} />
                </Box>
                <Divider />
                {!isLoading && (
                    <Stack direction={'column'} spacing={1}>
                        <FormLabel>{t('pie_chart.formatting')}</FormLabel>
                        {formattingList.slice(0, 10).map((f, index) => (
                            <Stack
                                key={index}
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    zIndex: 1205, //above backdrop
                                }}
                                spacing={1}
                            >
                                <Box>{f.key.name_f}</Box>
                                <Stack direction={'row'} spacing={1}>
                                    <EditPanelColorPicker
                                        elementProps={elementProps}
                                        name={formattingPref + f.name}
                                        icon={{ type: 'mi', value: 'color-fill', fill: 'text' }}
                                    />
                                    <Button
                                        variant="outlined"
                                        className={'min-width--icon'}
                                        color="neutral"
                                        disabled={f.color == f.key.default_color}
                                        onClick={() => handleResetColorClick(f.name, f.key.default_color)}
                                    >
                                        <IconMi icon={'rotate-ccw'} fontSize={'16'} />
                                    </Button>
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                )}
                <Box>
                    <Button
                        onClick={() => handleResetAllColorsClick()}
                        startIcon={<IconMi icon={'rotate-ccw'} />}
                        disabled={!hasChangedColors}
                        variant={'outlined'}
                    >
                        {t('pie_chart.reset_all')}
                    </Button>
                </Box>
            </Stack>
            <Box flexShrink={0}>
                <BlockEditPanelControls
                    onApply={handleApply}
                    onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                />
            </Box>
        </Stack>
    );
}
