import { Chart as HChart } from 'highcharts';
import { TooltipFormatterCallbackFunction, TooltipFormatterContextObject } from 'highcharts/highstock';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export default function TooltipWrapper({
    chart,
    children,
}: {
    chart: HChart | null;
    children(formatterContext: TooltipFormatterContextObject): JSX.Element;
}) {
    const isInit = useRef(false);
    const [context, setContext] = useState<TooltipFormatterContextObject | null>(null);
    const generateTooltipId = (chartId: number) => `highcharts-custom-tooltip-${chartId}`;

    useEffect(() => {
        if (chart) {
            const formatter: TooltipFormatterCallbackFunction = function () {
                if (!isInit.current) {
                    isInit.current = true;
                    chart.tooltip.refresh.apply(chart.tooltip, [this.point]);
                    chart.tooltip.hide(0);
                }

                setContext(this);

                return `<div id="${generateTooltipId(chart.index)}"></div>`;
            };

            try {
                chart.update({
                    tooltip: {
                        formatter,
                        useHTML: true,
                        style: {
                            'vertical-algin': 'top',
                            top: '0px',
                        },
                    },
                });
            } catch (e) {}
        }
    }, [chart]);

    useEffect(() => {
        if (context) {
            const tooltip: any = context.series.chart.tooltip;
            if (!tooltip?.label?.text) {
                return;
            }
            const textEl = tooltip.label.text.element;

            tooltip.label.box.attr({
                height: textEl.offsetHeight + 12,
                width: textEl.offsetWidth + 16,
            });

            tooltip.label.attr({
                height: 0,
            });

            tooltip.label.text.css({
                top: '8px',
            });
        }
    }, [context]);

    const node = chart && document.getElementById(generateTooltipId(chart.index));

    return node && context ? createPortal(children(context), node) : null;
}
