import { Box, Fade } from '@mui/material';
import React from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { getCellContent } from './index';
import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';

export default function CopyTextCell({ text, subText, icon, rawHtml, align, value }: CustomCellProps) {
    const clipboard = useClipboard();
    const { enqueueSnackbar } = useSnackbar();

    const onCopy = () => {
        if (text) {
            const copyValue = (value ?? text).toString();

            clipboard.copy(copyValue);
            enqueueSnackbar('Copied!', {
                variant: 'success',
                TransitionComponent: Fade,
            });
        }
    };

    return (
        <>
            <Box sx={{ cursor: 'copy' }} onClick={onCopy}>
                {getCellContent(text, icon, rawHtml, align, subText)}
            </Box>
        </>
    );
}
