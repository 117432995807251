import { FormControl, Grid } from '@mui/material';
import { WrapFormElementProps } from './index';
import { getFromComponentPropsClass } from 'components/common/form/layout/control';

/**
 * Default wrapper for Static components
 * Provide Grid Item wrapper
 */
export default function FormWrapperEmpty({ elementProps, children, componentValues }: WrapFormElementProps) {
    let className = 'form-wrapper-empty ' + getFromComponentPropsClass(elementProps, componentValues);
    return (
        <Grid className={className} item xs={elementProps.componentProps.xs}>
            <FormControl
                className={'form-wrapper-empty-control ' + elementProps.componentProps?.controlClass ?? ''}
                fullWidth
                sx={{ display: 'flex' /*inline-flex (default) => to flex, to refuse empty line-height space*/ }}
            >
                {children}
            </FormControl>
        </Grid>
    );
}
