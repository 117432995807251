import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, Link, Tooltip } from '@mui/material';
import IconHandler from 'components/common/icon/IconHandler';
import PartItemWallThread from 'components/wall/parts/PartItemWallThread';
import { wallProcessAlertText } from 'components/wall/index';
import IconMi from 'components/common/icon/IconMi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser';

export interface ItemWallAlertEventProps {
    data: any;
    uid: string;
    wallData: any;
    updateWallData: (newData: any) => void;
    followUser: (followData: any) => void;
    insightful: (type: string, id: number | string) => void;
    notifyOther: (type: string, text: any, itemData: any) => void;
    imageAntiCache?: string;
}

export default function ItemWallAlertEvent(props: ItemWallAlertEventProps) {
    const { t } = useBundleTranslation(['components/wall/wall']);
    const {
        data,
        uid,
        wallData,
        updateWallData = () => {},
        followUser = () => {},
        insightful = () => {},
        notifyOther = () => {},
        imageAntiCache,
    } = props;

    const params = wallData.params;
    const displayNone = wallData.viewFilter && wallData.viewFilter != 'alert_event';
    return (
        <Box
            className={`wall_node_OLD wall_alert_event_node ${
                data.issue ? 'alert_issue_mod_OLD' : ''
            } wall__item wall__item--alert`}
            sx={{ display: displayNone ? 'none' : '' }}
            data-element_id={data.element_id}
            data-segment_value_id={data.segment_value_id}
        >
            <Box id={`wall_alert_event_${uid}_${data.alert_event_id}`} className="wall_alert_event_OLD wall__item-body">
                <Box
                    className={`wall__item-icon-block wall_alert_image_td${
                        data.news_type && data.news_type == 'bad'
                            ? ' bad'
                            : data.news_type && data.news_type == 'good'
                            ? ' good'
                            : ''
                    }`}
                >
                    <Box
                        className="wall__item-icon-holder"
                        sx={{
                            backgroundColor: (theme) =>
                                alpha(
                                    data.news_type && data.news_type == 'bad'
                                        ? theme.palette.error.main
                                        : data.news_type && data.news_type == 'good'
                                        ? theme.palette.success.main
                                        : theme.palette.text.primary,
                                    0.08
                                ),
                            color: (theme) =>
                                data.news_type && data.news_type == 'bad'
                                    ? theme.palette.error.main
                                    : data.news_type && data.news_type == 'good'
                                    ? theme.palette.success.main
                                    : theme.palette.text.primary,
                        }}
                    >
                        <IconMi icon="alert" fontSize="16" />
                    </Box>
                </Box>
                <Box className="wall__item-content-block wall_alert_info_td">
                    <Box className="wall_alert_info_head">
                        {(params.element_type == 'internal report' || params.element_type == 'external report') && (
                            <Box className="wall_alert_from_metric_name_OLD">{data.element_dashboard_name}</Box>
                        )}
                        <Box
                            className="wall__item-text markdown-holder"
                            dangerouslySetInnerHTML={{
                                __html: wallProcessAlertText(data.text, params),
                            }}
                        />
                        {!data.issue && (
                            <Box className="wall__item-content-footer wall_node_footer_OLD">
                                <Box className="wall__item-content-footer-left">
                                    <Tooltip title={t('alert_event.share_link')} disableInteractive>
                                        <Box
                                            component="span"
                                            className="wall_alert_notify_other wall__item-content-footer-link"
                                            data-alert_event_id={data.alert_event_id}
                                            onClick={() => {
                                                notifyOther('alert', wallProcessAlertText(data.text, params), data);
                                            }}
                                        >
                                            <IconMi icon="share" fontSize="16" />
                                            {t('alert_event.share_link')}{' '}
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        )}
                        {data.issue && (
                            <Box className="wall_issue_wrap_OLD">
                                <Box>
                                    <Box component={FontAwesomeIcon} icon={faCircle} className="issue-info-ico_OLD" />
                                    {t('alert_event.current_status')}: <strong>{data.issue.status_name}</strong>
                                </Box>
                                <Box>
                                    <Box component={FontAwesomeIcon} icon={faUser} className="issue-info-ico_OLD" />
                                    {t('alert_event.assignee')}: <strong>{data.issue.assignee}</strong>
                                </Box>
                                <Box>
                                    <IconMi icon={'issue-priority'} className="issue-info-ico_OLD" />
                                    {t('alert_event.priority')}: <strong>{data.issue.priority_name}</strong>
                                </Box>
                                {data.acknowledgment_required_ind == 'Y' && data.issue.status_icon != 'closed' && (
                                    <Box>
                                        <IconMi icon={'error'} className="issue-info-ico_OLD t-red" />
                                        {t('alert_event.acknowledgement')}: <strong>{data.issue.priority_name}</strong>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box className="wall__item-thread-block">
                <PartItemWallThread
                    {...props}
                    elementType={'alert_event'}
                    updateWallData={(newData) => {
                        updateWallData(newData);
                    }}
                    followUser={followUser}
                    insightful={insightful}
                    notifyOther={notifyOther}
                    imageAntiCache={imageAntiCache}
                />
            </Box>
        </Box>
    );
}
