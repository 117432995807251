import React, { useState } from 'react';
import { ElementViewerPropsType } from 'components/element-viewer';
import { alpha, Box, Stack, Theme } from '@mui/material';
import ControlsPanel from 'components/element-viewer/ControlsPanel';
import TopControlsWrapper from 'components/element-viewer/common/TopControlsWrapper';
import EmbedElement from 'components/common/external/EmbedElement';
import ExpertCommentaryWall from 'components/wall/ExpertCommentaryWall';
import ViewerWall from 'components/wall/ViewerWall';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import useBundleTranslation from 'i18n';
import usePageTitleCrumbs from 'components/element-viewer/hooks/usePageTitleCrumbs';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';
import HeadingElementData from 'components/element-viewer/HeadingElementData';

type FileInCollectionType = {
    label: string;
    value: string;
    url: string;
    download: string;
};
export interface ExternalContentViewerDataType {
    additionalData: any;
    fileCollection: Array<FileInCollectionType>;
    fileId: string;
    urlsData: {
        externalUrl: string;
        url: string;
        urlDownload: string;
    };
}

export default function ExternalContentViewer({
    elementInfo,
    segmentValueId,
    onFavoritesChange,
    viewerRequestExtraData,
    alertStatus,
}: ElementViewerPropsType<ExternalContentViewerDataType>) {
    const { t } = useBundleTranslation();

    const userAuth: UserAuth = useAppSelector((state) => state.auth);
    const userId = userAuth?.userInfo?.user_id ?? 0;

    const [fileId, setFileId] = useState(viewerRequestExtraData.fileId);
    const [fileName, setFileName] = useState('');
    const [iframeUrl, setIframeUrl] = useState(viewerRequestExtraData.urlsData.externalUrl);
    const [url, setUrl] = useState(viewerRequestExtraData.urlsData.url);
    const [urlDownload, setUrlDownload] = useState(viewerRequestExtraData.urlsData.urlDownload);

    const handleFileChange = (fileId: string) => {
        const file = viewerRequestExtraData.fileCollection.find((f) => f.value == fileId);
        if (!file) {
            return;
        }
        setFileId(file.value);
        setFileName(file.label);
        setUrl(file.url);
        setIframeUrl(file.url);
        setUrlDownload(file.download);
    };

    usePageTitleCrumbs(elementInfo);
    const hasWall = useEmbeddingContextVisible('wall');
    return (
        <>
            <TopControlsWrapper>
                <HeadingElementData element={elementInfo} />
                <ControlsPanel
                    element={elementInfo}
                    segmentValueId={segmentValueId}
                    onFavoritesChange={onFavoritesChange}
                    alertStatus={alertStatus}
                />
            </TopControlsWrapper>
            <Box
                sx={{
                    pt: 2,
                    pb: 5,
                    overflow: 'auto',
                    px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                    flexGrow: 1,
                }}
                className={'scroll-content-container'}
            >
                {viewerRequestExtraData.fileCollection.length > 0 && (
                    <Box sx={{ mb: 1 }}>
                        <Stack direction="row">
                            <StaticAddon>File</StaticAddon>
                            <Box sx={{ width: '175px' }}>
                                <ReactSelect
                                    data={viewerRequestExtraData.fileCollection}
                                    value={
                                        fileId == '0' || fileId == ''
                                            ? viewerRequestExtraData.fileCollection[0].value
                                            : fileId
                                    }
                                    name={'file_id'}
                                    update={handleFileChange}
                                />
                            </Box>
                        </Stack>
                    </Box>
                )}
                <EmbedElement
                    url={url.replace('/download/Y', '')}
                    embedUrl={iframeUrl}
                    displayType={elementInfo.row.externalContentDisplayInViewer}
                    iframeHeight={
                        elementInfo.row.calcIframeHeightOnImageAspectRatioInd
                            ? 0
                            : elementInfo.row.externalReportIframeHeight
                    }
                />

                {(viewerRequestExtraData.urlsData.urlDownload.length > 0 ||
                    viewerRequestExtraData.urlsData.url.length > 0) && (
                    <Box
                        sx={{
                            textAlign: 'right',
                            position: 'relative',
                        }}
                    >
                        <Box sx={{ position: 'absolute', right: 0, top: 0, mt: 4 }}>
                            <Box
                                data-test={'external-content-external-link'}
                                component={'a'}
                                href={
                                    viewerRequestExtraData.urlsData.urlDownload.length > 0
                                        ? urlDownload
                                        : url.replace('/download/Y', '')
                                }
                                target={'_blank'}
                                sx={{
                                    border: '1px solid',
                                    borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
                                    borderRadius: '4px',
                                    padding: '7px 12px',
                                    '&:hover': {
                                        textDecoration: 'none',
                                        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.04),
                                    },
                                }}
                            >
                                {viewerRequestExtraData.urlsData.urlDownload.length > 0 ? (
                                    <>
                                        {t('download')} {elementInfo.row.externalReportReferenceName}
                                    </>
                                ) : (
                                    <>
                                        {t('open')} {elementInfo.row.name}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}

                {hasWall && (
                    <Box sx={{ pt: 4, mx: 'auto', width: '552px' }}>
                        <ExpertCommentaryWall
                            data={{}}
                            elementId={elementInfo.row.elementId}
                            segmentValueId={segmentValueId}
                            userId={userId}
                            elementType={'other external content'}
                            allowEdit={true /*set value*/}
                        />
                        <ViewerWall
                            wallType="chart"
                            data={{}}
                            elementId={elementInfo.row.elementId}
                            segmentValueId={segmentValueId}
                            elementType={'other external content'}
                            userId={userId}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
}
