import { useQuery } from '@tanstack/react-query';
import { devAPI } from 'api/api';
import React, { useEffect, useState } from 'react';
import pollingService, { PoolingRequestData } from 'tools/pollingService';
import { ImageDataType } from 'components/element-image/index';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import SetupImageForm from 'components/element-image/editor/form/SetupImageForm';

interface CollectImageRequestType {
    sv_id: number;
    width: number;
    height: number;
}

export default function SetupImageFormWrapper({
    settingsUrl,
    onHide,
    src,
    setupImgEnabled,
    segmentValueId,
}: {
    settingsUrl: string;
    onHide: () => void;
    src: string;
    setupImgEnabled: boolean;
    segmentValueId: number;
}) {
    const {
        data,
        remove,
        isLoading: isLoadingData,
    } = useQuery<any, Error>([settingsUrl], () => {
        return devAPI.getEditorImage(settingsUrl);
    });

    // Display Loading Mask
    const [isCollectionRunning, setIsCollectionRunning] = useState<boolean>(true);
    const [imageBase64, setImageBase64] = useState('');

    const collectImage = (width: number, height: number) => {
        setIsCollectionRunning(true);
        const onSuccess = function (data: any) {
            setIsCollectionRunning(false);
            // Update stamp to refresh Image
            setImageBase64(data.image);
        };
        const onError = function (data: any) {
            setIsCollectionRunning(false);
            setImageBase64('');
            alert(data.message);
        };

        const requestData: CollectImageRequestType & PoolingRequestData = {
            poolingCreateURL: src,
            sv_id: segmentValueId,
            width: width,
            height: height,
        };

        const { create } = pollingService<any, CollectImageRequestType>({ onSuccess, onError, requestData });
        create();
    };

    const [imageData, setImageData] = useState<ImageDataType>();

    const handleOnFormHide = (afterSubmit: boolean) => {
        onHide();
        setTimeout(() => {
            if (afterSubmit) {
                remove();
            }
        });
    };

    useEffect(() => {
        if (typeof data == 'undefined') {
            return;
        }
        setImageData(data.data.data);
    }, [data]);

    useEffect(() => {
        if (!imageData) {
            return;
        }
        collectImage(imageData.width, imageData.height);
    }, [src, imageData]);

    if (typeof imageData == 'undefined') {
        return <LoadingPlaceholder />;
    }

    return (
        <>
            {isCollectionRunning && <LoadingPlaceholder />}
            <SetupImageForm
                settingsUrl={settingsUrl}
                onHide={handleOnFormHide}
                imageData={imageData}
                imageBase64={imageBase64}
                setupImgEnabled={setupImgEnabled}
                recollectImage={collectImage}
            />
        </>
    );
}
