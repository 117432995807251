import useBundleTranslation from 'i18n';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import React, { useEffect, useState } from 'react';
import { FormComponentValueSelectable } from 'components/common/form/layout/control';
import { favoritesAPI, UpdateFavoritesRequestData } from 'api/favorites';
import { useWatch } from 'react-hook-form';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { FormHelperText, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';

export default function AddToFavoritePopup({
    elementId,
    segmentValueId,
    isOpen,
    onClose,
    onApply,
    scope = '',
    elementName = '',
}: {
    elementId: number;
    segmentValueId: number;
    isOpen: boolean;
    onClose: () => void;
    onApply: () => void;
    scope?: string;
    elementName?: string;
}) {
    const { t } = useBundleTranslation('app/favorites');
    const elementProps = useCustomSimplifiedForm();
    const popupSettings: PopupSettings = { title: t('addRemove'), textOK: t('save_changes') };

    const [values, setValues] = useState<Array<FormComponentValueSelectable>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        favoritesAPI.verifyNeedToConfirm(elementId, segmentValueId).then((response) => {
            if (response.status === 'OK') {
                if (response.data.needConfirm) {
                    setShowPopup(true);
                    favoritesAPI.getElementFavoritesList(elementId, segmentValueId, false).then((foldersList) => {
                        setValues(
                            foldersList.map((s) => ({
                                label: s.displayName,
                                value: String(s.favoriteId),
                                selected: s.selected,
                            }))
                        );
                        setIsLoading(false);
                    });
                }
                if (!response.data.needConfirm && (response.added || response.removed)) {
                    setIsLoading(false);
                    setShowPopup(false);
                    onApply();
                }
            }
        });
    }, []);

    useEffect(() => {
        const selectedList = JSON.stringify(values.filter((v) => v.selected).map((v) => v.value));
        elementProps.form.hookFormSetValue('favoriteCheckbox', selectedList);
    }, [values]);

    const handleApply = (ids: number[] = []) => {
        setIsLoading(true);
        const data: UpdateFavoritesRequestData = {
            ids: ids.length > 0 ? ids : JSON.parse(elementProps.form.hookFormGetValues('favoriteCheckbox')),
            scope: scope,
            name: newFavoriteName,
        };

        favoritesAPI
            .addElementToFavorites(elementId, segmentValueId, data)
            .then((response) => {
                if (response.status === 'ERROR') {
                    const message = Object.values(response.messages.display_name)
                        .join(', ')
                        .replace('{{label}}', t('name'));
                    setErrorMessage(message);
                } else {
                    setErrorMessage('');
                    onApply();
                    setShowPopup(false);
                }
            })
            .catch(() => {
                onClosePopup();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const newFavoriteName = useWatch({
        control: elementProps.form.hookFormControl,
        name: 'newFavoriteName',
        defaultValue: '',
    });

    const onClosePopup = () => {
        setShowPopup(false);
        onClose();
    };

    const onClearInput = () => {
        elementProps.form.hookFormSetValue('newFavoriteName', '');
    };

    return isOpen ? (
        <Popup
            maxWidth={'popupSm'}
            settings={popupSettings}
            open={showPopup}
            onHide={onClosePopup}
            onConfirm={handleApply}
        >
            {isLoading ? (
                <LoadingPlaceholder />
            ) : (
                <Stack spacing={1}>
                    <span dangerouslySetInnerHTML={{ __html: elementName }} />
                    <Typography fontWeight={600}>{t('by_checking')}</Typography>
                    <TemplateFormComponent
                        component={'CheckBoxList'}
                        elementProps={elementProps}
                        name={'favoriteCheckbox'}
                        props={{
                            data: { values: values },
                            showCounter: false,
                            selectAllLabel: t('existingFolders'),
                            async: false,
                            showSearch: false,
                        }}
                    />
                    {
                        <Grid container>
                            <Grid item xs={12}>
                                <TemplateFormComponent
                                    component={'FormText'}
                                    elementProps={elementProps}
                                    name={'newFavoriteName'}
                                    props={{
                                        placeholder: t('orCreate'),
                                        label: '',
                                        endAdornment:
                                            newFavoriteName > '' ? (
                                                <InputAdornment
                                                    onMouseDown={onClearInput}
                                                    position="end"
                                                    sx={{
                                                        color: 'primary.main',
                                                        cursor: 'pointer',
                                                        '&:hover': { opacity: 0.75 },
                                                    }}
                                                >
                                                    <IconMi icon="times" fontSize="16" />
                                                </InputAdornment>
                                            ) : null,
                                    }}
                                />
                                {errorMessage && (
                                    <FormHelperText sx={{ color: 'error.main' }}>{errorMessage}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    }
                    {newFavoriteName > '' && <span dangerouslySetInnerHTML={{ __html: t('new_hint') }} />}
                </Stack>
            )}
        </Popup>
    ) : null;
}
