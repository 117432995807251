import * as React from 'react';
import styled from '@mui/material/styles/styled';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface HtmlTooltipProps extends TooltipProps {
    certified: boolean;
}

export const HtmlTooltip = styled(({ className, certified, ...props }: HtmlTooltipProps) => {
    return <Tooltip {...props} classes={{ popper: className }} children={props.children} />;
})(({ theme, certified }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        maxWidth: 280,
        minWidth: 280,
        padding: '0',
        lineHeight: '16px',
        border: '1px solid',
        borderColor: certified ? theme.palette.success.main : theme.palette.primary.main,
        borderRadius: '3px',
        color: theme.palette.text.primary,
    },
}));
