import { FormControlProps } from 'components/common/form/layout/control';
import { ReportContentNS } from 'components/report-content';
import BlockFactoryManageFilters = ReportContentNS.BlockFactoryManageFilters;
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import BlockType = ReportContentNS.BlockType;
import DatasetField = ReportContentNS.DatasetField;
import ComponentSettings = ReportContentNS.ComponentSettings;

export interface FormControlReportContentEditorProps extends FormControlProps {
    isPermittedToEditDataset: boolean;
}

export interface ReportContentActions {
    openBlockEditor: (blockUID: string, editPanel: any, options?: any) => void;
    removeBlock: (blockUID: string, withConfirm?: boolean) => void;
    sortBlocks: (sourceIndex: number, destinationIndex: number) => void;
    updateMeasurementTime: (measurementTime: string) => void;
    updateSegmentValue: (segmentValueId: string) => void;
    updateBlockSettings: (
        blockUID: string,
        newSettings: ComponentSettings,
        options?: { shouldDirty?: boolean }
    ) => void;
    setBlockPreviewInd: (blockUID?: string) => void;
    getBlocks: () => Array<BlockType<any>>;
}

export function buildManageFiltersBlock(
    rendererFilters: ComponentSettingsManageFilters,
    datasetFields: Array<DatasetField>
): BlockType<ComponentSettingsManageFilters> {
    const block = new BlockFactoryManageFilters().getNewBlock({
        nextUId: -1,
        nextSortOrder: -1,
        datasetFields: datasetFields,
    });

    if (
        (typeof rendererFilters.fields == 'undefined' || rendererFilters.fields.length == 0) &&
        datasetFields.length > 0
    ) {
        block.component.settings = {
            ...rendererFilters,
            fields: datasetFields.map((f) => ({
                ...f,
                filterType: 'dropdown',
                filter_column: 'N',
                sortDirection: 'ASC',
            })),
        };
    } else {
        block.component.settings = rendererFilters;
    }
    return block;
}

export function prepareDatasetFieldEscapedName(newList: Array<DatasetField>) {
    // Prepare reference name in legacy format
    return newList.map((field) => ({
        ...field,
        reference_name_escaped: field.reference_name
            .replace(/"/g, '_1')
            .replace(/</g, '_2')
            .replace(/>/g, '_3')
            .replace(/\s/g, '_4'),
    }));
}
