import { LegendConfig } from 'components/common/grid/';

interface selectedRowDataProps {
    dataKey: string;
    checkedRowsKeys: Array<string | number>;
}

const gridStylePrefix = 'mi-table-row-';

const getRowProperty = (row: any, propertyName: string) => {
    if (row && row.hasOwnProperty(propertyName)) {
        return row[propertyName];
    }

    return null;
};

const getTypeCondition = (type: string, row: any) => {
    switch (type) {
        case 'disabled':
            return (
                'N' == getRowProperty(row, 'enabled_ind') ||
                'N' == getRowProperty(row, 'can_grant_to_user') ||
                'N' == getRowProperty(row, 'in_dashboard_ind_flag') ||
                'STOP' == getRowProperty(row, 'attempts_status')
            );
        case 'error':
            return (
                'Y' == getRowProperty(row, 'with_error') ||
                'Y' == getRowProperty(row, 'is_inactive') ||
                'N' == getRowProperty(row, 'dl_enabled') ||
                'N' == getRowProperty(row, 'success_ind')
            );
        case 'satisfied':
            return 'Y' == getRowProperty(row, 'satisfied');
        case 'without_segment_access':
            return 'Y' == getRowProperty(row, 'no_seg_values');
        case 'no_segment_values_access':
            return 'Y' == getRowProperty(row, 'no_seg_values');
        case 'never_enabled':
            return '' == getRowProperty(row, 'first_enabled_time');
        case 'aborting':
            return 'Y' == getRowProperty(row, 'is_aborting') || 'TRY' == getRowProperty(row, 'attempts_status');
        case 'running':
            return 'Y' == getRowProperty(row, 'is_running');
        case 'queued':
            return 'Y' == getRowProperty(row, 'is_queued');
        case 'invisible_in_dashboard':
            return 'Y' == getRowProperty(row, 'visible_in_dashboard_ind');
        case 'only_parent_child':
            return 'Y' == getRowProperty(row, 'is_child');
        case 'modified':
            return '' == getRowProperty(row, 'copied_on');
        case 'in_queue':
            return 'Y' == getRowProperty(row, 'in_queue');
        case 'changed':
            return 'Y' == getRowProperty(row, 'is_changed');
        case 'in_tile':
            return 'Y' == getRowProperty(row, 'just_in_tile');
        case 'expired':
            return 'Y' == getRowProperty(row, 'expired_ind');
        case 'not_shown':
            return 'Y' == getRowProperty(row, 'not_shown_ind');
    }

    return false;
};

const typeClasses: any = {
    disabled: 'disabled',
    error: 'error',
    satisfied: 'success',
    without_segment_access: 'warning',
    no_segment_values_access: 'warning',
    never_enabled: 'warning',
    aborting: 'warning',
    running: 'success',
    queued: 'queued',
    invisible_in_dashboard: 'queued',
    only_parent_child: 'queued',
    modified: 'queued',
    in_queue: 'warning',
    changed: 'error-solid',
    in_tile: 'queued',
    expired: 'warning',
    not_shown: 'queued',
};

export const useLegend = (data: any[], selectedRowData: selectedRowDataProps, legendConfig?: LegendConfig[]) => {
    const legendItems: LegendConfig[] = [];

    if (legendConfig) {
        legendConfig.forEach((config) => {
            let state: boolean = false;
            const configType: string = config.type;

            data.forEach((row) => {
                if (state) return;

                if (getTypeCondition(configType, row)) {
                    state = getTypeCondition(configType, row);
                }
            });

            if (state) {
                legendItems.push({
                    ...config,
                    className: gridStylePrefix + (config.className ? config.className : typeClasses[configType]),
                });
            }
        });
    }

    const getRowClassName = (rowData: any) => {
        let className: string = '';

        if (rowData) {
            if (legendConfig) {
                legendConfig.forEach((config) => {
                    const configType: string = config.type;

                    if (className === '' && getTypeCondition(configType, rowData)) {
                        className = gridStylePrefix + (config.className ? config.className : typeClasses[config.type]);

                        return;
                    }
                });
            }

            const isChecked = selectedRowData.checkedRowsKeys
                ? selectedRowData.checkedRowsKeys.some((item) => item == rowData[selectedRowData.dataKey])
                : false;

            className += isChecked ? ' rs-table-row--selected-row' : '';
        }

        return className;
    };

    return {
        legendItems,
        getRowClassName,
    };
};
