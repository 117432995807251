import { Chart as HChart } from 'highcharts';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { ElementType } from 'components/element-viewer';
import {
    ChartPoint,
    ChartPointSeriesMetadata,
    ChartSeriesType,
    MetricChartViewsMode,
} from 'components/metric/chart/index';
import { metricAPI } from 'api/viewer/metric';
import IconMi from 'components/common/icon/IconMi';
import { alpha, Box, Button, Divider, Stack } from '@mui/material';
import CollaborationAnnotation from 'components/metric/collaboration-controls/CollaborationAnnotation';
import { EventCalendarType } from 'components/metric/MetricViewer';
import CollaborationEvent from 'components/metric/collaboration-controls/CollaborationEvent';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';

export interface ChartTooltipProps {
    elementInfo: ElementType;
    point: ChartPoint;
    series: ChartSeriesType;
    chart: HChart;
    t: any; // i18 translation;
    isSelected?: boolean;
    onClose: () => void;
    userChartOverlay: number;
    segmentValueId: number;
    actualView: MetricChartViewsMode;
    manualCalendars: Array<EventCalendarType>;
    refetch: () => void;
}

interface TooltipLink {
    title: string;
    list: Array<{
        name: string;
        href?: string;
    }>;
}

export interface RegularSeriesTooltipData {
    // 'metric_id' => $this->_element_id,
    // 'segment_value_id' => ('' == $this->_sv_id ? 0 : (int)$this->_sv_id),
    // 'view' => $data['view'],
    // 'raw_value' => 0,
    links: Array<TooltipLink>;
    compares: Array<{
        name: string;
        value: string;
    }>;
    multimetricValues: Array<{
        name: string;
        value: string;
    }>;
    movingAverage: {
        name: string;
        value: string;
    } | null;
    projectedValue: string;
    rawProjectedValue: number;
    totalProjectedValue: string;
    measureData: {
        name: string;
    };
    viewEvents: string;
    stoplightEvents: string;
    statisticalEvents: {
        name: string;
        value: string;
        event: string;
    } | null;
    targets: Array<{
        name: string;
        value: string;
        variance: string;
        variance_value: string;
    }>;
    hiddenEvents: {
        meas_time: string;
        text: string;
    } | null;
    significant: string;
    impactedMetrics: {
        meas_time: string;
        text: string;
    } | null;
    // 'allowDeletePoint' => false
}

const b = {
    float: 'left',
    lineHeight: '1em',
    paddingBottom: '4px',
    paddingTop: '2px',
};
const styles = {
    label: {
        ...b,
        color: 'text.secondary',
        clear: 'left',
        width: '58%',
        textAlign: 'right',
        fontWeight: 'normal',
    },
    value: {
        ...b,
        width: '42%',
        boxSizing: 'border-box',
    },
};

export default function RegularSeriesTooltip({
    point,
    t,
    isSelected,
    onClose,
    elementInfo,
    segmentValueId,
    userChartOverlay,
    series,
    actualView,
    manualCalendars,
    refetch,
}: ChartTooltipProps) {
    const metadata: ChartPointSeriesMetadata = point.metadata;
    const key = [
        'chart_point',
        metadata?.metric_id ?? 0,
        metadata?.segment_value_id ?? 0,
        metadata?.metric_instance_id ?? 0,
        isSelected,
        actualView,
    ];

    const {
        status: pointDataRequestStatus,
        data: pointData,
        error: pointRequestError,
    } = useQuery<RegularSeriesTooltipData, Error>(key, () => {
        if (!metadata) {
            return {} as RegularSeriesTooltipData;
        }

        const isCorePoint =
            elementInfo.row.elementId == metadata.metric_id && segmentValueId == metadata.segment_value_id;

        const y = point?.y ?? 0;

        const isAssocPoint = Boolean(
            metadata?.raw_standard_deviation_value &&
                series?.metricUnusualValueStdDev &&
                metadata.raw_moving_avg_value != null &&
                Math.abs((y - metadata.raw_moving_avg_value) / metadata.raw_standard_deviation_value) >
                    series.metricUnusualValueStdDev
        );

        let parentElementId = 0;
        let parentSegmentValueId = 0;
        if (elementInfo.row.elementId != metadata.metric_id) {
            parentElementId = elementInfo.row.elementId;
            parentSegmentValueId = segmentValueId;
        }

        return isSelected
            ? metricAPI.getPoint(
                  point,
                  userChartOverlay,
                  isCorePoint,
                  isAssocPoint,
                  actualView,
                  parentElementId,
                  parentSegmentValueId
              )
            : ({} as RegularSeriesTooltipData);
    });

    const headerBlock = (
        <>
            <Box
                dangerouslySetInnerHTML={{ __html: point?.series?.name ?? '' }}
                sx={{ fontWeight: 600, pr: isSelected ? 1.5 : undefined }}
            ></Box>
            {isSelected && (
                <Box
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        p: 0.625,
                        right: 0,
                        top: 0,
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <IconMi icon={'times'} />
                </Box>
            )}
            {(point?.metadata?.value_formatted || point?.metadata?.meas_time) && (
                <Box sx={{ mt: 0.5 }}>
                    {t('label_value_on_time', {
                        value: point?.metadata?.value_formatted,
                        time: point?.metadata?.meas_time,
                    })}
                </Box>
            )}
            <Divider sx={{ my: 1 }} />
        </>
    );
    const canAddAnnotation = useIsAdminOrHasPrivilege('PRIV_ADD_ANNOTATION');

    if (
        !metadata ||
        typeof metadata.metric_instance_id == 'undefined' ||
        typeof point.y == 'undefined' ||
        point.y == null
    ) {
        return (
            <Box
                sx={{
                    py: 1,
                    px: 1.5,
                }}
            >
                {headerBlock}
                <Box sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.64) }}>
                    {t('chart_label_zoom_data_details')}
                </Box>
            </Box>
        );
    }

    if (isSelected) {
        // TODO: basic implementation
        return (
            <Box
                sx={{
                    py: 1,
                    px: 1.5,
                    '.label-box': {
                        color: (theme) => alpha(theme.palette.text.primary, 0.64),
                    },
                    dt: styles.label,
                    dd: styles.value,
                }}
            >
                {headerBlock}
                {pointDataRequestStatus === 'loading' ? (
                    <Box>{t('loading___')}</Box>
                ) : pointDataRequestStatus === 'error' ? (
                    <Box>Error: {pointRequestError!.message}</Box>
                ) : pointData ? (
                    <>
                        <Box sx={{ overflow: 'hidden' }}>
                            {pointData.totalProjectedValue.length > 0 && (
                                <Box className={'rst-row-1'}>
                                    <Box>{pointData.totalProjectedValue}</Box>
                                    <Box>
                                        <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                            {pointData.measureData.name}
                                        </Box>
                                        <Box component={'span'}>{pointData.rawProjectedValue}</Box>
                                    </Box>
                                    <Box>
                                        <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                            {pointData.measureData.name}
                                        </Box>
                                        <Box component={'span'}>{pointData.projectedValue}</Box>
                                    </Box>
                                </Box>
                            )}
                            {pointData.compares.map((line, i) => {
                                return (
                                    <Box className={'rst-row-2'} key={i}>
                                        <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                            {line.name}
                                        </Box>
                                        <Box component={'span'}>{line.value}</Box>
                                    </Box>
                                );
                            })}
                            {pointData.multimetricValues.map((line, i) => {
                                return (
                                    <Box className={'rst-row-3'} key={i}>
                                        <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                            {line.name}
                                        </Box>
                                        <Box component={'span'}>{line.value}</Box>
                                    </Box>
                                );
                            })}
                            {pointData.movingAverage && (
                                <Box className={'rst-row-4'}>
                                    <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                        {pointData.movingAverage.name}
                                    </Box>
                                    <Box component={'span'}>{pointData.movingAverage.value}</Box>
                                </Box>
                            )}
                            {pointData.viewEvents.length > 0 && (
                                <Box className={'rst-row-5'} sx={{ pt: 1.5 }}>
                                    <Box component={'span'} className="label-box">
                                        {pointData.viewEvents}
                                    </Box>
                                </Box>
                            )}
                            {pointData.stoplightEvents.length > 0 && (
                                <Box className={'rst-row-6'}>
                                    <Box
                                        component={'span'}
                                        className="label-box"
                                        dangerouslySetInnerHTML={{ __html: pointData.stoplightEvents }}
                                    />
                                </Box>
                            )}
                            {pointData.statisticalEvents && (
                                <Box className={'rst-row-7'}>
                                    <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                        {pointData.statisticalEvents.name}
                                    </Box>
                                    <Box component={'span'}>{pointData.statisticalEvents.value}</Box>
                                    <Box dangerouslySetInnerHTML={{ __html: pointData.statisticalEvents.event }} />
                                </Box>
                            )}

                            {pointData.targets.map((line, i) => {
                                return (
                                    <Box className={'rst-row-8'}>
                                        <Box>
                                            <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                                {line.name}
                                            </Box>
                                            {line?.value?.length && <Box component={'span'}>{line.value}</Box>}
                                        </Box>
                                        <Box>
                                            <Box component={'span'} className="label-box" sx={{ mr: 1 }}>
                                                {line.variance}
                                            </Box>
                                            <Box component={'span'}>{line.variance_value}</Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                            {pointData.significant.length > 0 && (
                                <Box className={'rst-row-9'}>
                                    <Box component={'span'} className="label-box">
                                        {pointData.significant}
                                    </Box>
                                </Box>
                            )}
                            {pointData.hiddenEvents && (
                                <Box className={'rst-row-10'}>
                                    <Box component={'strong'}>{pointData.hiddenEvents.text}</Box>
                                </Box>
                            )}
                        </Box>

                        {pointData.impactedMetrics && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                <Box
                                    className={'rst-row-11'}
                                    sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}
                                >
                                    <IconMi icon={'metric-impact'} fontSize={'16'} sx={{ mr: 1, flexShrink: 0 }} />
                                    <Box component={'strong'}>{pointData.impactedMetrics.text}</Box>
                                </Box>
                            </>
                        )}

                        <TooltipLinksList links={pointData.links} />

                        <Divider sx={{ my: 1 }} />

                        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                            {canAddAnnotation && (
                                <Box sx={{ width: 'calc(50% - 4px)', '.annotate-btn-wrap': { display: 'block' } }}>
                                    <CollaborationAnnotation
                                        startTime={point.metadata.original_measurement_time}
                                        metricInstanceId={point.metadata.metric_instance_id}
                                        annotationId={0}
                                        elementId={elementInfo.row.elementId}
                                        segmentValueId={segmentValueId}
                                        afterSave={() => refetch()}
                                    >
                                        <Button
                                            variant="outlined"
                                            startIcon={<IconMi icon="add-annotation" />}
                                            fullWidth
                                        >
                                            {t('add_annotation')}
                                        </Button>
                                    </CollaborationAnnotation>
                                </Box>
                            )}
                            {manualCalendars.length > 0 && (
                                <Box sx={{ width: 'calc(50% - 4px)', '.event-btn-wrap': { display: 'block' } }}>
                                    <CollaborationEvent
                                        startTime={point.metadata.original_measurement_time}
                                        eventId={0}
                                        elementId={elementInfo.row.elementId}
                                        segmentValueId={segmentValueId}
                                        afterSave={() => refetch()}
                                    >
                                        <Button variant="outlined" startIcon={<IconMi icon="add-event" />} fullWidth>
                                            {t('add_event')}
                                        </Button>
                                    </CollaborationEvent>
                                </Box>
                            )}
                        </Stack>
                    </>
                ) : null}
            </Box>
        );
    }

    return (
        <Box
            sx={{
                py: 1,
                px: 1.5,
            }}
        >
            {headerBlock}
            <Box sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.64) }}>
                {t('click_on_point_for_details')}
            </Box>
        </Box>
    );
}

export function TooltipLinksList({ links }: { links: Array<TooltipLink> }) {
    const [openList, setOpenList] = useState<Set<number>>(new Set());

    const handleToggleOpen = (i: number) => {
        setOpenList((prevState) => {
            if (openList.has(i)) {
                prevState.delete(i);
            } else {
                prevState.add(i);
            }
            return new Set(prevState);
        });
    };

    return (
        <>
            {links.length > 0 && <Divider sx={{ my: 1 }} />}
            {links.map((link, i) => {
                const list = link.list.map((row, i: number) => {
                    // TODO : ?
                    if (row.href) {
                        return <Box key={i}>{row.href}</Box>;
                    } else {
                        return <Box key={i}>{row.name}</Box>;
                    }
                });

                const isOpen = openList.has(i);

                return (
                    <>
                        <Box>
                            <Box
                                onClick={() => handleToggleOpen(i)}
                                className="test-12"
                                sx={{ display: 'flex', cursor: 'pointer', '&:hover': { color: 'primary.main' } }}
                            >
                                <strong>{link.title}</strong>
                                <IconMi
                                    icon={isOpen ? 'chevron-down' : 'chevron-right'}
                                    fontSize={'16'}
                                    sx={{ mr: 1, flexShrink: 0 }}
                                />
                            </Box>
                        </Box>
                        {isOpen && <Box>{list}</Box>}
                    </>
                );
            })}
        </>
    );
}
