import React, { useEffect, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import ComponentSettings = ReportContentNS.ComponentSettings;
import { reportAPI } from 'api/report';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { ActAfterSaveSuccess, ActSubmit } from 'components/common/form/act';
import { instance } from 'api/api';
import { AxiosResponse } from 'axios';
import { APIResponse } from 'tools/types';
import { InfoPopupWrapper, useInfoPopup } from 'components/common/popup/InfoPopup';
import useBundleTranslation from 'i18n';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { getElementFieldValue } from 'components/common/form/formTools';
import { FormRendererAPIType } from 'components/common/form';

export interface ISaveReportContent {
    blocks: Array<string>;
    components: Array<{
        uid: string;
        blockUid: string;
        internal_name: string;
        settings: string;
    }>;
    filters: any;
}

async function reportContentForSave(form: FormRendererAPIType, queryClient: QueryClient): Promise<ISaveReportContent> {
    const processForm = (): ISaveReportContent => {
        const data: ISaveReportContent = { blocks: [], components: [], filters: {} };

        const value = form.hookFormGetValues('content');
        const filtersValue = form.hookFormGetValues('content_filters');

        const filters: BlockType<ComponentSettings> | false = JSON.parse(filtersValue);
        const content: Array<BlockType<ComponentSettings>> = JSON.parse(value);

        const list = content.filter((block) => block.component);

        list.forEach((block) => {
            data.blocks.push(block.uid);
            data.components.push({
                uid: block.uid + '_1',
                blockUid: block.uid,
                internal_name: block.component.internalName,
                settings: JSON.stringify(block.component.settings),
            });
        });

        if (filters) {
            data.filters = filters.component.settings;
        }
        return data;
    };

    return new Promise((resolve, reject) => {
        let result = processForm();
        if (result.blocks.length == 0) {
            const elementId = Number(form.hookFormGetValues('element_id'));
            queryClient.resetQueries([`report_content_editor_${elementId}`]).then(() => {
                setTimeout(() => {
                    result = processForm();
                    resolve(result);
                }, 1000);
            });
        } else {
            resolve(result);
        }
    });
}

export default function ReportForm(props: IFormLayoutProps) {
    const queryClient = useQueryClient();
    const { t } = useBundleTranslation('app/editor/element/report');
    const invalidateQueries = () => {
        const elementId = Number(props.hookForm.form.hookFormGetValues('element_id'));
        // queryClient.invalidateQueries([`report_content_editor_${elementId}`]);
        queryClient.invalidateQueries([`report_content_editor_${elementId}_instances`]);
        queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] == `report_content_editor_${elementId}_segment_values`,
        });
    };

    const saveContent = async (response: any) => {
        let dataForSave: false | ISaveReportContent = false;
        try {
            dataForSave = await reportContentForSave(props.hookForm.form, queryClient);
        } catch (e) {
            console.log(e);
        }
        if (dataForSave) {
            const elementId = Number(props.hookForm.form.hookFormGetValues('element_id'));
            return reportAPI.saveReportContent(elementId, dataForSave).then(() => response);
        }
        return Promise.resolve(response);
    };

    props.hookForm.form.formAct.overrideAct(
        'afterSaveSuccess',
        class extends ActAfterSaveSuccess {
            public async exec(response: any, data: any): Promise<any> {
                return new Promise((resolve, reject) => {
                    if (window.location.hash != '#content') {
                        invalidateQueries();
                    }
                    setTimeout(() => {
                        props.hookForm.form.hookFormSetValue(
                            'last_segment_id',
                            props.hookForm.form.hookFormGetValues('segment_id'),
                            { shouldDirty: false }
                        );
                        return super.exec(response, data).then((response: any) => {
                            resolve(response);
                        });
                    }, 100);
                    setIsLoading(true);
                    saveContent(response).then((response) => {
                        if (dataSourceWasChanged) {
                            const elementId = Number(props.hookForm.form.hookFormGetValues('element_id'));
                            queryClient.resetQueries([`report_content_editor_${elementId}_instances`]);
                            queryClient.resetQueries([`report_content_editor_${elementId}`]);
                            setDataSourceWasChanged(false);
                            setTimeout(() => setIsLoading(false), 500);
                        } else {
                            setIsLoading(false);
                        }
                        return response;
                    });
                });
            }
        }
    );
    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                if (dataSourceWasChanged) {
                    props.hookForm.form.hookFormSetValue('content', '[]');
                    props.hookForm.form.hookFormSetValue('content_filters', 'false');
                }
                return super.exec(data);
            }
        }
    );

    // Form auto save
    const [hash, setHash] = useState(window.location.hash);
    useEffect(() => {
        if (hash != window.location.hash) {
            if (window.location.hash == '#content' && props.hookForm.form.hookFormState.isDirty) {
                props.hookForm.form.formAct.act.submit
                    .exec(props.hookForm.form.hookFormGetValues())
                    .then((response) => {
                        if (response?.data?.status == 'OK') {
                            invalidateQueries();
                            setTimeout(
                                () =>
                                    props.hookForm.form.hookFormSetValue(
                                        'last_segment_id',
                                        props.hookForm.form.hookFormGetValues('segment_id'),
                                        { shouldDirty: false }
                                    ),
                                100
                            );
                        }
                    });
            }
            setHash(window.location.hash);
        }
    }, [window.location.hash]);

    const [dataSourceWasChanged, setDataSourceWasChanged] = useState(false);
    const [lastDataSource, setLastDataSource] = useState<string>('');
    const selectedDataSource = props.hookForm.form.hookFormWatch('dataset_source') ?? '';

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (lastDataSource == '' && selectedDataSource != lastDataSource) {
            setLastDataSource(selectedDataSource);
            return;
        }

        const tmp = selectedDataSource.split('_');
        setDataSourceWasChanged(false);
        if (tmp.length == 2) {
            const elementId = props.hookForm.form.hookFormGetValues('element_id');
            setIsLoading(true);
            instance
                .get<
                    any,
                    AxiosResponse<
                        APIResponse<{
                            fields: Array<{
                                column_name: string;
                                reference_name: string;
                                value_type: string;
                            }>;
                        }>
                    >
                >(`/data/editor/report/${elementId}/check-dataset-change/ds/${tmp[0]}/filter/${tmp[1]}`)
                .then((response) => {
                    const sourceLabel =
                        getElementFieldValue(props.hookForm.form, 'dataset_source.$option.label', {}) ?? '';
                    if (response.data.status != 'OK' || response.data.data.fields.length == 0) {
                        return;
                    }
                    setDataSourceWasChanged(true);
                    infoPopupSuccess.setContent(
                        <>
                            <div>
                                {t('dataset_source_changed_fields', {
                                    source: sourceLabel,
                                    list: response.data.data.fields
                                        .map((f) => `${f.column_name} (${f.value_type})`)
                                        .join(', '),
                                })}
                            </div>
                            <div>
                                <strong>{t('dataset_source_changed_fields_2')}</strong>
                            </div>
                        </>
                    );
                    infoPopupSuccess.setIsOpen(true);
                })
                .finally(() => setIsLoading(false));
        }
    }, [selectedDataSource]);

    const infoPopupSuccess = useInfoPopup();
    return (
        <>
            {isLoading && <LoadingPlaceholder />}
            <InfoPopupWrapper infoPopup={infoPopupSuccess} />
            <FormLayoutComposer props={{ ...props }} />
        </>
    );
}
