import { GridDataRow } from 'components/common/grid';
import { FormComponentValue } from 'components/common/form/layout/control';
import ReactSelect from 'components/common/react-select/ReactSelect';
import React from 'react';
import { Box } from '@mui/material';
import useBundleTranslation from 'i18n';

function AggregationSelect({
    row,
    onChange,
}: {
    row: GridDataRow;
    onChange: (fieldId: string, value: string) => void;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const selectedValue = String(row.aggregation);
    const fieldId = String(row.id);

    const dateSelectValues: FormComponentValue[] = [
        {
            label: '',
            value: '',
        },
        {
            label: t('fields_selector.aggregation.min'),
            value: 'Min',
        },
        {
            label: t('fields_selector.aggregation.max'),
            value: 'Max',
        },
        {
            label: t('fields_selector.aggregation.count'),
            value: 'Count',
        },
    ];

    const numericSelectValues: FormComponentValue[] = [
        ...dateSelectValues,
        {
            label: t('fields_selector.aggregation.sum'),
            value: 'Sum',
        },
        {
            label: t('fields_selector.aggregation.avg'),
            value: 'Avg',
        },
    ];

    return (
        <Box sx={{ paddingTop: '2px' }}>
            <ReactSelect
                data={row.type === 'datetime' ? dateSelectValues : numericSelectValues}
                value={selectedValue}
                update={(value) => {
                    onChange(fieldId, value);
                }}
            />
        </Box>
    );
}

export default AggregationSelect;
