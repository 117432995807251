import { Box } from '@mui/material';
import React from 'react';
import { CustomCellProps } from 'components/common/grid/';
import useBundleTranslation from 'i18n';
import { getCellContent, getCellWithTooltip } from './index';

export default function PopupCell({
    text,
    tooltip,
    href,
    icon,
    popupType,
    popupMode,
    popupConfig,
    setPopupUrl,
    setPopupConfig,
    setPopupType,
    setPopupMode,
    t,
    rawHtml,
    align,
    subText,
    rawTooltipHtml,
    setPopupComponent,
    setPopupComponentProps,
    component,
    popupComponentProps,
}: CustomCellProps) {
    if (undefined === t) t = useBundleTranslation('main')['t'];
    //const textValue = text ? t(String(text)) : '';
    const textValue = text ? String(text) : '';

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (setPopupType) {
            setPopupType(popupType ?? 'page');
        }

        if (setPopupConfig) {
            setPopupConfig(popupConfig ?? {});
        }

        if (setPopupUrl) {
            setPopupUrl(href ?? '');
        }

        if (setPopupMode) {
            setPopupMode(popupMode ?? 'new');
        }

        if (setPopupComponent) {
            setPopupComponent(component ?? '');
        }
        if (setPopupComponentProps) {
            setPopupComponentProps(popupComponentProps ?? {});
        }
    };

    const button = (
        <Box component="a" onClick={onClick} sx={{ cursor: 'pointer' }}>
            {getCellContent(textValue, icon, rawHtml, align, subText)}
        </Box>
    );

    if (tooltip) {
        return getCellWithTooltip(button, tooltip, rawTooltipHtml, t);
    }

    return button;
}
