import { OrderDirection, ReportContentNS } from 'components/report-content';
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotDataCell = ReportContentNS.PivotDataCell;

export interface PivotHeaderDropDownFieldType {
    field: PivotDataCell | null;
    uiFieldIndex: number | null;
    realIndex: number;
    anchorEl: any;
}

// Header DropDown Field + Position
export interface HeaderDropDownFieldType<T extends ComponentSettingsDatasetField> {
    field: T | null;
    anchorEl: any;
}

export interface DatasetCellValue {
    field: ComponentSettingsDatasetField;
    value: string | number | null;
    hidden: boolean;
}

export const HeaderResizableClassName = 'dHeaderResizable';
export const HeaderDropDownTriggerClassName = 'dDropDownTrigger';

export function fieldToSortField(
    field: ComponentSettingsDatasetField,
    direction: OrderDirection
): ComponentSettingsDatasetSortField {
    return {
        column_name: field.column_name,
        dir: direction,
        reference_name: field.reference_name,
        reference_name_escaped: field.reference_name_escaped,
        value_type: field.value_type,
    };
}

const DefaultTotalWidth = 940;
const MinColumnWidth = 80;

type SettingsType = ComponentSettingsDataset | ComponentSettingsPivot;

export function applyAutoStretch<T extends SettingsType>(settings: T, blockComponentId: number): T {
    let result = { ...settings, autoStretch: true };
    const totalWidth = settings?.currentInnerWidth ?? DefaultTotalWidth;

    // Pivot
    if ((settings as ComponentSettingsPivot).uiFields) {
        const block = document.getElementById(`report_block_${blockComponentId}`);
        if (block) {
            const uiList = block.getElementsByClassName('dUiField');

            let singleColumnWidth = Math.floor(totalWidth / uiList.length);
            if (singleColumnWidth < MinColumnWidth) {
                singleColumnWidth = MinColumnWidth;
            }
            for (let i = 0; i < uiList.length; i++) {
                //@ts-ignore
                const uiIndex = uiList[i]?.dataset?.uiindex;
                (result as ComponentSettingsPivot).uiFields[uiIndex].columnSize = singleColumnWidth;
            }
        }
        return result;
    }

    const visibleColumns = settings.fields.filter(
        (f) => f.show_column_in_table_display_ind == 'Y' && f.break_column != 'Y'
    );
    let singleColumnWidth = Math.floor(totalWidth / visibleColumns.length);
    if (singleColumnWidth < MinColumnWidth) {
        singleColumnWidth = MinColumnWidth;
    }

    result.fields = settings.fields.map((f) => {
        return { ...f, columnSize: singleColumnWidth };
    });

    return result;
}
