import {
    MetricChartChartingInterval,
    MetricChartState,
    MetricChartStateLegend,
    MetricChartViewsMode,
} from 'components/metric/chart';

export function handleChartStateLegendChange(
    setChartState: React.Dispatch<React.SetStateAction<MetricChartState>>,
    id: string,
    legendState: MetricChartStateLegend
) {
    setChartState((prevState) => {
        const legend = { ...prevState.legend };
        legend[id] = legendState;
        return { ...prevState, legend: legend };
    });
}

export function handleChartStateRangeChange(
    setChartState: React.Dispatch<React.SetStateAction<MetricChartState>>,
    interval: MetricChartChartingInterval
) {
    setChartState((prevState) => {
        let range;
        if (interval.chartingIntervalUnit == 'custom') {
            range = {
                interval_unit: interval.chartingIntervalUnit,
                min_value: interval.minValue,
                max_value: interval.maxValue,
            };
        } else {
            range = { interval_unit: interval.chartingIntervalUnit, interval_value: interval.chartingIntervalValue };
        }

        return {
            ...prevState,
            range: range,
        };
    });
}

export function handleChartViewChange(
    setChartState: React.Dispatch<React.SetStateAction<MetricChartState>>,
    newView: MetricChartViewsMode
) {
    setChartState((prevState) => {
        return {
            ...prevState,
            view: newView,
        };
    });
}

const NegativeChartColors = ['#FF0000', '#FF6600', '#800080', '#99CC00', '#CC99FF', '#333399'];
export function getNegativeSeriesColor(i: number) {
    return NegativeChartColors[i % NegativeChartColors.length];
}
