import { FormPanelButtonProps } from 'components/common/form';
import { IconButton, Stack, Tooltip } from '@mui/material';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';

export default function FormPanelButtonDuplicate({ button, formActions, isSubMenuItem }: FormPanelButtonProps<any>) {
    const { t } = useBundleTranslation(button.translationNS ?? 'components/common/form/form');
    const handleDuplicate = function () {
        formActions.onDuplicate(button.props.popupSettings);
    };

    const label = button?.label ? t(button.label) : '';

    if (isSubMenuItem)
        return (
            <ActionMenuItem
                label={label}
                desc={button.menuItemDesc ? t(button.menuItemDesc) : undefined}
                icon="copy"
                onClick={handleDuplicate}
            />
        );

    return (
        <Tooltip title={label}>
            <IconButton sx={{ p: '6px', fontSize: 12 }} onClick={handleDuplicate}>
                <IconMi icon="copy" fontSize="16" />
            </IconButton>
        </Tooltip>
    );
}
