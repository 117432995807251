import { useCallback, useState, useEffect } from 'react';

export function on<T extends Window | Document | HTMLElement | EventTarget>(
    obj: T | null,
    ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
    if (obj && obj.addEventListener) {
        obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
    }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
    obj: T | null,
    ...args: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
): void {
    if (obj && obj.removeEventListener) {
        obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
    }
}
const useLifecycles = (mount: any, unmount?: any) => {
    useEffect(() => {
        if (mount) {
            mount();
        }
        return () => {
            if (unmount) {
                unmount();
            }
        };
    }, []);
};

export const useHash = () => {
    const [hash, setHash] = useState(() => window.location.hash);

    const onHashChange = useCallback(() => {
        setHash(window.location.hash);
    }, []);

    useLifecycles(
        () => {
            on(window, 'hashchange', onHashChange);
        },
        () => {
            off(window, 'hashchange', onHashChange);
        }
    );

    const _setHash = useCallback(
        (newHash: string) => {
            if (newHash !== hash) {
                if ('' == newHash)
                    history.pushState('', document.title, window.location.pathname + window.location.search);
                else history.pushState(null, '', newHash > '' ? '#' + newHash : '');
                setHash(newHash);
            }
        },
        [hash]
    );

    return [hash, _setHash] as const;
};
