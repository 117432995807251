import { ReportContentNS } from 'components/report-content/index';
import React, { useEffect, useState } from 'react';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Chart as HChart } from 'highcharts';
import { ChartPoint } from 'components/metric/chart';
import Chart from 'components/chart/Chart';
import ComponentSettingsFunnelChart = ReportContentNS.ComponentSettingsFunnelChart;
import Funnel from 'highcharts/modules/funnel';
import Highcharts from 'highcharts/highstock';
import useRCChartData from 'components/report-content/hooks/useRCChartData';
import { applyChartOptionsForThumbnail, escapeHtml } from 'components/report-content/utils/tools';
import usePalette from 'components/report-content/hooks/usePalette';
import TooltipWrapper from 'components/report-content/utils/TooltipWrapper';
import FunnelChartTooltip from 'components/report-content/components/funnel-chart/FunnelChartTooltip';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
Funnel(Highcharts);

export default function FunnelChart({
    contentSettings,
    component,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsFunnelChart>) {
    const { initialData, data } = useRCChartData(component, contentSettings, updateBlockSettings, true);
    const isThumbnail = contentSettings.forScreenShot && window.location.href.includes('thumbnail');
    const options = {
        chart: {
            animation: false,
            type: 'funnel',
            style: {
                fontFamily: 'Arial, helvetica, sans-serif',
            },
            height: 600,
        },
        legend: { enabled: false },
        credits: { enabled: false },
        rangeSelector: { enabled: false },
        navigator: { enabled: false },
        scrollbar: { enabled: false },
        exporting: { enabled: false },
        title: { text: '' },
        tooltip: {
            enabled: false,
            useHTML: false,
            formatter: function (tooltip: any) {
                //@ts-ignore
                return tooltip.defaultFormatter.call(this, tooltip);
            },
        },
        plotOptions: {
            series: {
                animation: !contentSettings.forScreenShot,
                turboThreshold: 0,
                dataLabels: {
                    enabled: !isThumbnail,
                    useHTML: true,
                    formatter: function (): any {
                        // @ts-ignore
                        const key = this.key;
                        // @ts-ignore
                        const y = this.point?.y_f;
                        return (
                            `<b>${key}</b>` +
                            ('Y' === component.settings.display_value && 'undefined' !== typeof y ? ' (' + y + ')' : '')
                        );
                    },
                    // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                    softConnector: true,
                },
                center: ['40%', '50%'],
                neckWidth: '30%',
                neckHeight: '25%',
                width: '80%',
                point: {
                    events: {
                        click: function () {
                            // self.drawDrillToTooltip(this);
                        },
                    },
                },
            },
        },
        series: [
            {
                name: component.settings.value_field.column_name,
                data: [] as Array<any>,
            },
        ],
    };

    const { getPaletteColor, localPalette, setLocalPalette } = usePalette(
        contentSettings.context,
        contentSettings.elementId,
        Highcharts.getOptions().colors as Array<string>,
        component.settings.localPalette
    );

    const [chartOptions, setChartOptions] = useState(options);
    useEffect(() => {
        if (isThumbnail) {
            //@ts-ignore
            applyChartOptionsForThumbnail(options);
        } else {
            options.chart.height = component.settings.height;
        }

        if (data.length == 0) {
            // if(!isPreview) {
            //     self.triggerAfterDataLoaded(false);
            // }
            // TODO:
            // self.el
            //     .find('.block-wrapper-chart-container:first')
            //     .html(
            //         '<img style="width:' +
            //             (self.settings.width > 0 ? self.settings.width + 'px' : '100%') +
            //             ';height:' +
            //             (self.settings.height > 0 ? self.settings.height + 'px' : '100%') +
            //             ';" src="' +
            //             globalConstants.homeSite +
            //             'img/default_preview_no_data.png' +
            //             '"/>'
            //     );
        } else {
            // if(!isPreview) {
            //     self.triggerAfterDataLoaded(true);
            // }

            for (const [k, v] of Object.entries(data)) {
                const tmpName =
                    v[component.settings.x_field.reference_name + '-formatted'] ??
                    escapeHtml(v[component.settings.x_field.reference_name]);
                options.series[0].data.push({
                    name: tmpName,
                    color: getPaletteColor(tmpName, Number(k), false),
                    y: parseFloat(v[component.settings.value_field.reference_name]),
                    y_f:
                        v[component.settings.value_field.reference_name + '-formatted'] ??
                        escapeHtml(v[component.settings.value_field.reference_name]),
                });
            }
        }
        setTimeout(() => {
            try {
                chart?.update({ tooltip: { enabled: true } });
            } catch (e) {}
        }, 100);
        setChartOptions(options);
    }, [data, component.settings]);

    const [chart, setChart] = useState<HChart>();
    useComponentReady(chart);
    const [hoveredChartPoint, setHoveredChartPoint] = useState<ChartPoint | null>(null);
    return initialData ? (
        <div style={{ overflow: 'hidden', maxHeight: component.settings.height + 'px', position: 'relative' }}>
            {/*@ts-ignore*/}
            <Chart chartOptions={chartOptions} afterChartCreated={(chart: HChart) => setChart(chart)} />
            {chart && (
                <TooltipWrapper chart={chart}>
                    {(formatterContext) => <FunnelChartTooltip context={formatterContext} />}
                </TooltipWrapper>
            )}
        </div>
    ) : (
        // TODO: add loading mask
        <span></span>
    );
}
