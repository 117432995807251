import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import useBundleTranslation from 'i18n';

export default function ExternalLinkButton({
    externalLink,
    externalLinkTitle,
}: {
    externalLink: string;
    externalLinkTitle?: string;
}) {
    const { t } = useBundleTranslation(['components/common/viewer_controls_panel']);

    return (
        <span data-test={'top-external-link-icon'}>
            <Tooltip title={externalLinkTitle ?? t('open_external_report_btn_tooltip')}>
                <IconButton sx={{ p: '6px' }} component="a" target="_blank" href={externalLink}>
                    <IconMi icon="external-link" fontSize="16" />
                </IconButton>
            </Tooltip>
        </span>
    );
}
