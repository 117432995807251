import React, { useContext, useEffect, useState } from 'react';
import BlockEditPanelControls from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelControls';
import { ReportContentNS } from 'components/report-content';
import BlockType = ReportContentNS.BlockType;
import { EditPanelContext } from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanel';
import { AssocArray, YNValues } from 'tools/types';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import useBundleTranslation from 'i18n';
import ComponentSettingsExternalVisualization = ReportContentNS.ComponentSettingsExternalVisualization;
import { useQuery } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import { FormComponentValue } from 'components/common/form/layout/control';
import EditPanelCheckBox from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/EditPanelCheckBox';
import { editorExternalReferenceAPI } from 'api/editor/external-reference';
import { ExternalFilterEntityType } from 'app/editor/external-filters';
import { RowValues } from 'components/common/select-map';
import { prepareFormElementProps } from 'components/common/form/formTools';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { FormDataAPIType } from 'components/common/form';
import ComponentSettingsExternalVisualizationMapColumnFilter = ReportContentNS.ComponentSettingsExternalVisualizationMapColumnFilter;
import ColumnFilterMap from 'components/report-content/components/external-visualization/controls/ColumnFilterMap';
import ComponentSettingsExternalVisualizationDataQueries = ReportContentNS.ComponentSettingsExternalVisualizationDataQueries;
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import IBlocksContext = ReportContentNS.IBlocksContext;
import BlocksContext = ReportContentNS.BlocksContext;
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import BlockEditPanelHeader from 'app/editor/report/content-editor/block-edit-panel/BlockEditPanelHeader';
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import GetEmbedCode from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/GetEmbedCode';
import IncludeAsAttachment from 'app/editor/report/content-editor/block-edit-panel/edit-panel-components/IncludeAsAttachment';

interface VisualizationElementProps {
    ext_ref: number;
}

// Field for form
const componentFormFields: Array<keyof ComponentSettingsExternalVisualization> = [
    'title',
    'report_ext_vis_element_id',
    'include_in_email',
    'include_in_email_attachment',
    'show_in_viewer',
    'link_to_dataset',
    'height',
    'report_refer_from_email_to',
    'report_ext_vis_applicable_scope',
    'report_max_viz_number_to_display',
    'report_ext_vis_images_in_email',
    'dataset_block_uid',
];

export default function ExternalVisualizationEditPanel({
    component,
    block,
    contentSettings,
    actions,
}: ComponentUpdateProps<ComponentSettingsExternalVisualization> & {
    block: BlockType<ComponentSettingsExternalVisualization>;
}) {
    const { t } = useBundleTranslation(['components/report-content']);
    const editPanelContext = useContext(EditPanelContext);
    const datasetFields = editPanelContext?.datasetFields ?? [];

    // Get Dataset Tables
    const [tablesList, setTablesList] = useState<Array<FormComponentValue>>(
        actions
            .getBlocks()
            .filter((block) => block.component.internalName == 'dataset')
            .map((block: BlockType<ComponentSettingsDataset>, index) => ({
                value: block.uid,
                label:
                    block.component.settings?.title?.trim()?.length == 0
                        ? t('titles.dataset') + ' ' + (index + 1)
                        : block.component.settings.title,
            }))
    );

    let defaultDatasetBlockUId = component.settings?.dataset_block_uid ?? '';
    if (tablesList.length && tablesList.findIndex((v) => v.value == defaultDatasetBlockUId) == -1) {
        defaultDatasetBlockUId = tablesList[0].value;
    }

    // Prepare data for Form
    const defaultState: AssocArray<any> = {
        title: component.settings?.title ?? '',
        include_in_email: component.settings.include_in_email ?? 'Y',
        include_in_email_attachment: component.settings.include_in_email_attachment ?? 'Y',
        show_in_viewer: component.settings.show_in_viewer ?? 'Y',
        link_to_dataset: component.settings.link_to_dataset ?? 'Y',
        height: component.settings.height ?? 600,
        report_ext_vis_element_id: component.settings?.report_ext_vis_element_id ?? 0,
        report_refer_from_email_to: component.settings?.report_refer_from_email_to ?? 'self',
        report_ext_vis_applicable_scope: component.settings?.report_ext_vis_applicable_scope ?? 'row',
        report_max_viz_number_to_display: component.settings?.report_max_viz_number_to_display ?? 5,
        report_ext_vis_images_in_email: component.settings?.report_ext_vis_images_in_email ?? 10,
        dataset_block_uid: defaultDatasetBlockUId,
    };

    const elementProps: FormDataAPIType = useCustomSimplifiedForm(defaultState);

    // Apply Button Click save all changes to ReportState
    const handleApply = () => {
        // Prepare DataQuery
        let dataQueries: ComponentSettingsExternalVisualizationDataQueries = {};
        const linkToDataset = elementProps.form.hookFormGetValues('link_to_dataset');
        if (linkToDataset == 'Y') {
            const resultFields = mapFilters.map((filter) => filter.reference_name);
            const displayMasks: any = {};
            mapFilters.forEach((filter) => {
                if (filter.display_mask_id != 'undefined') {
                    displayMasks[filter.reference_name] = filter.display_mask_id;
                }
            });
            dataQueries.filters = {
                resultFields: resultFields,
                displayMasks: displayMasks,
                distinct: true,
            };
        } else {
            dataQueries.table = {
                resultFields: ['*'],
                resultFieldAggregations: {},
                limit: 1,
            };
        }

        const newSettings: ComponentSettingsExternalVisualization = {
            ...component.settings,
            filters: mapFilters,
            filtersOnDataset: mapDatasetFilters,
            dataQueries: dataQueries,
        } as ComponentSettingsExternalVisualization;
        // @ts-ignore
        componentFormFields.forEach((f) => (newSettings[f] = elementProps.form.hookFormGetValues(f)));
        editPanelContext?.updateBlockSettings(block.uid, newSettings, true);
    };

    const vizListQueryKey = `report_block_external_visualization_list`;
    const { data: vizListValues } = useQuery<Array<FormComponentValue>, Error>([vizListQueryKey], () => {
        return reportAPI.getReportVisualizationList(contentSettings.elementId);
    });

    const fExtVisElementId = Number(elementProps.form.hookFormWatch('report_ext_vis_element_id'));

    const getVisualizationElementProps = (elementId: number): VisualizationElementProps | false => {
        const selectedValue = vizListValues?.find((v) => Number(v.value) == fExtVisElementId);
        if (!selectedValue) {
            return false;
        }
        return {
            ext_ref: Number(selectedValue.props?.ext_ref ?? 0),
        };
    };

    const [selectedVizElementProps, setSelectedVizElementProps] = useState<VisualizationElementProps | false>(false);

    useEffect(() => {
        const newProps = getVisualizationElementProps(fExtVisElementId);
        if (JSON.stringify(newProps) != JSON.stringify(selectedVizElementProps)) {
            setSelectedVizElementProps(newProps);
        }
    }, [fExtVisElementId, vizListValues]);

    const { data: vizFieldsList } = useQuery<Array<ExternalFilterEntityType> | false, Error>(
        ['report_block_ext_viz_fields_list', fExtVisElementId, selectedVizElementProps],
        () => {
            if (!selectedVizElementProps || fExtVisElementId == 0) {
                return false;
            }
            return editorExternalReferenceAPI.getFilters(fExtVisElementId, selectedVizElementProps.ext_ref);
        }
    );

    const fShowInViewer = elementProps.form.hookFormWatch('show_in_viewer') == 'Y';
    const fIncludeInEmail = elementProps.form.hookFormWatch('include_in_email') == 'Y';
    const fLinkToDataset: YNValues | 'D' = elementProps.form.hookFormWatch('link_to_dataset');

    const fHasInstances =
        elementProps.form.hookFormWatch('link_to_dataset') == 'Y' &&
        elementProps.form.hookFormWatch('report_ext_vis_applicable_scope') == 'row';

    // ColumnFilterMap
    const [mapFilters, setMapFilters] = useState<Array<ComponentSettingsExternalVisualizationMapColumnFilter>>(
        component.settings.filters
    );
    const [columnFilterMap] = useState<Array<RowValues>>(
        component.settings.filters.map((f) => ({
            firstColumn: f.reference_name,
            secondColumn: String(f.external_filter_id),
            data: null,
        }))
    );
    const handleColumnFilterMapChange = (filters: Array<ComponentSettingsExternalVisualizationMapColumnFilter>) => {
        setMapFilters(filters);
    };

    //ReportFilter FilterMap
    const blocksContext = useContext<IBlocksContext | null>(BlocksContext);
    const [mapDatasetFilters, setMapDatasetFilters] = useState<
        Array<ComponentSettingsExternalVisualizationMapColumnFilter>
    >(component.settings.filters);

    const handleDatasetFilterMapChange = (filters: Array<ComponentSettingsExternalVisualizationMapColumnFilter>) => {
        setMapDatasetFilters(filters);
    };

    const [filtersListValues, setFiltersListValues] = useState<Array<any>>([]);
    const [datasetFilterMap] = useState<Array<RowValues>>(
        component.settings.filtersOnDataset.map((f) => ({
            firstColumn: f.reference_name,
            secondColumn: String(f.external_filter_id),
            data: null,
        }))
    );
    useEffect(() => {
        if (!blocksContext?.manageFiltersSettings?.fields) {
            return;
        }

        setFiltersListValues(
            blocksContext.manageFiltersSettings.fields.filter(
                (field) => field.filter_column == 'Y' && field.filterType != 'range'
            )
        );
    }, [blocksContext?.manageFiltersSettings]);
    const actualHeight = elementProps.form.hookFormWatch('height');
    return (
        <>
            <Stack height={1}>
                <Box flexShrink={0}>
                    <BlockEditPanelHeader
                        title={t('external_viz.edit_title')}
                        onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                    />
                </Box>
                <Stack sx={{ overflow: 'auto', flexGrow: 1, p: 3 }} spacing={2}>
                    {/*Main Panel*/}
                    {vizListValues && (
                        <Box>
                            <FormLabel>{t('external_viz.external_visualization')}</FormLabel>
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        component: 'FormSelect',
                                        name: 'report_ext_vis_element_id',
                                    },
                                })}
                                componentValues={vizListValues}
                            />
                        </Box>
                    )}

                    <Box>
                        <ReactHookFormController
                            elementProps={prepareFormElementProps({
                                ...elementProps,
                                component: { component: 'FormText', name: 'title', label: t('title') },
                            })}
                        />
                    </Box>

                    <Divider />
                    {vizFieldsList && vizFieldsList.length > 0 && (
                        <>
                            <Box>
                                <ReactHookFormController
                                    elementProps={prepareFormElementProps({
                                        ...elementProps,
                                        component: {
                                            component: 'FormRadioGroup',
                                            name: 'link_to_dataset',
                                            label: t('link_to_dataset'),
                                            props: {
                                                row: false,
                                            },
                                        },
                                    })}
                                    componentValues={[
                                        { value: 'N', label: t('external_viz.link_do_not') },
                                        { value: 'Y', label: t('external_viz.link_data_table') },
                                        { value: 'D', label: t('external_viz.link_filters') },
                                    ]}
                                />
                            </Box>
                            <Divider />
                        </>
                    )}
                    {vizFieldsList &&
                        vizFieldsList.length > 0 &&
                        ((fLinkToDataset == 'Y' && (
                            <>
                                {tablesList.length > 1 && (
                                    <Box>
                                        <FormLabel>{t('titles.dataset')}</FormLabel>
                                        <ReactHookFormController
                                            elementProps={prepareFormElementProps({
                                                ...elementProps,
                                                component: {
                                                    component: 'FormSelect',
                                                    name: 'dataset_block_uid',
                                                },
                                            })}
                                            componentValues={tablesList}
                                        />
                                    </Box>
                                )}
                                <ColumnFilterMap
                                    vizFieldsList={vizFieldsList}
                                    elementProps={elementProps}
                                    datasetFields={datasetFields}
                                    columnFilterMap={columnFilterMap}
                                    onChange={handleColumnFilterMapChange}
                                />
                                <Box>
                                    <EditPanelCheckBox
                                        YValue={'once'}
                                        NValue={'row'}
                                        elementProps={elementProps}
                                        name={'report_ext_vis_applicable_scope'}
                                        label={t(
                                            fShowInViewer
                                                ? 'external_viz.scope_each_filter'
                                                : 'external_viz.scope_filters_together'
                                        )}
                                    />
                                </Box>
                            </>
                        )) ||
                            (fLinkToDataset == 'D' && (
                                <ColumnFilterMap
                                    vizFieldsList={vizFieldsList}
                                    elementProps={elementProps}
                                    datasetFields={filtersListValues}
                                    columnFilterMap={datasetFilterMap}
                                    onChange={handleDatasetFilterMapChange}
                                />
                            )))}

                    <Box>
                        <EditPanelCheckBox
                            elementProps={elementProps}
                            name={'show_in_viewer'}
                            label={t('show_in_viewer')}
                        />

                        {fShowInViewer && (
                            <>
                                <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ pl: 3, mt: 1 }}>
                                    <Box>Height of visualization (px)</Box>
                                    <Box sx={{ width: '60px' }}>
                                        <ReactHookFormController
                                            elementProps={prepareFormElementProps({
                                                ...elementProps,
                                                inputFilter: 'int',
                                                component: { component: 'FormText', name: 'height', label: t('') },
                                            })}
                                        />
                                    </Box>
                                </Stack>

                                {fHasInstances && (
                                    <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ pl: 3, mt: 1 }}>
                                        <Box>{t('external_viz.report_max_viz_number_to_display')}</Box>
                                        <Box sx={{ width: '60px' }}>
                                            <ReactHookFormController
                                                elementProps={prepareFormElementProps({
                                                    ...elementProps,
                                                    inputFilter: 'int',
                                                    component: {
                                                        component: 'FormText',
                                                        name: 'report_max_viz_number_to_display',
                                                        label: '',
                                                    },
                                                })}
                                            />
                                        </Box>
                                    </Stack>
                                )}
                            </>
                        )}
                    </Box>

                    <Box>
                        <EditPanelCheckBox
                            elementProps={elementProps}
                            name={'include_in_email'}
                            label={t('include_in_email')}
                        />

                        {fIncludeInEmail && (
                            <>
                                <Box sx={{ pl: 3, mt: 1 }}>
                                    <FormLabel>{t('external_viz.links_should_go_to')}</FormLabel>
                                    <Box>
                                        <ReactHookFormController
                                            elementProps={prepareFormElementProps({
                                                ...elementProps,
                                                component: {
                                                    component: 'FormToggleButtonGroup',
                                                    name: 'report_refer_from_email_to',
                                                    props: { row: true },
                                                },
                                            })}
                                            componentValues={[
                                                { value: 'self', label: t('external_viz.this_report') },
                                                { value: 'ext_vis', label: t('external_viz.external_visualization') },
                                            ]}
                                        />
                                    </Box>
                                </Box>

                                {fHasInstances && (
                                    <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ pl: 3, mt: 1 }}>
                                        <Box>{t('external_viz.report_max_viz_number_to_display')}</Box>
                                        <Box sx={{ width: '60px' }}>
                                            <ReactHookFormController
                                                elementProps={prepareFormElementProps({
                                                    ...elementProps,
                                                    inputFilter: 'int',
                                                    component: {
                                                        component: 'FormText',
                                                        name: 'report_ext_vis_images_in_email',
                                                        label: '',
                                                    },
                                                })}
                                            />
                                        </Box>
                                    </Stack>
                                )}
                            </>
                        )}
                    </Box>
                    <IncludeAsAttachment componentName={component.internalName} elementProps={elementProps} t={t} />
                    <Box>
                        <GetEmbedCode
                            contentSettings={contentSettings}
                            height={actualHeight}
                            blockUID={block.uid}
                            t={t}
                        />
                    </Box>
                </Stack>
                <Box flexShrink={0}>
                    <BlockEditPanelControls
                        onApply={handleApply}
                        onCancel={() => editPanelContext?.closeEditPanel(block.uid, component.settings)}
                    />
                </Box>
            </Stack>
        </>
    );
}
