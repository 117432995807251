import { Box, IconButton, Stack, TextField } from '@mui/material';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import useBundleTranslation from 'i18n';
import styles from './DatePicker.styles';
export default function DatePicker(props: ReactDatePickerProps) {
    const { t } = useBundleTranslation();
    const customHeader = ({
        monthDate,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
    }: ReactDatePickerCustomHeaderProps) => {
        return (
            <Stack direction={'row'} alignItems={'center'} sx={styles.customHeader}>
                <IconButton
                    onClick={decreaseYear}
                    sx={{ ...styles.headerIconBtn, mr: 2 }}
                    disabled={prevYearButtonDisabled}
                >
                    <IconMi icon={'chevrons-left'} sx={styles.headerIcon} />
                </IconButton>
                <IconButton onClick={decreaseMonth} sx={styles.headerIconBtn} disabled={prevMonthButtonDisabled}>
                    <IconMi icon={'chevron-left'} sx={styles.headerIcon} />
                </IconButton>
                <Box flexGrow={1} sx={{ textAlign: 'center' }}>
                    {monthDate.toLocaleString('en-US', {
                        month: 'short',
                        year: 'numeric',
                    })}
                </Box>
                <IconButton onClick={increaseMonth} sx={styles.headerIconBtn} disabled={nextMonthButtonDisabled}>
                    <IconMi icon={'chevron-right'} sx={styles.headerIcon} />
                </IconButton>
                <IconButton
                    onClick={increaseYear}
                    sx={{ ...styles.headerIconBtn, ml: 2 }}
                    disabled={nextYearButtonDisabled}
                >
                    <IconMi icon={'chevrons-right'} sx={styles.headerIcon} />
                </IconButton>
            </Stack>
        );
    };

    //ToDo add translation locale to component
    return (
        <Box sx={styles.mainHolder}>
            <ReactDatePicker
                renderCustomHeader={customHeader}
                fixedHeight
                showPopperArrow={false}
                disabledKeyboardNavigation
                todayButton={t('calendar_today_btn')}
                popperClassName={'custom-datepicker__react-datepicker-popper'}
                calendarClassName={'custom-datepicker__calendar'}
                className={'custom-datepicker__field'}
                {...props}
            />
        </Box>
    );
}
