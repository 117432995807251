import { Box, Checkbox, Typography } from '@mui/material';
import useBundleTranslation from 'i18n';
import { AlertRuleElementData } from 'components/alert-subscriptions/index';
import React, { useEffect, useState } from 'react';
import { alertSubscriptionAPI } from 'api/api';
import IconMi from 'components/common/icon/IconMi';
import SubscriptionNotificationChangeBox from 'components/alert-subscriptions/parts/SubscriptionNotificationChangeBox';
import DimensionPopup from 'components/dimension-popup/DimensionPopup';
import styles from './SubscriptionList.styles';
import BookmarkManager from 'components/external-reference/bookmark/BookmarManager';
import { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';
import { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';

interface subscriptionListData {
    status: string;
    subscriptionInfo: any;
    segmentInfo: any;
    elementInfo: any;
    bookmarksInfo: any;
}
export interface SubscriptionListProps {
    elementInfo: AlertRuleElementData;
    maxSegmentsInSegmentLine?: number;
    onChangeState?: (updatedField: any) => void;
    onUpdateData?: (data: any) => void;
    bookmarksContext?: IBookmarksContext;
}

//new ElementSubscription
//subscription_list_template_line_.phtml
export default function SubscriptionList(props: SubscriptionListProps) {
    const { elementInfo, maxSegmentsInSegmentLine = 3, onChangeState = () => {}, onUpdateData = () => {} } = props;
    const { t } = useBundleTranslation(['components/alert-subscription/subscription_list']);
    const [subscriptionListData, setSubscriptionListData] = useState<subscriptionListData>();
    const [initialData, setInitialData] = useState<subscriptionListData>();
    const [changeBoxAnchor, setChangeBoxAnchor] = useState<HTMLElement | null>(null);
    const [showDimensionPopup, setShowDimensionPopup] = useState<boolean>(false);
    const [isMarkChangeBoxIcon, setIsMarkChangeBoxIcon] = useState<boolean>(false);

    const dataTestAttr = 'alert_subscription_popup';

    useEffect(() => {
        alertSubscriptionAPI
            .elementSubscriptionLoadData(elementInfo.elementId, elementInfo.segmentId)
            .then((response) => {
                if (response.data?.status === 'OK') {
                    setSubscriptionListData(response.data);
                    setInitialData(response.data);
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (subscriptionListData) onUpdateData(subscriptionListData);
    }, [subscriptionListData]);

    const handleSelectedBookmarksChange = (list: Array<number>) => {
        const initialBookmarksInfo = initialData?.bookmarksInfo ?? [];
        const currentBookmarksInfo = list ?? [];
        let isChanged = initialBookmarksInfo.length !== currentBookmarksInfo.length;

        if (!isChanged) {
            isChanged = !initialBookmarksInfo.every((item: number) => {
                return currentBookmarksInfo.includes(item);
            });
        }

        onChangeState({
            elementSubscription_bookmarks: isChanged,
        });
        setSubscriptionListData((prevState: any) => {
            return { ...(prevState ?? {}), bookmarksInfo: list };
        });
    };

    if (!subscriptionListData || !initialData) return null;

    const isSubscribed = subscriptionListData.subscriptionInfo.is_subscribed == 'Y';
    const subscriptionType = subscriptionListData.subscriptionInfo.subscription_type;

    const openNotificationBox = (event: React.MouseEvent<HTMLElement>) => {
        setIsMarkChangeBoxIcon(!!event.currentTarget.getAttribute('data-markicon'));
        event.preventDefault();
        setChangeBoxAnchor(event.currentTarget);
    };

    const subscribeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = event.target.checked ? 'Y' : 'N';
        setSubscriptionListData({
            ...subscriptionListData,
            subscriptionInfo: {
                ...subscriptionListData.subscriptionInfo,
                is_subscribed: newVal,
            },
        });

        onChangeState({ elementSubscription_isSubscribed: initialData.subscriptionInfo.is_subscribed !== newVal });
    };

    const getSubscriptionTypeTitle = (type: string) => {
        const titleType = 'immediate' == type ? 'immediate' : 'other_type';

        return t(`subscription_type_title.${titleType}`, { type: t(`subscription_type.${type}`) });
    };

    const getNotificationLine = () => {
        if (isSubscribed) {
            if ('immediate' == subscriptionType) {
                const toolName =
                    'external report' == subscriptionListData.elementInfo.type &&
                    subscriptionListData.elementInfo.reporting_tool_name
                        ? subscriptionListData.elementInfo.reporting_tool_name
                        : null;
                return t(`notification_line.${toolName ? 'immediate_with_name' : 'immediate'}`, {
                    reporting_tool_name: toolName,
                });
            } else {
                return t(`notification_line.other_type`, {
                    subscription_type_title: getSubscriptionTypeTitle(subscriptionType),
                });
            }
        }
        return '';
    };

    const getSubscriptionLine = () => {
        let segments: any[] = [];
        let allSegments: any[] = [];

        // If this element hasn't segments
        if (0 == subscriptionListData.segmentInfo.amount) {
            return (
                <Box>
                    {isSubscribed
                        ? t('subscription_line.has_not_segments_subscribed_y')
                        : t('subscription_line.has_not_segments_subscribed_n')}
                </Box>
            );
        }

        // If this element has segments
        subscriptionListData.subscriptionInfo.segments.every((item: any) => {
            if (item.id == -1) {
                allSegments.push(item);
                return false;
            } else {
                segments.push(item);
                if (segments.length >= maxSegmentsInSegmentLine) return false;
            }
            return true;
        });

        return (
            <Box>
                {isSubscribed
                    ? t('subscription_line.has_segments_subscribed_y')
                    : t('subscription_line.has_segments_subscribed_n')}{' '}
                {allSegments.length > 0 ? (
                    <Box component="a" href="#" onClick={openDimensionPopup} sx={styles.slLineInfoDimLinks}>
                        {t('subscription_line.all_segment_values_link', {
                            amount: subscriptionListData.segmentInfo.amount,
                        })}
                    </Box>
                ) : (
                    <>
                        {segments.map((item, index) => {
                            return (
                                <>
                                    <Box
                                        component="a"
                                        href="#"
                                        onClick={openDimensionPopup}
                                        sx={styles.slLineInfoDimLinks}
                                    >
                                        {item.title}
                                    </Box>
                                    {index !== segments.length - 1 ? ', ' : ''}
                                </>
                            );
                        })}
                        {maxSegmentsInSegmentLine < subscriptionListData.subscriptionInfo.segments.length && (
                            <>
                                {', '}
                                {t('subscription_line.and_other')}{' '}
                                <Box component="a" href="#" onClick={openDimensionPopup} sx={styles.slLineInfoDimLinks}>
                                    {t('subscription_line.and_other_segments', {
                                        count:
                                            subscriptionListData.subscriptionInfo.segments - maxSegmentsInSegmentLine,
                                    })}
                                </Box>
                            </>
                        )}
                    </>
                )}
            </Box>
        );
    };

    const getSegmentsAmountLine = () => {
        let amount = 0;
        const segments = subscriptionListData.subscriptionInfo.segments;
        //subscriptionListData.segmentInfo.amount
        //subscriptionListData.subscriptionInfo.segments

        if (segments.length) {
            if (-1 == segments[0].id) {
                amount = subscriptionListData.segmentInfo.amount;
            } else {
                amount = segments.length;
            }
        }

        return t('segments_amount_line.segment', { count: amount });
    };

    const referenceManager = {
        //TMP for bookmark widget
        inited: true,
        state: {
            rootState: {
                hasAccessToBookmarks: true,
            },
        },
    };

    const openDimensionPopup = (event: any) => {
        event.preventDefault();
        setShowDimensionPopup(true);
    };
    return (
        <Box>
            <Typography variant="heading" sx={{ flexGrow: 1, mb: 2 }}>
                {t('title_send_on_schedule')}
            </Typography>
            <Box sx={styles.slWrapper}>
                <Box sx={styles.slLine}>
                    <Checkbox
                        onChange={subscribeChange}
                        checked={isSubscribed}
                        data-test={dataTestAttr + '_subscribe_checkbox'}
                    />
                    <Box sx={styles.slLineInfo}>
                        <Box component="span" sx={styles.slLineOptions}>
                            {getSubscriptionLine()}
                            {isSubscribed && (
                                <Box
                                    component="a"
                                    href="#"
                                    onClick={openNotificationBox}
                                    sx={styles.slLineOptionsNotLink}
                                    className={'invert-link'}
                                    data-test={dataTestAttr + '_subscribe_settings_link'}
                                >
                                    {getNotificationLine()}
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box sx={styles.slLineDimensionCell}>
                        {subscriptionListData.segmentInfo.amount > 0 && (
                            <Box sx={styles.slLineDimension} onClick={openDimensionPopup}>
                                <IconMi icon="cube" sx={{ fontSize: '16px', mr: 0.5 }} />
                                <Box component="span">{getSegmentsAmountLine()}</Box>
                            </Box>
                        )}
                    </Box>

                    <Box sx={styles.slLineEnvelopeCell}>
                        {isSubscribed && (
                            <Box
                                onClick={openNotificationBox}
                                data-markicon
                                sx={isMarkChangeBoxIcon ? styles.slLineEnvelopeActive : styles.slLineEnvelope}
                                data-test={dataTestAttr + '_subscribe_settings'}
                            >
                                <IconMi icon="mail" sx={{ fontSize: '16px' }} />
                            </Box>
                        )}
                    </Box>
                </Box>
                {/*
                if(this.params.element_type == 'external report' && referenceManager.inited){
                // Draw bookmarks list;
                referenceManager.initBookmarkSubscriptionList(this.params.alertRuleListState, this.bookmarksInfo);
                }
                */}
                {elementInfo.type == 'external report' &&
                    isSubscribed &&
                    referenceManager.inited &&
                    referenceManager.state.rootState.hasAccessToBookmarks &&
                    subscriptionListData.segmentInfo.amount == 0 &&
                    props.bookmarksContext &&
                    (props.bookmarksContext.filters.filter((f) => !f.replacedBySegment && f.filterId > 0).length > 0 ||
                        props.bookmarksContext.siblingTabs.length > 1) && (
                        <Box sx={styles.slBookmarkBlock}>
                            <BookmarkManager
                                elementId={elementInfo.elementId}
                                segmentValueId={elementInfo.segmentId}
                                selectedBookmarks={subscriptionListData.bookmarksInfo}
                                onSelectedBookmarksChange={handleSelectedBookmarksChange}
                                bookmarks={props.bookmarksContext.bookmarks}
                                filters={props.bookmarksContext.filters}
                                siblingTabs={props.bookmarksContext.siblingTabs}
                                onDeleteClick={props.bookmarksContext.APIDeleteBookmark}
                                onDuplicateClick={props.bookmarksContext.APIDuplicateBookmark}
                                onSortEnd={props.bookmarksContext.APISortBookmarks}
                                referenceId={props.bookmarksContext.selectedReferenceId}
                                onUpdateClick={(bookmark: BookmarkType) =>
                                    props.bookmarksContext?.APIUpdateBookmark(
                                        bookmark,
                                        props.bookmarksContext.selectedReferenceId
                                    )
                                }
                            />
                            {/*<Box sx={styles.slBookmarkDragNote}>*/}
                            {/*    (<IconMi sx={{ transform: 'rotate(90deg)' }} icon="arrows-alt-h" /> drag & drop to sort)*/}
                            {/*</Box>*/}
                            {/*<Box sx={styles.slBookmarkEmpty}>There are no Bookmarks.</Box>*/}
                            {/*<Button variant="outlined" sx={{ mt: 1 }} startIcon={<IconMi icon="new" />}>*/}
                            {/*    Bookmark*/}
                            {/*</Button>*/}
                        </Box>
                    )}
            </Box>
            {changeBoxAnchor && (
                <SubscriptionNotificationChangeBox
                    types={subscriptionListData.subscriptionInfo.subscription_types}
                    value={subscriptionListData.subscriptionInfo.subscription_type}
                    anchorElement={changeBoxAnchor}
                    getLabelFunc={getSubscriptionTypeTitle}
                    mod={isMarkChangeBoxIcon ? 'itemIcon' : undefined}
                    onApply={(newSubscriptionType) => {
                        setSubscriptionListData({
                            ...subscriptionListData,
                            subscriptionInfo: {
                                ...subscriptionListData.subscriptionInfo,
                                subscription_type: newSubscriptionType,
                            },
                        });

                        onChangeState({
                            elementSubscription_subscribeType:
                                newSubscriptionType !== initialData?.subscriptionInfo.subscription_type,
                        });

                        setChangeBoxAnchor(null);
                        setIsMarkChangeBoxIcon(false);
                    }}
                    onCancel={() => {
                        setChangeBoxAnchor(null);
                        setIsMarkChangeBoxIcon(false);
                    }}
                />
            )}

            {showDimensionPopup && (
                <DimensionPopup
                    onClose={() => {
                        setShowDimensionPopup(false);
                    }}
                    onApply={(data) => {
                        let newVal = data.segments.map((item: any) => {
                            //replace segment_value_id key to id (check on backend format data)
                            const id = item.segment_value_id;
                            delete item.segment_value_id;
                            return { ...item, id: id };
                        });
                        if (data.radioType === 'all') {
                            newVal = [{ id: -1 }];
                        } else if (data.radioType === 'current') {
                            newVal = [{ id: data.current, title: subscriptionListData.segmentInfo.title }];
                        }

                        setSubscriptionListData({
                            ...subscriptionListData,
                            subscriptionInfo: {
                                ...subscriptionListData.subscriptionInfo,
                                segments: newVal,
                            },
                        });

                        //different count = changed
                        let isChanged = initialData.subscriptionInfo.segments.length != newVal.length;
                        if (!isChanged) {
                            //same count => check every item by id. if one of id don't find then changed
                            isChanged = initialData.subscriptionInfo.segments.some((item: any) => {
                                return !newVal.find((newItem: any) => newItem.id === item.id);
                            });
                        }
                        onChangeState({ elementSubscription_segments: isChanged });

                        setShowDimensionPopup(false);
                    }}
                    elementId={elementInfo.elementId}
                    segmentId={elementInfo.segmentId}
                    initialSelectedIds={initialData.subscriptionInfo.segments
                        .filter((segment: any) => segment.selected == 'Y')
                        .map((segment: any) => segment.id)}
                />
            )}
        </Box>
    );
}
