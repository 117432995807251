import { IFilterColumn } from 'components/report-content/components/manage-filters/index';
import { ReportContentNS } from 'components/report-content/index';
import ComponentSettingsManageFilters = ReportContentNS.ComponentSettingsManageFilters;
import ManageFiltersInitialDataResponse = ReportContentNS.ManageFiltersInitialDataResponse;
import Moment from 'moment/moment';

function parseDate(value: string): Date {
    //@ts-ignore
    const match: Array<number> | undefined = value.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
    if (!match) {
        return new Date();
    }

    match.shift(); // discard the "full match" index
    match[1]--;
    return new Date(match[0], match[1], match[2], match[3], match[4], match[5]);
}

function getStdFormatDate(date: Date) {
    return Moment(date).format('yyyy-MM-DD');
}

function getStdFormatDateTime(date: Date, withTime: boolean = false, withSec: boolean = false) {
    let dateString = getStdFormatDate(date);
    if (withTime) {
        dateString += ' ' + getStdFormatTime(date, withSec);
    }
    return dateString;
}

function padLeft(number: number, base: number = 10, chr: string = '0') {
    let len = String(base).length - String(number).length + 1;
    return len > 0 ? new Array(len).join(chr) + number : number;
}

function getStdFormatTime(time: Date, withSec: boolean = false) {
    let hh = padLeft(time.getHours()),
        ii = padLeft(time.getMinutes()),
        ss = padLeft(time.getSeconds()),
        timeString = [hh, ii].join(':');
    if (withSec) {
        timeString += ':' + ss;
    }
    return timeString;
}

export default function buildFilterColumns(
    settings: ComponentSettingsManageFilters,
    initialData?: ManageFiltersInitialDataResponse
) {
    if (!settings.fields) {
        return [];
    }
    return settings.fields
        .filter((field) => field.filter_column == 'Y')
        .map((field) => {
            // this.settings.fields[i].canBeCached = false;
            const fc: IFilterColumn = {
                uid: field.reference_name_escaped,
                reference_name: field.reference_name,
                filterType: field.filterType ?? 'dropdown',
                column_name: field.filter_label?.length ? field.filter_label : field.column_name,
                show_date_only: 'undefined' !== typeof field.show_date_only && 'Y' === field.show_date_only ? 'Y' : 'N',
                include_all_value: field.include_all_value ?? 'Y',
                value_type: field.value_type,
                column_sequence: field.column_sequence,
                savedValues: typeof field.savedValues !== 'undefined' ? field.savedValues : [],
                data: '',
                chartingIntervals: [],
                sortDirection: field.sortDirection ?? 'ASC',
            };

            if (initialData) {
                if (fc.filterType == 'dropdown') {
                    if (fc.savedValues?.length > 1) {
                        fc.savedValues = [fc.savedValues[0]];
                    }
                }

                if (field.filterType == 'range' || field.filterType == 'charting_interval') {
                    fc.data = {};

                    const from = field.reference_name + '-from';
                    const to = field.reference_name + '-to';

                    if (typeof initialData.dataResults?.[from]?.[0]?.[field.reference_name] != 'undefined') {
                        fc.data.from = initialData.dataResults[from][0][field.reference_name];
                    }

                    if (typeof initialData.dataResults?.[to]?.[0]?.[field.reference_name] != 'undefined') {
                        fc.data.to = initialData.dataResults[field.reference_name + '-to'][0][field.reference_name];
                    }

                    if (field.filterType == 'range' && field.value_type == 'datetime' && field.show_date_only == 'Y') {
                        if (fc.data?.from > '') {
                            try {
                                fc.data.from = fc.data.from.split(' ')[0];
                            } catch (e) {}
                        }
                        if (fc.data?.to > '') {
                            try {
                                fc.data.to = fc.data.to.split(' ')[0];
                            } catch (e) {}
                        }
                    }

                    if (fc.savedValues.length == 0) {
                        if (field.filterType == 'range') {
                            fc.savedValues[0] = fc.data.from ?? '';
                            fc.savedValues[1] = fc.data.to ?? '';
                        } else {
                            fc.savedValues = [''];
                        }
                    }

                    if (field.filterType == 'charting_interval') {
                        fc.chartingIntervals = [{ label: 'All', value: '' }];
                        if (fc.data.from) {
                            const steps = [
                                { label: '2 Months', v: 2 },
                                { label: '6 Months', v: 6 },
                                { label: '1 Year', v: 12 },
                                { label: '2 Years', v: 24 },
                                { label: '5 Years', v: 60 },
                                { label: '10 Years', v: 120 },
                            ];
                            let dt = parseDate(fc.data.to);
                            let dt2 = getStdFormatDateTime(new Date(dt.setDate(dt.getDate() - 28)), true, true);
                            fc.chartingIntervals.push({ label: '4 Weeks', value: dt2 });

                            if (dt2 > fc.data.from) {
                                for (let j = 0; j < steps.length; j++) {
                                    dt = parseDate(fc.data.to);
                                    dt2 = getStdFormatDateTime(
                                        new Date(dt.setMonth(dt.getMonth() - steps[j].v)),
                                        true,
                                        true
                                    );

                                    fc.chartingIntervals.push({ label: steps[j].label, value: dt2 });
                                    if (dt2 < fc.data.from) {
                                        break;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    fc.data = initialData?.dataResults?.[field.reference_name] ?? null;
                }
            }

            return fc;
        })
        .sort((a, b) => a.column_sequence - b.column_sequence);
}
