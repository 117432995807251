import { ReportContentNS } from 'components/report-content/index';
import React, { lazy, useEffect, useState } from 'react';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsBubbleChart = ReportContentNS.ComponentSettingsBubbleChart;
import { Chart as HChart } from 'highcharts';
import { ChartPoint } from 'components/metric/chart';
import { applyChartOptionsForThumbnail, escapeHtml } from 'components/report-content/utils/tools';
import TooltipWrapper from 'components/report-content/utils/TooltipWrapper';
import BubbleChartTooltip from 'components/report-content/components/bubble-chart/BubbleChartTooltip';
import useRCChartData from 'components/report-content/hooks/useRCChartData';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { formatValueFull } from 'components/report-content/utils/valueFormatting';
const Chart = lazy(() => import('components/chart/Chart'));

export default function BubbleChart({
    contentSettings,
    component,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsBubbleChart>) {
    const { initialData, data } = useRCChartData(component, contentSettings, updateBlockSettings, true);
    const isThumbnail = contentSettings.forScreenShot && window.location.href.includes('thumbnail');
    const options = {
        chart: {
            animation: false,
            type: 'bubble',
            plotBorderWidth: 1,
            zoomType: 'xy',
            height: 600,
            zooming: {
                mouseWheel: {
                    enabled: false,
                },
            },
            marginBottom: 65,
        },
        legend: { enabled: false },
        credits: { enabled: false },
        rangeSelector: { enabled: false },
        navigator: { enabled: false },
        scrollbar: { enabled: false },
        exporting: { enabled: false },
        title: {
            enabled: false,
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            y: 0,
        },
        // tooltip: toolTipOptions,

        xAxis: [
            {
                allowDecimals: 'Y' === component.settings.x_axis_plot_intermediate_values,
                gridLineWidth: 1,
                title: {
                    text: component.settings.x_field.column_name,
                },
                labels: {
                    format: '{value}',
                },
                events: {
                    setExtremes: function (e: any) {
                        const axis: any = this;
                        setTimeout(() => {
                            if (axis?.chart?.tooltip) {
                                axis.chart.tooltip.destroy();
                            }
                        }, 10);
                    },
                },
            },
            // Fix for highcharts V 11
            {
                allowDecimals: 'Y' === component.settings.x_axis_plot_intermediate_values,
                title: { enabled: false },
                labels: { enabled: false },
            },
        ],
        yAxis: {
            allowDecimals: 'Y' === component.settings.y_axis_plot_intermediate_values,
            startOnTick: false,
            endOnTick: false,
            title: {
                text: component.settings.y_field.column_name,
            },
            labels: {
                format: '{value}',
            },
            maxPadding: 0.2,
            opposite: false,
        },

        plotOptions: {
            series: {
                animation: !contentSettings.forScreenShot,
                dataLabels: {
                    enabled: false,
                    useHTML: true,
                    style: {
                        color: '#222',
                    },
                } as any,
                point: {
                    events: {
                        click: function () {
                            // self.drawDrillToTooltip(this);
                        },
                    },
                },
            },
        },
        series: [
            {
                type: 'bubble',
                xAxis: 0,
                data: [],
            },
        ],
    };

    const [chartOptions, setChartOptions] = useState(options);
    useEffect(() => {
        if (isThumbnail) {
            //@ts-ignore
            applyChartOptionsForThumbnail(options);
        } else {
            options.chart.height = component.settings.height;
        }

        // TODO
        // var findDisplayMask = function (display_mask_id) {
        //     var dm = '';
        //     if (self.params.displayMasks && self.params.displayMasks.length && display_mask_id > 0) {
        //         dm = _.find(self.params.displayMasks, function (a) {
        //             return a.display_mask_id == display_mask_id;
        //         });
        //         if ('undefined' === typeof dm) dm = '';
        //     }
        //     return dm;
        // };

        // var dmX = findDisplayMask(component.settings.x_field.display_mask_id);
        let dmX = false;
        //@ts-ignore
        options.xAxis[0].labels.formatter = function () {
            //@ts-ignore
            let v = this.value;
            // Fix for highcharts V 11
            //@ts-ignore
            if (this?.chart?.axes[1]) {
                //@ts-ignore
                const ext = this.chart.axes[1].getExtremes();
                if (ext.dataMin != ext.min || ext.dataMax != ext.max) {
                    const st0 = ext.dataMax - ext.dataMin;
                    const st1 = ext.max - ext.min;
                    const a0 = v - ext.dataMin;
                    v = (st1 * a0) / st0 + ext.min;
                }
            }

            //@ts-ignore
            return formatValueFull(v, dmX);
        };

        // var dmY = findDisplayMask(component.settings.y_field.display_mask_id);
        var dmY = false;
        //@ts-ignore
        options.yAxis.labels.formatter = function () {
            //@ts-ignore
            return formatValueFull(this.value, dmY);
        };

        if ('Y' == component.settings.display_value || ('Y' == component.settings.display_label && !isThumbnail)) {
            options.plotOptions.series.dataLabels.enabled = true;

            if ('Y' == component.settings.display_value && 'Y' == component.settings.display_label) {
                options.plotOptions.series.dataLabels.format = '{point.name}: {point.v_f}';
            } else if ('Y' == component.settings.display_value) {
                options.plotOptions.series.dataLabels.format = '{point.v_f}';
            } else {
                options.plotOptions.series.dataLabels.format = '{point.name}';
            }
        }

        if (data.length == 0) {
            // if (!isPreview) {
            //     self.triggerAfterDataLoaded(false);
            // }
            // TODO:
            // self.el
            //     .find('.block-wrapper-chart-container:first')
            //     .html(
            //         '<img style="width:' +
            //             (self.settings.width > 0 ? self.settings.width + 'px' : '100%') +
            //             ';height:' +
            //             (self.settings.height > 0 ? self.settings.height + 'px' : '100%') +
            //             ';" src="' +
            //             globalConstants.homeSite +
            //             'img/default_preview_no_data.png' +
            //             '"/>'
            //     );
        } else {
            // if (!isPreview){
            //     self.triggerAfterDataLoaded(true);
            // }

            let minX = null,
                minY = null,
                maxX = null,
                maxY = null;

            const n_f = component.settings.name_field.reference_name,
                x_f = component.settings.x_field.reference_name,
                y_f = component.settings.y_field.reference_name,
                v_f = component.settings.value_field.reference_name,
                v_t = component.settings.value_field.value_type;
            for (const j in data) {
                if (data.hasOwnProperty(j)) {
                    const r = data[j];
                    let color = false;
                    // component.settings.formattingRules.forEach(function (rule) {
                    //     var value = false;
                    //     if (rule.field_name == component.settings.x_field.reference_name) {
                    //         value = parseFloat(r[x_f]);
                    //     }
                    //     if (rule.field_name == component.settings.y_field.reference_name) {
                    //         value = parseFloat(r[y_f]);
                    //     }
                    //     if (rule.field_name == '__value__') {
                    //         if (parseFloat(r[v_f]) == r[v_f]) {
                    //             value = parseFloat(r[v_f]);
                    //         }
                    //     }
                    //     if (value !== false && Computation.calculate('numeric', value, rule)) {
                    //         if (rule.bgColor) color = rule.bgColor;
                    //     }
                    // });
                    // Fix for highcharts V 11
                    let x = parseFloat(r[x_f]);
                    let y = parseFloat(r[y_f]);
                    if (maxX == null || x > maxX) {
                        maxX = x;
                    }
                    if (maxY == null || y > maxY) {
                        maxY = y;
                    }
                    if (minX == null || x < minX) {
                        minX = x;
                    }
                    if (minY == null || y < minY) {
                        minY = y;
                    }

                    var d = {
                        x: x,
                        y: y,
                        z: 'numeric' == v_t ? parseFloat(r[v_f]) : 10,
                        name: 'undefined' != typeof r[n_f + '-formatted'] ? r[n_f + '-formatted'] : escapeHtml(r[n_f]),
                        x_f: 'undefined' != typeof r[x_f + '-formatted'] ? r[x_f + '-formatted'] : r[x_f],
                        y_f: 'undefined' != typeof r[y_f + '-formatted'] ? r[y_f + '-formatted'] : r[y_f],
                        v_f: 'undefined' != typeof r[v_f + '-formatted'] ? r[v_f + '-formatted'] : r[v_f],
                    } as any;
                    if (color !== false) {
                        d.color = color;
                    }
                    //@ts-ignore
                    options.series[0].data.push(d);
                }
            }

            // Fix for highcharts V 11
            options.series[0].xAxis = 1;
            if (minX == null) {
                minX = 0;
            }
            if (maxX == null) {
                maxX = 0;
            }
            // Add 7% spacing from both sides
            const k = (maxX - minX) * 0.07;
            maxX += k;
            minX -= k;
            const gridData = [];
            const n = 250;
            const s = (maxX - minX) / n;
            for (let i = 0; i <= n; i++) {
                gridData.push({ x: minX + s * i, y: minY, z: 0 });
            }

            //@ts-ignore
            options.xAxis[0].min = minX;
            //@ts-ignore
            options.xAxis[0].max = maxX;
            options.series[1] = {
                xAxis: 1,
                tooltip: { enabled: false },
                enableMouseTracking: false,
                marker: {
                    enabled: false,
                },
                //@ts-ignore
                data: gridData,
            };
            //@ts-ignore
            options.xAxis[1].min = minX;
            //@ts-ignore
            options.xAxis[1].max = maxX;
        }

        setChartOptions(options);
    }, [data, component.settings]);

    const [chart, setChart] = useState<HChart>();
    const [hoveredChartPoint, setHoveredChartPoint] = useState<ChartPoint | null>(null);
    useComponentReady(chart);

    return initialData ? (
        <div style={{ overflow: 'hidden', maxHeight: component.settings.height + 'px' }}>
            {/*@ts-ignore*/}
            <Chart chartOptions={chartOptions} afterChartCreated={(chart: HChart) => setChart(chart)} />
            {chart && (
                <TooltipWrapper chart={chart}>
                    {(formatterContext) => (
                        <BubbleChartTooltip settings={component.settings} context={formatterContext} />
                    )}
                </TooltipWrapper>
            )}
        </div>
    ) : (
        // TODO: add loading mask
        <span></span>
    );
}
