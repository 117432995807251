import { alpha, IconButton, Stack, Theme, Typography } from '@mui/material';
import LineageIconButton from '../../lineage/LineageIconButton';
import React from 'react';
import IconMi from 'components/common/icon/IconMi';
import { useNavigate } from 'react-router-dom';
import SeparatorItem from '../../dataset-viewer/SeparatorItem';

function Infobar({ datasetId, label, filterId }: { datasetId: number; label: string; filterId: number }) {
    const navigate = useNavigate();

    const lineageElementType = 'external-metadata';
    const lineageElementId = String(filterId);

    return (
        <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems="center"
            sx={{
                mx: -2,
                p: 1,
                borderBottom: '1px solid',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                position: 'sticky',
                backgroundColor: 'background.default',
                minHeight: '44px',
                paddingRight: '32px',
                paddingLeft: '32px',
            }}
        >
            <Typography variant="heading" sx={{ flex: 1, padding: '5px 0', borderBottom: 'none' }}>
                {label}
            </Typography>
            <Stack direction={'row'} spacing={1} alignItems="center">
                {/*TODO: Lineage will be implement in 7.0.2*/}
                {/*<LineageIconButton elementType={lineageElementType} elementId={lineageElementId} />*/}
                {/*<SeparatorItem />*/}
                <IconButton
                    sx={{ p: '6px' }}
                    onClick={() => {
                        navigate(`/editor/virtual-dataset/${datasetId}`);
                    }}
                >
                    <IconMi icon="gear" fontSize="16" />
                </IconButton>
            </Stack>
        </Stack>
    );
}

export default Infobar;
