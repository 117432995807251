import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import styles from 'components/common/popup/AccessDeniedPopup.styles';
import { AccessDeniedTileInfo, TileInfo } from 'app/home/index.functions';
import React, { useMemo, useState } from 'react';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
import { useQuery } from '@tanstack/react-query';
import { AccessRequestElementInfo, homePage } from 'api/home-page';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import InfoOwners from 'app/home/tile/components/info-panel/InfoOwners';
import IconHandler from 'components/common/icon/IconHandler';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import DocumentsButton from 'components/element-viewer/controls-panel-icon/DocumentsButton';
import InfoCertification from 'app/home/tile/components/info-panel/InfoCertification';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import showdown from 'showdown';

const converter = new showdown.Converter();

export default function AccessDeniedPopup({
    accessDeniedTile,
    setAccessDeniedPopupTile,
}: {
    accessDeniedTile?: AccessDeniedTileInfo;
    setAccessDeniedPopupTile: (tile: AccessDeniedTileInfo | null) => void;
}) {
    const { t } = useBundleTranslation('app/home');
    const userAuth: UserAuth = useAppSelector((state) => state.auth);

    const [showPopup, setShowPopup] = useState(true);
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const [requestMessage, setRequestMessage] = useState('');
    const dataTestAttr = 'access_denied_popup';
    const queryKey = `element_preview_${accessDeniedTile?.element_id}_${accessDeniedTile?.segment_value_id}`;
    if (null === accessDeniedTile) return null;

    const showDocuments = useConfigConstantValue('DISCOVERABLE_CONTENT_DISPLAYS_DOCUMENTS');
    const okButtonText = useConfigConstantValue('ACCESS_REQUEST_BUTTON_TEXT');
    const accessRequestUrl = useConfigConstantValue('ACCESS_REQUEST_URL');
    const accessRequestViaWebpage = useConfigConstantValue('ACCESS_REQUEST_VIA_WEBPAGE');

    const { data: accessRequestInfoData } = useQuery<AccessRequestElementInfo, Error>([queryKey], () => {
        return homePage.getAccessRequestInfo(
            Number(accessDeniedTile?.element_id),
            Number(accessDeniedTile?.segment_value_id)
        );
    });

    const onPopupHide = () => {
        setShowPopup(false);
        setAccessDeniedPopupTile(null);
    };

    const onMessagePopupHide = () => {
        setAccessDeniedPopupTile(null);
        setShowMessagePopup(false);
    };

    const onRequestAccess = async () => {
        if (accessRequestViaWebpage && accessRequestUrl) {
            window.open(accessRequestUrl, '_blank');
            onPopupHide();
            return;
        }
        setShowPopup(false);
        let data = await homePage.requestAccess(
            Number(accessDeniedTile?.element_id),
            Number(accessDeniedTile?.segment_value_id)
        );
        setRequestMessage(data?.message ? data.message : t('access_request_sent'));
        setShowMessagePopup(true);
    };

    const isCertified = useMemo(() => {
        const tileInfo = accessRequestInfoData?.accessData as TileInfo;
        return tileInfo?.certified_ind === 'Y';
    }, [accessRequestInfoData?.accessData]);

    let TopicSection: ReactJSXElement[] = [];
    if (accessRequestInfoData?.accessData?.topics)
        for (const key in accessRequestInfoData.accessData?.topics)
            if (undefined !== accessRequestInfoData.accessData?.topics[key]) {
                const isTagsOptions = key === 'default-topic-type';
                let topicData = accessRequestInfoData.accessData?.topics[key];

                TopicSection.push(
                    <Box sx={styles.flexStartWrapper} key={`info-tag-${key}-wrapper`}>
                        <Typography fontSize={16}>
                            {topicData.use_icon === 'fa' && (
                                <IconHandler icon={{ type: 'fa', value: topicData.fa_icon }} />
                            )}
                            {topicData.use_icon === 'uploaded' && (
                                <img src={`data:image/png;base64, ${topicData.icon}`} alt={'topic icon'} />
                            )}
                            {isTagsOptions && <IconMi icon={'tags'} />}
                        </Typography>
                        <Stack direction={'column'} spacing={[0.5]}>
                            <Typography fontWeight={600}>{topicData.topic_type_name}</Typography>
                            <Box>
                                {topicData.topics &&
                                    topicData.topics.map((topicInfo) => {
                                        return (
                                            <Tooltip
                                                title={topicInfo.name}
                                                key={`info-tag-tooltip-${topicInfo.topic_id}`}
                                            >
                                                <Chip
                                                    label={topicInfo.name}
                                                    key={`info-tag-${topicInfo.topic_id}`}
                                                    color="primary"
                                                    sx={styles.tags.chip}
                                                />
                                            </Tooltip>
                                        );
                                    })}
                            </Box>
                        </Stack>
                    </Box>
                );
            }

    return showMessagePopup ? (
        <Dialog
            fullWidth
            maxWidth={'popupMd'}
            scroll={'body'}
            sx={{
                '.MuiDialog-paper': { overflow: 'visible', marginTop: '100px' },
                '.MuiDialog-container:after': {
                    content: 'none',
                },
            }}
            open={showMessagePopup}
            onClose={onMessagePopupHide}
        >
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={1}>
                    <DialogTitle id="alert-dialog-title" sx={{ py: '10px' }}>
                        {t('info')}
                    </DialogTitle>
                </Stack>
                <IconButton
                    aria-label="close"
                    onClick={onMessagePopupHide}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'primary.main',
                    }}
                >
                    <IconMi icon="times" fontSize="16" />
                </IconButton>
            </Stack>
            <DialogContent dividers sx={{ overflow: 'visible', py: '10px' }}>
                {requestMessage}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'normal', py: 2, px: 3, flexWrap: 'wrap' }}>
                <Button
                    variant={'contained'}
                    onClick={onMessagePopupHide}
                    className={'popup-button--ok'}
                    data-test={dataTestAttr + '_ok_button'}
                >
                    {t('ok_button')}
                </Button>
            </DialogActions>
        </Dialog>
    ) : showPopup ? (
        accessRequestInfoData ? (
            <Dialog
                fullWidth
                scroll={'body'}
                sx={{
                    '.MuiDialog-paper': { overflow: 'visible', marginTop: '100px' },
                    '.MuiDialog-container:after': {
                        content: 'none',
                    },
                    '.MuiDialog-container .MuiDialog-paper': { maxWidth: '720px' },
                }}
                open={showPopup}
                onClose={onPopupHide}
            >
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={1}>
                        <DialogTitle id="alert-dialog-title" sx={{ py: '10px' }}>
                            {t('access_denied')}
                        </DialogTitle>
                    </Stack>
                    <IconButton
                        aria-label="close"
                        onClick={onPopupHide}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'primary.main',
                        }}
                    >
                        <IconMi icon="times" fontSize="16" />
                    </IconButton>
                </Stack>
                <DialogContent dividers sx={{ overflow: 'visible', py: '10px' }}>
                    <Box
                        component={'span'}
                        dangerouslySetInnerHTML={{ __html: String(accessRequestInfoData.html) }}
                        sx={{ 'p:last-child': { mb: 0 }, 'p:first-child': { mt: 0 } }}
                    />
                </DialogContent>
                <DialogContent dividers sx={{ overflow: 'visible', py: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <p>{accessRequestInfoData.name}</p>
                            <Box alignSelf={'center'} sx={styles.sectionDivider} />
                            <p>{accessRequestInfoData.accessData?.description}</p>
                            {isCertified && (
                                <>
                                    <Box alignSelf={'center'} sx={styles.sectionDivider} />
                                    <Stack direction={'column'} spacing={0.5}>
                                        <InfoCertification
                                            tile={accessRequestInfoData.accessData as TileInfo}
                                            showDivider={false}
                                        />
                                    </Stack>
                                </>
                            )}
                            <Box alignSelf={'center'} sx={styles.sectionDivider} />
                            <Stack direction={'column'} spacing={0.5}>
                                {TopicSection}
                            </Stack>
                            <Box alignSelf={'center'} sx={styles.sectionDivider} />
                            <Stack direction={'column'} spacing={0.5}>
                                <InfoOwners tile={accessRequestInfoData.accessData as TileInfo} showDivider={false} />
                            </Stack>
                            {accessRequestInfoData.accessData?.custom_fields?.length ? (
                                <Box alignSelf={'center'} sx={styles.sectionDivider} />
                            ) : null}
                            <Stack direction={'column'} spacing={0.5}>
                                {accessRequestInfoData.accessData?.custom_fields &&
                                    accessRequestInfoData.accessData?.custom_fields.map((section) => {
                                        return (
                                            <Box
                                                sx={styles.flexStartWrapper}
                                                key={`custom-field-section-${section.cfs_id}`}
                                            >
                                                <Typography fontSize={16}>
                                                    <IconMi icon={'custom-field'} />
                                                </Typography>
                                                <Stack direction={'column'} spacing={[0.5]}>
                                                    <Typography fontWeight={600}>{section.label}</Typography>
                                                    {section.fields.map((field) => (
                                                        <Grid
                                                            container
                                                            direction={'row'}
                                                            key={`custom-field-${field.name}`}
                                                            sx={styles.custom_fields.container}
                                                        >
                                                            <Grid item xs={6}>
                                                                <Tooltip title={field.name}>
                                                                    <Typography
                                                                        sx={styles.custom_fields.item}
                                                                    >{`${field.name}:`}</Typography>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    component={'span'}
                                                                    sx={styles.custom_fields.item}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: converter.makeHtml(
                                                                            field.values.join(', ')
                                                                        ),
                                                                    }}
                                                                ></Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Stack>
                                            </Box>
                                        );
                                    })}
                            </Stack>
                            <Box alignSelf={'center'} sx={styles.sectionDivider} />
                        </Grid>
                        <Grid item xs={4}>
                            <p>{accessRequestInfoData.accessData?.content_type}</p>
                            <Box alignSelf={'center'} sx={styles.sectionDivider} />
                            {accessRequestInfoData.accessData?.image && (
                                <p>
                                    <img
                                        src={accessRequestInfoData.accessData?.image}
                                        style={styles.thumbnail as React.CSSProperties}
                                    />
                                </p>
                            )}

                            {showDocuments && accessRequestInfoData.accessData?.documents && (
                                <DocumentsButton docs={accessRequestInfoData.accessData?.documents} />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'normal', py: 2, px: 3, flexWrap: 'wrap' }}>
                    <Button
                        variant={'contained'}
                        onClick={onRequestAccess}
                        className={'popup-button--ok'}
                        data-test={dataTestAttr + '_ok_button'}
                    >
                        {okButtonText || t('request_access')}
                    </Button>
                    <Button
                        data-test={dataTestAttr + '_cancel_button'}
                        variant="outlined"
                        color="neutral"
                        onClick={onPopupHide}
                        className={'popup-button--cancel'}
                    >
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        ) : (
            <LoadingPlaceholder />
        )
    ) : null;
}
