import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material';
import { FormComponentBuilder } from 'components/common/form/layout';
import styles from './KpiRuleInfo.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertUserMapAccessAPI, customRequestAPI } from 'api/api';
import AlertRulePopup from 'components/alert-rule-popup/AlertRulePopup';
import { Popup } from 'components/common/popup/Popup';
import IconMi from 'components/common/icon/IconMi';
import ReactSelect from 'components/common/react-select/ReactSelect';

export interface FormControlKpiRuleUserMapAccessProps extends FormControlProps {}

export default function KpiRuleUserMapAccess({
    controlProps,
}: FormElementControlPropsType<FormControlKpiRuleUserMapAccessProps>) {
    const { t } = useBundleTranslation(['app/editor/kpi-alert-rule/kpi_alert_rule']);

    const [showUserMapDimensionAccessPopup, setShowUserMapDimensionAccessPopup] = useState<boolean>(false);
    const [userMapDimensionAccessPopupData, setUserMapDimensionAccessPopupData] = useState<any>(null);
    const [savedRuleInfo, setSavedRuleInfo] = useState<any>();
    const [currentSubscriptionUserMapName, setCurrentSubscriptionUserMapName] = useState<string>('');
    const [currentAssigneeUserMapName, setCurrentAssigneeUserMapName] = useState<string>('');

    const [ruleInfo, setRuleInfo] = useState<any>();
    const [subscriptionUserMapName, setSubscriptionUserMapName] = useState<string>('');
    const [assigneeUserMapName, setAssigneeUserMapName] = useState<string>('');

    const urlParams = useParams();
    const alertRuleId = urlParams.alertRuleId ?? 0;
    const navigate = useNavigate();
    const [columnsData, setColumnsData] = useState<any>({
        subscriptionUserMapColumn: [],
        assigneeUserMapColumn: [],
    });

    const getAlertRuleUserMapInfo = (callback?: any) => {
        AlertUserMapAccessAPI.getAlertRuleUserMapInfo(Number(alertRuleId))
            .then((response: any) => {
                if (response?.data?.status === 'OK') {
                    setUserMapDimensionAccessPopupData(response.data.data);
                    setRuleInfo(response.data.data.alertRule);
                    setSavedRuleInfo(response.data.data.alertRule);
                    /* Useless. Always empty
                    setColumnsData({
                        subscriptionUserMapColumn: response.data.data.subscriptionUserMapColumn,
                        assigneeUserMapColumn: response.data.data.assigneeUserMapColumn,
                    });
                    */
                    if (typeof callback === 'function') callback(response.data.data);
                }
            })
            .catch(() => {});
    };

    const loadColumnList = (type: string, val: string) => {
        AlertUserMapAccessAPI.loadColumns(Number(alertRuleId), Number(val))
            .then((response: any) => {
                if (response?.data?.status === 'OK') {
                    if (type == 'subscription') {
                        updateColumnsData({
                            subscriptionUserMapColumn: response.data.data,
                        });
                        updateRuleInfo({ subscription_user_map_column_id: response.data.data[0].value });
                        if (userMapDimensionAccessPopupData.segmentSecondary?.segment_id > 0) {
                            updateRuleInfo({ subscription_user_map_secondary_column_id: response.data.data[0].value });
                        }
                    } else if (type == 'assignee') {
                        updateColumnsData({
                            assigneeUserMapColumn: response.data.data,
                        });
                        updateRuleInfo({ assignee_user_map_column_id: response.data.data[0].value });
                        if (userMapDimensionAccessPopupData.segmentSecondary?.segment_id > 0) {
                            updateRuleInfo({ assignee_user_map_secondary_column_id: response.data.data[0].value });
                        }
                    }
                }
            })
            .catch(() => {});
    };
    const clearColumnList = (type: string) => {
        if (type == 'subscription') {
            updateRuleInfo({ subscription_user_map_column_id: '' });
            updateColumnsData({ subscriptionUserMapColumn: [] });

            if (userMapDimensionAccessPopupData.segmentSecondary?.segment_id > 0) {
                updateRuleInfo({ subscription_user_map_secondary_column_id: '' });
            }
        } else if (type == 'assignee') {
            updateRuleInfo({ assignee_user_map_column_id: '' });
            updateColumnsData({ assigneeUserMapColumn: [] });

            if (userMapDimensionAccessPopupData.segmentSecondary?.segment_id > 0) {
                updateRuleInfo({ assignee_user_map_secondary_column_id: '' });
            }
        }
    };

    const updateRuleInfo = (data: any) => {
        setRuleInfo((prevAlertRuleData: any) => {
            return { ...prevAlertRuleData, ...data };
        });
    };

    const updateColumnsData = (data: any) => {
        setColumnsData((prevColumnsData: any) => {
            return { ...prevColumnsData, ...data };
        });
    };

    useEffect(() => {
        getAlertRuleUserMapInfo((data: any) => {
            setCurrentSubscriptionUserMapName(
                data.subscriptionUserMap.find((item: any) => {
                    return item.value == data.alertRule.subscription_user_map_id;
                })?.label ?? ''
            );
            setCurrentAssigneeUserMapName(
                data.assigneeUserMap.find((item: any) => {
                    return item.value == data.alertRule.assignee_user_map_id;
                })?.label ?? ''
            );
            if (data.alertRule.subscription_user_map_id > 0) {
                loadColumnList('subscription', data.alertRule.subscription_user_map_id);
            }
            if (data.alertRule.assignee_user_map_id > 0) {
                loadColumnList('assignee', data.alertRule.assignee_user_map_id);
            }
        });
    }, []);

    const showPopup = () => {
        getAlertRuleUserMapInfo((data: any) => {
            setShowUserMapDimensionAccessPopup(true);
        });
    };

    const hidePopup = () => {
        setShowUserMapDimensionAccessPopup(false);
        setUserMapDimensionAccessPopupData(null);
    };

    const onSave = () => {
        AlertUserMapAccessAPI.updateAlertRuleUserMap(Number(alertRuleId), ruleInfo)
            .then((response: any) => {
                if (response?.data?.status === 'OK') {
                    setSavedRuleInfo(ruleInfo);
                    setCurrentSubscriptionUserMapName(subscriptionUserMapName);
                    setCurrentAssigneeUserMapName(assigneeUserMapName);
                }
            })
            .catch(() => {});

        hidePopup();
    };

    if (!ruleInfo) return null;

    return (
        <Box sx={styles.infoWrapper}>
            {savedRuleInfo.subscription_user_map_id > 0 && (
                <>
                    <Box>
                        <FormLabel>{t('user_map_access.subscription_map_label')}</FormLabel>
                        <Box>{currentSubscriptionUserMapName}</Box>
                    </Box>
                    {savedRuleInfo.assignee_user_map_id > 0 && savedRuleInfo.assignee_dimensions_ind == 'Y' && (
                        <Box sx={{ mt: '19px' }}>
                            <FormLabel>{t('user_map_access.assignee_map_label')}</FormLabel>
                            <Box>{currentAssigneeUserMapName}</Box>
                        </Box>
                    )}
                </>
            )}

            <Box sx={{ mt: '19px' }}>
                <Button startIcon={<IconMi icon={'new'} />} variant={'outlined'} onClick={showPopup}>
                    {t('user_map_access.edit_user_map_access')}
                </Button>
            </Box>

            {showUserMapDimensionAccessPopup && (
                <Popup
                    settings={{
                        title: t('user_map_access.popup.title'),
                        textOK: t('user_map_access.popup.ok_btn'),
                    }}
                    open={true}
                    onHide={hidePopup}
                    onConfirm={onSave}
                    maxWidth="popupMd"
                >
                    <Stack spacing={2.365}>
                        <FormControl>
                            <FormLabel>{t('user_map_access.popup.subscription_map_label')}</FormLabel>
                            <Box>
                                <ReactSelect
                                    data={userMapDimensionAccessPopupData.subscriptionUserMap}
                                    value={ruleInfo.subscription_user_map_id}
                                    update={(value: string, fullValue: any) => {
                                        setSubscriptionUserMapName(fullValue.label);
                                        updateRuleInfo({ subscription_user_map_id: value });

                                        if (Number(value) > 0) {
                                            loadColumnList('subscription', value);
                                        } else {
                                            clearColumnList('subscription');
                                        }
                                    }}
                                />
                            </Box>
                        </FormControl>

                        {userMapDimensionAccessPopupData.segment?.segment_id > 0 && (
                            <>
                                {ruleInfo.subscription_user_map_id > 0 && ruleInfo.workflow_instance_id > 0 && (
                                    <FormControl>
                                        <FormLabel>{t('user_map_access.popup.assignee_dimensions_label')}</FormLabel>
                                        <RadioGroup
                                            row
                                            name="assignee_dimensions_ind"
                                            onChange={(newVal) => {
                                                updateRuleInfo({ assignee_dimensions_ind: newVal.target.value });
                                            }}
                                            value={ruleInfo.assignee_dimensions_ind}
                                        >
                                            {userMapDimensionAccessPopupData.assigneeDimensions.map((item: any) => {
                                                return (
                                                    <FormControlLabel
                                                        key={item.value}
                                                        value={item.value}
                                                        control={<Radio />}
                                                        label={item.label}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                )}

                                {ruleInfo.assignee_dimensions_ind == 'Y' && ruleInfo.workflow_instance_id > 0 && (
                                    <FormControl>
                                        <FormLabel>{t('user_map_access.popup.assignee_map_label')}</FormLabel>
                                        <Box>
                                            <ReactSelect
                                                data={userMapDimensionAccessPopupData.assigneeUserMap}
                                                value={ruleInfo.assignee_user_map_id}
                                                update={(value: string, fullValue: any) => {
                                                    setAssigneeUserMapName(fullValue.label);
                                                    updateRuleInfo({ assignee_user_map_id: value });

                                                    if (Number(value) > 0) {
                                                        loadColumnList('assignee', value);
                                                    } else {
                                                        clearColumnList('assignee');
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </FormControl>
                                )}

                                {ruleInfo.subscription_user_map_id > 0 && (
                                    <>
                                        <Typography variant="heading">
                                            {t('user_map_access.popup.subscription_column_mapping_section')}
                                        </Typography>
                                        <Box>
                                            {t('user_map_access.popup.column_mapping_section_info', {
                                                elementName: subscriptionUserMapName,
                                                segmentName: userMapDimensionAccessPopupData.segment.name ?? '',
                                            })}
                                        </Box>
                                        <FormControl>
                                            <FormLabel>
                                                {userMapDimensionAccessPopupData.segmentPrimary?.name ??
                                                    userMapDimensionAccessPopupData.segment.name ??
                                                    ''}
                                            </FormLabel>
                                            <Box>
                                                <ReactSelect
                                                    data={columnsData.subscriptionUserMapColumn}
                                                    value={ruleInfo.subscription_user_map_column_id}
                                                    update={(value) => {
                                                        updateRuleInfo({ subscription_user_map_column_id: value });
                                                    }}
                                                />
                                            </Box>
                                        </FormControl>
                                        {userMapDimensionAccessPopupData.segmentSecondary?.segment_id > 0 && (
                                            <FormControl>
                                                <FormLabel>
                                                    {userMapDimensionAccessPopupData.segmentSecondary?.name ??
                                                        userMapDimensionAccessPopupData.segment.name ??
                                                        ''}
                                                </FormLabel>
                                                <Box>
                                                    <ReactSelect
                                                        data={columnsData.subscriptionUserMapColumn}
                                                        value={ruleInfo.subscription_user_map_secondary_column_id}
                                                        update={(value) => {
                                                            updateRuleInfo({
                                                                subscription_user_map_secondary_column_id: value,
                                                            });
                                                        }}
                                                    />
                                                </Box>
                                            </FormControl>
                                        )}
                                    </>
                                )}

                                {ruleInfo.assignee_dimensions_ind == 'Y' &&
                                    ruleInfo.workflow_instance_id > 0 &&
                                    ruleInfo.assignee_user_map_id > 0 && (
                                        <>
                                            <Typography variant="heading">
                                                {t('user_map_access.popup.assignee_column_mapping_section')}
                                            </Typography>
                                            <Box>
                                                {t('user_map_access.popup.column_mapping_section_info', {
                                                    elementName: assigneeUserMapName,
                                                    segmentName: userMapDimensionAccessPopupData.segment.name ?? '',
                                                })}
                                            </Box>
                                            <FormControl>
                                                <FormLabel>
                                                    {userMapDimensionAccessPopupData.segmentPrimary?.name ??
                                                        userMapDimensionAccessPopupData.segment.name ??
                                                        ''}
                                                </FormLabel>
                                                <Box>
                                                    <ReactSelect
                                                        data={columnsData.assigneeUserMapColumn}
                                                        value={ruleInfo.assignee_user_map_column_id}
                                                        update={(value) => {
                                                            updateRuleInfo({ assignee_user_map_column_id: value });
                                                        }}
                                                    />
                                                </Box>
                                            </FormControl>
                                            {userMapDimensionAccessPopupData.segmentSecondary?.segment_id > 0 && (
                                                <FormControl>
                                                    <FormLabel>
                                                        {userMapDimensionAccessPopupData.segmentSecondary?.name ??
                                                            userMapDimensionAccessPopupData.segment.name ??
                                                            ''}
                                                    </FormLabel>
                                                    <Box>
                                                        <ReactSelect
                                                            data={columnsData.assigneeUserMapColumn}
                                                            value={ruleInfo.assignee_user_map_secondary_column_id}
                                                            update={(value) => {
                                                                updateRuleInfo({
                                                                    assignee_user_map_secondary_column_id: value,
                                                                });
                                                            }}
                                                        />
                                                    </Box>
                                                </FormControl>
                                            )}
                                        </>
                                    )}
                            </>
                        )}
                    </Stack>
                </Popup>
            )}
        </Box>
    );
}

export class FormKpiRuleUserMapAccessBuilder extends FormComponentBuilder {
    prepareProps(): FormControlKpiRuleUserMapAccessProps {
        return { ...this.controlProps };
    }
}
