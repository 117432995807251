import React, { useState } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    IconButton,
    IconButtonProps,
    Input,
    Snackbar,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import styles from './RatingComponent.styles';
import { ratingPromptAPI, SaveRatingData } from 'api/rating';
import { RatingPromptProps } from './index';
import IconMi from 'components/common/icon/IconMi';
import Rating from './Rating';
import useBundleTranslation from 'i18n';
import { openInNewTab } from 'tools/tools';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';

function RatingComponent({
    itemId,
    itemType,
    showPrompt,
    promptData,
    hidePrompt,
    expanded,
    setExpanded,
}: RatingPromptProps) {
    const { t } = useBundleTranslation('components/rating/rating');
    const editPromptUrl = '/admin/rating-prompt/';
    const [feedbackComment, setFeedbackComment] = useState('');
    const [ratingValue, setRatingValue] = useState(0);
    const [saving, setSaving] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const userAuth: UserAuth = useAppSelector((state) => state.auth);
    const isAdmin = userAuth?.userInfo?.isAdmin ?? false;

    interface ExpandMoreProps extends IconButtonProps {
        expand: boolean;
    }

    const ExpandMore = styled((props: ExpandMoreProps) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const onFeedbackSubmit = () => {
        if (promptData) {
            setSaving(true);

            const data: SaveRatingData = {
                rating: ratingValue,
                comment: feedbackComment,
                ratingPromptId: promptData.ratingPromptId,
                viewLogId: promptData.viewLogId,
            };

            ratingPromptAPI.saveRating(itemType, itemId, data).then(() => {
                setSaving(false);
                hidePrompt();
                setShowSuccessMessage(true);
            });
        }
    };

    const editButtonClick = () => {
        if (promptData) {
            openInNewTab(editPromptUrl + promptData.ratingPromptId);
        }
    };

    return promptData ? (
        <>
            {showPrompt && (
                <Card sx={styles.wrapper} id={`rating-prompt-${itemType}-${itemId}`}>
                    <CardContent sx={{ padding: '20px' }}>
                        <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems="flex-start">
                            <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <ExpandMore
                                    expand={expanded}
                                    onClick={() => {
                                        setExpanded(!expanded);
                                    }}
                                >
                                    <IconMi icon={'event'} />
                                </ExpandMore>
                                <Typography noWrap={!expanded} sx={{ padding: '5px' }}>
                                    {promptData.componentTitle}
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} spacing={0.25} alignItems={'center'} justifyContent={'flex-end'}>
                                {isAdmin && (
                                    <IconButton onClick={editButtonClick}>
                                        <IconMi icon={'gear'} />
                                    </IconButton>
                                )}

                                <IconButton onClick={hidePrompt}>
                                    <IconMi icon={'times'} />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </CardContent>
                    <Collapse in={expanded} timeout="auto">
                        <CardContent sx={{ padding: '0 20px 25px' }}>
                            <Stack direction={'column'} spacing={2}>
                                <Rating promptData={promptData} rating={ratingValue} setRating={setRatingValue} />
                                <Input
                                    placeholder={promptData.defaultFeedbackText}
                                    value={feedbackComment}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFeedbackComment(event.target.value);
                                    }}
                                />
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                        variant={'contained'}
                                        sx={{ width: '25%' }}
                                        disabled={saving}
                                        onClick={onFeedbackSubmit}
                                    >
                                        {t('submit_button')}
                                    </Button>
                                    {saving && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '12.5%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Stack>
                        </CardContent>
                    </Collapse>
                </Card>
            )}
            <Snackbar
                open={showSuccessMessage}
                autoHideDuration={3000}
                onClose={() => {
                    setShowSuccessMessage(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Alert severity="success">
                    <AlertTitle>{t('success_header')}</AlertTitle>
                    {t('success_submitted')}
                </Alert>
            </Snackbar>
        </>
    ) : null;
}

export default RatingComponent;
