import React, { Fragment } from 'react';
import Filter, { FilterType } from './Filter';
import { Box, Button, Grid, Stack } from '@mui/material';
import useBundleTranslation from 'i18n';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import IconMi from 'components/common/icon/IconMi';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';

const FiltersToShowOnPage = 2;
const FiltersToShowOnFullScreen = 3;

export default function FilterBlock({
    filters,
    onFilterChange,
    onApplyFilterChanges,
    isFiltersChanged,
    fullScreenMode,
    isExpandedFilters,
    toggleExpandedState,
    fullscreenModePanelPos,
    elementId,
}: {
    onApplyFilterChanges: (filters: Array<FilterType>) => any;
    onFilterChange: () => void;
    filters: Array<FilterType>;
    isFiltersChanged: boolean;
    fullScreenMode: boolean;
    isExpandedFilters: boolean;
    toggleExpandedState: () => void;
    fullscreenModePanelPos?: string;
    elementId: number;
}) {
    const handleApplyFilterChanges = function () {
        onApplyFilterChanges(filters);
    };
    const { t } = useBundleTranslation(['components/external-reference/filter_block']);

    // Filter hidden
    const isVisibleSegment = useEmbeddingContextVisible('segment');
    const visibleInViewer = filters
        .filter((f) => f.visibleInViewer && !f.replacedBySegment)
        .filter((f) => f.filterId > 0 || isVisibleSegment);

    // Move segment filters to first place
    const childSegment = visibleInViewer.findIndex((f) => f.filterId < 0 && f.parentFilterId < 0);
    if (childSegment != -1) {
        visibleInViewer.unshift(visibleInViewer.splice(childSegment, 1)[0]);
    }
    const segment = visibleInViewer.findIndex((f) => f.filterId < 0 && f.parentFilterId >= 0);
    if (segment != -1) {
        visibleInViewer.unshift(visibleInViewer.splice(segment, 1)[0]);
    }
    // By default, show only FiltersToShowOnPage amount, until user click 'Show More';
    // But always show all filters In FullScreenMode
    const showAmount = fullScreenMode ? FiltersToShowOnFullScreen : FiltersToShowOnPage;
    let filtersToDisplay = isExpandedFilters ? visibleInViewer : visibleInViewer.slice(0, showAmount);

    return (
        <>
            {filtersToDisplay.map((filter: FilterType) => {
                return (
                    <Fragment key={filter.filterId}>
                        {isExpandedFilters ? (
                            <Grid
                                data-test={`reference-filter-${filter.name}`}
                                item
                                sx={{
                                    width: '20%',
                                    display:
                                        filter.visibleInViewer && !filter.replacedBySegment ? 'inline-block' : 'none',
                                }}
                            >
                                {filter.title}
                                <Filter
                                    context="filter"
                                    onFilterChange={onFilterChange}
                                    filter={filter}
                                    elementId={elementId}
                                />
                            </Grid>
                        ) : (
                            <Grid
                                data-test={`reference-filter-${filter.name}`}
                                item
                                sx={{
                                    display:
                                        filter.visibleInViewer && !filter.replacedBySegment ? 'inline-block' : 'none',
                                }}
                            >
                                <Stack direction="row">
                                    <StaticAddon>{filter.title}</StaticAddon>
                                    <Box sx={{ width: '175px' }}>
                                        <Filter
                                            elementId={elementId}
                                            context="filter"
                                            onFilterChange={onFilterChange}
                                            filter={filter}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                        )}
                    </Fragment>
                );
            })}
            <Grid item>
                {visibleInViewer.length > showAmount && (
                    <Button
                        data-test={'reference-collapse-button'}
                        variant="light"
                        sx={{ mr: 1 }}
                        onClick={toggleExpandedState}
                        endIcon={
                            <IconMi
                                icon={
                                    fullscreenModePanelPos == 'bottom'
                                        ? isExpandedFilters
                                            ? 'chevron-down'
                                            : 'chevron-up'
                                        : isExpandedFilters
                                        ? 'chevron-up'
                                        : 'chevron-down'
                                }
                            />
                        }
                    >
                        {isExpandedFilters ? t('collapse') : t('nMore', { count: visibleInViewer.length - showAmount })}
                    </Button>
                )}

                {visibleInViewer.length > 0 ? (
                    <Button
                        data-test={'reference-apply-button'}
                        variant="contained"
                        disabled={!isFiltersChanged}
                        onClick={handleApplyFilterChanges}
                    >
                        {t('filter_block_apply_changes')}
                    </Button>
                ) : (
                    <Box sx={{ height: '28px' }}></Box>
                )}
            </Grid>
        </>
    );
}
