import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import useBundleTranslation from 'i18n';

export default function EmbedElement({
    embedUrl,
    displayType,
    url,
    iframeHeight,
    iframeWidth,
}: {
    embedUrl: string;
    displayType: 'image' | 'iframe' | 'pdf';
    url?: string;
    iframeHeight?: number;
    iframeWidth?: number;
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const handleOnLoad = (e: any) => {
        if (displayType == 'image') {
            if (imgRef?.current?.complete) {
                setIsLoaded(true);
            }
        } else {
            if (e?.target?.src != window.location.href && e?.target?.src != encodeURI(window.location.href)) {
                setIsLoaded(true);
            }
        }
    };

    const imgRef = useRef<HTMLImageElement>(null);
    const image = (
        <img
            ref={imgRef}
            onLoad={handleOnLoad}
            onError={handleOnLoad}
            style={{ minHeight: '650px', maxWidth: '100%' }}
            src={embedUrl}
        />
    );

    // Default Height
    let height = '600px';
    if (displayType == 'iframe' && iframeHeight) {
        height = iframeHeight + 'px';
    } else if (displayType == 'image') {
        height = '100%';
    }
    const { t } = useBundleTranslation(['components/report-content']);

    return (
        <div
            data-test={'external-embed-element'}
            style={{
                width: '100%',
                height: height,
                overflow: 'auto',
                textAlign: 'center',
                position: 'relative',
            }}
        >
            {!isLoaded && <LoadingPlaceholder sx={{ position: 'absolute' }}>{t('loading___')}</LoadingPlaceholder>}
            {displayType == 'image' ? (
                url ? (
                    <a target={'_blank'} href={url}>
                        {image}
                    </a>
                ) : (
                    <span>{image}</span>
                )
            ) : (
                <Box
                    onLoad={handleOnLoad}
                    onError={handleOnLoad}
                    component="iframe"
                    width={iframeWidth ? iframeWidth : '100%'}
                    height={height}
                    src={embedUrl}
                    sx={{ border: 0, display: 'block', minHeight: iframeHeight ? undefined : '650px' }}
                />
            )}
        </div>
    );
}
