import { FormDataAPIType, FormRendererAPIType } from 'components/common/form/index';
import { DataFetchCommandEntityType } from 'components/common/form/DataFetchCommand';
import { AssocArray } from 'tools/types';
import { getElementFieldValue } from 'components/common/form/formTools';

export type ModeType = 'validate' | 'collect';

export interface ISubstitutionFormProps {
    formApi: FormDataAPIType;
    editorForm: FormRendererAPIType;
    mode: ModeType;
    requiredFields: Array<string>;
    hasData?: boolean;
    type: DataFetchCommandEntityType;
    dataSource: string;
}

export function getPostArgs(
    type: DataFetchCommandEntityType,
    dataSource: string,
    editorForm: FormRendererAPIType
): AssocArray<any> {
    const result: AssocArray<any> = {};
    if (dataSource != 'dataset') {
        return result;
    }

    if (['segment', 'metric', 'dataset', 'report'].includes(type)) {
        let dsParams: any;
        if (type == 'segment') {
            dsParams = {
                key_ds_column_id: editorForm.hookFormGetValues('key_ds_column_id'),
                display_ds_column_id: editorForm.hookFormGetValues('display_ds_column_id'),
                parent_segment_ds_column_id: editorForm.hookFormGetValues('parent_segment_ds_column_id'),
                primary_segment_ds_column_id: editorForm.hookFormGetValues('primary_segment_ds_column_id'),
                secondary_segment_ds_column_id: editorForm.hookFormGetValues('secondary_segment_ds_column_id'),
                technical_owner_id: editorForm.hookFormGetValues('technical_owner_id'),
            };
        } else if (type == 'metric') {
            dsParams = {
                primary_segment_value_ds_column_id: editorForm.hookFormGetValues('primary_segment_value_ds_column_id'),
                secondary_segment_value_ds_column_id: editorForm.hookFormGetValues(
                    'secondary_segment_value_ds_column_id'
                ),
                measurement_date_ds_column_id: editorForm.hookFormGetValues('measurement_date_ds_column_id'),
                measurement_value_ds_column_id: editorForm.hookFormGetValues('measurement_value_ds_column_id'),
                ds_aggregate_function: editorForm.hookFormGetValues('ds_aggregate_function'),
                ds_incremental_load_ind: editorForm.hookFormGetValues('ds_incremental_load_ind'),
                ignore_dayoff_ind: editorForm.hookFormGetValues('ignore_dayoff_ind'),
            };
        } else if (type == 'dataset') {
            dsParams = {
                hist: editorForm.hookFormGetValues('save_historical_instances_ind"'),
                command_source: editorForm.hookFormGetValues('measurement_time_source'),
                measurement_time_ds_column_id: editorForm.hookFormGetValues('measurement_time_ds_column_id'),
            };
        } else if (type == 'report') {
            dsParams = {
                report_save_historical_instances_ind: editorForm.hookFormGetValues(
                    'report_save_historical_instances_ind'
                ),
                uses_dataset_access_map_ind: getElementFieldValue(editorForm, `dataset_source.has_am`),
                primary_segment_value_ds_column_id: editorForm.hookFormGetValues('primary_segment_value_ds_column_id'),
                secondary_segment_value_ds_column_id: editorForm.hookFormGetValues(
                    'secondary_segment_value_ds_column_id'
                ),
            };
        }

        const source = editorForm.hookFormGetValues('dataset_source') ?? '';
        const srcParts = source.split('_');
        result['ds'] = {
            ...dsParams,
            dataset_id: srcParts?.length == 2 ? srcParts[0] : 0,
            dataset_filter_id: srcParts?.length == 2 ? srcParts[1] : '',
        };
    }

    return result;
}
