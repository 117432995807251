import { ActionMeta } from 'react-select';
import { FormComponentValue } from 'components/common/form/layout/control';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';
import { Params as UrlParams } from '@remix-run/router/dist/utils';

export type ReactSelectProps = {
    component?: string;
    uid?: string;
    id?: string;
    name?: string;
    addonLabel?: string;
    disabled?: boolean;
    data: Array<FormComponentValue>;
    value: any;
    update: (value: any, fullValue: any, actionMeta: ActionMeta<FormComponentValue>) => void;
    settingsUrl?: string;
    placeholder?: string;
    isMulti?: boolean;
    creatable?: boolean;
    isLoading?: boolean;
    onMenuOpen?: () => void;
    optionComponent?: (props: any) => any;
    onInputChange?: (nextValue: string) => void;
    customPortalTarget?: any;
    className?: string;
    isClearable?: boolean;
    isMultiOneRowMode?: boolean;
    tooltip?: string;
    async?: string;
    urlParams?: UrlParams;
    applyVirtualList?: boolean;
    getOptionLabel?: (option: any) => any;
};

export const useReactSelectStyles = () => {
    const appTheme = useTheme();

    const reactSelectTheme = (defaultTheme: any) => {
        return {
            ...defaultTheme,
            borderRadius: 3,
            colors: {
                ...defaultTheme.colors,
                primary: appTheme.palette.primary.main,
                primary25: alpha(appTheme.palette.primary.main, 0.25),
                primary50: alpha(appTheme.palette.primary.main, 0.5),
                primary75: alpha(appTheme.palette.primary.main, 0.75),
            },
            spacing: {
                ...defaultTheme.spacing,
                controlHeight: 32,
                menuGutter: 5,
            },
        };
    };

    const checkIsApplyMultiOneRowMode = (state: any) => {
        return state.selectProps.isMulti && state.selectProps.isMultiOneRowMode && !state.selectProps.menuIsOpen;
    };
    const checkIsApplyActiveMultiOneRowMode = (state: any) => {
        return state.selectProps.isMulti && state.selectProps.isMultiOneRowMode && state.selectProps.menuIsOpen;
    };

    // ToDo [Stas]: get and pass theme variables
    // Maybe need to:
    // - replace Dropdown icon
    // - set styles for different of default state
    // - consider search field to replace
    const reactSelectStyles = {
        container: (provided: any, state: any) => {
            const applyActiveMultiOneRowMode = checkIsApplyActiveMultiOneRowMode(state);

            const activeMultiOneRowModeStyles = applyActiveMultiOneRowMode
                ? {
                      position: 'absolute',
                      inset: '0 0 auto 0',
                      zIndex: 3,
                  }
                : {};
            return {
                ...provided,
                fontSize: `${appTheme.typography.fontSize}px`, // appTheme
                width: '100%',
                ...activeMultiOneRowModeStyles,
            };
        },
        control: (provided: any, state: any) => {
            let borderColor = useBlendColors(alpha(appTheme.palette.text.primary, 0.4));
            if (state.isFocused) borderColor = alpha(appTheme.palette.primary.main, 0.8);
            if (state.menuIsOpen) borderColor = appTheme.palette.primary.main;

            return {
                ...provided,
                borderColor: borderColor,
                boxShadow: state.menuIsOpen ? `0 0 0 2px ${alpha(appTheme.palette.primary.main, 0.24)}` : 'none',
                backgroundColor: appTheme.palette.background.default,
                minHeight: appTheme.size.defaultHeight + 'px',
                '&:hover': {
                    borderColor: state.menuIsOpen
                        ? appTheme.palette.primary.main
                        : alpha(appTheme.palette.primary.main, 0.8),
                },
            };
        },
        placeholder: (provided: any, state: any) => {
            return {
                ...provided,
                color: appTheme.palette.text.secondary,
                whiteSpace: 'nowrap', //hotfix for multi placeholder
            };
        },
        singleValue: (provided: any, state: any) => {
            return {
                ...provided,
                color: appTheme.palette.primary.main,
            };
        },
        multiValue: (provided: any, state: any) => {
            const applyMultiOneRowMode = checkIsApplyMultiOneRowMode(state);
            const multiOneRowModeStyles = applyMultiOneRowMode
                ? {
                      flexShrink: 0,
                  }
                : {};
            return {
                ...provided,
                backgroundColor: alpha(appTheme.palette.primary.main, 0.12),
                color: appTheme.palette.primary.main,
                margin: '1px',
                ...multiOneRowModeStyles,
            };
        },
        multiValueLabel: (provided: any, state: any) => {
            const applyMultiOneRowMode = checkIsApplyMultiOneRowMode(state);
            return {
                ...provided,
                color: appTheme.palette.primary.main,
                fontSize: `${appTheme.typography.fontSize}px`,
                paddingRight: applyMultiOneRowMode ? '6px' : '2px',

                '> svg': {
                    marginRight: 8,
                },

                '> img': {
                    top: 1,
                    marginRight: 5,
                    position: 'relative',
                },
            };
        },
        multiValueRemove: (provided: any) => {
            return {
                ...provided,
                cursor: 'pointer',
            };
        },
        dropdownIndicator: (provided: any, state: any) => {
            return {
                ...provided,
                padding: '0 4px',
                color: appTheme.palette.primary.main,
            };
        },
        clearIndicator: (provided: any, state: any) => {
            return {
                ...provided,
                padding: '0 4px',
                cursor: 'pointer',
                color: appTheme.palette.text.primary,
            };
        },
        valueContainer: (provided: any, state: any) => {
            const applyMultiOneRowMode = checkIsApplyMultiOneRowMode(state);
            const multiOneRowModeStyles = applyMultiOneRowMode
                ? {
                      flexWrap: 'nowrap',
                      '&:after': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          width: '25px',
                          backgroundImage: `linear-gradient(90deg, ${alpha(
                              appTheme.palette.background.default,
                              0
                          )} 0%, ${appTheme.palette.background.default} 100%)`,
                      },
                  }
                : {};
            return {
                ...provided,
                padding: '0 11px',
                '&.custom-select__value-container--is-multi.custom-select__value-container--has-value': {
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                ...multiOneRowModeStyles,
            };
        },
        menu: (provided: any, state: any) => {
            return {
                ...provided,
                background: appTheme.palette.background.default,
                boxShadow: '0px 4px 16px 0 rgba(0, 0, 0, 0.16)',
                color: appTheme.palette.text.primary,
                zIndex: 3, //overlap grid .rs-table-header-row-wrapper = 2
                padding: '4px 0',
                '& .react-virtualized-list': {
                    //Experimental for virtual list auto menu size
                    height: 'auto !important',
                },
                '& .react-virtualized-list__item > .custom-select__option': {
                    minHeight: '100%',
                },
            };
        },
        menuList: (provided: any, state: any) => {
            return {
                ...provided,
                paddingTop: 0,
                paddingBottom: 0,
            };
        },
        option: (provided: any, state: any) => {
            const separator = state?.value === 'sep';
            let background = appTheme.palette.background.default;
            if (state.isFocused && !state.isDisabled) background = alpha(appTheme.palette.primary.main, 0.04);
            if (state.isSelected) background = alpha(appTheme.palette.primary.main, 0.08);
            if (state.isDisabled) background = alpha(appTheme.palette.text.primary, 0.04);

            let color = state.isDisabled ? alpha(appTheme.palette.text.primary, 0.64) : appTheme.palette.text.primary;
            if (state.isSelected) color = appTheme.palette.primary.main;

            if (separator && state) {
                state.data.label = '';
            }

            return {
                ...provided,
                cursor: 'pointer',
                padding: separator ? '0' : '7px 12px',
                backgroundColor: background,
                color: color,
                position: 'relative',
                fontWeight: state.isDisabled ? '600' : undefined,
                wordWrap: 'break-word',

                '&:active': {
                    backgroundColor: background,
                },

                '&:before': {
                    content: '""',
                    position: 'absolute',
                    inset: '0 auto 0 0',
                    width: separator ? '100%' : '0',
                    height: separator ? '1px' : '',
                    background: separator ? alpha(appTheme.palette.text.secondary, 0.24) : '',
                    top: separator ? '50%' : '',
                    borderLeft: '1px solid',
                    borderColor: state.isSelected ? appTheme.palette.primary.main : 'transparent',
                },

                '> svg': {
                    marginRight: 8,
                    opacity: 0.6,
                },

                '> img': {
                    top: 1,
                    marginRight: 5,
                    position: 'relative',
                },
            };
        },
        indicatorSeparator: () => ({ display: 'none' }),
        menuPortal: (provided: any) => {
            return {
                ...provided,
                zIndex: appTheme.zIndex.tabsPanel - 1,
                '.popup-component ~ &': {
                    zIndex: appTheme.zIndex.modal + 50,
                },
                '.content-editor-aside-panel &': {
                    //todo: review and refactor to default aside modal
                    zIndex: appTheme.zIndex.tabsPanel + 5,
                },
            };
        },
    };

    return {
        theme: reactSelectTheme,
        styles: reactSelectStyles,
    };
};
