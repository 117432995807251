import { alpha, Box, Button, FormControl, FormLabel, IconButton, Input, Stack, Typography } from '@mui/material';
import ReactSelect from 'components/common/react-select/ReactSelect';
import React, { useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import { FormComponentValue } from 'components/common/form/layout/control';
import { FilterData } from '../index';
import useBundleTranslation from 'i18n';
import SeparatorItem from '../SeparatorItem';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { datasetViewAPI } from 'api/viewer/dataset';
import AddViewPopup from './AddViewPopup';
import { json } from 'd3';

function ViewSelection({
    selectedFilter,
    filterSelectData,
    onFilterSelect,
    filterDirty,
    selectedFilterData,
    isAdmin,
    isCreateAllowed,
    isPermitted4EditDataset,
    userId,
    viewCanBeDeleted,
    datasetId,
    originalDatasetId,
    reloadDatasetView,
    saveFilter,
    json,
}: {
    selectedFilter: string;
    filterSelectData: FormComponentValue[];
    onFilterSelect: (value: string) => void;
    filterDirty: boolean;
    selectedFilterData: FilterData | null;
    isAdmin: boolean;
    isCreateAllowed: boolean;
    isPermitted4EditDataset: boolean;
    userId: string;
    viewCanBeDeleted: boolean;
    datasetId: number;
    originalDatasetId: number;
    reloadDatasetView: (withFilter?: boolean, newFilterId?: number) => void;
    saveFilter: () => Promise<boolean>;
    json: string;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showRenamePopup, setShowRenamePopup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [name, setName] = useState('');
    const [showAddPopup, setShowAddPopup] = useState(false);

    let isEditAllowed = isAdmin || isCreateAllowed,
        isSaveAllowed = isAdmin || isCreateAllowed;

    if (selectedFilterData) {
        isEditAllowed = isAdmin || isPermitted4EditDataset || selectedFilterData.owner_user_id === userId;
    }

    const showRenameButton = isEditAllowed && isSaveAllowed && selectedFilterData;
    const showSaveFilterButton = selectedFilterData && isEditAllowed && isSaveAllowed;
    const showSaveAsFilterButton = isEditAllowed && selectedFilterData;
    const showSaveAsViewButton = !selectedFilterData && isEditAllowed && isSaveAllowed;
    const showDeleteFilterButton = viewCanBeDeleted && (selectedFilterData || datasetId !== originalDatasetId);

    const onDeleteConfirm = () => {
        if (selectedFilterData) {
            setShowLoader(true);
            datasetViewAPI
                .deleteDatasetView(datasetId, Number(selectedFilterData.dataset_filter_id))
                .then((response) => {
                    if (response.status === 'ERROR') {
                        setDeleteErrorMessage(response.message);
                    }
                    if (response.status === 'OK') {
                        closeDeleteConfirm();
                        reloadDatasetView(false);
                    }
                })
                .finally(() => {
                    setShowLoader(false);
                });
        } else {
            closeDeleteConfirm();
        }
    };

    const confirmDeleteSettings: PopupSettings = {
        title: deleteErrorMessage > '' ? t('info') : t('view.delete_title'),
        textOK: t('view.delete_button'),
        textCancel: deleteErrorMessage > '' ? t('ok') : t('cancel'),
        noOk: deleteErrorMessage > '',
    };

    const renamePopupSettings: PopupSettings = {
        title: t('view.rename_title'),
        textOK: t('view.rename_button'),
    };

    const closeDeleteConfirm = () => {
        setShowLoader(false);
        setDeleteErrorMessage('');
        setShowDeletePopup(false);
    };

    const onRenameClick = () => {
        if (selectedFilterData) {
            setShowRenamePopup(true);
            setName(selectedFilterData.name);
        }
    };

    const onSaveAsClick = () => {
        setShowAddPopup(true);
    };

    const onCloseRenamePopup = () => {
        setShowRenamePopup(false);
        setName('');
    };

    const onRenameConfirm = () => {
        if (selectedFilterData) {
            setShowLoader(true);
            datasetViewAPI
                .renameFilter(datasetId, Number(selectedFilterData.dataset_filter_id), name)
                .then((response) => {
                    reloadDatasetView();
                    onCloseRenamePopup();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        }
    };

    const afterFilterSave = (newFilterId: number) => {
        reloadDatasetView(true, newFilterId);
    };

    return (
        <>
            <Stack direction={'row'} spacing={1} alignItems="center">
                <Box sx={{ width: '264px' }}>
                    <ReactSelect
                        id={'select-view'}
                        data={filterSelectData}
                        isLoading={false}
                        value={selectedFilter}
                        update={onFilterSelect}
                    />
                </Box>
                {showRenameButton && (
                    <Button
                        data-test={'save-as-view-button'}
                        variant={'outlined'}
                        color={'neutral'}
                        onClick={onRenameClick}
                    >
                        {t('view_selection.rename_button')}
                    </Button>
                )}
                {showSaveFilterButton && (
                    <Button
                        data-test={'save-as-view-button'}
                        variant={'outlined'}
                        color={'success'}
                        disabled={!filterDirty}
                        onClick={saveFilter}
                        startIcon={<IconMi icon={'save'} />}
                    >
                        {`${t('view_selection.save_button')} ${
                            selectedFilterData ? '' : t('view_selection.as_view_button')
                        }`}
                    </Button>
                )}
                {showSaveAsViewButton && (
                    <Button
                        variant={'contained'}
                        color={'success'}
                        disabled={!filterDirty}
                        onClick={onSaveAsClick}
                        startIcon={<IconMi icon={'save'} />}
                    >
                        {`${t('view_selection.save_button')} ${t('view_selection.as_view_button')}`}
                    </Button>
                )}
                {(showSaveAsFilterButton || showDeleteFilterButton) && (
                    <Box sx={{ px: 0.5 }}>
                        <SeparatorItem />
                    </Box>
                )}
                {showSaveAsFilterButton && (
                    <Button
                        variant={'contained'}
                        color={'success'}
                        onClick={onSaveAsClick}
                        startIcon={<IconMi icon={'save'} />}
                    >
                        {t('view_selection.save_as_button')}
                    </Button>
                )}
                {showDeleteFilterButton && (
                    <IconButton
                        sx={{ p: '6px' }}
                        onClick={() => {
                            setShowDeletePopup(true);
                        }}
                    >
                        <IconMi icon={'trash'} fontSize="16" />
                    </IconButton>
                )}
            </Stack>
            {showDeletePopup && (
                <Popup
                    settings={confirmDeleteSettings}
                    open={showDeletePopup}
                    onHide={closeDeleteConfirm}
                    onConfirm={onDeleteConfirm}
                    maxWidth={'popupSm'}
                >
                    {deleteErrorMessage === '' ? (
                        <Typography>Are you sure that you want to delete the Dataset View?</Typography>
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: deleteErrorMessage }} />
                    )}
                    {showLoader && (
                        <LoadingPlaceholder
                            sx={{
                                position: 'absolute',
                                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                                color: (theme) => alpha(theme.palette.text.primary, 0.5),
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                        />
                    )}
                </Popup>
            )}
            {showRenamePopup && (
                <Popup
                    settings={renamePopupSettings}
                    open={showRenamePopup}
                    onHide={onCloseRenamePopup}
                    onConfirm={onRenameConfirm}
                    maxWidth={'popupSm'}
                >
                    <FormControl>
                        <FormLabel>{t('name')}</FormLabel>
                        <Input
                            name={'view_name'}
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            fullWidth
                        />
                    </FormControl>
                </Popup>
            )}
            {showAddPopup && (
                <AddViewPopup
                    showAddPopup={showAddPopup}
                    setShowAddPopup={setShowAddPopup}
                    datasetId={datasetId}
                    json={json}
                    afterSave={afterFilterSave}
                />
            )}
        </>
    );
}

export default ViewSelection;
