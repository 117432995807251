import { alpha } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';

export const MuiToggleButtonConfig = {
    defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        size: 'medium',
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {
                '.toggle-button--mod-light &': {
                    backgroundColor: theme.palette.background.default,
                },
                '&:focus': {
                    outline: 'none',
                },
                '&:hover, &.Mui-selected:hover': {
                    borderColor: alpha(theme.palette.text.primary, 0.24),
                    backgroundColor: alpha(theme.palette.text.primary, 0.04),
                    color: alpha(theme.palette.text.primary, 0.8),
                },
                '&:active, &.Mui-selected:active': {
                    borderColor: alpha(theme.palette.text.secondary, 0.64),
                    backgroundColor: alpha(theme.palette.text.secondary, 0.24),
                    color: alpha(theme.palette.text.secondary, 1),
                },
                '&.Mui-selected': {
                    fontWeight: 600, //todo review it. can jump
                    backgroundColor: 'transparent',
                    '.toggle-button--mod-light &': {
                        backgroundColor: useBlendColors(
                            alpha(theme.componentSettings.primaryButton.primaryColor, 0.08)
                        ),
                        zIndex: 1,
                    },
                },
                '&.Mui-disabled': {
                    color: alpha(theme.palette.text.primary, 0.24),
                    borderColor: alpha(theme.palette.text.primary, 0.24),
                    '&.MuiToggleButtonGroup-grouped:not(:hover)': {
                        borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    },
                },
                '&.Mui-selected.Mui-disabled': {
                    color: alpha(theme.palette.text.primary, 0.55),
                    borderColor: alpha(theme.palette.text.primary, 0.55),
                    '&.MuiToggleButtonGroup-grouped:not(:hover)': {
                        borderColor: useBlendColors(alpha(theme.palette.text.primary, 0.55)),
                        zIndex: 1,
                    },
                },
                '&.Mui-selected:not(.Mui-disabled)': {
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    '&.MuiToggleButtonGroup-grouped:not(:hover)': {
                        borderLeftColor: theme.palette.primary.main,
                        zIndex: 1,
                    },
                },
            };
        },
        sizeMedium: ({ theme }: { theme: any }) => {
            return {
                padding: '4px 12px',
                minHeight: theme.size.defaultHeight,
                color: theme.palette.text.primary,
                borderColor: alpha(theme.palette.text.primary, 0.4),
            };
        },
    },
};
