import { TooltipFormatterContextObject } from 'highcharts/highstock';
import React from 'react';
import { AssocArray } from 'tools/types';

export default function RangeChartTooltip({
    context,
    formattedValues,
}: {
    context: TooltipFormatterContextObject;
    formattedValues: AssocArray<string>;
}) {
    const point = context.point;
    return (
        <div>{(formattedValues[String(point.low)] ?? '') + ' - ' + (formattedValues[String(point.high)] ?? '')}</div>
    );
}
