import {
    ChartPoint,
    ChartPointSeriesMetadata,
    ChartSeriesType,
    ChartSeriesTypeRaw,
    DATA_X_MULTIPLIER,
    getChartIcon,
    MetricChartStateLegend,
} from 'components/metric/chart/index';
import { Chart as HChart } from 'highcharts';
import { strToSvg, svgElementFromString } from 'components/metric/chart/svgTools';
import { AssocArray } from 'tools/types';
import { ElementRowData } from 'components/element-viewer';
import { Theme } from '@mui/material';

export interface ChartAnnotationPointTypeRaw {
    x: number;
    title: number;
    text: string;
    display_name: string;
    mydata?: any;
    annotation_measurement_time: string;
    annotation_measurement_time_formatted: string;
    value_formatted: string;
}

export interface ChartAnnotationSeriesTypeRaw {
    id: string;
    data: { point: Array<ChartAnnotationPointTypeRaw>; range: Array<ChartAnnotationPointTypeRaw> };
}

export interface ChartPointAnnotationSeriesMetadata extends ChartPointSeriesMetadata {
    title: number;
    userName: string;
    text: string;
    annotationMeasurementTime: string;
    annotationMeasurementTimeFormatted: string;
    valueFormatted: string;
}

export interface ChartPointAnnotation extends ChartPoint {
    marker: any;
    iconNumber: number;
    pointId: string;
    iconSvg: SVGSVGElement;
    rangeEnd: number;
    metadata: ChartPointAnnotationSeriesMetadata & { [key: string]: any };
}

export function getAnnotationSeries(
    series: Array<ChartSeriesTypeRaw>,
    annotationSeries: Array<ChartAnnotationSeriesTypeRaw>,
    legendState: AssocArray<MetricChartStateLegend>,
    chart: HChart,
    elementRow: ElementRowData,
    theme: Theme
): Array<ChartSeriesType> {
    if (!chart?.series || chart.series.length == 0) {
        return [];
    }

    const data: Array<ChartPoint> = [];

    const buildPoint = (
        seriesPoint: ChartPoint,
        annotationPointRaw: ChartAnnotationPointTypeRaw,
        iconNumber: number
    ): ChartPointAnnotation => {
        // TODO:
        const toPixels = chart.yAxis[0].toPixels(Number(seriesPoint.y), false) + (-20 - iconNumber * 38) * 0;

        const iconSvg = strToSvg(getChartIcon('annotationChartIcon', theme));
        const rangeEnd = annotationPointRaw?.mydata?.finish ?? 0;

        const metadata: ChartPointAnnotationSeriesMetadata = {
            ...(annotationPointRaw.mydata ?? {}),
            title: annotationPointRaw.title,
            userName: annotationPointRaw.display_name,
            text: annotationPointRaw.text,
            annotationMeasurementTime: annotationPointRaw.annotation_measurement_time,
            annotationMeasurementTimeFormatted: annotationPointRaw.annotation_measurement_time_formatted,
            valueFormatted: annotationPointRaw.value_formatted,
        };

        return {
            x: seriesPoint.x * DATA_X_MULTIPLIER,
            y: chart.yAxis[0].toValue(toPixels),

            marker: {
                enabled: false,
            },
            metadata: metadata,
            iconNumber: iconNumber,
            pointId: `annotation_${seriesPoint.x}_${rangeEnd}_${iconNumber}`,
            iconSvg: iconSvg,
            rangeEnd: rangeEnd,
        } as ChartPointAnnotation;
    };

    const iconsDateMap: AssocArray<number> = {};
    annotationSeries.forEach((annotation) => {
        const chartSeries = series.find((s) => s.id == annotation.id);
        if (!chartSeries) {
            return;
        }

        const chartSeriesData = chartSeries.data.filter((row) => {
            return !(
                (elementRow?.metricMaxValueToChart && row.y > Number(elementRow.metricMaxValueToChart)) ||
                (elementRow?.metricMinValueToChart && row.y < Number(elementRow.metricMinValueToChart))
            );
        });

        annotation.data.point.forEach((annotationPoint) => {
            const point = chartSeriesData.find((seriesPoint) => seriesPoint.x == annotationPoint.x);
            if (!point) {
                return;
            }
            if (typeof iconsDateMap[point.x] == 'undefined') {
                iconsDateMap[point.x] = 0;
            }
            data.push(buildPoint(point, annotationPoint, iconsDateMap[point.x]));
            iconsDateMap[point.x]++;
        });

        annotation.data.range.forEach((annotationPoint) => {
            const point = chartSeriesData.find((seriesPoint) => seriesPoint.x == annotationPoint.mydata.start);
            if (!point) {
                return;
            }
            if (typeof iconsDateMap[point.x] == 'undefined') {
                iconsDateMap[point.x] = 0;
            }
            data.push(buildPoint(point, annotationPoint, 0));
        });
    });

    const id = 'annotations';
    return [
        {
            seriesType: 'annotation',
            data: data,
            type: 'scatter',
            id: id,
            name: 'Annotations',
            dashStyle: 'Dot',
            color: 'red',
            zIndex: -1,
            lineWidth: 0,
            marker: {
                enabled: true,
                symbol: svgElementFromString(getChartIcon('annotationLegendIcon', theme)),
            },
            states: {
                hover: {
                    lineWidthPlus: 0,
                    enabled: false,
                },
            },
            visible: legendState ? legendState[id]?.visible ?? true : true,
        },
    ];
}
