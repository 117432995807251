import { getElementFieldValue } from 'components/common/form/formTools';
import { useEffect, useState } from 'react';
import { HintComponentProps, useMethod } from 'components/common/form/data-fetch-command/hint/index';
import styles from '../DataFetchCommandHint.styles';
import { Box } from '@mui/material';

export default function MetricHint({ controlProps, t }: HintComponentProps) {
    const segment = controlProps.form.hookFormWatch('segment_id');
    const connection = controlProps.form.hookFormWatch('source_database_connection_id');
    const profile = controlProps.form.hookFormWatch('plugin_connection_profile_id');
    const interval = controlProps.form.hookFormWatch('measurement_interval_id');

    const getSegmentAttr = (attr: string) => {
        const result = getElementFieldValue(controlProps.form, `segment_id.${attr}`);
        return result == null ? '' : result;
    };

    const [hintText, setHintText] = useState('');
    const method = useMethod(controlProps.form);

    useEffect(() => {
        const segmentType = getSegmentAttr('segment_type');
        const segmentLabel = getSegmentAttr('$option.label');
        const fetchCommandLabel = controlProps.form.hookFormGetValues('fetch_command_label');
        let html: Array<string> = [];
        if (method == 'sql' || method == 'existing reports' || method == 'existing datasets' || method == 'plugin') {
            let innerMethod = method == 'plugin' ? fetchCommandLabel : method;
            innerMethod =
                innerMethod == 'sql' || innerMethod == 'existing reports' || innerMethod == 'existing datasets'
                    ? 'SQL statement'
                    : innerMethod;

            html = [t('main_hint.metric.enter_inner_method', { innerMethod: innerMethod }), '<br/>'];
            if ('compound' == segmentType) {
                const p_segment_id = Number(getSegmentAttr('primary_segment_id'));
                const s_segment_id = Number(getSegmentAttr('secondary_segment_id'));
                html.push(
                    '<b>' + (p_segment_id > 0 ? getSegmentAttr('primary_label') : t('main_hint.primary')) + '</b><br/>'
                );
                html.push(
                    '<b>' +
                        (s_segment_id > 0 ? getSegmentAttr('secondary_label') : t('main_hint.secondary')) +
                        '</b><br/>'
                );
            } else if ('simple' == segmentType) {
                const isRuntimeSegment = 'runtime' == getSegmentAttr('dfm');
                html.push(t('main_hint.metric.include_first_column', { label: segmentLabel }));
                if (!isRuntimeSegment) {
                    html.push(
                        t('main_hint.metric.otherwise_to_fetch', {
                            label: segmentLabel,
                            bindParam: getSegmentAttr('bind_param'),
                        })
                    );
                }
                html.push('<br/>');
            }

            let datetimeFormat = getElementFieldValue(controlProps.form, `source_database_connection_id.date`) ?? '';
            const intervalUnit = getElementFieldValue(controlProps.form, `measurement_interval_id.interval_unit`) ?? '';
            if ((!datetimeFormat && datetimeFormat == '') || method != 'sql') {
                datetimeFormat = 'YYYY-MM-DD';
                if (['minute', 'hour'].includes(intervalUnit)) {
                    datetimeFormat += ' HH:MI:SS';
                }
            }

            html.push(
                t('main_hint.metric.date_format', { datetimeFormat: datetimeFormat }) +
                    '<br/>' +
                    t('main_hint.metric.measurement_value')
            );

            let last_measurement_time = ':last_measurement_time';
            // TODO: ?
            // if (checkFlashEnabled()) {
            //     last_measurement_time =
            //         '<a id="clip_bind_param" href="javascript:void(0);" title="Click to copy text to your clipboard" data-bind-param=":last_measurement_time" onclick="return false;">:last_measurement_time</a>';
            // }

            html.push('<br/>' + t('main_hint.metric.also_include', { lastMeasurementTime: last_measurement_time }));

            if (method == 'plugin') {
                const additional_hint =
                    getElementFieldValue(controlProps.form, `plugin_connection_profile_id.add_hint`) ?? '';
                if (additional_hint > '') {
                    html.push('<br />' + atob(additional_hint));
                }
            }
        } else if (method == 'composite') {
            // TODO: ?
            // met.loadCompositeMetricHint(hint, forceReloadHint);
        }

        if ('manual' == method) {
            html.push(t('main_hint.metric.use_load_data'));
        }

        setHintText(html.join(''));
    }, [method, segment, connection, interval, profile]);

    if (!hintText) return null;

    return <Box sx={styles.hintWrapper} dangerouslySetInnerHTML={{ __html: hintText }} />;
}
