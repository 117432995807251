import { instance } from 'api/api';
import { APIResponse } from 'tools/types';
import {
    CreateReportResponse,
    DatasetDataResponse,
    DeleteViewResponse,
    DerivedFieldData,
    DerivedFieldValidationResponse,
    DisplayMasksResponse,
    ElementsAmount,
    LoadViewSQLDataResponse,
} from 'components/dataset-viewer';
import { VirtualDatasetColumns } from 'components/virtual-dataset-viewer';
import { MetricItem, SegmentReuse, SegmentReuseResponse } from 'components/dataset-viewer/metric-builder';

const DATASET_VIEW_BASE_URL = `/data/dataset/`;

export interface CreateMetricResponse {
    status: 'OK' | 'ERROR';
    id: number;
    message?: string;
}

export const datasetViewAPI = {
    async getDatasetData(
        datasetId: number,
        filterId: number,
        instanceId: number
    ): Promise<APIResponse<DatasetDataResponse>> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/load-dataset-data`;
        const response = await instance.post(url, {
            filterId,
            instance: instanceId,
        });

        return response.data;
    },
    async getVirtualDatasetColumns(datasetId: number): Promise<APIResponse<VirtualDatasetColumns[]>> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/load-columns`;
        const response = await instance.get(url);
        return response.data;
    },
    async getElementsAmount(datasetId: number, filterId: number): Promise<APIResponse<ElementsAmount>> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/filter/${filterId}/get-elements-amount`;
        const response = await instance.post(url);

        return response.data;
    },
    async getDisplayMasks(datasetId: number): Promise<APIResponse<DisplayMasksResponse>> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/querybuilder/derived-field`;
        const response = await instance.get(url);

        return response.data;
    },
    async validateDerivedField(
        datasetId: number,
        params: DerivedFieldData,
        cond: string
    ): Promise<DerivedFieldValidationResponse> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/querybuilder/validate-derived-field`;
        const response = await instance.post(url, { cond: cond, field: params });

        return response.data;
    },
    async deleteDatasetView(datasetId: number, filterId: number): Promise<DeleteViewResponse> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/filter/${filterId}/delete`;
        const response = await instance.post(url, { datasetId, filterId });

        return response.data;
    },
    async loadViewSqlData(datasetId: number, filterId: number, json: string): Promise<LoadViewSQLDataResponse> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/querybuilder/view-sql`;
        const response = await instance.post(url, { datasetId, dataset_filter_id: filterId, cond: json });

        return response.data;
    },
    async createReport(datasetId: number, filterId: number, json: string): Promise<CreateReportResponse> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/create-report`;
        const response = await instance.post(url, { datasetId, filterId: filterId, cond: json });
        return response.data;
    },
    async switchFilterPublic(datasetId: number, filterId: number, newPublic: 'Y' | 'N'): Promise<APIResponse<{}>> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/querybuilder/save-filter-public`;
        const response = await instance.post(url, { datasetId, filterId: filterId, public_ind: newPublic });
        return response.data;
    },
    async renameFilter(datasetId: number, filterId: number, name: string): Promise<APIResponse<{}>> {
        const url = `data/editor/dataset/${datasetId}/filter/${filterId}/form`;
        const response = await instance.post(url, { name });
        return response.data;
    },
    async createFilter(
        datasetId: number,
        name: string,
        publicInd: 'Y' | 'N'
    ): Promise<{ status: string; id: number; messages?: any }> {
        const url = `data/editor/dataset/${datasetId}/filter/0/form`;
        const response = await instance.post(url, { name, public_ind: publicInd });
        return response.data;
    },
    async saveFilter(datasetId: number, filterId: number, serializedRule: string): Promise<APIResponse<{}>> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/querybuilder/filter/${filterId}/save`;
        const response = await instance.post(url, { rules_serialized: serializedRule });
        return response.data;
    },
    async runDownload(datasetId: number, instanceId: number, filterId: number): Promise<APIResponse<{}>> {
        const url = DATASET_VIEW_BASE_URL + `${datasetId}/instance/${instanceId}/filter/${filterId}/state/run/download`;
        const response = await instance.get(url);
        return response.data;
    },
    async checkDownload(
        datasetId: number,
        instanceId: number,
        filterId: number
    ): Promise<APIResponse<{ pref: string; ready: boolean }>> {
        const url =
            DATASET_VIEW_BASE_URL + `${datasetId}/instance/${instanceId}/filter/${filterId}/state/check/download`;
        const response = await instance.get(url);
        return response.data;
    },
    async loadMetricChartData(datasetId: number, metric?: MetricItem): Promise<APIResponse<any>> {
        const url = `/data/dataset/${datasetId}/metric-preview-data`;
        const response = await instance.post(url, metric);

        return response.data;
    },
    async createMetric(datasetId: number, metric: MetricItem): Promise<CreateMetricResponse> {
        const url = `/data/dataset/${datasetId}/create-metric`;
        const response = await instance.post(url, metric);

        return response.data;
    },
    async validateSegmentReuse(datasetId: number, data: SegmentReuse): Promise<SegmentReuseResponse> {
        const url = `/data/dataset/${datasetId}/querybuilder/validate-segment-reuse`;
        const response = await instance.post(url, data);

        return response.data;
    },
    async collectMetric(datasetId: number, metricId: number): Promise<APIResponse<any>> {
        const response = await instance.get(`/data/dataset/${datasetId}/collect-metric/${metricId}`);

        return response.data;
    },
};
