import { useContext, useState } from 'react';
import { PopupSettings } from 'components/common/popup/Popup';
import { PopupMode, PopupType } from '../index';
import { AsyncPopupContext, AsyncPopupContextType } from 'tools/AsyncPopupContext';
import { useQueryClient } from '@tanstack/react-query';

export const useGridPopup = (gridUid: string) => {
    const queryClient = useQueryClient();

    const [popupUrl, setPopupUrl] = useState<string>('');
    const [popupConfig, setPopupConfig] = useState<PopupSettings>({});
    const [popupType, setPopupType] = useState<PopupType>('page');
    const [popupMode, setPopupMode] = useState<PopupMode>('new');
    const [popupRedirectAfter, setPopupRedirectAfter] = useState(false);
    const [formPostData, setFormPostData] = useState();
    const [popupGetMethod, setPopupGetMethod] = useState(false);
    const [popupComponent, setPopupComponent] = useState('');
    const [popupComponentProps, setPopupComponentProps] = useState<{ [key: string]: string }>({});

    const popupContext = useContext<AsyncPopupContextType | null>(AsyncPopupContext);

    const unmountPopup = () => {
        const uid: string = popupUrl.replaceAll('/', '_');
        queryClient.removeQueries([uid]);
        queryClient.removeQueries([popupUrl]);
        setPopupUrl('');
        setPopupConfig({});
        setPopupRedirectAfter(false);
        setPopupType('page');
        setPopupMode('new');
        setFormPostData(undefined);
        setPopupGetMethod(false);
    };

    const handleHidePopup = function () {
        unmountPopup();
        if (popupContext) {
            popupContext.onClose(gridUid);
        }
    };

    const handleConfirmPopup = function () {
        unmountPopup();
        if (popupContext) {
            popupContext.onApply(gridUid);
        }
    };

    return {
        popupUrl,
        setPopupUrl,
        popupConfig,
        setPopupConfig,
        popupType,
        setPopupType,
        popupMode,
        setPopupMode,
        popupRedirectAfter,
        setPopupRedirectAfter,
        formPostData,
        setFormPostData,
        popupGetMethod,
        setPopupGetMethod,
        popupComponent,
        setPopupComponent,
        handleHidePopup,
        handleConfirmPopup,
        popupComponentProps,
        setPopupComponentProps,
    };
};
