import { ReactComponent as Burst } from 'assets/icons/lineage/burst.svg';
import { ReactComponent as Dataset } from 'assets/icons/lineage/dataset.svg';
import { ReactComponent as DatasetView } from 'assets/icons/lineage/dataset-view.svg';
import { ReactComponent as DeletedDataset } from 'assets/icons/lineage/deleted-dataset.svg';
import { ReactComponent as Element } from 'assets/icons/lineage/element.svg';
import { ReactComponent as ExternalContent } from 'assets/icons/lineage/external-content.svg';
import { ReactComponent as ExternalDataset } from 'assets/icons/lineage/external-dataset.svg';
import { ReactComponent as ExternalDatasetView } from 'assets/icons/lineage/external-dataset-view.svg';
import { ReactComponent as ExternalReport } from 'assets/icons/lineage/external-report.svg';
import { ReactComponent as ExternalSource } from 'assets/icons/lineage/external-source.svg';
import { ReactComponent as LegacyReport } from 'assets/icons/lineage/legacy-report.svg';
import { ReactComponent as Metric } from 'assets/icons/lineage/metric.svg';
import { ReactComponent as MultiMetric } from 'assets/icons/lineage/multi-metric.svg';
import { ReactComponent as Plugin } from 'assets/icons/lineage/plugin.svg';
import { ReactComponent as Report } from 'assets/icons/lineage/report.svg';
import { ReactComponent as Source } from 'assets/icons/lineage/source.svg';
import { ReactComponent as View } from 'assets/icons/lineage/view.svg';

import { ReactComponent as CollapsedBurst } from 'assets/icons/lineage/collapsed/burst.svg';
import { ReactComponent as CollapsedDataset } from 'assets/icons/lineage/collapsed/dataset.svg';
import { ReactComponent as CollapsedDatasetView } from 'assets/icons/lineage/collapsed/dataset-view.svg';
import { ReactComponent as CollapsedElement } from 'assets/icons/lineage/collapsed/element.svg';
import { ReactComponent as CollapsedExternalContent } from 'assets/icons/lineage/collapsed/external-content.svg';
import { ReactComponent as CollapsedExternalDataset } from 'assets/icons/lineage/collapsed/external-dataset.svg';
import { ReactComponent as CollapsedExternalDatasetView } from 'assets/icons/lineage/collapsed/external-dataset-view.svg';
import { ReactComponent as CollapsedExternalReport } from 'assets/icons/lineage/collapsed/external-report.svg';
import { ReactComponent as CollapsedExternalSource } from 'assets/icons/lineage/collapsed/external-source.svg';
import { ReactComponent as CollapsedLegacyReport } from 'assets/icons/lineage/collapsed/legacy-report.svg';
import { ReactComponent as CollapsedMetric } from 'assets/icons/lineage/collapsed/metric.svg';
import { ReactComponent as CollapsedMultiMetric } from 'assets/icons/lineage/collapsed/multi-metric.svg';
import { ReactComponent as CollapsedPlugin } from 'assets/icons/lineage/collapsed/plugin.svg';
import { ReactComponent as CollapsedReport } from 'assets/icons/lineage/collapsed/report.svg';
import { ReactComponent as CollapsedSource } from 'assets/icons/lineage/collapsed/source.svg';
import { ReactComponent as CollapsedView } from 'assets/icons/lineage/collapsed/view.svg';

import React from 'react';
import { UseSvgDataUri } from 'hooks/useSvg';
import { AssocArray } from 'tools/types';

const iconsByType: { [key: string]: any } = {
    burst: Burst,
    dataset: Dataset,
    'dataset-view': DatasetView,
    'deleted-dataset': DeletedDataset,
    element: Element,
    'external-content': ExternalContent,
    'external-dataset': ExternalDataset,
    'external-metadata': ExternalDataset,
    'external-dataset-view': ExternalDatasetView,
    'external-report': ExternalReport,
    'external-source': ExternalSource,
    'legacy-report': LegacyReport,
    metric: Metric,
    'multi-metric': MultiMetric,
    plugin: Plugin,
    report: Report,
    source: Source,
    view: View,
};

const collapsedIconsByType: { [key: string]: any } = {
    burst: CollapsedBurst,
    dataset: CollapsedDataset,
    'dataset-view': CollapsedDatasetView,
    element: CollapsedElement,
    'external-content': CollapsedExternalContent,
    'external-dataset': CollapsedExternalDataset,
    'external-metadata': CollapsedExternalDataset,
    'external-dataset-view': CollapsedExternalDatasetView,
    'external-report': CollapsedExternalReport,
    'external-source': CollapsedExternalSource,
    'legacy-report': CollapsedLegacyReport,
    metric: CollapsedMetric,
    'multi-metric': CollapsedMultiMetric,
    plugin: CollapsedPlugin,
    report: CollapsedReport,
    source: CollapsedSource,
    view: CollapsedView,
};

export const getImageByType = (type: string = 'element', collapsed: boolean = false, color?: string) => {
    if (collapsed) {
        return collapsedIconsByType[type]
            ? UseSvgDataUri(collapsedIconsByType[type], color)
            : collapsedIconsByType[type];
    }

    return iconsByType[type] ? UseSvgDataUri(iconsByType[type], color) : iconsByType[type];
};

export const getIconComponentByType = (type: string = 'element', color?: string) => {
    return (
        <img
            width="100%"
            height="100%"
            style={{ maxWidth: '16px', maxHeight: '16px' }}
            src={UseSvgDataUri(iconsByType[type], color)}
            alt={type}
        />
    );
};

export const getPluginIconComponent = (pluginName: string, externalIcons: AssocArray<string>) => {
    const icon = getPluginIcon(pluginName, externalIcons);

    if (icon) {
        return (
            <img
                width="100%"
                height="100%"
                style={{ maxWidth: '16px', maxHeight: '16px' }}
                src={icon}
                alt={pluginName}
            />
        );
    }

    return null;
};

export const getPluginIcon = (pluginName: string, externalIcons: AssocArray<string>) => {
    let icon = null,
        convertedPluginName = pluginName.toLowerCase().replace(/\s/g, '');

    if (convertedPluginName.includes('microsoftpowerbi')) {
        convertedPluginName = 'microsoftpowerbi';
    }

    for (let pluginDBName in externalIcons) {
        if (externalIcons.hasOwnProperty(pluginDBName)) {
            let convertedPluginDBName = pluginDBName.toLowerCase().replace(/\s/g, '');

            if (convertedPluginDBName === convertedPluginName) {
                icon = externalIcons[pluginDBName];
                break;
            }
        }
    }

    return icon;
};
