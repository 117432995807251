import { alpha, Theme } from '@mui/material';

export default {
    container: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',

        '& > .MuiCardContent-root': {
            padding: '0 !important',
        },
    },
    title: {
        fontWeight: 'bold',
    },
    content: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    scrollContainer: {
        overflow: 'auto',
        flexGrow: 1,
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    type: {
        textAlign: 'center',
        wordBreak: 'break-word',
        textTransform: 'lowercase',
        marginLeft: '10px',
    },
    command: {
        display: 'inline-block',
        flexBasis: '70%',
        color: 'primary.main',
        textDecoration: 'underline',
        cursor: 'pointer',
        wordBreak: 'break-word',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    box: {
        padding: '12px 16px',
        '&:not(:last-of-type)': {
            borderBottom: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        },
    },
};
