import { useCallback, useState } from 'react';
import { MassActionsConfig } from 'components/common/grid/';

export const useGridMassActions = (gridData: Array<any>, massActionsConfig?: MassActionsConfig) => {
    const [checkedRowsKeys, setCheckedKeys] = useState<Array<string | number>>([]);
    const [checkedRowsNames, setCheckedNames] = useState<Array<string | number>>([]);
    const itemIdKey = massActionsConfig?.idColumn ?? 'id';

    const handleCheckAllRows = (event: any) => {
        const checked = event.target.checked;

        const currentPageKeys: (string | number)[] = gridData
            .filter((item) => !item['unselectable'])
            .map((item) => {
                return !isNaN(parseInt(item[itemIdKey])) ? parseInt(item[itemIdKey]) : item[itemIdKey];
            });

        setCheckedKeys((current) => {
            const currentPageKeysSet = new Set(current);

            currentPageKeys.forEach((key) => {
                if (checked) {
                    currentPageKeysSet.add(key);
                } else {
                    currentPageKeysSet.delete(key);
                }
            });

            return [...currentPageKeysSet];
        });
    };

    const checkAllChecked = () => {
        const currentPageKeys = gridData.map((item) => {
            if (item['unselectable']) {
                return null;
            }

            return !isNaN(parseInt(item[itemIdKey])) ? parseInt(item[itemIdKey]) : item[itemIdKey];
        });

        const includesAllCurrentPageValues = currentPageKeys.some((key) => checkedRowsKeys.includes(key));
        const checkedKeysFromCurrentPage = currentPageKeys.filter((key) => checkedRowsKeys.includes(key));

        return Boolean(
            includesAllCurrentPageValues &&
                checkedKeysFromCurrentPage.length == currentPageKeys.length &&
                currentPageKeys.length > 0 &&
                checkedRowsKeys.length > 0
        );
    };

    const handleCheckRow = useCallback(
        (event: any) => {
            const checked = event.target.checked;
            const value = !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : event.target.value;
            setCheckedNames([]);

            setCheckedKeys((current) => {
                const selectedKeysSet = new Set(current);

                if (checked) {
                    selectedKeysSet.add(value);
                } else {
                    selectedKeysSet.delete(value);
                }

                return [...selectedKeysSet];
            });

            setCheckedNames((current) => {
                const selectedNamesSet = new Set(current);
                const selectedRow = gridData.find((data) => Object.values(data).includes(value));
                const field = selectedRow?.name || selectedRow?.display_name || selectedRow?.username;
                const name = field?.config?.text;
                if (name) {
                    if (checked) {
                        selectedNamesSet.add(name);
                    } else {
                        selectedNamesSet.delete(name);
                    }
                }
                return [...selectedNamesSet];
            });
        },
        [checkedRowsKeys, checkedRowsNames, gridData]
    );

    return {
        handleCheckRow,
        checkAllChecked,
        handleCheckAllRows,
        checkedRowsKeys,
        setCheckedKeys,
        checkedRowsNames,
    };
};
