import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FilterElementProps } from '../index';
import { gridAPI } from 'api/grid';
import { FormComponentValue } from 'components/common/form/layout/control';
import ReactSelect from 'components/common/react-select/ReactSelect';
import useBundleTranslation from 'i18n';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';

function SelectFilter({ name, id, value, onChange, label, dataUrl, isMulti, filterData }: FilterElementProps) {
    const { t } = useBundleTranslation(['components/common/grid/grid_filter']);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<FormComponentValue>>(
        filterData
            ? filterData
            : isMulti
            ? []
            : [
                  {
                      label: t('all_label'),
                      value: '',
                  },
              ]
    );

    useEffect(() => {
        if (value) {
            onFilterOpen();
            onChange(name, value);
        }
    }, []);

    const handleSelectUpdate = (newValue: any) => {
        onChange(name, newValue);
    };

    const onFilterOpen = () => {
        if (dataUrl && data.length < 2) {
            setIsLoading(true);
            gridAPI.loadFilter(dataUrl).then((data: { data: { status: string; data: FormComponentValue[] } }) => {
                if (data.data.status === 'OK' && data.data.data.length > 0) {
                    setIsLoading(false);
                    setData(data.data.data);
                }
            });
        }
    };

    return (
        <Grid wrap="nowrap" item container xs={'auto'} key={`grid-filter-${id}-${name}`}>
            <Box flexGrow="1" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                {label && <StaticAddon>{t(label)}</StaticAddon>}
                <Box sx={{ width: '144px' }}>
                    <ReactSelect
                        data={data}
                        value={Array.isArray(value) ? value : [value]}
                        update={handleSelectUpdate}
                        isMulti={isMulti}
                        isMultiOneRowMode
                        name={name}
                        data-test={id}
                        onMenuOpen={onFilterOpen}
                        isLoading={isLoading}
                    />
                </Box>
            </Box>
        </Grid>
    );
}

export default SelectFilter;
