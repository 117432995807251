import { FormComponentValue, FormControlProps } from 'components/common/form/layout/control';
import { FormElementProps } from 'components/common/form/index';

export class FormComponentBuilder {
    protected elementProps: FormElementProps;
    protected controlProps: FormControlProps;
    protected componentValues: Array<FormComponentValue>;

    constructor(
        elementProps: FormElementProps,
        controlProps: FormControlProps,
        componentValues: Array<FormComponentValue> = []
    ) {
        this.elementProps = elementProps;
        this.controlProps = controlProps;
        this.componentValues = componentValues;
    }

    prepareProps(value: any, onChange: (...event: any[]) => void, afterChange: (event: any) => void): FormControlProps {
        return this.controlProps;
    }

    processFormData(formData: FormData, key: string, data: any) {
        formData.append(key, data);
    }
}
