import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import React, { useMemo } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import DataRowType = ReportContentNS.DataRowType;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import { Box } from '@mui/material';
import BlockComponent = ReportContentNS.BlockComponent;
import { combineCellStyle } from 'components/report-content/components/dataset/parts/index';
import { getFontSizeStyle } from 'components/report-content/utils/tools';

export default function DatasetBreakLine({
    component,
    row,
    cellStyler,
    index,
    text,
}: {
    row: DataRowType | false;
    cellStyler: CellStyler;
    component: BlockComponent<ComponentSettingsDataset>;
    index: number;
    text: string;
}) {
    const field = component.settings.fields.find(
        (field) => field.show_column_in_table_display_ind == 'Y' && field.break_column == 'Y'
    );

    if (!field) {
        return null;
    }

    const cell = {
        field: field,
        value: text,
        hidden: false,
        isEven: false,
    };

    const decoration = row ? cellStyler.calculateCellDecoration(cell, row) : {};
    const styleData = cellStyler.getCellStyle(0, field, decoration, true);
    const style = combineCellStyle(styleData.styleRules);

    const className = cellStyler.buildClassStr(styleData.classRules) + ' dataset-renderer-break-line';
    const res = useMemo(() => {
        const innerValue = <span dangerouslySetInnerHTML={{ __html: cell.value == null ? '' : cell.value }}></span>;

        const fixedColor = style?.backgroundColor ? style.backgroundColor : '#fff';
        return (
            // @ts-ignore
            <Box
                data-break={index}
                sx={{
                    // position: 'relative',
                    // left: scrollLeft + 'px',
                    '&.block-wrapper-fixed-column': {
                        position: 'sticky',
                        left: 0,
                        backgroundColor: fixedColor,
                        zIndex: 2,
                    },
                }}
                className={className}
                style={{ ...style, width: '100%' }}
            >
                {innerValue}
            </Box>
        );
    }, [JSON.stringify(style), className, cell.value]);

    const fontStyle = getFontSizeStyle(component.settings);
    return (
        <Box
            sx={{ fontStyle }}
            style={{
                display: 'flex',
                width: '100%',
                whiteSpace: 'nowrap',
            }}
        >
            {res}
        </Box>
    );
}
