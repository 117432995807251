import { DatasetCellValue } from 'components/report-content/components/dataset/index';
import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import React, { useMemo } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import DataRowType = ReportContentNS.DataRowType;
import ComponentProps = ReportContentNS.ComponentProps;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import { Box } from '@mui/material';
import { combineCellStyle } from 'components/report-content/components/dataset/parts/index';

export default function DatasetCell({
    component,
    isTotalLine,
    cell,
    row,
    cellStyler,
    isEven,
    actions,
    contentSettings,
    left,
    index,
}: ComponentProps<ComponentSettingsDataset> & {
    isTotalLine: boolean;
    row: DataRowType | false;
    cell: DatasetCellValue;
    cellStyler: CellStyler;
    isEven: boolean;
    left: number;
    index: number;
}) {
    const decoration = row ? cellStyler.calculateCellDecoration(cell, row) : {};
    const field = isTotalLine ? { ...cell.field, ...component.settings.totalsSettings } : cell.field;
    const styleData = cellStyler.getCellStyle(index, field, decoration, !isEven);
    const style = combineCellStyle(styleData.styleRules);

    if (left > 0) {
        // @ts-ignore
        style.left = left + 'px';
    }
    style.minWidth = '30px';

    const handleHrefClick = (event: React.MouseEvent) => {
        if (contentSettings.context == 'view' && row) {
            event.preventDefault();
            const extViz = actions.getBlocks().find((b) => b.component.internalName == 'external_visualization');
            if (!extViz) {
                return;
            }
            actions.updateBlockSettings(extViz.uid, {
                ...extViz.component.settings,
                userSelectedViz: String(row['external-visualization-link']),
            });
            return false;
        }
    };

    const className = cellStyler.buildClassStr(styleData.classRules);
    return useMemo(() => {
        const innerValue =
            row && row['external-visualization-link'] ? (
                <Box
                    component={'a'}
                    onClick={(event) => handleHrefClick(event)}
                    dangerouslySetInnerHTML={{ __html: cell.value == null ? '' : cell.value }}
                    target="_blank"
                    href={String(row['external-visualization-link'])}
                    sx={{ textDecoration: 'underline', '&:hover': { textDecoration: 'none' } }}
                ></Box>
            ) : (
                <Box
                    component={'span'}
                    sx={{ a: { textDecoration: 'underline', '&:hover': { textDecoration: 'none' } } }}
                    dangerouslySetInnerHTML={{ __html: cell.value == null ? '' : cell.value }}
                ></Box>
            );

        const fixedColor = style?.backgroundColor
            ? style.backgroundColor
            : isEven && component.settings.zebra_striping == 'Y'
            ? cellStyler.getZebraColor('#fff')
            : '#fff';
        return (
            // @ts-ignore
            <Box
                sx={{
                    '&.block-wrapper-fixed-column': {
                        position: 'sticky',
                        left: 0,
                        backgroundColor: fixedColor,
                        zIndex: 2,
                    },
                }}
                className={className}
                style={style}
            >
                {cell.hidden ? '' : innerValue}
            </Box>
        );
    }, [JSON.stringify(style), className, cell.value, component.settings.zebra_striping]);
}
