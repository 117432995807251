import React, { createContext, useRef, useState } from 'react';
import { downloadAPI } from 'api/api';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { Box, Stack } from '@mui/material';
import useBundleTranslation from 'i18n';

export interface DownloadManagerHandlerProps {
    elementId: number;
    segmentId: number;
    format: string;
}
export const DownloadManagerContext = createContext(function (props: DownloadManagerHandlerProps, options?: any) {});
export default function DownloadManager({ children }: { children: any }) {
    const [isActive, setIsActive] = useState<Boolean>(false);
    const [hashPref, setHashPref] = useState<string>('');

    const isActiveRef = useRef(isActive);
    isActiveRef.current = isActive;

    const hashPrefRef = useRef(hashPref);
    hashPrefRef.current = hashPref;
    const { t } = useBundleTranslation();

    const runDownload = (props: DownloadManagerHandlerProps, options?: any) => {
        const checker = () => {
            setTimeout(() => {
                let data = options ?? {};
                if (hashPrefRef.current) {
                    // @ts-ignore
                    data.download_pref = hashPrefRef.current;
                }
                downloadAPI.element.checkDownload(props, data).then((response) => {
                    if (isActiveRef.current) {
                        setHashPref(response.data.data.pref);
                        if (response.data.data.ready) {
                            setIsActive(false);
                            // @ts-ignore
                            downloadAPI.element.getDownload(props, data);
                        } else {
                            checker();
                        }
                    }
                });
            }, 1000);
        };

        setIsActive(true);
        downloadAPI.element.runDownload(props, options).then((response) => {
            setTimeout(() => checker(), 100);
        });
    };

    const cancelDownload = () => {
        setIsActive(false);
    };

    return (
        <DownloadManagerContext.Provider value={runDownload}>
            {isActive && (
                <LoadingPlaceholder>
                    <Stack direction="column" alignItems="center">
                        <Box>{t('downloading_long')}</Box>
                        <Box onClick={() => cancelDownload()} sx={{ cursor: 'pointer' }}>
                            {t('cancel')}
                        </Box>
                    </Stack>
                </LoadingPlaceholder>
            )}
            {children}
        </DownloadManagerContext.Provider>
    );
}
