import {
    HeaderDropDownTriggerClassName,
    PivotHeaderDropDownFieldType,
} from 'components/report-content/components/dataset';
import React, { useMemo, useState } from 'react';
import { ReportContentNS } from 'components/report-content/index';
import ComponentProps = ReportContentNS.ComponentProps;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotHeaderCell from 'components/report-content/components/pivot/parts/header/PivotHeaderCell';
import PivotHeaderColumnDropDown from 'components/report-content/components/pivot/parts/header/PivotHeaderColumnDropDown';
import PivotInitialDataResponse = ReportContentNS.PivotInitialDataResponse;
import PivotDataCell = ReportContentNS.PivotDataCell;
import PivotDataCellStyle = ReportContentNS.PivotDataCellStyle;
import { PivotCellStyler } from 'components/report-content/components/pivot/PivotCellStyler';
import BlockComponent = ReportContentNS.BlockComponent;
import ContentMode = ReportContentNS.ContentMode;
import { getFontSizeStyle } from 'components/report-content/utils/tools';
import { Box } from '@mui/material';

export default function PivotHeaderLine({
    component,
    onChangeColumnSettings,
    cellStyler,
    scrollLeft,
    contentSettings,
    rows,
    actions,
    editPanel,
    blockId,
}: ComponentProps<ComponentSettingsPivot> & {
    onChangeColumnSettings: (
        cell: PivotDataCell,
        newSettings: PivotDataCellStyle,
        unsetAutoStretch: boolean,
        realIndex: number
    ) => void;
    cellStyler: PivotCellStyler;
    scrollLeft: number;
    rows: PivotInitialDataResponse;
}) {
    const [headerDropDownField, setHeaderDropDownField] = useState<PivotHeaderDropDownFieldType>({
        field: null,
        uiFieldIndex: null,
        anchorEl: null,
        realIndex: 0,
    });

    const list = useMemo(
        () =>
            rows.map((row, index) =>
                row.map((cell, j) => (
                    <CellWrapper
                        realIndex={j}
                        key={String(cell.uid) + j}
                        cell={cell}
                        headerDropDownField={headerDropDownField}
                        setHeaderDropDownField={setHeaderDropDownField}
                        component={component}
                        cellStyler={cellStyler}
                        onChangeColumnSettings={onChangeColumnSettings}
                        context={contentSettings.context}
                        mainRow={index + 1 == rows.length}
                        row={row}
                    />
                ))
            ),
        [rows, JSON.stringify(component.settings)]
    );

    const result = rows.map((row, index: number) => (
        <span key={index} style={{ display: 'flex', marginLeft: '-' + scrollLeft + 'px' }}>
            {list[index]}
        </span>
    ));
    const fontStyle = getFontSizeStyle(component.settings);
    return (
        <span style={{ position: 'relative' }}>
            {headerDropDownField.field != null && (
                <PivotHeaderColumnDropDown
                    component={component}
                    onChangeColumnSettings={onChangeColumnSettings}
                    field={headerDropDownField}
                    actions={actions}
                    editPanel={editPanel}
                    blockId={blockId}
                    onClickAway={(event: PointerEvent) => {
                        if ((event.target as HTMLElement).classList.contains(HeaderDropDownTriggerClassName)) {
                            return;
                        }
                        setHeaderDropDownField({ field: null, uiFieldIndex: null, anchorEl: null, realIndex: 0 });
                    }}
                />
            )}
            <Box sx={{ fontStyle }}>{result}</Box>
        </span>
    );
}

function CellWrapper({
    realIndex,
    cell,
    headerDropDownField,
    setHeaderDropDownField,
    component,
    cellStyler,
    onChangeColumnSettings,
    context,
    mainRow,
    row,
}: {
    realIndex: number;
    cell: PivotDataCell;
    headerDropDownField: PivotHeaderDropDownFieldType;
    setHeaderDropDownField: (field: PivotHeaderDropDownFieldType) => void;
    component: BlockComponent<ComponentSettingsPivot>;
    cellStyler: PivotCellStyler;
    onChangeColumnSettings: any;
    context: ContentMode;
    mainRow: boolean;
    row: Array<PivotDataCell>;
}) {
    const result = useMemo(
        () => (
            <PivotHeaderCell
                realIndex={realIndex}
                cell={cell}
                headerDropDownField={headerDropDownField}
                setHeaderDropDownField={setHeaderDropDownField}
                component={component}
                cellStyler={cellStyler}
                onChangeColumnSettings={onChangeColumnSettings}
                context={context}
                mainRow={mainRow}
                row={row}
            />
        ),
        [JSON.stringify(cell), JSON.stringify(component.settings), headerDropDownField.uiFieldIndex]
    );
    return result;
}
