import { MetricItem } from '../index';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { processMetricSeries } from 'components/metric/chart/seriesBuilder';
import { ChartPoint, ChartSeriesType } from 'components/metric/chart';
import { getAlertSeries } from 'components/metric/chart/series/getAlertSeries';
import {
    AdditionalToDrawType,
    clearChart,
    drawSVG,
    RangeToDrawType,
    refreshSVGRangesGroup,
} from 'components/metric/chart/draw/drawRanges';
import { Chart as HChart } from 'highcharts';
import Chart from 'components/chart/Chart';
import Highcharts from 'highcharts/highstock';
import chartRedrawAlerts from 'components/metric/chart/draw/chartRedrawAlerts';
import adjustCanvas from 'components/metric/chart/draw/adjustCanvas';
import { Box, Typography } from '@mui/material';
import useBundleTranslation from 'i18n';
import { useTheme } from '@mui/material/styles';

interface IMetricChartProps {
    metrics: MetricItem[];
    activeMetricUid: string;
    chartData?: any;
}

export default function MetricChart({ metrics, activeMetricUid, chartData }: IMetricChartProps) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const appTheme = useTheme();

    const basicChartOptions: Highcharts.Options = useMemo(() => {
        return {
            credits: { enabled: false },
            chart: {
                height: 300,
                animation: false,
                zooming: {
                    mouseWheel: {
                        enabled: false,
                    },
                },
                style: {
                    fontFamily: 'Arial, helvetica, sans-serif',
                },
                events: {
                    redraw: function () {
                        const svgRangesGroup = refreshSVGRangesGroup(this);
                        let additionalIcons: AdditionalToDrawType = {
                            points: [],
                            ranges: [],
                        };
                        additionalIcons = chartRedrawAlerts(this, mainSeriesRef, additionalIcons);

                        additionalIcons.ranges.forEach((range: RangeToDrawType<ChartPoint>) => {
                            drawSVG(appTheme, this, 'range', range, svgRangesGroup);
                        });

                        additionalIcons.points.forEach((point) => {
                            drawSVG(appTheme, this, 'point', point, svgRangesGroup);
                        });

                        adjustCanvas(this, additionalIcons);
                    },
                },
            },
            yAxis: {
                opposite: false,
            },
        };
    }, []);

    const [chart, setChart] = useState<HChart>();
    const [chartOptions, setChartOptions] = useState<Highcharts.Options>(basicChartOptions);
    const afterChartCreated = (chart: HChart) => setChart(chart);

    const mainSeries = useMemo(() => {
        if (!chartData) {
            return;
        }

        const series = processMetricSeries(chartData, { compareLines: [], overlays: [], legend: {} });

        const seriesData = { ...series[0] };

        return [
            {
                ...seriesData,
                data: seriesData.data.filter((point) => point.y !== null),
            } as ChartSeriesType,
        ];
    }, [chartData]);

    const mainSeriesRef = useRef<Array<ChartSeriesType>>();
    useEffect(() => {
        mainSeriesRef.current = mainSeries;
    }, [mainSeries]);

    const alertSeries = useMemo(
        () =>
            chart && chartData
                ? getAlertSeries(chartData.seriesData, chartData.alertSeriesData, {}, chart, appTheme)
                : [],
        [chartData, chart, chart?.series?.length ?? 0]
    );

    useEffect(() => {
        // Remove ChartRanges lines & Icons
        clearChart();
    }, [alertSeries]);

    useEffect(() => {
        if (!chart) {
            return;
        }

        setTimeout(() => chart.redraw(), 100);
    }, [chart]);

    useEffect(() => {
        if (!chart || !mainSeries) {
            return;
        }
        let seriesList = mainSeries;
        if (alertSeries?.[0]?.data?.length) {
            seriesList = seriesList.concat(alertSeries);
        }
        //@ts-ignore
        setChartOptions({ ...basicChartOptions, series: seriesList });
    }, [chart, mainSeries, alertSeries]);

    return chartData && activeMetricUid > '' ? (
        <Box sx={{ pt: '6px' }}>
            <Chart afterChartCreated={afterChartCreated} chartOptions={chartOptions} />
        </Box>
    ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Typography>{t('add_metric.add_preview')}</Typography>
        </Box>
    );
}
