import { useEffect } from 'react';

export default function TokenTest() {
    useEffect(() => {
        if ('undefined' !== typeof window.opener.tokenFormUpdatePropertyValue) {
            window.opener.tokenFormUpdatePropertyValue(
                ['param_token', 'param_token_label'],
                '$token',
                'param_token',
                window.location.search
            );
        } else {
            window.opener.location.reload(false);
        }
        setTimeout(() => window.close(), 200);
    }, []);

    return <div></div>;
}
