import { useEffect, useState } from 'react';
import { getElementFieldValue } from 'components/common/form/formTools';
import { getDFCommandLabel, HintComponentProps, useMethod } from 'components/common/form/data-fetch-command/hint/index';
import styles from '../DataFetchCommandHint.styles';
import { Box } from '@mui/material';

export default function SegmentHint({ controlProps, t }: HintComponentProps) {
    const [hintText, setHintText] = useState('');
    const method = useMethod(controlProps.form);

    const getAttr = (field: string, attr: string) => {
        const result = getElementFieldValue(controlProps.form, `${field}.${attr}`);
        return result == null ? '' : result;
    };

    const command = controlProps.form.hookFormWatch('data_fetch_command');
    const currencyInd = controlProps.form.hookFormWatch('currency_set_by_segment_value_ind');
    const parentSegment = controlProps.form.hookFormWatch('parent_segment_id');
    const compound = controlProps.form.hookFormWatch('segment_type');

    useEffect(() => {
        let html: Array<string> = [];
        let additional_hint =
            method == 'plugin'
                ? getElementFieldValue(controlProps.form, `plugin_connection_profile_id.add_hint`) ?? ''
                : '';

        const label = getDFCommandLabel(method, controlProps.form, t) ?? '';
        if (compound == 'simple') {
            // $('#tr_primary_segment_dataset_column_label').html($('#name').val());
            if (method == 'sql' || method == 'plugin') {
                const valueType = controlProps.form.hookFormGetValues('partition_value_type');
                html.push(
                    t('main_hint.segment.enter_a_label', {
                        label: label,
                        n: parentSegment > '' ? '3' : '2',
                        int: valueType == 'int' ? '(as integer)' : '',
                    })
                );
                if (parentSegment > '') {
                    html.push(
                        '<br/>' +
                            t('main_hint.segment.enter_a_label_3', {
                                bindParam:
                                    getAttr('parent_segment_id', 'data_fetch_command_bind_parameter') ||
                                    getAttr('parent_segment_id', '$option.label'),
                            })
                    );
                }
                if (currencyInd == 'Y') {
                    html.push('<br/>' + t('main_hint.segment.may_include'));
                }
                if (additional_hint > '') {
                    html.push('<br />' + atob(additional_hint));
                }
            }
        } else {
            // $('#tr_primary_segment_dataset_column_label').html($('#primary_segment_id option:selected').html());
            // $('#tr_secondary_segment_dataset_column_label').html($('#secondary_segment_id option:selected').html());
            if (method == 'sql' || method == 'plugin') {
                html = [
                    t('main_hint.segment.specify_a_label', {
                        label: label,
                        primary: getAttr('primary_segment_id', 'bind_param'),
                        secondary: getAttr('secondary_segment_id', 'bind_param'),
                    }),
                ];
                if (additional_hint > '') {
                    html.push('<br />' + atob(additional_hint));
                }
            }
        }
        setHintText(html.join(''));
    }, [method, command, currencyInd, parentSegment, compound]);

    if (!hintText) return null;

    return <Box sx={styles.hintWrapper} dangerouslySetInnerHTML={{ __html: hintText }} />;
}
