import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { Box, FormControl, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import { dimensionAPI } from 'api/api';
import { TransferList } from 'components/common/transfer-list/TransferList';

interface segmentOption {
    segment_value_id: number | string;
    title: string;
    current: string;
    selected: string;
}

interface dimensionMainData {
    alertName: string;
    segment: {
        id: number | string;
        title: string;
    };
    list: segmentOption[];
}

export interface DimensionPopupProps {
    onClose: (event: any) => void;
    onApply: (data: any) => void;
    elementId: number;
    segmentId: number;
    alertRuleId?: number;
    userId?: number;
    showAll?: boolean;
    initialSelectedIds?: (string | number)[];
    initialClearSelected?: boolean;
}

const DIMENSION_TYPE = {
    CURRENT: 'current',
    ALL: 'all',
    SELECTED: 'selected',
};

//ElementSubscription.openDimensionPopup
//AlertRuleList2.openDimensionPopup
//www/js/application/alert_rule_dimension_control.js
//AlertRuleDimensionControl
//engine/application/notification/views/scripts/alertrule/alert-rule-dimension-popup.phtml
export default function DimensionPopup(props: DimensionPopupProps) {
    const {
        onClose = () => {},
        onApply = () => {},
        elementId,
        segmentId,
        alertRuleId = 0,
        userId = 0,
        showAll = false,
        initialSelectedIds = [],
        initialClearSelected = false,
    } = props;

    const { t } = useBundleTranslation(['components/dimension-popup/dimension_popup']);
    const [mainData, setMainData] = useState<dimensionMainData>();
    const [selectedItems, setSelectedItems] = useState<segmentOption[]>([]);
    const [dimensionType, setDimensionType] = useState<string>();

    const currentSegment = (mainData?.list || []).find((item) => {
        return item.current === 'Y';
    }) ?? { segment_value_id: 0, title: '' };

    useEffect(() => {
        dimensionAPI
            .getData(elementId, segmentId, alertRuleId, showAll, userId)
            .then((response) => {
                if (response.statusText === 'OK') {
                    //afterLoad changed to initialSelectedIds [] and option initialClearSelected
                    const listData = (response.data?.list ?? []).map((item: segmentOption) => {
                        const isSelected = initialSelectedIds.includes(item.segment_value_id);
                        const isSelectedDefault = initialClearSelected ? 'N' : item.selected;
                        const newVal = isSelected ? 'Y' : isSelectedDefault;
                        return { ...item, selected: newVal };
                    });

                    setMainData({ ...response.data, list: listData });
                    setSelectedItems(listData.filter((item: any) => item.segment_value_id != -1));
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (mainData && !dimensionType) {
            let type = DIMENSION_TYPE.CURRENT;
            const selectedItems = mainData.list.filter((item) => {
                return item.selected === 'Y';
            });
            const selectedItem = selectedItems?.[0];
            if (selectedItem) {
                if (selectedItem.segment_value_id == -1) {
                    type = DIMENSION_TYPE.ALL;
                } else if (
                    selectedItems.length > 1 ||
                    selectedItem.segment_value_id != currentSegment.segment_value_id
                ) {
                    type = DIMENSION_TYPE.SELECTED;
                }
            }
            if (type == DIMENSION_TYPE.ALL) {
                // Mark all segments as selected on initData
                const newList = mainData.list.map((item) => {
                    return { ...item, selected: 'Y' };
                });
                setMainData({
                    ...mainData,
                    list: newList,
                });
                setSelectedItems(newList.filter((item: any) => item.segment_value_id != -1));
            }
            if (currentSegment.segment_value_id == 0 && type == DIMENSION_TYPE.CURRENT) {
                type = DIMENSION_TYPE.ALL;
            }
            setDimensionType(type);
        }
    }, [mainData]);

    if (!mainData) return null;

    const onApplyPrepareData = () => {
        let segments: segmentOption[] = [];
        let selectedAll = false;

        if (dimensionType == DIMENSION_TYPE.ALL) {
            // Mark all segments as selected
            segments = selectedItems.map((item) => {
                return { ...item, selected: 'Y' };
            });
        } else if (dimensionType == DIMENSION_TYPE.CURRENT) {
            // Mark only current segments as selected
            segments = selectedItems.map((item) => {
                return { ...item, selected: item.current === 'Y' ? 'Y' : 'N' };
            });
        } else if (dimensionType == DIMENSION_TYPE.SELECTED) {
            segments = selectedItems;
            selectedAll = selectedItems.every((item) => item.selected === 'Y');
        }

        onApply({
            radioType: selectedAll ? DIMENSION_TYPE.ALL : dimensionType,
            segments: segments,
            current: currentSegment.segment_value_id,
            options: {
                elementId: elementId,
                segmentId: segmentId,
                alertRuleId: alertRuleId,
                userId: userId,
                showAll: showAll,
            },
        });
    };

    return (
        <Popup
            settings={{
                title: mainData.alertName,
                textOK: t('button_done'), //id = dSaveAlertRuleDimensions
                needTranslation: false,
                customMaxWidth: '650px',
            }}
            open={true}
            onHide={onClose}
            onConfirm={onApplyPrepareData}
        >
            <Box id="dAlertRuleDimensionWrapper">
                <FormControl>
                    <FormLabel>
                        {t('label_subscribe_to')}
                        {mainData.segment?.title ? ` ${mainData.segment.title}` : ''}:
                    </FormLabel>
                    <ToggleButtonGroup
                        color="primary"
                        value={String(dimensionType)}
                        exclusive
                        onChange={(event, value) => {
                            if (value !== null) {
                                setDimensionType(value);
                            }
                        }}
                    >
                        {currentSegment.segment_value_id > 0 && (
                            <ToggleButton value={DIMENSION_TYPE.CURRENT}>
                                {t(`type_label.${DIMENSION_TYPE.CURRENT}`, { title: currentSegment.title })}
                            </ToggleButton>
                        )}
                        <ToggleButton value={DIMENSION_TYPE.ALL}>
                            {t(`type_label.${DIMENSION_TYPE.ALL}`, { amount: selectedItems.length })}
                        </ToggleButton>
                        <ToggleButton value={DIMENSION_TYPE.SELECTED}>
                            {t(`type_label.${DIMENSION_TYPE.SELECTED}`)}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </FormControl>
                {dimensionType === DIMENSION_TYPE.SELECTED && (
                    <Box className="dCommunicatingWrapper" sx={{ width: '100%', mt: 2 }}>
                        <TransferList
                            onChange={(selectedIds) => {
                                setSelectedItems(
                                    selectedItems.map((item) => {
                                        const isSelected = selectedIds.includes(String(item.segment_value_id));
                                        return { ...item, selected: isSelected ? 'Y' : 'N' };
                                    })
                                );
                            }}
                            value={selectedItems
                                .filter((item) => item.selected === 'Y')
                                .map((item) => {
                                    return item.segment_value_id;
                                })}
                            data={selectedItems.map((item) => {
                                return {
                                    value: item.segment_value_id,
                                    label: item.title,
                                };
                            })}
                        />
                    </Box>
                )}
            </Box>
        </Popup>
    );
}

//ToDo Save button with icon popup_btn_save
