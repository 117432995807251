import { useTranslation } from 'react-i18next';
import { translationAPI } from 'api/api';

export default function useBundleTranslation(translationNS: string | string[] = '') {
    if (!Array.isArray(translationNS)) {
        translationNS = [translationNS];
    }

    const prefixList: Array<string> = translationNS.map((ns) => ns.replace(/\//g, '.'));
    const { t, i18n, ready } = useTranslation();
    return {
        t: (key: string, options?: any): any => {
            let withPrefix = '';

            if ('' == key || undefined == key) return '';
            if (prefixList.length && prefixList[0].startsWith('app.dev')) return key;

            for (let i = 0; i < prefixList.length; i++)
                if (prefixList[i].length) {
                    if (withPrefix.includes('?')) withPrefix = withPrefix.split('?')[0];

                    withPrefix = prefixList[i] + '.' + key;
                    if (i18n.exists(withPrefix)) {
                        return t(withPrefix, options);
                    } else if (
                        'object' === typeof options &&
                        (i18n.exists(withPrefix + '_one') || i18n.exists(withPrefix + '_other'))
                    ) {
                        return t(withPrefix, options);
                    }
                }

            if (!i18n.exists(key) && prefixList.length && i18n.options.debug) {
                withPrefix = prefixList[0] + '.' + key;
                console.log(withPrefix, key, options);
                //translationAPI.post({ withPrefix, key, options });
            }

            return t(key, options);
        },
        i18n,
        ready,
    };
}
