import React, { useEffect, useRef, useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { useDispatch, useSelector } from 'react-redux';
import { resetThemeEditor, setThemeMenuOverrides, setThemeOverrides } from 'store/themeEditorSlice';
import { deepmerge } from '@mui/utils';
import { authAPI } from 'api/api';
import { setBrandThemeProperties } from 'store/auth';
import { setFontWeightValue } from 'app/editor/brand-theme/ThemeUsagePreview';
import { useQueryClient } from '@tanstack/react-query';
import _isEqual from 'lodash/isEqual';

const aliases = {
    brand_theme_color_primary: 'palette.primary.main',
    brand_theme_color_text: 'palette.text.primary',
    brand_theme_color_background: 'palette.background.default',
    brand_theme_color_accept: 'palette.success.main',
    brand_theme_color_alert_high: 'palette.error.main',
    brand_theme_color_alert_medium: 'palette.warning.main',
    brand_theme_color_alert_low: 'palette.warningLow.main',
    brand_theme_color_tab: 'componentSettings.tab.primaryColor',
    brand_theme_color_top_bar: 'componentSettings.topbar.backgroundColor',
    brand_theme_color_top_bar_text: 'componentSettings.topbar.textColor',
    brand_theme_color_primary_button: 'componentSettings.primaryButton.primaryColor',
    brand_theme_color_primary_button_text: 'componentSettings.primaryButton.textColor',
    brand_theme_button_corner_radius: 'componentSettings.button.borderRadius',
    brand_theme_color_stoplight_good: 'componentSettings.tile.stoplight.goodColor',
    brand_theme_color_stoplight_bad: 'componentSettings.tile.stoplight.badColor',
    brand_theme_color_stoplight_neutral: 'componentSettings.tile.stoplight.neutralColor',
    brand_theme_color_tile_title: 'componentSettings.tile.titleColor',
    brand_theme_color_section_title: 'componentSettings.tile.sectionTitleColor',
};
const usageAliases = {
    section_title: 'typography.heading',
    regular_text: 'typography.body1',
};
const usagePropsAliases = {
    font: 'fontFamily',
    font_style: 'fontStyle',
    font_weight: 'fontWeight',
    font_size: 'fontSize',
};
const menuAliases = {
    brand_theme_menu_content_display: 'content_display',
    brand_theme_menu_content_display_user_type: 'content_display_user_type',
};
const createNestedObject = (keys: string[], value: any) => {
    let obj: Record<string, any> = {};
    let currentObj: Record<string, any> = obj;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (i === keys.length - 1) {
            currentObj[key] = value;
        } else {
            currentObj[key] = {};
            currentObj = currentObj[key];
        }
    }

    return obj;
};
export const getMuiThemeOverrideObject = (settingsData: any) => {
    let themeOverride = {};
    Object.keys(settingsData ?? {}).forEach((field: string) => {
        if (field && aliases[field as keyof typeof aliases]) {
            const keysArray = aliases[field as keyof typeof aliases].split('.');
            const oneSettingVal = createNestedObject(keysArray, settingsData[field]);
            themeOverride = deepmerge(themeOverride, oneSettingVal);
        } else if (field == 'usages') {
            Object.keys(settingsData[field]).forEach((usageKey: any) => {
                if (usageKey && usageAliases[usageKey as keyof typeof usageAliases]) {
                    const usageSettings = settingsData[field][usageKey];
                    let usageAliasMainPart = [usageAliases[usageKey as keyof typeof usageAliases]];
                    if (usageKey == 'regular_text') {
                        const mainPartsRegular = ['typography', 'typography.body2', 'typography.button'];
                        usageAliasMainPart = [...usageAliasMainPart, ...mainPartsRegular];
                    }

                    Object.keys(usagePropsAliases).forEach((usageProp: any) => {
                        if (usageSettings.hasOwnProperty(usageProp)) {
                            const usageAliasPropPart = usagePropsAliases[usageProp as keyof typeof usagePropsAliases];
                            let usageThemeValue = usageSettings[usageProp];

                            switch (usageProp) {
                                case 'font':
                                    usageThemeValue = `CustomFont_${usageThemeValue}, Inter, Arial, Helvetica, sans-serif`;
                                    break;
                                case 'font_weight':
                                    usageThemeValue = setFontWeightValue(usageThemeValue);
                                    break;
                                case 'font_size':
                                    usageThemeValue = parseInt(usageThemeValue);
                                    break;
                            }

                            usageAliasMainPart.forEach((aliasPart) => {
                                const keysArray = (aliasPart + '.' + usageAliasPropPart).split('.');
                                const oneSettingVal = createNestedObject(keysArray, usageThemeValue);
                                themeOverride = deepmerge(themeOverride, oneSettingVal);
                            });
                        }
                    });
                }
            });
        }
    });
    return themeOverride;
};

const getThemeMenuOverrideObject = (settingsData: any) => {
    let themeMenuOverride: any = {};
    Object.keys(settingsData ?? {}).forEach((field: string) => {
        if (field && menuAliases[field as keyof typeof menuAliases]) {
            const newKeyMenuSetting = menuAliases[field as keyof typeof menuAliases];
            themeMenuOverride[newKeyMenuSetting] = settingsData[field];
        }
    });
    return themeMenuOverride;
};

export default function BrandThemeForm(props: IFormLayoutProps) {
    const themeOverrides = useSelector((state: any) => state.themeEditor.overrides ?? {});
    const themeMenuOverrides = useSelector((state: any) => state.themeEditor.menu ?? {});
    const dispatch = useDispatch();

    const isNewMode = props.isNew;
    const isFormChanged = useRef<number>(0);
    //watch state for reset to defaults
    const form_state = props.hookForm.form.hookFormWatch('form_brand_theme_state');

    const queryClient = useQueryClient();
    const fontUsageGridSubscribeRef: any = useRef();
    const fontUsageGridInitialLoad = useRef<boolean>(true);

    const updateOverridesData = () => {
        const currentValues = props.hookForm.form.hookFormWatch();
        const newThemeOverrideObject = getMuiThemeOverrideObject(currentValues);
        if (!_isEqual(themeOverrides, newThemeOverrideObject)) {
            dispatch(setThemeOverrides(newThemeOverrideObject));
        }
        const newThemeMenuOverrideObject = getThemeMenuOverrideObject(currentValues);
        if (!_isEqual(themeMenuOverrides, newThemeMenuOverrideObject)) {
            dispatch(setThemeMenuOverrides(newThemeMenuOverrideObject));
        }
    };

    //const test = props.hookForm.form.hookFormWatch();
    useEffect(() => {
        if (!isNewMode && props.hookForm.form.formDidMount && form_state === 'initial') {
            updateOverridesData();
            props.hookForm.form.hookFormSetValue('form_brand_theme_state', 'active', { shouldDirty: false });
            isFormChanged.current = 1;
        }
    }, [form_state]);

    useEffect(() => {
        if (!isNewMode) {
            updateOverridesData();
            props.hookForm.form.hookFormSetValue('form_brand_theme_state', 'active', { shouldDirty: false });
        }

        return () => {
            if (!isNewMode) {
                if (isFormChanged.current) {
                    //reload brandThemeProperties if changed form and leave it
                    authAPI.info().then((response) => {
                        if (response.statusText == 'OK' && response.data?.userAuth?.userInfo?.brandThemeProperties) {
                            dispatch(setBrandThemeProperties(response.data.userAuth.userInfo.brandThemeProperties));
                        }
                    });
                }
                dispatch(resetThemeEditor());

                if (fontUsageGridSubscribeRef.current) {
                    fontUsageGridSubscribeRef.current();
                }
            }
        };
    }, []);

    useEffect(() => {
        if (!isNewMode) {
            updateOverridesData();
        }
    }, [props.hookForm.form.hookFormWatch()]);

    useEffect(() => {
        if (!isNewMode && !fontUsageGridSubscribeRef.current) {
            const queryCacheData = queryClient.getQueryCache();
            const fontUsageQuery = queryCacheData.findAll(['brandThemeFontUsageGrid']);
            if (fontUsageQuery.length) {
                fontUsageGridSubscribeRef.current = queryCacheData.subscribe((result) => {
                    if (
                        result.type == 'updated' &&
                        result?.action?.type == 'success' &&
                        result.query.queryKey.includes('brandThemeFontUsageGrid')
                    ) {
                        if (fontUsageGridInitialLoad?.current) {
                            fontUsageGridInitialLoad.current = false;
                        } else {
                            isFormChanged.current = 1;
                        }

                        const rows = result?.query?.state?.data?.data?.data?.rows;
                        const usagesObject: any = { usages: {} };
                        if (rows && rows.length) {
                            rows.forEach((usage: any) => {
                                usagesObject.usages[usage.usage_id] = {
                                    font: usage.font_id,
                                    font_size: usage.font_size.slice(0, -2),
                                    font_style: usage.font_style.toLowerCase(),
                                    font_weight: usage.font_weight,
                                };
                            });

                            const newThemeOverrideObject = {
                                ...themeOverrides,
                                ...getMuiThemeOverrideObject(usagesObject),
                            };
                            if (!_isEqual(themeOverrides, newThemeOverrideObject)) {
                                dispatch(setThemeOverrides(newThemeOverrideObject));
                            }
                        }
                    }
                });
            }
        }
    }, [queryClient.getQueryCache()]);

    return <FormLayoutComposer props={{ ...props }} />;
}
